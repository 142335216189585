import { AnalyticsData, TrackingData } from '../models/tracking-data.model';
import { TrackingProviders } from '../models/tracking-providers.enum';
import { AnalyticsTag, TrackingTag } from '../models/tracking-tag.model';
import { AbstractTrackingMapperService } from '../services/abstract-tracking-mapper.service';

export abstract class AbstractTrackingProvider {
  mapper: AbstractTrackingMapperService<AnalyticsTag, AnalyticsData>;

  protected constructor(mappers: AbstractTrackingMapperService<AnalyticsTag, AnalyticsData>[], provider: TrackingProviders) {
    this.mapper = mappers.find((mapper) => mapper.getTrackingProviderName() === provider) as AbstractTrackingMapperService<AnalyticsTag, AnalyticsData>;
  }

  abstract track(tag: TrackingTag, trackingData: TrackingData): void;
}
