import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Site } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderComponent } from '../../card-loader/card-loader.component';
import { DetailSiteCardComponent } from '../../detail-popup-cards/detail-site-card/detail-site-card.component';
import { DetailPopupSectionHeaderComponent } from '../../detail-popup/detail-popup-section-header/detail-popup-section-header.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, NgClass, DetailPopupSectionHeaderComponent, DetailSiteCardComponent, CardLoaderComponent],
  selector: 'iot-platform-ui-site-details-full-info',
  templateUrl: './site-details-full-info.component.html',
  styleUrls: ['./site-details-full-info.component.scss']
})
export class SiteDetailsFullInfoComponent {
  site = input<Site | null>(null);
  loaded = input<boolean>(false, { alias: 'isSiteLoaded' });

  selectSite = output<Site>();
}
