import { EnvironmentProviders, InjectionToken, Type, makeEnvironmentProviders } from '@angular/core';
import { AbstractVariableCreateFormService } from './abstract-variable-create-form.service';

export interface VariableCreateFormSettings {
  variableCreateFormService: Type<AbstractVariableCreateFormService>;
}

export const VARIABLE_CREATE_FORM_SETTINGS = new InjectionToken<VariableCreateFormSettings>('VARIABLE_CREATE_FORM_SETTINGS');

export function provideVariableCreateFormSettings(settings: VariableCreateFormSettings): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: AbstractVariableCreateFormService, useClass: settings.variableCreateFormService }]);
}
