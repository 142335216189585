<div class="detail-card">
  <ul class="detail-card-list">
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ENTITY' | translate }}"
        class="detail-card-item">{{ site()?.entity.name | infoDisplay }}
    </li>
    @if (site()?.type === 'stock') {
      <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.NAME' | translate }}"
          class="detail-card-item">{{ site()?.name | infoDisplay }}
      </li>
    } @else {
      <li (click)="selectSite.emit(site())" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.NAME' | translate }}"
          class="detail-card-item-navigable">
        {{ site()?.name | infoDisplay }}
      </li>
    }
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.TYPE' | translate }}"
        class="detail-card-item">{{ 'SITES.CARD.TYPES.' + site()?.type | translate }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ID' | translate }}"
        class="detail-card-item">{{ site()?.businessId | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ADDRESS_1' | translate }}"
        class="detail-card-item">{{ site()?.address.address1 | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ADDRESS_2' | translate }}"
        class="detail-card-item">{{ site()?.address.address2 | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.CITY' | translate }}" class="detail-card-item">
      {{ site()?.address | infoDisplay: 'brackets' : 'city' : 'zipCode' }}
    </li>
  </ul>
</div>
