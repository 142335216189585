@if (tagsLoading()) {
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
}

<div class="p-20">
  <form [formGroup]="form">
    @if (data().concepts.length > 1) {
      <section fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px">
        <mat-form-field class="concept-select" color="accent">
          <mat-label>{{ 'MANAGE_TAGS_FORM.CONCEPT_PLACEHOLDER' | translate }}</mat-label>
          <mat-select formControlName="concept">
            <mat-select-trigger fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon
                [color]="'#394C5A'"
                [ngClass]="['concept-icon', 'concept-icon' + concept.value.toLowerCase()]"
                [svgIcon]="concept.value.toLowerCase()"
              ></mat-icon>
              <span class="concept-name">{{ concept.value }}</span>
            </mat-select-trigger>

            @for (concept of data().concepts; track concept) {
              <mat-option [value]="concept">
                <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon [color]="'#394C5A'" [svgIcon]="concept"></mat-icon>
                  <span class="concept-name">{{ concept | uppercase }}</span>
                </span>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </section>
    }

    @if (data().enforceMandatoryCategories) {
      <div class="manage-tags-form--mandatory-toggle fs-12" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-slide-toggle color="accent" formControlName="mandatoryOnly"></mat-slide-toggle>
        <mat-label>{{ 'MANAGE_TAGS_FORM.MANDATORY_TOGGLE_LABEL' | translate }}</mat-label>
      </div>
    }
  </form>

  <section class="tag-lists">
    <mat-accordion [multi]="true" class="tag-list-accordion">
      @for (tagsByEntity of tagsToDisplay()[concept.value.toUpperCase()]; track tagsByEntity.entityId) {
        <mat-expansion-panel
          [disabled]="
            (!tagsByEntity.tagCategories?.length && !data().editable) ||
            (!tagsByEntity.tagCategories?.length && data().editable && tagsByEntity.entityId !== data().currentEntityId)
          "
          [hideToggle]="
            (!tagsByEntity.tagCategories?.length && !data().editable) ||
            (!tagsByEntity.tagCategories?.length && data().editable && tagsByEntity.entityId !== data().currentEntityId)
          "
        >
          <mat-expansion-panel-header class="entity-header">
            <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
              <span
                [ngClass]="{
                  'no-tag':
                    (!tagsByEntity.tagCategories?.length && !data().editable) ||
                    (!tagsByEntity.tagCategories?.length && data().editable && tagsByEntity.entityId !== data().currentEntityId)
                }"
                class="entity-name-selected"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="6px"
              >
                @if (data().enforceMandatoryCategories && tagsByEntity.isMandatoryTagMissing) {
                  <mat-icon
                    class="manage-tags-form--mandatory-tag-missing-icon"
                    color="warn"
                    [matTooltip]="'MANAGE_TAGS_FORM.MANDATORY_TAGS_TOOLTIP' | translate"
                    matTooltipClass="warning-tooltip"
                    >warning_outlined</mat-icon
                  >
                }
                <span class="entity-name">{{ tagsByEntity.entityName }}</span>
                @if (tagsByEntity.totalSelected) {
                  <span class="entity-tag-selected">
                    {{ 'MANAGE_TAGS_FORM.TAG_SELECTED' | translate: { total: tagsByEntity.totalSelected } }}
                  </span>
                }
              </span>
              <div class="panel-header-line" fxFlex></div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          @for (tagCategory of tagsByEntity.tagCategories; track tagCategory.id) {
            <div
              class="tag-by-entity-container"
              fxLayout="row"
              fxLayoutAlign="start start"
              [ngClass]="{ 'mandatory-category': data().enforceMandatoryCategories && tagCategory.mandatory && tagsByEntity.isMandatoryTagMissing }"
            >
              @if ((tagCategory?.entityId === data().currentEntityId && !data().editable) || tagCategory?.entityId !== data().currentEntityId) {
                <div class="tag-by-entity" fxLayout="row">
                  <div class="tag-category-color-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                    <div [ngStyle]="{ backgroundColor: tagCategory?.color }" class="tag-category-color"></div>
                    <span class="tag-category-name capitalize-first-letter">
                      {{ tagCategory?.name }}&nbsp;
                      @if (tagCategory.mandatory) {
                        <span class="mandatory-warning">*</span>
                      }
                    </span>
                  </div>
                  @if ((tagCategory?.entityId === data().currentEntityId && !data().editable) || tagCategory?.entityId !== data().currentEntityId) {
                    <mat-chip-set [disabled]="data().editable" class="tag-list-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                      @for (tag of tagCategory?.labels; track tag) {
                        <mat-chip-option
                          (click)="!data().editable && !tag.selected ? addSelectedTag(tagCategory, tag) : ''"
                          [class.disabled]="tag.selected"
                          [class.not-disabled]="!tag.selected"
                          [ngStyle]="{ backgroundColor: tagCategory?.color }"
                          [selectable]="false"
                          disableRipple="true"
                        >
                          {{ tag.name }}
                        </mat-chip-option>
                      }
                    </mat-chip-set>
                  }
                </div>
              }
            </div>
          }
          @if (tagsByEntity?.entityId === data().currentEntityId && data().editable) {
            <iot-platform-ui-tag-editor
              (changeValue)="canClose.set($event)"
              [concept]="concept.value"
              [organizationId]="data().currentEntityId"
            ></iot-platform-ui-tag-editor>
          }
        </mat-expansion-panel>
      }
    </mat-accordion>
  </section>
</div>

@if (!data().editable) {
  <section class="selected-tags" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div class="fw-600">{{ 'MANAGE_TAGS_FORM.SELECTED' | translate }}</div>
    <mat-chip-set fxFlex>
      @for (selectedTag of selectedTags(); track selectedTag.id) {
        <mat-chip-option
          (click)="canRemove() && removeSelectedTag(selectedTag)"
          [class.removed]="!selectedTag.selected"
          [class.selected]="selectedTag.selected"
          [ngStyle]="{ backgroundColor: selectedTag.color, cursor: canRemove() ? 'pointer' : 'default' }"
          [selectable]="false"
          disableRipple="true"
          fxLayoutAlign="start center"
          fxLayoutGap="4px"
        >
          <mat-icon [svgIcon]="selectedTag.concept | lowercase"></mat-icon>
          <span>{{ selectedTag.categoryName }} : {{ selectedTag.name }}</span>
          @if (canRemove()) {
            <mat-icon>close</mat-icon>
          }
        </mat-chip-option>
      }
    </mat-chip-set>
    @if (totalMissingMandatoryTags()) {
      <div class="mandatory-warning">{{ 'MANAGE_TAGS_FORM.MANDATORY_TAGS_TOTAL_MISSING' | translate: { total: totalMissingMandatoryTags() } }}</div>
    }
  </section>
}
