import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PlatformResponse } from '@iot-platform/models/common';
import { EscalationProtocol, EscalationProtocolLevel } from '@iot-platform/models/ocm';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EscalationProtocolsService } from '../../services/escalation-protocols.service';
import { EscalationProtocolsDbActions, EscalationProtocolsUiActions } from '../actions';
import * as fromEscalationProtocols from '../reducers';

@Injectable()
export class EscalationProtocolsEffects {
  listEscalationProtocols$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.listEscalationProtocols),
      switchMap((action) =>
        this.escalationProtocolsService.getAll(action.request).pipe(
          map((response: PlatformResponse) => EscalationProtocolsDbActions.listEscalationProtocolsSuccess({ response })),
          catchError((error) => of(EscalationProtocolsDbActions.listEscalationProtocolsFailure({ error })))
        )
      )
    )
  );

  loadEscalationProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.loadEscalationProtocolById),
      switchMap((action) =>
        this.escalationProtocolsService.getEscalationProtocolById(action.protocolId).pipe(
          map((protocol: EscalationProtocol) => EscalationProtocolsDbActions.loadEscalationProtocolByIdSuccess({ protocol })),
          catchError((error) => of(EscalationProtocolsDbActions.loadEscalationProtocolByIdFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.selectEscalationProtocol),
      switchMap((action) =>
        this.escalationProtocolsService.saveTableState(action.protocolToSelect).pipe(
          map((selectedProtocol: EscalationProtocol) => EscalationProtocolsDbActions.selectEscalationProtocolSuccess({ selectedProtocol })),
          catchError((error) => of(EscalationProtocolsDbActions.selectEscalationProtocolFailure({ error })))
        )
      )
    )
  );

  addEscalationProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.addEscalationProtocol),
      concatMap((action) =>
        this.escalationProtocolsService.saveEscalationProtocol(action.protocolToAdd).pipe(
          map((addedProtocol: EscalationProtocol) => EscalationProtocolsDbActions.addEscalationProtocolSuccess({ addedProtocol })),
          catchError((error) => of(EscalationProtocolsDbActions.addEscalationProtocolFailure({ error })))
        )
      )
    )
  );

  updateEscalationProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.updateEscalationProtocol),
      concatMap((action) =>
        this.escalationProtocolsService.updateEscalationProtocol(action.protocolToUpdate).pipe(
          map((updatedProtocol: EscalationProtocol) => EscalationProtocolsDbActions.updateEscalationProtocolSuccess({ updatedProtocol })),
          catchError((error) => of(EscalationProtocolsDbActions.updateEscalationProtocolFailure({ error })))
        )
      )
    )
  );

  deleteEscalationProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.deleteEscalationProtocol),
      concatMap((action) =>
        this.escalationProtocolsService.deleteEscalationProtocol(action.protocolToDelete).pipe(
          map((deletedProtocol: EscalationProtocol) => EscalationProtocolsDbActions.deleteEscalationProtocolSuccess({ deletedProtocol })),
          catchError((error) => of(EscalationProtocolsDbActions.deleteEscalationProtocolFailure({ error })))
        )
      )
    )
  );

  deleteEscalationProtocolSuccessThenNavigateToMV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EscalationProtocolsDbActions.deleteEscalationProtocolSuccess),
        tap(() => this.router.navigate(['on-call-management', 'escalation-protocols']))
      ),
    { dispatch: false }
  );

  loadTopicSubscriptionsByEscalationProtocolId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.loadTopicSubscriptionByProtocolId),
      switchMap((action) =>
        this.escalationProtocolsService.getTopicSubscriptionsByProtocolId(action.protocolId).pipe(
          map((topics: PlatformResponse) => EscalationProtocolsDbActions.loadTopicSubscriptionByProtocolIdSuccess({ topics })),
          catchError((error) => of(EscalationProtocolsDbActions.loadTopicSubscriptionByProtocolIdFailure({ error })))
        )
      )
    )
  );

  updateEscalationProtocolTopicSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.updateEscalationProtocolTopicSubscriptions),
      concatMap((action) =>
        this.escalationProtocolsService.updateTopicSubscriptions(action.protocolId, action.topics).pipe(
          map((updatedTopics: PlatformResponse) => EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsSuccess({ updatedTopics })),
          catchError((error) => of(EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsFailure({ error })))
        )
      )
    )
  );

  navigateToEscalationProtocolDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.navigateToEscalationProtocolDetails),
      tap((action) => this.router.navigate(['on-call-management', 'escalation-protocols', action.protocolToNavigate.id])),
      map((action) => EscalationProtocolsUiActions.listEscalationProtocolLevels({ protocolId: action.protocolToNavigate.id }))
    )
  );

  navigateToEscalationProtocolDetailsAfterCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsDbActions.addEscalationProtocolSuccess),
      tap((action) => {
        this.router.navigate(['on-call-management', 'escalation-protocols', action.addedProtocol.id]);
      }),
      map((action) => EscalationProtocolsUiActions.listEscalationProtocolLevels({ protocolId: action.addedProtocol.id }))
    )
  );

  actionOnProtocolThenListProtocols$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsDbActions.deleteEscalationProtocolSuccess),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(fromEscalationProtocols.getPagination), this.store.select(fromEscalationProtocols.getCurrentFilters)))
      ),
      map(([_, pagination, filters]) =>
        EscalationProtocolsUiActions.listEscalationProtocols({ request: { limit: pagination.limit, page: pagination.currentPage, filters } })
      )
    )
  );

  listEscalationProtocolLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.listEscalationProtocolLevels),
      switchMap((action) =>
        this.escalationProtocolsService.getEscalationProtocolLevels(action.protocolId).pipe(
          map((levels: EscalationProtocolLevel[]) => EscalationProtocolsDbActions.listEscalationProtocolLevelsSuccess({ levels })),
          catchError((error) => of(EscalationProtocolsDbActions.listEscalationProtocolLevelsFailure({ error })))
        )
      )
    )
  );

  addEscalationProtocolLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.addEscalationProtocolLevel),
      concatMap((action) =>
        this.escalationProtocolsService.addEscalationProtocolLevel(action.levelToAdd, action.protocolId).pipe(
          map((addedLevel: EscalationProtocolLevel) => EscalationProtocolsDbActions.addEscalationProtocolLevelSuccess({ addedLevel })),
          catchError((error) => of(EscalationProtocolsDbActions.addEscalationProtocolLevelFailure({ error })))
        )
      )
    )
  );

  updateEscalationProtocolLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.updateEscalationProtocolLevel),
      concatMap((action) =>
        this.escalationProtocolsService.updateEscalationProtocolLevel(action.levelToUpdate, action.protocolId).pipe(
          map((updatedLevel: EscalationProtocolLevel) => EscalationProtocolsDbActions.updateEscalationProtocolLevelSuccess({ updatedLevel })),
          catchError((error) => of(EscalationProtocolsDbActions.updateEscalationProtocolLevelFailure({ error })))
        )
      )
    )
  );

  updateEscalationProtocolLevelsOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.updateEscalationProtocolLevelsOrders),
      concatMap((action) =>
        this.escalationProtocolsService.updateEscalationProtocolLevelsOrder(action.levelsToUpdate, action.protocolId).pipe(
          map((updatedLevels: EscalationProtocolLevel[]) => EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersSuccess({ updatedLevels })),
          catchError((error) => of(EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersFailure({ error })))
        )
      )
    )
  );

  deleteEscalationProtocolLevelThenUpdateLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscalationProtocolsUiActions.deleteEscalationProtocolLevelThenUpdateLevels),
      concatMap((action) =>
        this.escalationProtocolsService.deleteEscalationProtocolLevelThenUpdateLevels(action.levelToDelete, action.protocolId, action.levelsToUpdate).pipe(
          map((updatedLevels: EscalationProtocolLevel[]) =>
            EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsSuccess({ deletedLevel: action.levelToDelete, updatedLevels })
          ),
          catchError((error) => of(EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsFailure({ error })))
        )
      )
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EscalationProtocolsUiActions.listEscalationProtocols,
          EscalationProtocolsUiActions.loadEscalationProtocolById,
          EscalationProtocolsUiActions.addEscalationProtocol,
          EscalationProtocolsUiActions.updateEscalationProtocol,
          EscalationProtocolsUiActions.deleteEscalationProtocol,
          EscalationProtocolsUiActions.updateEscalationProtocolTopicSubscriptions,
          EscalationProtocolsUiActions.listEscalationProtocolLevels,
          EscalationProtocolsUiActions.addEscalationProtocolLevel,
          EscalationProtocolsUiActions.updateEscalationProtocolLevel,
          EscalationProtocolsUiActions.updateEscalationProtocolLevelsOrders,

          EscalationProtocolsUiActions.deleteEscalationProtocolLevelThenUpdateLevels
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EscalationProtocolsDbActions.listEscalationProtocolsFailure,
          EscalationProtocolsDbActions.listEscalationProtocolsSuccess,
          EscalationProtocolsDbActions.loadEscalationProtocolByIdFailure,
          EscalationProtocolsDbActions.loadEscalationProtocolByIdSuccess,
          EscalationProtocolsDbActions.addEscalationProtocolFailure,
          EscalationProtocolsDbActions.addEscalationProtocolSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolSuccess,
          EscalationProtocolsDbActions.deleteEscalationProtocolFailure,
          EscalationProtocolsDbActions.deleteEscalationProtocolSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsFailure,
          EscalationProtocolsDbActions.listEscalationProtocolLevelsSuccess,
          EscalationProtocolsDbActions.listEscalationProtocolLevelsFailure,
          EscalationProtocolsDbActions.addEscalationProtocolLevelSuccess,
          EscalationProtocolsDbActions.addEscalationProtocolLevelFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersFailure,
          EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsFailure,
          EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsSuccess
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EscalationProtocolsDbActions.loadEscalationProtocolByIdSuccess,
          EscalationProtocolsDbActions.addEscalationProtocolSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolSuccess,
          EscalationProtocolsDbActions.deleteEscalationProtocolSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsSuccess,
          EscalationProtocolsDbActions.addEscalationProtocolLevelSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelSuccess,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersSuccess,
          EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EscalationProtocolsDbActions.listEscalationProtocolsFailure,
          EscalationProtocolsDbActions.loadEscalationProtocolByIdFailure,
          EscalationProtocolsDbActions.addEscalationProtocolFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolFailure,
          EscalationProtocolsDbActions.deleteEscalationProtocolFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsFailure,
          EscalationProtocolsDbActions.listEscalationProtocolLevelsFailure,
          EscalationProtocolsDbActions.addEscalationProtocolLevelFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelFailure,
          EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersFailure,
          EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private escalationProtocolsService: EscalationProtocolsService,
    private notificationService: NotificationService,
    private router: Router
  ) {}
}
