import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { CommonApiListResponse, Environment, TagCategory } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagEditorFormService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  isNameUnique(tagCategoryName: string, entityId: string): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', '0');
    params = params.append('categoryName', tagCategoryName);
    params = params.append('entityId', entityId);
    params = params.set('withParents', 'true');
    params = params.set('withChildren', 'true');
    return this.httpClient
      .get<CommonApiListResponse<TagCategory>>(`${this.environment.api.url}${this.environment.api.endpoints?.tags}`, { params })
      .pipe(map((data: CommonApiListResponse<TagCategory>) => data.page.total === 0));
  }
}
