import { Role } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminOrganizationsRolesApiActions, AdminOrganizationsRolesPageActions } from '../actions';

export const adminOrganizationsRolesApiFeatureKey = 'adminOrganizationsRolesApi';

export interface State extends EntityState<Role> {
  selectedRoleId: string | null;
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (role: Role) => role.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedRoleId: null
});

export const getSelectedId = (state: State) => state.selectedRoleId;

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsRolesPageActions.selectRoleInOrganization, (state: State, { selectedRoleId }) => ({ ...state, selectedRoleId })),
  on(AdminOrganizationsRolesApiActions.updateRoleSuccess, (state: State, { updatedRole }) => adapter.updateOne(updatedRole, state)),

  on(AdminOrganizationsRolesApiActions.addRoleToOrganizationSuccess, (state: State, { addedRole }) => adapter.addOne(addedRole, state)),
  on(AdminOrganizationsRolesApiActions.addRoleToOrganizationFailure, (state: State, { error }) => ({ ...state, error })),

  on(AdminOrganizationsRolesApiActions.removeRoleFromOrganizationSuccess, (state: State, { removedRole }) => adapter.removeOne(removedRole.id, state)),
  on(AdminOrganizationsRolesApiActions.removeRoleFromOrganizationFailure, (state: State, { error }) => ({ ...state, error })),

  on(AdminOrganizationsRolesApiActions.listRolesByOrganizationSuccess, (state: State, { roles }) => adapter.setAll(roles, state)),
  on(AdminOrganizationsRolesApiActions.listRolesByOrganizationFailure, (state: State, { error }) => ({ ...state, error }))
);
