import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const deviceCallLogsColumnSettings = [
  {
    order: 10,
    id: 'timestamp',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATE_TIME',
    catalogName: 'TIMESTAMP',
    default: true,
    sortable: true,
    sortProperty: 'timestamp',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    type: 'start center',
    width: '',
    cellWidth: ''
  },
  {
    order: 20,
    id: 'callDirection',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DIRECTION',
    catalogName: 'DIRECTION',
    default: true,
    sortable: true,
    sortProperty: 'callDirection',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    type: 'start center',
    width: '',
    cellWidth: ''
  },
  {
    order: 30,
    id: 'connectorId',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_ID',
    catalogName: 'INCOMING CONNECTOR ID',
    default: true,
    sortable: true,
    sortProperty: 'connectorId',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    type: 'start center',
    width: '',
    cellWidth: ''
  },
  {
    order: 40,
    id: 'data',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATA',
    catalogName: 'DATA',
    default: true,
    sortable: true,
    sortProperty: 'data',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    type: 'start center',
    width: '',
    cellWidth: '100'
  },
  {
    order: 50,
    id: 'events',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EVENTS',
    catalogName: 'EVENTS',
    default: true,
    sortable: true,
    sortProperty: 'events',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    type: 'start center',
    width: '',
    cellWidth: '100'
  },
  {
    order: 60,
    id: 'callDuration',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DURATION',
    catalogName: 'DURATION',
    default: true,
    sortable: true,
    sortProperty: 'callDuration',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    type: 'start center',
    width: '',
    cellWidth: '100'
  },
  {
    order: 70,
    id: 'callStatus',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.STATUS',
    catalogName: 'STATUS',
    default: true,
    sortable: true,
    sortProperty: 'callStatus',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    type: 'start center',
    width: '',
    cellWidth: '100'
  }
];
