<div class="ag-filter">
  <div class="ag-filter-wrapper ag-focus-managed">
    <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
      <mat-form-field appearance="fill" class="ag-filter-select ag-labeled ag-label-align-left ag-select">
        <mat-label>{{ 'AG_GRID.ariaFilteringOperator' | translate }}</mat-label>
        <mat-select (selectionChange)="onChangeOperator($event)" [(ngModel)]="selectedOperator" name="operator">
          @for (operator of operators; track operator.value) {
            <mat-option [value]="operator" class="ag-custom-component-popup">
              {{ 'AG_GRID.' + operator.label | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class=".ag-filter-from ag-filter-filter ag-labeled ag-label-align-left ag-text-field ag-input-field" color="accent">
        <mat-label>{{ 'AG_GRID.filterOoo' | translate }}</mat-label>
        <input #input (ngModelChange)="onChange($event)" [ngModel]="lastValue" matInput type="number" />
      </mat-form-field>
    </div>
    <div class="ag-filter-apply-panel">
      <button (click)="reset()" class="ag-standard-button ag-filter-apply-panel-button" ref="resetFilterButton" type="button">
        {{ 'AG_GRID.resetFilter' | translate }}
      </button>
    </div>
  </div>
</div>
