import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@iot-platform/iot-platform-ui';
import { RatingCellComponent } from './rating-cell.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule, IconModule],
  declarations: [RatingCellComponent],
  exports: [RatingCellComponent]
})
export class RatingCellModule {}
