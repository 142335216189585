import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TrackingData } from '../models/tracking-data.model';
import { TrackingEventId } from '../models/tracking-tag.model';
import { TrackingActions } from './tracking.actions';

@Injectable({
  providedIn: 'root'
})
export class CoreTrackingFacade {
  protected store: Store = inject(Store);

  trackEventById(eventId: TrackingEventId, trackingData?: TrackingData) {
    this.store.dispatch(TrackingActions.trackEventById(eventId, trackingData));
  }
}
