import { NgClass } from '@angular/common';
import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { BusinessProfile, UserAccount } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatIcon, NgClass, TranslateModule],
  selector: 'iot-platform-users-user-related-business-profiles',
  templateUrl: './user-related-business-profiles.component.html',
  styleUrls: ['./user-related-business-profiles.component.scss']
})
export class UserRelatedBusinessProfilesComponent {
  currentUser = input<UserAccount>();

  bPSortedByEntityLevel: Signal<BusinessProfile[]> = computed(() => {
    const user = this.currentUser();

    const sortedBPs = Object.assign([], user?.businessProfiles);
    sortedBPs.sort(SortUtil.sortByProperty('level'));
    return sortedBPs;
  });
}
