import { createReducer, on } from '@ngrx/store';
import { ProfileActions } from '../actions';

export const userProfileUiFeatureKey = 'userProfileUi';

export interface State {
  loading: boolean;
  error: any;
}

export const initialState: State = {
  loading: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(ProfileActions.loadUserProfile, (state: State) => ({ ...state, loading: true })),
  on(ProfileActions.loadUserProfileSuccess, (state: State) => ({ ...state, loading: false })),
  on(ProfileActions.loadUserProfileFailure, (state: State, { error }) => ({ ...state, loading: false, error })),
  on(ProfileActions.saveUserProfile, (state: State) => ({ ...state, loading: true })),
  on(ProfileActions.saveUserProfileSuccess, (state: State) => ({ ...state, loading: false })),
  on(ProfileActions.saveUserProfileFailure, (state: State, { error }) => ({ ...state, loading: false, error }))
);

export const getError = (state: State) => state.error;
export const getLoading = (state: State) => state.loading;
