<div (click)="onFavoriteViewClicked()" class="favorite-view-card" fxLayout="row" fxLayoutAlign="start stretch" matRipple>
  <div class="favorite-view-text-info" fxFlex="150px" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div [innerHTML]="favoriteView.name" class="favorite-view-name" fxLayout="row" fxLayoutAlign="start center"></div>
    <div fxLayout="row">
      @if (favoriteView.gridId) {
        <mat-icon class="favorite-view-mv-icon">link</mat-icon>
      }
      <div class="favorite-view-mv" fxLayout="row" fxLayoutAlign="end center" style="flex: 1">
        <span>{{ favoriteView.masterView | translate }}</span>
        <mat-icon [svgIcon]="favoriteView.masterView?.slice(0, -1)" class="favorite-view-mv-icon"></mat-icon>
      </div>
    </div>
  </div>
  <div [ngStyle]="{ 'background-color': favoriteView.color }" class="favorite-view-count" fxFlex="80px" fxLayout="row" fxLayoutAlign="center center">
    @if (favoriteView.loading) {
      <mat-progress-spinner [diameter]="26" mode="indeterminate"></mat-progress-spinner>
    } @else {
      <span>{{ favoriteView.count | numberFormat }}</span>
    }
  </div>
</div>
