import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatEvent, IotAction, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetEvent, AssetVariable, Concept, Device, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';
import { Topic } from '@iot-platform/models/ocm';
import { Observable, Subscription } from 'rxjs';
import { TopicsFacade } from '../../../../../../on-call-management/src/lib/features/topics/+state/topics.facade';
import { NavigationApi } from '../../containers/+state/navigation.api';
import { AssetEventsApi } from '../../features/asset-events/+state/asset-events.api';
import { DeviceEventsApi } from '../../features/device-events/+state/device-events.api';
import { EventDetailPopupService } from './services/event-detail-popup.service';

@Component({
  selector: 'iot4bos-ui-event-detail-popup',
  templateUrl: './event-detail-popup.component.html',
  styleUrls: ['./event-detail-popup.component.scss']
})
export class EventDetailPopupComponent implements OnInit, OnDestroy {
  event?: any; // AssetEvent | DeviceEvent;

  eventSite?: Site;
  isSiteLoaded?: boolean;

  eventAsset?: Asset;
  isAssetLoaded?: boolean;

  eventAssetVariable?: AssetVariable;
  isAssetVariableLoaded?: boolean;

  eventDevice?: Device;
  isDeviceLoaded?: boolean;

  eventDeviceVariable?: DeviceVariable;
  isDeviceVariableLoaded?: boolean;

  eventLogs: Log[] = [];
  eventLogs$!: Observable<Log[]>;
  isLogsLoaded = false;

  eventTags?: TagCategory[] = [];
  isTagsLoaded?: boolean;

  eventRelatedTopics?: Topic[];
  eventRelatedTopicsLoaded = false;

  subscriptions: Subscription[] = [];

  @Output() updateStatus: EventEmitter<string> = new EventEmitter<string>();
  @Output() addComment: EventEmitter<string> = new EventEmitter<string>();
  @Output() navigateToSite: EventEmitter<Site> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gridId: string;
      event: AssetEvent;
      eventType: string;
      filteredBy: string;
      canUpdateEvent: boolean;
    },
    private navigationApi: NavigationApi,
    private topicsFacade: TopicsFacade,
    private assetEventsApi: AssetEventsApi,
    private deviceEventsApi: DeviceEventsApi,
    private eventDetailPopupService: EventDetailPopupService
  ) {}

  ngOnInit() {
    if (this.data && this.data.event && this.data.eventType) {
      this.setDataByEventType(this.data.event, this.data.eventType, this.data.filteredBy);
      this.subscriptions.push(
        this.eventDetailPopupService.getTopicsByEventId(this.data.event.id).subscribe((topics) => {
          this.eventRelatedTopics = topics;
          this.eventRelatedTopicsLoaded = true;
        })
      );
    }
  }

  setDataByEventType(event, eventType: string, filteredBy?: string): void {
    this.event = this.data.event;
    this.setEventTags(event);

    if (eventType === 'asset-events') {
      this.subscriptions.push(
        this.assetEventsApi.site$.subscribe((site) => (this.eventSite = site)),
        this.assetEventsApi.siteLoaded$.subscribe((isSiteLoaded) => (this.isSiteLoaded = isSiteLoaded)),
        this.assetEventsApi.asset$.subscribe((eventAsset) => (this.eventAsset = eventAsset)),
        this.assetEventsApi.assetLoaded$.subscribe((isAssetLoaded) => (this.isAssetLoaded = isAssetLoaded)),
        this.assetEventsApi.assetVariable$.subscribe((eventAssetVariable) => (this.eventAssetVariable = eventAssetVariable)),
        this.assetEventsApi.assetVariableLoaded$.subscribe((isAssetVariableLoaded) => (this.isAssetVariableLoaded = isAssetVariableLoaded)),
        this.assetEventsApi.device$.subscribe((eventDevice) => (this.eventDevice = eventDevice)),
        this.assetEventsApi.deviceLoaded$.subscribe((isDeviceLoaded) => (this.isDeviceLoaded = isDeviceLoaded)),
        this.assetEventsApi.deviceVariable$.subscribe((eventDeviceVariable) => (this.eventDeviceVariable = eventDeviceVariable)),
        this.assetEventsApi.deviceVariableLoaded$.subscribe((isDeviceVariableLoaded) => (this.isDeviceVariableLoaded = isDeviceVariableLoaded))
      );

      if (!filteredBy) {
        this.eventLogs$ = this.assetEventsApi.assetEventComments$;
        this.subscriptions.push(
          this.assetEventsApi.selectedAssetEvent$(this.data.gridId, this.data.event.id).subscribe((ev) => (this.event = ev)),
          this.assetEventsApi.assetEventComments$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.assetEventsApi.assetEventCommentsLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }

      if (filteredBy === 'site') {
        this.eventLogs$ = this.navigationApi.assetEventsBySiteLogsByAssetEventId$;

        this.subscriptions.push(
          this.navigationApi.selectedAssetEventBySite$.subscribe((ev) => (this.event = ev)),
          this.navigationApi.assetEventsBySiteLogsByAssetEventId$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.navigationApi.assetEventsBySiteLogsByAssetEventIdLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }

      if (filteredBy === 'topic') {
        this.eventLogs$ = this.topicsFacade.assetEventsByTopicLogsByAssetEventId$;

        this.subscriptions.push(
          this.topicsFacade.selectedAssetEventByTopic$.subscribe((ev) => (this.event = ev)),
          this.topicsFacade.assetEventsByTopicLogsByAssetEventId$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.topicsFacade.assetEventsByTopicLogsByAssetEventIdLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }

      if (filteredBy === 'asset') {
        this.eventLogs$ = this.navigationApi.assetEventsByAssetLogsByAssetEventId$;

        this.subscriptions.push(
          this.navigationApi.selectedAssetEventByAsset$.subscribe((ev) => (this.event = ev)),

          this.deviceEventsApi.selectedDeviceEvent$(this.data.gridId, this.data.event.id).subscribe((ev) => {
            this.event = ev;
          }),
          this.navigationApi.assetEventsByAssetLogsByAssetEventId$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.navigationApi.assetEventsByAssetLogsByAssetEventIdLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }
    }

    if (eventType === 'device-events') {
      this.subscriptions.push(
        this.deviceEventsApi.site$.subscribe((site) => (this.eventSite = site)),
        this.deviceEventsApi.siteLoaded$.subscribe((isSiteLoaded) => (this.isSiteLoaded = isSiteLoaded)),
        this.deviceEventsApi.asset$.subscribe((eventAsset) => (this.eventAsset = eventAsset)),
        this.deviceEventsApi.assetLoaded$.subscribe((isAssetLoaded) => (this.isAssetLoaded = isAssetLoaded)),
        this.deviceEventsApi.assetVariable$.subscribe((eventAssetVariable) => (this.eventAssetVariable = eventAssetVariable)),
        this.deviceEventsApi.assetVariableLoaded$.subscribe((isAssetVariableLoaded) => (this.isAssetVariableLoaded = isAssetVariableLoaded)),
        this.deviceEventsApi.device$.subscribe((eventDevice) => (this.eventDevice = eventDevice)),
        this.deviceEventsApi.deviceLoaded$.subscribe((isDeviceLoaded) => (this.isDeviceLoaded = isDeviceLoaded)),
        this.deviceEventsApi.deviceVariable$.subscribe((eventDeviceVariable) => (this.eventDeviceVariable = eventDeviceVariable)),
        this.deviceEventsApi.deviceVariableLoaded$.subscribe((isDeviceVariableLoaded) => (this.isDeviceVariableLoaded = isDeviceVariableLoaded))
      );

      if (!filteredBy) {
        this.eventLogs$ = this.deviceEventsApi.deviceEventComments$;

        this.subscriptions.push(
          this.deviceEventsApi.selectedDeviceEvent$(this.data.gridId, this.data.event.id).subscribe((ev) => {
            this.event = ev;
          }),
          this.deviceEventsApi.deviceEventComments$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.deviceEventsApi.deviceEventCommentsLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }

      if (filteredBy === 'site') {
        this.eventLogs$ = this.navigationApi.deviceEventsBySiteLogsByDeviceEventId$;

        this.subscriptions.push(
          this.navigationApi.selectedDeviceEventBySite$.subscribe((ev) => (this.event = ev)),
          this.navigationApi.deviceEventsBySiteLogsByDeviceEventId$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.navigationApi.deviceEventsBySiteLogsByDeviceEventIdLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }

      if (filteredBy === 'topic') {
        this.eventLogs$ = this.topicsFacade.deviceEventsByTopicLogsByDeviceEventId$;

        this.subscriptions.push(
          this.topicsFacade.selectedDeviceEventByTopic$.subscribe((ev) => (this.event = ev)),
          this.topicsFacade.deviceEventsByTopicLogsByDeviceEventId$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.topicsFacade.deviceEventsByTopicLogsByDeviceEventIdLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }

      if (filteredBy === 'device') {
        this.eventLogs$ = this.navigationApi.deviceEventsByDeviceLogsByDeviceEventId$;

        this.subscriptions.push(
          this.navigationApi.selectedDeviceEventByDevice$.subscribe((ev) => (this.event = ev)),
          this.navigationApi.deviceEventsByDeviceLogsByDeviceEventId$.subscribe((eventLogs) => (this.eventLogs = this.filterLogs(eventLogs))),
          this.navigationApi.deviceEventsByDeviceLogsByDeviceEventIdLoaded$.subscribe((isLogsLoaded) => (this.isLogsLoaded = isLogsLoaded))
        );
      }
    }
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.addComment.emit(event.value as string);
        break;
      default:
        break;
    }
  }

  filterLogs(logs: Log[]): Log[] {
    return logs.filter((log: Log) => log.concept === Concept.EVENT);
  }

  setEventTags(event: AssetEvent): void {
    if (!!event.tags) {
      Object.keys(event.tags).forEach((tagCategoryId) => this.eventTags?.push(event.tags[tagCategoryId]));
    }
    this.isTagsLoaded = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
