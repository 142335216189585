import { Action, createReducer, on } from '@ngrx/store';
import { AssetEventsByTopicLogsDbActions, AssetEventsByTopicLogsUiActions } from '../../actions';
import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
//
export const assetEventsByTopicLogsUiFeatureKey = 'assetEventsByTopicLogsUi';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: unknown;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null
};

const assetEventsByTopicLogsUiReducer = createReducer(
  initialState,
  on(AssetEventsByTopicLogsUiActions.loadLogsByAssetEvent, (state: State): State => ({ ...state, loading: true, loaded: false, error: null })),
  on(AssetEventsByTopicLogsDbActions.loadLogsByAssetEventSuccess, (state: State): State => ({ ...state, loading: false, loaded: true })),
  on(AssetEventsByTopicLogsDbActions.loadLogsByAssetEventFailure, (state: State, { error }): State => ({ ...state, loading: false, loaded: false, error })),
  // ****
  on(AssetEventsByTopicLogsUiActions.createLogByAssetEvent, (state: State): State => ({ ...state, loading: true, loaded: false, error: null })),
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventSuccess, (state: State): State => ({ ...state, loading: false, loaded: true })),
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventFailure, (state: State, { error }): State => ({ ...state, loading: false, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (): State => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByTopicLogsUiReducer(state, action);
}

export const getLogsLoading = (state: State) => state.loading;
export const getLogsLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
