import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { SortEntitiesUtil } from '@iot-platform/iot-platform-utils';

import { Organization } from '@iot-platform/models/common';

interface OrganizationNode extends Organization {
  children?: OrganizationNode[];
}

interface OrganizationFlatNode extends OrganizationNode {
  expandable: boolean;
  treeLevel: number;
}

@Component({
  selector: 'iot4bos-ui-backoffice-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss', '../../../../style/admin.style.scss']
})
export class OrganizationsListComponent implements OnChanges {
  @Input() organizations: Organization[];
  @Input() selectedOrganization: Organization;
  @Input() canCreateOrganization: boolean;
  @Input() canUpdateOrganization: boolean;
  @Input() isTopLevelAdmin = false;

  @Output() selectOrganization: EventEmitter<Organization> = new EventEmitter();
  @Output() addOrganization: EventEmitter<Organization> = new EventEmitter();
  @Output() lockUnlockOrganization: EventEmitter<{ organization: Organization; isLocked: boolean }> = new EventEmitter<{
    organization: Organization;
    isLocked: boolean;
  }>();

  selectOrgId: string;
  expandedNodes;
  treeControl = new FlatTreeControl<OrganizationFlatNode>(
    (node) => node.treeLevel,
    (node) => node.expandable
  );

  transformer = (node: OrganizationNode, treeLevel: number) => ({
    expandable: !!node.children && node.children.length > 0,
    treeLevel,
    ...node
  });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    new MatTreeFlattener(
      this.transformer,
      (node) => node.treeLevel,
      (node) => node.expandable,
      (node) => node.children
    )
  );

  hasChild = (_: number, node: OrganizationFlatNode) => node.expandable;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('organizations') && changes.organizations.currentValue) {
      this.dataSource.data = SortEntitiesUtil.arrayToTree(changes.organizations.currentValue);

      if (this.selectOrgId) {
        this.restoreExpandedNodes();
        this.expandParents(this.treeControl.dataNodes.find((node) => node.id === this.selectOrgId));
      }
    }

    if (changes.hasOwnProperty('selectedOrganization') && changes.selectedOrganization.currentValue) {
      this.selectOrgId = changes.selectedOrganization.currentValue.id;

      if (this.treeControl.dataNodes) {
        this.expandParents(this.treeControl.dataNodes.find((node) => node.id === this.selectOrgId));
      }
    }
  }

  expandParents(node: OrganizationFlatNode) {
    const parent = this.getParent(node);
    this.treeControl.expand(parent);
    this.treeControl.expand(node);

    if (parent && parent.level > 0) {
      this.expandParents(parent);
    }
  }

  getParent(node: OrganizationFlatNode) {
    const currentLevel = this.treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  onSelectOrganization(organization: any) {
    if (this.selectOrgId !== organization.id) {
      this.selectOrgId = organization.id;
      this.treeControl.expand(organization);
      this.saveExpandedNodes();
      this.selectOrganization.emit(this.organizations.find((el) => el.id === organization.id));
      this.selectedOrganization = organization;
    }
  }

  onAddOrganization(organizationId: string) {
    this.addOrganization.emit(this.organizations.find((el) => el.id === organizationId));
  }

  saveExpandedNodes() {
    this.expandedNodes = new Array<OrganizationFlatNode>();
    this.treeControl.dataNodes.forEach((node) => {
      if (node.children.length && this.treeControl.isExpanded(node)) {
        this.expandedNodes.push(node);
      }
    });
  }

  restoreExpandedNodes() {
    if (this.expandedNodes) {
      this.expandedNodes.forEach((node) => {
        this.treeControl.expand(this.treeControl.dataNodes.find((n) => n.id === node.id));
      });
    }
  }

  onLockUnlockOrganization(organization: Organization, isLocked: boolean): void {
    if (this.isTopLevelAdmin) {
      this.lockUnlockOrganization.emit({
        organization,
        isLocked
      });
    }
  }
}
