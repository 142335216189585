export enum FormulaType {
  EQUAL = 'equal',
  MIN_MAX = 'min-max',
  MIN_MAX_ADVANCED = 'min-max-advanced',
  SLOPE_SHIFT = 'slope-shift',
  LINEARIZATION = 'linearization',
  CONSTANT = 'constant',
  CONSUMPTION_FOR_PG = 'consumption-for-pg',
  SHORTFALL = 'shortfall',
  AUTONOMY_FOR_PG = 'autonomy-for-pg',
  SUM = 'sum'
}
