<div class="detail-card" fxLayoutAlign="start center">
  <div fxFlex fxLayout="row">
    <div>
      <mat-icon>filter_{{ followedNumber() }}</mat-icon>
    </div>
    @if (!followedVariable()) {
      <span class="no-var">-</span>
    } @else {
      <div class="detail-card-item" fxLayout="column">
        <div>
          <span>{{ followedVariable()?.name | infoDisplay }}</span>
        </div>
        <div fxFlex fxLayout="row">
          <span
            class="fw-600"> {{ followedVariable() | valueUnitFormat }} </span>
          <span>{{ followedVariable()?.lastValue?.datetime | dateFormat | infoDisplay }}</span>
        </div>
      </div>
    }
  </div>
</div>
