import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import { AbstractAppShellService } from '@iot-platform/feature/app-shell';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { ReleaseNotesComponent } from '@iot-platform/iot-platform-ui';
import { HashUtils, SortUtil } from '@iot-platform/iot-platform-utils';
import { AppName, BusinessProfile, FavoriteView } from '@iot-platform/models/common';
import { SidenavMenuBlock, SidenavMenuItem } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { NavigationTreeService as BaseNavigationTreeService } from '@iot-platform/shared/services';
import { UserPreferencesService } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppShellService extends AbstractAppShellService {
  protected readonly navigationTreeService: BaseNavigationTreeService = inject(BaseNavigationTreeService);
  protected readonly store: Store = inject(Store);
  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  protected readonly userPreferencesService: UserPreferencesService = inject(UserPreferencesService);
  protected readonly dialog: MatDialog = inject(MatDialog);
  protected readonly router: Router = inject(Router);

  getCurrentBusinessProfile(): Observable<BusinessProfile> {
    return this.store.select(fromAuth.selectSelectedBusinessProfileForAccount) as Observable<BusinessProfile>;
  }

  closePreferences(): void {
    this.userPreferencesService.closePreferences();
  }

  openReleaseNotes(): void {
    this.dialog.open(ReleaseNotesComponent, {
      width: '1200px',
      data: { appName: AppName.IOT4BOS }
    });
  }

  openMyProfile(): void {
    this.router.navigate(['my-profile']);
  }

  onBusinessProfileReady(): void {
    this.store.dispatch(GridsDbActions.loadGrids({}));
  }

  onBusinessProfileChange(): void {
    this.store.dispatch(GridsDbActions.resetState());
  }

  getNavigationTree(): Observable<SidenavMenuBlock[]> {
    return combineLatest([
      this.navigationTreeService.getNavigationTree(),
      this.store.select(fromAuth.selectPrivileges),
      this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewSites),
      this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssets),
      this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDevices),
      this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDeviceEvents),
      this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssetEvents)
    ]).pipe(
      map(([navTree, _, fvSites, fvAssets, fvDevices, fvDeviceEvents, fvAssetEvents]) => {
        const result = navTree.map((block) => {
          block.items.forEach((item) => {
            item.level = 0;
            switch (item.name) {
              case 'TAB_NAV.SITES':
                if (fvSites.length) {
                  item.submenu = [
                    {
                      items: this.getSubmenuItems(fvSites, '/sites', [[{ name: 'SITE', right: 'READ' }]])
                    }
                  ];
                } else {
                  item.submenu = [];
                }
                break;
              case 'TAB_NAV.ASSETS':
                if (fvAssets.length) {
                  item.submenu = [
                    {
                      items: this.getSubmenuItems(fvAssets, '/assets', [[{ name: 'ASSET', right: 'READ' }]])
                    }
                  ];
                } else {
                  item.submenu = [];
                }
                break;
              case 'TAB_NAV.DEVICES':
                if (fvDevices.length) {
                  item.submenu = [
                    {
                      items: this.getSubmenuItems(fvDevices, '/devices', [[{ name: 'DEVICE', right: 'READ' }]])
                    }
                  ];
                } else {
                  item.submenu = [];
                }
                break;
              case 'TAB_NAV.ASSET_EVENTS':
                if (fvAssetEvents.length) {
                  item.submenu = [
                    {
                      items: this.getSubmenuItems(fvAssetEvents, '/asset-events', [[{ name: 'EVENT', right: 'READ' }], [{ name: 'ASSET', right: 'READ' }]])
                    }
                  ];
                } else {
                  item.submenu = [];
                }
                break;
              case 'TAB_NAV.DEVICE_EVENTS':
                if (fvDeviceEvents.length) {
                  item.submenu = [
                    {
                      items: this.getSubmenuItems(fvDeviceEvents, '/device-events', [[{ name: 'EVENT', right: 'READ' }], [{ name: 'DEVICE', right: 'READ' }]])
                    }
                  ];
                } else {
                  item.submenu = [];
                }
                break;
            }
          });
          return { ...block, id: HashUtils.simpleHashFromObj(block) };
        });
        return this.authorizationService.getNavigationTree(result);
      })
    );
  }

  returnHome(): void {
    this.router.navigate(['/', 'home']);
  }

  private getSubmenuItems(
    favoriteViews: FavoriteView[],
    path: string,
    concepts: {
      name: string;
      right: string;
    }[][]
  ): SidenavMenuItem[] {
    return favoriteViews
      .map((fv) => ({
        name: fv.name ?? '',
        icon: fv.shared ? 'business_profile' : 'person',
        rightIcons: fv.gridId ? ['link'] : [],
        concepts,
        path,
        handleClick: this.handleClick(fv).bind(this),
        level: 1,
        leftBorderColor: fv.color,
        disableActiveState: true
      }))
      .sort(SortUtil.sortByName);
  }

  private handleClick(favoriteView: FavoriteView) {
    return () => {
      this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: favoriteView.masterView as string, favoriteView }));
      this.store.dispatch(
        GridsDbActions.selectGridAndLoadData({
          gridId: favoriteView.gridId as string,
          masterview: favoriteView.masterView as string,
          filters: favoriteView.filters
        })
      );
    };
  }
}
