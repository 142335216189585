<mat-drawer-container fxFlexFill hasBackdrop="false">
  <mat-drawer-content fxFlexFill fxLayout="column" fxLayoutAlign="start center">
    <div class="map" fxFlexFill fxLayout="column" fxLayoutAlign="start center" style>
      <iot-platform-maps-map-spinner [loading]="loading()"></iot-platform-maps-map-spinner>
      <div
        (leafletMapReady)="onMapReady($event)"
        [leafletBaseLayers]="baseLayers"
        [leafletOptions]="options"
        class="map__container"
        fxFlex="auto"
        leaflet
      ></div>
    </div>
  </mat-drawer-content>
  <mat-drawer [opened]="displayPanelInfo()" class="map-drawer" mode="over" position="end">
    <iot-platform-maps-map-panel-info
      (close)="onClosePanelInfo()"
      (displaySegments)="onDisplaySegments($event)"
      (selectElement)="onElementSelection($event)"
      [feature]="selectedFeature()"
    >
    </iot-platform-maps-map-panel-info>
  </mat-drawer>
</mat-drawer-container>
