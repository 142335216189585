import { inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-expect-error "linkify-string uses a default export"
import linkifyStr from 'linkify-string';

@Pipe({
  name: 'linkify',
  standalone: true
})
export class LinkifyPipe implements PipeTransform {
  private readonly sanitizer: DomSanitizer = inject(DomSanitizer);

  transform(content: string | null | undefined) {
    if (content) {
      return this.sanitizer.sanitize(
        SecurityContext.HTML,
        linkifyStr(content, {
          rel: 'noopener',
          target: '_blank'
        }) ?? ''
      );
    }

    return content;
  }
}
