import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import {
  BaseUser,
  BusinessProfile,
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  Environment,
  Pagination,
  UserAccount
} from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly http: HttpClient = inject(HttpClient);

  getUsers(request: CommonApiRequest): Observable<CommonApiResponse<BaseUser, Pagination>> {
    const params: HttpParams = ApiHelpers.getHttpParams(request);
    return this.http
      .get<CommonApiListResponse<BaseUser>>(`${this.environment.api.url}${this.environment.api.endpoints.users}`, { params })
      .pipe(map((users: CommonApiListResponse<BaseUser>) => ApiHelpers.getHttpResponse<BaseUser>(users)));
  }

  getUserById(userId: string): Observable<UserAccount> {
    return this.http.get<UserAccount>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${userId}`);
  }

  addUser(user: UserAccount): Observable<UserAccount> {
    return this.http.post<UserAccount>(`${this.environment.api.url}${this.environment.api.endpoints.users}`, user);
  }

  updateUser(user: UserAccount): Observable<UserAccount> {
    return this.http.patch<UserAccount>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}`, user);
  }

  enableUser(user: UserAccount): Observable<UserAccount> {
    return this.http
      .put<void>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}/activate`, {})
      .pipe(switchMap(() => this.getUserById(user.id)));
  }

  disableUser(user: UserAccount): Observable<UserAccount> {
    return this.http
      .put<void>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}/disable`, {})
      .pipe(switchMap(() => this.getUserById(user.id)));
  }

  resendValidationLink(user: UserAccount): Observable<UserAccount> {
    return this.http
      .put<void>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}/reset`, {})
      .pipe(switchMap(() => this.getUserById(user.id)));
  }

  isUserEmailUnique(email: string): Observable<boolean> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('page', '0');
    params = params.set('limit', '0');
    params = params.set('exactEmail', email);
    return this.http
      .get<CommonApiListResponse<UserAccount>>(`${this.environment.api.url}${this.environment.api.endpoints.users}`, { params })
      .pipe(map((response: CommonApiListResponse<UserAccount>) => response.page.total === 0));
  }

  getBusinessProfilesByUserId(userId: string): Observable<BusinessProfile[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('userId', userId);

    return this.http
      .get<CommonApiListResponse<BusinessProfile>>(`${this.environment.api.url}${this.environment.api.endpoints.businessProfiles}`, { params })
      .pipe(map((response) => response.content));
  }

  getCarmUrl(): string {
    return this.environment.carmUrl ?? '';
  }
}
