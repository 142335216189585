import { FormulaType } from '@iot-platform/models/common';

export interface Formula {
  model: FormulaType | null;
  parameters: FormulaParameters | null;
  srcVariables: FormulaSourceVariables;
  isActive?: boolean; // TODO: MAKE IT REQUIRED WHEN BREAK FORMULA TICKET IS DONE
  version?: number | null;
}

export const ASSET_VARIABLE_ALL_FORMULA_TYPES = [
  FormulaType.EQUAL,
  FormulaType.MIN_MAX,
  FormulaType.MIN_MAX_ADVANCED,
  FormulaType.SLOPE_SHIFT,
  FormulaType.LINEARIZATION,
  FormulaType.CONSTANT,
  FormulaType.CONSUMPTION_FOR_PG,
  FormulaType.SHORTFALL,
  FormulaType.AUTONOMY_FOR_PG,
  FormulaType.SUM
];

export const FORMULA_TYPES_WITH_DEVICE_VARIABLE_SOURCE = [
  FormulaType.EQUAL,
  FormulaType.MIN_MAX,
  FormulaType.MIN_MAX_ADVANCED,
  FormulaType.SLOPE_SHIFT,
  FormulaType.LINEARIZATION
];

export const FORMULA_TYPES_WITH_ASSET_VARIABLE_SOURCE = [FormulaType.CONSUMPTION_FOR_PG, FormulaType.SHORTFALL, FormulaType.AUTONOMY_FOR_PG, FormulaType.SUM];

// Parameters

export type FormulaParameters =
  | FormulaDefaultParameters
  | ConstantParameters
  | MinMaxParameters
  | MinMaxAdvancedParameters
  | SlopeShiftParameters
  | LinearizationParameters
  | SumParameters;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormulaDefaultParameters {}

export interface ConstantParameters {
  constantValue: number;
}

export interface MinMaxParameters {
  maxRead: number;
  maxDisplay: number;
}

export interface MinMaxAdvancedParameters {
  minRead: number;
  maxRead: number;
  minDisplay: number;
  maxDisplay: number;
}

export interface LinearizationParameters {
  headHeight: number;
  diameter: number;
  length: number;
  density: number;
  maxDisplay: number;
  maxRead: number;
}

export interface SlopeShiftParameters {
  slope: number;
  shift: number;
}

export interface SumParameters {
  toleranceInMinutes: number;
}

// SrcVariables

type FormulaSourceVariableType = 'device-variable' | 'asset-variable';

export interface FormulaSourceVariable {
  name: string;
  originId: string;
  type: FormulaSourceVariableType;
  variableId: string;
}

export type FormulaSourceVariables = DefaultFormulaSourceVariable | AutonomyForPGSrcVariables | ConsumptionForPGSrcVariables | ShortfallSrcVariables;

export interface DefaultFormulaSourceVariable {
  [key: string]: FormulaSourceVariable;
}

// Consumption
export interface ConsumptionForPGSrcVariables {
  'left-pressure': ConsumptionForPGSrcVariableLeftPressure;
  'right-pressure': ConsumptionForPGSrcVariableRightPressure;
  'left-volume': ConsumptionForPGSrcVariableLeftVolume;
  'right-volume': ConsumptionForPGSrcVariableRightVolume;
}

interface ConsumptionForPGSrcVariableLeftPressure extends FormulaSourceVariable {
  name: 'left-pressure';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface ConsumptionForPGSrcVariableLeftVolume extends FormulaSourceVariable {
  name: 'left-volume';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface ConsumptionForPGSrcVariableRightPressure extends FormulaSourceVariable {
  name: 'right-pressure';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface ConsumptionForPGSrcVariableRightVolume extends FormulaSourceVariable {
  name: 'right-volume';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

// Autonomy
export interface AutonomyForPGSrcVariables {
  pressure: AutonomyForPGSrcVariablePressure;
  volume: AutonomyForPGSrcVariableVolume;
  consumption: AutonomyForPGSrcVariableConsumption;
  'minimum-usable-pressure': AutonomyForPGSrcVariableMinimumUsablePressure;
}

interface AutonomyForPGSrcVariablePressure extends FormulaSourceVariable {
  name: 'pressure';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface AutonomyForPGSrcVariableVolume extends FormulaSourceVariable {
  name: 'volume';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface AutonomyForPGSrcVariableConsumption extends FormulaSourceVariable {
  name: 'consumption';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface AutonomyForPGSrcVariableMinimumUsablePressure extends FormulaSourceVariable {
  name: 'minimum-usable-pressure';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

// Shortfall
export interface ShortfallSrcVariables {
  'contractual-flow': ShortfallSrcVariableContractualFlow;
  'production-flow': ShortfallSrcVariableProductionFlow;
  'total-flow': ShortfallSrcVariableTotalFlow;
}

interface ShortfallSrcVariableContractualFlow extends FormulaSourceVariable {
  name: 'contractual-flow';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface ShortfallSrcVariableProductionFlow extends FormulaSourceVariable {
  name: 'production-flow';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}

interface ShortfallSrcVariableTotalFlow extends FormulaSourceVariable {
  name: 'total-flow';
  variableId: string;
  type: 'asset-variable';
  originId: string;
}
