@if (mostRecentCommand()) {
  <div
    [matTooltip]="tooltipMessage()"
    matTooltipPosition="above"
    matTooltipClass="regular-tooltip"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    class="dlcs-container dlcs-step-ongoing"
    [ngClass]="{
      'dlcs-step-validated': mostRecentCommand().state === 'completed',
      'dlcs-step-error': mostRecentCommand().state === 'error'
    }"
  >
    @if (mostRecentCommand().state === 'ongoing') {
      <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
    } @else {
      <mat-icon [svgIcon]="mostRecentCommand().iconToDisplay?.name" class="dlcs-icon"></mat-icon>
    }
    <div fxLayout="column">
      <div class="capitalize-first-letter">{{ 'DEVICES.COMMANDS_STATUSES.LAST_OUTGOING_CALL' | translate }}{{ mostRecentCommand().commandName }}</div>
      <div class="capitalize-first-letter">
        {{
          'DEVICES.COMMANDS_STATUSES.' + (mostRecentCommand().state === 'error' ? 'error' : mostRecentCommand().commandStatus.status.toUpperCase()) | translate
        }}
        :
        {{ mostRecentCommand().commandStatus.timestamp | dateFormat }}
      </div>
    </div>
  </div>
}
