import { TrackingEventIds as BaseTrackingEventIds } from '../../common/tracking/tracking-event-ids.model';

export const TrackingEventIds = {
  ...BaseTrackingEventIds,
  // ASSET TEMPLATE SHELL
  OPEN_ASSET_TEMPLATE_CREATE_DIALOG: 'open_asset_template_create_dialog',
  CREATE_ASSET_TEMPLATE: 'create_asset_template',
  REFRESH_ASSET_TEMPLATE_SHELL_DATA: 'refresh_asset_template_shell_data',
  OPEN_ASSET_TEMPLATE_DETAILS_POPUP: 'open_asset_template_details_popup',
  NAVIGATE_TO_ASSET_TEMPLATE_OVERVIEW_PAGE_FROM_POPUP: 'navigate_to_asset_template_overview_page_from_popup',
  NAVIGATE_TO_ASSET_TEMPLATE_OVERVIEW_PAGE_FROM_GRID: 'navigate_to_asset_template_overview_page_from_grid',
  OPEN_ASSET_TEMPLATE_UPDATE_DIALOG_FROM_SHELL: 'open_asset_template_update_dialog_from_shell',
  UPDATE_ASSET_TEMPLATE_FROM_SHELL: 'update_asset_template_from_shell',
  OPEN_ASSET_TEMPLATE_DELETE_DIALOG_FROM_SHELL: 'open_asset_template_delete_dialog_from_shell',
  DELETE_ASSET_TEMPLATE_FROM_SHELL: 'delete_asset_template_from_shell',
  // ASSET TEMPLATE OVERVIEW
  OPEN_ASSET_TEMPLATE_VARIABLE_CREATE_DIALOG: 'open_asset_template_variable_create_dialog',
  CREATE_ASSET_TEMPLATE_VARIABLE: 'create_asset_template_variable',
  OPEN_ASSET_TEMPLATE_VARIABLE_CONFIGURE_DIALOG_FROM_GRID: 'open_asset_template_variable_configure_dialog_from_grid',
  OPEN_ASSET_TEMPLATE_VARIABLE_CONFIGURE_DIALOG_FROM_POPUP: 'open_asset_template_variable_configure_dialog_from_popup',
  CONFIGURE_ASSET_TEMPLATE_VARIABLE: 'configure_asset_template_variable',
  OPEN_ASSET_TEMPLATE_UPDATE_DIALOG_FROM_OVERVIEW: 'open_asset_template_update_dialog_from_overview',
  UPDATE_ASSET_TEMPLATE_FROM_OVERVIEW: 'update_asset_template_from_overview',
  OPEN_TEMPLATE_UPDATE_DIALOG: 'open_template_update_dialog',
  UPDATE_TEMPLATE: 'update_template',
  OPEN_ASSET_TEMPLATE_PRODUCTS_CONFIGURE_DIALOG: 'open_asset_template_products_configure_dialog',
  CONFIGURE_ASSET_TEMPLATE_PRODUCTS: 'configure_asset_template_products',
  OPEN_ASSET_TEMPLATE_PRODUCTS_DELETE_DIALOG: 'open_asset_template_products_delete_dialog',
  DELETE_ASSET_TEMPLATE_PRODUCTS: 'delete_asset_template_products',
  OPEN_ASSET_TEMPLATE_OPTIONAL_PROPERTIES_CONFIGURE_DIALOG: 'open_asset_template_optional_properties_configure_dialog',
  CONFIGURE_ASSET_TEMPLATE_OPTIONAL_PROPERTIES: 'configure_asset_template_optional_properties',
  OPEN_ASSET_TEMPLATE_TAGS_CONFIGURE_DIALOG: 'open_asset_template_tags_configure_dialog',
  CONFIGURE_ASSET_TEMPLATE_TAGS: 'configure_asset_template_tags',
  OPEN_ASSET_TEMPLATE_FOLLOWED_VARIABLES_ADD_DIALOG: 'open_asset_template_followed_variables_add_dialog',
  ADD_ASSET_TEMPLATE_FOLLOWED_VARIABLES: 'add_asset_template_followed_variables',
  REMOVE_ASSET_TEMPLATE_FOLLOWED_VARIABLES: 'remove_asset_template_followed_variables',
  OPEN_ASSET_TEMPLATE_VARIABLE_UPDATE_DIALOG: 'open_asset_template_variable_update_dialog',
  UPDATE_ASSET_TEMPLATE_VARIABLE: 'update_asset_template_variable',
  OPEN_ASSET_TEMPLATE_VARIABLE_DELETE_DIALOG: 'open_asset_template_variable_delete_dialog',
  DELETE_ASSET_TEMPLATE_VARIABLE: 'delete_asset_template_variable',
  OPEN_ASSET_TEMPLATE_VARIABLE_BULK_DELETE_DIALOG: 'open_asset_template_variable_bulk_delete_dialog',
  BULK_DELETE_ASSET_TEMPLATE_VARIABLE: 'bulk_delete_asset_template_variable',
  ASSET_TEMPLATE_OVERVIEW_TAB_CHANGE: 'asset_template_overview_tab_change',
  OPEN_ASSET_TEMPLATE_DELETE_DIALOG_FROM_OVERVIEW: 'open_asset_template_delete_dialog_from_overview',
  DELETE_ASSET_TEMPLATE_FROM_OVERVIEW: 'delete_asset_template_from_overview',
  OPEN_ASSET_TEMPLATE_AUDIT_TRAIL_POPUP: 'open_asset_template_audit_trail_popup',
  REFRESH_ASSET_TEMPLATE_OVERVIEW_DATA: 'refresh_asset_template_overview_data',
  OPEN_ASSET_TEMPLATE_VARIABLE_DETAIL_POPUP: 'open_asset_template_variable_detail_popup',
  // USER SHELL
  OPEN_USER_CREATE_DIALOG: 'open_user_create_dialog',
  CREATE_USER: 'create_user',
  REFRESH_USER_SHELL_DATA: 'refresh_user_shell_data',
  OPEN_USER_FILTER_ENGINE: 'open_user_filter_engine',
  EXPORT_USER_DATA: 'export_user_data',
  OPEN_USER_DETAILS_POPUP: 'open_user_details_popup',
  NAVIGATE_TO_USER_OVERVIEW_PAGE_FROM_POPUP: 'navigate_to_user_overview_page_from_popup',
  NAVIGATE_TO_USER_OVERVIEW_PAGE_FROM_GRID: 'navigate_to_user_overview_page_from_grid',
  ENABLE_USER: 'enable_user',
  DISABLE_USER: 'disable_user',
  RESEND_USER_VALIDATION_LINK: 'resend_user_validation_link',
  // USER OVERVIEW
  REFRESH_USER_OVERVIEW_DATA: 'refresh_user_overview_data',
  OPEN_USER_AUDIT_TRAIL_POPUP: 'open_user_audit_trail_popup',
  OPEN_USER_UPDATE_DIALOG: 'open_user_update_dialog',
  UPDATE_USER: 'update_user',
  // PO EVENT RULE SHELL
  REFRESH_PO_EVENT_RULE_SHELL_DATA: 'refresh_po_event_rule_shell_data',
  OPEN_PO_EVENT_RULE_FILTER_ENGINE: 'open_po_event_rule_filter_engine',
  OPEN_PO_EVENT_RULE_DETAILS_POPUP: 'open_po_event_rule_details_popup',
  OPEN_PO_EVENT_RULE_CREATE_DIALOG: 'open_po_event_rule_create_dialog',
  CREATE_PO_EVENT_RULE: 'create_po_event_rule',
  OPEN_CONFIGURE_PO_EVENT_DIALOG_RULE_FROM_SHELL: 'open_configure_po_event_rule_dialog_from_shell',
  CONFIGURE_PO_EVENT_RULE_FROM_SHELL: 'configure_po_event_rule_from_shell',
  OPEN_EDIT_PO_EVENT_DIALOG_RULE_FROM_SHELL: 'open_edit_po_event_rule_dialog_from_shell',
  EDIT_PO_EVENT_RULE_FROM_SHELL: 'edit_po_event_rule_from_shell',
  OPEN_DELETE_PO_EVENT_DIALOG_RULE_FROM_SHELL: 'open_delete_po_event_rule_dialog_from_shell',
  DELETE_PO_EVENT_RULE_FROM_SHELL: 'delete_po_event_rule_from_shell',
  NAVIGATE_TO_PO_EVENT_RULE_OVERVIEW_PAGE_FROM_POPUP: 'navigate_to_po_event_rule_overview_page_from_popup',
  NAVIGATE_TO_PO_EVENT_RULE_OVERVIEW_PAGE_FROM_GRID: 'navigate_to_po_event_rule_overview_page_from_grid',
  // PO EVENT RULE OVERVIEW
  REFRESH_PO_EVENT_RULE_OVERVIEW_DATA: 'refresh_po_event_rule_overview_data',
  OPEN_PO_EVENT_RULE_AUDIT_TRAIL_POPUP: 'open_po_event_rule_audit_trail_popup',
  OPEN_CONFIGURE_PO_EVENT_DIALOG_RULE_FROM_OVERVIEW: 'open_configure_po_event_rule_dialog_from_overview',
  CONFIGURE_PO_EVENT_RULE_FROM_OVERVIEW: 'configure_po_event_rule_from_overview',
  OPEN_EDIT_PO_EVENT_DIALOG_RULE_FROM_OVERVIEW: 'open_edit_po_event_rule_dialog_from_overview',
  EDIT_PO_EVENT_RULE_FROM_OVERVIEW: 'edit_po_event_rule_from_overview',
  OPEN_DELETE_PO_EVENT_DIALOG_RULE_FROM_OVERVIEW: 'open_delete_po_event_rule_dialog_from_overview',
  DELETE_PO_EVENT_RULE_FROM_OVERVIEW: 'delete_po_event_rule_from_overview'
};
