export const teamPlanningsMetadataSettings = {
  metadata: {
    url: '/team-plannings',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'team-plannings',
    expanded: false
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      multipleSelection: false,
      buttonColumn: {
        id: 'button',
        name: '',
        width: '40px',
        type: 'center center',
        enableConditions: [[{ key: 'isEditable', value: true }]],
        visibleConditions: [
          [
            { key: 'canUpdate', value: true },
            { key: 'canDelete', value: true }
          ]
        ],
        singleActions: [
          {
            order: 10,
            key: 'edit',
            label: 'IOT_DICTIONARY.EDIT',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          },
          {
            order: 20,
            key: 'navigate',
            label: 'IOT_DICTIONARY.CONFIGURE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          },
          {
            order: 30,
            key: 'delete',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canDelete', value: true }]]
          }
        ]
      },
      columns: []
    }
  },
  masterViewComments: {}
};
