<button
  (click)="!displayNestedAction() && !preventMainAction() && dispatchEvent.emit(); !displayNestedAction() && !preventMainAction() && onDispatchEvent?.()"
  [color]="color()"
  [disabled]="disabled()"
  [matTooltip]="tooltip() | translate"
  [ngClass]="{ 'iot-platform-ui-status-button__has-child': displayNestedAction(), 'iot-platform-ui-status-button__no-action': preventMainAction() }"
  [ngStyle]="style()"
  class="regular-round-button reverse-accent-button iot-platform-ui-status-button {{ cssClassName() }} iot-platform-ui-status-button__parent"
  fxFlex
  mat-stroked-button
  matTooltipClass="regular-tooltip"
>
  <div class="iot-platform-ui-status-button__content" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="center center">
      @if (actionLabel()) {
        <span class="iot-platform-ui-status-button__label">{{ actionLabel() | translate }}</span>
      }
      @if (actionSubLabel()) {
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span class="iot-platform-ui-status-button__text">{{ actionSubLabel() | translate }}</span>
          <ng-content select="[actionSubLabelMeta]"></ng-content>
        </div>
      }
    </div>

    @if (displayNestedAction()) {
      <button
        (click)="$event.preventDefault(); $event.stopPropagation(); dispatchEvent.emit(); onDispatchEvent?.()"
        [disabled]="disabled()"
        class="regular-round-button reverse-accent-button iot-platform-ui-status-button iot-platform-ui-status-button__child"
        [color]="color()"
        matTooltipClass="regular-tooltip"
        mat-stroked-button
      >
        {{ nestedActionLabel() | translate }}
      </button>
    }
    <ng-content></ng-content>
  </div>
</button>
