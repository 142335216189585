<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">
        {{ 'MAINTENANCE_DIALOGS.END_MAINTENANCE_FORM.TITLE' | translate: { elementName: data.elementEndingMaintenance.name } }}
      </h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="endMaintenanceForm" class="p-20 fs-12" fxFlex fxLayout="column" fxLayoutGap="20px">
      <p>{{ 'MAINTENANCE_DIALOGS.END_MAINTENANCE_FORM.EXPLANATIONS.' + data.type | translate }}</p>
      @if (data.type === 'DEVICE') {
        <mat-form-field color="accent">
          <mat-label>{{ 'MAINTENANCE_DIALOGS.END_MAINTENANCE_FORM.NEW_STATUS' | translate }}</mat-label>
          <mat-select formControlName="switchbackStatus" required>
            @for (status of availableStatuses; track status) {
              <mat-option [value]="status">{{ 'DEVICES.CARD.STATUS_LIST.' + status | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="confirm()" [disabled]="!endMaintenanceForm.valid" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.CONFIRM' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
