import { EntityState } from '@ngrx/entity';

export interface BaseState<T, P, F> extends EntityState<T> {
  selectedId: string | null;
  pagination: P | null;
  entity: T | null;
  loaded: boolean;
  loading: boolean;
  error: unknown;
  filters: F[];
}

export const INITIAL_BASE_STATE = {
  selectedId: null,
  pagination: null,
  entity: null,
  loaded: false,
  loading: false,
  error: null,
  filters: []
};
