import { Injectable } from '@angular/core';
import { Graph } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';

import { GraphsDbActions } from '../actions';
import * as fromGraphs from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class GraphsFacade {
  public allGraphs$ = this.store.select(fromGraphs.getAllGraphs);
  public selectedGraph$ = this.store.select(fromGraphs.getSelectedGraph);
  public graphLoaded$ = this.store.select(fromGraphs.getGraphsLoaded);
  public graphLoading$ = this.store.select(fromGraphs.getGraphsLoading);

  constructor(private store: Store) {}

  public loadGraphsBySiteId(siteId: string): void {
    this.store.dispatch(GraphsDbActions.loadGraphsBySiteId({ siteId }));
  }

  public loadGraphById(graphId: string): void {
    this.store.dispatch(GraphsDbActions.loadGraphById({ graphId }));
  }

  public addGraph(graph: Graph): void {
    this.store.dispatch(GraphsDbActions.addGraph({ graph }));
  }

  public updateGraph(graph: Graph): void {
    this.store.dispatch(GraphsDbActions.updateGraph({ graph }));
  }

  public deleteGraph(graph: Graph): void {
    this.store.dispatch(GraphsDbActions.deleteGraph({ graph }));
  }

  public updateGraphsDisplayOrder(graphs: Graph[]): void {
    this.store.dispatch(GraphsDbActions.updateGraphsDisplayOrder({ graphs }));
  }
}
