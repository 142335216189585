import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { MultiSelectChipComponent } from './multi-select-chip.component';

@NgModule({
  declarations: [MultiSelectChipComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatChipsModule, MatIconModule],
  exports: [MultiSelectChipComponent]
})
export class MultiSelectChipModule {}
