<div class="tag-category-editor" fxFlex fxLayout="column" fxLayoutGap="6px">
  @for (tagCategory of categories(); track tagCategory.name) {
    <iot-platform-ui-tag-editor-form
      (changeValue)="onChangeValue(false)"
      (remove)="onRemove($event)"
      (save)="onUpdateTagCategory($event)"
      [colors]="colors()"
      [mode]="'UPDATE'"
      [tagCategory]="tagCategory"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
    </iot-platform-ui-tag-editor-form>
  }
  <iot-platform-ui-tag-editor-form
    (changeValue)="onChangeValue(false)"
    (save)="onAddTagCategory($event)"
    [colors]="colors()"
    [mode]="'ADD'"
    [tagCategory]="newCategory()"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
  >
  </iot-platform-ui-tag-editor-form>
</div>
