<div
  [matTooltipClass]="'warning-tooltip'"
  [matTooltipDisabled]="!data?.status"
  [matTooltip]="'DEVICES.TABLE_CONTENT.CYCLICAL_CALL_FAULT_TOOLTIP' | translate: { dateValue: (data?.datetime | dateFormat) ?? 'n/a' }"
  matTooltipPosition="above"
>
  @if (data?.status) {
    <mat-icon svgIcon="cyclicalCallFault" color="warn" class="ccf-icon"></mat-icon>
  }
</div>
