import { Component } from '@angular/core';
import { Country, CountryFilterType, FilterCountryOptions } from '@iot-platform/models/common';
import { get } from 'lodash';
import { CountryAutocompleteComponent } from '../../../country-autocomplete/country-autocomplete.component';
import { AbstractFilterEngineFieldComponent } from '../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  imports: [CountryAutocompleteComponent],
  selector: 'iot-platform-ui-country-autocomplete-field',
  templateUrl: './country-autocomplete-field.component.html'
})
export class CountryAutocompleteFieldComponent extends AbstractFilterEngineFieldComponent<FilterCountryOptions> {
  constructor() {
    super();
    this.data.set({ filterType: CountryFilterType.ALL });
  }

  onSelectionChange(country: Country): void {
    let key = 'name';
    const data = this.data();
    if (data.filterType !== CountryFilterType.NAME && data.filterType !== CountryFilterType.ALL) {
      key = data.filterType;
    }
    const value: string = get(country, key);
    this.dispatchFilterEvent({
      value,
      label: value,
      criteriaKey: data?.criteriaKey,
      criteriaLabel: data?.criteriaLabel
    });
  }
}
