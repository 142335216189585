import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Asset } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderComponent } from '../../card-loader/card-loader.component';
import { DetailAssetsCardComponent } from '../../detail-popup-cards/detail-assets-card/detail-assets-card.component';
import { DetailPopupSectionHeaderComponent } from '../../detail-popup/detail-popup-section-header/detail-popup-section-header.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, CardLoaderComponent, DetailPopupSectionHeaderComponent, DetailAssetsCardComponent, NgClass],
  selector: 'iot-platform-ui-site-detail-assets',
  templateUrl: './site-detail-assets.component.html',
  styleUrls: ['./site-detail-assets.component.scss']
})
export class SiteDetailAssetsComponent {
  assets = input<Asset[]>([]);
  totalAssets = input<number>(0);
  loaded = input<boolean>(false, { alias: 'isAssetLoaded' });

  selectAsset = output<Asset>();
}
