import { Signal } from '@angular/core';
import { Dashboard } from '@iot-platform/models/dashboards';
import { FeatureMasterViewEvent } from '../models/master-view-event.model';

export abstract class AbstractMasterViewDashboardService {
  abstract getDashboardConfiguration(): Signal<{
    sortedDashboards: Dashboard[];
    currentDashboard: Dashboard | undefined;
    isDashboardsLoading: boolean;
  }>;

  abstract onToolbarEvent(event: FeatureMasterViewEvent): void;
}
