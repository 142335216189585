import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Contact, Filter, Pagination } from '@iot-platform/models/common';
import * as fromContacts from '../reducers/contacts.reducer';

@Injectable({
  providedIn: 'root'
})
export class ContactsSelectors extends ApiFeatureSelector<fromContacts.State, Contact, Pagination, Filter> {
  /* selectTotal: MemoizedSelector<fromDevices.State, number> = createSelector(this.selectState, (state: fromDevices.State): number => state.pagination.total);
  selectConfigurationStatus: MemoizedSelector<fromDevices.State, DeviceConfigurationStatus> = createSelector(this.selectState, (state: fromDevices.State) =>
    DeviceHelpers.getConfigurationStatus(state.entity)
  );
  selectConfigurationTargetStatus: MemoizedSelector<fromDevices.State, DeviceConfigurationTargetStatus> = createSelector(
    this.selectState,
    (state: fromDevices.State) => DeviceHelpers.getConfigurationTargetStatus(state.entity)
  );*/

  constructor() {
    super(fromContacts.contactsFeature.selectContactsState, fromContacts.adapter);
  }
}
