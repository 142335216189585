import { BusinessProfile } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BusinessProfilesApiActions } from '../../../admin-business-profiles/state/actions';
import { AdminOrganizationsBusinessProfilesApiActions } from '../actions';

export const adminOrganizationsBusinessProfilesApiFeatureKey = 'adminOrganizationsBusinessProfilesApi';

export interface State extends EntityState<BusinessProfile> {
  selectedBusinessProfileId: string | null;
}

export const adapter: EntityAdapter<BusinessProfile> = createEntityAdapter<BusinessProfile>({
  selectId: (businessProfile: BusinessProfile) => businessProfile.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedBusinessProfileId: null
});

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsBusinessProfilesApiActions.listBusinessProfilesByOrganizationSuccess, (state: State, { businessProfiles }) =>
    adapter.setAll(businessProfiles, state)
  ),
  on(AdminOrganizationsBusinessProfilesApiActions.listBusinessProfilesByOrganizationFailure, (state: State, { error }) => ({ ...state, error })),
  on(BusinessProfilesApiActions.updateBusinessProfileSuccess, (state: State, { businessProfile }) => adapter.updateOne(businessProfile, state)),
  on(BusinessProfilesApiActions.addBusinessProfileSuccess, (state: State, { businessProfile }) => adapter.addOne(businessProfile, state)),
  on(BusinessProfilesApiActions.deleteBusinessProfileSuccess, (state: State, { deletedBusinessProfile }) => adapter.removeOne(deletedBusinessProfile.id, state))
);

export const getSelectedId = (state: State) => state.selectedBusinessProfileId;
