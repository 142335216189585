export const deviceVariablesConfigurationPopupMetadataSettings = {
  metadata: {
    url: '',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'deviceVariablesConfigurationPopup',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 100,
      refreshDelay: 120,
      multipleSelection: false,
      columns: [],
      expandedPanel: {}
    }
  },
  masterViewComments: {}
};
