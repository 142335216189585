<!-- BODY HEADER SECTION -->
<div class="iot-platform-ui-chat-body" data-cy="iot-platform-ui-chat-body" fxFlex fxLayout="column">
  <section class="iot-platform-ui-chat-body__header">
    @if (body().header?.ref) {
      @if (body().header?.inputs) {
        <ng-container *ngComponentOutlet="body().header?.ref; inputs: body().header?.inputs" />
      } @else {
        <ng-container [ngTemplateOutlet]="body().header?.ref" />
      }
    }
  </section>
  <!-- BODY CONTENT SECTION -->
  <section class="iot-platform-ui-chat-body__content" fxFlex>
    @if (body()?.content?.ref) {
      @if (body()?.content?.inputs) {
        <ng-container *ngComponentOutlet="body().content?.ref; inputs: body?.()?.content?.inputs" />
      } @else {
        <ng-container [ngTemplateOutlet]="body().content?.ref" />
      }
    } @else {
      @if (body().content?.loading?.()) {
        <iot-platform-ui-card-loader fxLayoutAlign="center center" fxFlex></iot-platform-ui-card-loader>
      } @else {
        @if (!!body()?.content?.messages?.()?.length) {
          <div #chatBody class="iot-platform-ui-chat-body__content_messages" fxFlex>
            <div fxLayout="column" fxLayoutGap="14px">
              @for (message of body()?.content?.messages?.(); track message) {
                @if (message?.ref) {
                  @if (message?.inputs) {
                    <ng-container *ngComponentOutlet="message?.ref; inputs: message?.inputs" />
                  } @else {
                    <ng-container [ngTemplateOutlet]="message?.ref" />
                  }
                } @else {
                  <iot-platform-ui-chat-message [message]="message" (dispatchAction)="dispatchAction.emit($event)"></iot-platform-ui-chat-message>
                }
              }
            </div>
          </div>
        } @else if (body()?.content?.emptyBodyLabel?.()) {
          <div class="iot-platform-ui-chat-body__content_no_messages" fxLayoutAlign="center center" fxFlex fxLayout="column">
            {{ body()?.content?.emptyBodyLabel?.() | translate }}
          </div>
        }
      }
    }
  </section>
</div>
