import { Injectable, Signal, inject } from '@angular/core';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { fromUserPreferences } from '../../../preferences';
import { PreferencesActions } from '../../../preferences/state/actions';
import * as fromUserProfile from '../../state/reducers';
import { ProfileActions } from '../actions';

@Injectable({
  providedIn: 'root'
})
export class ProfileFacade {
  private readonly store: Store = inject(Store);

  user: Signal<UserAccount> = this.store.selectSignal(fromUserProfile.getCurrentUser);
  userPreferences: Signal<UserPreferences> = this.store.selectSignal(fromUserPreferences.getPreferences);
  userLoading: Signal<boolean> = this.store.selectSignal(fromUserProfile.getUserProfileLoading);

  loadUserProfile(): void {
    this.store.dispatch(ProfileActions.loadUserProfile());
  }

  loadUserPreferences(): void {
    this.store.dispatch(PreferencesActions.loadUserPreferences());
  }

  saveUserProfile(user: UserAccount): void {
    this.store.dispatch(ProfileActions.saveUserProfile({ userToUpdate: user }));
  }

  saveUserPreferences(user: UserAccount): void {
    this.store.dispatch(PreferencesActions.saveUserPreferences({ user, preferences: user.preferences }));
  }
}
