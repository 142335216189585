import { EntityAdapter } from '@ngrx/entity';
import { Dictionary, EntitySelectors } from '@ngrx/entity/src/models';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { BaseState } from './base-state.model'; /* eslint-disable  @typescript-eslint/no-explicit-any */

/* eslint-disable  @typescript-eslint/no-explicit-any */

/* eslint-disable  @typescript-eslint/ban-types */
export abstract class ApiFeatureSelector<S extends BaseState<T, P, F>, T, P, F> {
  selectState: MemoizedSelector<S, any>;
  selectAdapter: EntitySelectors<T, S>;

  selectIds: (state: S) => string[] | number[];
  selectEntities: (state: S) => Dictionary<T>;
  selectAll: (state: S) => T[];
  selectTotal: (state: S) => number;

  selectSelectedEntity: MemoizedSelector<S, '' | T | undefined | null>;
  selectCurrentEntity: MemoizedSelector<S, T | null>;
  selectId: MemoizedSelector<S, string | null>;
  selectPagination: MemoizedSelector<S, P | null>;
  selectPlatformResponse: MemoizedSelector<
    S,
    {
      data: T[];
      currentPage: number;
      hasMore: boolean;
      limit: number;
      maxPage: number;
      total: number;
    }
  >;
  selectLoading: MemoizedSelector<S, boolean>;
  selectLoaded: MemoizedSelector<S, boolean>;
  selectFilters: MemoizedSelector<S, F[]>;
  selectError: MemoizedSelector<S, unknown>;

  protected constructor(featureState: MemoizedSelector<S, any>, adapter: EntityAdapter<T>) {
    this.selectState = featureState;
    this.selectAdapter = adapter?.getSelectors(this.selectState);

    this.selectIds = this.selectAdapter.selectIds;
    this.selectEntities = this.selectAdapter.selectEntities;
    this.selectAll = this.selectAdapter.selectAll;
    this.selectTotal = this.selectAdapter.selectTotal;

    this.selectCurrentEntity = createSelector(this.selectState, (state: S) => state.entity);
    this.selectId = createSelector(this.selectState, (state: S) => state.selectedId);
    this.selectSelectedEntity = createSelector(this.selectEntities, this.selectId, (entities, selectedId) => selectedId && entities[selectedId]);
    this.selectPagination = createSelector(this.selectState, (state: S) => state.pagination);
    this.selectPlatformResponse = createSelector(this.selectAll, this.selectPagination as MemoizedSelector<object, P>, (data: T[], pagination: P) => {
      const p: any = { ...pagination };
      return {
        data,
        currentPage: p.currentPage,
        hasMore: p.hasMore,
        limit: p.limit,
        maxPage: p.maxPage,
        total: p.total
      };
    });

    this.selectLoading = createSelector(this.selectState, (state: S) => state.loading);
    this.selectLoaded = createSelector(this.selectState, (state: S) => state.loaded);
    this.selectFilters = createSelector(this.selectState, (state: S) => state.filters);
    this.selectError = createSelector(this.selectState, (state: S) => state.error);
  }
}
