import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { CommonApiListResponse, Environment } from '@iot-platform/models/common';
import { DeviceVariable, I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceVariablesService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  getDeviceVariables(deviceId: string): Observable<DeviceVariable[]> {
    return this.httpClient
      .get<
        CommonApiListResponse<DeviceVariable>
      >(`${this.environment.api.url + this.environment.api.endpoints.devices}/${deviceId}/variables?limit=3000&page=0`)
      .pipe(map((response: CommonApiListResponse<DeviceVariable>) => response.content));
  }

  bulkDeleteDeviceVariables(deviceVariables: DeviceVariable[]): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.deleteDevicesVariables}`, {
      variablesIds: deviceVariables.map((variable) => variable.id)
    });
  }

  bulkResetDeviceVariablesLastValues(deviceVariables: DeviceVariable[]): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.resetDeviceVariablesLastValues}`, {
      variablesIds: deviceVariables.map((variable) => variable.id)
    });
  }
}
