import { get } from 'lodash';
import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BVariableColumnOptions } from '../options/i4b-variable-column-options';
import { DaliaFirmwareVersionColumn } from './dalia-firmware-version.column';
import { I4BBasicColumn } from './i4b-basic.column';

export class DeviceTypeGroupColumn extends I4BBasicColumn {
  columnId = '79f2e9aa-9f85-11ec-9da8-0023a40210d0-device-type-group';
  className = DeviceTypeGroupColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BVariableColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName ? customOptions?.overrideHeaderName : customHeader?.displayName ? customHeader?.displayName : 'VARIABLE',
        type: HeaderType.BASIC
      },
      {
        cell: {
          type: I4BCellType.BASIC,
          options: get(customConfig, ['cell', 'options'])
        },
        id: customConfig.id,
        concept: I4bCellConcept.DEVICES,
        isDefault: !!customConfig?.isDefault,
        marryChildren: true,
        children: [
          new I4BBasicColumn(
            { displayName: 'FAMILY' },
            {
              id: 'type.family',
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
              cell: { type: I4BCellType.BASIC },
              isDefault: true,
              sortProperty: 'type.family',
              concept: I4bCellConcept.DEVICES,
              width: 120
            },
            {
              order: 800
            }
          ),
          new I4BBasicColumn(
            { displayName: 'MODEL' },
            {
              id: 'type.model',
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
              cell: { type: I4BCellType.BASIC },
              isDefault: false,
              sortProperty: 'type.model',
              concept: I4bCellConcept.DEVICES,
              width: 120
            },
            {
              order: 801
            }
          ),
          new DaliaFirmwareVersionColumn(
            { displayName: 'FIRMWARE' },
            {
              id: 'type.firmware',
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
              cell: {
                type: I4BCellType.BASIC,
                options: get(customConfig, ['cell', 'options'])
              },
              isDefault: false,
              sortProperty: 'type.firmware',
              concept: I4bCellConcept.DEVICES,
              width: 140
            },
            {
              order: 802
            }
          )
        ]
      },
      { ...customOptions, customId: customConfig.id }
    );
  }
}
