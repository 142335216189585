import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const assetVariablesColumnSettings = [
  {
    order: 5,
    id: 'followedNumber',
    name: '',
    catalogName: 'FOLLOWED NUMBER',
    default: true,
    sortable: true,
    sortProperty: 'followedNumber',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.FOLLOWED_NUMBER_CELL,
    isLink: false,
    width: '3%',
    cellWidth: '80',
    type: 'center center'
  },
  {
    order: 10,
    id: 'totalActiveEvents',
    name: '',
    catalogName: 'ACTIVE EVENTS',
    default: true,
    sortable: true,
    sortProperty: 'totalActiveEvents',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ACTIVE_EVENTS_CELL,
    isLink: false,
    width: '3%',
    cellWidth: '80',
    type: 'center center'
  },
  {
    order: 15,
    id: 'linked',
    name: '',
    catalogName: 'LINKED',
    default: true,
    sortable: true,
    sortProperty: 'linked',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.LINKED_CELL,
    isLink: false,
    width: '3%',
    cellWidth: '80',
    type: 'center center'
  },
  {
    order: 20,
    id: 'name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_NAME',
    catalogName: 'VARIABLE NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '25%',
    type: 'start center'
  },
  {
    order: 30,
    id: 'comment',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMENT',
    catalogName: 'COMMENT',
    default: true,
    sortable: true,
    sortProperty: 'comment',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '30%',
    type: 'start center'
  },
  {
    order: 40,
    id: '',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VALUE',
    catalogName: 'VALUE',
    default: true,
    sortable: true,
    sortProperty: 'lastValue.value',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.RICH_VARIABLE,
    cellTypeOptions: {
      dataType: 'lastValue',
      unit: 'unit'
    },
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '25%',
    type: 'start center'
  },
  {
    order: 42,
    id: 'thresholds.values',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD1',
    catalogName: 'THRESHOLD 1',
    default: true,
    sortable: false,
    sortProperty: 'threshold1',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL,
    cellTypeOptions: {
      position: 1
    },
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 45,
    id: 'thresholds.values',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD2',
    catalogName: 'THRESHOLD 2',
    default: true,
    sortable: false,
    sortProperty: 'threshold2',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL,
    cellTypeOptions: {
      position: 2
    },
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 50,
    id: 'thresholds.values',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD3',
    catalogName: 'THRESHOLD 3',
    default: false,
    sortable: false,
    sortProperty: 'threshold 3',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL,
    cellTypeOptions: {
      position: 3
    },
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 55,
    id: 'thresholds.values',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD4',
    catalogName: 'THRESHOLD 4',
    default: false,
    sortable: false,
    sortProperty: 'threshold 4',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL,
    cellTypeOptions: {
      position: 4
    },
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 57,
    id: 'thresholds.values',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD5',
    catalogName: 'THRESHOLD 5',
    default: false,
    sortable: false,
    sortProperty: 'threshold5',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL,
    cellTypeOptions: {
      position: 5
    },
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 60,
    id: 'datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TIME',
    catalogName: 'TIME',
    default: true,
    sortable: false,
    sortProperty: 'datetime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    width: '25%',
    type: 'start center'
  },
  {
    order: 70,
    id: 'dispatch',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DISPATCH',
    catalogName: 'DISPATCH',
    default: true,
    sortable: false,
    sortProperty: 'dispatch',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.VARIABLE_DISPATCH_CELL,
    isLink: false,
    width: '10%',
    cellWidth: '80',
    type: 'center center'
  },
  {
    order: 90,
    id: 'id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_ID',
    catalogName: 'VARIABLE TECHNICAL ID',
    default: false,
    sortable: true,
    sortProperty: 'id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '15%',
    type: 'start center'
  }
];
