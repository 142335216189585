import { Sort } from '@angular/material/sort';
import { gridSortModel, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, Device, DeviceEvent, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsDbActions, DeviceEventsUiActions } from '../actions';

export const deviceEventsDbFeatureKey = 'deviceEventsDb';

export interface State extends EntityState<DeviceEvent> {
  selectedDeviceEventId: string | null;
  checkedDeviceEventIds: string[];
  error?: any;
  site?: Site | null;
  asset?: Asset | null;
  assetVariable?: AssetVariable | null;
  device?: Device | null;
  deviceVariable?: DeviceVariable | null;
  tags?: TagCategory[];
  status?: DeviceEvent;
  settings: any;
  initialSort: gridSortModel[] | Sort;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  tableState?: { selected: DeviceEvent | null; checked: DeviceEvent[] };
}

export const adapter: EntityAdapter<DeviceEvent> = createEntityAdapter<DeviceEvent>({
  selectId: (deviceEvent: DeviceEvent) => deviceEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedDeviceEventId: null,
  checkedDeviceEventIds: [],
  error: null,
  site: null,
  asset: null,
  device: null,
  tags: [],
  initialSort: [],
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 },
  settings: null,
  tableState: { selected: null, checked: [] }
});

const deviceEventsDbReducer = createReducer(
  initialState,
  on(DeviceEventsUiActions.loadDeviceEvents, (state: State) => ({ ...state })),
  on(DeviceEventsDbActions.loadDeviceEventsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    })
  ),
  // ***
  on(DeviceEventsDbActions.loadMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(DeviceEventsDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  // ****
  on(DeviceEventsUiActions.loadSiteById, (state: State) => ({ ...state, site: null })),
  on(DeviceEventsDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, site })),
  // ****
  on(DeviceEventsUiActions.loadAssetById, (state: State) => ({ ...state, asset: null })),
  on(DeviceEventsDbActions.loadAssetByIdSuccess, (state: State, { asset }) => ({ ...state, asset })),
  // ****
  on(DeviceEventsUiActions.loadDeviceById, (state: State) => ({ ...state, device: null })),
  on(DeviceEventsDbActions.loadDeviceByIdSuccess, (state: State, { device }) => ({ ...state, device })),
  // ****
  on(DeviceEventsUiActions.loadDeviceVariableById, (state: State) => ({ ...state, deviceVariable: null })),
  on(DeviceEventsDbActions.loadDeviceVariableByIdSuccess, (state: State, { deviceVariable }) => ({ ...state, deviceVariable })),
  // ****
  on(DeviceEventsUiActions.loadAssetVariableById, (state: State) => ({ ...state, assetVariable: null })),
  on(DeviceEventsDbActions.loadAssetVariableByIdSuccess, (state: State, { assetVariable }) => ({ ...state, assetVariable })),
  // ****
  on(DeviceEventsUiActions.loadTagsByDeviceEventId, (state: State) => ({ ...state, tags: [] })),
  on(DeviceEventsDbActions.loadTagsByDeviceEventIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  // ****
  on(DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: deviceEvent }, { ...state, status: deviceEvent })
  ),
  on(DeviceEventsDbActions.saveTableStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedDeviceEventId: selectedId,
    checkedDeviceEventIds: checkedIds
  })),
  //
  on(DeviceEventsUiActions.saveMVSettings, (state: State) => adapter.removeAll({ ...state })),
  on(DeviceEventsDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  //
  on(DeviceEventsUiActions.saveInitialSort, (state: State, { initialSort }) => ({ ...state, initialSort }))
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedDeviceEventId;
export const getCheckedIds = (state: State) => state.checkedDeviceEventIds;
export const getSite = (state: State) => state.site;
export const getAsset = (state: State) => state.asset;
export const getAssetVariable = (state: State) => state.assetVariable;
export const getDevice = (state: State) => state.device;
export const getDeviceVariable = (state: State) => state.deviceVariable;
export const getTags = (state: State) => state.tags;
export const getTableState = (state: State) => state.tableState;
export const getStatus = (state: State) => state.status;
