<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content p-20">
    <form [formGroup]="businessProfileFrom" fxLayout="column">
      <iot-platform-ui-async-autocomplete
        (reset)="resetEntity()"
        (search)="resetEntity()"
        (selectionChanged)="onEntitySelection($event)"
        [data]="sortedEntities"
        [disabled]="!!data?.organization || !!data?.businessProfile"
        [displayKey]="'label'"
        [displaySearchIcon]="false"
        [filterKey]="'label'"
        [initialItem]="initialEntity"
        [minLength]="1"
        [placeholder]="'IOT_DICTIONARY.ENTITY'"
        [required]="true"
        [showSpinner]="entitiesLoading"
      />
      <mat-form-field class="" color="accent">
        <mat-label>{{ 'ADMIN.DIALOG.ADD_BP.PLACEHOLDER_NAME' | translate }}</mat-label>
        <input formControlName="nameControl" matInput maxlength="50" required />
        @if (displayDuplicateMessage$ | async) {
          <mat-hint align="end">
            <span class="dialog-card-input-error">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</span>
          </mat-hint>
        }
      </mat-form-field>
      <mat-checkbox color="accent" formControlName="withSiteStockControl" labelPosition="before"
        >{{ 'ADMIN.DIALOG.ADD_ORGANIZATION.CREATE_STOCK' | translate }}
      </mat-checkbox>
      <mat-checkbox color="accent" formControlName="withMobileFleetControl" labelPosition="before"
        >{{ 'ADMIN.DIALOG.ADD_ORGANIZATION.CREATE_MOBILE_FLEET' | translate }}
      </mat-checkbox>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ADMIN.DIALOG.ADD_BP.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!businessProfileFrom.valid || (isDisabled$ | async)" class="button-regular" color="accent" mat-raised-button>
      {{ 'ADMIN.DIALOG.ADD_BP.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
