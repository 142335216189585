import { createSelector } from '@ngrx/store';
import { State, adapter, deviceCommentsFeature } from '../reducers/device-comments.reducer';

const selectDeviceCommentsState = deviceCommentsFeature.selectDeviceCommentsState;

export const {
  selectIds: selectDeviceCommentsIds,
  selectEntities: selectDeviceCommentsDictionary,
  selectAll: selectAllDeviceComments,
  selectTotal: selectTotalDeviceComments
} = adapter.getSelectors(selectDeviceCommentsState);

export const selectDeviceCommentsError = createSelector(selectDeviceCommentsState, (state: State) => state.error);
export const selectDeviceCommentsLoading = createSelector(selectDeviceCommentsState, (state: State) => state.loading);
