@if (actions()?.length) {
  <div class="app-round-button-mv" data-cy="grid-engine-call-to-action-cell" fxLayoutAlign="center center">
    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @for (action of actions(); track $index) {
        <grid-engine-call-to-action-menu-item
          (actionClicked)="onActionClicked($event)"
          [action]="action"
          [element]="params().data"
          [userPermissions]="params()?.userPermissions"
        />
      }
    </mat-menu>
  </div>
}
