import { DeviceStatusName } from '@iot-platform/models/i4b';
import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BStockSiteDevicesButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.DEVICES,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          bulkActions: [
            {
              order: 10,
              key: 'bulkMoveTo',
              label: 'IOT_DICTIONARY.MOVE_TO',
              enableConditions: [],
              disableConditions: [[{ key: 'status.name', value: DeviceStatusName.test_mode }]],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
          ],
          singleActions: [
            {
              order: 10,
              key: 'moveTo',
              label: 'IOT_DICTIONARY.MOVE_TO',
              enableConditions: [],
              disableConditions: [[{ key: 'status.name', value: DeviceStatusName.test_mode }]],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
          ],
          visibleConditions: [[{ key: 'canUpdateDevice', value: true }]],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
