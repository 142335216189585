import { DeviceEvent } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsByTopicDbActions, DeviceEventsByTopicLogsDbActions, DeviceEventsByTopicUiActions } from '../../actions';
//
export const deviceEventsByTopicDbFeatureKey = 'deviceEventsByTopicDb';

export interface State extends EntityState<DeviceEvent> {
  selectedDeviceEventId: string | null;
  checkedDeviceEventIds: string[];
  previousTopicId: string | null;
  totalActiveEvents: number;
  error?: any;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  settings?: any;
  status?: DeviceEvent;
}

export const adapter: EntityAdapter<DeviceEvent> = createEntityAdapter<DeviceEvent>({
  selectId: (deviceEvent: DeviceEvent) => deviceEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedDeviceEventId: null,
  checkedDeviceEventIds: [],
  previousTopicId: null,
  totalActiveEvents: 0,
  error: null,
  pagination: { currentPage: 0, hasMore: false, limit: 100, maxPage: 0, total: 0 },
  settings: null
});

const deviceEventsByTopicDbReducer = createReducer(
  initialState,

  on(DeviceEventsByTopicUiActions.loadDeviceEventsByTopic, (state: State) => ({ ...state })),
  on(DeviceEventsByTopicDbActions.loadDeviceEventsByTopicSuccess, (state: State, { response, topicId }) => {
    const newState = { ...state };
    if (!state.previousTopicId || (state.previousTopicId && state.previousTopicId !== topicId)) {
      newState.previousTopicId = topicId;
    }
    return adapter.setAll(response.data, {
      ...newState,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    });
  }),
  // ****
  on(DeviceEventsByTopicDbActions.loadTotalActiveDeviceEventsByTopicSuccess, (state: State, { totalActiveEvents }) => ({ ...state, totalActiveEvents })),
  // ****
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: { totalComments: state.entities[state.selectedDeviceEventId].totalComments + 1 } }, state)
  ),
  // ****
  on(DeviceEventsByTopicDbActions.updateStatusByDeviceEventIdByTopicSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: deviceEvent }, { ...state, status: deviceEvent })
  ),
  on(DeviceEventsByTopicDbActions.bulkUpdateStatusByDeviceEventIdByTopicSuccess, (state: State, { deviceEvents }) => {
    const updates: Array<{ id: string; changes: any }> = deviceEvents.reduce((acc, value) => {
      acc.push({ id: value.id, changes: value });
      return acc;
    }, []);

    return adapter.updateMany(updates, { ...state, status: deviceEvents[0] });
  }),
  on(DeviceEventsByTopicDbActions.saveTableByTopicStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedDeviceEventId: selectedId,
    checkedDeviceEventIds: checkedIds
  })),
  on(DeviceEventsByTopicDbActions.loadMvDeviceEventsByTopicSettingsSuccess, (state: State, { settings }) => ({ ...state, settings }))
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByTopicDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedDeviceEventId;
export const getCheckedIds = (state: State) => state.checkedDeviceEventIds;
export const getPreviousTopicId = (state: State) => state.previousTopicId;
export const getTotalActiveEvents = (state: State) => state.totalActiveEvents;
export const getMvSettings = (state: State) => state.settings;
export const getStatus = (state: State) => state.status;
