import { AsyncPipe } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, input, output, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, StatusChangeEvent, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { AsyncAutocompleteModule, ImageSelectorModule, ProgressBarButtonModule } from '@iot-platform/iot-platform-ui';
import { NameValidators, SortUtil } from '@iot-platform/iot-platform-utils';
import { Entity } from '@iot-platform/models/common';
import { Site, SiteType } from '@iot-platform/models/i4b';
import { EntitiesService } from '@iot-platform/shared';
import { SitesService } from '@iot-platform/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { filter } from 'rxjs/operators';

interface ERPData {
  city: string;
  country: string;
  countryCode: string;
  division: string;
  houseNumber: string;
  maintenancePlant: string;
  name1: string;
  name2: string;
  postalCode: string;
  region: string;
  shipToAccountNumber: string;
  state: string;
  status: string;
}

@Component({
  selector: 'iot4bos-ui-site-info-form',
  templateUrl: './site-info-form.component.html',
  styleUrls: ['./site-info-form.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    AsyncAutocompleteModule,
    MatSelectModule,
    TranslateModule,
    ImageSelectorModule,
    ReactiveFormsModule,
    ProgressBarButtonModule,
    AsyncPipe
  ],
  providers: [TranslateService]
})
export class SiteInfoFormComponent {
  //
  site = input<Site | undefined>();
  changedForm = output<Site | null>();
  //
  sourceList: string[] = ['SAP AIRGAS'];
  defaultSource = 'SAP AIRGAS';
  allowedSiteTypes = [SiteType.PRODUCTION_SITE, SiteType.CUSTOMER_SITE];
  defaultName = computed(() => {
    const site = this.site();
    return site?.name;
  });
  imageUrl: WritableSignal<string | undefined> = signal(undefined);
  entitiesLoading: Signal<boolean> = computed(() => {
    const sortedEntities = this.sortedEntities();
    return !sortedEntities;
  });
  initialEntity: WritableSignal<Entity | null> = signal(null);
  parentEntity: WritableSignal<Entity | undefined> = signal(undefined);
  initialCountry: WritableSignal<any> = signal(null);
  importLoading: WritableSignal<boolean> = signal(false);
  // Injections
  private readonly fb: UntypedFormBuilder = inject(UntypedFormBuilder);

  private readonly sitesService: SitesService = inject(SitesService);
  private readonly entitiesService: EntitiesService = inject(EntitiesService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  //
  siteForm = this.fb.group({
    creationViaERP: [false, [Validators.required]],
    source: [this.defaultSource],
    shipto: [''],
    entity: ['', [Validators.required]],
    name: [null, [Validators.required, Validators.maxLength(60), Validators.pattern('\\S.*')]],
    name2: [null, [Validators.maxLength(60)]],
    businessId: [null, [Validators.maxLength(30)]],
    type: [SiteType.CUSTOMER_SITE, [Validators.required]],
    address1: [null, [Validators.maxLength(50)]],
    address2: [null, [Validators.maxLength(50)]],
    zipCode: [null, [Validators.maxLength(10)]],
    city: [null, [Validators.required, Validators.maxLength(30), Validators.pattern('\\S.*')]],
    state: [null],
    country: [null, [Validators.required]],
    description: [null, [Validators.maxLength(300)]],
    imageUrl: [null]
  });
  sortedEntities: Signal<Entity[]> = toSignal(this.entitiesService.getHierarchicallySortedEntities().pipe(takeUntilDestroyed(this.destroyRef)));
  countries: Signal<any[]> = toSignal(
    this.sitesService.getCountries().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((countries) =>
        countries
          .map((c) => ({ ...c, name: this.translateService.instant('SITES.CARD.COUNTRIES.' + c.countryCodeAlpha3) }))
          .sort(SortUtil.sortByProperty('name'))
      )
    )
  );
  formChanges = toSignal(
    this.siteForm.events.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((event) => event instanceof StatusChangeEvent)
    )
  );

  constructor() {
    this.initFormEffect();
    this.emitChangeEffect();
    this.initEntitiesEffect();
    this.initCountriesEffect();
    this.initInitialEntityEffect();
    this.initInitialCountryEffect();
  }

  get creationViaERP() {
    return this.siteForm.get('creationViaERP') as AbstractControl;
  }

  get source() {
    return this.siteForm.get('source') as AbstractControl;
  }

  get shipto() {
    return this.siteForm.get('shipto') as AbstractControl;
  }

  get entity() {
    return this.siteForm.get('entity') as AbstractControl;
  }

  get name() {
    return this.siteForm.get('name') as AbstractControl;
  }

  get name2() {
    return this.siteForm.get('name2') as AbstractControl;
  }

  get businessId() {
    return this.siteForm.get('businessId') as AbstractControl;
  }

  get type() {
    return this.siteForm.get('type') as AbstractControl;
  }

  get address1() {
    return this.siteForm.get('address1') as AbstractControl;
  }

  get address2() {
    return this.siteForm.get('address2') as AbstractControl;
  }

  get zipCode() {
    return this.siteForm.get('zipCode') as AbstractControl;
  }

  get city() {
    return this.siteForm.get('city') as AbstractControl;
  }

  get state() {
    return this.siteForm.get('state') as AbstractControl;
  }

  get country() {
    return this.siteForm.get('country') as AbstractControl;
  }

  get description() {
    return this.siteForm.get('description') as AbstractControl;
  }

  get imageUrlControl() {
    return this.siteForm.get('imageUrl') as AbstractControl;
  }

  initForm(site: Site) {
    site = site ?? ({ address: {} } as any);
    this.name.setValue(site.name ?? null);
    this.name.addAsyncValidators([NameValidators.asyncUniqueNameValidatorByEntity(this.sitesService, site.name ?? '')]);
    this.name2.setValue(site.name2 ?? null);
    this.businessId.setValue(site.businessId ?? null);
    this.type.setValue(site.type ?? SiteType.CUSTOMER_SITE);
    this.address1.setValue(site.address.address1 ?? null);
    this.address2.setValue(site.address.address2 ?? null);
    this.zipCode.setValue(site.address.zipCode ?? null);
    this.city.setValue(site.address.city ?? null);
    this.state.setValue(site.address.state ?? null);
    this.country.setValue(site.address.country ?? null);
    this.description.setValue(site.description ?? null);
    this.imageUrlControl.setValue(site.imageUrl ?? null);
  }

  importSite(): void {
    this.importLoading.set(true);
    this.sitesService
      .getSiteFromERP({ source: this.source.value, shipto: this.shipto.value })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (erpData: ERPData) => {
          this.importLoading.set(false);
          this.siteForm.enable();

          if (erpData.status === '0004') {
            this.shipto.setErrors({ incorrectReference: true });
          } else {
            this.updateFormWithERPData(erpData);
            this.entity.setErrors(!!this.entity.value ? null : { airgasError: true });
            this.markRequiredFieldsAsTouched();
          }
        },
        error: () => {
          this.siteForm.enable();
          this.importLoading.set(false);
          this.shipto.setErrors({ serverError: true });
        }
      });
  }

  updateFormWithERPData(erpData: ERPData): void {
    this.siteForm.patchValue({
      name: erpData.name1,
      name2: erpData.name2,
      address1: erpData.houseNumber,
      zipCode: erpData.postalCode,
      city: erpData.city,
      entity: this.sortedEntities().find(
        (e) =>
          e.name.toLowerCase().trim() === erpData.region.toLowerCase().trim() &&
          this.sortedEntities()
            .find((parentEntity) => parentEntity.id === e.parentId)
            ?.name.toLowerCase()
            .trim() === erpData.division.toLowerCase().trim()
      ),
      businessId: erpData.shipToAccountNumber,
      description: !!erpData.maintenancePlant ? `[Maintenance plant: ${erpData.maintenancePlant}]` : ''
    });

    const erpCountry = this.countries().find((c) => c.countryCode === erpData.countryCode);
    this.country.setValue(erpCountry);
    this.initialCountry.set(erpCountry);
    this.state.setValue(erpCountry?.stateProvinces.find((s: { name: string }) => s.name === erpCountry.state));

    if (!!this.entity.value) {
      const entity = this.sortedEntities().find((e) => e.id === this.entity.value.id) ?? null;
      this.initialEntity.set(entity);
    }
    this.name.markAsDirty();
    this.name.updateValueAndValidity();
  }

  markRequiredFieldsAsTouched(): void {
    this.name.markAsTouched();
    this.entity.markAsTouched();
    this.type.markAsTouched();
  }

  onEntitySelection(entity: Entity): void {
    if (!!entity) {
      this.entity.setValue(entity);
      this.onChangeEntity();
      this.parentEntity.set(this.sortedEntities().find((e) => e.id === entity.parentId));
    }
  }

  resetEntity(): void {
    this.entity.reset();
    this.parentEntity.set(undefined);
  }

  onChangeEntity(): void {
    this.name.enable();
  }

  onCountrySelection(country?: any): void {
    if (!!country && !this.country.untouched) {
      this.state.reset();
    }

    if (!!country) {
      this.country.setValue(country);
    }
  }

  resetCountry(): void {
    if (!this.country.untouched) {
      this.country.reset();
      this.state.reset();
      this.country.markAsTouched();
    } else {
      this.country.reset();
    }
  }

  onSelectImage(image: string): void {
    this.siteForm.markAsDirty();
    this.imageUrl.set(image);
  }

  getSiteToSave(): Site {
    return {
      ...this.site(),
      entity: this.entity.value ? { id: this.entity.value.id, name: this.entity.value.name } : null,
      name: this.name.value?.trim(),
      name2: this.name2.value ? this.name2.value.trim() : null,
      businessId: this.businessId.value ? this.businessId.value.trim() : null,
      type: this.type.value,
      address: {
        address1: this.address1.value ? this.address1.value.trim() : null,
        address2: this.address2.value ? this.address2.value.trim() : null,
        city: this.city.value.trim(),
        state: this.state.value ?? null,
        zipCode: this.zipCode.value ? this.zipCode.value.trim() : null,
        country: this.country.value.countryCodeAlpha3
      },
      description: this.description.value ? this.description.value.trim() : null,
      imageUrl: this.imageUrl() ?? null
    } as Site;
  }

  private emitChangeEffect(): void {
    effect(() => {
      const event = this.formChanges();

      if ((event as StatusChangeEvent)?.status === 'VALID') {
        this.changedForm.emit(this.getSiteToSave());
      } else if (this.siteForm.dirty) {
        this.changedForm.emit(null);
      }
    });
  }

  private initCountriesEffect(): void {
    effect(
      () => {
        const countries = this.countries();
        const site = this.site();
        this.initialCountry.set(countries?.find((c) => c.countryCodeAlpha3 === site?.address?.country));
      },
      { allowSignalWrites: true }
    );
  }

  private initEntitiesEffect(): void {
    effect(
      () => {
        const sortedEntities = this.sortedEntities();
        const site = this.site();
        this.initialEntity.set(sortedEntities?.find((e) => e.id === site?.entity?.id) ?? null);
      },
      { allowSignalWrites: true }
    );
  }

  private initInitialCountryEffect(): void {
    effect(
      () => {
        const initialCountry = this.initialCountry();
        const site = this.site();
        const initialState = initialCountry?.stateProvinces?.find((state: { name: string }) => state?.name === site?.address?.state);
        if (this.siteForm) {
          this.state.setValue(initialState?.name);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private initInitialEntityEffect(): void {
    effect(
      () => {
        const initialEntity = this.initialEntity();
        if (this.siteForm) {
          this.entity.setValue(initialEntity);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private initFormEffect(): void {
    effect(
      () => {
        const site = this.site();
        this.initForm(site);
        this.imageUrl.set(site?.imageUrl);
      },
      { allowSignalWrites: true }
    );
  }
}
