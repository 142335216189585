import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TopicsService } from '../../services/topics.service';
import { TopicsDbActions, TopicsUiActions } from '../actions';
import * as fromTopics from '../reducers';

@Injectable()
export class TopicsEffects {
  listTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.listTopics),
      switchMap((action) =>
        this.topicsService.listTopics(action.request).pipe(
          map((response) => TopicsDbActions.listTopicsSuccess({ response })),
          catchError((error) => of(TopicsDbActions.listTopicsFailure({ error })))
        )
      )
    )
  );

  getTopicById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.getTopicById),
      switchMap((action) =>
        this.topicsService.getTopicById(action.topicId).pipe(
          map((selectedTopic) => TopicsDbActions.getTopicByIdSuccess({ selectedTopic })),
          catchError((error) => of(TopicsDbActions.getTopicByIdFailure({ error })))
        )
      )
    )
  );

  addTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.addTopic),
      concatMap((action) =>
        this.topicsService.addTopic(action.topicToAdd).pipe(
          map((addedTopic) => TopicsDbActions.addTopicSuccess({ addedTopic })),
          catchError((error) => of(TopicsDbActions.addTopicFailure({ error })))
        )
      )
    )
  );

  addTopicThenConfigure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.addTopicThenConfigure),
      concatMap((action) =>
        this.topicsService.addTopic(action.topicToAdd).pipe(
          map((addedTopic) => TopicsDbActions.addTopicThenConfigureSuccess({ addedTopic })),
          catchError((error) => of(TopicsDbActions.addTopicThenConfigureFailure({ error })))
        )
      )
    )
  );

  actionOnTopicThenListTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsDbActions.deleteTopicSuccess),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromTopics.getPagination), this.store.select(fromTopics.getCurrentFilters)))),
      map(([_, pagination, filters]) => TopicsUiActions.listTopics({ request: { limit: pagination.limit, page: pagination.currentPage, filters } }))
    )
  );

  updateTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.updateTopic),
      concatMap((action) =>
        this.topicsService.updateTopic(action.topicToUpdate).pipe(
          map((updatedTopic) => TopicsDbActions.updateTopicSuccess({ updatedTopic })),
          catchError((error) => of(TopicsDbActions.updateTopicFailure({ error })))
        )
      )
    )
  );

  subscribeToTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.subscribeToTopic),
      switchMap((action) =>
        this.topicsService.subscribeToTopic(action.topic).pipe(
          map((updatedTopic) => TopicsDbActions.subscribeToTopicSuccess({ updatedTopic: { id: updatedTopic.id, changes: updatedTopic } })),
          catchError((error) => of(TopicsDbActions.subscribeToTopicFailure({ error })))
        )
      )
    )
  );

  unsubscribeToTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.unsubscribeToTopic),
      switchMap((action) =>
        this.topicsService.unsubscribeToTopic(action.topic).pipe(
          map((updatedTopic) => TopicsDbActions.unsubscribeToTopicSuccess({ updatedTopic: { id: updatedTopic.id, changes: updatedTopic } })),
          catchError((error) => of(TopicsDbActions.unsubscribeToTopicFailure({ error })))
        )
      )
    )
  );

  deleteTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TopicsUiActions.deleteTopic),
      concatMap((action) =>
        this.topicsService.deleteTopic(action.topicToDelete).pipe(
          map((deletedTopic) => TopicsDbActions.deleteTopicSuccess({ deletedTopic })),
          catchError((error) => of(TopicsDbActions.deleteTopicFailure({ error })))
        )
      )
    )
  );

  deleteTopicSuccessThenNavigateToMV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TopicsDbActions.deleteTopicSuccess),
        tap(() => this.router.navigate(['on-call-management', 'topics']))
      ),
    { dispatch: false }
  );

  navigateToTopicDetailsAfterCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TopicsDbActions.addTopicSuccess),
        tap((action) => {
          this.router.navigate(['on-call-management', 'topics', action.addedTopic.id]);
        })
      ),
    { dispatch: false }
  );

  navigateToDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TopicsUiActions.navigateToTopicDetails),
        tap((action) => this.router.navigate(['on-call-management', 'topics', action.toNavigateTo.id]))
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TopicsDbActions.addTopicSuccess,
          TopicsDbActions.addTopicThenConfigureSuccess,
          TopicsDbActions.updateTopicSuccess,
          TopicsDbActions.deleteTopicSuccess,
          TopicsDbActions.subscribeToTopicSuccess,
          TopicsDbActions.unsubscribeToTopicSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TopicsDbActions.addTopicFailure,
          TopicsDbActions.addTopicThenConfigureFailure,
          TopicsDbActions.updateTopicFailure,
          TopicsDbActions.listTopicsFailure,
          TopicsDbActions.deleteTopicFailure,
          TopicsDbActions.subscribeToTopicFailure,
          TopicsDbActions.unsubscribeToTopicFailure,
          TopicsDbActions.getTopicByIdFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TopicsUiActions.listTopics,
          TopicsUiActions.addTopic,
          TopicsUiActions.addTopicThenConfigure,
          TopicsUiActions.updateTopic,
          TopicsUiActions.deleteTopic,
          TopicsUiActions.addTopicToProtocol,
          TopicsUiActions.subscribeToTopic,
          TopicsUiActions.unsubscribeToTopic,
          TopicsUiActions.getTopicById
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TopicsDbActions.listTopicsSuccess,
          TopicsDbActions.listTopicsFailure,
          TopicsDbActions.addTopicSuccess,
          TopicsDbActions.addTopicFailure,
          TopicsDbActions.addTopicThenConfigureSuccess,
          TopicsDbActions.addTopicThenConfigureFailure,
          TopicsDbActions.updateTopicSuccess,
          TopicsDbActions.updateTopicFailure,
          TopicsDbActions.deleteTopicSuccess,
          TopicsDbActions.deleteTopicFailure,
          TopicsDbActions.addTopicToProtocolSuccess,
          TopicsDbActions.addTopicToProtocolFailure,
          TopicsDbActions.subscribeToTopicSuccess,
          TopicsDbActions.subscribeToTopicFailure,
          TopicsDbActions.unsubscribeToTopicSuccess,
          TopicsDbActions.unsubscribeToTopicFailure,
          TopicsDbActions.getTopicByIdSuccess,
          TopicsDbActions.getTopicByIdFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly topicsService: TopicsService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {}
}
