import { EnvironmentProviders, InjectionToken, Type, makeEnvironmentProviders } from '@angular/core';
import { AbstractConfigureProductsFormService } from './abstract-configure-products-form.service';

export interface ConfigureProductsFormSettings {
  configureProductsFormService: Type<AbstractConfigureProductsFormService>;
}

export const CONFIGURE_PRODUCTS_FORM_SETTINGS = new InjectionToken<ConfigureProductsFormSettings>('CONFIGURE_PRODUCTS_FORM_SETTINGS');

export function provideConfigureProductsFormSettings(settings: ConfigureProductsFormSettings): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: AbstractConfigureProductsFormService, useClass: settings.configureProductsFormService }]);
}
