import { NgClass } from '@angular/common';
import { Component, effect, input, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { Device } from '@iot-platform/models/i4b';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

type DeviceStatus = 'cCF' | 'warning' | 'success' | 'undefined';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, MatIcon, NgClass, DateFormatPipe],
  selector: 'iot-platform-ui-device-info-status',
  templateUrl: './device-info-status.component.html',
  styleUrls: ['./device-info-status.component.scss']
})
export class DeviceInfoStatusComponent {
  device = input<Device | null>(null);

  deviceStatus: WritableSignal<DeviceStatus> = signal('undefined');
  iconToDisplay: WritableSignal<{ name: string | null; className: string | null }> = signal({
    name: null,
    className: null
  });

  constructor() {
    this.initDeviceEffect();
  }

  static isLastCall(dateTime: string): boolean {
    const timestamp: number = Date.now();
    const lastCallDate: number = Date.parse(dateTime);
    const days = (timestamp - lastCallDate) / 1000 / 60 / 60 / 24;
    return days > 1;
  }

  setDeviceStatusAndIconToDisplay(device: Device | null): void {
    if (device && device.cyclicalCallFault && device.cyclicalCallFault.status) {
      this.deviceStatus.set('cCF');
      this.iconToDisplay.set({ name: 'cyclicalCallFault', className: 'ccf' });
      return;
    } else if (
      device &&
      device.cyclicalCallFault &&
      !device.cyclicalCallFault.status &&
      device.lastCallStatus &&
      DeviceInfoStatusComponent.isLastCall(device.lastCallStatus.datetime as string)
    ) {
      this.deviceStatus.set('warning');
      this.iconToDisplay.set({ name: 'clock', className: 'warning' });
      return;
    } else if (
      device &&
      device.lastCallStatus &&
      device.lastCallStatus.name === 'success' &&
      !DeviceInfoStatusComponent.isLastCall(device.lastCallStatus.datetime as string)
    ) {
      this.deviceStatus.set('success');
      this.iconToDisplay.set({ name: 'done', className: 'success' });
      return;
    } else {
      this.deviceStatus.set('undefined');
      this.iconToDisplay.set({ name: null, className: null });
    }
  }

  private initDeviceEffect(): void {
    effect(
      () => {
        const device = this.device();
        this.setDeviceStatusAndIconToDisplay(device);
      },
      { allowSignalWrites: true }
    );
  }
}
