import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MapComponent } from './components';
import { MapEffects } from './state/effects/map.effects';
import * as fromMap from './state/reducers';

@NgModule({
  imports: [MapComponent, StoreModule.forFeature(fromMap.featureKey, fromMap.reducers), EffectsModule.forFeature([MapEffects])],
  exports: [MapComponent]
})
export class IotPlatformMapsModule {}
