<iot-platform-ui-async-autocomplete-multiple-selects
  (reset)="onReset()"
  (search)="onSearch($event)"
  (selectionChanged)="onSelect($event)"
  [autocomplete]="true"
  [clearOnSelect]="clearOnSelect()"
  [currentFilters]="currentFilters()"
  [data]="filteredTimezones()"
  [debounceTime]="0"
  [disableOnlyOptions]="disableOnlyOptions()"
  [disabled]="disabled()"
  [displaySearchIcon]="false"
  [errorMessage]="errorMessage()"
  [initialItem]="selectedTimezone()"
  [minLength]="0"
  [multiSelect]="data()?.multiSelect"
  [placeholder]="placeholder() | translate"
  [required]="required()"
  displayKey="key"
  fxFlex
/>
