import { Action, createReducer, on } from '@ngrx/store';
import { AssetEventsBySiteLogsDbActions, AssetEventsBySiteLogsUiActions } from '../../actions';
import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';

export const assetEventsBySiteLogsUiFeatureKey = 'assetEventsBySiteLogsUi';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: unknown;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null
};

const assetEventsBySiteLogsUiReducer = createReducer(
  initialState,
  on(AssetEventsBySiteLogsUiActions.loadLogsByAssetEvent, (state: State): State => ({ ...state, loading: true, loaded: false, error: null })),
  on(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventSuccess, (state: State): State => ({ ...state, loading: false, loaded: true })),
  on(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventFailure, (state: State, { error }): State => ({ ...state, loading: false, loaded: false, error })),
  // ****
  on(AssetEventsBySiteLogsUiActions.createLogByAssetEvent, (state: State): State => ({ ...state, loading: true, loaded: false, error: null })),
  on(AssetEventsBySiteLogsDbActions.createLogByAssetEventSuccess, (state: State): State => ({ ...state, loading: false, loaded: true })),
  on(AssetEventsBySiteLogsDbActions.createLogByAssetEventFailure, (state: State, { error }): State => ({ ...state, loading: false, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (): State => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsBySiteLogsUiReducer(state, action);
}

export const getLogsLoading = (state: State) => state.loading;
export const getLogsLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
