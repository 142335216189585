import { Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';

import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { I4BCellType } from '@iot-platform/models/grid-engine';

import { MasterViewEngineDirective } from '../../master-view-engine/master-view-table/master-view-engine.directive';
import { MasterViewComponentFactory } from '../../master-view-engine/master-view-table/master-view-factory';
import { MasterViewComponent } from '../../master-view-engine/master-view-table/master-view.component';

@Component({
  selector: 'i4b-table-engine-master-view-cell-container',
  templateUrl: './master-view-cell-container.component.html',
  styleUrls: ['./master-view-cell-container.component.scss']
})
export class MasterViewCellContainerComponent implements OnInit, OnChanges {
  @Input() column: any;
  @Input() element: any;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  @ViewChild(MasterViewEngineDirective, { static: true })
  iotPlatformUiMasterViewEngineDirective: MasterViewEngineDirective;

  constructor(private masterViewComponentFactory: MasterViewComponentFactory) {}

  ngOnInit() {
    this.initViewContainerRef();
    this.addCell();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('element')) {
      this.initViewContainerRef();
      this.addCell();
    }
  }

  initViewContainerRef(): ViewContainerRef {
    const viewContainerRef = this.iotPlatformUiMasterViewEngineDirective.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef;
  }

  addCell() {
    const componentRef: ComponentRef<MasterViewComponent> = this.masterViewComponentFactory.createCellComponent(
      this.column.cellType,
      this.initViewContainerRef()
    );
    const elementProperties = this.column.id.split('&');
    let dataToDisplay = '';

    if (elementProperties.length === 1) {
      dataToDisplay = this.getElementPropertyByPath(elementProperties[0]);
    }
    if (elementProperties.length > 1) {
      elementProperties.forEach((property) => {
        const subData = this.getElementPropertyByPath(property);

        if (subData !== null) {
          dataToDisplay = dataToDisplay.concat(' ', subData).trim();
        }
      });
    }

    if (this.column.cellTypeOptions) {
      componentRef.instance.cellOptions = this.column.cellTypeOptions;
    }

    componentRef.instance.data = dataToDisplay;
    componentRef.instance.rawData = this.element;
    componentRef.instance.elementId = this.element.id;

    if (this.column.isLink || this.column.cellType === I4BCellType.BUTTON_CELL) {
      componentRef.instance.clickEvent = this.column.clickEvent;
    }

    if (this.column.cellType === 'assetVariableCell') {
      componentRef.instance.columnId = this.column.id;
      componentRef.instance.userPermissions = this.userPermissions;
    }

    if (componentRef.instance.dispatchEvent) {
      componentRef.instance.dispatchEvent.subscribe((event: MasterViewEngineEvent) => {
        if (event) {
          this.dispatchEvent.emit(event);
        }
      });
    }
  }

  getElementPropertyByPath(path: string): string {
    const clonedElement = Object.assign({}, this.element);
    return path.split('.').reduce((acc, value) => {
      if (acc) {
        return (acc = acc[value]);
      } else {
        return acc;
      }
    }, clonedElement);
  }
}
