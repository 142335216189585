import { Filter, PlatformRequest, Product } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listProducts = createAction('[Products] List Products', props<{ request: PlatformRequest }>());
//
export const addProduct = createAction('[Products] Add Product', props<{ productToAdd: Product }>());
//
export const updateProduct = createAction('[Products] Update Product', props<{ productToUpdate: Product }>());
//
export const saveProductFilters = createAction('[Products] Save Product Filters', props<{ filters: Filter[] }>());
//
export const deleteProduct = createAction('[Products] Delete Product', props<{ productToDelete: Product }>());
