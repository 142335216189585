import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';

/* eslint-disable  @typescript-eslint/ban-types */
export abstract class BaseSelector<S, S1> {
  selectParentState: MemoizedSelector<object, S>;
  selectState: MemoizedSelector<object, S1>;

  protected constructor(public parentFeatureKey: string, public featureKey: string) {
    this.selectParentState = createFeatureSelector<S>(parentFeatureKey);
    this.selectState = createSelector(this.selectParentState, (state: S): S1 => 
      /* eslint-disable  @typescript-eslint/no-explicit-any */
       (state as any)[featureKey]
    );
  }
}
