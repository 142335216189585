<grid-engine-grid-page
  (dispatchGridEvent)="onDispatchGridEvent($event)"
  (dispatchMasterViewEngineEvent)="dispatchMasterViewEngineEvent.emit($event)"
  (pageChange)="onPageChange($event)"
  (selectRow)="onSelectRow($event)"
  (sortChange)="onSortChange($event)"
  [gridSort]="gridSort()"
  [grid]="grid()"
  [hidePaginator]="hidePaginator()"
  [userPermissions]="userPermissions()"
  [visibleNodeId]="visibleNodeId()"
/>
