import { BaseUser } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { BusinessProfilesMembersApiActions, BusinessProfilesPageActions } from '../actions/';

export const adminBusinessProfilesMembersApiFeatureKey = 'adminBusinessProfilesMembersApi';

export interface State extends EntityState<BaseUser> {
  selectedMemberId: string;
  error: any;
}

export const adapter: EntityAdapter<BaseUser> = createEntityAdapter<BaseUser>({
  selectId: (user: BaseUser) => user.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedMemberId: null,
  error: null
});

export const adminBusinessProfilesMembersReducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listMembersByBusinessProfile, (state) => adapter.removeAll(state)),
  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileSuccess, (state, { members }) => adapter.setAll(members, state)),

  on(BusinessProfilesMembersApiActions.addMemberToBusinessProfileSuccess, (state, { addedMember }) => adapter.addOne(addedMember, state)),
  on(BusinessProfilesMembersApiActions.addMemberToBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesMembersApiActions.removeMemberFromBusinessProfileSuccess, (state, { removedMember }) => adapter.removeOne(removedMember.id, state)),
  on(BusinessProfilesMembersApiActions.removeMemberFromBusinessProfileFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return adminBusinessProfilesMembersReducer(state, action);
}

export const getSelectedMemberId = (state: State) => state.selectedMemberId;
