import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BuildInfo } from '@iot-platform/models/common';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService {
  constructor(
    private readonly httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  setBuildInfo(): void {
    this.httpClient
      .get<BuildInfo>('assets/data/build.json')
      .pipe(tap((data) => this.localStorage.set(LocalStorageKeys.STORAGE_APP_BUILD_INFO, JSON.stringify(data))))
      .subscribe();
  }
}
