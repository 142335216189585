import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CommonGenericModel, CommonIndexedPagination, Contact, Environment, TagCategory } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapPanelInfoService {
  constructor(
    @Inject('environment') private readonly environment: Environment,
    private http: HttpClient,
    private authz: AuthorizationService
  ) {}

  getOne<T extends CommonGenericModel>(id: string, concept: string): Observable<T> {
    return this.http.get<T>(`${this.environment.api.url}/${concept}/${id}`);
  }

  getSite(id: string): Observable<any> {
    const site$ = this.getOne<Site>(id, 'sites');
    const contacts$ = this.getContactsBySiteId(id);
    const tags$ = this.getTagsBySiteId(id);
    const assets$ = this.getAssetsBySiteId(id);
    const devices$ = this.getDevicesBySiteId(id);
    if (this.authz.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ)) {
      return combineLatest([site$, tags$, assets$, devices$, contacts$]);
    } else {
      return combineLatest([site$, tags$, assets$, devices$]);
    }
  }

  getAsset(id: string): Observable<any> {
    const asset$ = this.getOne<Asset>(id, 'assets');
    const site$ = asset$.pipe(switchMap((asset: Asset) => this.getOne<Site>(asset.site?.id as string, 'sites')));
    const tags$ = this.getTagsByAssetId(id);
    return combineLatest([asset$, site$, tags$]);
  }

  getDevice(id: string): Observable<any> {
    const device$ = this.getOne<Device>(id, 'devices');
    const site$ = device$.pipe(switchMap((device: Device) => this.getOne<Site>(device.site?.id as string, 'sites')));
    const tags$ = this.getTagsByDeviceId(id);
    return combineLatest([device$, site$, tags$]);
  }

  getContactsBySiteId(siteId: string): Observable<Contact[]> {
    return this.http
      .get<{ content: Contact[]; page: CommonIndexedPagination }>(
        `${this.environment.api.url}${this.environment.api.endpoints['sites']}/${siteId}${this.environment.api.endpoints['contacts']}`
      )
      .pipe(map((response) => response.content));
  }

  getTagsBySiteId(siteId: string): Observable<TagCategory[]> {
    return this.http
      .get<{ page: any; content: TagCategory[] }>(`${this.environment.api.url}${this.environment.api.endpoints['sites']}/${siteId}/tags`)
      .pipe(map((response: { page: any; content: TagCategory[] }) => response.content));
  }

  getTagsByAssetId(assetId: string): Observable<TagCategory[]> {
    return this.http
      .get<{ page: any; content: TagCategory[] }>(`${this.environment.api.url}${this.environment.api.endpoints['assets']}/${assetId}/tags`)
      .pipe(map((response: { page: any; content: TagCategory[] }) => response.content));
  }

  getTagsByDeviceId(deviceId: string): Observable<TagCategory[]> {
    return this.http
      .get<{ page: any; content: TagCategory[] }>(`${this.environment.api.url}${this.environment.api.endpoints['devices']}/${deviceId}/tags`)
      .pipe(map((response: { page: any; content: TagCategory[] }) => response.content));
  }

  getAssetsBySiteId(siteId: string): Observable<Asset[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', 1000);
    params = params.set('page', 0);
    params = params.set('siteId', siteId);

    return this.http.get<Asset[]>(`${this.environment.api.url + this.environment.api.endpoints['assets']}`, { params }).pipe(map((data: any) => data.content));
  }

  getDevicesBySiteId(siteId: string): Observable<Device[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', 1000);
    params = params.set('page', 0);
    params = params.set('siteId', siteId);
    return this.http
      .get<Device[]>(`${this.environment.api.url + this.environment.api.endpoints['devices']}`, { params })
      .pipe(map((data: any) => data.content));
  }
}
