import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'i4b-table-engine-dynamic-icon-cell',
  templateUrl: './dynamic-icon-cell.component.html',
  styleUrls: ['./dynamic-icon-cell.component.scss']
})
export class DynamicIconCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;

  @Output() dispatchEvent;

  getTooltip() {
    return this.data ? this.rawData[this.cellOptions.tooltip.key] : '';
  }
}
