import { effect, inject, Injectable, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { map, withLatestFrom } from 'rxjs/operators';
import { TrackingData } from '../models/tracking-data.model';
import { TrackingTag } from '../models/tracking-tag.model';
import { AbstractTrackingProvider } from '../services/abstract-tracking-provider.service';
import { AbstractTrackingSettingsProviderService } from './abstract-tracking-settings-provider.service';
import { TrackingSettingsService } from './tracking-settings.service';
import { TRACKING_PROVIDERS } from './tracking.tokens';

@Injectable()
export class TrackingService {
  trackingData: Signal<
    | {
        eventId: string;
        trackingData: TrackingData & { currentUrl: string; previousUrl: string };
      }
    | undefined
  >;
  protected readonly providers: AbstractTrackingProvider[] = inject(TRACKING_PROVIDERS);
  protected readonly trackingSettingsService: TrackingSettingsService = inject(TrackingSettingsService);
  protected readonly trackingSettingsProvider: AbstractTrackingSettingsProviderService = inject(AbstractTrackingSettingsProviderService);

  constructor() {
    this.trackingData = toSignal(
      this.trackingSettingsProvider.getCurrentUrl$().pipe(
        withLatestFrom(this.trackingSettingsProvider.getPreviousUrl$(), this.trackingSettingsProvider.getTrackingData$()),
        map(([url, previousUrl, trackingData]: [string, string, TrackingData]) => ({
          eventId: this.cleanUrl(url),
          trackingData: { ...trackingData, currentUrl: url, previousUrl }
        })),
        takeUntilDestroyed()
      )
    );

    effect(() => {
      const trackingData = this.trackingData();
      if (trackingData) {
        this.trackEvent(trackingData.eventId, trackingData.trackingData);
      }
    });
  }

  trackEvent(trackingEventId: string, trackingData: TrackingData): void {
    const tag = this.trackingSettingsService.getSettings().tags[trackingEventId];
    if (tag) {
      this.providers.forEach((provider) => {
        provider.track(tag as TrackingTag, {
          ...trackingData,
          trackingEventId
        });
      });
    }
  }

  private cleanUrl(url: string): string {
    url = this.cleanQueryStrings(url);
    url = this.cleanPathStrings(url);
    return url;
  }

  private cleanQueryStrings(url: string): string {
    const queryStringDelimiter = '?';
    if (url.indexOf(queryStringDelimiter) > -1) {
      url = url.substring(0, url.indexOf(queryStringDelimiter));
    }
    return url;
  }

  private cleanPathStrings(url: string): string {
    const pipeDelimiter = '/';
    if (url.indexOf(pipeDelimiter) > -1) {
      url = url.substring(url.lastIndexOf(pipeDelimiter) + 1);
    }
    return url;
  }
}
