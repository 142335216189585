import { UserAccount } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';

import { PreferencesActions } from '../../../preferences/state/actions';
import { ProfileActions } from '../actions';

export const userProfileDbFeatureKey = 'userProfileDb';

export interface State {
  currentUser: UserAccount;
  maxApiKeysReached: boolean;
  apiKeys: any[];
  detailedApiKey: any;
}

export const initialState: State = {
  currentUser: null,
  maxApiKeysReached: false,
  apiKeys: [],
  detailedApiKey: null
};

export const reducer = createReducer(
  initialState,
  on(ProfileActions.loadUserProfile, (state: State) => ({ ...state })),
  on(ProfileActions.loadUserProfileSuccess, (state: State, { user }) => ({ ...state, currentUser: user })),
  on(ProfileActions.saveUserProfile, (state: State, { userToUpdate }) => ({ ...state, currentUser: userToUpdate })),
  on(ProfileActions.saveUserProfileSuccess, (state: State, { updatedUser }) => ({ ...state, currentUser: updatedUser })),

  on(PreferencesActions.saveUserPreferencesSuccess, (state: State, { user }) => ({ ...state, currentUser: user })),
  on(ProfileActions.loadApiKeysSuccess, (state: State, { apiKeys }) => ({ ...state, apiKeys, maxApiKeysReached: apiKeys.length === 2 })),
  on(ProfileActions.getApiKeySuccess, (state: State, { detailedApiKey }) => ({ ...state, detailedApiKey }))
);

export const getCurrentUser = (state: State) => state.currentUser;
export const getMaxApiKeysReached = (state: State) => state.maxApiKeysReached;
export const getApiKeys = (state: State) => state.apiKeys;
export const getDetailedApiKey = (state: State) => state.detailedApiKey;
