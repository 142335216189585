import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';

import { Device, DeviceVariable, Event } from '@iot-platform/models/i4b';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, InfoDisplayPipe, NumberFormatPipe, DateFormatPipe, ValueUnitFormatPipe],
  providers: [InfoDisplayPipe, NumberFormatPipe, DateFormatPipe, ValueUnitFormatPipe],
  selector: 'iot-platform-ui-detail-device-variable-card',
  templateUrl: './detail-device-variable-card.component.html',
  styleUrls: ['./detail-device-variable-card.component.scss']
})
export class DetailDeviceVariableCardComponent {
  device = input<Device>();
  deviceVariable = input<DeviceVariable>();
  event = input<Event>();

  eventVariableProcessedUnit: Signal<string | null> = computed(() => {
    const event = this.event();
    if (event.context?.deviceVariable?.unit && event.context?.deviceVariable?.unit[0] === '/') {
      const lastRecords =
        event.context.deviceVariable.value !== null
          ? [
              {
                value: event.context.deviceVariable.value,
                datetime: ''
              }
            ]
          : [];

      return ProcessMultipleStateVariablesUtil.getProcessedUnit({
        ...event.context.deviceVariable,
        lastRecords
      } as never);
    }
    return null;
  });
}
