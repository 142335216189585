export const TrackingEventLabels = {
  OPEN_ADD_FAVORITE_VIEW_DIALOG: 'open_add_favorite_view',
  ADD_FAVORITE_VIEW: 'add_favorite_view',
  OPEN_EDIT_FAVORITE_VIEW_DIALOG: 'open_edit_favorite_view',
  UPDATE_FAVORITE_VIEW: 'update_favorite_view',
  OPEN_DELETE_FAVORITE_VIEW_DIALOG: 'open_delete_favorite_view',
  DELETE_FAVORITE_VIEW: 'delete_favorite_view',
  SELECT_FAVORITE_VIEW: 'select_favorite_view',
  RESET_TO_DEFAULT_FAVORITE_VIEW: 'reset_to_default_favorite_view',
  APPLY_FILTERS: 'apply_filters',
  CLOSE_FILTER_ENGINE: 'close_filter_engine',
  OPEN_FILTER_ENGINE: 'open_filter_engine',
  REFRESH_DATA: 'refresh_data',
  OPEN_GRID_SETTINGS_DIALOG: 'open_grid_settings',
  DELETE_GRID: 'delete_grid',
  UPDATE_GRID: 'update_grid',
  ADD_GRID: 'add_grid',
  SELECT_GRID: 'select_grid',
  OPEN_MOVE_TO_DIALOG: 'open_move_to',
  MOVE_TO: 'move_to',
  EXPORT_DATA: 'export_data',
  OPEN_DEVICE_INFO_DIALOG: 'open_device_info_form',
  UPDATE_DEVICE_INFO: 'update_device',
  BULK_UPDATE_DEVICE_DETAILS: 'bulk_update_device',
  OPEN_CHART_BY_VARIABLE: 'open_chart_by_variable',
  OPEN_CHART: 'open_chart',
  OPEN_BULK_MANAGE_TAG_ADD_DIALOG: 'open_bulk_manage_tag_add',
  OPEN_BULK_MANAGE_TAG_REMOVE_DIALOG: 'open_bulk_manage_tag_remove',
  BULK_ADD_TAG: 'bulk_add_tag',
  BULK_REMOVE_TAG: 'bulk_remove_tag',
  BULK_SEND_COMMAND: 'bulk_send_command',
  SEND_COMMAND: 'send_command',
  OPEN_BULK_DEVICE_EDIT_FORM: 'open_bulk_device_edit_form',
  CONFIGURE_CONNECTOR: 'configure_connector',
  SEND_REFRESH_COMMAND: 'send_refresh_command',
  NAVIGATE_TO_SITE: 'navigate_to_site',
  NAVIGATE_TO_DEVICE: 'navigate_to_device',
  CHANGE_DISPLAY_MODE: 'change_display_mode',
  TAB_CHANGE: 'tab_change',
  OPEN_HISTORY: 'open_history',
  OPEN_DEVICE_NAME_EDIT_FORM: 'open_device_name_edit_form',
  OPEN_DEVICE_AFFILIATE_EDIT_FORM: 'open_device_affiliate_edit_form',
  OPEN_DEVICE_GENERAL_INFO_EDIT_FORM: 'open_device_general_info_edit_form',
  OPEN_DEVICE_EDIT_FILES_FORM: 'open_device_edit_files_form',
  OPEN_BULK_DEVICES_EDIT_FILES_FORM: 'open_bulk_devices_edit_files_form',
  UPDATE_DEVICE_FILES: 'update_device_files',
  BULK_UPDATE_DEVICES_FILES: 'bulk_update_devices_files',
};
