import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BDeviceEventsButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.DEVICE_EVENTS,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          bulkActions: [
            {
              order: 10,
              key: 'bulkSnooze',
              label: 'EVENTS.TABLE.BUTTONS.SNOOZE',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
            },
            {
              order: 20,
              key: 'bulkAcknowledge',
              label: 'EVENTS.TABLE.BUTTONS.ACKNOWLEDGE',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
            },
            {
              order: 30,
              key: 'bulkClose',
              label: 'EVENTS.TABLE.BUTTONS.CLOSE',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
            }
          ],
          singleActions: [
            {
              order: 10,
              key: 'snooze',
              label: 'EVENTS.TABLE.BUTTONS.SNOOZE',
              enableConditions: [[{ key: 'status', value: 'active' }]],
              disableConditions: [[{ key: 'snoozeQuota', value: 0 }]],
              visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
            },
            {
              order: 20,
              key: 'acknowledge',
              label: 'EVENTS.TABLE.BUTTONS.ACKNOWLEDGE',
              enableConditions: [[{ key: 'status', value: 'active' }]],
              visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
            },
            {
              order: 30,
              key: 'close',
              label: 'EVENTS.TABLE.BUTTONS.CLOSE',
              enableConditions: [[{ key: 'status', value: 'acknowledged' }]],
              disableConditions: [
                [
                  { key: 'status', value: 'closed' },
                  { key: 'status', value: 'snoozed' }
                ]
              ],
              visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
            }
          ],
          visibleConditions: [[{ key: 'canUpdateEvent', value: true }]],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
