import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const escalationProtocolsColumnSettings = [
  {
    order: 5,
    id: 'name',
    name: 'IOT_DICTIONARY.NAME',
    catalogName: 'NAME',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    width: '',
    type: 'start center'
  },
  {
    order: 20,
    id: 'entity.name',
    name: 'IOT_DICTIONARY.ENTITY',
    catalogName: 'ENTITY',
    default: true,
    sortable: true,
    sortProperty: 'entity.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    type: 'start center'
  },
  {
    order: 40,
    id: 'createdBy',
    name: 'IOT_DICTIONARY.CREATED_BY',
    catalogName: 'CREATED BY',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.USER_CELL,
    isLink: false,
    clickEvent: '',
    width: '',
    type: 'start center'
  },
  {
    order: 60,
    id: 'createdAt',
    name: 'IOT_DICTIONARY.CREATED_AT',
    catalogName: 'CREATED AT',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    width: '',
    type: 'start center'
  },
  {
    order: 70,
    id: 'evaluation_mode',
    name: 'IOT_DICTIONARY.EVALUATION_MODE',
    catalogName: 'EVALUATION MODE',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      translateKey: 'IOT_DICTIONARY.',
      applyLowerCase: true
    },
    isLink: false,
    clickEvent: '',
    width: '',
    type: 'start center'
  },
  {
    order: 80,
    id: 'topics',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TOPIC_SUBSCRIPTIONS',
    catalogName: 'TOPIC SUBSCRIPTIONS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    clickEvent: '',
    width: '',
    type: 'center center'
  }
];
