import { FavoriteView, PlatformResponse } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createAction, props } from '@ngrx/store';

export const listFavoriteViewsByBusinessProfileSuccess = createAction(
  '[Admin Business Profile/API] List Favorite Views by Business Profile Success',
  props<{ response: PlatformResponse }>()
);
export const listFavoriteViewsByBusinessProfileFailure = createAction(
  '[Admin Business Profile/API] List Favorite Views by Profile Business Failure',
  props<{ error: any }>()
);

export const addFavoriteViewSuccess = createAction(
  '[Admin Business Profile/Admin Page] Add Favorite View Success',
  props<{ addedFavoriteView: FavoriteView; grid?: I4BGrid<I4BGridOptions, I4BGridData> }>()
);

export const addFavoriteViewFailure = createAction('[Admin Business Profile/Admin Page] Add Favorite View Failure', props<{ error: any }>());

export const updateFavoriteViewSuccess = createAction(
  '[Admin Business Profile/API] Update Favorite View Success',
  props<{ updatedFavoriteView: FavoriteView; grid?: I4BGrid<I4BGridOptions, I4BGridData> }>()
);
export const updateFavoriteViewFailure = createAction('[Admin Business Profile/API] Update Favorite View Failure', props<{ error: any }>());

export const deleteFavoriteViewSuccess = createAction(
  '[Admin Business Profile/API] Delete Favorite View Success',
  props<{ deletedFavoriteView: FavoriteView }>()
);
export const deleteFavoriteViewFailure = createAction('[Admin Business Profile/API] Delete Favorite View Failure', props<{ error: any }>());
//
export const addFavoriteViewInAnotherBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Admin Page] Add Favorite View in another Business Profile Success'
);
//
export const duplicateFavoriteViewSuccess = createAction(
  '[Admin Business Profile/API] Duplicate Favorite View Success',
  props<{ duplicatedFavoriteView: FavoriteView }>()
);
export const duplicateFavoriteViewFailure = createAction('[Admin Business Profile/API] Duplicate Favorite View Failure', props<{ error: any }>());
export const duplicateFavoriteViewInAnotherBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Admin Page] Duplicate Favorite View in another Business Profile Success'
);

export const duplicateGridThenAddFavoriteViewFailure = createAction(
  '[Admin Business Profile/API] Duplicate Grid then Add Favorite View Failure',
  props<{ error: any }>()
);
export const duplicateGridThenAddFavoriteViewSuccess = createAction('[Admin Business Profile/Admin Page] Duplicate Grid then Add Favorite View Success');

export const duplicateGridThenUpdateFavoriteViewFailure = createAction(
  '[Admin Business Profile/API] Duplicate Grid then Update Favorite View Failure',
  props<{ error: any }>()
);
export const duplicateGridThenUpdateFavoriteViewSuccess = createAction('[Admin Business Profile/Admin Page] Duplicate Grid then Update Favorite View Success');
