import { TrackingEventNames as BaseTrackingEventNames } from '../../common/tracking/tracking-event-names.model';

export const TrackingEventNames = {
  ...BaseTrackingEventNames,
  ASSET_TEMPLATE_SHELL: 'asset_template_shell',
  ASSET_TEMPLATE_OVERVIEW: 'asset_template_info_page',
  USER_SHELL: 'admin_users_shell',
  USER_OVERVIEW: 'user_info_page',
  PO_EVENT_RULE_SHELL: 'po_event_rule_shell',
  PO_EVENT_RULE_OVERVIEW: 'po_event_rule_info_page'
};
