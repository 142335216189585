import { BusinessProfile } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const createBusinessProfile = createAction(
  '[Admin Business Profiles/Admin Page] Create Business Profile',
  props<{ businessProfile: BusinessProfile }>()
);
export const updateBusinessProfile = createAction(
  '[Admin Business Profiles/Admin Page] Update Business Profile',
  props<{ businessProfile: BusinessProfile }>()
);
export const deleteBusinessProfile = createAction(
  '[Admin Business Profiles/Admin Page] Delete Business Profile',
  props<{ businessProfile: BusinessProfile }>()
);

export const listBusinessProfiles = createAction('[Admin Business Profiles/Admin Page] List');
export const filterBusinessProfiles = createAction('[Admin Business Profiles/Admin Page] Filter Business Profiles', props<{ searchString: string }>());

export const listRolesByBusinessProfile = createAction(
  '[Admin Business Profiles/Admin Page] List Roles By Business Profile',
  props<{ businessProfileId: string }>()
);

export const listMembersByBusinessProfile = createAction(
  '[Admin Business Profiles/Admin Page] List Members By Business Profile',
  props<{ businessProfileId: string }>()
);

export const listTagsByBusinessProfile = createAction(
  '[Admin Business Profile/Admin Page] List Tags By Business Profile',
  props<{ businessProfileId: string }>()
);

export const listFavoriteViewsByBusinessProfile = createAction(
  '[Admin Business Profile/Admin Page] List Favorite Views by Business Profile',
  props<{ businessProfileId: string }>()
);

export const listGridsByBusinessProfile = createAction(
  '[Admin Business Profile/Admin Page] List Grids by Business Profile',
  props<{ businessProfileId: string }>()
);

export const navigateToSelectedOrganization = createAction(
  '[Admin Business Profile/Admin Page] Navigate To Selected Organization',
  props<{ organizationId: string }>()
);
