import { ProcessCellForExportParams } from '@ag-grid-community/core';

export class TranslatedCellHelpers {
  static processValue(params: ProcessCellForExportParams): string {
    const { value, column } = params;
    const {
      cellOptions: { translateKey, applyLowerCase }
    } = column.getColDef().cellRendererParams;
    const key: string = translateKey ?? 'IOT_DICTIONARY.';
    return `${key}${applyLowerCase ? value.toLowerCase() : value}`;
  }
}
