import { TagCategory } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { BusinessProfilesApiActions, BusinessProfilesPageActions, BusinessProfilesTagsApiActions, BusinessProfilesTagsPageActions } from '../actions/';

export const adminBusinessProfilesTagsApiFeatureKey = 'adminBusinessProfilesTagsApi';

export interface State extends EntityState<TagCategory> {
  selectedTagCategoryId: string;
  error: any;
}

function sortByName(a: TagCategory, b: TagCategory): number {
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
}

export const adapter: EntityAdapter<TagCategory> = createEntityAdapter<TagCategory>({
  selectId: (tagCategory: TagCategory) => tagCategory.id,
  sortComparer: sortByName
});

export const initialState: State = adapter.getInitialState({
  selectedTagCategoryId: null,
  error: null
});

export const adminBusinessProfilesTagsReducer = createReducer(
  initialState,
  on(BusinessProfilesApiActions.selectBusinessProfile, (state) => adapter.removeAll(state)),

  on(BusinessProfilesPageActions.listTagsByBusinessProfile, (state) => adapter.removeAll(state)),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileSuccess, (state, { tags }) => adapter.setAll(tags, state)),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesTagsPageActions.updateTagsForBusinessProfile, (state) => adapter.removeAll(state)),
  on(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileSuccess, (state, { addedTags }) => adapter.setAll(addedTags, state)),
  on(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return adminBusinessProfilesTagsReducer(state, action);
}

export const getSelectedTagCategoryId = (state: State) => state.selectedTagCategoryId;
