import { BusinessProfile, Filter, UserAccount } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const UsersActions = createActionGroup({
  source: 'Users',
  events: {
    'Get User By Id': props<{ userId: string }>(),
    'Get User By Id Success': props<{ user: UserAccount }>(),
    'Get User By Id Failure': props<{ error: unknown }>(),

    'Add User': props<{ user: UserAccount }>(),
    'Add User Success': props<{ user: UserAccount }>(),
    'Add User Failure': props<{ error: unknown }>(),

    'Update User': props<{ user: UserAccount }>(),
    'Update User Success': props<{ user: UserAccount }>(),
    'Update User Failure': props<{ error: unknown }>(),

    'Enable User': props<{ user: UserAccount }>(),
    'Enable User Success': props<{ user: UserAccount }>(),
    'Enable User Failure': props<{ error: unknown }>(),

    'Disable User': props<{ user: UserAccount }>(),
    'Disable User Success': props<{ user: UserAccount }>(),
    'Disable User Failure': props<{ error: unknown }>(),

    'Resend User Validation Link': props<{ user: UserAccount }>(),
    'Resend User Validation Link Success': props<{ user: UserAccount }>(),
    'Resend User Validation Link Failure': props<{ error: unknown }>(),

    'Get Business Profiles By User Id': props<{ userId: string }>(),
    'Get Business Profiles By User Id Success': props<{ businessProfiles: BusinessProfile[] }>(),
    'Get Business Profiles By User Id Failure': props<{ error: unknown }>(),

    'Navigate To User Details': props<{ userId: string }>(),

    'Set Filters': props<{ filters: Filter[] }>()
  }
});
