import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadExportSpreadsheetsSuccess = createAction('[ExportSpreadsheets] Load Export Spreadsheets Success', props<{ exports: ExportSpreadsheet[] }>());
export const loadExportSpreadsheetsFailure = createAction('[ExportSpreadsheets] Load Export Spreadsheets Failure', props<{ error: any }>());

export const addExportSpreadsheetSuccess = createAction(
  '[ExportSpreadsheets] Add Export Spreadsheet Success',
  props<{ addedExportSpreadsheet: ExportSpreadsheet }>()
);
export const addExportSpreadsheetFailure = createAction('[ExportSpreadsheets] Add Export Spreadsheet Failure', props<{ error: any }>());
export const updateExportSpreadsheetSuccess = createAction(
  '[ExportSpreadsheets] Update Export Spreadsheet Success',
  props<{ updatedExportSpreadsheet: ExportSpreadsheet }>()
);
export const updateExportSpreadsheetFailure = createAction('[ExportSpreadsheets] Update Export Spreadsheet Failure', props<{ error: any }>());
export const deleteExportSpreadsheetSuccess = createAction(
  '[ExportSpreadsheets] Delete Export Spreadsheet Success',
  props<{ deletedExportSpreadsheet: ExportSpreadsheet }>()
);
export const deleteExportSpreadsheetFailure = createAction('[ExportSpreadsheets] Delete Export Spreadsheet Failure', props<{ error: any }>());
export const configureContactExportSpreadsheetsSuccess = createAction(
  '[ExportSpreadsheets] Configure Contact Export Spreadsheet Success',
  props<{ updatedExportSpreadsheets: ExportSpreadsheet[] }>()
);
export const configureContactExportSpreadsheetsFailure = createAction(
  '[ExportSpreadsheets] Configure Contact Export Spreadsheet Failure',
  props<{ error: any }>()
);
