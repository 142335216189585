import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Override, Rotation, TeamPlanning } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listSuccess = createAction(
  '[TeamPlannings] List Team Plannings Success',
  props<{ response: CommonApiResponse<TeamPlanning, CommonIndexedPagination> }>()
);
export const listFailure = createAction('[TeamPlannings] List Team Plannings Failure', props<{ error: any }>());

export const getOneSuccess = createAction('[TeamPlannings] Get Team Planning Success', props<{ selectedTeamPlanning: TeamPlanning }>());
export const getOneFailure = createAction('[TeamPlannings] Get Team Planning Failure', props<{ error: any }>());

export const addOneSuccess = createAction('[TeamPlannings] Add Team Planning Success', props<{ addedTeamPlanning: TeamPlanning }>());
export const addOneFailure = createAction('[TeamPlannings] Add Team Planning failure', props<{ error: any }>());

export const updateOneSuccess = createAction('[TeamPlannings] Update Team Planning Success', props<{ updatedTeamPlanning: TeamPlanning }>());
export const updateOneFailure = createAction('[TeamPlannings] Update Team Planning Failure', props<{ error: any }>());

export const deleteOneSuccess = createAction('[TeamPlannings] Delete Team Planning Success', props<{ deletedTeamPlanning: TeamPlanning }>());
export const deleteOneFailure = createAction('[TeamPlannings] Delete Team Planning Failure', props<{ error: any }>());

export const loadMVSettingsSuccess = createAction('[TeamPlannings] Load MV Team Plannings Settings Success', props<{ mvSettings: any }>());
export const loadMVSettingsFailure = createAction('[TeamPlannings] Load MV Team Plannings Settings Failure', props<{ error: any }>());

export const saveMVSettingsSuccess = createAction('[TeamPlannings] Save MV Team Plannings Settings Success', props<{ mvSettings: any }>());
export const saveMVSettingsFailure = createAction('[TeamPlannings] Save MV Team Plannings Settings Failure', props<{ error: any }>());
//
export const listRotationsByPlanningIdSuccess = createAction('[TeamPlannings] List Rotations by Team Planning Id Success', props<{ rotations: Rotation[] }>());
export const listRotationsByPlanningIdFailure = createAction('[TeamPlannings] List Rotations by Team Planning Id Failure', props<{ error: any }>());
export const addRotationSuccess = createAction('[TeamPlannings] Add Rotation Success', props<{ addedRotation: Rotation }>());
export const addRotationFailure = createAction('[TeamPlannings] Add Rotation Failure', props<{ error: any }>());
export const updateRotationSuccess = createAction('[TeamPlannings] Update Rotation Success', props<{ updatedRotation: Rotation }>());
export const updateRotationFailure = createAction('[TeamPlannings] Update Rotation Failure', props<{ error: any }>());
export const deleteRotationSuccess = createAction('[TeamPlannings] Delete Rotation Success', props<{ deletedRotation: Rotation }>());
export const deleteRotationFailure = createAction('[TeamPlannings] Delete Rotation Failure', props<{ error: any }>());
//
export const listOverridesByPlanningIdSuccess = createAction('[TeamPlannings] List Overrides by Team Planning Id Success', props<{ overrides: Override[] }>());
export const listOverridesByPlanningIdFailure = createAction('[TeamPlannings] List Overrides by Team Planning Id Failure', props<{ error: any }>());
export const addOverrideSuccess = createAction('[TeamPlannings] Add Override Success', props<{ addedOverride: Override }>());
export const addOverrideFailure = createAction('[TeamPlannings] Add Override Failure', props<{ error: any }>());
export const addManyOverridesSuccess = createAction('[TeamPlannings] Add Many Overrides Success', props<{ addedOverrides: Override[] }>());
export const addManyOverridesFailure = createAction('[TeamPlannings] Add Many Overrides Failure', props<{ error: any }>());
export const updateOverrideSuccess = createAction('[TeamPlannings] Update Override Success', props<{ updatedOverride: Override }>());
export const updateOverrideFailure = createAction('[TeamPlannings] Update Override Failure', props<{ error: any }>());
export const updateManyOverridesSuccess = createAction('[TeamPlannings] Update Many Overrides Success', props<{ updatedOverrides: Override[] }>());
export const updateManyOverridesFailure = createAction('[TeamPlannings] Update Many Overrides Failure', props<{ error: any }>());
export const deleteOverrideSuccess = createAction('[TeamPlannings] Delete Override Success', props<{ deletedOverride: Override }>());
export const deleteOverrideFailure = createAction('[TeamPlannings] Delete Override Failure', props<{ error: any }>());
