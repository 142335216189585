import { Injectable } from '@angular/core';
import { AuthFacade, AuthorizationService } from '@iot-platform/auth';
import { BaseFacade, Contact, Filter, Pagination } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { ContactsActions } from '../actions';
import { ContactsSelectors } from '../selectors/contacts.selectors';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade extends BaseFacade<Contact, Pagination, Filter> {
  allContacts$: Observable<Contact[]> = this.all$;
  contactsLoading$: Observable<boolean> = this.loading$;
  totalContacts$: Observable<number> = this.total$;

  constructor(
    protected override store: Store,
    protected override selector: ContactsSelectors,
    protected readonly authFacade: AuthFacade,
    protected readonly authorizationService: AuthorizationService
  ) {
    super(store, selector);
  }

  loadContacts(siteId: string): void {
    this.store.dispatch(ContactsActions.loadContacts({ siteId }));
  }

  addContact(contact: Contact): void {
    this.store.dispatch(ContactsActions.addContact({ contact }));
  }

  updateContact(contact: Contact): void {
    this.store.dispatch(ContactsActions.updateContact({ contact }));
  }

  deleteContact(contact: Contact): void {
    this.store.dispatch(ContactsActions.deleteContact({ contact }));
  }

  getAll(): void {
    noop();
  }

  setFilters(): void {
    noop();
  }
}
