@if (loading()) {
  <iot-platform-ui-card-loader [backgroundClass]="'h-100'" />
} @else {
  <div class="" data-cy="iot-platform-feature-data-guard-detailed-checks-tab" fxLayout="column" fxLayoutGap="10px">
    <div class="detailed-checks-tab--element-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
      @if (detailedChecks().source.icon) {
        <mat-icon [svgIcon]="detailedChecks().source.icon"></mat-icon>
      }
      <span>{{ detailedChecks().source.name }}</span>
    </div>
    <iot-platform-ui-overview-card [overviewCard]="detailedChecksCard()" />
  </div>
}
