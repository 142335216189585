import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const calendarsColumnSettings = [
  {
    order: 10,
    id: 'name',
    name: 'IOT_DICTIONARY.NAME',
    catalogName: 'CALENDAR NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    width: '',
    cellWidth: '400',
    type: 'start center'
  },
  {
    order: 20,
    id: 'entity.name',
    name: 'IOT_DICTIONARY.ENTITY',
    catalogName: 'ENTITY NAME',
    default: true,
    sortable: true,
    sortProperty: 'entity.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    cellWidth: '300',
    type: 'start center'
  },
  {
    order: 30,
    id: 'createdBy',
    name: 'IOT_DICTIONARY.CREATED_BY',
    catalogName: 'CREATED BY',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.USER_CELL,
    isLink: false,
    clickEvent: '',
    width: '',
    cellWidth: '300',
    type: 'start center'
  }
];
