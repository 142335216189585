import { ChangeDetectorRef, Component, Inject, OnInit, Signal } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { fromAuth } from '@iot-platform/auth';
import { Timezone } from '@iot-platform/models/common';

import { Asset, AssetStatus, Device } from '@iot-platform/models/i4b';

import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as moment from 'moment';

export enum SwitchbackOptions {
  NONE = 'NONE',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  DATE = 'DATE'
}

@Component({
  selector: 'iot4bos-ui-start-maintenance-dialog',
  templateUrl: './start-maintenance-dialog.component.html',
  styleUrls: ['./start-maintenance-dialog.component.scss']
})
export class StartMaintenanceDialogComponent implements OnInit {
  readonly switchbackOptions = SwitchbackOptions;

  scheduleMaintenanceForm: UntypedFormGroup = new UntypedFormGroup({});

  hourList: number[] = Array.from({ length: 12 });
  dayList: number[] = Array.from({ length: 31 });
  minDate: moment.Moment = moment().add(1, 'd');
  maxDate: moment.Moment = moment().add(6, 'M');
  tz: Signal<Timezone> = this.store.selectSignal(fromAuth.selectSelectedBusinessProfileTimezone);

  originalOrder = (): number => 0;

  constructor(
    private readonly store: Store,
    public dialogRef: MatDialogRef<StartMaintenanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { elementGoingIntoMaintenance: Asset | Device; type: 'ASSET' | 'DEVICE' },
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.cd.detectChanges();
  }

  initForm(): void {
    if (this.data.type === 'ASSET') {
      this.scheduleMaintenanceForm = new UntypedFormGroup(
        {
          delayType: new UntypedFormControl(
            (this.data.elementGoingIntoMaintenance.status as AssetStatus)?.scheduledMaintenance?.active &&
            (this.data.elementGoingIntoMaintenance.status as AssetStatus)?.scheduledMaintenance?.switchbackDate
              ? SwitchbackOptions.DATE
              : SwitchbackOptions.NONE
          ),
          switchbackDate: new UntypedFormControl(null),
          serviceId: new UntypedFormControl(this.data.elementGoingIntoMaintenance.serviceId)
        },
        { validators: [this.maintenanceValidator()] }
      );
      if (moment().isSame((this.data.elementGoingIntoMaintenance.status as AssetStatus)?.scheduledMaintenance?.switchbackDate, 'day')) {
        this.minDate = moment().utcOffset(this.tz().offset);
      }

      if (
        (this.data.elementGoingIntoMaintenance.status as AssetStatus)?.scheduledMaintenance?.active &&
        (this.data.elementGoingIntoMaintenance.status as AssetStatus)?.scheduledMaintenance?.switchbackDate
      ) {
        this.switchbackDate.setValue(
          moment((this.data.elementGoingIntoMaintenance.status as AssetStatus)?.scheduledMaintenance?.switchbackDate).utcOffset(this.tz().offset)
        );
      }
    } else {
      this.scheduleMaintenanceForm = new UntypedFormGroup({ serviceId: new UntypedFormControl(this.data.elementGoingIntoMaintenance.serviceId) });
    }
  }

  get delayType(): AbstractControl {
    return this.scheduleMaintenanceForm.get('delayType') as AbstractControl;
  }

  get switchbackDate(): AbstractControl {
    return this.scheduleMaintenanceForm.get('switchbackDate') as AbstractControl;
  }

  get serviceId(): AbstractControl {
    return this.scheduleMaintenanceForm.get('serviceId') as AbstractControl;
  }

  maintenanceValidator(): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const delayType: SwitchbackOptions = form.get('delayType').value;
      const date: string = form.get('switchbackDate').value;
      if (delayType === SwitchbackOptions.NONE) {
        return date === null ? null : { invalidForm: true };
      } else {
        return date !== null ? null : { invalidForm: true };
      }
    };
  }

  resetSwitchbackDate() {
    this.switchbackDate.reset(null);
  }

  computeDelay(event): void {
    switch (this.delayType.value) {
      case SwitchbackOptions.NONE:
        this.switchbackDate.reset(null);
        break;
      case SwitchbackOptions.HOURS:
        this.switchbackDate.setValue(moment().utcOffset(this.tz().offset).add(event.value, 'h'));
        break;
      case SwitchbackOptions.DAYS:
        this.switchbackDate.setValue(moment().utcOffset(this.tz().offset).add(event.value, 'd'));
        break;
      case SwitchbackOptions.DATE:
        const dayDiff: number = event.value.diff(moment().utcOffset(this.tz().offset), 'days') + 1;
        this.switchbackDate.setValue(moment().utcOffset(this.tz().offset).add(dayDiff, 'd'));
        break;
      default:
        break;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    const updatedElement: Asset | Device = cloneDeep(this.data.elementGoingIntoMaintenance);

    updatedElement.serviceId = this.serviceId.value?.trim() ?? null;

    if (this.data.type === 'ASSET') {
      (updatedElement.status as AssetStatus).scheduledMaintenance = {
        switchbackDate: this.switchbackDate.value ? `${this.switchbackDate.value.format('yyyy-MM-DDTHH:mm:ss')}${this.tz().offset}` : null,
        active: true
      };
    }

    this.dialogRef.close(updatedElement);
  }

  protected readonly SwitchbackOptions = SwitchbackOptions;
}
