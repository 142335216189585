@if (images()) {
  <div fxLayout="column" class="container">
    <div class="preview">
      <img [src]="imagePreview()" [alt]="" class="image-preview" />
    </div>
    @if (showSlider()) {
      <div fxLayout="row" fxLayoutAlign="center center" class="slider">
        <button mat-mini-fab (click)="showPrevious()" class="left-button">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="row" fxLayoutAlign="start" class="thumbnail-container">
          @for (image of imagesDisplayed(); track image) {
            <img [src]="image" (click)="onSelect(image)" alt="" [class.selected]="image === imagePreview()" class="thumbnail" />
          }
        </div>
        <button mat-mini-fab (click)="showNext()" class="right-button">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    }
  </div>
}
