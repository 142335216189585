import { PlatformResponse } from '@iot-platform/models/common';
import { OCMDayOff, OCMDayOffType } from '@iot-platform/models/ocm';
import { Action, combineReducers, createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromCalendarDaysOffDb from './calendar-days-off-db.reducer';
import * as fromCalendarDaysOffUi from './calendar-days-off-ui.reducer';
import * as fromCalendarsDb from './calendars-db.reducer';
import * as fromCalendarsUi from './calendars-ui.reducer';

export const calendarsFeatureKey = 'calendars';

export interface CalendarsState {
  [fromCalendarsDb.calendarsDbFeatureKey]: fromCalendarsDb.State;
  [fromCalendarsUi.calendarsUiFeatureKey]: fromCalendarsUi.State;
  [fromCalendarDaysOffDb.calendarDaysOffDbFeatureKey]: fromCalendarDaysOffDb.State;
  [fromCalendarDaysOffUi.calendarDaysOffUiFeatureKey]: fromCalendarDaysOffUi.State;
}

export interface State {
  [calendarsFeatureKey]: CalendarsState;
}

export function reducers(state: CalendarsState | undefined, action: Action) {
  return combineReducers({
    [fromCalendarsDb.calendarsDbFeatureKey]: fromCalendarsDb.reducer,
    [fromCalendarsUi.calendarsUiFeatureKey]: fromCalendarsUi.reducer,
    [fromCalendarDaysOffDb.calendarDaysOffDbFeatureKey]: fromCalendarDaysOffDb.reducer,
    [fromCalendarDaysOffUi.calendarDaysOffUiFeatureKey]: fromCalendarDaysOffUi.reducer
  })(state, action);
}

export const selectCalendarsState = createFeatureSelector<CalendarsState>(calendarsFeatureKey);

export const selectCalendarsDbState = createSelector(selectCalendarsState, (state: CalendarsState) =>
  state ? state[fromCalendarsDb.calendarsDbFeatureKey] : null
);

export const selectCalendarsUiState = createSelector(selectCalendarsState, (state: CalendarsState) =>
  state ? state[fromCalendarsUi.calendarsUiFeatureKey] : null
);

export const {
  selectIds: getCalendarsId,
  selectEntities: getCalendarsEntities,
  selectAll: getAllCalendars,
  selectTotal: getTotalCalendars
} = fromCalendarsDb.adapter.getSelectors(selectCalendarsDbState);

export const getLoading = createSelector(selectCalendarsUiState, fromCalendarsUi.getLoading);
export const getLoaded = createSelector(selectCalendarsUiState, fromCalendarsUi.getLoaded);
export const getError = createSelector(selectCalendarsUiState, fromCalendarsUi.getError);
export const getSelectedCalendarId = createSelector(selectCalendarsDbState, fromCalendarsDb.getSelectedCalendarId);

export const getSelectedCalendar = createSelector(
  getCalendarsEntities,
  getSelectedCalendarId,
  (calendars, selectedCalendarId) => calendars[selectedCalendarId]
);

export const getPagination = createSelector(selectCalendarsDbState, (state: fromCalendarsDb.State) => ({ ...state.pagination, limit: 100 }));

export const getMVSettings = createSelector(selectCalendarsDbState, fromCalendarsDb.getMVSettings);

export const getCalendarsFilters = createSelector(selectCalendarsDbState, fromCalendarsDb.getCalendarsFilters);

export const getFormattedData = createSelector(getAllCalendars, getPagination, (data, pagination) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getTotal = createSelector(getPagination, (pagination) => pagination.total);

// ************************** //
//         Days Off           //
// ************************** //

export const selectCalendarDaysOffDbState = createSelector(selectCalendarsState, (state: CalendarsState) =>
  state ? state[fromCalendarDaysOffDb.calendarDaysOffDbFeatureKey] : null
);

export const selectCalendarDaysOffUiState = createSelector(selectCalendarsState, (state: CalendarsState) =>
  state ? state[fromCalendarDaysOffUi.calendarDaysOffUiFeatureKey] : null
);

export const {
  selectIds: getCalendarDaysOffId,
  selectEntities: getCalendarDaysOffEntities,
  selectAll: getAllCalendarDaysOff,
  selectTotal: getTotalCalendarDaysOffs
} = fromCalendarDaysOffDb.adapter.getSelectors(selectCalendarDaysOffDbState);

export const selectDaysOffLoading = createSelector(selectCalendarDaysOffUiState, fromCalendarDaysOffUi.getLoading);
export const selectDaysOffLoaded = createSelector(selectCalendarDaysOffUiState, fromCalendarDaysOffUi.getLoaded);
export const selectDaysOffError = createSelector(selectCalendarDaysOffUiState, fromCalendarDaysOffUi.getError);
export const selectSelectedCalendarDayOffId = createSelector(selectCalendarDaysOffDbState, fromCalendarDaysOffDb.getSelectedDayOffId);

export function selectDaysOffByType(type: OCMDayOffType): MemoizedSelector<fromCalendarDaysOffDb.State, OCMDayOff[]> {
  return createSelector(getAllCalendarDaysOff, (daysOff) => daysOff.filter((day) => day.type === type));
}

export function selectDaysOffTotalByType(type: OCMDayOffType): MemoizedSelector<fromCalendarDaysOffDb.State, number> {
  return createSelector(selectDaysOffByType(type), (daysOff) => daysOff.length);
}
