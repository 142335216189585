import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { BaseUser, Filter, PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { Subscriber } from '@iot-platform/models/i4b';
import { Topic } from '@iot-platform/models/ocm';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {
  events24h$: Subject<{ total: number; source: string }> = new Subject<{ total: number; source: string }>();

  constructor(@Inject('environment') private readonly environment, private readonly http: HttpClient) {}

  listTopics(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<Topic[]>(`${this.environment.api.url}${this.environment.api.endpoints.topics}`, { params }).pipe(
      map((response: any) => ({
        data: response.content,
        currentPage: response.page.curPage,
        hasMore: response.page.hasMore,
        limit: response.page.limit,
        maxPage: response.page.maxPage,
        total: response.page.total
      }))
    );
  }

  getTopicById(topicId: string): Observable<Topic> {
    return this.http.get<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topicId}`);
  }

  isTopicNameUnique(initialName: string, name: string, entityId: string): Observable<boolean> {
    if (initialName.toLowerCase() === name.toLowerCase()) {
      return of(true);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('page', '0');
    params = params.append('name', name);
    params = params.append('entityId', entityId);
    params = params.append('exactParam', 'name');

    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}${this.environment.api.endpoints.topics}`, { params })
      .pipe(map((data: any) => data.content.findIndex((item) => item.name.toLowerCase() === name.toLowerCase()) === -1));
  }

  getSubscribersByTopic(topicId: string): Observable<Subscriber[]> {
    return this.http
      .get<Subscriber[]>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topicId}/users`)
      .pipe(map((result: any) => result.content));
  }

  subscribeUser(topicId: string, userToSubscribe: BaseUser): Observable<Subscriber> {
    return this.http
      .put(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topicId}/subscribe`, {
        userId: userToSubscribe.id
      })
      .pipe(
        switchMap(() => this.getSubscribersByTopic(topicId).pipe(map((subscribers: Subscriber[]) => subscribers.find((s) => s.user.id === userToSubscribe.id))))
      );
  }

  unsubscribeUser(topicId: string, userToUnsubscribe: Subscriber): Observable<Subscriber> {
    return this.http
      .put(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topicId}/unsubscribe`, {
        userId: userToUnsubscribe.user.id
      })
      .pipe(map(() => userToUnsubscribe));
  }

  getUsersByEntityWithParents(): Observable<BaseUser[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', '1000');
    params = params.set('page', '0');
    params = params.append('userStatus', 'Active');
    params = params.append('userStatus', 'Pending');
    params = params.set('withParents', 'true');

    return this.http
      .get<{ page: any; content: BaseUser[] }>(`${this.environment.api.url}${this.environment.api.endpoints.users}`, { params })
      .pipe(map((data) => data.content));
  }

  addTopic(topic: Topic): Observable<Topic> {
    return this.http.post<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}`, topic);
  }

  updateTopic(topic: Topic): Observable<Topic> {
    return this.http.put<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}`, topic);
  }

  deleteTopic(topic: Topic): Observable<Topic> {
    return this.http.delete<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}`).pipe(map(() => topic));
  }

  subscribeToTopic(topic: Topic): Observable<Topic> {
    return this.http
      .put<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}/subscribe`, {})
      .pipe(switchMap(() => this.http.get<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}`)));
  }

  unsubscribeToTopic(topic: Topic): Observable<Topic> {
    return this.http
      .put<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}/unsubscribe`, {})
      .pipe(switchMap(() => this.http.get<Topic>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}`)));
  }

  calculateEstimatedEvents(request: PlatformRequest, view: string, source: string): Observable<number> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', '0');
    params = params.set('withChildren', 'true');

    const url = view === 'asset-events' ? this.environment.api.endpoints.assetEvents : this.environment.api.endpoints.deviceEvents;
    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<any>(`${this.environment.api.url}${url}`, { params }).pipe(
      map((response) => {
        this.events24h$.next({ total: response.page.total, source });
        return response.page.total;
      })
    );
  }

  getSubscribersByIds(subscribers: Subscriber[]): Observable<BaseUser[]> {
    return forkJoin(
      subscribers.map((subscriber: Subscriber) =>
        this.http.get<BaseUser>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${subscriber.user.id}`)
      )
    );
  }
}
