import { createSelector } from '@ngrx/store';
import { authFeatures } from '../reducers';
import { adapter } from '../reducers/auth-business-profiles-api.reducer';

export const { selectAuthBusinessProfilesApi: selectAuthBusinessProfilesApiState } = authFeatures;

export const {
  selectIds: getBusinessProfilesIds,
  selectEntities: getBusinessProfilesEntities,
  selectAll: getAllBusinessProfiles,
  selectTotal: getTotalBusinessProfiles
} = adapter.getSelectors(selectAuthBusinessProfilesApiState);

export const selectSelectedBusinessProfileId = createSelector(selectAuthBusinessProfilesApiState, (state) => state.selectedBusinessProfileId);

export const selectSelectedBusinessProfile = createSelector(
  getBusinessProfilesEntities,
  selectSelectedBusinessProfileId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
