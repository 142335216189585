import { AssetEvent, Log } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const AssetEventsCommentsActions = createActionGroup({
  source: 'Asset Events Comments',
  events: {
    'Load comments': props<{ assetEvent: AssetEvent }>(),
    'Load comments Success': props<{ comments: Log[] }>(),
    'Load comments Failure': props<{ error: unknown }>(),
    'Add comment': props<{ assetEvent: AssetEvent; comment: string }>(),
    'Add comment Success': props<{ comment: Log }>(),
    'Add comment Failure': props<{ error: unknown }>(),
    'Edit comment': props<{ assetEventId: string; comment: Log }>(),
    'Edit comment Success': props<{ comment: Log }>(),
    'Edit comment Failure': props<{ error: unknown }>(),
    'Delete comment': props<{ assetEvent: AssetEvent; commentId: string }>(),
    'Delete comment Success': props<{ commentId: string }>(),
    'Delete comment Failure': props<{ error: unknown }>()
  }
});
