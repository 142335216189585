import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
  selector: 'i4b-table-engine-severity-cell',
  templateUrl: './severity-cell.component.html',
  styleUrls: ['./severity-cell.component.scss']
})
export class SeverityCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() elementId: string;
  @Input() clickEvent: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();
}
