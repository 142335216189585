<div
  [ngClass]="{ failure: deviceStatus() === 'cCF', notDefined: deviceStatus() === 'undefined', warning: deviceStatus() === 'warning' }"
  class="device-info-toolbar-status-card"
  data-cy="iot-platform-ui-device-info-status"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  @if (deviceStatus() === 'undefined') {
    <span>{{ 'DEVICES.INFO_TOOLBAR.HAS_NOT_CALLED_YET' | translate }}</span>
  } @else {
    <mat-icon [svgIcon]="iconToDisplay().name" [class]="iconToDisplay().className"></mat-icon>
    <span>{{ 'DEVICES.INFO_TOOLBAR.LAST_INCOMING_CALL' | translate }}{{ device().lastCallStatus?.datetime | dateFormat }} </span>
  }
</div>
