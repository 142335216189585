import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const deviceVariablesConfigurationPopupColumnSettings = [
  {
    order: 2,
    id: 'name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_NAME',
    catalogName: 'VARIABLE NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '25%',
    type: 'start center'
  },
  {
    order: 3,
    id: 'comment',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
    catalogName: 'DESCRIPTION',
    default: true,
    sortable: true,
    sortProperty: 'comment',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '30%',
    type: 'start center'
  },
  {
    order: 4,
    id: 'lastValue',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VALUE',
    catalogName: 'VALUE',
    default: true,
    sortable: true,
    sortProperty: 'value',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.RICH_VARIABLE,
    cellTypeOptions: {
      dataType: 'lastValue',
      unit: 'unit'
    },
    isLink: false,
    width: '25%',
    type: 'start center'
  },
  {
    order: 5,
    id: 'lastValue.datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TIME',
    catalogName: 'TIME',
    default: true,
    sortable: true,
    sortProperty: 'lastValue.datetime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    width: '25%',
    type: 'start center'
  },
  {
    order: 9,
    id: 'id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_ID',
    catalogName: 'VARIABLE TECHNICAL ID',
    default: false,
    sortable: true,
    sortProperty: 'id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '15%',
    type: 'start center'
  },
  {
    order: 7,
    id: 'threshold1',
    name: '',
    catalogName: 'THRESHOLDS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: 'thresholdIconCell',
    isLink: false,
    width: '5%',
    type: 'center center'
  }
];
