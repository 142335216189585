<div class="start-maintenance-comment-container" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxFlex>
  <div class="start-maintenance-comment-header-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon fxFlex="26px">outlined_flag</mat-icon>
    <div fxFlex>
      <span class="start-maintenance-comment-header-title fw-600">{{ 'IOT_DICTIONARY.START_OF_MAINTENANCE' | translate }}</span>
    </div>
  </div>

  <div fxLayout="column">
    @if (log?.comment) {
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxFlex="26px"></div>
        <div fxFlex>
          <span>{{ 'IOT_DICTIONARY.SERVICE_ID' | translate }} : </span>
          <span>{{ log.comment }}</span>
        </div>
      </div>
    }

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon [svgIcon]="'line_start_circle'" color="accent" fxFlex="26px"></mat-icon>
      <span class="start-maintenance-user-name" [matTooltip]="log?.user?.name" matTooltipPosition="above" matTooltipClass="regular-tooltip" fxFlex="80px"
        >{{ 'IOT_DICTIONARY.BY' | translate }} : {{ log?.user?.name }}</span
      >
      <span>{{ log?.datetime | dateFormat }}</span>
    </div>
  </div>
</div>
