@if (totalSelected() > 0) {
  <div class="app-round-button-mv action">
    @if (isCallToActionVisible()) {
      <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" color="accent" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
    }
    <mat-menu #menu="matMenu">
      @for (action of bulkActions(); track $index) {
        @if (action?.divider) {
          <mat-divider></mat-divider>
        }
        @if (bulkActionVisibility()?.[action.key]) {
          <button (click)="onBulkActionClick(action.key)" mat-menu-item>
            {{ action.label | translate | uppercase }}
          </button>
        }
      }
    </mat-menu>
  </div>
}
