<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'DEVICES.MOVE_TO.TITLE' | translate }}</h1>
      <button (click)="closeOnCancel()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="row">
    <section class="device-move-form-list p-20" fxFlex="40%" fxLayout="column">
      <div class="device-move-form-list-title">
        @if (data.devices?.length === 1) {
          {{ 'DEVICES.MOVE_TO.LIST_TITLE_SINGULAR' | translate }}
        } @else if (data.devices?.length > 1) {
          {{ 'DEVICES.MOVE_TO.LIST_TITLE_PLURAL' | translate: { total: data.devices.length } }}
        }
      </div>
      <ul>
        @for (device of data.devices; track device.id) {
          <li>{{ device.name }}</li>
        }
      </ul>
    </section>

    <section fxFlex="60%" fxLayout="column" fxLayoutAlign="space-between">
      <form [formGroup]="deviceMoveToForm" class="p-20" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="device-move-to-autocomplete" color="accent" fxFlex="90%">
          <mat-label>{{ 'DEVICES.MOVE_TO.PLACEHOLDER' | translate }}</mat-label>
          <input (keyup)="getFilteredSites(false)" [matAutocomplete]="auto" formControlName="destinationSite" matInput required type="text" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
            @for (site of filteredSites; track site.id) {
              <mat-option [value]="site" class="device-move-to-autocomplete-option">
                <div fxLayout="column" class="auto-option">
                  <span class="auto-name">{{ site.name }}</span>
                  <span class="auto-address">{{ site.address.address1 }} - {{ site.address | infoDisplay: 'brackets' : 'city' : 'zipCode' }}</span>
                </div>
              </mat-option>
            }
            @if (hasMore) {
              <button (click)="getFilteredSites(true)" class="option-hasmore" fxLayout="row" fxLayoutAlign="space-around center" mat-button>
                <span>{{ 'DEVICES.MOVE_TO.LOAD_MORE_OPTIONS' | translate }}</span>
              </button>
            }
          </mat-autocomplete>
        </mat-form-field>
        @if (spinner) {
          <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
        }
      </form>

      <div [class.visible]="deviceMoveToForm.valid && destinationSite.value.name" class="device-move-to-confirmation-zone">
        <div class="device-move-to-summary">
          @if (data.devices?.length === 1) {
            {{ 'DEVICES.MOVE_TO.CONFIRMATION_MESSAGE_SINGULAR' | translate }}
          } @else if (data.devices?.length > 1) {
            {{ 'DEVICES.MOVE_TO.CONFIRMATION_MESSAGE_PLURAL' | translate: { total: data.devices.length } }}
          }
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <div
            [ngClass]="{ 'customer-site': originSites.length === 1 && data.devices[0].site.type === 'customer_site' }"
            class="device-move-to-origin"
            fxFlex="40"
            fxLayout="column"
            fxLayoutAlign="start center"
          >
            <mat-icon color="#394C5A" svgIcon="alizent"></mat-icon>
            @if (originSites.length === 1) {
              <div fxLayout="column">
                <span class="device-move-to-site-type">{{ 'SITES.CARD.TYPES.' + data.devices[0].site.type | translate }}</span>
                <span class="device-move-to-site-name">{{ data.devices[0].site.name }}</span>
              </div>
            } @else {
              <span class="device-move-to-site-type">{{ 'DEVICES.MOVE_TO.FROM_MULTIPLE_SITES' | translate: { total: originSites.length } }}</span>
            }
          </div>
          <div class="device-move-to-picto" fxFlex="20" fxLayout="column" fxLayoutAlign="start center">
            <mat-icon>wifi_tethering</mat-icon>
            <mat-icon>arrow_right</mat-icon>
          </div>
          <div class="device-move-to-destination" fxFlex="40" fxLayout="column" fxLayoutAlign="start center">
            <mat-icon color="#394C5A" svgIcon="site"></mat-icon>
            <span class="device-move-to-site-type">{{ 'SITES.CARD.TYPES.' + destinationSite.value.type | translate }}</span>
            <span class="device-move-to-site-name">{{ destinationSite.value.name }}</span>
          </div>
        </div>
      </div>
    </section>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button (click)="closeOnCancel()" class="button-regular" mat-button>{{ 'DEVICES.MOVE_TO.CANCEL' | translate }}</button>
    <button (click)="apply()" [disabled]="!deviceMoveToForm.valid || !destinationSite.value.name" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
