import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserProfileService } from '../../services/user-profile.service';
import { ProfileActions } from '../actions';

@Injectable()
export class ProfileEffects {
  currentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadUserProfile),
      switchMap(() =>
        this.userProfileService.loadProfile().pipe(
          map((user) => ProfileActions.loadUserProfileSuccess({ user })),
          catchError((error) => of(ProfileActions.loadUserProfileFailure({ error })))
        )
      )
    )
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveUserProfile),
      switchMap((action) =>
        this.userProfileService.saveUserProfile(action.userToUpdate).pipe(
          map((user) => ProfileActions.saveUserProfileSuccess({ updatedUser: user })),
          catchError((error) => of(ProfileActions.saveUserProfileFailure({ error })))
        )
      )
    )
  );

  loadApiKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadApiKeys, ProfileActions.addApiKeySuccess, ProfileActions.deleteApiKeySuccess),
      mergeMap(() =>
        this.userProfileService.getApiKeys().pipe(
          map((apiKeys) => ProfileActions.loadApiKeysSuccess({ apiKeys })),
          catchError((error) => of(ProfileActions.loadApiKeysFailure({ error })))
        )
      )
    )
  );

  addApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.addApiKey),
      mergeMap(() =>
        this.userProfileService.addApiKey().pipe(
          map((apiKeys) => ProfileActions.addApiKeySuccess({ apiKeys })),
          catchError((error) => of(ProfileActions.addApiKeyFailure({ error })))
        )
      )
    )
  );

  getApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getApiKey),
      mergeMap((action) =>
        this.userProfileService.getApiKeyById(action.apiKeyIdToGet).pipe(
          map((detailedApiKey) => ProfileActions.getApiKeySuccess({ detailedApiKey })),
          catchError((error) => of(ProfileActions.getApiKeyFailure({ error })))
        )
      )
    )
  );

  deleteApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.deleteApiKey),
      mergeMap((action) =>
        this.userProfileService.deleteApiKeyById(action.apiKeyIdToDelete).pipe(
          map(() => ProfileActions.deleteApiKeySuccess()),
          catchError((error) => of(ProfileActions.deleteApiKeyFailure({ error })))
        )
      )
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.saveUserProfile, ProfileActions.loadUserProfile, ProfileActions.addApiKey, ProfileActions.deleteApiKey, ProfileActions.getApiKey),
        tap(() => this.notificationService.displayLoader(true))
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProfileActions.saveUserProfileSuccess,
          ProfileActions.saveUserProfileFailure,
          ProfileActions.loadUserProfileSuccess,
          ProfileActions.loadUserProfileFailure,
          ProfileActions.addApiKeySuccess,
          ProfileActions.addApiKeyFailure,
          ProfileActions.deleteApiKeySuccess,
          ProfileActions.deleteApiKeyFailure,
          ProfileActions.getApiKeySuccess,
          ProfileActions.getApiKeyFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.saveUserProfileSuccess, ProfileActions.addApiKeySuccess, ProfileActions.deleteApiKeySuccess),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.saveUserProfileFailure),
        tap((action) => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userProfileService: UserProfileService,
    private notificationService: NotificationService
  ) {}
}
