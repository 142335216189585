<i18n-translation-toolbar
  (addLanguage)="onAddLanguage()"
  (clear)="onClear()"
  (deleteLanguage)="onDeleteLanguage($event)"
  (editMode)="onSwitchEditMode($event)"
  (export)="onExport($event)"
  [keyCount]="(dictionaries$ | async)?.length"
  [languages]="languages"
  [showClearSelectedButton]="showClearSelectedButton$ | async"
>
</i18n-translation-toolbar>

<div class="regular-background">
  <i18n-translation-table-view
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [data]="dictionaries$ | async"
    [editMode]="editMode"
    [languages]="languages"
  ></i18n-translation-table-view>
</div>
