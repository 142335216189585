import { UserAccount } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadUserProfile = createAction('[Users/Profile] Load User Profile');
export const loadUserProfileSuccess = createAction('[Users/Profile] Load User Profile Success', props<{ user: UserAccount }>());
export const loadUserProfileFailure = createAction('[Users/Profile] Load User Profile Failure', props<{ error: any }>());

export const saveUserProfile = createAction('[Users/Profile] Save User Profile', props<{ userToUpdate: UserAccount }>());
export const saveUserProfileSuccess = createAction('[Users/Profile] Save User Profile Success', props<{ updatedUser: UserAccount }>());
export const saveUserProfileFailure = createAction('[Users/Profile] Save User Profile Failure', props<{ error: any }>());

export const loadApiKeys = createAction('[Users/Profile] Load Api Keys');
export const loadApiKeysSuccess = createAction('[Users/Profile] Load Api Keys Success', props<{ apiKeys: any[] }>());
export const loadApiKeysFailure = createAction('[Users/Profile] Load Api Keys Failure', props<{ error: any }>());

export const addApiKey = createAction('[Users/Profile] Add Api Key');
export const addApiKeySuccess = createAction('[Users/Profile] Add Api Key Success', props<{ apiKeys: [] }>());
export const addApiKeyFailure = createAction('[Users/Profile] Add Api Key Failure', props<{ error: any }>());

export const getApiKey = createAction('[Users/Profile] Get Api Key', props<{ apiKeyIdToGet: string }>());
export const getApiKeySuccess = createAction('[Users/Profile] Get Api Key Success', props<{ detailedApiKey: any }>());
export const getApiKeyFailure = createAction('[Users/Profile] Get Api Key Failure', props<{ error: any }>());

export const deleteApiKey = createAction('[Users/Profile] Delete Api Key', props<{ apiKeyIdToDelete: string }>());
export const deleteApiKeySuccess = createAction('[Users/Profile] Delete Api Key Success');
export const deleteApiKeyFailure = createAction('[Users/Profile] Delete Api Key Failure', props<{ error: any }>());
