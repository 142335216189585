<form [formGroup]="filterForm">
  <div class="device-calls-log-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="device-calls-log-toolbar-status">
      <mat-form-field color="accent">
        <mat-label>{{ 'DEVICES.CALL_LOG.STATUS_SELECT' | translate }}</mat-label>
        <mat-select (selectionChange)="onFilterChange()" formControlName="status">
          @for (s of statusList; track s) {
            <mat-option [value]="s">{{ s }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <span style="width: 120px"></span>
    <div class="device-calls-log-toolbar-filters" fxLayout="row" fxLayoutAlign="end center">
      <div class="date-picker" fxLayoutAlign=" center">
        <mat-icon class="calendar-icon" matIconPrefix>event</mat-icon>
        <mat-form-field color="accent">
          <mat-label>{{ 'DEVICES.CALL_LOG.FROM' | translate }}</mat-label>
          <input
            (dateChange)="onFilterChange()"
            (focus)="startDateInput.open()"
            [matDatepicker]="startDateInput"
            [max]="maxDate"
            [min]="minDate"
            formControlName="startDate"
            matInput
          />
          <mat-datepicker-toggle [for]="startDateInput" matTextSuffix>
            <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDateInput></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="date-picker" fxLayoutAlign=" center">
        <mat-icon class="calendar-icon" matIconPrefix>event</mat-icon>
        <mat-form-field color="accent">
          <mat-label>{{ 'DEVICES.CALL_LOG.TO' | translate }}</mat-label>
          <input
            (dateChange)="onFilterChange()"
            (focus)="endDateInput.open()"
            [matDatepicker]="endDateInput"
            [max]="maxDate"
            [min]="minDate"
            formControlName="endDate"
            matInput
          />
          <mat-datepicker-toggle [for]="endDateInput" matTextSuffix>
            <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #endDateInput></mat-datepicker>
        </mat-form-field>
      </div>
      <button
        (click)="resetFilters()"
        [disabled]="!(status.value || startDate.value || endDate.value)"
        class="device-call-log-toolbar-reset-button"
        color="accent"
        mat-button
      >
        {{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}
      </button>
    </div>
  </div>
</form>
