<div fxLayout="column" fxLayoutGap="10px" style="padding: 10px 20px">
  <p class="fw-600">{{ 'ASSETS.VARIABLE_FORM.CONFIGURE_SOURCE_VARIABLES_ASSOCIATION' | translate }}</p>
  <p>{{ 'ASSETS.VARIABLE_FORM.ASSOCIATION_RULE' | translate }}</p>

  <mat-radio-group (change)="onSelectedRuleChange($event.value)" [value]="selectedAssociationRule()" color="accent" fxLayout="column">
    @for(associationRule of associationRules; track associationRule) {
    <mat-radio-button class="capitalize-first-letter" [value]="associationRule" fxFlex="30">
      {{ associationRule.split('_').join(' ').toLowerCase() | titlecase }}
    </mat-radio-button>
    }
  </mat-radio-group>

  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start">
    <mat-icon style="font-size: 18px">info</mat-icon>
    <span style="word-wrap: break-word; max-width: 90%">
      {{ infoMessage() | translate }}
    </span>
  </div>

  @if(selectedAssociationRule() !== ASSOCIATION_RULE.NONE) {
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start start">
    <mat-form-field class="input-field" color="accent">
      <mat-label style="font-size: 14px">{{ 'ASSETS.VARIABLE_FORM.SUGGESTED_VARIABLE_NAME' | translate }}</mat-label>
      <input formControlName="suggestedNameControl" matInput />
      <button
        (click)="onAddSuggestedVariableName()"
        [disabled]="suggestedNameControl.invalid || suggestedNames()?.length === 40"
        color="accent"
        mat-icon-button
        matTextSuffix
        type="submit"
      >
        <mat-icon style="font-size: 20px; padding-bottom: 3px">add_circle</mat-icon>
      </button>
    </mat-form-field>
    @if(suggestedNameControl.invalid && suggestedNameControl.errors?.duplicate) {
    <mat-error>{{ 'ASSETS.VARIABLE_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>
    } @if(suggestedNames()?.length === 40) {
    <mat-error>{{ 'ASSETS.VARIABLE_FORM.ERROR_MESSAGE.MAXIMUM_SUGGESTED_NAMES' | translate }}</mat-error>
    }
  </form>

  @if(suggestedNames()?.length) {
  <mat-chip-set aria-orientation="horizontal">
    @for(name of suggestedNames(); track suggestedNameControl) {
    <mat-chip-option (removed)="onSuggestedNameRemove(name)" [removable]="true" [selectable]="false">
      {{ name }}
      <mat-icon style="color: #ffffff" matChipRemove>highlight_off</mat-icon>
    </mat-chip-option>
    }
    <mat-chip-option (click)="suggestedNames.set([])">
      {{ 'FILTER_ENGINE.CLEAR' | translate }}
    </mat-chip-option>
  </mat-chip-set>
  } }
</div>
