import { PlatformResponse, ProductCatalog } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listProductCatalogsSuccess = createAction('[Product Catalogs] List Product Catalogs Success', props<{ response: PlatformResponse }>());
export const listProductCatalogsFailure = createAction('[Product Catalogs] List Product Catalogs Failure', props<{ error: any }>());
//
export const addProductCatalogSuccess = createAction('[Product Catalogs] Add Product Catalog Success', props<{ addedProductCatalog: ProductCatalog }>());
export const addProductCatalogFailure = createAction('[Product Catalogs] Add Product Catalog Failure', props<{ error: any }>());
//
export const updateProductCatalogSuccess = createAction(
  '[Product Catalogs] Update Product Catalog Success',
  props<{ updatedProductCatalog: ProductCatalog }>()
);
export const updateProductCatalogFailure = createAction('[Product Catalogs] Update Product Catalog Failure', props<{ error: any }>());
//
export const deleteProductCatalogSuccess = createAction(
  '[Product Catalogs] Delete Product Catalog Success',
  props<{ deletedProductCatalog: ProductCatalog }>()
);
export const deleteProductCatalogFailure = createAction('[Product Catalogs] Delete Product Catalog Failure', props<{ error: any }>());
