import { createReducer, on } from '@ngrx/store';
import { BusinessProfilesPageActions, BusinessProfilesRolesApiActions } from '../actions';

export const adminBusinessProfilesRolesPageFeatureKey = 'adminBusinessProfilesRolesPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const getError = (state: State) => state.error;

export const reducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listRolesByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);
export const getPending = (state: State) => state.pending;
