import { Injectable, Signal } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { CommonApiRequest, FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { AssetEvent, Log } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AssetEventsCommentsActions, AssetEventsUiActions } from './actions';
import * as fromAssetEvents from './reducers';

@Injectable()
export class AssetEventsApi {
  grid$ = this.store.select(fromGrids.getDefaultAssetEventsGrid);
  grids$ = this.store.select(fromGrids.getAssetEventsGrids);
  assetEventsLoaded$ = this.store.select(fromAssetEvents.getAssetEventsLoaded);
  site$ = this.store.select(fromAssetEvents.getSite);
  siteLoaded$ = this.store.select(fromAssetEvents.getSiteLoaded);
  asset$ = this.store.select(fromAssetEvents.getAsset);
  assetLoaded$ = this.store.select(fromAssetEvents.getAssetLoaded);
  assetVariable$ = this.store.select(fromAssetEvents.getAssetVariable);
  assetVariableLoaded$ = this.store.select(fromAssetEvents.getAssetVariableLoaded);
  device$ = this.store.select(fromAssetEvents.getDevice);
  deviceLoaded$ = this.store.select(fromAssetEvents.getDeviceLoaded);
  deviceVariable$ = this.store.select(fromAssetEvents.getDeviceVariable);
  deviceVariableLoaded$ = this.store.select(fromAssetEvents.getDeviceVariableLoaded);

  assetEventComments$: Observable<Log[]> = this.store.select(fromAssetEvents.selectAllAssetEventComments);
  assetEventComments: Signal<Log[]> = this.store.selectSignal(fromAssetEvents.selectAllAssetEventComments);
  assetEventCommentsLoading: Signal<boolean> = this.store.selectSignal(fromAssetEvents.selectAssetEventCommentsLoading);
  assetEventCommentsLoaded$: Observable<boolean> = this.store.select(fromAssetEvents.selectAssetEventCommentsLoaded);
  assetEventCommentsLoaded: Signal<boolean> = this.store.selectSignal(fromAssetEvents.selectAssetEventCommentsLoaded);

  tags$ = this.store.select(fromAssetEvents.getTags);
  tagsLoaded$ = this.store.select(fromAssetEvents.getTagsLoaded);
  status$ = this.store.select(fromAssetEvents.getStatus);
  tableState$ = this.store.select(fromAssetEvents.getTableState);
  pagination$ = this.store.select(fromAssetEvents.getPagination);
  autoRefresh$ = this.store.select(fromAssetEvents.getAutoRefresh);
  refreshDelay$ = this.store.select(fromAssetEvents.getRefreshDelay);
  mvSettings$ = this.store.select(fromAssetEvents.getMVSettings);
  favoriteViews$ = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssetEvents);
  currentFavoriteView$ = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewAssetEvents);
  currentFilters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewAssetEvents);
  loadingFavoriteViews$ = this.store.select(fromFavoriteViews.getLoading);
  assetEventFavoriteViewsConfiguration$ = this.store.select(fromFavoriteViews.selectAssetEventFavoriteViewsConfiguration);

  constructor(private readonly store: Store) {}

  selectedAssetEvent$ = (gridId: string, itemId: string) => this.store.select(fromGrids.selectItemInGrid(gridId, itemId));

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'asset-events' }));
  }

  loadAssetEvents(request: CommonApiRequest) {
    this.store.dispatch(
      GridsDbActions.loadGridData({
        request: { concept: 'asset-events', ...request }
      })
    );
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(AssetEventsUiActions.loadSiteById({ siteId }));
  }

  loadDeviceById(deviceId: string) {
    this.store.dispatch(AssetEventsUiActions.loadDeviceById({ deviceId }));
  }

  loadDeviceVariableById(deviceVariableId: string) {
    this.store.dispatch(AssetEventsUiActions.loadDeviceVariableById({ deviceVariableId }));
  }

  loadAssetById(assetId: string) {
    this.store.dispatch(AssetEventsUiActions.loadAssetById({ assetId }));
  }

  loadAssetVariableById(assetVariableId: string) {
    this.store.dispatch(AssetEventsUiActions.loadAssetVariableById({ assetVariableId }));
  }

  loadTagsByAssetEventId(assetEventId: string) {
    this.store.dispatch(AssetEventsUiActions.loadTagsByAssetEventId({ assetEventId }));
  }

  loadEventDetailPopupDataByAssetEvent(assetEvent: AssetEvent) {
    this.loadSiteById(assetEvent.context.site.id);
    this.loadAssetById(assetEvent.context.asset.id);
    this.loadAssetVariableById(assetEvent.context.assetVariable.id);
    this.loadDeviceById(assetEvent.context.device.id);
    this.loadDeviceVariableById(assetEvent.context.deviceVariable.id);
  }

  bulkUpdateStatusByGridAndAssetEvent(grid, assetEvents: AssetEvent[], status: string) {
    assetEvents.forEach((aEvent) => {
      const updatedEvent = {
        ...aEvent,
        status
      };
      this.store.dispatch(
        GridsDbActions.updateItemInGridData({
          gridId: grid.id,
          item: updatedEvent,
          concept: 'asset-events'
        })
      );
    });
  }

  updateStatusByAssetEventId(assetEventIds: string[], status: string) {
    this.store.dispatch(AssetEventsUiActions.bulkUpdateStatusByAssetEventId({ assetEventIds, status }));
  }

  bulkUpdateStatusByAssetEventId(assetEventIds: string[], status: string): void {
    this.store.dispatch(AssetEventsUiActions.newBulkUpdateStatusByAssetEventId({ assetEventIds, status }));
  }

  saveTableState(tableState: { checked: AssetEvent[]; selected: AssetEvent }) {
    this.store.dispatch(AssetEventsUiActions.saveTableState({ tableState }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (data.favoriteView.shared && !!data.grid) {
      this.store.dispatch(
        FavoriteViewsActions.shareGridThenAddFavoriteViewFromMV({
          grid: data.grid,
          favoriteView: data.favoriteView
        })
      );
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteViewFromMV({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (data.favoriteView.shared && !!data.grid) {
      this.store.dispatch(
        FavoriteViewsActions.shareGridThenUpdateFavoriteViewFromMV({
          grid: data.grid,
          favoriteView: data.favoriteView
        })
      );
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteViewFromMV({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'asset-events', favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'asset-events', filters }));
  }

  loadComments(assetEvent: AssetEvent) {
    this.store.dispatch(AssetEventsCommentsActions.loadComments({ assetEvent }));
  }

  addComment(assetEvent: AssetEvent, comment: string) {
    this.store.dispatch(AssetEventsCommentsActions.addComment({ assetEvent, comment }));
  }

  editComment(assetEventId: string, comment: Log): void {
    this.store.dispatch(AssetEventsCommentsActions.editComment({ assetEventId, comment }));
  }

  deleteComment(assetEvent: AssetEvent, commentId: string): void {
    this.store.dispatch(AssetEventsCommentsActions.deleteComment({ assetEvent, commentId }));
  }
}
