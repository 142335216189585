import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
  standalone: true,
  selector: 'grid-engine-outgoing-connector-cell',
  templateUrl: './outgoing-connector-cell.component.html',
  imports: [FlexLayoutModule, TranslateModule, NgClass, MatIcon, MatTooltip],
  styleUrls: ['./outgoing-connector-cell.component.scss']
})
export class OutgoingConnectorCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);

  displayInfoIcon: Signal<boolean> = computed(() => {
    const params = this.params();
    return params?.data && params.data.hasOwnProperty('hasCredential') && typeof params.data.hasCredential === 'boolean' && params.data.hasCredential === false;
  });

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
