import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CommonApiResponse,
  CommonGenericModel,
  CommonPagination,
  DynamicCachedItem,
  DynamicDataOptions,
  DynamicDataResponse,
  Environment
} from '@iot-platform/models/common';
import { get } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CustomEncoder } from '../encoder/custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class DynamicDataService {
  cachedData$: BehaviorSubject<DynamicCachedItem[]> = new BehaviorSubject<DynamicCachedItem[]>([]);

  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly httpClient: HttpClient
  ) {}

  getDynamicData(endpoint: string, options?: Partial<DynamicDataOptions>): Observable<DynamicDataResponse> {
    if (options && options.useCache) {
      return this.cachedData$.pipe(
        switchMap((cashedData: DynamicCachedItem[]) => {
          const value = get(options.rawData, get(options, 'cacheFieldId', ''), null);
          if (value === null) {
            return of(null);
          }
          const found: DynamicCachedItem | undefined = this.getCachedItem(cashedData, options, value);
          if (found) {
            return of(get(found, 'data', null));
          }
          return this.processHttpRequest(endpoint, options as DynamicDataOptions).pipe(
            tap((data: DynamicDataResponse) => {
              if (data !== null) {
                cashedData.push({
                  data,
                  cacheFieldId: options.cacheFieldId as string
                });
                this.cachedData$.next(cashedData);
              }
            })
          );
        })
      );
    } else {
      return this.processHttpRequest(endpoint, options as DynamicDataOptions);
    }
  }

  getCachedItem(cashedData: DynamicCachedItem[], options: Partial<DynamicDataOptions>, value: unknown): DynamicCachedItem | undefined {
    const applyPredicate = (item: DynamicDataResponse) => {
      const key: string = get(options, 'compareField', 'id');
      return get(item, key) === value;
    };
    return cashedData.find(
      (item: DynamicCachedItem) =>
        item.cacheFieldId === options.cacheFieldId &&
        (item.data instanceof Array ? item.data.find((e: DynamicDataResponse) => applyPredicate(e)) : applyPredicate(item.data))
    );
  }

  private processHttpRequest(endpoint: string, options: DynamicDataOptions): Observable<DynamicDataResponse> {
    const extendedEndpoint: boolean = get(options, 'extendedEndpoint', false);
    const apiVersion: number = get(options, 'version', 1);
    const url: string = extendedEndpoint ? `/${endpoint}` : this.environment.api.endpoints[endpoint];
    const path = `${apiVersion === 2 ? this.environment.api.url_v2 : this.environment.api.url}${url}`;
    const httpParams: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (options) {
      const { queryParams, pathParams, rawData, params, total } = options;
      if (extendedEndpoint) {
        return this.handleCustomPathParams(path, params, rawData, httpParams, total);
      } else if (queryParams) {
        // Handle query params
        return this.handleQueryParams(path, queryParams, rawData, httpParams);
      } else if (pathParams) {
        // Handle path params
        return this.handlePathParams(path, pathParams, rawData, httpParams);
      }
    }
    return this.callHttpRequest(path, httpParams);
  }

  private handleCustomPathParams(path: string, params: string[], rawData: unknown, httpParams: HttpParams, total: boolean): Observable<DynamicDataResponse> {
    for (const value of params) {
      const p: any = get(rawData, value.toString(), null);
      if (p === null) {
        return of(null);
      }
      path = path.replace(`{{${value}}}`, p);
    }
    return this.callHttpRequest(path, httpParams, total);
  }

  private handlePathParams(path: string, pathParams: string[], rawData: unknown, httpParams: HttpParams): Observable<DynamicDataResponse> {
    for (const value of pathParams) {
      const p: any = get(rawData, value.toString(), null);
      if (p === null) {
        return of(null);
      }
      path = `${path}/${p}`;
    }
    return this.callHttpRequest(path, httpParams);
  }

  private handleQueryParams(path: string, queryParams: { [key: string]: string }, rawData: unknown, httpParams: HttpParams): Observable<DynamicDataResponse> {
    for (const [key, value] of Object.entries(queryParams)) {
      // "params": {
      //     "nestedId": "id",
      //     "type": "type-value"
      //   }
      // nestedId exist in raw data
      // type is hardcoded value and does not exist in raw data
      // Final result : /[endpoint]?nestedId=<id-in-raw-data>&type=type-value
      const p: any = get(rawData, `${value}`, null);
      if (p === null) {
        return of(null);
      }
      httpParams = httpParams.append(key, p);
    }
    return this.callHttpRequest(path, httpParams);
  }

  private callHttpRequest(path: string, params: HttpParams, total: boolean = false): Observable<DynamicDataResponse> {
    return this.httpClient.get<CommonGenericModel | CommonApiResponse<CommonGenericModel, CommonPagination>>(path, { params }).pipe(
      map((response: any) => {
        if (total) {
          return response.page.total;
        }
        return response.content ?? response;
      })
    );
  }
}
