import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormulaType } from '@iot-platform/models/common';
import { AssetVariable, DeviceVariable, Formula } from '@iot-platform/models/i4b';
import { AutonomyParametersFormComponent } from '../formula-parameters-forms/autonomy-parameters-form/autonomy-parameters-form.component';
import { ConstantParametersFormComponent } from '../formula-parameters-forms/constant-parameters-form/constant-parameters-form.component';
import { ConsumptionParametersFormComponent } from '../formula-parameters-forms/consumption-parameters-form/consumption-parameters-form.component';
import { LinearizationParametersFormComponent } from '../formula-parameters-forms/linearization-parameters-form/linearization-parameters-form.component';
import { MinMaxAdvancedParametersFormComponent } from '../formula-parameters-forms/min-max-advanced-parameters-form/min-max-advanced-parameters-form.component';
import { MinMaxParametersFormComponent } from '../formula-parameters-forms/min-max-parameters-form/min-max-parameters-form.component';
import { ShortfallParametersFormComponent } from '../formula-parameters-forms/shortfall-parameters-form/shortfall-parameters-form.component';
import { SlopeShiftParametersFormComponent } from '../formula-parameters-forms/slope-shift-parameters-form/slope-shift-parameters-form.component';
import { SumParametersFormComponent } from '../formula-parameters-forms/sum-parameters-form/sum-parameters-form.component';

@Component({
  standalone: true,
  imports: [
    AutonomyParametersFormComponent,
    ConstantParametersFormComponent,
    ConsumptionParametersFormComponent,
    LinearizationParametersFormComponent,
    MinMaxParametersFormComponent,
    MinMaxAdvancedParametersFormComponent,
    ShortfallParametersFormComponent,
    SlopeShiftParametersFormComponent,
    SumParametersFormComponent
  ],
  selector: 'iot4bos-ui-asset-formula-parameters-configuration',
  templateUrl: './formula-parameters-configuration.component.html'
})
export class FormulaParametersConfigurationComponent {
  @Input() formulaType: string;
  @Input() initialFormula: Formula | null;
  @Input() deviceVariable?: DeviceVariable;
  @Input() assetVariable?: AssetVariable;
  @Input() selectedAssetVariables?: AssetVariable[];
  @Input() readonly = false;

  @Output() dispatchFormula: EventEmitter<Formula | null> = new EventEmitter<Formula | null>();
  @Output() updateSelectedAssetVariables: EventEmitter<AssetVariable[]> = new EventEmitter<AssetVariable[]>();

  protected readonly FormulaType = FormulaType;
}
