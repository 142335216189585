import { AssetEvent, Log } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadLogsByAssetEventSuccess = createAction('[AssetEventsBySite] Load Logs By Asset Event Success', props<{ logs: Log[] }>());
export const loadLogsByAssetEventFailure = createAction('[AssetEventsBySite] Load Logs By Asset Event Failure', props<{ error: any }>());
//
export const createLogByAssetEventSuccess = createAction(
  '[AssetEventsBySite] Create Log By Asset Event Success',
  props<{ log: Log; assetEvent: AssetEvent }>()
);
export const createLogByAssetEventFailure = createAction('[AssetEventsBySite] Create Log By Asset Event Failure', props<{ error: any }>());
