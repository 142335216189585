@if (data) {
  <div
    class="cell"
    [matTooltip]="data[cellOptions.tooltip] | dateFormat"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="above"
    [matTooltipDisabled]="!cellOptions.tooltip"
  >
    @if (!processedUnit && data[cellOptions.value] !== (undefined || null)) {
      <span> {{ data[cellOptions.value] | numberFormat | infoDisplay }} {{ data[cellOptions.unit] }} </span>
    }
    @if (processedUnit) {
      <span>{{ processedUnit }}</span>
    }
    @if (data[cellOptions.value] === (undefined || null)) {
      <span>-</span>
    }
  </div>
}
@if (!data) {
  <div class="cell no-value">-</div>
}
