import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, ProviderToken } from '@angular/core';
import { AbstractAuditTrailService } from '@iot-platform/audit-trail';
import { CustomEncoder } from '@iot-platform/core';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiListResponse, Environment } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type HistoryLogParams = { concept: string; elementId: string };

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService extends AbstractAuditTrailService<HistoryLogParams> {
  protected readonly httpClient: HttpClient = inject(HttpClient);
  protected readonly environment: Environment = inject('environment' as unknown as ProviderToken<Environment>);

  get baseUrl() {
    return `${this.environment.api.url}${this.environment.api.endpoints.auditTrail}`;
  }

  getHistoryLogs(options: HistoryLogParams): Observable<HistoryLog[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('concept', options.concept);
    params = params.set('conceptId', options.elementId);
    return this.httpClient.get<CommonApiListResponse<HistoryLog>>(this.baseUrl, { params }).pipe(map((response) => response.content));
  }

  getHistoryLogById(params: { id: string } & HistoryLogParams): Observable<HistoryLogDetail> {
    return this.httpClient.get<HistoryLogDetail>(`${this.baseUrl}/${params.id}`);
  }
}
