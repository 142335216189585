import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CustomEncoder, ENVIRONMENT, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { CommonApiListResponse, Environment, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetEvent, AssetVariable, Concept, Device, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventsService } from './events.service';
import * as moment from 'moment';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';

@Injectable({
  providedIn: 'root'
})
export class AssetEventsService extends EventsService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly httpClient: HttpClient = inject(HttpClient);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  getAssetEvents(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    params = params.set('limit', request.limit?.toString(10) ?? '100');
    params = params.set('page', request.page?.toString(10) ?? '0');

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<AssetEvent[]>(`${this.environment.api.url + this.environment.api.endpoints.assetEvents}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  getAssetEventById(eventId: string): Observable<AssetEvent> {
    return this.httpClient.get<AssetEvent>(`${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${eventId}`);
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + siteId);
  }

  getDeviceById(deviceId: string): Observable<Device | undefined> {
    return deviceId ? this.httpClient.get<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + deviceId) : of(undefined);
  }

  getDeviceVariableById(deviceVariableId: string): Observable<DeviceVariable | undefined> {
    return deviceVariableId
      ? this.httpClient.get<DeviceVariable>(this.environment.api.url + this.environment.api.endpoints.deviceVariables + '/' + deviceVariableId)
      : of(undefined);
  }

  getAssetById(assetId: string): Observable<Asset> {
    return this.httpClient.get<Asset>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + assetId);
  }

  getAssetVariableById(assetVariableId: string): Observable<AssetVariable> {
    return this.httpClient.get<AssetVariable>(this.environment.api.url + this.environment.api.endpoints.assetVariables + '/' + assetVariableId);
  }

  loadComments(assetEvent: AssetEvent): Observable<Log[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('startDate', moment(assetEvent.receptionTime).subtract(1, 'months').toISOString());

    const canReadAsset = this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.READ);
    const canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);

    return forkJoin([
      this.httpClient.get<CommonApiListResponse<Log>>(
        `${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${assetEvent.id}${this.environment.api.endpoints.logs}`
      ),
      canReadAsset
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetEvent.context?.asset?.id}${this.environment.api.endpoints.logs}`,
            { params }
          )
        : of([]),
      canReadSite
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints.sites}/${assetEvent.context?.site?.id}${this.environment.api.endpoints.logs}`,
            { params }
          )
        : of([])
    ]).pipe(
      map(([eventLogs, assetLogs, siteLogs]) => [
        ...eventLogs.content.map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'asset-event' })),
        ...assetLogs.map((log: Log) => ({ ...log, concept: Concept.ASSET, icon: 'asset' })),
        ...siteLogs.map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' }))
      ])
    );
  }

  addComment(assetEventId: string, comment: string): Observable<Log> {
    return this.httpClient
      .put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${assetEventId}/comments`, { comment })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'asset-event' })));
  }

  editComment(assetEventId: string, comment: Log): Observable<Log> {
    return this.httpClient
      .put<Log>(
        `${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${assetEventId}${this.environment.api.endpoints.logs}/${comment.id}`,
        {
          comment: comment.comment
        }
      )
      .pipe(map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'asset-event' })));
  }

  deleteComment(assetEventId: string, commentId: string): Observable<string> {
    return this.httpClient
      .delete<void>(
        `${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${assetEventId}${this.environment.api.endpoints.logs}/${commentId}`
      )
      .pipe(map(() => commentId));
  }

  getTagsByAssetEventId(assetEventId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get<TagCategory[]>(this.environment.api.url + this.environment.api.endpoints.assetEvents + `/${assetEventId}/tags`)
      .pipe(map((data: any) => data.content));
  }

  putStatus(status: { assetEventId: string; value: string }): Observable<AssetEvent> {
    return this.httpClient.put<AssetEvent>(`${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${status.assetEventId}/${status.value}`, {
      comment: null
    });
  }

  bulkUpdateStatus(assetEventIds: string[], status: string): Observable<AssetEvent>[] {
    return assetEventIds.map((id) => this.putStatus({ assetEventId: id, value: status }));
  }

  saveTableState(tableState: { selected: AssetEvent; checked: AssetEvent[] }): Observable<{ selected: AssetEvent; checked: AssetEvent[] }> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ASSET_EVENTS_TABLE_STATE_KEY, JSON.stringify(tableState));
    return of(tableState);
  }
}
