import { ContactNotification } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsDbActions, NotificationsUiActions } from '../actions';

export const notificationsDbFeatureKey = 'notificationsDb';

export interface State extends EntityState<ContactNotification> {
  selectedNotificationId: string | null;
  error: any;
}

export const adapter: EntityAdapter<ContactNotification> = createEntityAdapter<ContactNotification>({
  selectId: (notification: ContactNotification) => notification.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({ selectedNotificationId: null, error: null });

export const notificationsDbReducer = createReducer(
  initialState,
  on(NotificationsDbActions.loadNotificationsSuccess, (state: State, { notifications }) => adapter.setAll(notifications, state)),
  on(NotificationsUiActions.selectNotification, (state: State, { notificationToSelect }) => ({
    ...state,
    selectedNotificationId: notificationToSelect.id as string
  })),
  on(NotificationsDbActions.addNotificationSuccess, (state: State, { addedNotification }) => adapter.addOne(addedNotification, state)),
  on(NotificationsDbActions.updateNotificationSuccess, (state: State, { updatedNotification }) => adapter.upsertOne(updatedNotification, state)),
  on(NotificationsDbActions.deleteNotificationSuccess, (state: State, { deletedNotification }) => adapter.removeOne(deletedNotification.id as string, state)),
  on(NotificationsDbActions.configureNotificationsSuccess, (state: State, { updatedNotifications }) => adapter.upsertMany(updatedNotifications, state))
);

export function reducer(state: State | undefined, action: Action) {
  return notificationsDbReducer(state, action);
}

export const getSelectedNotificationId = (state: State) => state.selectedNotificationId;
export const getError = (state: State) => state.error;
