import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Contact } from '@iot-platform/models/common';
import { ContactNotification, ExportSpreadsheet } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatIconModule, TranslateModule, MatTooltipModule, NgClass],
  selector: 'iot4bos-ui-notifications-exports-tabs-related-contacts',
  templateUrl: './notifications-exports-tabs-related-contacts.component.html',
  styleUrls: ['./notifications-exports-tabs-related-contacts.component.scss']
})
export class NotificationsExportsTabsRelatedContactsComponent {
  @Input() selectedItem!: ContactNotification | ExportSpreadsheet;
  @Input() currentContact!: Contact;
}
