import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';
import { AbstractAuditTrailService } from '../services/abstract-audit-trail.service';

export interface AuditTrailSettings {
  auditTrailService: Type<AbstractAuditTrailService<unknown>>;
}

export const AUDIT_TRAIL_SETTINGS = new InjectionToken<AuditTrailSettings>('AUDIT_TRAIL_SETTINGS');

export function provideAuditTrailSettings(settings: AuditTrailSettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    { provide: AUDIT_TRAIL_SETTINGS, useValue: settings },
    { provide: AbstractAuditTrailService, useClass: settings.auditTrailService }
  ]);
}
