import { Subscriber } from '@iot-platform/models/i4b';
import { EscalationProtocol, Topic } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listProtocolSubscribedToTopicByTopicId = createAction(
  '[Topics] List Escalation Protocols Subscribed to Topic By Topic Id',
  props<{ topicId: string }>()
);
//
export const subscribeProtocolToTopic = createAction(
  '[Topics] Subscribe Protocol to Topic',
  props<{ protocolToSubscribe: EscalationProtocol; topic: Topic }>()
);
//
export const unsubscribeProtocolToTopic = createAction('[Topics] Unsubscribe Protocol to Topic', props<{ protocolToUnsubscribe: Subscriber; topic: Topic }>());
