import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { Site } from '@iot-platform/models/i4b';

@Component({
  standalone: true,
  imports: [NgClass, MatIconModule, FlexLayoutModule],
  selector: 'iot4bos-ui-site-breadcrumb-item',
  templateUrl: './site-breadcrumb-item.component.html',
  styleUrls: ['./site-breadcrumb-item.component.scss', '../breadcrumb-item.scss']
})
export class SiteBreadcrumbItemComponent {
  site = input<Site>();
  active = input<boolean>(true);

  navigate = output<{ activeId: string; requestedSiteId: string }>();

  onClick() {
    this.navigate.emit({ activeId: this.site().id, requestedSiteId: this.site().id });
  }
}
