import { DataGuardCheck } from './data-guard.model';

export interface DataGuardEvent {
  type: DataGuardEventType;
  check?: DataGuardCheck;
}

export enum DataGuardEventType {
  CLICK_ON_CLOSE_BUTTON = 'clickOnCloseButton',
  CLICK_ON_CHECK = 'clickOnCheck'
}
