<section [fxLayout]="displayMode() === 'default' ? 'row' : 'column'" fxLayoutGap="20px">
  @if (site(); as site) {
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
      <span (click)="selectSite.emit(site)" class="mve-detail-popup-title fw-600 mve-detail-popup-title-clickable">{{ site.name }}</span>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'SITES.CARD.TYPE' | translate }}"
        value="{{ 'SITES.CARD.TYPES.' + site.type | infoDisplay | translate }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
    @if (site.type !== SiteType.MOBILE_FLEET) {
      <div [fxFlex]="displayMode() === 'default' ? '50%' : '100%'" class="" fxLayout="column">
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'SITES.CARD.BUSINESS_ID' | translate }}"
          value="{{ site.businessId | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'SITES.INFO_FORM.ADDRESS_1' | translate }}:"
          value="{{ site.address.address1 | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'SITES.INFO_FORM.ADDRESS_2' | translate }}:"
          value="{{ site.address.address2 | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'SITES.INFO_FORM.CITY' | translate }}:"
          value="{{ site.address.city | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        @if (site.address.country) {
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'SITES.INFO_FORM.COUNTRY' | translate }}:"
            value="{{ 'SITES.CARD.COUNTRIES.' + site.address.country | translate }}"
          ></iot-platform-ui-detail-popup-display-property>
        }
      </div>
    }
  }
</section>
