import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Device, Scheduler } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const listSchedulersSuccess = createAction(
  '[Schedulers] list Schedulers Success',
  props<{ response: CommonApiResponse<Scheduler, CommonIndexedPagination> }>()
);
export const listSchedulersFailure = createAction('[Schedulers] List Schedulers Failure', props<{ error: any }>());

export const getSchedulerSuccess = createAction('[Schedulers] Get Scheduler Success', props<{ selectedScheduler: Scheduler }>());
export const getSchedulerFailure = createAction('[Scheduler] Get Scheduler Failure', props<{ error: any }>());

export const addSchedulerSuccess = createAction('[Schedulers] Add Scheduler Success', props<{ addedScheduler: Scheduler }>());
export const addSchedulerFailure = createAction('[Schedulers] Add Scheduler Failure', props<{ error: any }>());

export const updateSchedulerSuccess = createAction('[Schedulers] Update Scheduler Success', props<{ updatedScheduler: Scheduler }>());
export const updateSchedulerFailure = createAction('[Schedulers] Update Scheduler Failure', props<{ error: any }>());

export const deleteSchedulerSuccess = createAction('[Schedulers] Delete Scheduler Success', props<{ deletedScheduler: Scheduler }>());
export const deleteSchedulerFailure = createAction('[Schedulers] Delete Scheduler Failure', props<{ error: any }>());

export const loadMVSettingsSuccess = createAction('[Schedulers] Load MV Schedulers Settings Success', props<{ settings: any }>());
export const loadMVSettingsFailure = createAction('[Schedulers] Load MV Schedulers Settings Failure', props<{ error: any }>());
//
export const loadSchedulerSelectedDevicesMVSettingsSuccess = createAction(
  '[Schedulers] Load Scheduler Selected Devices MV Settings Success',
  props<{ settings: any }>()
);
export const loadSchedulerSelectedDevicesMVSettingsFailure = createAction(
  '[Schedulers] Load Scheduler Selected Devices MV Settings Failure',
  props<{ error: any }>()
);
//
export const loadSchedulerAvailableDevicesMVSettingsSuccess = createAction(
  '[Schedulers] Load Scheduler Available Devices MV Settings Success',
  props<{ settings: any }>()
);
export const loadSchedulerAvailableDevicesMVSettingsFailure = createAction(
  '[Schedulers] Load Scheduler Available Devices MV Settings Failure',
  props<{ error: any }>()
);
//
export const saveMVSettingsSuccess = createAction('[Schedulers] Save MV Schedulers Settings Success', props<{ settings: any }>());
export const saveMVSettingsFailure = createAction('[Schedulers] Save MV Schedulers Settings Failure', props<{ error: any }>());
//
export const loadAvailableDevicesForSchedulerSuccess = createAction(
  '[Schedulers] Load Available Devices For Scheduler Success',
  props<{ devices: CommonApiResponse<Device, CommonIndexedPagination> }>()
);
export const loadAvailableDevicesForSchedulerFailure = createAction('[Schedulers] Load Available Devices For Scheduler Failure', props<{ error: any }>());
//
export const loadSelectedDevicesForSchedulerSuccess = createAction(
  '[Schedulers] Load Selected Devices For Scheduler Success',
  props<{ devices: CommonApiResponse<Device, CommonIndexedPagination> }>()
);
export const loadSelectedDevicesForSchedulerFailure = createAction('[Schedulers] Load Selected Devices For Scheduler Failure', props<{ error: any }>());
//
export const loadTotalAvailableDevicesForSchedulerSuccess = createAction(
  '[Schedulers] Load Total Available Devices For Scheduler Success',
  props<{ totalDevices: number }>()
);
export const loadTotalAvailableDevicesForSchedulerFailure = createAction(
  '[Schedulers] Load Total Available Devices For Scheduler Failure',
  props<{ error: any }>()
);
//
export const loadTotalSelectedDevicesForSchedulerSuccess = createAction(
  '[Schedulers] Load Total Selected Devices For Scheduler Success',
  props<{ totalDevices: number }>()
);
export const loadTotalSelectedDevicesForSchedulerFailure = createAction(
  '[Schedulers] Load Total Selected Devices For Scheduler Failure',
  props<{ error: any }>()
);
//
export const launchNowSuccess = createAction('[Schedulers] Launch Now Success', props<{ launched: Scheduler }>());
export const launchNowFailure = createAction('[Scheduler] Launch Now Failure', props<{ error: any }>());
