import { Injectable } from '@angular/core';
import { Dashboard } from '@iot-platform/models/dashboards';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractI4bDashboardsService {
  abstract getDashboards(): Observable<Dashboard[]>;
}
