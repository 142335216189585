import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dashboard } from '@iot-platform/models/dashboards';
import { Observable } from 'rxjs';
import { AbstractI4bDashboardsService } from '../../../../services/abstract-i4b-dashboards.service';
import { I4bMasterViewDashboardService } from '../../../../services/i4b-master-view-dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class DevicesDashboardsService extends AbstractI4bDashboardsService {
  protected readonly httpClient: HttpClient = inject(HttpClient);

  getDashboards(): Observable<Dashboard[]> {
    return this.httpClient.get<Dashboard[]>('assets/data/device-dashboards.json');
  }
}

@Injectable({
  providedIn: 'root'
})
export class DevicesMasterViewDashboardService extends I4bMasterViewDashboardService {
  constructor(protected readonly service: DevicesDashboardsService) {
    super(service);
  }
}
