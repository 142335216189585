import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class SiteAddressColumn extends I4BBasicColumn {
  columnId = '3ee33e4c-5e98-11ec-b1e8-acde48001122-site-address-full';
  className = SiteAddressColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'ADDRESS FULL',
        type: HeaderType.GROUP
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig.id ? customConfig.id : 'address',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
        concept: I4bCellConcept.SITES,
        isDefault: false,
        isConfigurable: true,
        width: 500,
        children: [
          new I4BBasicColumn(
            { displayName: 'ADDRESS1' },
            { id: customConfig.id ? customConfig.id + '.address1' : 'address.address1', width: 130, isConfigurable: true },
            {
              customId: customConfig.id ? customConfig.id + '.address1' : 'address.address1'
            }
          ),
          new I4BBasicColumn(
            { displayName: 'ADDRESS2' },
            { id: customConfig.id ? customConfig.id + '.address2' : 'address.address2', width: 130, isConfigurable: true },
            {
              customId: customConfig.id ? customConfig.id + '.address2' : 'address.address2'
            }
          ),
          new I4BBasicColumn(
            { displayName: 'ZIP CODE' },
            { id: customConfig.id ? customConfig.id + '.zipCode' : 'address.zipCode', width: 120, isConfigurable: true },
            {
              customId: customConfig.id ? customConfig.id + '.zipCode' : 'address.zipCode'
            }
          ),
          new I4BBasicColumn(
            { displayName: 'STATE' },
            { id: customConfig.id ? customConfig.id + '.state' : 'address.state', width: 120, isConfigurable: true },
            {
              customId: customConfig.id ? customConfig.id + '.state' : 'address.state'
            }
          ),
          new I4BBasicColumn(
            { displayName: 'CITY' },
            { id: customConfig.id ? customConfig.id + '.city' : 'address.city', width: 120, isConfigurable: true },
            {
              customId: customConfig.id ? customConfig.id + '.city' : 'address.city'
            }
          ),
          new I4BBasicColumn(
            { displayName: 'COUNTRY' },
            {
              id: customConfig.id ? customConfig.id + '.country' : 'address.country',
              width: 120,
              isConfigurable: true,
              cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.COUNTRIES.' } }
            },
            {
              customId: customConfig.id ? customConfig.id + '.city' : 'address.city'
            }
          )
        ]
      },
      { customId: customConfig.id ? customConfig.id : 'address', order: customOptions ? customOptions.order : 99, ...customOptions }
    );
  }
}
