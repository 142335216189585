import { IotToolbarDefaultButton, IotToolbarMenuButton } from '@iot-platform/iot-platform-ui';
import { FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Observable } from 'rxjs';
import { FeatureMasterViewEvent } from '../models/master-view-event.model';

export abstract class AbstractMasterViewService {
  abstract getPermissions(): Observable<
    {
      key: string;
      value: boolean;
    }[]
  >;

  abstract getInitialFilters(): Observable<Filter[]>;

  abstract getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null>;

  abstract onMasterViewEngineEvent(event: FeatureMasterViewEvent): void;

  abstract onToolbarEvent(event: FeatureMasterViewEvent): void;

  abstract onBusinessProfileChange(event: FeatureMasterViewEvent): void;

  abstract getGridConfiguration(): Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null>;

  abstract getGrids(): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]>;

  abstract getToolbarButtons(): Observable< (IotToolbarDefaultButton | IotToolbarMenuButton)[]>;

  abstract getFavoriteViewsConfiguration(): Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null>;

  abstract canUpdateBusinessProfile(): Observable<boolean>;
}
