import { PlatformResponse } from '@iot-platform/models/common';
import { EscalationProtocol, EscalationProtocolLevel } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listEscalationProtocolsSuccess = createAction('[EscalationProtocols] List Escalation Protocols Success', props<{ response: PlatformResponse }>());
export const listEscalationProtocolsFailure = createAction('[EscalationProtocols] List Escalation Protocols Failure', props<{ error: any }>());
//
export const loadEscalationProtocolByIdSuccess = createAction(
  '[EscalationProtocols] Load Escalation Protocol By Id Success',
  props<{ protocol: EscalationProtocol }>()
);
export const loadEscalationProtocolByIdFailure = createAction('[EscalationProtocols] Load Escalation Protocol By Id Failure', props<{ error: any }>());
//
export const addEscalationProtocolSuccess = createAction(
  '[EscalationProtocols] Add Escalation Protocol Success',
  props<{ addedProtocol: EscalationProtocol }>()
);
export const addEscalationProtocolFailure = createAction('[EscalationProtocols] Add Escalation Protocol Failure', props<{ error: any }>());
//
export const updateEscalationProtocolSuccess = createAction(
  '[EscalationProtocols] Update Escalation Protocol Success',
  props<{ updatedProtocol: EscalationProtocol }>()
);
export const updateEscalationProtocolFailure = createAction('[EscalationProtocols] Update Escalation Protocol Failure', props<{ error: any }>());
//
export const deleteEscalationProtocolSuccess = createAction(
  '[EscalationProtocols] Delete Escalation Protocol Success',
  props<{ deletedProtocol: EscalationProtocol }>()
);
export const deleteEscalationProtocolFailure = createAction('[EscalationProtocols] Delete Escalation Protocol Failure', props<{ error: any }>());
//
export const selectEscalationProtocolSuccess = createAction(
  '[EscalationProtocols] Select Escalation Protocol Success',
  props<{ selectedProtocol: EscalationProtocol }>()
);
export const selectEscalationProtocolFailure = createAction('[EscalationProtocols] Select Escalation Protocol Failure', props<{ error: any }>());
//
export const loadTopicSubscriptionByProtocolIdSuccess = createAction(
  '[EscalationProtocols] Load Topic Subscriptions By Escalation Protocol Id Success',
  props<{ topics: PlatformResponse }>()
);
export const loadTopicSubscriptionByProtocolIdFailure = createAction(
  '[EscalationProtocols] Load Topic Subscriptions By Escalation Protocol Id Failure',
  props<{ error: any }>()
);
//
export const updateEscalationProtocolTopicSubscriptionsSuccess = createAction(
  '[EscalationProtocols] Update Escalation Protocol Topic Subscriptions Success',
  props<{ updatedTopics: PlatformResponse }>()
);
export const updateEscalationProtocolTopicSubscriptionsFailure = createAction(
  '[EscalationProtocols] Update Escalation Protocol Topic Subscriptions Failure',
  props<{ error: any }>()
);
//
export const listEscalationProtocolLevelsSuccess = createAction(
  '[EscalationProtocols] List Escalation Protocol Levels Success',
  props<{ levels: EscalationProtocolLevel[] }>()
);
export const listEscalationProtocolLevelsFailure = createAction('[EscalationProtocols] List Escalation Protocol Levels Failure', props<{ error: any }>());
//
export const addEscalationProtocolLevelSuccess = createAction(
  '[EscalationProtocols] Add Escalation Protocol Level Success',
  props<{ addedLevel: EscalationProtocolLevel }>()
);
export const addEscalationProtocolLevelFailure = createAction('[EscalationProtocols] Add Escalation Protocol Level Failure', props<{ error: any }>());
//
export const updateEscalationProtocolLevelSuccess = createAction(
  '[EscalationProtocols] Update Escalation Protocol Level Success',
  props<{ updatedLevel: EscalationProtocolLevel }>()
);
export const updateEscalationProtocolLevelFailure = createAction('[EscalationProtocols] Update Escalation Protocol Level Failure', props<{ error: any }>());
//
export const updateEscalationProtocolLevelsOrdersSuccess = createAction(
  '[EscalationProtocols] Update Escalation Protocol Levels Orders Success',
  props<{ updatedLevels: EscalationProtocolLevel[] }>()
);
export const updateEscalationProtocolLevelsOrdersFailure = createAction(
  '[EscalationProtocols] Update Escalation Protocol Levels Orders Failure',
  props<{ error: any }>()
);
//
export const deleteEscalationProtocolLevelThenUpdateLevelsSuccess = createAction(
  '[EscalationProtocols] Delete Escalation Protocol Level Success',
  props<{ deletedLevel: EscalationProtocolLevel; updatedLevels: EscalationProtocolLevel[] }>()
);
export const deleteEscalationProtocolLevelThenUpdateLevelsFailure = createAction(
  '[EscalationProtocols] Delete Escalation Protocol Level Failure',
  props<{ error: any }>()
);
//
