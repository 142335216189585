import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, InfoDisplayPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { TableEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BusinessProfilesListModule } from '../../components/business-profiles-list/business-profiles-list.module';
import { RolesListModule } from '../../components/roles-list/roles-list.module';
import { UsersListModule } from '../../components/users-list/users-list.module';
import { BusinessProfileTagsComponent } from './components/business-profile-tags/business-profile-tags.component';
import { BusinessProfilesDetailComponent } from './components/business-profiles-detail/business-profiles-detail.component';
import { FavoriteViewsDetailComponent } from './components/favorite-views-detail/favorite-views-detail.component';
import { FilterComponent } from './components/filter/filter.component';
import { GridManagerBackofficeComponent } from './components/grid-manager/grid-manager-backoffice.component';
import { AdminBusinessProfilesComponent } from './containers/admin-business-profiles.component';
import { BusinessProfilesService } from './services/business-profiles.service';
import { AdminBusinessProfilesFavoriteViewsEffects } from './state/effects/admin-business-profiles-favorite-views.effects';
import { AdminBusinessProfilesGridsEffects } from './state/effects/admin-business-profiles-grids.effects';
import { AdminBusinessProfilesMembersEffects } from './state/effects/admin-business-profiles-members.effects';
import { AdminBusinessProfilesRolesEffects } from './state/effects/admin-business-profiles-roles.effects';
import { AdminBusinessProfilesTagsEffects } from './state/effects/admin-business-profiles-tags.effects';
import { AdminBusinessProfilesEffects } from './state/effects/admin-business-profiles.effects';
import * as fromAdminBusinessProfiles from './state/reducers';

const SHARED_COMPONENTS = [AdminBusinessProfilesComponent, FilterComponent, BusinessProfilesDetailComponent, BusinessProfileTagsComponent];

@NgModule({
  declarations: [...SHARED_COMPONENTS, FavoriteViewsDetailComponent, GridManagerBackofficeComponent],
  exports: [...SHARED_COMPONENTS, BusinessProfilesListModule],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(fromAdminBusinessProfiles.adminBusinessProfilesFeatureKey, fromAdminBusinessProfiles.reducers),
    EffectsModule.forFeature([
      AdminBusinessProfilesEffects,
      AdminBusinessProfilesRolesEffects,
      AdminBusinessProfilesMembersEffects,
      AdminBusinessProfilesTagsEffects,
      AdminBusinessProfilesFavoriteViewsEffects,
      AdminBusinessProfilesGridsEffects
    ]),
    BusinessProfilesListModule,
    RolesListModule,
    UsersListModule,
    TableEngineModule,
    DateFormatPipe,
    IotPlatformUiModule,
    InfoDisplayPipe
  ],
  providers: [BusinessProfilesService]
})
export class AdminBusinessProfilesModule {}
