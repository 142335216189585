import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { CalendarsDbActions, CalendarsUiActions } from '../actions';

export const calendarsUiFeatureKey = 'calendarsUi';

export interface State {
  error?: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false,
  loading: false
};

const calendarsUiReducer = createReducer(
  initialState,
  on(CalendarsUiActions.listCalendars, (state: State) => ({ ...state, loading: true, loaded: false })),
  on(CalendarsDbActions.listCalendarsSuccess, (state: State) => ({ ...state, loading: false, loaded: true })),
  on(CalendarsDbActions.listCalendarsFailure, (state: State) => ({ ...state, loading: false, loaded: true })),
  //
  on(CalendarsUiActions.getCalendar, (state: State) => ({ ...state, loading: true, loaded: false })),
  on(CalendarsDbActions.getCalendarSuccess, (state: State) => ({ ...state, loading: false, loaded: true })),
  on(CalendarsDbActions.getCalendarFailure, (state: State) => ({ ...state, loading: false, loaded: true })),
  //
  on(CalendarsUiActions.addCalendar, (state: State) => ({ ...state, loading: true, loaded: false })),
  on(CalendarsDbActions.addCalendarSuccess, (state: State) => ({ ...state, loading: false, loaded: true })),
  on(CalendarsDbActions.addCalendarFailure, (state: State) => ({ ...state, loading: false, loaded: true })),

  on(CalendarsUiActions.updateCalendar, (state: State) => ({ ...state, loading: true, loaded: false })),
  on(CalendarsDbActions.updateCalendarSuccess, (state: State) => ({ ...state, loading: false, loaded: true })),
  on(CalendarsDbActions.updateCalendarFailure, (state: State) => ({ ...state, loading: false, loaded: true })),

  on(CalendarsUiActions.deleteCalendar, (state: State) => ({ ...state, loading: true, loaded: false })),
  on(CalendarsDbActions.deleteCalendarSuccess, (state: State) => ({ ...state, loading: false, loaded: true })),
  on(CalendarsDbActions.deleteCalendarFailure, (state: State) => ({ ...state, loading: false, loaded: true })),

  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return calendarsUiReducer(state, action);
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
