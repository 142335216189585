import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination, TagCategory } from '@iot-platform/models/common';
import { Asset, Site } from '@iot-platform/models/i4b';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromAssets from '../reducers/assets.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetsSelectors extends ApiFeatureSelector<fromAssets.State, Asset, Pagination, Filter> {
  selectTagsByAsset: MemoizedSelector<fromAssets.State, TagCategory[]> = createSelector(
    this.selectState,
    (state: fromAssets.State): TagCategory[] => state.tags
  );

  selectAssetCreationByTemplateStatuses: MemoizedSelector<
    fromAssets.State,
    {
      pending: boolean;
      completed: boolean;
    }
  > = createSelector(
    this.selectState,
    (
      state: fromAssets.State
    ): {
      pending: boolean;
      completed: boolean;
    } => state.assetCreationByTemplateStatuses
  );
  selectSite: MemoizedSelector<fromAssets.State, Site | null> = createSelector(this.selectState, (state: fromAssets.State): Site | null => state.site);
  selectSiteLoading: MemoizedSelector<fromAssets.State, boolean> = createSelector(this.selectState, (state: fromAssets.State): boolean => state.siteLoading);
  selectSiteLoaded: MemoizedSelector<fromAssets.State, boolean> = createSelector(this.selectState, (state: fromAssets.State): boolean => state.siteLoaded);
  selectTagsLoaded: MemoizedSelector<fromAssets.State, boolean> = createSelector(this.selectState, (state: fromAssets.State): boolean => state.tagsLoaded);
  selectTagsLoading: MemoizedSelector<fromAssets.State, boolean> = createSelector(this.selectState, (state: fromAssets.State): boolean => state.tagsLoading);
  selectError: MemoizedSelector<fromAssets.State, any> = createSelector(this.selectState, (state: fromAssets.State) => state.error);

  constructor() {
    super(fromAssets.assetsFeature.selectAssetsState, fromAssets.adapter);
  }
}
