import { Injectable } from '@angular/core';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, concatMap, map, of, tap } from 'rxjs';

import { BusinessProfilesService } from '../../services/business-profiles.service';
import { BusinessProfilesGridsApiActions, BusinessProfilesGridsPageActions, BusinessProfilesPageActions } from '../actions';

@Injectable()
export class AdminBusinessProfilesGridsEffects {
  addGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsPageActions.addGrid),
      concatMap((action) =>
        this.businessProfilesService.addGrid(action.gridToAdd).pipe(
          map((addedGrid) => BusinessProfilesGridsApiActions.addGridSuccess({ grid: addedGrid })),
          catchError((error) => of(BusinessProfilesGridsApiActions.addGridFailure({ error })))
        )
      )
    )
  );

  updateGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsPageActions.updateGrid),
      concatMap((action) =>
        this.businessProfilesService.updateGrid(action.gridToUpdate).pipe(
          map((updatedGrid) => BusinessProfilesGridsApiActions.updateGridSuccess({ grid: updatedGrid })),
          catchError((error) => of(BusinessProfilesGridsApiActions.updateGridFailure({ error })))
        )
      )
    )
  );

  deleteGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsPageActions.deleteGrid),
      concatMap((action) =>
        this.businessProfilesService.deleteGrid(action.gridToDelete).pipe(
          map((deletedGrid) => BusinessProfilesGridsApiActions.deleteGridSuccess({ removed: deletedGrid })),
          catchError((error) => of(BusinessProfilesGridsApiActions.deleteGridFailure({ error })))
        )
      )
    )
  );

  addGridSuccessThenDispatchGridsBdAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsApiActions.addGridSuccess),
      concatMap((action) => [GridsDbActions.loadGrids({ concept: action.grid.masterview }), GridsDbActions.addGridSuccess({ grid: action.grid })])
    )
  );

  updateGridSuccessThenDispatchGridsBdAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsApiActions.updateGridSuccess),
      concatMap((action) => [GridsDbActions.loadGrids({ concept: action.grid.masterview }), GridsDbActions.updateGridSuccess({ grid: action.grid })])
    )
  );

  deleteGridSuccessThenDispatchGridsBdAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsApiActions.deleteGridSuccess),
      concatMap((action) => [GridsDbActions.loadGrids({ concept: action.removed.masterview }), GridsDbActions.removeGridSuccess({ removed: action.removed })])
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesGridsApiActions.addGridSuccess,
          BusinessProfilesGridsApiActions.updateGridSuccess,
          BusinessProfilesGridsApiActions.deleteGridSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesGridsApiActions.addGridFailure,
          BusinessProfilesGridsApiActions.updateGridFailure,
          BusinessProfilesGridsApiActions.deleteGridFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesPageActions.listGridsByBusinessProfile),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesGridsApiActions.listGridsByBusinessProfileSuccess, BusinessProfilesGridsApiActions.listGridsByBusinessProfileFailure),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly businessProfilesService: BusinessProfilesService
  ) {}
}
