import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, HostBinding, Inject, inject, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl, ValidatorFn } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { AbstractFormControl } from './abstract-form-control';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractMatFormControl<T> extends AbstractFormControl implements OnInit, OnDestroy, MatFormFieldControl<T> {
  @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField = inject(MatFormField);
  @Optional() @Self() public ngControl: NgControl = inject(NgControl);
  @Optional() @Self() public focusMonitor: FocusMonitor = inject(FocusMonitor);
  nextId = 0;
  controlType: string = this.getControlId();

  @HostBinding() id = `${this.getControlId()}-${this.nextId++}`;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy!: string;

  constructor() {
    super();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this as NonNullable<never>;
    }
    this.initForm();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return this.isEmpty();
  }

  get errorState(): boolean {
    return (this.form.invalid || this.ngControl?.control?.invalid || false) && this.touched;
  }

  get control() {
    return this.ngControl?.control;
  }

  abstract isEmpty(): boolean;

  abstract getControlId(): string;

  abstract getValidators(): ValidatorFn[];

  ngOnInit() {
    const validators = this.control?.validator ? [this.control.validator, ...this.getValidators()] : [...this.getValidators()];
    this.control?.setValidators(validators);
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.focusMonitor.focusVia(this.elementRef.nativeElement.querySelector('input'), 'program');
    }
  }

  onFocusIn(_: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.elementRef.nativeElement.querySelector('id');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }
}
