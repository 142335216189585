<div class="detail-card">
  <ul class="detail-card-list">
    @if (extraFill()) {
      <li class="detail-card-item"
          attr.data-label="{{ 'DETAIL_CARD_COMPONENT.NAME' | translate }}">{{ site()?.name | infoDisplay }}
      </li>
      <li class="detail-card-item"
          attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ENTITY' | translate }}">{{ site()?.entity.name | infoDisplay }}
      </li>
    }
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ID' | translate }}"
        class="detail-card-item">{{ site()?.businessId | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.TYPE' | translate }}"
        class="detail-card-item">{{ site()?.type | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ADDRESS_1' | translate }}"
        class="detail-card-item">{{ site()?.address.address1 | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ADDRESS_2' | translate }}"
        class="detail-card-item">{{ site()?.address.address2 | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_CARD_COMPONENT.CITY' | translate }}" class="detail-card-item">
      {{ site()?.address | infoDisplay: 'brackets' : 'city' : 'zipCode' }}
    </li>
  </ul>
</div>
