import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { BusinessProfilesListComponent } from './business-profiles-list.component';

@NgModule({
  declarations: [BusinessProfilesListComponent],
  imports: [SharedModule, IotPlatformUiModule],
  exports: [BusinessProfilesListComponent]
})
export class BusinessProfilesListModule {}
