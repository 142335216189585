import { Concept } from './enums';

export enum LogType {
  USER = 'user',
  START_MAINTENANCE = 'start_maintenance',
  END_MAINTENANCE = 'end_maintenance',
  STATUS_CHANGE = 'status_change'
}

export interface Log {
  id: string;
  eventIdentifier: string;
  type: LogType;
  previousStatus: string;
  currentStatus: string;
  snoozeDuration: string;
  comment: string;
  concept?: Concept;
  icon?: string;
  user: {
    id: string;
    name: string;
  };
  datetime: string;
}
