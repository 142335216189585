import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

enum Status {
  ok = 'status_ok',
  warning = 'status_warning',
  error = 'status_error',
  na = 'status_na'
}

@Component({
  selector: 'iot-platform-ui-status-icon',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './status-icon.component.html',
  styleUrl: './status-icon.component.scss'
})
export class StatusIconComponent {
  status = input<Status>(Status.na);
  protected readonly Status = Status;
}
