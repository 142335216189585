import { NgModule } from '@angular/core';
import { DetailPopupDisplayPropertyComponent } from './detail-popup-display-property/detail-popup-display-property.component';
import { DetailPopupMetadataFooterComponent } from './detail-popup-metadata-footer/detail-popup-metadata-footer.component';
import { DetailPopupSectionHeaderComponent } from './detail-popup-section-header/detail-popup-section-header.component';

@NgModule({
  imports: [DetailPopupSectionHeaderComponent, DetailPopupDisplayPropertyComponent, DetailPopupMetadataFooterComponent],
  exports: [DetailPopupSectionHeaderComponent, DetailPopupDisplayPropertyComponent, DetailPopupMetadataFooterComponent]
})
export class DetailPopupModule {}
