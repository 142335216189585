import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ExportSpreadsheetsDbActions, ExportSpreadsheetsUiActions } from '../actions';

export const exportSpreadsheetsDbFeatureKey = 'exportSpreadsheetsDb';

export interface State extends EntityState<ExportSpreadsheet> {
  selectedExportSpreadsheetId: string | null;
  error: any;
}

export const adapter: EntityAdapter<ExportSpreadsheet> = createEntityAdapter<ExportSpreadsheet>({
  selectId: (exportSpreadsheet: ExportSpreadsheet) => exportSpreadsheet.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({ selectedExportSpreadsheetId: null, error: null });

export const exportSpreadsheetsDbReducer = createReducer(
  initialState,
  on(ExportSpreadsheetsDbActions.loadExportSpreadsheetsSuccess, (state: State, { exports }) => adapter.setAll(exports, state)),
  on(ExportSpreadsheetsDbActions.addExportSpreadsheetSuccess, (state: State, { addedExportSpreadsheet }) => adapter.addOne(addedExportSpreadsheet, state)),
  on(ExportSpreadsheetsDbActions.updateExportSpreadsheetSuccess, (state: State, { updatedExportSpreadsheet }) =>
    adapter.upsertOne(updatedExportSpreadsheet, state)
  ),
  on(ExportSpreadsheetsDbActions.deleteExportSpreadsheetSuccess, (state: State, { deletedExportSpreadsheet }) =>
    adapter.removeOne(deletedExportSpreadsheet.id as string, state)
  ),
  on(ExportSpreadsheetsUiActions.selectExportSpreadsheet, (state: State, { exportSpreadsheetToSelect }) => ({
    ...state,
    selectedExportSpreadsheetId: exportSpreadsheetToSelect.id
  })),
  on(ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsSuccess, (state: State, { updatedExportSpreadsheets }) =>
    adapter.upsertMany(updatedExportSpreadsheets, state)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return exportSpreadsheetsDbReducer(state, action);
}

export const getSelectedExportSpreadsheetId = (state: State) => state.selectedExportSpreadsheetId;
export const getError = (state: State) => state.error;
