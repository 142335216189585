import { computed, Injectable, Signal } from '@angular/core';
import { AuthorizationConcept, AuthorizationType } from '@iot-platform/auth';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { Filter, Pagination, TagCategory } from '@iot-platform/models/common';
import { CommandType, CommonBulkOperationType, Device, DeviceCallLog, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { UserPreferencesService } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { I4BBaseFacade } from '../../../../models/ngrx/i4b-base-facade.model';
import { DeviceCommentsActions, DevicesActions } from '../actions';
import * as deviceCommentsSelectors from '../selectors/device-comments.selectors';
import { DevicesSelectors } from '../selectors/devices.selectors';

@Injectable({
  providedIn: 'root'
})
export class DevicesFacade extends I4BBaseFacade<Device, Pagination, Filter> {
  concept = 'devices';
  grids$ = this.store.select(fromGrids.getDevicesGrids);
  grids = this.store.selectSignal(fromGrids.getDevicesGrids);
  grid$ = this.store.select(fromGrids.getDefaultDevicesGrid);
  grid = this.store.selectSignal(fromGrids.getDefaultDevicesGrid);
  gridConfiguration = this.store.selectSignal(fromGrids.selectDeviceGridsConfiguration);
  gridConfiguration$ = this.store.select(fromGrids.selectDeviceGridsConfiguration);

  favoriteViews$ = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDevices);
  favoriteViews = this.store.selectSignal(fromFavoriteViews.getFavoriteViewsForMasterViewDevices);
  currentFavoriteView$ = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDevices);
  currentFavoriteView = this.store.selectSignal(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDevices);
  favoriteViewConfiguration$ = this.store.select(fromFavoriteViews.selectDeviceFavoriteViewsConfiguration);
  favoriteViewConfiguration = this.store.selectSignal(fromFavoriteViews.selectDeviceFavoriteViewsConfiguration);

  filters = this.store.selectSignal(fromFavoriteViews.getFiltersForMasterViewDevices);
  filters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewDevices);

  deviceSite: Signal<Site | null> = this.store.selectSignal(this.selector.selectDeviceSite);
  deviceSiteLoading: Signal<boolean> = this.store.selectSignal(this.selector.selectDeviceSiteLoading);
  deviceTags: Signal<TagCategory[]> = this.store.selectSignal(this.selector.selectDeviceTags);
  deviceTagsLoading: Signal<boolean> = this.store.selectSignal(this.selector.selectDeviceTagsLoading);
  deviceCallLogs$: Observable<DeviceCallLog[]> = this.store.select(this.selector.selectDeviceCallLogs);
  deviceComments: Signal<Log[]> = this.store.selectSignal(deviceCommentsSelectors.selectAllDeviceComments);
  deviceCommentsLoading: Signal<boolean> = this.store.selectSignal(deviceCommentsSelectors.selectDeviceCommentsLoading);

  canRead = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
  });

  canCreate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
  });

  canUpdate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.UPDATE);
  });

  canDelete = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.DELETE);
  });

  canReadSite = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
  });

  constructor(
    protected store: Store,
    protected selector: DevicesSelectors,
    private userPreferencesService: UserPreferencesService
  ) {
    super(store, selector);
  }

  getAll(): void {
    noop();
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'devices', filters }));
  }

  loadDeviceById(deviceId: string): void {
    this.store.dispatch(DevicesActions.loadDeviceById({ deviceId }));
  }

  loadDeviceSite(siteId: string): void {
    this.store.dispatch(DevicesActions.loadDeviceSite({ siteId }));
  }

  loadDeviceTags(deviceId: string): void {
    this.store.dispatch(DevicesActions.loadDeviceTags({ deviceId }));
  }

  loadDeviceVariables(deviceId: string) {
    this.store.dispatch(DevicesActions.loadDeviceVariables({ deviceId }));
  }

  loadDeviceCallLogs(deviceId: string): void {
    this.store.dispatch(DevicesActions.loadDeviceCallLogs({ deviceId }));
  }

  updateDevice(device: Device): void {
    this.store.dispatch(DevicesActions.updateDevice({ device }));
  }

  activateDevice(device: Device): void {
    this.store.dispatch(DevicesActions.activateDevice({ device }));
  }

  deleteDevice(device: Device): void {
    this.store.dispatch(DevicesActions.deleteDevice({ device }));
  }

  updateDeviceTags(deviceId: string, tags: TagCategory[]): void {
    this.store.dispatch(DevicesActions.updateDeviceTags({ deviceId, tags }));
  }

  bulkOperationOnTag(bulkOperationType: CommonBulkOperationType, devicesIds: string[], tagLabelId: string): void {
    this.store.dispatch(DevicesActions.bulkOperationOnTag({ bulkOperationType, devicesIds, tagLabelId }));
  }

  sendCommand(device: Device, command: { command: CommandType }): void {
    this.store.dispatch(DevicesActions.sendCommand({ device, command }));
  }

  bulkSendCommand(devicesIds: string[], command: { command: CommandType }): void {
    this.store.dispatch(DevicesActions.bulkSendCommand({ devicesIds, command }));
  }

  moveDevices(devices: Device[], originSite?: Site): void {
    this.store.dispatch(DevicesActions.moveDevices({ devices, originSite }));
  }

  resetDevice(device: Device) {
    this.store.dispatch(DevicesActions.resetDevice({ device }));
  }

  deleteDeviceVariables(deviceVariables: DeviceVariable[]) {
    this.store.dispatch(DevicesActions.deleteDeviceVariables({ deviceVariables }));
  }

  resetDeviceVariablesLastValues(deviceVariables: DeviceVariable[]) {
    this.store.dispatch(DevicesActions.resetDeviceVariablesLastValues({ deviceVariables }));
  }

  getCallLogsMVSettings(): Observable<any> {
    return this.userPreferencesService.loadActiveSettings('device-call-logs');
  }

  loadComments(device: Device): void {
    this.store.dispatch(DeviceCommentsActions.loadComments({ device }));
  }

  addComment(deviceId: string, comment: string): void {
    this.store.dispatch(DeviceCommentsActions.addComment({ deviceId, comment }));
  }

  editComment(deviceId: string, comment: Log): void {
    this.store.dispatch(DeviceCommentsActions.editComment({ deviceId, comment }));
  }

  deleteComment(deviceId: string, commentId: string): void {
    this.store.dispatch(DeviceCommentsActions.deleteComment({ deviceId, commentId }));
  }

  reloadGrid(): void {
    const grid = this.grid();
    const filters = this.filters();

    if (grid) {
      this.store.dispatch(
        GridsDbActions.loadGridData({
          request: {
            filters,
            limit: grid?.data?.response?.pagination?.limit,
            concept: grid?.masterview.toLowerCase(),
            page: grid?.data?.response?.pagination?.currentPage,
            variables: grid?.gridOptions.variableNames,
            tags: grid?.gridOptions.tagIds,
            endPoint: grid?.gridOptions.endPoint
          }
        })
      );
    }
  }

  updateDeviceInCurrentGrid(item: Device): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(GridsDbActions.updateItemInGridData({ gridId, item, concept: 'devices' }));
    }
  }
}
