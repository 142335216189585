<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  <mat-checkbox class="role-crud-checkbox" fxLayoutAlign="center center" [disabled]="disabled" [(ngModel)]="canRead" (change)="onToggleRead($event)">
  </mat-checkbox>

  <mat-checkbox class="role-crud-checkbox" fxLayoutAlign="center center" [disabled]="disabled" [(ngModel)]="canCreate" (change)="onToggleCreate($event)">
  </mat-checkbox>

  <mat-checkbox class="role-crud-checkbox" fxLayoutAlign="center center" [disabled]="disabled" [(ngModel)]="canUpdate" (change)="onToggleUpdate($event)">
  </mat-checkbox>

  <mat-checkbox class="role-crud-checkbox" fxLayoutAlign="center center" [disabled]="disabled" [(ngModel)]="canDelete" (change)="onToggleDelete($event)">
  </mat-checkbox>
</div>
