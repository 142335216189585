import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserPreferencesService } from './services/user-preferences.service';
import { PreferencesEffects } from './state/effects/preferences.effects';
import * as fromPreferences from './state/reducers';

@NgModule({
  imports: [StoreModule.forFeature(fromPreferences.userPreferencesFeatureKey, fromPreferences.reducers), EffectsModule.forFeature([PreferencesEffects])],
  providers: [UserPreferencesService]
})
export class PreferencesModule {}
