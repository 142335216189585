import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class DescriptionColumn extends I4BBasicColumn {
  columnId = '558445ce-5b50-11ec-80a5-0023a40210d0-description';
  className = DescriptionColumn;
  dataLibs = [DataLibrary.SITES, DataLibrary.ASSETS];

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions.overrideHeaderName ? customOptions.overrideHeaderName : customHeader.displayName ? customHeader.displayName : 'DESCRIPTION',
        ...customHeader
      },
      {
        id: customConfig.id ? customConfig.id : 'description',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
        isConfigurable: true,
        isDefault: customConfig.isDefault ? customConfig.isDefault : false,
        width: customOptions?.width ? customOptions.width : customConfig.width ? customConfig.width : 200,
        concept: customConfig.concept ?? ('' as I4bCellConcept),
        ...customConfig
      },
      { customId: customConfig.id ? customConfig.id : 'description', ...customOptions }
    );
  }
}
