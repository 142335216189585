import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, DestroyRef, effect, inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { EntityCellService } from './entity-cell.service';

@Component({
  standalone: true,
  selector: 'grid-engine-entity-cell',
  templateUrl: './entity-cell.component.html',
  imports: [MatProgressSpinner, InfoDisplayPipe],
  providers: [InfoDisplayPipe],
  styleUrls: ['./entity-cell.component.scss']
})
export class EntityCellComponent implements ICellRendererAngularComp {
  private readonly entityCellService: EntityCellService = inject(EntityCellService);
  params: WritableSignal<ICellRendererParams> = signal(null);
  loading: WritableSignal<boolean> = signal(false);
  displayErrorMessage: WritableSignal<boolean> = signal(false);
  dataToDisplay: WritableSignal<string> = signal('');
  destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    effect(
      () => {
        const params = this.params();
        if (params.value) {
          this.loading.set(true);
          this.displayErrorMessage.set(false);
          this.entityCellService
            .getEntityById(params.value)
            .pipe(
              finalize(() => this.loading.set(false)),
              catchError(() => {
                this.displayErrorMessage.set(true);
                return of(null);
              }),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((entity) => {
              this.dataToDisplay.set(get(entity, ['name'], ''));
            });
        }
      },
      { allowSignalWrites: true }
    );
  }

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
