import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { noop, Subject, Subscription } from 'rxjs';

/* eslint-disable no-underscore-dangle */
@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractFormControl implements ControlValueAccessor, OnInit, OnDestroy {
  form!: UntypedFormGroup;
  subscriptions: Subscription = new Subscription();
  focused = false;
  touched = false;
  stateChanges: Subject<void> = new Subject<void>();
  protected formBuilder: FormBuilder = inject(FormBuilder);
  protected elementRef: ElementRef = inject(ElementRef);
  protected renderer: Renderer2 = inject(Renderer2);
  protected rootFormGroup: FormGroupDirective = inject(FormGroupDirective);

  constructor() {
    this.initForm();
  }

  get control() {
    return this.rootFormGroup.control?.get(this.elementRef.nativeElement.getAttribute('formControlName'));
  }

  private _placeholder!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.stateChanges.next();
  }

  @Input() get value() {
    return this.getValue();
  }

  set value(v: any) {
    this.setValue(v);
    this.stateChanges.next();
  }

  abstract getValue(): any;

  abstract setValue(value: any): void;

  onChange = (_: any) => noop();

  onTouched = () => noop();

  abstract registerOnChange(fn: any): void;

  abstract initForm(): void;

  ngOnInit() {
    this.subscriptions.add(
      this.rootFormGroup.ngSubmit.subscribe(() => {
        this.form.markAllAsTouched();
      })
    );
    this.subscriptions.add(
      this.rootFormGroup.valueChanges?.subscribe((changes) => {
        if (changes && !changes[this.elementRef.nativeElement.getAttribute('formControlName')]) {
          this.form.reset(null, { emitEvent: false });
        }
      })
    );
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.stateChanges.complete();
  }
}
