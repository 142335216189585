import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatProgressSpinner, MatTooltip],
  selector: 'iot-platform-ui-detail-popup-display-property',
  templateUrl: './detail-popup-display-property.component.html',
  styleUrls: ['./detail-popup-display-property.component.scss']
})
export class DetailPopupDisplayPropertyComponent {
  property = input<string>();
  value = input<string | number>();
  margin = input<string>();
  loading = input<boolean>(false);
}
