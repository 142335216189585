<div class="full-height-with-overflow" fxLayout="column" fxLayoutGap="10px">
  <div class="business-profile-form" fxLayoutAlign="space-between center">
    <span>
      {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.TIMEZONE' | translate }}:&nbsp;
      <span class="fw-600">{{ getTimezoneName() }} </span>
    </span>
    <span fxLayout="row" fxLayoutAlign="start center">
      {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.STOCK_VISIBILITY' | translate }}:&nbsp;
      @if (businessProfile.siteStocksVisible !== undefined) {
        <span class="fw-600">{{ 'IOT_DICTIONARY.' + businessProfile.siteStocksVisible | translate }} </span>
      }
    </span>
    <span>
      {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_DEFAULT_PERIOD' | translate }}:&nbsp;
      <span class="fw-600">
        {{ businessProfile.chartPeriod }}
        {{ (businessProfile?.chartPeriod > 1 ? 'ADMIN.BUSINESS_PROFILES.DETAIL.DAYS' : 'ADMIN.BUSINESS_PROFILES.DETAIL.DAY') | translate }}
      </span>
    </span>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex fxLayout="column">
      <mat-card-title>
        <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
          <h2 class="panel-title" fxFlex fxLayout="row" fxLayoutGap="10px">
            <span> {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.MEMBERS' | translate | uppercase }} ({{ users?.length }}) </span>
            @if (membersPendingStatus) {
              <mat-progress-spinner [diameter]="14" mode="indeterminate" color="accent"></mat-progress-spinner>
            }
          </h2>
          @if (canUpdateBusinessProfile) {
            <button
              [matTooltip]="'ADMIN.BUSINESS_PROFILES.DETAIL.MANAGE_MEMBERS' | translate"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
              mat-icon-button
              class="regular-round-button"
              (click)="onManageProfileMembers()"
            >
              <mat-icon>edit</mat-icon>
            </button>
          }
        </mat-toolbar>
      </mat-card-title>
      <mat-card-content fxLayout="column">
        @if (businessProfile) {
          <iot4bos-ui-backoffice-users-list [users]="users" class="member-list" style="max-height: 200px; overflow-y: auto"></iot4bos-ui-backoffice-users-list>
        }
      </mat-card-content>
    </div>
    <div fxFlex fxLayout="column">
      <mat-card-title>
        <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
          <h2 class="panel-title" fxFlex fxLayout="row" fxLayoutGap="10px">
            <span> {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.ROLES' | translate | uppercase }} ({{ roles?.length }}) </span>
            @if (rolesPendingStatus) {
              <mat-progress-spinner [diameter]="14" mode="indeterminate" color="accent"></mat-progress-spinner>
            }
          </h2>
          @if (canUpdateBusinessProfile) {
            <button
              [matTooltip]="'ADMIN.BUSINESS_PROFILES.DETAIL.MANAGE_ROLES' | translate"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
              mat-icon-button
              class="regular-round-button"
              (click)="onManageProfileRoles()"
            >
              <mat-icon>edit</mat-icon>
            </button>
          }
        </mat-toolbar>
      </mat-card-title>
      <mat-card-content fxLayout="column">
        @if (businessProfile) {
          <iot4bos-ui-backoffice-roles-list [roles]="roles" class="member-list" style="max-height: 200px; overflow: auto"></iot4bos-ui-backoffice-roles-list>
        }
      </mat-card-content>
    </div>
  </div>
</div>
