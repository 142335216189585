import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  CreatedGroupColumn,
  DescriptionColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BSelectionColumn,
  I4BSitesButtonColumn,
  I4bTotalAssetsColumn,
  IdColumn,
  LatLongGroupColumn,
  NameColumn,
  SiteAddressColumn,
  SiteNameColumn,
  SiteTypeColumn,
  TotalColumn,
  TotalContactsColumn,
  TotalDevicesColumn,
  TotalEventsColumn,
  TotalTagsColumn,
  UpdatedGroupColumn
} from './definitions';
import { I4bCellConcept } from './enums';
import { HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions, SitesGridData } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new SiteNameColumn({}, {}, {}),
  new NameColumn(
    { displayName: 'SITE NAME 2', type: HeaderType.BASIC },
    {
      id: 'name2',
      sortProperty: 'name2',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
      concept: I4bCellConcept.SITES,
      isDefault: true,
      isConfigurable: true,
      isLink: true,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: {
          dispatchEvent: '',
          type: 'navigate',
          options: {
            targetType: 'site',
            targetId: 'id',
            siteId: 'id'
          }
        }
      }
    },
    { order: 1 }
  ),
  new SiteTypeColumn({}, {}, { order: 1 }),
  new DescriptionColumn({}, { concept: I4bCellConcept.SITES, isDefault: false }, { order: 2 }),
  new I4BBasicColumn({ displayName: 'ENTITY NAME' }, { concept: I4bCellConcept.SITES, id: 'entity.name', isDefault: true }, { order: 2 }),
  new IdColumn(
    { displayName: 'BUSINESS ID' },
    {
      id: 'businessId',
      concept: I4bCellConcept.SITES,
      width: 150
    },
    { order: 3 }
  ),
  new SiteAddressColumn({}, { id: 'address', concept: I4bCellConcept.SITES }, {}),
  new I4BBasicColumn({ displayName: 'ADDRESS1' }, { id: 'address.address1', width: 130, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'ADDRESS2' }, { id: 'address.address2', width: 130, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'ZIP CODE' }, { id: 'address.zipCode', width: 120, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'STATE' }, { id: 'address.state', width: 120, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'CITY' }, { id: 'address.city', width: 120, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn(
    { displayName: 'COUNTRY' },
    {
      id: 'address.country',
      width: 120,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.COUNTRIES.' } }
    },
    {}
  ),
  new TotalContactsColumn({}, { concept: I4bCellConcept.SITES, isDefault: true }, { order: 4 }),
  new I4bTotalAssetsColumn({}, { concept: I4bCellConcept.SITES, isDefault: true }, { order: 5 }),
  new TotalTagsColumn({}, { concept: I4bCellConcept.SITES, isDefault: true }, { order: 6 }),
  new TotalDevicesColumn({}, { concept: I4bCellConcept.SITES, isDefault: true }, { order: 7 }),
  new TotalEventsColumn(
    { icon: 'asset-event', displayName: 'TOTAL ASSET EVENTS' },
    { concept: I4bCellConcept.SITES, isDefault: true, id: 'totalAssetActiveEvents' },
    { order: 8, customId: 'totalAssetActiveEvents' }
  ),
  new TotalEventsColumn(
    { icon: 'device-event', displayName: 'TOTAL DEVICE EVENTS' },
    { concept: I4bCellConcept.SITES, isDefault: false, id: 'totalDeviceActiveEvents' },
    { order: 9, customId: 'totalDeviceActiveEvents' }
  ),
  new TotalColumn(
    {
      displayName: 'COMMENTS',
      type: HeaderType.EMPTY
    },
    {
      id: 'totalComments',
      isDefault: true,
      isConfigurable: false,
      cell: { type: I4BCellType.COMMENT },
      concept: I4bCellConcept.SITES
    },
    {
      order: 11
    }
  )
];

export const DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW = [
  new IdColumn(
    { displayName: 'SITE TECHNICAL ID' },
    {
      id: 'id',
      concept: I4bCellConcept.SITES,
      isDefault: false,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: {
          dispatchEvent: '',
          type: 'navigate',
          options: {
            targetType: 'site',
            targetId: 'id',
            siteId: 'id'
          }
        }
      }
    },
    { order: 50 }
  ),
  new CreatedGroupColumn({ displayName: 'CREATED FULL' }, { id: 'createdInfo', concept: I4bCellConcept.SITES }, { order: 51 }),
  new UpdatedGroupColumn({ displayName: 'UPDATED FULL' }, { id: 'updatedInfo', concept: I4bCellConcept.SITES }, { order: 52 })
];

export const DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW = [
  new I4BBasicColumn(
    { displayName: 'ADDRESS ACCURACY' },
    {
      id: 'geolocation.matchScore',
      cell: { type: I4BCellType.ADDRESS_ACCURACY },
      concept: I4bCellConcept.GEOLOCATION,
      isDefault: false,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ADDRESS_ACCURACY',
      width: 170
    },
    { order: 53 }
  ),
  new I4BBasicColumn(
    { displayName: 'SITE TIMEZONE' },
    { id: 'geolocation.timezoneName', width: 150, concept: I4bCellConcept.GEOLOCATION, isDefault: false },
    { order: 54 }
  ),
  new LatLongGroupColumn({ displayName: 'GEOLOCATION FULL' }, {}, { order: 55 })
];

export class DefaultSitesGrid implements I4BGrid<I4BGridOptions, SitesGridData> {
  masterview = 'sites';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId: string = null;
  data: SitesGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: true,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new I4BSitesButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 0 }]
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = getDefaultColumnsByConcept(this.masterview);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
