import { computed, DestroyRef, inject, Injectable, Injector, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AbstractMasterViewDashboardService, FeatureMasterViewEvent } from '@iot-platform/feature/master-view';
import { IotToolbarDispatchActionType } from '@iot-platform/iot-platform-ui';
import { IotToolbarEvent } from '@iot-platform/models/common';
import { Dashboard } from '@iot-platform/models/dashboards';
import { catchError, finalize, of, tap } from 'rxjs';
import { AbstractI4bDashboardsService } from './abstract-i4b-dashboards.service';

@Injectable({
  providedIn: 'root'
})
export class I4bMasterViewDashboardService extends AbstractMasterViewDashboardService {
  allDashboards: Signal<Dashboard[]>;
  dashboardsLoading: WritableSignal<boolean> = signal(false);
  currentDashboard: WritableSignal<Dashboard | undefined> = signal(undefined);
  dashboardsConfiguration: Signal<{
    sortedDashboards: Dashboard[];
    currentDashboard: Dashboard | undefined;
    isDashboardsLoading: boolean;
  }> = computed(() => ({ sortedDashboards: this.allDashboards(), currentDashboard: this.currentDashboard(), isDashboardsLoading: this.dashboardsLoading() }));
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly injector: Injector = inject(Injector);

  constructor(protected readonly i4BDashboardService: AbstractI4bDashboardsService) {
    super();

    this.allDashboards = toSignal(
      this.i4BDashboardService.getDashboards().pipe(
        tap(() => this.dashboardsLoading.set(true)),
        finalize(() => this.dashboardsLoading.set(false)),
        catchError(() => of([])),
        takeUntilDestroyed(this.destroyRef)
      )
    ) as Signal<Dashboard[]>;
  }

  getDashboardConfiguration(): Signal<{
    sortedDashboards: Dashboard[];
    currentDashboard: Dashboard | undefined;
    isDashboardsLoading: boolean;
  }> {
    return this.dashboardsConfiguration;
  }

  onToolbarEvent(_event: FeatureMasterViewEvent) {
    const event: IotToolbarEvent = _event.event as IotToolbarEvent;
    switch (event.type) {
      case IotToolbarDispatchActionType.APPLY_DASHBOARD:
        this.onApplyDashboard(event.options.dashboard);
        break;
    }
  }

  onApplyDashboard(dashboard: Dashboard) {
    this.currentDashboard.set(dashboard);
  }
}
