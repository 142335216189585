<form [formGroup]="parametersForm" class="asset-variable-formula-result three-lines" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="25px">
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.MIN_READ' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaMinMaxAdvancedMinRead" matInput min="0.01" type="number" />
    </mat-form-field>

    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.MIN_DISPLAY' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaMinMaxAdvancedMinDisplay" matInput min="0.01" type="number" />
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="25px">
    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.MAX_READ' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaMinMaxAdvancedMaxRead" matInput min="0.01" type="number" />
    </mat-form-field>

    <mat-form-field color="accent" fxFlex>
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.MAX_DISPLAY' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaMinMaxAdvancedMaxDisplay" matInput min="0.01" type="number" />
    </mat-form-field>
  </div>
</form>
