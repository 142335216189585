import { PlatformResponse } from '@iot-platform/models/common';
import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEventsByAssetSuccess = createAction(
  '[AssetEventsByAsset] Load Asset Events By Asset Success',
  props<{ response: PlatformResponse; assetId: string }>()
);
export const loadAssetEventsByAssetFailure = createAction('[AssetEventsByAsset] Load Asset Events By Asset Failure', props<{ error: any }>());
//
export const loadTotalActiveAssetEventsByAssetSuccess = createAction(
  '[AssetEventsByAsset] Load Total Active Asset Events By Asset Success',
  props<{ totalActiveEvents: number }>()
);
export const loadTotalActiveAssetEventsByAssetFailure = createAction(
  '[AssetEventsByAsset] Load Total Active Asset Events By Asset Failure',
  props<{ error: any }>()
);
//
export const saveTableByAssetStateSuccess = createAction(
  '[AssetEventsByAsset] Save Table State Success',
  props<{ selectedId: string; checkedIds: string[] }>()
);
export const saveTableByAssetStateFailure = createAction('[AssetEventsByAsset] Save Table State Failure', props<{ error: any }>());

export const updateStatusByAssetEventIdByAssetSuccess = createAction(
  '[AssetEventsByAsset] Update Status By AssetEvent Id Success',
  props<{ assetEvent: AssetEvent }>()
);
export const updateStatusByAssetEventIdByAssetFailure = createAction('[AssetEventsByAsset] Update Status By Asset Event Id Failure', props<{ error: any }>());
//
export const bulkUpdateStatusByAssetEventIdByAssetSuccess = createAction(
  '[AssetEventsByAsset] Bulk Update Status By AssetEvent Id Success',
  props<{ assetEvents: AssetEvent[] }>()
);
export const bulkUpdateStatusByAssetEventIdFailure = createAction('[AssetEventsByAsset] Bulk Update Status By Asset Event Id Failure', props<{ error: any }>());
//
export const loadMvAssetEventsByAssetSettingsSuccess = createAction(
  '[AssetEventsByAsset] Load MV AssetEventsByAsset Settings Success',
  props<{ settings: any }>()
);
export const loadMvAssetEventsByAssetSettingsFailure = createAction(
  '[AssetEventsByAsset] Load MV AssetEventsByAsset Settings Failure',
  props<{ error: any }>()
);
