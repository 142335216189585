<div class="organization-detail">
  @if (!organization) {
    <span class="regular-background no-profile" fxFlexFill fxLayoutAlign="center center">{{
      'ADMIN.ORGANIZATIONS.DETAIL.SELECT_AN_ORGANIZATION' | translate | uppercase
    }}</span>
  } @else {
    <div class="regular-background full-height p-20" fxFlexFill fxLayout="column">
      <mat-toolbar color="accent" fxLayoutAlign="space-between center" style="flex-shrink: 0">
        <h2 fxFlex fxLayout="row" fxLayoutAlign="start center">
          {{ organization?.name }}
        </h2>
        @if (canUpdateOrganization && (organization?.isLocked || isTopLevelAdmin)) {
          <button
            (click)="onLockUnlockOrganization(!organization?.isLocked)"
            [disabled]="!isTopLevelAdmin"
            [matTooltip]="
              (organization?.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate
            "
            class="regular-round-button organization-detail__lock-btn"
            mat-icon-button
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
          >
            <mat-icon>{{ organization?.isLocked ? 'lock_open_outline' : 'lock_outline' }}</mat-icon>
          </button>
        }
        @if (!organization?.isLocked || isTopLevelAdmin) {
          @if (canUpdateOrganization) {
            <button
              (click)="editOrganizationName(organization)"
              [disabled]="organization?.isLocked"
              [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.EDIT_ORGANIZATION_NAME' | translate"
              class="regular-round-button organization-detail__edit-btn"
              mat-icon-button
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
            >
              <mat-icon>edit</mat-icon>
            </button>
          }
          @if (canDeleteOrganization) {
            <button
              (click)="onDeleteOrganizationButtonClick(organization)"
              [disabled]="organization?.isLocked"
              [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.DELETE_ORGANIZATION' | translate"
              class="regular-round-button organization-detail__delete-btn"
              mat-icon-button
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
            >
              <mat-icon>delete</mat-icon>
            </button>
          }
        }
      </mat-toolbar>
      <mat-tab-group color="accent" fxFlex mat-align-tabs="left" mat-stretch-tabs>
        @if (canReadBusinessProfile) {
          <mat-tab>
            <ng-template mat-tab-label>
              {{ 'ADMIN.ORGANIZATIONS.DETAIL.BUSINESS_PROFILES' | translate | uppercase }}
              ({{ businessProfiles?.length || 0 }})
              @if (businessProfilesPendingStatus) {
                <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" style="margin-left: 8px"></mat-progress-spinner>
              }
              @if (!businessProfilesPendingStatus) {
                <span style="width: 20px"></span>
              }
            </ng-template>
            <mat-toolbar color="primary" fxLayoutAlign="space-between center">
              <h2>{{ businessProfiles?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.BUSINESS_PROFILES' | translate | uppercase }}</h2>
              @if (canCreateBusinessProfile) {
                <button
                  (click)="addBusinessProfile.emit(organization)"
                  [matTooltipClass]="'regular-tooltip'"
                  [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_BUSINESS_PROFILES' | translate"
                  class="regular-round-button"
                  mat-icon-button
                  matTooltipPosition="below"
                >
                  <mat-icon>add</mat-icon>
                </button>
              }
            </mat-toolbar>
            <iot4bos-ui-backoffice-business-profiles-list
              (deleteBusinessProfile)="onDeleteBusinessProfile($event)"
              (selectBusinessProfile)="onSelectBusinessProfile($event)"
              [canDeleteBusinessProfile]="canDeleteBusinessProfile"
              [canUpdateBusinessProfile]="canUpdateBusinessProfile"
              [profiles]="businessProfiles"
            >
            </iot4bos-ui-backoffice-business-profiles-list>
          </mat-tab>
        }
        @if (canReadBusinessProfile) {
          <mat-tab>
            <ng-template mat-tab-label>
              {{ 'ADMIN.ORGANIZATIONS.DETAIL.ROLES' | translate | uppercase }} ({{ roles?.length || 0 }})
              @if (rolesPendingStatus) {
                <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" style="margin-left: 8px"></mat-progress-spinner>
              }
              @if (!rolesPendingStatus) {
                <span style="width: 20px"></span>
              }
            </ng-template>
            <div fxFlex fxLayout="row">
              <div fxFlex fxLayout="column">
                <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                  <h2>{{ roles?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.ROLES' | translate | uppercase }}</h2>
                  @if (isCurrentUserAdmin) {
                    <button
                      (click)="addRole.emit(organization)"
                      [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ROLES' | translate"
                      class="regular-round-button"
                      mat-icon-button
                      matTooltipClass="regular-tooltip"
                      matTooltipPosition="below"
                    >
                      <!--<iot-platform-ui-icon name="add_role" color="#394C5A"></iot-platform-ui-icon>-->
                      <mat-icon color="#394C5A" svgIcon="add_role"></mat-icon>
                    </button>
                  }
                </mat-toolbar>
                <div class="organization-detail-role-list" fxLayout="row" fxLayoutAlign="start start">
                  <iot4bos-ui-backoffice-roles-list
                    (deleteRole)="onDeleteRole($event)"
                    (editRole)="onAuthorizationsChanged($event)"
                    (renameRole)="onRenameRole($event)"
                    (selectRole)="onSelectRole($event)"
                    [roles]="roles"
                    [selectedRole]="selectedRole"
                    [showActionMenu]="isCurrentUserAdmin"
                    fxFlex="40"
                  >
                  </iot4bos-ui-backoffice-roles-list>
                  @if (!selectedRole) {
                    <span class="background no-profile" fxFlex="60">{{ 'ADMIN.ORGANIZATIONS.DETAIL.SELECT_A_ROLE' | translate | uppercase }}</span>
                  } @else {
                    <iot4bos-ui-backoffice-roles-crud
                      [adminConceptsFromEntitySession]="adminConceptsFromEntitySession"
                      [isAdmin]="isCurrentUserAdmin"
                      [readonly]="true"
                      [role]="selectedRole"
                      fxFlex="60"
                    >
                    </iot4bos-ui-backoffice-roles-crud>
                  }
                </div>
              </div>
            </div>
          </mat-tab>
        }
        @if (canReadTags) {
          <mat-tab>
            <ng-template mat-tab-label>
              {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAGS' | translate | uppercase }} ({{ tags?.length }})
              @if (tagsPendingStatus) {
                <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" style="margin-left: 8px"></mat-progress-spinner>
              } @else {
                <span style="width: 20px"></span>
              }
            </ng-template>
            <div class="organization-detail-tags-zone" fxFlex style="width: 100%; height: 100%">
              <div fxFlex fxLayout="column">
                <mat-toolbar class="organization-detail-tags-zone__toolbar" color="primary" fxLayoutAlign="space-between center">
                  <h2>
                    {{ tags?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAG_CATEGORIES' | translate | uppercase }} / {{ getTagsTotal(tags) }}
                    {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAG_LABELS' | translate | uppercase }}
                  </h2>
                </mat-toolbar>
                <div class="organization-detail-tags-zone__content">
                  @for (concept of conceptList$ | async; track concept) {
                    <div class="organization-detail-tags-list">
                      <iot-platform-ui-tag-category-list-by-concept
                        (manageTags)="onManageTags($event)"
                        [canCreate]="false"
                        [canManageTags]="canCreateTags && canUpdateTags && canDeleteTags"
                        [concept]="concept"
                        [labelOnly]="true"
                        [tagCategoriesByConcept]="getTagCategoriesByConcept(concept)"
                      ></iot-platform-ui-tag-category-list-by-concept>
                    </div>
                  }
                </div>
              </div>
            </div>
          </mat-tab>
        }
        @if (isCurrentUserAdmin) {
          <mat-tab>
            <ng-template mat-tab-label>
              <span fxLayoutAlign="start center"
                >{{ 'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS_AND_CONCEPTS' | translate | uppercase }}&nbsp;
                <mat-icon
                  [matTooltipPosition]="'below'"
                  [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS_AND_CONCEPTS_TOOLTIP' | translate"
                  matTooltipClass="regular-tooltip"
                  >warning</mat-icon
                >
              </span>
              @if (administratorsPendingStatus) {
                <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" style="margin-left: 8px"></mat-progress-spinner>
              } @else {
                <span style="width: 20px"></span>
              }
            </ng-template>
            <div fxLayout="row" fxLayoutGap="10px">
              <section fxFlex="50%" fxLayout="column">
                <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                  <h2>{{ administrators?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS' | translate | uppercase }}</h2>
                  <button
                    (click)="addAdministratorToOrganization.emit(true)"
                    [disabled]="organization.isLocked"
                    [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.MANAGE_ADMINISTRATORS' | translate"
                    class="regular-round-button"
                    mat-icon-button
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="below"
                  >
                    <!--<iot-platform-ui-icon name="edit_admin" color="#394C5A"></iot-platform-ui-icon>-->
                    <mat-icon>person_add_outlined</mat-icon>
                  </button>
                </mat-toolbar>
                <iot4bos-ui-backoffice-users-list [users]="administrators"></iot4bos-ui-backoffice-users-list>
              </section>
              <section fxFlex="50%" fxLayout="column">
                <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                  <h2>{{ organization?.adminConcepts?.length || 0 }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.CONCEPTS' | translate | uppercase }}</h2>
                  <button
                    (click)="manageConcepts.emit({ organization: organization, adminConceptsFromEntitySession: adminConceptsFromEntitySession })"
                    [disabled]="organization.isLocked"
                    [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.MANAGE_CONCEPTS' | translate"
                    class="regular-round-button"
                    mat-icon-button
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="below"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-toolbar>
                <iot4bos-ui-backoffice-organization-concepts-selection
                  [adminConceptsFromEntitySession]="adminConceptsFromEntitySession"
                  [organization]="organization"
                  [readonly]="true"
                ></iot4bos-ui-backoffice-organization-concepts-selection>
              </section>
            </div>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  }
</div>
