<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'TAG_CATEGORY_LIST_COMPONENT.EDIT_TAG_LABEL_DIALOG.TITLE' | translate }}</h1>
      <button (click)="matDialogRef.close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="column">
    <form [formGroup]="form" class="full-width p-20">
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'TAG_CATEGORY_LIST_COMPONENT.EDIT_TAG_LABEL_DIALOG.PLACEHOLDER' | translate }}</mat-label>
        <input autofocus formControlName="label" matInput maxlength="20" required />
        <mat-hint align="end">{{ label.value.length || 0 }}/{{ data.tagNameMaxLength }}</mat-hint>
        @if (label.invalid && label.errors?.nameExists) {
          <mat-error>{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_LABEL' | translate }}</mat-error>
        }
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button (click)="save()" [disabled]="form.invalid" class="button-regular" color="accent" mat-raised-button>
      {{ 'TAG_CATEGORY_LIST_COMPONENT.EDIT_TAG_LABEL_DIALOG.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
