import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DynamicDataService } from '@iot-platform/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ExpressionType } from './expression-type.enum';
import { FunctionType } from './function-type.enum';
import { MethodType } from './method-type.enum';
import { OperatorType } from './operator-type.enum';

@Component({
  selector: 'i4b-table-engine-dynamic-data',
  templateUrl: './dynamic-data.component.html',
  styleUrls: ['./dynamic-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicDataComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() clickEvent: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  dataToDisplay$: BehaviorSubject<any> = new BehaviorSubject('-');
  isDataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  cssClasses$: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});

  displayErrorMessage = false;
  errorMessage = 'N/A';
  subscriptions = new Subscription();

  constructor(
    private readonly dynamicDataService: DynamicDataService,
    private readonly translateService: TranslateService
  ) {}

  get isClickAllowed() {
    const allowConditions = get(this.clickEvent, 'allowConditions');
    if (allowConditions) {
      return allowConditions.some((c: { fieldId: string; value: string }) => get(this.rawData, c.fieldId) === c.value);
    }
    return true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.cssClasses$.next({
      visited: this.cellOptions && this.cellOptions.linkStyling && this.isClickAllowed,
      link: this.clickEvent && this.clickEvent.type,
      link_padding: this.cellOptions && this.cellOptions.padding
    });

    if (!this.cellOptions.api) {
      this.processData(this.rawData);
    } else {
      this.isDataLoaded$.next(false);
      this.subscriptions.add(
        this.dynamicDataService
          .getDynamicData(this.cellOptions.api.endpoint, {
            ...this.cellOptions.api,
            rawData: this.rawData
          })
          .pipe(finalize(() => this.isDataLoaded$.next(true)))
          .subscribe({
            next: (data: any) => {
              this.isDataLoaded$.next(true);
              this.processData(data);
            },
            error: () => (this.displayErrorMessage = true)
          })
      );
    }
  }

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.clickEvent && this.clickEvent.type && this.isClickAllowed) {
      this.dispatchEvent.emit({
        type: this.clickEvent.type,
        options: this.clickEvent.options,
        rawData: this.rawData,
        cachedData: this.dynamicDataService.cachedData$.value
      });
    }
  }

  // Default behavior to display data
  private transformDataToBeDisplayed(data: any): any {
    if (this.cellOptions.displayProperties) {
      const dataToDisplayed = this.cellOptions.displayProperties.reduce((acc: string, value: string) => {
        acc = acc.concat(' ', GetUtils.get(data, value, ''));
        return acc;
      }, '');
      return dataToDisplayed.trim();
    }
    return data;
  }

  private processData(data: any): void {
    // In case there's no expressions
    if (!this.cellOptions.expressions) {
      this.handleDefault(data);
    } else {
      // Expressions found
      this.cellOptions.expressions.forEach((expression: any) => {
        switch (expression.type) {
          case ExpressionType.FUNCTION:
            this.handleFunctionExpression(expression, data);
            break;
          case ExpressionType.ALERT:
            this.handleAlertExpression(expression, data);
            break;
          default:
            this.handleDefault(data);
            break;
        }
      });
      // In case there's no functions
      if (!this.cellOptions.expressions.find((exp) => exp.type === ExpressionType.FUNCTION)) {
        this.handleDefault(data);
      }
    }
  }

  private handleDefault(data: any): void {
    this.dataToDisplay$.next(this.transformDataToBeDisplayed(data));
  }

  private isArray(data): boolean {
    return data && data instanceof Array;
  }

  // Apply predicates
  private processPredicates(elem: any, predicates): boolean {
    const result: boolean[] = predicates.map((predicate) => this.processOperator(elem, predicate));
    // Return false if at least we found one falsy item
    return !result.some((b) => !b);
  }

  // Apply join predicates
  private processJoinPredicates(elem: any, predicates, separator = ', '): string {
    return predicates
      .reduce((acc, p) => (this.processOperator(elem, p) ? [...acc, this.translateService.instant(p.normalizedValue)] : [...acc]), [])
      .join(separator);
  }

  private handleOperator(operator: OperatorType, value1, value2): boolean {
    let v1: any = value1;
    let v2: any = value2;
    if (value1 !== null && typeof value1 === 'string') {
      v1 = this.translateService.instant(value1);
    }
    if (value2 !== null && typeof value2 === 'string') {
      v2 = this.translateService.instant(value2);
    }
    if (operator === OperatorType.EQUAL) {
      return v1 === v2;
    } else if (operator === OperatorType.DIFFERENT) {
      return v1 !== v2;
    }
    return false;
  }

  private processOperator(elem: any, predicate: any): boolean {
    const { operator } = predicate;
    const { key, value, field1, field2, notNull } = predicate;
    if (predicate.hasOwnProperty('key') && predicate.hasOwnProperty('value')) {
      const v = GetUtils.get(elem, key, null);
      if ((notNull && v !== null) || !notNull) {
        return this.handleOperator(operator, v, value);
      }
    } else if (predicate.hasOwnProperty('field1') && predicate.hasOwnProperty('field2')) {
      const v1 = GetUtils.get(elem, field1, null);
      const v2 = GetUtils.get(elem, field2, null);
      if ((notNull && v1 !== null && v2 !== null) || !notNull) {
        return this.handleOperator(operator, v1, v2);
      }
    }
    return false;
  }

  // Apply methods
  private processMethode(data: any, predicates, method: MethodType): boolean {
    if (method === MethodType.SOME && this.isArray(data)) {
      return data.some((elem: any) => this.processPredicates(elem, predicates));
    } else if (method === MethodType.MISMATCH) {
      return this.processPredicates(data, predicates);
    }
    return false;
  }

  // handle alerts expressions and set dynamic css classes
  private handleAlertExpression({ predicates, cssClass, method, field }, data: any): void {
    // eslint-disable-next-line no-underscore-dangle
    const _data: any = field ? GetUtils.get(data, field) : data;
    const cssClasses = {
      ...this.cssClasses$.value
    };
    if (!cssClasses[cssClass]) {
      if (method) {
        cssClasses[cssClass] = this.processMethode(_data, predicates, method);
      } else {
        cssClasses[cssClass] = this.processPredicates(_data, predicates);
      }
    }
    this.cssClasses$.next(cssClasses);
  }

  private handleSimpleJoin(data, field): string {
    return data.map((elem) => elem[field]).join(', ');
  }

  // Handle functions expressions, function are applied on api response data
  private handleFunctionExpression(expression, data: any): void {
    const { value, field, predicates, normalizedValue, defaultProperty } = expression;
    // eslint-disable-next-line no-underscore-dangle
    const _data: any = field ? GetUtils.get(data, field, null) : data;
    switch (value) {
      case FunctionType.COUNT:
        this.dataToDisplay$.next(_data !== null ? _data.length : 0);
        break;
      case FunctionType.SIMPLE_JOIN:
        this.dataToDisplay$.next(data !== null ? this.handleSimpleJoin(data, field) : '-');
        break;
      case FunctionType.NORMALIZE:
        const ok = this.processPredicates(_data, predicates);
        const str = this.translateService.instant(normalizedValue);
        this.dataToDisplay$.next(ok ? str : GetUtils.get(_data, defaultProperty, null));
        break;
      case FunctionType.NORMALIZE_JOIN:
        const joinedValues = this.processJoinPredicates(_data, predicates);
        this.dataToDisplay$.next(joinedValues);
        break;
      default:
        break;
    }
  }
}
