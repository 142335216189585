import { createReducer, on } from '@ngrx/store';
import { AdminOrganizationsPageActions, AdminOrganizationsRolesApiActions, AdminOrganizationsRolesPageActions } from '../actions';

export const adminOrganizationsRolesPageFeatureKey = 'adminOrganizationsRolesPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsPageActions.selectOrganization, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminOrganizationsRolesPageActions.listRolesByOrganization, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminOrganizationsRolesApiActions.listRolesByOrganizationSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(AdminOrganizationsRolesApiActions.listRolesByOrganizationFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
