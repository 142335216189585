import { AsyncPipe } from '@angular/common';
import { Component, forwardRef, HostBinding } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PhoneNumberUtils, PhoneNumberValidators, StringUtils } from '@iot-platform/iot-platform-utils';
import { PhoneNumber } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractMatFormControl } from '../../abstract-mat-form-control';

@Component({
  standalone: true,
  imports: [FlexModule, ReactiveFormsModule, MatInputModule, AsyncPipe, TranslateModule, FormsModule],
  selector: 'iot-platform-ui-phone-number-input',
  templateUrl: 'phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true
    }
  ]
})
export class PhoneNumberInputComponent extends AbstractMatFormControl<string> {
  form!: FormGroup<{
    number: FormControl<string | null>;
  }>;
  convertedPhoneNumber$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  @HostBinding('class.iot-platform-ui-phone-number-input-floating')
  get shouldLabelFloat() {
    return super.shouldLabelFloat;
  }

  getControlId() {
    return PhoneNumberInputComponent.name;
  }

  getValue() {
    return this.form.value.number;
  }

  setValue(number: string) {
    this.form.setValue({ number });
  }

  isEmpty() {
    const {
      value: { number }
    } = this.form;

    return !number;
  }

  getValidators(): ValidatorFn[] {
    return [PhoneNumberValidators.isValid()];
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      number: ['']
    });
    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        let result = null;
        let marginBottom = 0;
        if (StringUtils.hasAlphaCharacters(this.value)) {
          const formatted: PhoneNumber = PhoneNumberUtils.parsePhoneNumber(this.value);
          result = formatted.isValid && formatted.originalFormat !== formatted.e164Format ? formatted.e164Format : null;
          if (result) {
            marginBottom = 10;
          }
        }
        this.renderer.setStyle(this.elementRef.nativeElement.parentElement?.parentElement?.parentElement, 'marginBottom', `${marginBottom}px`);
        this.convertedPhoneNumber$.next(result);
      })
    );
  }

  registerOnChange(fn: any): void {
    this.subscriptions.add(this.form.valueChanges.subscribe(() => fn(this.value)));
  }
}
