import { PlatformResponse, ProductCatalog } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listProductsSuccess = createAction('[Product] List Products Success', props<{ response: PlatformResponse }>());
export const listProductsFailure = createAction('[Product] List Products Failure', props<{ error: any }>());
//
export const addProductSuccess = createAction('[Product] Add Product Success', props<{ addedProduct: ProductCatalog }>());
export const addProductFailure = createAction('[Product] Add Product Failure', props<{ error: any }>());
//
export const updateProductSuccess = createAction('[Product] Update Product Success', props<{ updatedProduct: ProductCatalog }>());
export const updateProductFailure = createAction('[Product] Update Product Failure', props<{ error: any }>());
//
export const deleteProductSuccess = createAction('[Product] Delete Product Success', props<{ deletedProduct: ProductCatalog }>());
export const deleteProductFailure = createAction('[Product] Delete Product Failure', props<{ error: any }>());
