import { Injectable } from '@angular/core';
import { AuthBusinessProfilesApiActions } from '@iot-platform/auth';
import { DynamicDataResponse } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IotGeoJsonFeature } from '../../models/iot-geo-json-object.model';
import { MapService } from '../../services/map.service';

import { MapActions } from '../actions';
import { MapFacade } from '../facades/map.facade';

@Injectable()
export class MapEffects {
  loadGeoLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadGeoLocations),
      concatLatestFrom(() => this.mapFacade.filters$),
      switchMap(([request, filters]) =>
        this.mapService.loadGeoLocations({ ...request, filters }).pipe(
          map((response: IotGeoJsonFeature[]) => MapActions.loadGeoLocationsSuccess({ response })),
          catchError((error) => of(MapActions.loadGeoLocationsFailure({ error })))
        )
      )
    )
  );

  loadRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadRoute),
      switchMap(({ assetId, limit, start, end, minutesBetweenSegments }) =>
        this.mapService.loadRoute(assetId, limit, start, end, minutesBetweenSegments).pipe(
          map((response) => MapActions.loadRouteSuccess({ response })),
          catchError((error) => of(MapActions.loadRouteFailure({ error })))
        )
      )
    )
  );

  loadMarkerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadMarkerDetails),
      concatLatestFrom(() => this.mapFacade.concept$),
      switchMap(([{ feature }, concept]) =>
        this.mapService.loadMarkerDetails(concept, feature).pipe(
          map((response: DynamicDataResponse) => MapActions.loadMarkerDetailsSuccess({ response })),
          catchError((error) => of(MapActions.loadMarkerDetailsFailure({ error })))
        )
      )
    )
  );

  clearDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      map(() => MapActions.clearData())
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapActions.loadGeoLocationsFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapActions.loadGeoLocations),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapActions.loadGeoLocationsSuccess, MapActions.loadGeoLocationsFailure),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly mapService: MapService,
    private readonly mapFacade: MapFacade
  ) {}
}
