import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
  selector: 'i4b-table-engine-call-to-action-cell',
  templateUrl: './call-to-action-cell.component.html',
  styleUrls: ['./call-to-action-cell.component.scss']
})
export class CallToActionCellComponent implements OnInit {
  @Input() element: any;
  @Input() actions: any;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;

  @Output() actionClicked: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  actionVisibilityList = [];
  isCallToActionVisible = true;

  ngOnInit() {
    this.actions.forEach((action) => this.actionVisibilityList.push({ action: action.key, isVisible: null }));
  }
}
