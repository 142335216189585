import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const teamPlanningsColumnSettings = [
  {
    order: 10,
    id: 'name',
    name: 'IOT_DICTIONARY.NAME',
    catalogName: 'TEAM PLANNING NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    width: '',
    cellWidth: '250',
    type: 'start center'
  },
  {
    order: 20,
    id: 'entity.name',
    name: 'IOT_DICTIONARY.ENTITY',
    catalogName: 'ENTITY NAME',
    default: true,
    sortable: true,
    sortProperty: 'entity.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 30,
    id: 'businessProfile.name',
    name: 'IOT_DICTIONARY.BUSINESS_PROFILE',
    catalogName: 'BUSINESS PROFILE NAME',
    default: true,
    sortable: true,
    sortProperty: 'businessProfile.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 40,
    id: 'timezoneDetails.name',
    name: 'IOT_DICTIONARY.TIMEZONE',
    catalogName: 'TIMEZONE NAME',
    default: true,
    sortable: true,
    sortProperty: 'timezoneDetails.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 50,
    id: 'rotationMode',
    name: 'IOT_DICTIONARY.ROTATION_MODE',
    catalogName: 'ROTATION MODE',
    default: true,
    sortable: true,
    sortProperty: 'rotationMode',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    cellTypeOptions: {
      capitalize: true
    },
    isLink: false,
    width: '',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 60,
    id: 'createdBy',
    name: 'IOT_DICTIONARY.CREATED_BY',
    catalogName: 'CREATED BY',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.USER_CELL,
    isLink: false,
    clickEvent: '',
    width: '',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 70,
    id: 'createdAt',
    name: 'IOT_DICTIONARY.CREATED_AT',
    catalogName: 'CREATED AT',
    default: true,
    sortable: true,
    sortProperty: 'createdAt',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    width: '',
    cellWidth: '150',
    type: 'start center'
  }
];
