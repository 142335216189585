import { Component, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { MasterViewEngineDirective } from '../../master-view-engine/master-view-table/master-view-engine.directive';
import { MasterViewComponentFactory } from '../../master-view-engine/master-view-table/master-view-factory';
import { MasterViewComponent } from '../../master-view-engine/master-view-table/master-view.component';

@Component({
  selector: 'i4b-table-engine-master-view-header-container',
  templateUrl: './master-view-header-container.component.html',
  styleUrls: ['./master-view-header-container.component.scss']
})
export class MasterViewHeaderContainerComponent implements OnInit, OnChanges {
  @Input() column: any;

  @ViewChild(MasterViewEngineDirective, { static: true }) iotPlatformUiMasterViewEngineDirective: MasterViewEngineDirective;

  constructor(private readonly masterViewComponentFactory: MasterViewComponentFactory) {}

  ngOnInit() {
    this.initViewContainerRef();
    this.addHeaderCell();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('column')) {
      this.initViewContainerRef();
      this.addHeaderCell();
    }
  }

  initViewContainerRef(): ViewContainerRef {
    const viewContainerRef = this.iotPlatformUiMasterViewEngineDirective.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef;
  }

  addHeaderCell() {
    const componentRef: ComponentRef<MasterViewComponent> = this.masterViewComponentFactory.createHeaderComponent(
      this.column.headerType,
      this.initViewContainerRef()
    );
    componentRef.instance.data = this.column;
  }
}
