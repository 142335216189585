import { Component, effect, Signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fromAuth } from '@iot-platform/auth';
import { ApiInterceptorService } from '@iot-platform/core';
import { UserPreferences } from '@iot-platform/models/common';
import { fromUserPreferences, PreferencesActions, UserProfileService } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <iot-platform-notification [isLoggedOut]="selectLoggedOut()"></iot-platform-notification>
  `
})
export class AppComponent {
  subscriptions = new Subscription();
  selectLoggedOut = this.store.selectSignal(fromAuth.selectLoggedOut);
  private preferences: Signal<UserPreferences> = this.store.selectSignal(fromUserPreferences.getPreferences);

  constructor(
    private readonly store: Store,
    private readonly userProfileService: UserProfileService,
    private readonly apiInterceptorService: ApiInterceptorService,
    private readonly iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iot4bos-icon-set.svg'));
    this.apiInterceptorService.setBuildInfo();

    effect(
      () => {
        const preferences = this.preferences();
        if (preferences) {
          this.userProfileService.setThemeOverlay(preferences.appTheme);
          this.store.dispatch(PreferencesActions.changeLanguage({ lang: preferences.appLanguage }));
        }
      },
      { allowSignalWrites: true }
    );
  }
}
