import { Observable } from 'rxjs';
import { TrackingData } from '../models/tracking-data.model';

export abstract class AbstractTrackingSettingsProviderService {
  abstract getCurrentUrl$(): Observable<string>;

  abstract getPreviousUrl$(): Observable<string>;

  abstract getTrackingData$(): Observable<TrackingData>;
}
