<div [matTooltipClass]="'regular-tooltip'" [matTooltip]="tooltip()" matTooltipPosition="above">
  @if (isIcon()) {
    <mat-icon [ngClass]="{ 'not-linked': !value(), 'table-icon-content': true }"
    >{{ value() ? 'link' : params()?.data?.formula?.model === 'constant' ? 'swap_horiz' : 'link_off' }}
    </mat-icon>
  } @else {
    <!-- Used for filter custom filter renderer -->
    <span>{{ value() }}</span>
  }
</div>
