<iot4bos-ui-events-by-concept-grid
  [concept]="'device'"
  [eventType]="'device-events'"
  [grid]="grid()"
  [mandatoryFiltersInput]="mandatoryFilters"
  [masterviewName]="masterviewName"
  [origin]="origin"
  [permissions]="userPermissions"
  [displayFilterEngine]="true"
></iot4bos-ui-events-by-concept-grid>
