import { Injectable } from '@angular/core';
import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';

import { ExportSpreadsheetsUiActions } from '../actions';
import * as fromExportSpreadsheets from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class ExportSpreadsheetsFacade {
  constructor(private readonly store: Store) {}

  public exportSpreadsheetsLoaded$ = this.store.select(fromExportSpreadsheets.selectExportSpreadsheetsLoaded);
  public exportSpreadsheetsLoading$ = this.store.select(fromExportSpreadsheets.selectExportSpreadsheetsLoading);
  public exportSpreadsheets$ = this.store.select(fromExportSpreadsheets.selectExportSpreadsheetsWithExpandedContacts);
  public totalExportSpreadsheets$ = this.store.select(fromExportSpreadsheets.selectTotalExportSpreadsheets);
  public selectedExportSpreadsheet$ = this.store.select(fromExportSpreadsheets.selectSelectedExportSpreadsheet);

  public loadExportSpreadsheets(siteId: string): void {
    this.store.dispatch(ExportSpreadsheetsUiActions.loadExportSpreadsheets({ siteId }));
  }

  public selectExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet): void {
    this.store.dispatch(ExportSpreadsheetsUiActions.selectExportSpreadsheet({ exportSpreadsheetToSelect: exportSpreadsheet }));
  }

  public addExportSpreadsheet(exportSpreadsheetToAdd: ExportSpreadsheet, siteId: string): void {
    this.store.dispatch(ExportSpreadsheetsUiActions.addExportSpreadsheet({ exportSpreadsheetToAdd, siteId }));
  }

  public updateExportSpreadsheet(exportSpreadsheetToUpdate: ExportSpreadsheet, siteId: string): void {
    this.store.dispatch(ExportSpreadsheetsUiActions.updateExportSpreadsheet({ exportSpreadsheetToUpdate, siteId }));
  }

  public deleteExportSpreadsheet(exportSpreadsheetToDelete: ExportSpreadsheet, siteId: string): void {
    this.store.dispatch(ExportSpreadsheetsUiActions.deleteExportSpreadsheet({ exportSpreadsheetToDelete, siteId }));
  }

  public configureContactExportSpreadsheets(exportSpreadsheetsToUpdate: ExportSpreadsheet[], siteId: string): void {
    this.store.dispatch(ExportSpreadsheetsUiActions.configureContactExportSpreadsheets({ exportSpreadsheetsToUpdate, siteId }));
  }
}
