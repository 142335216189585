import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { AssetTemplate } from '@iot-platform/models/i4b';
import * as fromAssetTemplates from '../reducers/asset-templates.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetTemplatesSelectors extends ApiFeatureSelector<fromAssetTemplates.State, AssetTemplate, Pagination, Filter> {
  constructor() {
    super(fromAssetTemplates.assetTemplatesFeature.selectAssetTemplatesState, fromAssetTemplates.adapter);
  }
}
