import { DynamicDataResponse, Filter } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';
import * as Leaflet from 'leaflet';
import { IotGeoJsonFeature, IotGeoJsonRouteFeature } from '../../models/iot-geo-json-object.model';
import { IotMapDisplayMode } from '../../models/iot-map-display-mode.model';

export const loadGeoLocations = createAction('[IOT MAPS] Load Geolocations', props<{ concept: string; displayMode: IotMapDisplayMode; filters: Filter[] }>());
export const loadGeoLocationsSuccess = createAction('[IOT MAPS] Load Geolocations Success', props<{ response: IotGeoJsonFeature[] }>());
export const loadGeoLocationsFailure = createAction('[IOT MAPS] Load Geolocations Failure', props<{ error: unknown }>());

export const loadRoute = createAction(
  '[IOT MAPS] Load Route',
  props<{ assetId: string; limit: number; start: Date; end: Date; minutesBetweenSegments: number; order: string }>()
);
export const loadRouteSuccess = createAction('[IOT MAPS] Load Route Success', props<{ response: IotGeoJsonRouteFeature[] }>());
export const loadRouteFailure = createAction('[IOT MAPS] Load Route Failure', props<{ error: unknown }>());

export const clearData = createAction('[IOT MAPS] Clear Data');
export const clearRoutes = createAction('[IOT MAPS] Clear Routes');
export const refreshMap = createAction('[IOT MAPS] Refresh Map');

export const loadMarkerDetails = createAction('[IOT MAPS] Load Marker Details', props<{ feature: IotGeoJsonFeature }>());
export const loadMarkerDetailsSuccess = createAction('[IOT MAPS] Load Marker Details Success', props<{ response: DynamicDataResponse }>());
export const loadMarkerDetailsFailure = createAction('[IOT MAPS] Load Marker Details Failure', props<{ error: unknown }>());

export const setFilters = createAction('[IOT MAPS] Set Filters', props<{ filters: Filter[] }>());
export const setConcept = createAction('[IOT MAPS] Set Concept', props<{ concept: string }>());

export const saveMapUiState = createAction(
  '[IOT MAPS] Save Map UI State',
  props<{ mapUiState: { center: Leaflet.LatLng; zoom: number; selectedMarker: Leaflet.Marker; hasLeavedMap: boolean; isReturningFromOutside: boolean } }>()
);
