import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CoreTrackingFacade } from '@iot-platform/core';
import { TrackingEventIds } from '@iot-platform/models/dashboards';

import { NumberFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot-platform-dashboards-dashboards-data-table-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCardModule, MatIconModule, MatToolbarModule, TranslateModule, NumberFormatPipe],
  templateUrl: './dashboards-data-table-dialog.component.html',
  styleUrl: './dashboards-data-table-dialog.component.scss'
})
export class DashboardsDataTableDialogComponent implements OnInit {
  public data: {
    data: { name: string; count: number; color: string | null }[];
    title: string;
    total: number;
    showLegends: boolean;
  } = inject(MAT_DIALOG_DATA);
  protected readonly trackingFacade: CoreTrackingFacade = inject(CoreTrackingFacade);
  private readonly dialogRef = inject(MatDialogRef<DashboardsDataTableDialogComponent>);

  ngOnInit() {
    this.trackingFacade.trackEventById(TrackingEventIds.OPEN_DASHBOARD_DATA_TABLE_DIALOG, { widgetTitle: this.data.title });
  }

  exit() {
    this.dialogRef.close();
  }
}
