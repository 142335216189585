export enum HeaderType {
  BASIC = 'basicHeader',
  ICON_AND_TEXT = 'iconAndText',
  ICON = 'iconHeader',
  GROUP = 'group',
  CALL_TO_ACTION = 'callToAction',
  CUSTOM_HEADER_GROUP = 'customHeaderGroupComponent',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  EMPTY = 'empty'
}

export interface I4BColumnHeader {
  type: HeaderType;
  icon?: string;
  tooltip?: string;
  displayName?: string;
  showHeaderName?: boolean;
}
