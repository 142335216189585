import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const usersColumnSettings = [
  {
    order: 20,
    id: 'firstname',
    name: 'IOT_DICTIONARY.FIRST_NAME',
    catalogName: 'FIRST NAME',
    default: true,
    sortable: true,
    sortProperty: 'firstname',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: false,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 19,
    id: 'lastname',
    name: 'IOT_DICTIONARY.LAST_NAME',
    catalogName: 'LAST NAME',
    default: true,
    sortable: true,
    sortProperty: 'lastname',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: false,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 30,
    id: 'email',
    name: 'IOT_DICTIONARY.EMAIL',
    catalogName: 'EMAIL',
    default: true,
    sortable: true,
    sortProperty: 'email',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    isLink: false,
    width: '26%',
    cellWidth: '180',
    type: 'start center'
  },
  {
    order: 31,
    id: 'secondaryEmail',
    name: 'IOT_DICTIONARY.SECONDARY_EMAIL',
    catalogName: 'SECONDARY EMAIL',
    default: true,
    sortable: true,
    sortProperty: 'secondaryEmail',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 32,
    id: 'phoneNumber',
    name: 'IOT_DICTIONARY.PHONE_NUMBER',
    catalogName: 'PHONE NUMBER',
    default: true,
    sortable: true,
    sortProperty: 'phoneNumber',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '80',
    type: 'start center'
  },
  {
    order: 33,
    id: 'entities',
    name: 'IOT_DICTIONARY.ENTITIES',
    catalogName: 'ENTITIES',
    default: true,
    sortable: false,
    cellTypeOptions: { attributes: ['name'] },
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ARRAY_OF_OBJECTS,
    isLink: false,
    width: '30',
    cellWidth: '120',
    type: 'start center'
  },
  {
    order: 35,
    id: 'isShared',
    name: 'ADMIN.USERS.TABLE.ACCOUNT_TYPE',
    catalogName: 'ACCOUNT TYPE',
    default: true,
    sortable: true,
    sortProperty: 'isShared',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BOOLEAN_CELL,
    cellTypeOptions: {
      mode: 'booleanAsText',
      translateKey: 'ADMIN.USERS.TABLE.TYPE.',
      dataToDisplayIfTrue: 'SHARED_ACCOUNT',
      dataToDisplayIfFalse: 'USER_ACCOUNT'
    },
    isLink: false,
    width: '7%',
    cellWidth: '80',
    type: 'start center'
  },
  {
    order: 40,
    id: 'status',
    name: 'IOT_DICTIONARY.STATUS',
    catalogName: 'STATUS',
    default: true,
    sortable: true,
    sortProperty: 'status',
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      translateKey: 'ADMIN.USERS.TABLE.STATUS_LIST.',
      class: {
        disabled: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' },
        active: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
        unconfirmed: { color: '#262836', 'background-color': 'rgba(255, 158, 0, 0.1)', padding: '9px' },
        pending: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' }
      },
      withStatusPadding: true
    },
    isLink: false,
    clickEvent: '',
    width: '13%',
    cellWidth: '80',
    type: 'center center'
  },
  {
    order: 50,
    id: 'lastActivity',
    name: 'IOT_DICTIONARY.LAST_ACTIVITY',
    catalogName: 'LAST ACTIVITY',
    default: true,
    sortable: true,
    sortProperty: 'lastActivity',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    width: '14%',
    cellWidth: '120',
    type: 'start center'
  },
  {
    order: 70,
    id: 'totalBusinessProfile',
    name: 'IOT_DICTIONARY.BUSINESS_PROFILES',
    catalogName: 'BUSINESS PROFILES',
    default: true,
    sortable: true,
    sortProperty: 'totalBusinessProfile',
    headerType: HeaderType.ICON,
    headerIcon: 'business_profile',
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '3%',
    cellWidth: '50',
    type: 'center center'
  },
  {
    order: 82,
    id: 'preferences.appDateFormats.momentFullFormat',
    name: 'IOT_DICTIONARY.APP_DATE_FORMAT',
    catalogName: 'DATE FORMAT',
    default: false,
    sortable: true,
    sortProperty: 'preferences.appDateFormat',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '120',
    type: 'start center'
  },
  {
    order: 83,
    id: 'preferences.appLanguage',
    name: 'IOT_DICTIONARY.APP_LANGUAGE',
    catalogName: 'LANGUAGE',
    default: false,
    sortable: true,
    sortProperty: 'preferences.appLanguage',
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      translateKey: 'USERS.PROFILE_PREFERENCES.LANGUAGES.'
    },
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '60',
    type: 'start center'
  },
  {
    order: 84,
    id: 'preferences.appNumberFormat',
    name: 'IOT_DICTIONARY.APP_NUMBER_FORMAT',
    catalogName: 'NUMBER FORMAT',
    default: false,
    isDefault: false,
    sortable: true,
    sortProperty: 'preferences.appNumberFormat',
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      translateKey: 'USERS.PROFILE_PREFERENCES.NUMBER_FORMATS.'
    },
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '80',
    type: 'start center'
  }
];
