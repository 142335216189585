<mat-card class="dialog-card" fxLayout="column">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.TITLE_MANAGE_CATALOGS' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="10px">
    <div class="catalog-edition-section">
      @for (catalog of catalogs$ | async; track catalog.id) {
        <iot4bos-ui-backoffice-catalog-editor-form
          (changeValue)="onChangeValue($event)"
          (remove)="onDeleteCatalog($event)"
          (save)="onUpdateCatalog($event)"
          [catalog]="catalog"
          [entityList]="entities"
          [mode]="'UPDATE'"
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="10px"
        >
        </iot4bos-ui-backoffice-catalog-editor-form>
      }
    </div>
    <iot4bos-ui-backoffice-catalog-editor-form
      (changeValue)="onChangeValue($event)"
      (save)="onAddCatalog($event)"
      [catalog]="newCatalog"
      [entityList]="entities"
      [mode]="'ADD'"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
    </iot4bos-ui-backoffice-catalog-editor-form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button (click)="close()" [disabled]="!canClose" class="button-regular" color="accent" mat-raised-button>{{ 'MANAGE_TAGS_FORM.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
