import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { NameColumn } from './i4b-name.column';

export class SiteNameColumn extends NameColumn {
  columnId = '4e2d5694-5b50-11ec-80a5-0023a40210d0-site-name';
  className = SiteNameColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
          ? customHeader?.displayName
          : 'SITE NAME',
        icon: 'site'
      },
      {
        id: 'name',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
        concept: I4bCellConcept.SITES,
        isDefault: true,
        isConfigurable: true,
        width: customOptions?.width ? customOptions.width : 150,
        cell: {
          type: I4BCellType.BASIC_LINK,
          event: {
            dispatchEvent: '',
            type: 'navigate',
            options: {
              targetType: 'site',
              targetId: 'id',
              siteId: 'id'
            }
          }
        }
      },
      customOptions
    );
  }
}
