<div class="end-maintenance-comment-container" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxFlex>
  <div class="end-maintenance-comment-header-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon [svgIcon]="'line_end_circle'" color="accent" fxFlex="26px"></mat-icon>
    <div fxFlex>
      <span class="end-maintenance-comment-header-title fw-600">{{ 'IOT_DICTIONARY.END_OF_MAINTENANCE' | translate }}</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
    <div fxFlex="26px"></div>
    @if (isMaintenanceEnd) {
      @if (log?.user) {
        <span class="end-maintenance-user-name" [matTooltip]="log?.user?.name" matTooltipPosition="above" matTooltipClass="regular-tooltip" fxFlex="80px"
          >{{ 'IOT_DICTIONARY.BY' | translate }} : {{ log?.user?.name }}</span
        >
      } @else {
        <span>
          {{ 'IOT_DICTIONARY.BY' | translate }} :
          {{ 'EVENTS.EVENT_TYPES.system' | translate }}
        </span>
      }
      <span>{{ log?.datetime | dateFormat }}</span>
    } @else {
      <span>{{ 'IOT_DICTIONARY.SCHEDULED_END' | translate }} : {{ log?.datetime | dateFormat }}</span>
    }
  </div>
</div>
