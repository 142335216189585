import { EnvironmentProviders, makeEnvironmentProviders, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { TrackingProviders } from './models/tracking-providers.enum';
import { TrackingSettings } from './models/tracking-settings.model';
import { AbstractTrackingProvider } from './services/abstract-tracking-provider.service';
import { AbstractTrackingSettingsProviderService } from './services/abstract-tracking-settings-provider.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { HotjarAnalyticsService } from './services/hotjar-analytics.service';
import { TrackingSettingsService } from './services/tracking-settings.service';
import { TrackingService } from './services/tracking.service';
import { TRACKING_MAPPERS, TRACKING_PROVIDERS, TRACKING_SETTINGS } from './services/tracking.tokens';
import { CoreTrackingEffects } from './store/tracking.effects';

const ProvidersDictionary: { [provider in TrackingProviders]: Type<AbstractTrackingProvider> } = {
  [TrackingProviders.GoogleAnalytics]: GoogleAnalyticsService,
  [TrackingProviders.HotjarAnalytics]: HotjarAnalyticsService
};

export function provideCoreTrackingProviders(
  trackingSettings: TrackingSettings,
  trackingSettingsProviderService: Type<AbstractTrackingSettingsProviderService>
): EnvironmentProviders {
  const providers = trackingSettings.providers.reduce(
    (acc, curr) =>
      acc
        .concat({ provide: TRACKING_MAPPERS, useClass: curr.mapper, multi: true })
        .concat({ provide: TRACKING_PROVIDERS, useClass: ProvidersDictionary[curr.provider], multi: true }),
    [
      { provide: TRACKING_SETTINGS, useValue: trackingSettings, multi: true },
      { provide: AbstractTrackingSettingsProviderService, useClass: trackingSettingsProviderService },
      TrackingSettingsService,
      TrackingService
    ] as Provider[]
  );
  return makeEnvironmentProviders([...providers, provideEffects(CoreTrackingEffects)]);
}

@NgModule()
export class CoreTrackingModule {
  static withTrackingSettings(
    trackingSettings: TrackingSettings,
    trackingSettingsProviderService: Type<AbstractTrackingSettingsProviderService>
  ): ModuleWithProviders<CoreTrackingModule> {
    return {
      ngModule: CoreTrackingModule,
      providers: [provideCoreTrackingProviders(trackingSettings, trackingSettingsProviderService)]
    };
  }
}
