import { NgModule } from '@angular/core';

import { SharedModule } from '@iot-platform/shared';
import { MvCellComponent } from './mv-cell.component';

@NgModule({
  imports: [SharedModule],
  declarations: [MvCellComponent],
  exports: [MvCellComponent]
})
export class MvCellModule {}
