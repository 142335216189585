import { I4BCellType, I4BStatusColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class I4BStatusColumn implements I4BColumn<I4BBasicColumnHeader, I4BStatusColumnConfiguration, I4BColumnOptions> {
  columnId = '73729338-7644-11ec-803a-acde48001122-status';
  className = I4BStatusColumn;

  dataLibs: DataLibrary[] = [DataLibrary.SITES, DataLibrary.ASSETS, DataLibrary.DEVICES, DataLibrary.TAGS, DataLibrary.VARIABLES];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC, displayName: 'STATUS' });
  configuration: I4BStatusColumnConfiguration = {
    isLink: false,
    position: '',
    sortProperty: '',
    id: 'status.name',
    translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.STATUS',
    concept: I4bCellConcept.ASSETS,
    isDefault: true,
    isConfigurable: true,
    width: 150,
    cell: {
      type: I4BCellType.TRANSLATED_CELL,
      options: {
        withStatusPadding: true,
        class: {
          production: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
          maintenance: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' },
          decommissioned: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' }
        }
      }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BStatusColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader,
        displayName: customHeader.displayName ? customHeader.displayName : this.header.displayName ? this.header.displayName : 'STATUS'
      });
    }

    if (customConfig) {
      this.configuration = {
        ...this.configuration,
        ...customConfig,
        id: this.options.customId ? this.options.customId : customConfig.id ? customConfig.id : this.configuration.id,
        sortProperty: customConfig.sortProperty ? customConfig.sortProperty : this.configuration.sortProperty,
        concept: customConfig.concept ? customConfig.concept : this.configuration.concept,
        width: customOptions?.width ? customOptions.width : customConfig.width ? customConfig.width : 150,
        cell: customConfig.cell ? customConfig.cell : this.configuration.cell
      };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions, customId: customConfig.id ? customConfig.id : 'status.name' };
    }
  }
}
