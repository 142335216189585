import { Role } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listRolesByBusinessProfileSuccess = createAction(
  '[Admin Business Profile/API] List Roles by Business Profile Success',
  props<{ roles: Role[] }>()
);
export const listRolesByBusinessProfileFailure = createAction('[Admin Business Profile/API] List Roles by Business Profile Failure', props<{ error: any }>());

export const addRoleToBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Roles API] Add Role To Business Profile Success',
  props<{ addedRole: Role }>()
);

export const addRoleToBusinessProfileFailure = createAction('[Admin Business Profile/Roles API] Add Role To Business Profile Failure', props<{ error: any }>());

export const removeRoleFromBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Roles API] Remove Role From Business Profile Success',
  props<{ removedRole: Role }>()
);
export const removeRoleFromBusinessProfileFailure = createAction(
  '[Admin Business Profile/Roles API] Remove Role To Business Profile Failure',
  props<{ error: any }>()
);
