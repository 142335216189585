import { Injectable } from '@angular/core';
import { AuthApiActions, fromAuth } from '@iot-platform/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { OrganizationsService } from '../../services/organizations.service';
import { AdminOrganizationsAdministratorsApiActions, AdminOrganizationsAdministratorsPageActions } from '../actions';

@Injectable()
export class AdminOrganizationsAdministratorsEffects {
  addAdministrator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsAdministratorsPageActions.addAdministratorToOrganization),
      mergeMap((action) =>
        this.organizationsService.addAdministratorToOrganization(action.organizationId, action.administratorToAdd).pipe(
          map((addedAdministrator) => AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationSuccess({ addedAdministrator })),
          catchError((error) => of(AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationFailure({ error })))
        )
      )
    )
  );

  removeAdministrator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsAdministratorsPageActions.removeAdministratorFromOrganization),
      mergeMap((action) =>
        this.organizationsService.removeAdministratorFromOrganization(action.organizationId, action.administratorToRemove).pipe(
          map((removedAdministratorId) => AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationSuccess({ removedAdministratorId })),
          catchError((error) => of(AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationFailure({ error })))
        )
      )
    )
  );

  checkIfUserIsAdminAfterLoadSelectedEntityForSessionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationSuccess,
        AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationSuccess
      ),
      concatLatestFrom(() => [this.store.select(fromAuth.selectSelectedEntityForSession), this.store.select(fromAuth.selectUserId)]),
      exhaustMap(([_, selectedEntityForSession, currentUserId]) =>
        of(
          AuthApiActions.checkIfUserIsAdmin({
            entityId: selectedEntityForSession?.id,
            userId: currentUserId
          })
        )
      )
    )
  );

  constructor(
    private readonly store: Store,
    private actions$: Actions,
    private organizationsService: OrganizationsService
  ) {}
}
