<div class="passive" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon [svgIcon]="icon()" color="accent"></mat-icon>
  <div fxLayout="column">
    @if (entityName()) {
      <span class="secondary" fxLayoutAlign="start center">
        {{ entityName() }}
      </span>
    }
    @if (name()) {
      <span class="main" fxLayoutAlign="start center">{{ name() }}</span>
    }
  </div>
</div>
