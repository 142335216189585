import { createSelector } from '@ngrx/store';
import { authFeatures } from '../reducers';

export const { selectAuthPage: selectAuthPageState } = authFeatures;

export const selectAuthPagePending = createSelector(selectAuthPageState, (state) => state.pending);
export const selectAuthPage = createSelector(selectAuthPagePending, (pending) => pending);
export const selectAuthPageStepEmail = createSelector(selectAuthPageState, (state) => state.stepEmail);
export const selectAuthPageStepPassword = createSelector(selectAuthPageState, (state) => state.stepPassword);
export const selectAuthPageRequireNewPassword = createSelector(selectAuthPageState, (state) => state.requireNewPassword);
export const selectDisplayEmailPasswordLogin = createSelector(selectAuthPageState, (state) => state.displayEmailPasswordLogin);
export const selectAuthPageError = createSelector(selectAuthPageState, (state) => state.error);
export const selectAuthPageErrorSignIn = createSelector(selectAuthPageState, (state) => state.errorSignIn);
export const selectAuthPageErrorEmail = createSelector(selectAuthPageState, (state) => state.errorEmail);
export const selectAuthPageErrorPassword = createSelector(selectAuthPageState, (state) => state.errorPassword);
export const selectAccountLoading = createSelector(selectAuthPageState, (state) => state.accountLoading);
export const selectSignOutPending = createSelector(selectAuthPageState, (state) => state.signOutPending);
