import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Device } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderComponent } from '../../card-loader/card-loader.component';
import { DetailDevicesCardComponent } from '../../detail-popup-cards/detail-devices-card/detail-devices-card.component';
import { DetailPopupSectionHeaderComponent } from '../../detail-popup/detail-popup-section-header/detail-popup-section-header.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, DetailPopupSectionHeaderComponent, DetailDevicesCardComponent, CardLoaderComponent, NgClass],
  selector: 'iot-platform-ui-site-detail-devices',
  templateUrl: './site-detail-devices.component.html',
  styleUrls: ['./site-detail-devices.component.scss']
})
export class SiteDetailDevicesComponent {
  devices = input<Device[]>([]);
  totalDevices = input<number>(0);
  loaded = input<boolean>(false, { alias: 'isDeviceLoaded' });

  selectDevice = output<Device>();
}
