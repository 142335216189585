import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { OCMDayOff } from '@iot-platform/models/ocm';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CalendarsDbActions, CalendarsUiActions } from '../actions';

export const calendarDaysOffDbFeatureKey = 'calendarDaysOffDb';

export interface State extends EntityState<OCMDayOff> {
  selectedDayOffId: string;
  error: any;
}

export const adapter: EntityAdapter<OCMDayOff> = createEntityAdapter<OCMDayOff>({
  selectId: (dayOff: OCMDayOff) => dayOff.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedDayOffId: null,
  error: null
});

export const calendarDaysOffDbReducer = createReducer(
  initialState,
  on(CalendarsUiActions.listDaysOffByCalendarId, (state) => ({ ...state, selectedDayOffId: null })),
  on(CalendarsDbActions.listDaysOffByCalendarIdSuccess, (state, { daysOff }) => adapter.setAll(daysOff, state)),
  on(
    CalendarsDbActions.listDaysOffByCalendarIdFailure,
    CalendarsDbActions.addDayOffFailure,
    CalendarsDbActions.updateDayOffFailure,
    CalendarsDbActions.deleteDayOffFailure,
    CalendarsDbActions.importDaysOffByCalendarIdFailure,
    (state, { error }) => ({ ...state, error })
  ),
  //

  on(CalendarsDbActions.addDayOffSuccess, (state, { addedDayOff }) => adapter.addOne(addedDayOff, { ...state, selectedDayOffId: addedDayOff.id })),

  on(CalendarsDbActions.updateDayOffSuccess, (state, { updatedDayOff }) => adapter.updateOne({ id: updatedDayOff.id, changes: updatedDayOff }, state)),

  on(CalendarsDbActions.deleteDayOffSuccess, (state, { deletedDayOff }) => adapter.removeOne(deletedDayOff.id, { ...state, selectedDayOffId: null })),

  on(CalendarsDbActions.importDaysOffByCalendarIdSuccess, (state, { daysOff }) => adapter.addMany(daysOff, { ...state, selectedDayOffId: null })),

  on(AuthBusinessProfilesPageActions.selectBusinessProfile, CalendarsUiActions.navigateToCalendarDetails, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return calendarDaysOffDbReducer(state, action);
}

export const getSelectedDayOffId = (state: State) => state.selectedDayOffId;
