import { Graph } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GraphsDbActions } from '../actions';

export const graphsDbFeatureKey = 'graphsDb';

export interface State extends EntityState<Graph> {
  selectedGraphId: string | null;
  graph: Graph;
  allGraphs?: Graph[];
}

export const adapter: EntityAdapter<Graph> = createEntityAdapter<Graph>({
  selectId: (graph: Graph) => graph.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedGraphId: null,
  graph: null,
  allGraphs: []
});

const graphsDbReducer = createReducer(
  initialState,
  on(GraphsDbActions.loadGraphsBySiteIdSuccess, (state: State, { response }) =>
    adapter.setAll(response, {
      ...state,
      allGraphs: response
    })
  ),
  on(GraphsDbActions.loadGraphByIdSuccess, (state: State, { response }) => ({
    ...state,
    selectedGraphId: response.id,
    graph: response
  })),
  on(GraphsDbActions.addGraphSuccess, (state: State, { response }) =>
    adapter.addOne(response, {
      ...state,
      graph: response
    })
  ),
  on(GraphsDbActions.updateGraphSuccess, (state: State, { response }) =>
    adapter.updateOne({ id: response.id, changes: response }, { ...state, graph: response })
  ),
  on(GraphsDbActions.updateGraphsDisplayOrderSuccess, (state: State, { response }) => adapter.upsertMany(response, state)),
  on(GraphsDbActions.deleteGraphSuccess, (state: State, { response }) => adapter.removeOne(response.id, state))
);

export function reducer(state: State | undefined, action: Action) {
  return graphsDbReducer(state, action);
}

export const getSelectedGraphId = (state: State) => state.selectedGraphId;
export const getGraph = (state: State) => state.graph;
export const getAllGraphs = (state: State) => state.allGraphs;
