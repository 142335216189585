import { ThemePalette } from '@angular/material/core';
import { IotToolbarButtonConfig, IotToolbarMenuButtonOption } from '../../models';
import { IotToolbarButtonType } from './button-type.enum';
import { IotToolbarMenuButtonConfig } from './button.config';

export class IotToolbarMenuButton implements Partial<IotToolbarButtonConfig> {
  key: string;
  icon = '';
  tooltip = '';
  displayButton = true;
  color = 'accent' as ThemePalette;
  type = IotToolbarButtonType.MENU_BUTTON;
  disabled = false;
  order = 1;
  menuOptions: IotToolbarMenuButtonOption[] = [];
  removable = false;

  constructor(buttonConfig: IotToolbarMenuButtonConfig, order: number) {
    Object.keys(buttonConfig).forEach((property: string) => (this[property] = buttonConfig[property] ?? this[property]));
    this.order = order ?? this.order;
  }
}
