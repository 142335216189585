import { Component, Input } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-event-timeline-log',
  templateUrl: './event-timeline-log.component.html',
  styleUrls: ['./event-timeline-log.component.scss']
})
export class EventTimelineLogComponent {
  @Input() log: Log;
}
