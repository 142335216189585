import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Pagination } from '@iot-platform/models/common';
import { debounce, get } from 'lodash';

/* eslint-disable  @angular-eslint/directive-selector */
@Directive({
  selector: '[i4b-table-engine-master-view-table-full-height]',
  standalone: true
})
export class TableFullHeightDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() pagination!: Pagination;
  @Input() sticky = false;
  @Input() data: any[] = [];
  @Input() observerSelectors: string[] = [];

  elementSelectors: Array<Element | null> = [];
  observer = new ResizeObserver(
    debounce(() => {
      this.resize();
    }, 250)
  );
  private readonly defaultSelectors: string[] = ['body', 'iot-platform-ui-filter-engine-container', '.filter-engine-row'];

  constructor(
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone
  ) {}

  ngAfterViewInit(): void {
    const timeout = setTimeout(() => {
      this.elementSelectors = [...this.observerSelectors, ...this.defaultSelectors]
        .reduce((acc: Element[], selector: string) => {
          const elements: Element[] = Array.from(this.document.querySelectorAll(selector));
          return [...acc, ...elements];
        }, [])
        .filter((element: Element | null) => !!element);

      this.ngZone.runOutsideAngular(() => {
        this.elementSelectors.forEach((element: Element | null) => {
          this.observer.observe(element);
        });
      });
      clearTimeout(timeout);
    }, 0);
  }

  ngOnDestroy(): void {
    this.elementSelectors.forEach((element: Element | null) => {
      this.observer.unobserve(element);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'data.currentValue') || get(changes, 'pagination.currentValue')) {
      this.resize();
    }
  }

  resize(): void {
    if (this.sticky) {
      const timeout = setTimeout(() => {
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', '100%');
        const element: HTMLElement = this.elementRef.nativeElement.parentElement.parentElement;
        if (element) {
          let height = element.getBoundingClientRect().height;
          const paginationHeight = element.querySelector('.mat-mdc-paginator')?.getBoundingClientRect()?.height || 20;
          const elementOffsetTop = this.calcTopOffset(element);
          const containerHeight = height + elementOffsetTop + paginationHeight;
          if (containerHeight >= window.innerHeight) {
            height = Math.abs(window.innerHeight - elementOffsetTop - paginationHeight);
          }
          this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${Math.max(Math.round(height) - 10, 150)}px`);
        }
        clearTimeout(timeout);
      }, 0);
    }
  }

  calcTopOffset(element: HTMLElement): number {
    const rect = element.getBoundingClientRect();
    const scrollTop: number = get(window, 'pageYOffset') || get(this.document, 'documentElement.scrollTop') || 0;
    return rect.top + scrollTop;
  }
}
