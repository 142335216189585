<div class="mv-toolbar translation-toolbar translation-toolbar-height">
  <div class="mv-toolbar-row" fxLayout="row" fxLayoutAlign="start stretch">
    <div class="mv-toolbar-col" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span class="mv-toolbar-label"> {{ keyCount }} {{ 'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.TITLE' | translate | uppercase }}</span>
      <span fxFlex></span>
      <button
        (click)="toggleEditMode()"
        [matTooltip]="
          (isEditMode ? 'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_READ_ONLY_MODE' : 'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_EDIT_MODE') | translate
        "
        [ngClass]="{ 'translation-toolbar-readonly-button': !isEditMode, 'translation-toolbar-edit-button': isEditMode }"
        class="regular-round-button"
        mat-icon-button
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon color="accent">{{ isEditMode ? 'edit_off' : 'mode_edit' }}</mat-icon>
      </button>
      <button
        [matMenuTriggerFor]="menu"
        [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_EXPORT' | translate"
        class="regular-round-button translation-toolbar-export-button"
        mat-icon-button
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon color="accent">file_download</mat-icon>
      </button>
      <mat-menu #menu="matMenu" fxLayout="row">
        @for (lang of languages; track lang) {
          <button mat-menu-item (click)="onExport(lang)">{{ lang | uppercase }}</button>
          <mat-divider></mat-divider>
        }
        <button (click)="onExport('all')" mat-menu-item>{{ 'ALL' | translate }}</button>
      </mat-menu>
      @if (isEditMode) {
        <button
          [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_ADD_LANGUAGE' | translate"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button translation-toolbar-add-language-button"
          (click)="onAddLanguage()"
        >
          <mat-icon color="accent">add</mat-icon>
        </button>
        @if (showClearSelectedButton) {
          <button
            [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_CLEAR' | translate"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button translation-toolbar-clear-button"
            (click)="onClear()"
          >
            <mat-icon color="accent">clear</mat-icon>
          </button>
        }
        @if (!!languagesToRemove?.length) {
          <button
            [matMenuTriggerFor]="menuDeleteLanguages"
            [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_DELETE_LANGUAGE' | translate"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button translation-toolbar-delete-language-button"
          >
            <mat-icon color="accent">delete</mat-icon>
          </button>
        }
      }

      <mat-menu #menuDeleteLanguages="matMenu" fxLayout="row">
        @for (lang of languagesToRemove; track lang; let i = $index) {
          <button mat-menu-item (click)="onDeleteLanguage(lang)">{{ lang | uppercase }}</button>
          @if (i < languagesToRemove?.length - 1) {
            <mat-divider></mat-divider>
          }
        }
      </mat-menu>
    </div>
  </div>
</div>
