import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class AssetVariableColumn extends I4BBasicColumn {
  columnId = 'e24f0f50-5ce7-11ec-9577-acde48001122-asset-variable';
  className = AssetVariableColumn;
  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      { displayName: 'VARIABLE', ...customHeader },
      {
        ...customConfig,
        isConfigurable: true,
        translationKey: 'variable',
        cell: { type: I4BCellType.NUMBER },
        isDefault: false,
        width: customOptions?.width ? customOptions.width : 150
      },
      { ...customOptions }
    );
  }
}
