import { computed, Inject, Injectable, Optional, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { EMPTY } from 'rxjs';
import { FILTER_ENGINE_SETTINGS } from '../filter-engine-settings.providers';
import { FilterEngineOptions, FilterEngineSettings } from '../models';
import { AbstractFavoriteFilterEngineService } from './abstract-favorite-filter-engine.service';

@Injectable()
export class FilterEngineSettingsService {
  account: Signal<UserAccount> = toSignal(this.favoriteFilterEngineService?.getAccount$().pipe(takeUntilDestroyed()) ?? EMPTY);
  userPreferences: Signal<UserPreferences> = toSignal(this.favoriteFilterEngineService?.getUserPreferences$().pipe(takeUntilDestroyed()) ?? EMPTY);
  options: Signal<FilterEngineOptions> = computed(() => {
    this.account();
    this.userPreferences();
    return this.filterEngineSettings?.options;
  });

  constructor(
    @Optional() @Inject(FILTER_ENGINE_SETTINGS) private readonly filterEngineSettings: FilterEngineSettings,
    @Optional() private readonly favoriteFilterEngineService: AbstractFavoriteFilterEngineService
  ) {}

  saveUserPreferences(user: UserAccount, preferences: UserPreferences): void {
    return this.favoriteFilterEngineService?.saveUserPreferences(user, preferences);
  }
}
