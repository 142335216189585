@if (data.device) {
  <mat-card-content class="p-20 mve-detail-popup-container" fxLayout="column" fxLayoutGap="20px">
    <iot-platform-ui-device-details-basic-info (selectDevice)="selectDevice.emit($event)" [device]="data.device"></iot-platform-ui-device-details-basic-info>

    <iot-platform-ui-site-detail-tags [loaded]="!tagsLoading()" [tags]="tags()"></iot-platform-ui-site-detail-tags>

    <div fxLayout="row" fxLayoutGap="20px">
      <iot-platform-ui-site-details-full-info
        (selectSite)="selectSite.emit($event)"
        [isSiteLoaded]="!siteLoading()"
        [site]="site()"
        fxFlex="50%"
      ></iot-platform-ui-site-details-full-info>
      <iot-platform-ui-device-details-connectors [device]="data.device" fxFlex="50%"></iot-platform-ui-device-details-connectors>
    </div>
    <iot-platform-ui-detail-popup-metadata-footer [data]="data.device"></iot-platform-ui-detail-popup-metadata-footer>
  </mat-card-content>
}
