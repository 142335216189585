import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, map, of } from 'rxjs';

export class NameValidators {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static asyncUniqueNameValidator(service: any, initialName: string, ...params: any[]): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const name = control?.getRawValue().trim();

      if (name) {
        if (name.toLowerCase() === initialName?.toLowerCase()) {
          return of(null);
        }

        const serviceParams = [name as string, ...params];
        return service?.isNameUnique(...serviceParams).pipe(map((unique: boolean) => (unique ? null : { nameExists: true })));
      }
      return of(null);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static asyncUniqueNameValidatorByEntity(service: any, initialName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const name = control?.getRawValue().trim();

      const entity = control?.parent?.get('entity')?.getRawValue();
      if (name && entity) {
        if (name.toLowerCase() === initialName?.toLowerCase()) {
          return of(null);
        }
        return service?.isNameUnique(name as string, entity.id as string).pipe(map((unique: boolean) => (unique ? null : { nameExists: true })));
      }
      return of(null);
    };
  }

  static syncUniqueValueValidator(values: string[], initialValue: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.getRawValue().trim();

      if (value) {
        if (value.toLowerCase() === initialValue?.toLowerCase()) {
          return of(null);
        }
        return values.some((elem) => elem.trim().toLowerCase() === value.toLowerCase()) ? { nameExists: true } : null;
      }
      return of(null);
    };
  }
}
