import { inject } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { SitesService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, of, map, tap, concatMap } from 'rxjs';
import { SitesCommentsActions } from '../actions';
import { NotificationService } from '@iot-platform/notification';

const loadSiteComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), sitesService = inject(SitesService)) =>
    actions$.pipe(
      ofType(SitesCommentsActions.loadComments),
      concatMap(({ siteId }) =>
        sitesService.loadComments(siteId).pipe(
          map((comments: Log[]) => SitesCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(SitesCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), sitesService = inject(SitesService)) =>
    actions$.pipe(
      ofType(SitesCommentsActions.addComment),
      concatMap(({ siteId, comment }) =>
        sitesService.addComment(siteId, comment).pipe(
          map((addedComment: Log) => SitesCommentsActions.addCommentSuccess({ comment: addedComment })),
          catchError((error) => of(SitesCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), sitesService = inject(SitesService)) =>
    actions$.pipe(
      ofType(SitesCommentsActions.editComment),
      concatMap(({ siteId, comment }) =>
        sitesService.editComment(siteId, comment).pipe(
          map((editedComment: Log) => SitesCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(SitesCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), sitesService = inject(SitesService)) =>
    actions$.pipe(
      ofType(SitesCommentsActions.deleteComment),
      concatMap(({ siteId, commentId }) =>
        sitesService.deleteComment(siteId, commentId).pipe(
          map((deletedCommentId: string) => SitesCommentsActions.deleteCommentSuccess({ commentId: deletedCommentId })),
          catchError((error) => of(SitesCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(SitesCommentsActions.loadComments, SitesCommentsActions.addComment, SitesCommentsActions.editComment, SitesCommentsActions.deleteComment),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        SitesCommentsActions.loadCommentsSuccess,
        SitesCommentsActions.loadCommentsFailure,
        SitesCommentsActions.addCommentSuccess,
        SitesCommentsActions.addCommentFailure,
        SitesCommentsActions.editCommentSuccess,
        SitesCommentsActions.editCommentFailure,
        SitesCommentsActions.deleteCommentSuccess,
        SitesCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(SitesCommentsActions.addCommentSuccess, SitesCommentsActions.editCommentSuccess, SitesCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        SitesCommentsActions.loadCommentsFailure,
        SitesCommentsActions.addCommentFailure,
        SitesCommentsActions.editCommentFailure,
        SitesCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const SitesCommentsEffects = {
  loadSiteComments$,
  addComment$,
  editComment$,
  deleteComment$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
