<div class="app-round-button-mv">
  <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" data-cy="i4b-table-engine-call-to-action-menu-btn" mat-icon-button>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    @for (action of actions; track action) {
      <div>
        <i4b-table-engine-call-to-action-menu-item
          [action]="action"
          [element]="element"
          [userPermissions]="userPermissions"
          (actionClicked)="actionClicked.emit($event)"
        >
        </i4b-table-engine-call-to-action-menu-item>
      </div>
    }
  </mat-menu>
</div>
