import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, NgClass],
  selector: 'iot-platform-ui-card-loader',
  templateUrl: './card-loader.component.html',
  styleUrls: ['./card-loader.component.scss']
})
export class CardLoaderComponent {
  backgroundClass = input<string>('');
}
