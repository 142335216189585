import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { TagCategory } from '@iot-platform/models/common';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatChipsModule],
  selector: 'iot-platform-ui-tag-label-list',
  templateUrl: './tag-label-list.component.html'
})
export class TagLabelListComponent {
  tagCategory = input<TagCategory>();
  labelOnly = input<boolean>();
}
