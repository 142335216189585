import { GoogleAnalyticsTrackingTag, TrackingData, TrackingProviders, TrackingSettings } from '@iot-platform/core';
import { TrackingEventCategories, TrackingEventIds, TrackingEventLabels, TrackingEventNames } from '@iot-platform/models/i4b';
import {
  TrackingEventCategories as DashboardsTrackingEventCategories,
  TrackingEventIds as DashboardsTrackingEventIds,
  TrackingEventNames as DashboardsTrackingEventNames
} from '@iot-platform/models/dashboards';
import { GoogleAnalyticsMapperService } from '../services/google-analytics-mapper.service';

export const trackingSettings: TrackingSettings = {
  tags: {
    /**
     Asset template master view
     */
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_CREATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_CREATE_DIALOG
      })
    },
    [TrackingEventIds.CREATE_ASSET_TEMPLATE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.CREATE_ASSET_TEMPLATE
      })
    },
    [TrackingEventIds.REFRESH_ASSET_TEMPLATE_SHELL_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_ASSET_TEMPLATE_SHELL_DATA
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_DETAILS_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_DETAILS_POPUP
      })
    },
    [TrackingEventIds.NAVIGATE_TO_ASSET_TEMPLATE_OVERVIEW_PAGE_FROM_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.POPUP_ACTIONS,
        label: TrackingEventLabels.NAVIGATE_TO_ASSET_TEMPLATE_OVERVIEW_PAGE
      })
    },
    [TrackingEventIds.NAVIGATE_TO_ASSET_TEMPLATE_OVERVIEW_PAGE_FROM_GRID]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.NAVIGATE_TO_ASSET_TEMPLATE_OVERVIEW_PAGE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_UPDATE_DIALOG_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_UPDATE_DIALOG
      })
    },
    [TrackingEventIds.UPDATE_ASSET_TEMPLATE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.UPDATE_ASSET_TEMPLATE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_DELETE_DIALOG_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_DELETE_DIALOG
      })
    },
    [TrackingEventIds.DELETE_ASSET_TEMPLATE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.DELETE_ASSET_TEMPLATE
      })
    },
    /**
     Asset template overview
     */
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_CREATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_CREATE_DIALOG
      })
    },
    [TrackingEventIds.CREATE_ASSET_TEMPLATE_VARIABLE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.CREATE_ASSET_TEMPLATE_VARIABLE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_CONFIGURE_DIALOG_FROM_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_CONFIGURE_DIALOG
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_CONFIGURE_DIALOG_FROM_GRID]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_CONFIGURE_DIALOG
      })
    },
    [TrackingEventIds.CONFIGURE_ASSET_TEMPLATE_VARIABLE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.CONFIGURE_ASSET_TEMPLATE_VARIABLE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_DELETE_DIALOG_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_DELETE_DIALOG
      })
    },
    [TrackingEventIds.DELETE_ASSET_TEMPLATE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.DELETE_ASSET_TEMPLATE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_UPDATE_DIALOG_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_UPDATE_DIALOG
      })
    },
    [TrackingEventIds.UPDATE_ASSET_TEMPLATE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.UPDATE_ASSET_TEMPLATE
      })
    },
    [TrackingEventIds.OPEN_TEMPLATE_UPDATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.OPEN_TEMPLATE_UPDATE_DIALOG
      })
    },
    [TrackingEventIds.UPDATE_TEMPLATE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.UPDATE_TEMPLATE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_PRODUCTS_CONFIGURE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_PRODUCTS_CONFIGURE_DIALOG
      })
    },
    [TrackingEventIds.CONFIGURE_ASSET_TEMPLATE_PRODUCTS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.CONFIGURE_ASSET_TEMPLATE_PRODUCTS
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_PRODUCTS_DELETE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_PRODUCTS_DELETE_DIALOG
      })
    },
    [TrackingEventIds.DELETE_ASSET_TEMPLATE_PRODUCTS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.DELETE_ASSET_TEMPLATE_PRODUCTS
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_OPTIONAL_PROPERTIES_CONFIGURE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_OPTIONAL_PROPERTIES_CONFIGURE_DIALOG
      })
    },
    [TrackingEventIds.CONFIGURE_ASSET_TEMPLATE_OPTIONAL_PROPERTIES]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.CONFIGURE_ASSET_TEMPLATE_OPTIONAL_PROPERTIES
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_TAGS_CONFIGURE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_TAGS_CONFIGURE_DIALOG
      })
    },
    [TrackingEventIds.CONFIGURE_ASSET_TEMPLATE_TAGS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.CONFIGURE_ASSET_TEMPLATE_TAGS
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_FOLLOWED_VARIABLES_ADD_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_FOLLOWED_VARIABLES_ADD_DIALOG
      })
    },
    [TrackingEventIds.ADD_ASSET_TEMPLATE_FOLLOWED_VARIABLES]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.ADD_ASSET_TEMPLATE_FOLLOWED_VARIABLES
      })
    },
    [TrackingEventIds.REMOVE_ASSET_TEMPLATE_FOLLOWED_VARIABLES]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CONFIGURATION_ACTION,
        label: TrackingEventLabels.REMOVE_ASSET_TEMPLATE_FOLLOWED_VARIABLES
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_UPDATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_UPDATE_DIALOG
      })
    },
    [TrackingEventIds.UPDATE_ASSET_TEMPLATE_VARIABLE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.UPDATE_ASSET_TEMPLATE_VARIABLE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_DELETE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_DELETE_DIALOG
      })
    },
    [TrackingEventIds.DELETE_ASSET_TEMPLATE_VARIABLE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.DELETE_ASSET_TEMPLATE_VARIABLE
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_BULK_DELETE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_BULK_DELETE_DIALOG
      })
    },
    [TrackingEventIds.BULK_DELETE_ASSET_TEMPLATE_VARIABLE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.BULK_DELETE_ASSET_TEMPLATE_VARIABLE
      })
    },
    [TrackingEventIds.ASSET_TEMPLATE_OVERVIEW_TAB_CHANGE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CHANGE,
        label: data.tabLabel as string
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_AUDIT_TRAIL_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_AUDIT_TRAIL_POPUP
      })
    },
    [TrackingEventIds.REFRESH_ASSET_TEMPLATE_OVERVIEW_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_ASSET_TEMPLATE_OVERVIEW_DATA
      })
    },
    [TrackingEventIds.OPEN_ASSET_TEMPLATE_VARIABLE_DETAIL_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.ASSET_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_ASSET_TEMPLATE_VARIABLE_DETAIL_POPUP
      })
    },
    /**
     Users master view
     */
    [TrackingEventIds.OPEN_USER_CREATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_USER_CREATE_DIALOG
      })
    },
    [TrackingEventIds.CREATE_USER]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.CREATE_USER
      })
    },
    [TrackingEventIds.REFRESH_USER_SHELL_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_USER_SHELL_DATA
      })
    },
    [TrackingEventIds.OPEN_USER_FILTER_ENGINE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: data.filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.EXPORT_USER_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.EXPORT_USER_DATA
      })
    },
    [TrackingEventIds.OPEN_USER_DETAILS_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_USER_DETAILS_POPUP
      })
    },
    [TrackingEventIds.NAVIGATE_TO_USER_OVERVIEW_PAGE_FROM_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.POPUP_ACTIONS,
        label: TrackingEventLabels.NAVIGATE_TO_USER_OVERVIEW_PAGE
      })
    },
    [TrackingEventIds.NAVIGATE_TO_USER_OVERVIEW_PAGE_FROM_GRID]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.NAVIGATE_TO_USER_OVERVIEW_PAGE
      })
    },
    [TrackingEventIds.ENABLE_USER]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.ENABLE_USER
      })
    },
    [TrackingEventIds.DISABLE_USER]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.DISABLE_USER
      })
    },
    [TrackingEventIds.RESEND_USER_VALIDATION_LINK]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.RESEND_USER_VALIDATION_LINK
      })
    },
    /**
     Users overview
     */
    [TrackingEventIds.REFRESH_USER_OVERVIEW_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_USER_OVERVIEW_DATA
      })
    },
    [TrackingEventIds.OPEN_USER_AUDIT_TRAIL_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_USER_AUDIT_TRAIL_POPUP
      })
    },
    [TrackingEventIds.OPEN_USER_UPDATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.OPEN_USER_UPDATE_DIALOG
      })
    },
    [TrackingEventIds.UPDATE_USER]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.USER_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.UPDATE_USER
      })
    },
    /**
     Po event rules master view
     */
    [TrackingEventIds.REFRESH_PO_EVENT_RULE_SHELL_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_PO_EVENT_RULE_SHELL_DATA
      })
    },
    [TrackingEventIds.OPEN_PO_EVENT_RULE_FILTER_ENGINE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_PO_EVENT_RULE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.OPEN_PO_EVENT_RULE_DETAILS_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_PO_EVENT_RULE_DETAILS_POPUP
      })
    },
    [TrackingEventIds.OPEN_PO_EVENT_RULE_CREATE_DIALOG]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_PO_EVENT_RULE_CREATE_DIALOG
      })
    },
    [TrackingEventIds.CREATE_PO_EVENT_RULE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.CREATE_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.OPEN_CONFIGURE_PO_EVENT_DIALOG_RULE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.CALL_TO_ACTION,
        label: TrackingEventLabels.OPEN_CONFIGURE_PO_EVENT_DIALOG_RULE
      })
    },
    [TrackingEventIds.CONFIGURE_PO_EVENT_RULE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.CALL_TO_ACTION,
        label: TrackingEventLabels.CONFIGURE_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.OPEN_EDIT_PO_EVENT_DIALOG_RULE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.CALL_TO_ACTION,
        label: TrackingEventLabels.OPEN_EDIT_PO_EVENT_DIALOG_RULE
      })
    },
    [TrackingEventIds.EDIT_PO_EVENT_RULE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.CALL_TO_ACTION,
        label: TrackingEventLabels.EDIT_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.OPEN_DELETE_PO_EVENT_DIALOG_RULE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.CALL_TO_ACTION,
        label: TrackingEventLabels.OPEN_DELETE_PO_EVENT_DIALOG_RULE
      })
    },
    [TrackingEventIds.DELETE_PO_EVENT_RULE_FROM_SHELL]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.CALL_TO_ACTION,
        label: TrackingEventLabels.DELETE_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.NAVIGATE_TO_PO_EVENT_RULE_OVERVIEW_PAGE_FROM_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.POPUP_ACTIONS,
        label: TrackingEventLabels.NAVIGATE_TO_PO_EVENT_RULE_OVERVIEW_PAGE_FROM_POPUP
      })
    },
    [TrackingEventIds.NAVIGATE_TO_PO_EVENT_RULE_OVERVIEW_PAGE_FROM_GRID]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.NAVIGATE_TO_PO_EVENT_RULE_OVERVIEW_PAGE_FROM_GRID
      })
    },
    /**
     Po event rules overview
     */
    [TrackingEventIds.REFRESH_PO_EVENT_RULE_OVERVIEW_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_PO_EVENT_RULE_OVERVIEW_DATA
      })
    },
    [TrackingEventIds.OPEN_PO_EVENT_RULE_AUDIT_TRAIL_POPUP]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_PO_EVENT_RULE_AUDIT_TRAIL_POPUP
      })
    },
    [TrackingEventIds.OPEN_CONFIGURE_PO_EVENT_DIALOG_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.OPEN_CONFIGURE_PO_EVENT_DIALOG_RULE
      })
    },
    [TrackingEventIds.CONFIGURE_PO_EVENT_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.CONFIGURE_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.OPEN_EDIT_PO_EVENT_DIALOG_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.OPEN_EDIT_PO_EVENT_DIALOG_RULE
      })
    },
    [TrackingEventIds.EDIT_PO_EVENT_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.EDIT_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.EDIT_PO_EVENT_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.EDIT_PO_EVENT_RULE
      })
    },
    [TrackingEventIds.OPEN_DELETE_PO_EVENT_DIALOG_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_DELETE_PO_EVENT_DIALOG_RULE
      })
    },
    [TrackingEventIds.DELETE_PO_EVENT_RULE_FROM_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.PO_EVENT_RULE_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.DELETE_PO_EVENT_RULE
      })
    },
    /**
     iot platform dashboards
     */
    [DashboardsTrackingEventIds.OPEN_DASHBOARD_DATA_TABLE_DIALOG]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: DashboardsTrackingEventNames.DASHBOARDS_DATA_TABLE_DIALOG,
        category: DashboardsTrackingEventCategories.OPEN_DATA_TABLE_DIALOG,
        label: data.widgetTitle as string
      })
    }
  },
  providers: [{ provider: TrackingProviders.GoogleAnalytics, mapper: GoogleAnalyticsMapperService }]
};
