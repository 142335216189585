<div (click)="onClick()" [ngClass]="{ 'breadcrumb-item': false, passive: true }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon color="accent" svgIcon="site"></mat-icon>
  <div fxLayout="column">
    @if (site().entity?.name) {
      <span class="secondary" fxLayoutAlign="start center">
        {{ site().entity.name }}
      </span>
    }
    @if (site().name) {
      <span class="main" fxLayoutAlign="start center">{{ site().name }}</span>
    }
  </div>
</div>
