import { PlatformResponse } from '@iot-platform/models/common';
import { Topic } from '@iot-platform/models/ocm';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
//
export const listTopicsSuccess = createAction('[Topics] List Topics Success', props<{ response: PlatformResponse }>());
export const listTopicsFailure = createAction('[Topics] List Topics Failure', props<{ error: any }>());

export const getTopicByIdSuccess = createAction('[Topics] Get Topic Success', props<{ selectedTopic: Topic }>());
export const getTopicByIdFailure = createAction('[Topics] Get Topic Failure', props<{ error: any }>());

export const addTopicSuccess = createAction('[Topics] Add Topic Success', props<{ addedTopic: Topic }>());
export const addTopicFailure = createAction('[Topics] Add Topic failure', props<{ error: any }>());

export const addTopicThenConfigureSuccess = createAction('[Topics] Add Topic Then Configure Success', props<{ addedTopic: Topic }>());
export const addTopicThenConfigureFailure = createAction('[Topics] Add Topic Then Configure Failure', props<{ error: any }>());

export const updateTopicSuccess = createAction('[Topics] Update Topic Success', props<{ updatedTopic: Topic }>());
export const updateTopicFailure = createAction('[Topics] Update Topic Failure', props<{ error: any }>());

export const deleteTopicSuccess = createAction('[Topics] Delete Topic Success', props<{ deletedTopic: Topic }>());
export const deleteTopicFailure = createAction('[Topics] Delete Topic Failure', props<{ error: any }>());

export const addTopicToProtocolSuccess = createAction('[Topics] Add Topic to Protocol Success', props<{ addedToProtocolTopic: Topic }>());
export const addTopicToProtocolFailure = createAction('[Topics] Add Topic to Protocol Failure', props<{ error: any }>());

export const subscribeToTopicSuccess = createAction('[Topics] Subscribe to Topic Success', props<{ updatedTopic: Update<Topic> }>());
export const subscribeToTopicFailure = createAction('[Topics] Subscribe to Topic Failure', props<{ error: any }>());

export const unsubscribeToTopicSuccess = createAction('[Topics] Unsubscribe to Topic Success', props<{ updatedTopic: Update<Topic> }>());
export const unsubscribeToTopicFailure = createAction('[Topics] Unsubscribe to Topic Failure', props<{ error: any }>());
