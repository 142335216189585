import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment, UserDateFormats } from '@iot-platform/models/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private http: HttpClient = inject(HttpClient);
  private overlayContainer: OverlayContainer = inject(OverlayContainer);
  private environment: Environment = inject(ENVIRONMENT);

  public loadProfile(): Observable<any> {
    return this.http.get(`${this.environment.api.url}/account`);
  }

  public saveUserProfile(user): Observable<any> {
    return this.http.put(`${this.environment.api.url}/account`, user);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setThemeOverlay(theme: string): void {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));

    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }

    // overlayContainerClasses.add(theme);
    overlayContainerClasses.add('default-theme');
  }

  public getAppDateFormats(): Observable<UserDateFormats[]> {
    return this.http.get<UserDateFormats[]>('assets/data/app-date-formats.json');
  }

  public getApiKeys(): Observable<any> {
    return this.http.get(`${this.environment.api.url}/account/synapse/api-keys`).pipe(map((response) => response['content']));
  }

  public addApiKey(): Observable<any> {
    return this.http.post(`${this.environment.api.url}/account/synapse/api-keys`, {});
  }

  public getApiKeyById(apiKeyIdToGet: string) {
    return this.http.get(`${this.environment.api.url}/account/synapse/api-keys/${apiKeyIdToGet}`, {});
  }

  public deleteApiKeyById(apiKeyIdToDelete: string) {
    return this.http.delete(`${this.environment.api.url}/account/synapse/api-keys/${apiKeyIdToDelete}`, {});
  }
}
