<div class="detail-device-card-row" fxLayout="row" fxLayoutGap="4px">
  <div class="detail-device-card-col">
    @if (context() === 'connector') {
      <span class="detail-device-card-label"
            [ngClass]="context()">{{ 'DEVICES.DETAIL_DEVICE_CARD.INCOMING_CONNECTOR' | translate }}</span>
      <span class="detail-device-card-label"
            [ngClass]="context()">{{ 'DEVICES.DETAIL_DEVICE_CARD.OUTGOING_CONNECTOR' | translate }}</span>
    }
    @if (context() === 'communication') {
      <span class="detail-device-card-label"
            [ngClass]="context()">{{ 'DEVICES.DETAIL_DEVICE_CARD.IDENTIFIER' | translate }}</span>
      <span class="detail-device-card-label"
            [ngClass]="context()">{{ 'DEVICES.DETAIL_DEVICE_CARD.SUPPORT' | translate }}</span>
      <span class="detail-device-card-label"
            [ngClass]="context()">{{ 'DEVICES.DETAIL_DEVICE_CARD.ENDPOINT' | translate }}</span>
    }
  </div>
  <div class="detail-device-card-col">
    @if (context() === 'connector') {
      <span class="detail-device-card-value">{{ device()?.incomingConnector?.name | infoDisplay }}</span>
      <span class="detail-device-card-value">{{ device()?.outgoingConnector?.name | infoDisplay }}</span>
    }
    @if (context() === 'communication') {
      <span class="detail-device-card-value">{{ device()?.identifier | infoDisplay }}</span>
      <span class="detail-device-card-value">{{ device()?.communication.support | infoDisplay }}</span>
      <span class="detail-device-card-value">{{ device()?.communication.endpoint | infoDisplay }}</span>
    }
  </div>
</div>
