import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Calendar, OCMDayOff } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listCalendarsSuccess = createAction(
  '[Calendars] List Calendars Success',
  props<{ response: CommonApiResponse<Calendar, CommonIndexedPagination> }>()
);
export const listCalendarsFailure = createAction('[Calendars] List Calendars Failure', props<{ error: any }>());

export const getCalendarSuccess = createAction('[Calendars] Get Calendar Success', props<{ selectedCalendar: Calendar }>());
export const getCalendarFailure = createAction('[Calendars] Get Calendar Failure', props<{ error: any }>());

export const addCalendarSuccess = createAction('[Calendars] Add Calendar Success', props<{ addedCalendar: Calendar }>());
export const addCalendarFailure = createAction('[Calendars] Add Calendar failure', props<{ error: any }>());

export const updateCalendarSuccess = createAction('[Calendars] Update Calendar Success', props<{ updatedCalendar: Calendar }>());
export const updateCalendarFailure = createAction('[Calendars] Update Calendar Failure', props<{ error: any }>());

export const deleteCalendarSuccess = createAction('[Calendars] Delete Calendar Success', props<{ deletedCalendar: Calendar }>());
export const deleteCalendarFailure = createAction('[Calendars] Delete Calendar Failure', props<{ error: any }>());

export const loadMVSettingsSuccess = createAction('[Calendars] Load MV Calendars Settings Success', props<{ mvSettings: any }>());
export const loadMVSettingsFailure = createAction('[Calendars] Load MV Calendars Settings Failure', props<{ error: any }>());
//
export const saveMVSettingsSuccess = createAction('[Calendars] Save MV Calendars Settings Success', props<{ mvSettings: any }>());
export const saveMVSettingsFailure = createAction('[Calendars] Save MV Calendars Settings Failure', props<{ error: any }>());
//
export const listDaysOffByCalendarIdSuccess = createAction('[Calendars] List Calendar Days Off  Success', props<{ daysOff: OCMDayOff[] }>());
export const listDaysOffByCalendarIdFailure = createAction('[Calendars] List Calendar Days Off Failure', props<{ error: any }>());

export const addDayOffSuccess = createAction('[Calendars] Add Day Off Success', props<{ addedDayOff: OCMDayOff }>());
export const addDayOffFailure = createAction('[Calendars] Add Day Off failure', props<{ error: any }>());

export const updateDayOffSuccess = createAction('[Calendars] Update Day Off Success', props<{ updatedDayOff: OCMDayOff }>());
export const updateDayOffFailure = createAction('[Calendars] Update Day Off Failure', props<{ error: any }>());

export const deleteDayOffSuccess = createAction('[Calendars] Delete Day Off Success', props<{ deletedDayOff: OCMDayOff }>());
export const deleteDayOffFailure = createAction('[Calendars] Delete Day Off Failure', props<{ error: any }>());

export const importDaysOffByCalendarIdSuccess = createAction('[Calendars] Import Days Off Success', props<{ daysOff: OCMDayOff[] }>());
export const importDaysOffByCalendarIdFailure = createAction('[Calendars] Import Days Off Failure', props<{ error: any }>());
