import { NgModule } from '@angular/core';
import { AssetBreadcrumbItemComponent } from './asset-breadcrumb-item/asset-breadcrumb-item.component';
import { BreadcrumbComponent } from './breadcrumb.component';
import { DeviceBreadcrumbItemComponent } from './device-breadcrumb-item/device-breadcrumb-item.component';
import { SiteBreadcrumbItemComponent } from './site-breadcrumb-item/site-breadcrumb-item.component';

@NgModule({
  imports: [BreadcrumbComponent, SiteBreadcrumbItemComponent, AssetBreadcrumbItemComponent, DeviceBreadcrumbItemComponent],
  exports: [BreadcrumbComponent, SiteBreadcrumbItemComponent, AssetBreadcrumbItemComponent, DeviceBreadcrumbItemComponent]
})
export class BreadcrumbModule {}
