import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'i4b-table-engine-rating-cell',
  templateUrl: './rating-cell.component.html',
  styleUrls: ['./rating-cell.component.scss']
})
export class RatingCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: RatingConfig;

  @Output() dispatchEvent;

  icons: string[] = [];
  dataType: string;

  ngOnInit() {
    this.dataType = typeof this.data;
    const rating = Number(this.data);
    for (let index = 0; index < this.cellOptions.max; index++) {
      this.icons[index] = rating > index ? this.cellOptions.highlight : this.cellOptions.icon;
    }
  }
}

interface RatingConfig {
  max: number;
  icon: string;
  highlight: string;
}
