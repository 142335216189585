import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { I4BGridFilterOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';

@Component({
  standalone: true,
  selector: 'grid-engine-rich-variable-value-cell-filter',
  imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, TranslateModule, FlexModule, MatButtonModule],
  templateUrl: './rich-variable-value-cell-filter.component.html'
})
export class RichVariableValueCellFilterComponent implements IFilterAngularComp {
  public lastValue: number;
  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;
  operators: { label: string; value: string }[] = [
    { label: I4BGridFilterOptions.EQUALS, value: '==' },
    { label: I4BGridFilterOptions.LESS_THAN, value: '<' },
    { label: I4BGridFilterOptions.GREATER_THAN, value: '>' },
    { label: I4BGridFilterOptions.LESS_THAN_OR_EQUAL, value: '<=' },
    { label: I4BGridFilterOptions.GREATER_THAN_OR_EQUAL, value: '>=' }
  ];
  selectedOperator: { label: string; value: string } = this.operators[0];
  private filterParams!: IFilterParams;

  agInit(params: IFilterParams): void {
    this.filterParams = params;
  }

  isFilterActive(): boolean {
    return this.lastValue != null && this.lastValue !== undefined;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = this.filterParams.colDef.filterParams.field || this.filterParams.colDef.field;
    const value = get(params.data, field, '').toString().toLowerCase();
    // eslint-disable-next-line no-eval
    return eval(value + this.selectedOperator.value + this.lastValue);
  }

  getModel(): any {
    if (!this.isFilterActive()) {
      return null;
    }
    return { value: this.lastValue };
  }

  setModel(model: any): void {
    this.lastValue = model ? model.value : null;
  }

  afterGuiAttached(/* params: IAfterGuiAttachedParams */): void {
    window.setTimeout(() => this.input.element.nativeElement.focus());
  }

  onChange(newValue: any): void {
    if (this.lastValue !== newValue) {
      this.lastValue = newValue;
      this.filterParams.filterChangedCallback();
    }
  }

  onChangeOperator({ value }) {
    if (this.selectedOperator.value !== value.value) {
      this.selectedOperator.value = value.value;
    }
    this.filterParams.filterChangedCallback();
  }

  reset() {
    this.lastValue = null;
    this.selectedOperator.value = null;
    this.filterParams.filterChangedCallback();
  }
}
