<mat-expansion-panel [expanded]="expanded()" data-cy="iot-platform-ui-filter-engine">
  <div class="filter-engine-row" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between stretch">
    <div class="filter-engine-col" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start stretch"
         fxLayoutGap="10px">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        @if (withFavoriteFilters()) {
          <div class="filter-type-toggle" fxFlex="68px" fxLayoutAlign="start start">
            <mat-button-toggle-group [(ngModel)]="mode"
                                     [hideSingleSelectionIndicator]="true">
              <mat-button-toggle
                [matTooltip]="'IOT_TOOLBAR.TOOLTIP.SHOW_FAVORITE_FILTERS' | translate"
                [value]="FilterEngineMode.FAVORITE"
                matTooltipClass="regular-tooltip"
              >
                <mat-icon style="font-size: 22px">star_outlined</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle
                [matTooltip]="'IOT_TOOLBAR.TOOLTIP.SHOW_EXPANDED_FILTERS' | translate"
                [value]="FilterEngineMode.CLASSIC"
                matTooltipClass="regular-tooltip"
              >
                <mat-icon [svgIcon]="'classic_filter_engine'"></mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          @if (mode() === FilterEngineMode.FAVORITE && displayManageFavoriteFilters()) {
            <div class="favorite-filter-engine--settings-zone">
              <span class="left-separator"></span>
              <button
                (click)="openManageFavoriteFilters()"
                [matTooltip]="'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.BUTTON_TOOLTIP' | translate"
                class="regular-round-button"
                mat-icon-button
                matTooltipClass="regular-tooltip"
              >
                <mat-icon>settings</mat-icon>
              </button>
              <span class="right-separator"></span>
            </div>
          }
          <div [style.display]="mode() === FilterEngineMode.FAVORITE ? 'block' : 'none'"
               class="filters-container">
            <div
              class="filter-engine-title"
              fxLayout="row wrap"
              fxLayoutAlign="start center"
              fxLayoutGap="15px"
              data-cy="iot-platform-ui-filter-engine-favorite-mode"
            >
              <ng-container *ngxInit="favoriteFields() as favoriteFields">
                @for (field of favoriteFields; track $index) {
                  <ng-container *ngComponentOutlet="field.component; inputs: field.inputs" />
                }
              </ng-container>
            </div>
          </div>
          <div [style.display]="mode() === FilterEngineMode.CLASSIC ? 'block' : 'none'"
               class="filters-container">
            <div
              class="filter-engine-title"
              fxLayout="row wrap"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              data-cy="iot-platform-ui-filter-engine-classic-mode"
            >
              <ng-container *ngTemplateOutlet="classicFilterEngine" />
            </div>
          </div>
        } @else {
          <div
            class="filter-engine-title"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            data-cy="iot-platform-ui-filter-engine-classic-mode"
          >
            <mat-icon>filter_list</mat-icon>
            <ng-container *ngTemplateOutlet="classicFilterEngine" />
          </div>
        }
      </div>
      @if (currentFiltersNotHidden().length) {
        <ng-container *ngTemplateOutlet="appliedFiltersList" />
      }
    </div>
  </div>
</mat-expansion-panel>

@if (!expanded() && currentFiltersNotHidden().length) {
  <ng-container *ngTemplateOutlet="appliedFiltersList" />
}

<ng-template #classicFilterEngine>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <button
      [disabled]="readonly()"
      [matMenuTriggerFor]="filterCriteria"
      class="filter-engine-menu"
      data-cy="iot-platform-ui-filter-engine-menu-btn"
      mat-button
      type="button"
    >
      <span>{{ filterCriteriaButtonTitle() | translate | uppercase }}</span>
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #filterCriteria="matMenu">
      @for (category of categories(); track category.key) {
        @if (category.root) {
          <button (click)="addField(category)" data-cy="iot-platform-ui-filter-engine-menu-item-btn"
                  mat-menu-item>
            {{ category.label | translate | uppercase }}
          </button>
        } @else {
          <button [matMenuTriggerFor]="subMenu" data-cy="iot-platform-ui-filter-engine-cascade-menu-btn" mat-menu-item>
            {{ category.label | translate | uppercase }}
          </button>
          <mat-menu #subMenu="matMenu">
            @for (option of category.options; track option.key) {
              <button (click)="addField(option)"
                      data-cy="iot-platform-ui-filter-engine-cascade-menu-item-btn"
                      mat-menu-item>
                {{ option.label | translate | uppercase }}
              </button>
            }
          </mat-menu>
        }
      }
    </mat-menu>
    <ng-container *ngxInit="classicFields() as classicFields">
      @for (field of classicFields; track $index) {
        <ng-container *ngComponentOutlet="field.component; inputs: field.inputs" />
      }
    </ng-container>
  </div>
</ng-template>

<ng-template #appliedFiltersList>
  <div class="filter-engine-row filter-engine-applied" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
    <span [ngClass]="{ 'filter-engine-max-capacity': currentFiltersNotHidden().length === maxFilters }"
          class="filters-number"
    >{{ currentFiltersNotHidden().length }}/{{ maxFilters }}</span
    >
    <mat-chip-set class="filter-engine-selected" fxLayout="row wrap" fxLayoutAlign="start center">
      @for (filter of currentFiltersNotHidden(); track $index) {
        @if (expanded()) {
          <iot-platform-ui-chip
            (chipButtonClick)="removeOneFilter($event)"
            [chip]="filter"
            [chipButton]="{ display: !readonly(), name: 'close' }"
          />
        } @else {
          <iot-platform-ui-chip [chipButton]="{ display: false }" [chip]="filter" />
        }
      }
      @if (displayActionButtons()) {
        <button (click)="onClearAllFilters()" class="action-link" color="accent" mat-stroked-button>
          {{ 'FILTER_ENGINE.CLEAR_ALL' | translate }}
        </button>

        @if (displayResetToFavoriteViewFiltersButton()) {
          <button (click)="onResetFavoriteView()" class="action-link" color="accent" mat-stroked-button>
            {{ 'FILTER_ENGINE.RESET' | translate }}
          </button>
        }
      }
    </mat-chip-set>
  </div>
</ng-template>
