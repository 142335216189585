import { TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, Device, DeviceVariable, Site, SiteAssociations, VariablesTableFilters } from '@iot-platform/models/i4b';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { AssetsActions, AssetVariablesActions } from '../../../features/assets/+state/actions';
import { DevicesActions } from '../../../features/devices/state/actions';
import { SitesDbActions } from '../../../features/sites/+state/actions';
import { NavigationActions } from '../actions';

export const navigationUiFeatureKey = 'navigationUi';

export interface State {
  // SITE
  site: Site;
  assets: Asset[];
  assetsLoaded: boolean;
  devices: Device[];
  devicesLoaded: boolean;
  devicesByStock?: { data: Device[]; currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  deviceCountByFamily: { family: string; total: number }[];
  deviceCountByFamilyLoading: boolean;
  siteLoaded: boolean;
  siteTags: TagCategory[];
  siteTagsLoaded?: boolean;

  // SELECTED ASSET
  selectedAsset: Asset;
  assetTags: TagCategory[];
  assetLoaded: boolean;
  assetTagsLoaded: boolean;
  assetVariables: AssetVariable[];
  assetVariablesLoaded: boolean;

  // SELECTED DEVICE
  selectedDevice: Device;
  deviceTags: TagCategory[];
  deviceTagsLoaded: boolean;
  deviceLoaded: boolean;
  deviceVariables: DeviceVariable[];
  deviceVariablesTableFilters: Partial<VariablesTableFilters>;
  deviceVariablesLoaded: boolean;

  // ASSOCIATIONS
  associations?: SiteAssociations;

  activeId?: string;
  siteId?: string;
  origin?: string;
  pageType?: string;
  error?: any;
}

export const initialState: State = {
  site: null,
  selectedAsset: null,
  selectedDevice: null,
  siteLoaded: false,
  siteTags: [],
  siteTagsLoaded: false,
  assetLoaded: false,
  assetTags: [],
  assetTagsLoaded: false,
  assetVariables: [],
  assetVariablesLoaded: false,
  deviceTags: [],
  deviceLoaded: false,
  deviceTagsLoaded: false,
  deviceVariables: [],
  deviceVariablesTableFilters: {
    name: null,
    description: null,
    group: null,
    file: null,
    linked: null
  },
  deviceVariablesLoaded: false,
  assets: [],
  assetsLoaded: false,
  devices: [],
  devicesLoaded: false,
  devicesByStock: { currentPage: 0, data: [], hasMore: false, limit: 25, maxPage: 0, total: 0 },
  deviceCountByFamily: [],
  deviceCountByFamilyLoading: false,
  associations: null,
  activeId: '',
  siteId: '',
  origin: '',
  pageType: 'info',
  error: null
};

const navigationReducer = createReducer(
  initialState,
  on(NavigationActions.clearData, () => initialState),
  on(NavigationActions.loadData, (state: State, { pageType, origin, siteId, activeId }) => ({
    ...state,
    site: null,
    assets: [],
    devices: [],
    devicesByStock: null,
    siteTags: [],
    assetTags: [],
    selectedDevice: null,
    selectedAsset: null,
    associations: null,
    activeId,
    siteId,
    origin,
    pageType
  })),
  on(SitesDbActions.updateSiteSuccess, (state: State, { updatedSite }) => ({ ...state, site: updatedSite })),
  on(SitesDbActions.updateTagsBySiteId, (state: State, { tags }) => ({ ...state, siteTags: tags, siteTagsLoaded: false, siteLoaded: false })),
  on(SitesDbActions.updateTagsBySiteIdSuccess, (state: State, { tags }) => ({ ...state, siteTags: tags, siteTagsLoaded: true, siteLoaded: true })),
  //
  on(AssetsActions.deleteOne, (state: State, { toDelete }) => ({
    ...state,
    assetLoaded: false,
    selectedAsset: null,
    assets: [...state.assets.slice(state.assets.indexOf(toDelete), 1)]
  })),
  on(AssetsActions.updateOne, (state: State) => ({ ...state, assetLoaded: false })),
  on(AssetsActions.updateOneSuccess, (state: State, { updated }) => {
    const newAssets: Asset[] = updateElement(state.assets, updated);
    return {
      ...state,
      selectedAsset: updated,
      assets: newAssets,
      assetLoaded: true,
      assetLoading: false
    };
  }),
  //
  on(DevicesActions.updateDeviceSuccess, (state: State, { device }) => ({
    ...state,
    selectedDevice: device,
    devices: updateElement(state.devices, device),
    devicesByStock: { ...state.devicesByStock, data: [...state.devicesByStock.data.map((d: Device) => (d.id === device.id ? device : d))] }
  })),
  //
  on(DevicesActions.activateDeviceSuccess, (state: State, { device }) => ({
    ...state,
    selectedDevice: device,
    devices: updateElement(state.devices, device),
    devicesByStock: { ...state.devicesByStock, data: [...state.devicesByStock.data.map((d: Device) => (d.id === device.id ? device : d))] }
  })),
  on(DevicesActions.deleteDevice, (state: State, { device }) => ({
    ...state,
    deviceLoaded: false,
    selectedDevice: null,
    devices: [...state.devices.slice(state.devices.indexOf(device), 1)]
  })),

  on(DevicesActions.deleteDeviceSuccess, (state: State) => ({
    ...state,
    deviceLoaded: true,
    selectedDevice: null,
    devices: [...state.devicesByStock.data],
    devicesByStock: { ...state.devicesByStock, data: [...state.devicesByStock.data], total: state.devicesByStock.total - 1 }
  })),

  //
  on(SitesDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, site })),
  on(SitesDbActions.loadSiteById, (state: State) => ({ ...state })), // Make a new Action for loadiing site and removing previous
  on(SitesDbActions.loadAssetsBySiteId, (state: State) => ({ ...state, assetsLoaded: false })),
  on(SitesDbActions.loadAssetsBySiteIdSuccess, (state: State, { response }) => ({ ...state, assets: response.data, assetsLoaded: true })),
  on(SitesDbActions.loadDevicesBySiteId, (state: State) => ({ ...state, devicesLoaded: false })),
  on(SitesDbActions.loadDevicesBySiteIdSuccess, (state: State, { response }) => ({ ...state, devices: response.data, devicesLoaded: true })),
  on(SitesDbActions.loadAssociationsBySiteId, (state: State) => ({ ...state, associations: null })),
  on(SitesDbActions.loadAssociationsBySiteIdSuccess, (state: State, { associations }) => ({ ...state, associations })),
  //
  on(AssetsActions.getOneSuccess, (state: State, { element }) => ({ ...state, selectedAsset: element })),
  on(SitesDbActions.loadTagsBySiteId, (state: State) => ({ ...state, siteTags: [], siteTagsLoaded: false })),
  on(SitesDbActions.loadTagsBySiteIdSuccess, (state: State, { tags }) => ({ ...state, siteTags: tags, siteTagsLoaded: true })),
  //
  on(NavigationActions.clearSelectedItem, (state: State) => ({
    ...state,
    selectedDevice: null,
    selectedAsset: null
  })),
  on(NavigationActions.selectAsset, (state: State, { assetId }) => ({
    ...state,
    selectedAsset: state.assets.find((asset) => asset.id === assetId),
    selectedDevice: null
  })),
  on(NavigationActions.selectAssetAvecLeSite, (state: State, { selectedAsset }) => ({
    ...state,
    selectedAsset,
    selectedDevice: null,
    site: selectedAsset.site
  })),
  on(NavigationActions.selectLeSite, (state: State, { selectedSite }) => ({
    ...state,
    site: selectedSite,
    selectedDevice: null,
    selectedAsset: null,
    associations: null,
    assets: [],
    devices: [],
    siteTags: []
  })),
  on(NavigationActions.selectDeviceAvecLeSite, (state: State, { selectedDevice }) => ({
    ...state,
    selectedDevice,
    selectedAsset: null,
    site: selectedDevice.site
  })),
  on(NavigationActions.selectDevice, (state: State, { deviceId }) => ({
    ...state,
    selectedDevice: state.devices.find((device) => device.id === deviceId),
    selectedAsset: null
  })),
  on(NavigationActions.applyDeviceVariablesTableFilters, (state: State, { filters }) => ({
    ...state,
    deviceVariablesTableFilters: filters
  })),
  on(AssetsActions.getTags, (state: State) => ({ ...state, assetTags: [], assetTagsLoaded: false })),
  on(AssetsActions.getTagsSuccess, (state: State, { tags }) => ({ ...state, assetTags: tags, assetTagsLoaded: true })),
  on(AssetVariablesActions.getAll, (state: State) => ({ ...state, assetVariables: [], assetVariablesLoaded: false })),
  on(AssetVariablesActions.getAllSuccess, (state: State, { allVariables }) => ({
    ...state,
    assetVariables: allVariables,
    assetVariablesLoaded: true
  })),
  on(AssetVariablesActions.addOneSuccess, (state: State, { added }) => ({
    ...state,
    assetVariables: [...state.assetVariables, added]
  })),
  on(AssetVariablesActions.updateOneSuccess, (state: State, { updated }) => ({
    ...state,
    assetVariables: [...state.assetVariables.map((variable: AssetVariable) => (variable.id === updated.id ? updated : variable))]
  })),
  //
  on(DevicesActions.loadDeviceByIdSuccess, (state: State, { device }) => ({ ...state, selectedDevice: device })),
  on(DevicesActions.loadDeviceTags, (state: State) => ({ ...state, deviceTags: [], deviceTagsLoaded: false })),
  on(DevicesActions.loadDeviceTagsSuccess, (state: State, { tags }) => ({ ...state, deviceTags: tags, deviceTagsLoaded: true })),
  on(DevicesActions.loadDeviceVariables, (state: State) => ({ ...state, deviceVariables: [], deviceVariablesLoaded: false })),
  on(DevicesActions.loadDeviceVariablesSuccess, (state: State, { deviceVariables }) => ({
    ...state,
    deviceVariables,
    deviceVariablesLoaded: true
  })),
  on(DevicesActions.updateDeviceTags, (state: State) => ({
    ...state,
    deviceTags: [],
    deviceTagsLoaded: false,
    deviceLoaded: false
  })),
  on(DevicesActions.updateDeviceTagsSuccess, (state: State, { tags }) => ({
    ...state,
    deviceTags: tags,
    deviceTagsLoaded: true,
    deviceLoaded: true
  })),
  on(AssetsActions.updateTagsByAssetId, (state: State, { tags }) => ({
    ...state,
    assetTags: tags,
    assetTagsLoaded: false,
    assetLoading: true,
    assetLoaded: false
  })),
  on(AssetsActions.updateTagsByAssetIdSuccess, (state: State, { tags }) => ({
    ...state,
    assetTags: tags,
    assetTagsLoaded: true,
    assetLoading: false,
    assetLoaded: true
  })),
  // Delete last line
  on(NavigationActions.loadDataSuccess, (state: State, { site, assets, devices }) => ({
    ...state,
    site,
    assets,
    devices
  })),
  on(NavigationActions.changeStockPage, (state: State) => ({
    ...state,
    deviceLoaded: false
  })),
  on(NavigationActions.changeStockPageSuccess, (state: State, { response }) => ({
    ...state,
    devicesByStock: {
      data: response.data,
      currentPage: response.currentPage,
      limit: response.limit,
      hasMore: response.hasMore,
      maxPage: response.maxPage,
      total: response.total
    },
    deviceLoaded: true
  })),
  // STOCK
  on(NavigationActions.loadDeviceCountByFamily, (state) => ({ ...state, deviceCountByFamilyLoading: true })),
  on(NavigationActions.loadDeviceCountByFamilySuccess, (state, { deviceCountByFamily }) => ({
    ...state,
    deviceCountByFamilyLoading: false,
    deviceCountByFamily
  })),
  on(NavigationActions.loadDeviceCountByFamilyFailure, (state, { error }) => ({ ...state, deviceCountByFamilyLoading: false, error })),
  //
  on(NavigationActions.moveDeviceSuccess, (state: State, { movedDevice }) => {
    // TODO : it very needs to be cleaned.

    const newArray = updateElement(state.devicesByStock.data, movedDevice);

    return {
      ...state,
      devicesByStock: {
        ...state.devicesByStock,
        data: newArray,
        total: state.devicesByStock.total - 1
      }
    };
  }),
  on(NavigationActions.moveDevicesSuccess, (state: State, { movedDevices }) => {
    // TODO : it very needs to be cleaned.
    const moved = movedDevices[0];

    const newArray = updateElement(state.devicesByStock.data, moved);

    return {
      ...state,
      devicesByStock: {
        ...state.devicesByStock,
        data: newArray,
        total: state.devicesByStock.total - 1
      }
    };
  }),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

const updateElement = (array: any[], elm: any): any[] => {
  const newArray: any[] = [...array];
  newArray.splice(
    array.findIndex((curElm) => curElm.id === elm.id),
    1,
    elm
  );
  return newArray;
};

export function reducer(state: State | undefined, action: Action) {
  return navigationReducer(state, action);
}

// SELECTED SITE
export const getSite = (state: State) => state.site;
export const getAssets = (state: State) => state.assets;
export const getAssetsLoaded = (state: State) => state.assetsLoaded;
export const getDevices = (state: State) => state.devices;
export const getDevicesLoaded = (state: State) => state.devicesLoaded;
export const getDevicesByStock = (state: State) => state.devicesByStock;
export const getDeviceCountByFamily = (state: State) => state.deviceCountByFamily;
export const getDeviceCountByFamilyLoading = (state: State) => state.deviceCountByFamilyLoading;
export const getSiteTags = (state: State) => state.siteTags;
export const getSiteTagsLoaded = (state: State) => state.siteTagsLoaded;
export const getSiteAssociations = (state: State) => state.associations;

// SELECTED ASSET
export const getSelectedAsset = (state: State) => state.selectedAsset;
export const getAssetTags = (state: State) => state.assetTags;
export const getAssetTagsLoaded = (state: State) => state.assetTagsLoaded;
export const getAssetVariables = (state: State) => state.assetVariables;
export const getAssetVariablesLoaded = (state: State) => state.assetVariablesLoaded;

// SELECTED DEVICE
export const getSelectedDevice = (state: State) => state.selectedDevice;
export const getDeviceTags = (state: State) => state.deviceTags;
export const getDeviceTagsLoaded = (state: State) => state.deviceTagsLoaded;
export const getDeviceVariables = (state: State) => state.deviceVariables;
export const getDeviceVariablesTableFilters = (state: State) => state.deviceVariablesTableFilters;
export const getDeviceVariablesLoaded = (state: State) => state.deviceVariablesLoaded;

// LOADERS
export const getAssetLoaded = (state: State) => state.assetLoaded;
export const getDeviceLoaded = (state: State) => state.deviceLoaded;
export const getSiteLoaded = (state: State) => state.siteLoaded;

export const getActiveId = (state: State) => state.activeId;
export const getSiteId = (state: State) => state.siteId;
export const getOrigin = (state: State) => state.origin;
export const getPageType = (state: State) => state.pageType;
