export enum AssetModelName {
  BLANK = 'blank',
  BULK = 'bulk',
  ONSITE = 'onsite',
  PG = 'pg',
  CONTAINER = 'container',
  TRAILER = 'trailer',
  WAGON = 'wagon'
}

export enum AssetCategoryName {
  FIXED_ASSET = 'fixed_asset',
  MOBILE_ASSET = 'mobile_asset'
}

export interface AssetModel {
  name: string;
  category: AssetCategory;
  assetOptionalProperties?: string[];
  icon?: string;
}

export const QUANTITIES: (string | null)[] = [
  null,
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '2x1',
  '2x2',
  '2x3',
  '2x4',
  '2x5',
  '2x6',
  '2x7',
  '2x8',
  '2x9',
  '2x10',
  '2x11',
  '2x12',
  '2x13',
  '2x14',
  '2x15'
];

type AssetCategory = { name: AssetCategoryName.FIXED_ASSET; icon: 'asset' } | { name: AssetCategoryName.MOBILE_ASSET; icon: 'mobile_asset' };

// Categories
const fixedCategory: AssetCategory = { name: AssetCategoryName.FIXED_ASSET, icon: 'asset' };
const mobileCategory: AssetCategory = { name: AssetCategoryName.MOBILE_ASSET, icon: 'mobile_asset' };

// Models
export const blankModel: AssetModel = {
  name: AssetModelName.BLANK,
  category: fixedCategory,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.BLANK + '.png'
};

export const bulkModel: AssetModel = {
  name: AssetModelName.BULK,
  category: fixedCategory,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.BULK + '.png',
  assetOptionalProperties: ['businessId', 'shipTo', 'product1']
};

export const onsiteModel: AssetModel = {
  name: AssetModelName.ONSITE,
  category: fixedCategory,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.ONSITE + '.png',
  assetOptionalProperties: ['businessId']
};

export const pgModel: AssetModel = {
  name: AssetModelName.PG,
  category: fixedCategory,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.PG + '.png',
  assetOptionalProperties: ['product1', 'product2', 'quantity1', 'quantity2', 'deliveryDate']
};

export const containerModel: AssetModel = {
  name: AssetModelName.CONTAINER,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.CONTAINER + '.png',
  category: mobileCategory
};

export const trailerModel: AssetModel = {
  name: AssetModelName.TRAILER,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.TRAILER + '.png',
  category: mobileCategory
};

export const wagonModel: AssetModel = {
  name: AssetModelName.WAGON,
  icon: 'assets/images/asset-creation-models/' + AssetModelName.WAGON + '.png',
  category: mobileCategory
};

export const FIXED_ASSET_MODELS: AssetModel[] = [blankModel, bulkModel, onsiteModel, pgModel];
export const MOBILE_ASSET_MODELS: AssetModel[] = [containerModel, trailerModel, wagonModel];

export const ASSET_TEMPLATES_MODELS = [
  AssetModelName.BULK,
  AssetModelName.ONSITE,
  AssetModelName.PG,
  AssetModelName.CONTAINER,
  AssetModelName.TRAILER,
  AssetModelName.WAGON
];
