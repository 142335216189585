import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { OrganizationsService } from '../../services/organizations.service';
import { AdminOrganizationsRolesApiActions, AdminOrganizationsRolesPageActions } from '../actions';

@Injectable()
export class AdminOrganizationsRolesEffects {
  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsRolesPageActions.updateRoleForOrganization),
      debounceTime(1000),
      mergeMap((action) =>
        this.organizationsService.updateRoleForOrganization(action.roleToUpdate).pipe(
          map((updatedRole) => AdminOrganizationsRolesApiActions.updateRoleSuccess({ updatedRole: { id: updatedRole.id, changes: updatedRole } })),
          catchError((error) => of(AdminOrganizationsRolesApiActions.updateRoleFailure({ error })))
        )
      )
    )
  );

  addRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsRolesPageActions.addRoleToOrganization),
      mergeMap((action) =>
        this.organizationsService.addRoleToOrganization(action.roleToAdd).pipe(
          map((addedRole) => AdminOrganizationsRolesApiActions.addRoleToOrganizationSuccess({ addedRole })),
          catchError((error) => of(AdminOrganizationsRolesApiActions.addRoleToOrganizationFailure({ error })))
        )
      )
    )
  );

  removeRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsRolesPageActions.removeRoleFromOrganization),
      mergeMap((action) =>
        this.organizationsService.removeRoleFromOrganization(action.roleToRemove).pipe(
          map((removedRole) => AdminOrganizationsRolesApiActions.removeRoleFromOrganizationSuccess({ removedRole })),
          catchError((error) => of(AdminOrganizationsRolesApiActions.removeRoleFromOrganizationFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesApiActions.addRoleToOrganizationSuccess,
          AdminOrganizationsRolesApiActions.updateRoleSuccess,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesApiActions.addRoleToOrganizationFailure,
          AdminOrganizationsRolesApiActions.updateRoleFailure,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesPageActions.listRolesByOrganization,
          AdminOrganizationsRolesPageActions.addRoleToOrganization,
          AdminOrganizationsRolesPageActions.updateRoleForOrganization,
          AdminOrganizationsRolesPageActions.removeRoleFromOrganization
        ),
        tap(() => this.notificationService.displayLoader(true))
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesApiActions.listRolesByOrganizationSuccess,
          AdminOrganizationsRolesApiActions.listRolesByOrganizationFailure,
          AdminOrganizationsRolesApiActions.addRoleToOrganizationSuccess,
          AdminOrganizationsRolesApiActions.addRoleToOrganizationFailure,
          AdminOrganizationsRolesApiActions.updateRoleSuccess,
          AdminOrganizationsRolesApiActions.updateRoleFailure,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationSuccess,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationFailure
        ),
        tap(() => this.notificationService.displayLoader(false))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService
  ) {}
}
