import { EnvironmentProviders, InjectionToken, Type, makeEnvironmentProviders } from '@angular/core';
import { AbstractFollowedVariableAddPopupService } from './abstract-followed-variable-add-popup.service';

export interface FollowedVariableAddPopupSettings {
  followedVariableAddPopupService: Type<AbstractFollowedVariableAddPopupService>;
}

export const FOLLOWED_VARIABLE_ADD_POPUP_SETTINGS = new InjectionToken<FollowedVariableAddPopupSettings>('FOLLOWED_VARIABLE_ADD_POPUP_SETTINGS');

export function provideFollowedVariableAddPopupSettings(settings: FollowedVariableAddPopupSettings): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: AbstractFollowedVariableAddPopupService, useClass: settings.followedVariableAddPopupService }]);
}
