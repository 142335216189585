import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { Asset } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

interface Hour {
  value: string;
  viewValue: string;
}

@Component({
  standalone: true,
  imports: [DetailPopupModule, MatFormFieldModule, MatInputModule, FormsModule, TranslateModule, FlexLayoutModule],
  selector: 'iot-platform-maps-map-panel-info-toolbox',
  templateUrl: './map-panel-info-toolbox.component.html'
})
export class MapPanelInfoToolboxComponent {
  asset = input<Asset>();
  loadRoutes = output<{ asset: Asset; daysToDisplay: string; minutesBetweenSegment: number; limit: number }>();

  SELECT_HOUR_LABEL = 'Select duration';
  limit = 20000;
  selectedHours = this.SELECT_HOUR_LABEL;
  minuteBetweenSegment = 5;

  hours: Hour[] = [
    { value: this.SELECT_HOUR_LABEL, viewValue: this.SELECT_HOUR_LABEL },
    { value: '60', viewValue: '1 hour' },
    { value: '1440', viewValue: '1 day' },
    { value: '2880', viewValue: '2 days' },
    { value: '4320', viewValue: '3 days' },
    { value: '7200', viewValue: '5 days' },
    { value: '14400', viewValue: '10 days' },
    { value: '43200', viewValue: '30 days' }
  ];

  onLoadRoutes() {
    if (this.selectedHours !== this.SELECT_HOUR_LABEL && this.asset) {
      this.loadRoutes.emit({ asset: this.asset(), daysToDisplay: this.selectedHours, minutesBetweenSegment: this.minuteBetweenSegment, limit: this.limit });
    }
  }
}
