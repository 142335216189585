import { inject, Injectable } from '@angular/core';
import { AbstractGridAssetVariableConfigureReadPopupService } from '@iot-platform/grid-engine';
import { AssetVariable } from '@iot-platform/models/i4b';
import { AssetVariablesFacade } from '../features/assets/+state/facades/asset-variables.facade';

@Injectable({
  providedIn: 'root'
})
export class GridAssetVariableConfigureReadPopupService extends AbstractGridAssetVariableConfigureReadPopupService {
  private readonly assetVariablesFacade: AssetVariablesFacade = inject(AssetVariablesFacade);

  updateAssetVariableThresholds(assetVariable: AssetVariable): void {
    this.assetVariablesFacade.updateAssetVariableThresholds(assetVariable);
  }
}
