import { BaseUser } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listMembersByBusinessProfileSuccess = createAction(
  '[Admin Business Profile/API] List Members by Business Profile Success',
  props<{ members: BaseUser[] }>()
);
export const listMembersByBusinessProfileFailure = createAction(
  '[Admin Business Profile/API] List Members by Business Profile Failure',
  props<{ error: any }>()
);

export const addMemberToBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Members API] Add Member To Business Profile Success',
  props<{ addedMember: BaseUser }>()
);
export const addMemberToBusinessProfileFailure = createAction(
  '[Admin Business Profile/Members API] Add Member To Business Profile Failure',
  props<{ error: any }>()
);

export const removeMemberFromBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Members API] Remove Member From Business Profile Success',
  props<{ removedMember: BaseUser }>()
);
export const removeMemberFromBusinessProfileFailure = createAction(
  '[Admin Business Profile/Members API] Remove Role To Business Profile Failure',
  props<{ error: any }>()
);
