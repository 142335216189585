<form [formGroup]="form">
  <mat-form-field class="select-field" color="accent">
    <mat-label>{{ data()?.criteriaLabel | translate }}</mat-label>
    @if (data()?.multiSelect) {
      <mat-select
        (selectionChange)="onSelectionChange();"
        [compareWith]="compareFn"
        multiple
        data-cy="iot-platform-ui-select-field"
        formControlName="select"
      >
        @for (option of data()?.list; track option) {
          <mat-option [disabled]="!isSelectedOption(option) && disabled()" [value]="option"
                      data-cy="iot-platform-ui-select-field-option"
          >{{ option.value | translate }}
          </mat-option>
        }
      </mat-select>
    } @else {
      <mat-select
        (selectionChange)="onSelectionChange()"
        [compareWith]="compareFn"
        data-cy="iot-platform-ui-select-field"
        formControlName="select"
      >
        @for (option of data()?.list; track option) {
          <mat-option [disabled]="disabled()" [value]="option"
                      data-cy="iot-platform-ui-select-field-option"
          >{{ option.value | translate }}
          </mat-option>
        }
      </mat-select>
    }
  </mat-form-field>
</form>
