import { Component, computed, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TimeseriesWidgetOptions, WidgetInstance } from '@iot-platform/models/widgets';
import { VariableChartComponent } from '../../../variable-chart/variable-chart.component';

@Component({
  standalone: true,
  imports: [MatCardModule, MatToolbarModule, FlexLayoutModule, VariableChartComponent],
  selector: 'iot-platform-ui-timeseries-widget',
  templateUrl: './timeseries-widget.component.html',
  styleUrls: ['./timeseries-widget.component.scss']
})
export class TimeseriesWidgetComponent {
  widget = input<WidgetInstance>();

  widgetOptions = computed(() => {
    const widget = this.widget?.();
    if (widget) {
      return widget.options as TimeseriesWidgetOptions;
    }
    return null;
  });
}
