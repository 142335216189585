import { I4BCellType, I4BColumnConfiguration } from './configurations/i4b-column-configuration';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { I4BBasicColumn } from './definitions/i4b-basic.column';
import { I4BColumn } from './definitions/i4b-column';
import { I4bCellConcept } from './enums/i4b-cell-concept.enum';
import { I4BColumnHeader } from './models/i4b-column-header.model';
import { EmailTemplatesGridData } from './models/i4b-grid-data.model';
import { I4BGridOptions } from './models/i4b-grid-options.model';
import { I4BGrid } from './models/i4b-grid.model';
import { I4BColumnOptions } from './options/i4b-column-options';

export const DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new I4BBasicColumn(
    { displayName: 'NAME' },
    {
      id: 'name',
      isDefault: true,
      sortProperty: 'name',
      width: 140,
      concept: I4bCellConcept.EMAIL_TEMPLATES
    },
    { order: 1 }
  ),

  new I4BBasicColumn(
    { displayName: 'ENTITY' },
    {
      id: 'entityId',
      isDefault: true,
      sortProperty: 'entityId',
      width: 100,
      concept: I4bCellConcept.EMAIL_TEMPLATES,
      cell: { type: I4BCellType.ENTITY_CELL }
    },
    { order: 2 }
  ),

  new I4BBasicColumn(
    { displayName: 'CATEGORY' },
    {
      id: 'workflow',
      isDefault: true,
      sortProperty: 'workflow',
      width: 140,
      concept: I4bCellConcept.EMAIL_TEMPLATES
    },
    { order: 3 }
  )
];

export class DefaultEmailTemplatesGrid implements I4BGrid<I4BGridOptions, EmailTemplatesGridData> {
  masterview = 'email-templates';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId: string = null;
  data: EmailTemplatesGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: false },
    gridSort: [{ colId: 'workflow', sort: 'asc', sortIndex: 0 }]
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
