<div class="calls-log-header" fxLayout="row" fxLayoutAlign="space-around center">
  <iot4bos-ui-device-call-log-toolbar [initialFilters$]="initialFilters$" (applyFilters)="filterCallLog($event)" fxFlex></iot4bos-ui-device-call-log-toolbar>
</div>

<div style="flex: 1">
  <grid-engine-grid-engine-component
    fxFlex
    class="layout-ag-grid-shell"
    [gridMeta]="mvSettings$ | async"
    [gridData]="callLogToDisplay$ | async"
    [userPermissions]="[]"
    (pageChange)="onPageChange($event)"
    (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
  ></grid-engine-grid-engine-component>
</div>
