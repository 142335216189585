<div fxLayoutAlign="center center">
  <div (click)="openReleaseNotes.emit(true)" class="version" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    @if (label()) {
      <span class="version__label">{{ label() }}</span>
    }
    <span>{{ buildInfo()?.version }}</span>
    @if (showBuildNumber()) {
      <span>#{{ buildInfo()?.buildNumber }}</span>
    }
  </div>
</div>
