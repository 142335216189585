import { Injectable } from '@angular/core';
import { BaseFacade, Filter, Pagination } from '@iot-platform/models/common';
import { AssetVariable, AssetVariableCreationModel } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AssetVariablesActions } from '../actions';
import { AssetVariablesSelectors } from '../selectors/asset-variables.selectors';

@Injectable({
  providedIn: 'root'
})
export class AssetVariablesFacade extends BaseFacade<AssetVariable, Pagination, Filter> {
  concept = 'assetVariables';

  constructor(
    protected store: Store,
    protected selector: AssetVariablesSelectors
  ) {
    super(store, selector);
  }

  getAll(_: any): void {
    noop();
  }

  setFilters(_: Filter[]): void {
    noop();
  }

  loadVariablesByAssetId(assetId: string) {
    this.store.dispatch(AssetVariablesActions.getAll({ assetId }));
  }

  addAssetVariable(toAdd: AssetVariableCreationModel) {
    this.store.dispatch(AssetVariablesActions.addOne({ toAdd }));
  }

  updateAssetVariable(toUpdate: AssetVariable) {
    this.store.dispatch(AssetVariablesActions.updateOne({ toUpdate }));
  }

  updateAssetVariableThresholds(toUpdate: AssetVariable) {
    this.store.dispatch(AssetVariablesActions.updateThresholds({ toUpdate }));
  }

  updateFollowedAssetVariable(toUpdate: AssetVariable) {
    this.store.dispatch(AssetVariablesActions.updateFollowedAssetVariable({ toUpdate }));
  }

  deleteAssetVariables(toDelete: AssetVariable[]) {
    this.store.dispatch(AssetVariablesActions.deleteMany({ toDelete }));
  }

  resetAssetVariablesLastValues(assetVariables: AssetVariable[]) {
    this.store.dispatch(AssetVariablesActions.resetLastValues({ assetVariables }));
  }

  breakFormula(id: string) {
    this.store.dispatch(AssetVariablesActions.breakFormula({ id }));
  }
}
