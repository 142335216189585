import { CellClassParams, CellStyle, ValueGetterParams } from '@ag-grid-community/core';
import { DeviceVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import { I4BCellType, I4BDiagnosticVariableColumnConfiguration } from '../configurations';
import { DataLibrary, I4bCellConcept } from '../enums';
import { I4BBasicColumnHeader } from '../headers';
import { HeaderType, I4BColumnHeader } from '../models';
import { BasicColumnOptions, I4BColumnOptions } from '../options';
import { I4BColumn } from './i4b-column';

const variableNamesByCategory: { [key: string]: string[] } = {
  TEMPERATURE: ['TLF_TEMP', 'TEMP', 'CAM_TEMP', 'µD_TEMP', 'TP'],
  BATTERY_LEVEL: ['TLF_VBATT', 'VBATT', 'µD_BATT_PCT', 'CAM_BATT_PCT', 'BL', 'BATTERY_LEVEL'],
  RADIO_LEVEL: ['RL'],
  SMS_SENT: ['SMS_DAY_COUNTER', 'SS'],
  ENERGY_MODE: ['EM']
};

export class DiagnosticVariableColumn implements I4BColumn<I4BBasicColumnHeader, I4BDiagnosticVariableColumnConfiguration, I4BColumnOptions> {
  columnId = 'c653e68d-f410-4952-814b-d6c69c6dacce-diagnostic-variable';
  className: DiagnosticVariableColumn;

  dataLibs: DataLibrary[] = [DataLibrary.DEVICES];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC });
  configuration: I4BDiagnosticVariableColumnConfiguration = {
    concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
    id: null,
    isConfigurable: false,
    isDefault: true,
    isLink: true,
    position: null,
    sortProperty: null,
    width: 160,
    cell: {
      type: I4BCellType.DIAGNOSTIC_VARIABLE,
      options: {
        variableCategory: '',
        displayMode: 'value'
      },
      event: { type: 'openChart', options: null }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: false });

  constructor(
    customHeader: Partial<I4BColumnHeader>,
    customConfig: Partial<I4BDiagnosticVariableColumnConfiguration>,
    customOptions: Partial<I4BColumnOptions>
  ) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }

    if (customConfig) {
      this.configuration = {
        ...this.configuration,
        ...customConfig,
        cell: { ...this.configuration.cell, ...customConfig.cell }
      };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions, sortable: false };
    }

    this.configuration.cell.valueGetter = (params: ValueGetterParams) => {
      const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
      const variable = DiagnosticVariableColumn.getDiagnosticVariableByCategory(
        get(cellOptions, ['variableCategory'], ''),
        get(params, ['data', 'expandedVariables'], {})
      );
      if (cellOptions?.translatedValues) {
        const value = get(variable, ['lastValue', 'value']);
        return get(cellOptions, ['translatedValues', value, 'value'], null);
      } else {
        return variable;
      }
    };

    this.configuration.cell.cellStyle = (params: CellClassParams): CellStyle => {
      const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
      const value = get(params, `data.${params.colDef.field}`);
      const cssOptions: any = {};
      if (cellOptions?.textAlign) {
        cssOptions.textAlign = cellOptions.textAlign;
      }
      const translatedOptions = get(cellOptions, `translatedValues[${value}]`);
      if (translatedOptions?.backgroundColor) {
        cssOptions.backgroundColor = translatedOptions.backgroundColor;
      }
      if (translatedOptions?.color) {
        cssOptions.color = translatedOptions.color;
      }
      return cssOptions;
    };
  }

  static getDiagnosticVariableByCategory(
    category: string,
    expandedVariables: {
      [key: string]: Partial<DeviceVariable>;
    }
  ): Partial<DeviceVariable> | undefined {
    const variables = DiagnosticVariableColumn.getVariableNamesByCategory(category);
    const firstVariableFound = variables.find((v) => !!expandedVariables[v.toLowerCase()]);
    return firstVariableFound ? expandedVariables[firstVariableFound.toLowerCase()] : undefined;
  }

  static getVariableNamesByCategory(category: string): string[] {
    return variableNamesByCategory[category.toUpperCase()] ?? [];
  }
}
