import { BusinessProfile } from '../common/business-profile.model';
import { CommonGenericModel } from '../common/core/common-generic.model';
import { Entity } from '../common/entity.model';

export interface Rotation {
  color: string;
  id?: string;
  localEndDate: Date | string;
  localStartDate: Date | string;
  shiftDuration: number;
  status?: TeamPlanningRotationOverrideStatus;
  timezoneDetails?: { name: string };
  users: {
    id: string;
    order: number;
  }[];
}

export interface Override {
  id?: string;
  localEndDate: string;
  localStartDate: string;
  status?: TeamPlanningRotationOverrideStatus;
  timezoneDetails?: { name: string };
  user: { id: string };
}

export interface TeamPlanning extends CommonGenericModel {
  businessProfile: BusinessProfile;
  entity: Entity;
  handoffTime?: string;
  media?: { email: boolean; secondaryEmail: boolean; sms: boolean };
  rotationMode: TeamPlanningRotationMode;
  timezoneDetails: { name: string };
  tryCounter: number;
  waitDelay?: number;
}

export enum TeamPlanningRotationMode {
  serial = 'serial',
  parallel = 'parallel'
}

export enum TeamPlanningRotationOverrideStatus {
  past = 'past',
  current = 'current',
  scheduled = 'scheduled'
}
