import { inject } from '@angular/core';
import { AuthApiActions } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { UserAccount } from '@iot-platform/models/common';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserPreferencesService } from '../../services/user-preferences.service';
import { PreferencesActions } from '../actions';

/* Migrate old effects to functional effects */
const loadUserPreferences$ = createEffect(
  (actions$ = inject(Actions), userPrefService = inject(UserPreferencesService)) =>
    actions$.pipe(
      ofType(PreferencesActions.loadUserPreferences),
      switchMap(() =>
        userPrefService.loadPreferences().pipe(
          map((user) => PreferencesActions.loadUserPreferencesSuccess({ user, preferences: user.preferences })),
          catchError((error) => of(PreferencesActions.loadUserPreferencesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const saveUserPreferences$ = createEffect(
  (actions$ = inject(Actions), userPrefService = inject(UserPreferencesService), storage = inject(LocalStorageService)) =>
    actions$.pipe(
      ofType(PreferencesActions.saveUserPreferences),
      switchMap((action) =>
        userPrefService.saveUserPreferences(action.user).pipe(
          map((user: UserAccount) => {
            storage.set(LocalStorageKeys.STORAGE_USER_PREFERENCES, JSON.stringify(user.preferences));
            return PreferencesActions.saveUserPreferencesSuccess({ user, preferences: user.preferences });
          }),
          catchError((error) => of(PreferencesActions.saveUserPreferencesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const changeLanguage$ = createEffect(
  (actions$ = inject(Actions), translateService = inject(TranslateService)) =>
    actions$.pipe(
      ofType(PreferencesActions.changeLanguage),
      tap(({ lang }) => {
        if (lang && translateService.langs.includes(lang)) {
          translateService.currentLang = '';
          translateService.use(lang);
        }
      })
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(PreferencesActions.saveUserPreferencesSuccess),
      // map(({ preferences }) => PreferencesActions.changeLanguage({ lang: preferences.appLanguage })),
      tap((action: Action) => {
        notificationService.displaySuccess(action.type);
      })
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(PreferencesActions.saveUserPreferencesFailure),
      tap((action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

const pendingActions$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(PreferencesActions.saveUserPreferences),
      map(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const completedActions$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(PreferencesActions.saveUserPreferencesSuccess, PreferencesActions.saveUserPreferencesFailure),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const signInSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        AuthApiActions.signInSuccess,
        AuthApiActions.retrieveSsoSessionSuccess,
        AuthApiActions.signInWithSSOSuccess,
        AuthApiActions.retrieveSessionSuccess
      ),
      map(() => PreferencesActions.loadUserPreferences())
    ),
  { functional: true }
);

export const PreferencesEffects = {
  signInSuccess$,
  completedActions$,
  pendingActions$,
  displayError$,
  displaySuccess$,
  changeLanguage$,
  loadUserPreferences$,
  saveUserPreferences$
};
