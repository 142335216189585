import { inject } from '@angular/core';
import { UserAccount, BusinessProfile } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UsersService } from '../../services/users.service';
import { UsersActions } from '../actions';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { UsersFacade } from '../facade/users.facade';

const getUserById$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.getUserById, UsersActions.navigateToUserDetails),
      concatMap(({ userId }) =>
        usersService.getUserById(userId).pipe(
          map((response: UserAccount) => UsersActions.getUserByIdSuccess({ user: response })),
          catchError((error) => of(UsersActions.getUserByIdFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addUser$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.addUser),
      concatMap(({ user }) =>
        usersService.addUser(user).pipe(
          map((response: UserAccount) => UsersActions.addUserSuccess({ user: response })),
          catchError((error) => of(UsersActions.addUserFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addUserSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersFacade = inject(UsersFacade)) =>
    actions$.pipe(
      ofType(UsersActions.addUserSuccess),
      tap(({ user }) => usersFacade.addUserToCurrentGrid(user))
    ),
  { functional: true, dispatch: false }
);

const updateUser$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.updateUser),
      concatMap(({ user }) =>
        usersService.updateUser(user).pipe(
          map((response: UserAccount) => UsersActions.updateUserSuccess({ user: response })),
          catchError((error) => of(UsersActions.updateUserFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateUserSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersFacade = inject(UsersFacade)) =>
    actions$.pipe(
      ofType(UsersActions.updateUserSuccess),
      tap(({ user }) => usersFacade.updateUserInCurrentGrid(user))
    ),
  { functional: true, dispatch: false }
);

const enableUser$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.enableUser),
      concatMap(({ user }) =>
        usersService.enableUser(user).pipe(
          map((response: UserAccount) => UsersActions.enableUserSuccess({ user: response })),
          catchError((error) => of(UsersActions.enableUserFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const enableUserSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersFacade = inject(UsersFacade)) =>
    actions$.pipe(
      ofType(UsersActions.enableUserSuccess),
      tap(({ user }) => usersFacade.updateUserInCurrentGrid(user))
    ),
  { functional: true, dispatch: false }
);

const disableUser$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.disableUser),
      concatMap(({ user }) =>
        usersService.disableUser(user).pipe(
          map((response: UserAccount) => UsersActions.disableUserSuccess({ user: response })),
          catchError((error) => of(UsersActions.disableUserFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const disableUserSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersFacade = inject(UsersFacade)) =>
    actions$.pipe(
      ofType(UsersActions.disableUserSuccess),
      tap(({ user }) => usersFacade.updateUserInCurrentGrid(user))
    ),
  { functional: true, dispatch: false }
);

const resendUserValidationLink$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.resendUserValidationLink),
      concatMap(({ user }) =>
        usersService.resendValidationLink(user).pipe(
          map((response: UserAccount) => UsersActions.resendUserValidationLinkSuccess({ user: response })),
          catchError((error) => of(UsersActions.resendUserValidationLinkFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const resendUserValidationLinkSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersFacade = inject(UsersFacade)) =>
    actions$.pipe(
      ofType(UsersActions.resendUserValidationLinkSuccess),
      tap(({ user }) => usersFacade.updateUserInCurrentGrid(user))
    ),
  { functional: true, dispatch: false }
);

const getBusinessProfilesByUserId$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), usersService = inject(UsersService)) =>
    actions$.pipe(
      ofType(UsersActions.getBusinessProfilesByUserId),
      concatMap(({ userId }) =>
        usersService.getBusinessProfilesByUserId(userId).pipe(
          map((response: BusinessProfile[]) => UsersActions.getBusinessProfilesByUserIdSuccess({ businessProfiles: response })),
          catchError((error) => of(UsersActions.getBusinessProfilesByUserIdFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const navigateToUserDetails$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(UsersActions.navigateToUserDetails),
      map((action) => action.userId),
      tap((id) => {
        router.navigate(['admin', 'users', id]);
      })
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        UsersActions.getUserById,
        UsersActions.addUser,
        UsersActions.updateUser,
        UsersActions.enableUser,
        UsersActions.disableUser,
        UsersActions.resendUserValidationLink,
        UsersActions.getBusinessProfilesByUserId
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        UsersActions.getUserByIdSuccess,
        UsersActions.getUserByIdFailure,
        UsersActions.addUserSuccess,
        UsersActions.addUserFailure,
        UsersActions.updateUserSuccess,
        UsersActions.updateUserFailure,
        UsersActions.enableUserSuccess,
        UsersActions.enableUserFailure,
        UsersActions.disableUserSuccess,
        UsersActions.disableUserFailure,
        UsersActions.resendUserValidationLinkSuccess,
        UsersActions.resendUserValidationLinkFailure,
        UsersActions.getBusinessProfilesByUserIdSuccess,
        UsersActions.getBusinessProfilesByUserIdFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        UsersActions.getUserByIdFailure,
        UsersActions.addUserFailure,
        UsersActions.updateUserFailure,
        UsersActions.enableUserFailure,
        UsersActions.disableUserFailure,
        UsersActions.resendUserValidationLinkFailure,
        UsersActions.getBusinessProfilesByUserIdFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        UsersActions.addUserSuccess,
        UsersActions.updateUserSuccess,
        UsersActions.enableUserSuccess,
        UsersActions.disableUserSuccess,
        UsersActions.resendUserValidationLinkSuccess
      ),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const UsersEffects = {
  getUserById$,
  addUser$,
  addUserSuccess$,
  updateUser$,
  updateUserSuccess$,
  enableUser$,
  enableUserSuccess$,
  disableUser$,
  disableUserSuccess$,
  resendUserValidationLink$,
  resendUserValidationLinkSuccess$,
  navigateToUserDetails$,
  getBusinessProfilesByUserId$,
  showLoader$,
  hideLoader$,
  displayError$,
  displaySuccess$
};
