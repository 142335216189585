import { UpperCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatToolbar } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

export function existLanguageValidator(languages: string[]): ValidatorFn {
  return (
    control: AbstractControl
  ): {
    [key: string]: unknown;
  } | null => (languages.includes(control.value) ? { exist: { value: control.value } } : null);
}

@Component({
  selector: 'i18n-translation-create-form',
  templateUrl: './translation-create-form.component.html',
  styleUrls: ['./translation-create-form.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatToolbar,
    FlexLayoutModule,
    MatIconButton,
    MatIcon,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatCardActions,
    MatButton,
    UpperCasePipe,
    TranslateModule
  ]
})
export class TranslationCreateFormComponent implements OnInit {
  public createForm: UntypedFormGroup;

  constructor(
    public matDialogRef: MatDialogRef<TranslationCreateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { languages: string[] }
  ) {}

  public get languageKey(): AbstractControl {
    return this.createForm.get('key');
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public initForm(): void {
    this.createForm = new UntypedFormGroup({
      key: new UntypedFormControl('', [Validators.required, existLanguageValidator(this.data.languages)])
    });
  }

  public save(): void {
    if (this.createForm.valid) {
      this.matDialogRef.close(this.createForm.value.key);
    }
  }
}
