import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthBusinessProfilesPageActions } from '../state/actions/auth-business-profiles-page.actions';
import { AuthPageActions } from '../state/actions/auth-page.actions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private readonly router: Router = inject(Router);
  private readonly store: Store = inject(Store);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly authService: AuthService = inject(AuthService);

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    if ('refreshToken' in next.queryParams) {
      const { userId, businessProfileId } = this.collectRouteParams(next);
      return this.authService.signWithRefreshToken(next.queryParams.refreshToken, userId).pipe(
        map((success: boolean) => {
          if (!success) {
            this.signOut();
            return false;
          }
          const bp: Partial<BusinessProfile> = { id: businessProfileId };
          this.store.dispatch(
            AuthBusinessProfilesPageActions.selectBusinessProfile({
              selectedBusinessProfile: bp as BusinessProfile,
              withRedirect: false
            })
          );
          return true;
        })
      );
    } else if (
      (this.storage.get(LocalStorageKeys.STORAGE_ID_TOKEN_KEY) || this.storage.get(LocalStorageKeys.STORAGE_SSO_TOKEN_ID)) &&
      this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)
    ) {
      return of(true);
    } else {
      this.signOut();
      return of(false);
    }
  }

  private collectRouteParams(activatedRoute: ActivatedRouteSnapshot): Params {
    let route = activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return get(route, 'params', {});
  }

  private signOut(): void {
    this.store.dispatch(AuthPageActions.signOut());
    this.router.navigate(['/login']);
  }
}
