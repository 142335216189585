import { TagCategory } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminOrganizationsTagsApiActions, AdminOrganizationsTagsPageActions } from '../actions';

export const adminOrganizationsTagsApiFeatureKey = 'adminOrganizationsTagsApi';

export interface State extends EntityState<TagCategory> {
  selectedTagId: string | null;
  inEditModeTags?: TagCategory[];
}

function sortByName(a: TagCategory, b: TagCategory): number {
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
}

export const adapter: EntityAdapter<TagCategory> = createEntityAdapter<TagCategory>({
  selectId: (tag: TagCategory) => tag.id,
  sortComparer: sortByName
});

export const initialState: State = adapter.getInitialState({
  selectedTagId: null,
  inEditModeTags: []
});

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsTagsPageActions.listTagsByOrganization, (state: State) => adapter.removeAll(state)),
  on(AdminOrganizationsTagsApiActions.listTagsByOrganizationSuccess, (state: State, { tags }) => adapter.setAll(tags, state)),
  on(AdminOrganizationsTagsApiActions.listTagsByOrganizationFailure, (state: State, { error }) => ({ ...state, error })),

  on(AdminOrganizationsTagsPageActions.listTagsByOrganizationForEdition, (state: State) => ({ ...state, inEditModeTags: [] })),
  on(AdminOrganizationsTagsApiActions.listTagsByOrganizationForEditionSuccess, (state: State, { tags }) => ({ ...state, inEditModeTags: tags })),
  on(AdminOrganizationsTagsApiActions.listTagsByOrganizationForEditionFailure, (state: State, { error }) => ({ ...state, error })),

  on(AdminOrganizationsTagsApiActions.addTagToOrganizationSuccess, (state, { newTag }) => adapter.addOne(newTag, state)),
  on(AdminOrganizationsTagsApiActions.removeTagFromOganizationSuccess, (state, { removedTag }) => adapter.removeOne(removedTag.id, state)),
  on(AdminOrganizationsTagsApiActions.updateTagInOrganizationSuccess, (state, { updatedTag }) => adapter.updateOne(updatedTag, state)),

  on(AdminOrganizationsTagsApiActions.addTagToOrganizationForEditionSuccess, (state, { newTag }) => ({
    ...state,
    inEditModeTags: [...state.inEditModeTags, newTag]
  })),
  on(AdminOrganizationsTagsApiActions.removeTagFromOganizationForEditionSuccess, (state, { removedTag }) => {
    const toRemoveIndex = state.inEditModeTags.findIndex((tag) => tag.id === removedTag.id);
    const tags = [...state.inEditModeTags];
    tags.splice(toRemoveIndex, 1);
    return {
      ...state,
      inEditModeTags: [...tags]
    };
  }),
  on(AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionSuccess, (state, { updatedTag }) => {
    const toUpdateIndex = state.inEditModeTags.findIndex((tag) => tag.id === updatedTag.id);
    const tags = [...state.inEditModeTags];
    tags.splice(toUpdateIndex, 1, updatedTag.changes);
    return {
      ...state,
      inEditModeTags: [...tags]
    };
  }),
  on(AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionFailure, (state: State, { error }) => ({ ...state, error }))
);

export const getSelectedId = (state: State) => state.selectedTagId;

export const getInEditModeTags = (state: State) => {
  const fullySortedTags: TagCategory[] = [...state.inEditModeTags];
  fullySortedTags.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
  fullySortedTags.forEach((category: TagCategory) => {
    category.labels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
  });
  return fullySortedTags;
};
