import { TagCategory } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAdminOrganizationsAdministratorsApi from './admin-organizations-administrators-api.reducer';
import * as fromAdminOrganizationsAdministratorsPage from './admin-organizations-administrators-page.reducer';
import * as fromAdminOrganizationsApi from './admin-organizations-api.reducer';
import * as fromAdminOrganizationsBusinessProfilesApi from './admin-organizations-business-profiles-api.reducer';
import * as fromAdminOrganizationsBusinessProfilesPage from './admin-organizations-business-profiles-page.reducer';
import * as fromAdminOrganizationsPage from './admin-organizations-page.reducer';
import * as fromAdminOrganizationsRolesApi from './admin-organizations-roles-api.reducer';
import * as fromAdminOrganizationsRolesPage from './admin-organizations-roles-page.reducer';
import * as fromAdminOrganizationsTagsApi from './admin-organizations-tags-api.reducer';
import * as fromAdminOrganizationsTagsPage from './admin-organizations-tags-page.reducer';

export const adminOrganizationsFeatureKey = 'adminOrganizations';

export interface OrganizationsState {
  [fromAdminOrganizationsPage.adminOrganizationsPageFeatureKey]: fromAdminOrganizationsPage.State;
  [fromAdminOrganizationsApi.adminOrganizationsApiFeatureKey]: fromAdminOrganizationsApi.State;
  [fromAdminOrganizationsRolesApi.adminOrganizationsRolesApiFeatureKey]: fromAdminOrganizationsRolesApi.State;
  [fromAdminOrganizationsRolesPage.adminOrganizationsRolesPageFeatureKey]: fromAdminOrganizationsRolesPage.State;
  [fromAdminOrganizationsBusinessProfilesApi.adminOrganizationsBusinessProfilesApiFeatureKey]: fromAdminOrganizationsBusinessProfilesApi.State;
  [fromAdminOrganizationsBusinessProfilesPage.adminOrganizationsBusinessProfilesPageFeatureKey]: fromAdminOrganizationsBusinessProfilesPage.State;
  [fromAdminOrganizationsTagsApi.adminOrganizationsTagsApiFeatureKey]: fromAdminOrganizationsTagsApi.State;
  [fromAdminOrganizationsTagsPage.adminOrganizationsTagsPageFeatureKey]: fromAdminOrganizationsTagsPage.State;
  [fromAdminOrganizationsAdministratorsApi.adminOrganizationsAdministratorsApiFeatureKey]: fromAdminOrganizationsAdministratorsApi.State;
  [fromAdminOrganizationsAdministratorsPage.adminOrganizationsAdministratorsPageFeatureKey]: fromAdminOrganizationsAdministratorsPage.State;
}

export interface State {
  [adminOrganizationsFeatureKey]: OrganizationsState;
}

export function reducers(state: OrganizationsState | undefined, action: Action) {
  return combineReducers({
    [fromAdminOrganizationsPage.adminOrganizationsPageFeatureKey]: fromAdminOrganizationsPage.reducer,
    [fromAdminOrganizationsApi.adminOrganizationsApiFeatureKey]: fromAdminOrganizationsApi.reducer,
    [fromAdminOrganizationsRolesApi.adminOrganizationsRolesApiFeatureKey]: fromAdminOrganizationsRolesApi.reducer,
    [fromAdminOrganizationsRolesPage.adminOrganizationsRolesPageFeatureKey]: fromAdminOrganizationsRolesPage.reducer,
    [fromAdminOrganizationsBusinessProfilesApi.adminOrganizationsBusinessProfilesApiFeatureKey]: fromAdminOrganizationsBusinessProfilesApi.reducer,
    [fromAdminOrganizationsBusinessProfilesPage.adminOrganizationsBusinessProfilesPageFeatureKey]: fromAdminOrganizationsBusinessProfilesPage.reducer,
    [fromAdminOrganizationsTagsApi.adminOrganizationsTagsApiFeatureKey]: fromAdminOrganizationsTagsApi.reducer,
    [fromAdminOrganizationsTagsPage.adminOrganizationsTagsPageFeatureKey]: fromAdminOrganizationsTagsPage.reducer,
    [fromAdminOrganizationsAdministratorsApi.adminOrganizationsAdministratorsApiFeatureKey]: fromAdminOrganizationsAdministratorsApi.reducer,
    [fromAdminOrganizationsAdministratorsPage.adminOrganizationsAdministratorsPageFeatureKey]: fromAdminOrganizationsAdministratorsPage.reducer
  })(state, action);
}

export const selectAdminOrganizationsState = createFeatureSelector<OrganizationsState>(adminOrganizationsFeatureKey);

export const selectAdminOrganizationsApiState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsApi.adminOrganizationsApiFeatureKey]
);

export const selectAdminOrganizationsPageState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsPage.adminOrganizationsPageFeatureKey]
);

export const selectAdminOrganizationsRolesApiState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsRolesApi.adminOrganizationsRolesApiFeatureKey]
);

export const selectAdminOrganizationsRolesPageState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsRolesPage.adminOrganizationsRolesPageFeatureKey]
);

export const selectAdminOrganizationsBusinessProfilesApiState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsBusinessProfilesApi.adminOrganizationsBusinessProfilesApiFeatureKey]
);

export const selectAdminOrganizationsBusinessProfilesPageState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsBusinessProfilesPage.adminOrganizationsBusinessProfilesPageFeatureKey]
);

export const selectAdminOrganizationsTagsApiState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsTagsApi.adminOrganizationsTagsApiFeatureKey]
);

export const selectAdminOrganizationsTagsPageState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsTagsPage.adminOrganizationsTagsPageFeatureKey]
);

export const selectAdminOrganizationsAdministratorsApiState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsAdministratorsApi.adminOrganizationsAdministratorsApiFeatureKey]
);

export const selectAdminOrganizationsAdministratorsPageState = createSelector(
  selectAdminOrganizationsState,
  (state: OrganizationsState) => state[fromAdminOrganizationsAdministratorsPage.adminOrganizationsAdministratorsPageFeatureKey]
);

// ORGANIZATIONS
export const {
  selectIds: getOrganizationsIds,
  selectEntities: getOrganizationsEntities,
  selectAll: getAllOrganizations,
  selectTotal: getTotalOrganizations
} = fromAdminOrganizationsApi.adapter.getSelectors(selectAdminOrganizationsApiState);

// ROLES
export const {
  selectIds: getRolesIds,
  selectEntities: getRolesEntities,
  selectAll: getAllRoles,
  selectTotal: getTotalRoles
} = fromAdminOrganizationsRolesApi.adapter.getSelectors(selectAdminOrganizationsRolesApiState);

// BUSINESS PROFILES
export const {
  selectIds: getBusinessProfilesIds,
  selectEntities: getBusinessProfilesEntities,
  selectAll: getAllBusinessProfiles,
  selectTotal: getTotalBusinessProfiles
} = fromAdminOrganizationsBusinessProfilesApi.adapter.getSelectors(selectAdminOrganizationsBusinessProfilesApiState);

// TAGS
export const {
  selectIds: getTagsIds,
  selectEntities: getTagsEntities,
  selectAll: getAllTags,
  selectTotal: getTotalTags
} = fromAdminOrganizationsTagsApi.adapter.getSelectors(selectAdminOrganizationsTagsApiState);

// ADMINISTRATORS
export const {
  selectIds: getAdministratorsIds,
  selectEntities: getAdministratorsEntities,
  selectAll: getAllAdministrators,
  selectTotal: getTotalAdministrators
} = fromAdminOrganizationsAdministratorsApi.adapter.getSelectors(selectAdminOrganizationsAdministratorsApiState);

export const getSelectedOrganizationId = createSelector(selectAdminOrganizationsApiState, fromAdminOrganizationsApi.getSelectedId);

// export const getSelectedOrganization = createSelector(
//   getOrganizationsEntities,
//   getSelectedOrganizationId,
//   (entities, selectedId) => {
//     return selectedId && entities[selectedId];
//   }
// );

export const getSelectedOrganization = createSelector(selectAdminOrganizationsApiState, fromAdminOrganizationsApi.getSelectedOrganization);

export const getSelectedRoleId = createSelector(selectAdminOrganizationsRolesApiState, fromAdminOrganizationsRolesApi.getSelectedId);

export const getSelectedRole = createSelector(getRolesEntities, getSelectedRoleId, (entities, selectedId) => selectedId && entities[selectedId]);

export const getAdminOrganizationsPageError = createSelector(selectAdminOrganizationsPageState, fromAdminOrganizationsPage.getError);

export const getAdminOrganizationsPagePending = createSelector(selectAdminOrganizationsPageState, fromAdminOrganizationsPage.getPending);

export const getAdministratorsPendingStatus = createSelector(
  selectAdminOrganizationsAdministratorsPageState,
  fromAdminOrganizationsAdministratorsPage.getPending
);

export const getBusinessProfilesPendingStatus = createSelector(
  selectAdminOrganizationsBusinessProfilesPageState,
  fromAdminOrganizationsBusinessProfilesPage.getPending
);

export const getRolesPendingStatus = createSelector(selectAdminOrganizationsRolesPageState, fromAdminOrganizationsRolesPage.getPending);

export const getTagsPendingStatus = createSelector(selectAdminOrganizationsTagsPageState, fromAdminOrganizationsTagsPage.getPending);

export const getInEditModeTags = createSelector(selectAdminOrganizationsTagsApiState, fromAdminOrganizationsTagsApi.getInEditModeTags);

export const getAllTagsWithSortedLabels = createSelector(getAllTags, (tagCategories: TagCategory[]) => {
  tagCategories.forEach((category: TagCategory) => {
    category.labels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
  });
  return tagCategories;
});
