import { BaseState, FavoriteView, Filter, INITIAL_BASE_STATE, Pagination, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { SitesDbActions } from '../actions';

export interface State extends BaseState<Site, Pagination, Filter> {
  tags: TagCategory[];
  assetsBySite: PlatformResponse;
  devicesBySite: PlatformResponse;
  currentFavoriteView: FavoriteView | null;
  assetsLoaded: boolean;
  assetsLoading: boolean;
  devicesLoaded: boolean;
  devicesLoading: boolean;
  tagsLoaded: boolean;
  tagsLoading: boolean;
}

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>({
  selectId: (site: Site) => site.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  tags: [],
  assetsBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 },
  devicesBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 },
  currentFavoriteView: null,
  assetsLoaded: false,
  assetsLoading: false,
  devicesLoaded: false,
  devicesLoading: false,
  tagsLoaded: false,
  tagsLoading: false
});

export const sitesV2feature = createFeature({
  name: 'sitesV2',
  reducer: createReducer(
    initialState,
    on(SitesDbActions.addSiteSuccess, (state: State, { siteAdded }) => adapter.addOne(siteAdded, state)),
    on(SitesDbActions.updateSiteSuccess, (state: State, { updatedSite }) => adapter.updateOne({ changes: updatedSite, id: updatedSite.id as string }, state)),
    on(SitesDbActions.deleteSiteSuccess, (state: State, { deletedSite }) => adapter.removeOne(deletedSite.id as string, state)),

    on(SitesDbActions.loadSiteById, (state: State) => ({ ...state, selectedId: null })),
    on(SitesDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, selectedId: site.id as string })),
    //
    on(SitesDbActions.loadAssetsBySiteId, (state: State) => ({
      ...state,
      assetsBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 }
    })),
    on(SitesDbActions.loadAssetsBySiteIdSuccess, (state: State, { response }) => ({ ...state, assetsBySite: response })),
    //
    on(SitesDbActions.loadDevicesBySiteId, (state: State) => ({
      ...state,
      devicesBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 }
    })),
    on(SitesDbActions.loadDevicesBySiteIdSuccess, (state: State, { response }) => ({ ...state, devicesBySite: response })),
    //
    on(SitesDbActions.loadTagsBySiteId, (state: State) => ({ ...state, tags: [] })),
    on(SitesDbActions.loadTagsBySiteIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
    //
    on(SitesDbActions.updateTagsBySiteIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
    //
    on(SitesDbActions.selectSiteSuccess, (state: State, { selectedSite }) => ({ ...state, selectedId: selectedSite.id as string })),
    //
    on(SitesDbActions.saveCurrentFavoriteView, (state: State, { currentFavoriteView }) => ({ ...state, currentFavoriteView })),
    //
    on(SitesDbActions.openSiteDetail, (state: State, { siteId }) => ({ ...state, selectedId: siteId })),
    //
    on(SitesDbActions.setFilters, (state: State, { filters }): State => ({ ...state, filters })),
    //
    on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),

    // UI

    on(SitesDbActions.loadSiteById, (state: State) => ({ ...state, siteLoaded: false, siteLoading: true })),
    on(SitesDbActions.loadSiteByIdSuccess, (state: State) => ({ ...state, siteLoaded: true, siteLoading: false })),
    on(SitesDbActions.loadSiteByIdFailure, (state: State, { error }) => ({ ...state, siteLoaded: false, siteLoading: false, error })),
    //
    on(SitesDbActions.loadAssetsBySiteId, (state: State) => ({ ...state, assetsLoaded: false, assetsLoading: true })),
    on(SitesDbActions.loadAssetsBySiteIdSuccess, (state: State) => ({ ...state, assetsLoaded: true, assetsLoading: false })),
    on(SitesDbActions.loadAssetsBySiteIdFailure, (state: State, { error }) => ({ ...state, assetsLoaded: false, assetsLoading: false, error })),
    //
    on(SitesDbActions.loadDevicesBySiteId, (state: State) => ({ ...state, devicesLoaded: false, devicesLoading: true })),
    on(SitesDbActions.loadDevicesBySiteIdSuccess, (state: State) => ({ ...state, devicesLoaded: true, devicesLoading: false })),
    on(SitesDbActions.loadDevicesBySiteIdFailure, (state: State, { error }) => ({ ...state, devicesLoaded: false, devicesLoading: false, error })),
    //
    on(SitesDbActions.loadTagsBySiteId, (state: State) => ({ ...state, tagsLoaded: false, tagsLoading: true })),
    on(SitesDbActions.loadTagsBySiteIdSuccess, (state: State) => ({ ...state, tagsLoaded: true, tagsLoading: false })),
    on(SitesDbActions.loadTagsBySiteIdFailure, (state: State, { error }) => ({ ...state, tagsLoaded: false, tagsLoading: false, error })),
    //
    on(SitesDbActions.loadSites, (state: State) => ({ ...state, siteLoading: true, siteLoaded: false })),
    on(SitesDbActions.loadSitesSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
    on(SitesDbActions.loadSitesFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: false, error })),
    //
    on(SitesDbActions.addSite, (state: State) => ({ ...state, siteLoading: true })),
    on(SitesDbActions.addSiteSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
    on(SitesDbActions.addSiteFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: false, error })),
    //
    on(SitesDbActions.updateTagsBySiteId, (state: State) => ({ ...state, tagsLoading: true, tagsLoaded: false })),
    on(SitesDbActions.updateTagsBySiteIdSuccess, (state: State) => ({ ...state, tagsLoading: false, tagsLoaded: true })),
    on(SitesDbActions.updateTagsBySiteIdFailure, (state: State, error) => ({ ...state, tagsLoaded: false, tagsLoading: false, error }))
  )
});
