import { DeviceEvent } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsBySiteDbActions, DeviceEventsBySiteLogsDbActions, DeviceEventsBySiteUiActions, NavigationActions } from '../../actions';

export const deviceEventsBySiteDbFeatureKey = 'deviceEventsBySiteDb';

export interface State extends EntityState<DeviceEvent> {
  selectedDeviceEventId: string | null;
  checkedDeviceEventIds: string[];
  previousSiteId: string | null;
  totalActiveEvents: number;
  error?: any;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  settings?: any;
  status?: DeviceEvent;
}

export const adapter: EntityAdapter<DeviceEvent> = createEntityAdapter<DeviceEvent>({
  selectId: (deviceEvent: DeviceEvent) => deviceEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedDeviceEventId: null,
  checkedDeviceEventIds: [],
  previousSiteId: null,
  totalActiveEvents: 0,
  error: null,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 },
  settings: null
});

const deviceEventsBySiteDbReducer = createReducer(
  initialState,
  on(NavigationActions.clearData, () => initialState),

  on(DeviceEventsBySiteUiActions.loadDeviceEventsBySite, (state: State) => ({ ...state })),
  on(DeviceEventsBySiteDbActions.loadDeviceEventsBySiteSuccess, (state: State, { response, siteId }) => {
    const newState = { ...state };
    if (!state.previousSiteId || (state.previousSiteId && state.previousSiteId !== siteId)) {
      newState.previousSiteId = siteId;
    }
    return adapter.setAll(response.data, {
      ...newState,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    });
  }),
  // ****
  on(DeviceEventsBySiteDbActions.loadTotalActiveDeviceEventsBySiteSuccess, (state: State, { totalActiveEvents }) => ({ ...state, totalActiveEvents })),
  // ****
  on(DeviceEventsBySiteLogsDbActions.createLogByDeviceEventSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: { totalComments: state.entities[state.selectedDeviceEventId].totalComments + 1 } }, state)
  ),
  // ****
  on(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: deviceEvent }, { ...state, status: deviceEvent })
  ),
  on(DeviceEventsBySiteDbActions.bulkUpdateStatusByDeviceEventIdBySiteSuccess, (state: State, { deviceEvents }) => {
    const updates: { id: string; changes: any }[] = deviceEvents.reduce((acc, value) => {
      acc.push({ id: value.id, changes: value });
      return acc;
    }, []);

    return adapter.updateMany(updates, { ...state, status: deviceEvents[0] });
  }),
  on(DeviceEventsBySiteDbActions.saveTableBySiteStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedDeviceEventId: selectedId,
    checkedDeviceEventIds: checkedIds
  })),
  on(DeviceEventsBySiteDbActions.loadMvDeviceEventsBySiteSettingsSuccess, (state: State, { settings }) => ({ ...state, settings }))
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsBySiteDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedDeviceEventId;
export const getCheckedIds = (state: State) => state.checkedDeviceEventIds;
export const getPreviousSiteId = (state: State) => state.previousSiteId;
export const getTotalActiveEvents = (state: State) => state.totalActiveEvents;
export const getMvSettings = (state: State) => state.settings;
export const getStatus = (state: State) => state.status;
