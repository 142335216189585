import { BusinessProfile } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listBusinessProfilesByOrganizationSuccess = createAction(
  '[Organizations API] get Business Profiles By Organization Success',
  props<{ businessProfiles: BusinessProfile[] }>()
);
export const listBusinessProfilesByOrganizationFailure = createAction(
  '[Organizations API] get Business Profiles By Organization  Failure',
  props<{ error: any }>()
);
