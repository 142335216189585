import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
  standalone: true,
  imports: [InfoDisplayPipe],
  providers: [InfoDisplayPipe],
  selector: 'grid-engine-basic-link-cell',
  templateUrl: './basic-link-cell.component.html',
  styleUrls: ['./basic-link-cell.component.scss']
})
export class BasicLinkCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent) {
    event.stopPropagation();
    const params = this.params();
    params.dispatchEvent({ type: params.eventConfiguration.type, options: params.eventConfiguration.options, rawData: params.data });
  }
}
