import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { AbstractDebounceDirective } from '../abstract-debounce/abstract-debounce.directive';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[restrictedInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RestrictInputDirective),
      multi: true
    }
  ]
})
export class RestrictInputDirective extends AbstractDebounceDirective implements ControlValueAccessor {
  regex!: RegExp;
  patternEnabled = false;
  uppercaseEnabled = false;
  preValue: string | number = '';

  constructor(
    public elementRef: ElementRef,
    private renderer2: Renderer2
  ) {
    super();
  }

  @Input() set pattern(value: string | RegExp) {
    this.patternEnabled = !!value;
    this.regex = value instanceof RegExp ? value : new RegExp(value);
  }

  @Input() set uppercase(value: boolean) {
    this.uppercaseEnabled = !!value;
  }

  public onChange = (_: any) => noop();
  public onTouch = () => noop();

  @HostListener('input', ['$event'])
  public onInput(e: any): void {
    e.preventDefault();
    this.emitEvent$.next(e);
  }

  @HostListener('change', ['$event'])
  public onInputChange(e: any): void {
    e.preventDefault();
    this.emitEvent$.next(e);
  }

  onEvent(e: any): void {
    this.writeValue(e?.target?.value);
  }

  @HostListener('blur', ['$event'])
  public onBlur(): void {
    this.onTouch();
  }

  public validateValue(value: string) {
    return this.regex?.test(value);
  }

  /** It writes the value in the input */
  public writeValue(value: string | number): void {
    if (this.patternEnabled) {
      if (!this.regex) {
        throw new Error('No pattern found');
      }
      if (this.validateValue(value as string)) {
        this.preValue = value;
      } else {
        value = this.preValue;
      }
    }
    if (this.uppercaseEnabled) {
      value = value?.toString()?.toUpperCase();
    }
    try {
      this.onChange(value);
    } catch (ex) {
      /* empty */
    }
    this.renderer2.setProperty(this.elementRef.nativeElement, 'value', value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
