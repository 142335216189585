import { CommonGenericModel, Entity, FormulaType, LastValue, ProductCatalog, TagCategory } from '../common';
import { AssetVariableThresholds } from './asset-variable-thresholds.model';
import { DeviceVariable } from './device-variable.model';
import { Formula, FormulaSourceVariable } from './formula.model';

export interface AssetTemplate extends CommonGenericModel {
  name: string;
  category: string;
  entity: Entity;
  entityPath: Entity[];
  description: string;
  template: {
    name: string;
    description: string;
    imageUrl: string;
    businessId: string;
    shipTo: string;
    quantity: string;
    quantity1: string;
    quantity2: string;
    deliveryDate: string;
    product: {
      id: string;
      identifier: string;
      name: string;
      catalog: ProductCatalog;
    };
    product1: {
      id: string;
      identifier: string;
      name: string;
      catalog: ProductCatalog;
    };
    product2: {
      id: string;
      identifier: string;
      name: string;
      catalog: ProductCatalog;
    };
    optionalProperties: { [propertyName: string]: boolean };
    tags: TagCategory[];
    variables: AssetTemplateVariable[];
  };
  isEditable?: boolean;
}

export interface AssetTemplateVariable {
  id?: string;
  name: string;
  comment: string;
  unit: string;
  followedNumber: number | null;
  formula: AssetTemplateVariableFormula;
  thresholds: AssetVariableThresholds;
}

export interface AssetTemplateVariableFormula extends Formula {
  srcVariables: {
    [name: string]: TemplateSuggestedVariable;
  };
  evaluation?: {
    sourceVariable?: DeviceVariable;
    lastRecords: LastValue[];
  };
}

export interface TemplateSuggestedVariable extends FormulaSourceVariable {
  match: {
    type: TemplateSuggestedVariableMatchingType;
    suggestedNames: string[] | null;
    matchingVariables?: DeviceVariable[];
  } | null;
  lastRecords?: LastValue[] | null;
}

export const ASSET_TEMPLATE_VARIABLE_FORMULA_TYPE: string[] = [
  FormulaType.EQUAL,
  FormulaType.MIN_MAX,
  FormulaType.MIN_MAX_ADVANCED,
  FormulaType.SLOPE_SHIFT,
  FormulaType.LINEARIZATION,
  FormulaType.CONSTANT
];

export enum TemplateSuggestedVariableMatchingType {
  NONE = 'NONE',
  EXACT = 'EXACT',
  BEGINS_WITH = 'BEGINS_WITH'
}

export const ASSOCIATION_RULES: string[] = [
  TemplateSuggestedVariableMatchingType.NONE,
  TemplateSuggestedVariableMatchingType.EXACT,
  TemplateSuggestedVariableMatchingType.BEGINS_WITH
];
