import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const assetVariablesConfigurationPopupColumnSettings = [
  {
    order: 20,
    id: 'name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_NAME',
    catalogName: 'VARIABLE NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '25%',
    type: 'start center'
  },
  {
    order: 30,
    id: 'comment',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMENT',
    catalogName: 'COMMENT',
    default: true,
    sortable: true,
    sortProperty: 'comment',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '30%',
    type: 'start center'
  },
  {
    order: 40,
    id: 'lastValue',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VALUE',
    catalogName: 'VALUE',
    default: true,
    sortable: true,
    sortProperty: 'value',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.RICH_VARIABLE,
    cellTypeOptions: {
      dataType: 'lastValue',
      unit: 'unit'
    },
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '25%',
    type: 'start center'
  },
  {
    order: 50,
    id: 'lastValue.datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TIME',
    catalogName: 'TIME',
    default: true,
    sortable: false,
    sortProperty: 'lastValue.datetime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    width: '25%',
    type: 'start center'
  },
  {
    order: 90,
    id: 'id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_ID',
    catalogName: 'VARIABLE TECHNICAL ID',
    default: false,
    sortable: true,
    sortProperty: 'id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '15%',
    type: 'start center'
  },
  {
    order: 70,
    id: 'threshold1',
    name: '',
    catalogName: 'THRESHOLDS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: 'thresholdIconCell',
    isLink: false,
    width: '5%',
    type: 'center center'
  }
];
