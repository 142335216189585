export interface PoEventAlgorithm {
  concepts: string[];
  defaultClass: string;
  isBackableToNormal: boolean;
  name: string;
  params: any[];
  severity: string;
  supportedClasses: string[];
}

export enum PoEventRuleAlgos {
  COUNTING_HOUR_CONSISTENCY = 'Counting Hour Consistency',
  CYCLICAL_CALL_FAULT = 'Cyclical Call Fault',
  DECREASE_COUNTING_VALUE = 'Decrease Counting Value',
  ERRATIC_LEVEL = 'Erratic Level',
  FORECAST_FOR_PG = 'Forecast For Pg',
  INVARIANCE = 'Invariance',
  MISSING_VALUE = 'Missing Value',
  RANGE = 'Range',
  THRESHOLD_CROSSED = 'Threshold Crossed',
  VARIATION_DETECTION = 'Variation Detection'
}
