<div class="detail-card" fxLayout="column" fxLayoutGap="4px">
  <ul class="detail-card-list">
    <li attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.NAME' | translate }}"
        class="detail-card-item">{{ asset()?.name | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.SHIP_TO' | translate }}" class="detail-card-item">
      {{ asset()?.erpReference.shipTo | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.VARIABLE' | translate }}" class="detail-card-item">
      {{ assetVariable()?.name | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.EVENT_VALUE' | translate }}" class="detail-card-item">
      @if (eventVariableProcessedUnit()) {
        <span> {{ eventVariableProcessedUnit() }} </span>
      } @else {
        <span> {{ event()?.context?.assetVariable?.value | numberFormat | infoDisplay }} {{ event()?.context?.assetVariable?.unit }} </span>
      }
      @if (event()?.context?.assetVariable?.value) {
        <span>({{ event()?.occurrenceTime | dateFormat }})</span>
      }
    </li>
    <li attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.COMMENT' | translate }}" class="detail-card-item">
      {{ assetVariable()?.comment | infoDisplay }}
    </li>
  </ul>
  <ul class="detail-card-list">
    <li attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.CURRENT_VALUE' | translate }}"
        class="detail-card-item">
      <span class="detail-asset-variable-card-value"> {{ assetVariable() | valueUnitFormat }} </span>
      <span>({{ assetVariable()?.lastValue?.datetime | dateFormat | infoDisplay }})</span>
    </li>
  </ul>
</div>
