import { BusinessProfile } from '@iot-platform/models/common';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthBusinessProfilesPageActions = createActionGroup({
  source: '[Auth] Business Profiles Page Actions',
  events: {
    loadBusinessProfiles: emptyProps(),
    selectBusinessProfile: props<{ selectedBusinessProfile: BusinessProfile; withRedirect: boolean }>()
  }
});
