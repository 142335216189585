import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { CalendarsDbActions, CalendarsUiActions } from '../actions';

export const calendarDaysOffUiFeatureKey = 'calendarDaysOffUi';

export interface State {
  error?: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false,
  loading: false
};

const calendarDaysOffUiReducer = createReducer(
  initialState,
  on(
    CalendarsUiActions.listDaysOffByCalendarId,
    CalendarsUiActions.addDayOff,
    CalendarsUiActions.updateDayOff,
    CalendarsUiActions.deleteDayOff,
    CalendarsUiActions.importDaysOffByCalendarId,
    (state: State) => ({ ...state, loading: true, loaded: false })
  ),
  on(
    CalendarsDbActions.listDaysOffByCalendarIdSuccess,
    CalendarsDbActions.addDayOffSuccess,
    CalendarsDbActions.updateDayOffSuccess,
    CalendarsDbActions.deleteDayOffSuccess,
    CalendarsDbActions.importDaysOffByCalendarIdSuccess,
    (state: State) => ({ ...state, loading: false, loaded: true })
  ),
  on(
    CalendarsDbActions.listDaysOffByCalendarIdFailure,
    CalendarsDbActions.addDayOffSuccess,
    CalendarsDbActions.updateDayOffFailure,
    CalendarsDbActions.deleteDayOffFailure,
    CalendarsDbActions.importDaysOffByCalendarIdFailure,
    (state: State) => ({ ...state, loading: false, loaded: true })
  ),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return calendarDaysOffUiReducer(state, action);
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getError = (state: State) => state.error;
