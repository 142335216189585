import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsByTopicLogsDbActions, DeviceEventsByTopicLogsUiActions } from '../../actions';
//
export const deviceEventsByTopicLogsUiFeatureKey = 'deviceEventsByTopicLogsUi';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: unknown;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null
};

const deviceEventsByDeviceLogsUiReducer = createReducer(
  initialState,
  on(DeviceEventsByTopicLogsUiActions.loadLogsByDeviceEvent, (state: State): State => ({ ...state, loading: true, loaded: false, error: null })),
  on(DeviceEventsByTopicLogsDbActions.loadLogsByDeviceEventSuccess, (state: State): State => ({ ...state, loading: false, loaded: true })),
  on(DeviceEventsByTopicLogsDbActions.loadLogsByDeviceEventFailure, (state: State, { error }): State => ({ ...state, loading: false, loaded: false, error })),
  // ****
  on(DeviceEventsByTopicLogsUiActions.createLogByDeviceEvent, (state: State): State => ({ ...state, loading: true, loaded: false, error: null })),
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventSuccess, (state: State): State => ({ ...state, loading: false, loaded: true })),
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventFailure, (state: State, { error }): State => ({ ...state, loading: false, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (): State => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByDeviceLogsUiReducer(state, action);
}

export const getLogsLoading = (state: State) => state.loading;
export const getLogsLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
