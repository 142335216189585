import { Log } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsByTopicLogsDbActions, DeviceEventsByTopicLogsUiActions } from '../../actions';
//
export const deviceEventsByTopicLogsDbFeatureKey = 'deviceEventsByTopicLogsDb';

export interface State extends EntityState<Log> {
  selectedLogId: string | null;
  error?: any;
  log?: Log;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (log: Log) => log.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedLogId: null,
  error: null,
  log: null
});

const deviceEventsByDeviceLogsDbReducer = createReducer(
  initialState,

  on(DeviceEventsByTopicLogsUiActions.loadLogsByDeviceEvent, (state: State): State => ({ ...state })),
  on(DeviceEventsByTopicLogsDbActions.loadLogsByDeviceEventSuccess, (state: State, { logs }): State => adapter.setAll(logs, state)),
  on(DeviceEventsByTopicLogsDbActions.loadLogsByDeviceEventFailure, (state: State, { error }): State => ({ ...state, error })),
  // ****
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventSuccess, (state: State, { log }): State => adapter.addOne(log, { ...state, log })),
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventFailure, (state: State, { error }): State => ({ ...state, error }))
  // ****
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByDeviceLogsDbReducer(state, action);
}

export const getLog = (state: State) => state.log;
