import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import {
  DataGuardCheck,
  DataGuardCheckDetail,
  DataGuardCheckDetailApiResponse,
  DataGuardCheckError,
  DataGuardCheckStatus
} from '@iot-platform/models/data-guard';
import { Asset, Concept, Site } from '@iot-platform/models/i4b';
import { combineLatest, debounceTime, of, switchMap } from 'rxjs';
import { NavigationApi } from '../../+state/navigation.api';
import { AbstractI4BDataGuardService } from '../../../services/abstract-i4b-data-guard.service';

export interface DataGuardDetailedChecksApiResponseWithVariables extends DataGuardCheckDetailApiResponse {
  variables: { [id: string]: { name: string; checks: { [errorId: string]: DataGuardCheckStatus } } };
}

@Injectable({
  providedIn: 'root'
})
export class DataGuardAssetService extends AbstractI4BDataGuardService<Asset, Site> {
  // Injections
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  // Inheritance implementation
  concept: Concept = Concept.ASSET;
  loadingTrigger = this.navigationApi.dataGuardOpened;
  loadingTrigger$ = toObservable(this.loadingTrigger);
  sourceElementForOverviewChecks = this.navigationApi.site;
  sourceElementForDetailedChecks = this.navigationApi.selectedAsset;
  isOverviewTabActive = signal(false);
  isDetailTabActive = signal(true);
  checkOverviewURL: Signal<string> = computed(() => {
    const site = this.sourceElementForOverviewChecks();
    if (site) {
      return `${this.environment.api.url}${this.environment.api.endpoints.dataGuardChecks}${this.environment.api.endpoints.sites}/${site.id}`;
    }
  });
  checkDetailURL: Signal<string> = computed(() => {
    const asset = this.sourceElementForDetailedChecks();
    if (asset) {
      return `${this.environment.api.url}${this.environment.api.endpoints.assets}/${asset.id}${this.environment.api.endpoints.dataGuardChecks}`;
    }
  });

  checkDetailURL$ = toObservable(this.checkDetailURL);
  sourceElementForDetailedChecks$ = toObservable(this.sourceElementForDetailedChecks);
  isDetailTabDisabled$ = toObservable(this.isDetailTabDisabled);
  detailedChecks = toSignal(
    combineLatest([this.loadingTrigger$, this.checkDetailURL$, this.sourceElementForDetailedChecks$, this.isDetailTabDisabled$]).pipe(
      debounceTime(10),
      switchMap(([trigger, url, element, tabDisabled]) => {
        if (trigger && url && element && !tabDisabled) {
          return this.getDataGuardCheckDetail(element, url);
        } else {
          return of(undefined);
        }
      })
    )
  );
  checkOverviewURL$ = toObservable(this.checkOverviewURL);
  sourceElementForOverviewChecks$ = toObservable(this.sourceElementForOverviewChecks);
  isOverviewTabDisabled$ = toObservable(this.isOverviewTabDisabled);
  overviewChecks = toSignal(
    combineLatest([
      this.loadingTrigger$,
      this.checkOverviewURL$,
      this.sourceElementForOverviewChecks$,
      this.sourceElementForDetailedChecks$,
      this.isOverviewTabDisabled$
    ]).pipe(
      debounceTime(10),
      switchMap(([trigger, url, sourceElementForOverviewChecks, sourceElementForDetailedChecks, tabDisabled]) => {
        if (trigger && url && sourceElementForOverviewChecks && sourceElementForDetailedChecks && !tabDisabled) {
          return this.getDataGuardChecksOverview(sourceElementForOverviewChecks, sourceElementForDetailedChecks, url);
        } else {
          return of(undefined);
        }
      })
    )
  );

  static getChecksStatusesCountsForVariables(allVarErrors: DataGuardDetailedChecksApiResponseWithVariables['variables']): {
    [status in DataGuardCheckStatus]: number;
  } {
    const formattedPayload: {
      [errorKey: string]: DataGuardCheckStatus;
    } = Object.values(allVarErrors).reduce((acc, varChecksInfo) => {
      const varChecks = varChecksInfo.checks;

      const allRenamedCheckErrors = Object.entries(varChecks).reduce((renamedCheckErrors, error) => {
        const renamedCheckError = { [varChecksInfo.name + error[0]]: error[1] };
        return { ...renamedCheckErrors, ...renamedCheckError };
      }, {});
      return { ...acc, ...allRenamedCheckErrors };
    }, {});
    return DataGuardAssetService.getChecksStatusesCounts(formattedPayload);
  }

  closeDataGuard(): void {
    this.navigationApi.toggleDataGuard(false);
  }

  navigateTo(check: DataGuardCheck): void {
    if (check.source.concept === 'asset' && !!check.source.id) {
      this.navigationApi.toggleDataGuard(false);
      this.navigationApi.selectAsset(this.sourceElementForOverviewChecks().id, check.source.id);
    } else if (check.source.concept === 'device') {
      this.navigationApi.toggleDataGuard(false);
      this.navigationApi.selectDevice(this.sourceElementForOverviewChecks().id, check.source.id);
    } else if (check.source.concept === 'site') {
      this.navigationApi.toggleDataGuard(false);
      this.navigationApi.selectLeSite({ id: check.source.id } as Site);
    }
  }

  processCheckDetailsFromApiResponse(apiResponse: DataGuardDetailedChecksApiResponseWithVariables): DataGuardCheckDetail[] {
    const categories = Object.keys(apiResponse.checks);
    const variableErrors: DataGuardDetailedChecksApiResponseWithVariables['variables'] = apiResponse['variables'];

    const defaultDetailedChecks: DataGuardCheckDetail[] = categories.reduce((acc, cat) => {
      if (cat !== 'variable') {
        const checkDetail: DataGuardCheckDetail = {
          category: cat,
          statusCount: DataGuardAssetService.getChecksStatusesCounts(apiResponse.checks[cat]),
          errors: this.getFullCheckErrors(apiResponse.checks[cat])
        };
        acc.push(checkDetail);
      }
      return acc;
    }, []);

    const variablesDetailedChecks: DataGuardCheckDetail[] = [
      {
        category: 'variables',
        statusCount: DataGuardAssetService.getChecksStatusesCountsForVariables(variableErrors),
        errors: this.getFullCheckErrorsForVariables(variableErrors)
      }
    ];

    return defaultDetailedChecks.concat(variablesDetailedChecks);
  }

  getFullCheckErrorsForVariables(allVarErrors: DataGuardDetailedChecksApiResponseWithVariables['variables']): DataGuardCheckError[] {
    return Object.values(allVarErrors).reduce(
      (
        acc: DataGuardCheckError[],
        varChecksInfo: {
          name: string;
          checks: { [errorId: string]: DataGuardCheckStatus };
        }
      ) => {
        DataGuardAssetService.concatErrorKeyWithVariableName('badVariableUnit', ['LEVEL', 'AUTONOMY', 'TEMPERATURE', 'PRESSURE'], varChecksInfo);

        const varCheckErrors: DataGuardCheckError[] = this.getFullCheckErrors(varChecksInfo.checks).map((error) => ({
          ...error,
          title: varChecksInfo.name
        }));
        acc.push(...varCheckErrors);
        return acc;
      },
      []
    );
  }

  static concatErrorKeyWithVariableName(
    errorKey: string,
    concernedVariables: string[],
    checksInfo: {
      name: string;
      checks: { [errorId: string]: DataGuardCheckStatus };
    }
  ) {
    const matchingVariable: string | undefined = concernedVariables.find((name) => name === checksInfo.name);

    if (matchingVariable && Object.keys(checksInfo.checks).includes(errorKey)) {
      checksInfo.checks[errorKey + matchingVariable] = checksInfo.checks[errorKey];
      delete checksInfo.checks[errorKey];
    }
  }
}
