import { I4BColumnConfiguration } from './configurations';
import {
  ArrayOfObjectColumn,
  AssetFollowedVariableGroupColumn,
  AssetFollowedVariableWithCommentGroupColumn,
  AssetNameColumn,
  AssetVariableColumn,
  AssetVariableGroupColumn,
  CreatedGroupColumn,
  DescriptionColumn,
  DeviceCommunicationGroupColumn,
  DeviceTypeGroupColumn,
  DynamicIconColumn,
  EntityNameColumn,
  EventDurationColumn,
  EventVariableGroupColumn,
  ExpandedVariableColumn,
  I4BBasicColumn,
  I4BDeviceStatusColumn,
  I4BSelectionColumn,
  I4BStatusColumn,
  I4BTagColumn,
  I4bTotalAssetsColumn,
  IdColumn,
  LatLongGroupColumn,
  NameColumn,
  RichVariableColumn,
  SiteAddressColumn,
  SiteNameColumn,
  SiteTypeColumn,
  TotalColumn,
  TotalContactsColumn,
  TotalDevicesColumn,
  TotalEventsColumn,
  TotalTagsColumn,
  UpdatedGroupColumn
} from './definitions';
import { DaliaFirmwareVersionColumn } from './definitions/dalia-firmware-version.column';
import { DiagnosticVariableGroupColumn } from './definitions/i4b-diagnostic-variable-group.column';
import { I4BColumnHeader } from './models';
import { I4BColumnOptions } from './options';

export class ColumnFactory {
  //  allowSystemOverride: take config from default-grids.definitions.ts, allowUserOverride: take ALL the config from user saved grid
  static columnsCatalog: { [columnId: string]: { class: any; options: { allowSystemOverride: boolean; allowUserOverride: boolean } } } = {
    '65b4f54c-5b50-11ec-80a5-0023a40210d0-basic': { class: I4BBasicColumn, options: { allowSystemOverride: true, allowUserOverride: true } },
    '5d94b65e-5b50-11ec-80a5-0023a40210d0-name': { class: NameColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '4e2d5694-5b50-11ec-80a5-0023a40210d0-site-name': { class: SiteNameColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    '3ee33e4c-5e98-11ec-b1e8-acde48001122-site-address-full': { class: SiteAddressColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '9fa4c7f0-5c1d-11ec-80a5-0023a40210d0-site-type': { class: SiteTypeColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    '79f2e9aa-9f85-11ec-9da8-0023a40210d0-device-type-group': {
      class: DeviceTypeGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    'e1f820e6-9f86-11ec-9da8-0023a40210d0-device-communication-group': {
      class: DeviceCommunicationGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    '8e1e28b0-76d9-11ec-8703-acde48001122-asset-name': { class: AssetNameColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    'e24f0f50-5ce7-11ec-9577-acde48001122-asset-variable': { class: AssetVariableColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    '19bcb62c-724b-11ec-a844-acde48001122-asset-variable-group': {
      class: AssetVariableGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    '3f58dd72-5f3e-11ec-9de0-acde48001122-asset-followed-variable-with-comment-group': {
      class: AssetFollowedVariableWithCommentGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    '3f58dd72-5f3e-11ec-9de0-acde48001122-asset-followed-variable-group': {
      class: AssetFollowedVariableGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    'e1f820e6-9f86-11ec-9da8-0023a40210d0-created-group': { class: CreatedGroupColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'e1f820e6-9f86-11ec-9da8-0023a40210d0-updated-group': { class: UpdatedGroupColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '171bc694-5c1e-11ec-80a5-0023a40210d0-total': { class: TotalColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'b8f7bf72-5c1e-11ec-80a5-0023a40210d0-total-contact': { class: TotalContactsColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '3a655890-5c1e-11ec-80a5-0023a40210d0-total-asset': { class: I4bTotalAssetsColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'db000b66-5b50-11ec-80a5-0023a40210d0-total-tags': { class: TotalTagsColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'a4ea36ae-5c1e-11ec-80a5-0023a40210d0-total-device': { class: TotalDevicesColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'd815d72c-5c1e-11ec-80a5-0023a40210d0-total-events': { class: TotalEventsColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'e262102a-5c1d-11ec-80a5-0023a40210d0-entity-name': { class: EntityNameColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    '7a788742-6f09-11ec-87d5-acde48001122-rich-variable': { class: RichVariableColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    '7z348742-6b09-11ec-87d5-acda48401122-expanded-variable': {
      class: ExpandedVariableColumn,
      options: { allowSystemOverride: false, allowUserOverride: true }
    },
    '73729338-7644-11ec-803a-acde48001122-status': { class: I4BStatusColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'f6538da4-7237-11ec-a844-acde48001122-tag': { class: I4BTagColumn, options: { allowSystemOverride: false, allowUserOverride: true } },
    '558445ce-5b50-11ec-80a5-0023a40210d0-description': { class: DescriptionColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'cb4617f6-5b50-11ec-80a5-0023a40210d0-id': { class: IdColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'b9b4bd88-9fb3-11ec-9da8-0023a40210d0-device-status': { class: I4BDeviceStatusColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '1a8dec64-aabe-11ec-858e-acde48001122-dynamic-icon': { class: DynamicIconColumn, options: { allowSystemOverride: false, allowUserOverride: false } },
    '89aad5ee-ba72-11ec-b2ff-acde48001122-event-variable-group': {
      class: EventVariableGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    '06cf77aa-af7e-11ec-bb66-acde48001122-selection': { class: I4BSelectionColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    'e5e6da5a-cb50-4fd2-bd8c-c40f3df1e611-array-of-object': { class: ArrayOfObjectColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '772bc694-5c9e-11ec-87a5-0093a40210d1-event-duration': { class: EventDurationColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '3c4822fa-4674-4260-8e24-9091b79b7ef8-lat-long-group': { class: LatLongGroupColumn, options: { allowSystemOverride: true, allowUserOverride: false } },
    '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group': {
      class: DiagnosticVariableGroupColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    },
    'z1b4bd54-9fc3-11ec-9da8-0023a40210d0-firmware-version': {
      class: DaliaFirmwareVersionColumn,
      options: { allowSystemOverride: true, allowUserOverride: false }
    }
  };

  static createColumn<T extends I4BBasicColumn>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Class: new (customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) => T,
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<I4BColumnConfiguration>,
    customOptions?: Partial<I4BColumnOptions>
  ): T {
    return new Class(customHeader, customConfig, customOptions);
  }

  static getUserColumnClass = (columnId: string) => {
    let columnConfig;
    if (ColumnFactory.columnsCatalog[columnId]) {
      columnConfig = ColumnFactory.columnsCatalog[columnId];
    } else {
      console.error(`Column with id ${columnId} is not referenced in the ColumnFactory. Fall back to I4BBasicColumn and no custom behavior.`);
      columnConfig = { class: I4BBasicColumn, options: { allowSystemOverride: true, allowUserOverride: false } };
    }
    return columnConfig;
  };
}
