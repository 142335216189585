<div fxFlex fxLayout="column">
  <div class="sticky">
    <iot-platform-ui-toolbar-v2
      [name]="'EMAIL_TEMPLATES.SHELL.TITLE' | translate"
      [total]="total$ | async"
      [buttonList]="emailTemplatesButtonList"
      [isDataLoaded]="templatesLoaded$ | async"
      [size]="toolbarSize"
      (dispatchToolbarEvent)="onToolbarEvent($event)"
    >
    </iot-platform-ui-toolbar-v2>
  </div>

  <div class="layout-master-view-engine-container">
    <grid-engine-grid-manager
      fxFlex
      class="layout-ag-grid-shell"
      [grid]="grid$ | async"
      [gridSort]="gridSort$ | async"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    >
    </grid-engine-grid-manager>
  </div>
</div>
