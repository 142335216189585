import { Contact } from '@iot-platform/models/common';
import { ContactNotification } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadNotifications = createAction('[Notifications] Load Notifications', props<{ siteId: string }>());
export const addNotification = createAction(
  '[Notifications] Add Notification',
  props<{ notificationToAdd: ContactNotification; siteId: string; contactIdsToSub: string[] }>()
);
export const updateNotification = createAction(
  '[Notifications] Update Notification',
  props<{ notificationToUpdate: ContactNotification; siteId: string; contactIdsToSub: string[]; contactIdsToUnsub: string[] }>()
);
export const deleteNotification = createAction('[Notifications] Delete Notification', props<{ notificationToDelete: ContactNotification; siteId: string }>());
export const selectNotification = createAction('[Notifications] Select Notification', props<{ notificationToSelect: ContactNotification }>());
export const configureNotifications = createAction(
  '[Notifications] Configure Contact Notifications',
  props<{ contact: Contact; notificationIdsToBeSubTo: string[]; notificationIdsToBeUnsubFrom: string[] }>()
);
