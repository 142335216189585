import { HeaderType } from '@iot-platform/models/grid-engine';

export const topicsMetadataSettings = {
  metadata: {
    url: '/on-call-management/topics',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'topics',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      multipleSelection: false,
      enableConditions: [],
      visibleConditions: [],
      columns: [],
      expandedPanel: {},
      buttonColumn: {
        id: 'button',
        name: 'BUTTON',
        order: 9,
        width: '1%',
        headerType: HeaderType.BASIC,
        cellType: 'button',
        type: 'center center',
        enableConditions: [[{ key: 'isEditable', value: true }]],
        visibleConditions: [
          [
            { key: 'canUpdateTopic', value: true },
            { key: 'canDeleteTopic', value: true }
          ]
        ],
        singleActions: [
          {
            key: 'addToProtocol',
            label: 'TOPICS.MANAGE_EP_POPUP.ADD_TO_PROTOCOL',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateTopic', value: true }]]
          },
          {
            key: 'manageSubscribers',
            label: 'TOPICS.MANAGE_EP_POPUP.MANAGE_SUBSCRIBERS',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateTopic', value: true }]]
          },
          {
            key: 'edit',
            label: 'IOT_DICTIONARY.EDIT',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateTopic', value: true }]]
          },
          {
            key: 'configure',
            label: 'IOT_DICTIONARY.CONFIGURE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateTopic', value: true }]]
          },
          {
            key: 'delete',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [[{ key: 'status', value: 'inactive' }]],
            visibleConditions: [[{ key: 'canDeleteTopic', value: true }]]
          }
        ]
      }
    }
  },
  masterViewComments: {}
};
