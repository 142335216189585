import { Injectable, Signal } from '@angular/core';
import { Asset, AssetEvent, Device, DeviceEvent, Event, Log, Site } from '@iot-platform/models/i4b';
import { AbstractTopicsService } from '@iot-platform/on-call-management';
import { NavigationApi } from '../containers/+state/navigation.api';
import { AssetEventsApi } from '../features/asset-events/+state/asset-events.api';
import { DeviceEventsApi } from '../features/device-events/+state/device-events.api';

@Injectable({
  providedIn: 'root'
})
export class TopicsService extends AbstractTopicsService {
  assetEventComments: Signal<Log[]> = this.assetEventsApi.assetEventComments;
  assetEventCommentsLoading: Signal<boolean> = this.assetEventsApi.assetEventCommentsLoading;
  deviceEventComments: Signal<Log[]> = this.deviceEventsApi.deviceEventComments;
  deviceEventCommentsLoading: Signal<boolean> = this.deviceEventsApi.deviceEventCommentsLoading;

  constructor(
    private readonly assetEventsApi: AssetEventsApi,
    private readonly deviceEventsApi: DeviceEventsApi,
    private readonly navigationApi: NavigationApi
  ) {
    super();
  }

  loadEventDetailPopupDataByAssetEvent(event: AssetEvent): void {
    this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(event);
  }

  loadEventDetailPopupDataByDeviceEvent(event: DeviceEvent): void {
    this.deviceEventsApi.loadEventDetailPopupDataByDeviceEvent(event);
  }

  selectAssetAvecLeSite(selectedAsset: Asset): void {
    this.navigationApi.selectAssetAvecLeSite(selectedAsset);
  }

  selectDeviceAvecLeSite(selectedDevice: Device): void {
    this.navigationApi.selectDeviceAvecLeSite(selectedDevice);
  }

  openAssetDetail(asset: Asset, origin: string): void {
    this.navigationApi.openAssetDetail(asset, origin);
  }

  openDeviceDetail(device: Device, origin: string): void {
    this.navigationApi.openDeviceDetail(device, origin);
  }

  openSiteDetail(site: Site, origin: string): void {
    this.navigationApi.openSiteDetail(site, origin);
  }

  loadAssetEventComments(assetEvent: Event): void {
    this.assetEventsApi.loadComments(assetEvent);
  }

  addAssetEventComment(assetEvent: Event, comment: string): void {
    this.assetEventsApi.addComment(assetEvent, comment);
  }

  editAssetEventComment(assetEventId: string, comment: Log): void {
    this.assetEventsApi.editComment(assetEventId, comment);
  }

  deleteAssetEventComment(assetEvent: Event, commentId: string): void {
    this.assetEventsApi.deleteComment(assetEvent, commentId);
  }

  updateStatusByAssetEventId(assetEventIds: string[], status: string): void {
    this.assetEventsApi.updateStatusByAssetEventId(assetEventIds, status);
  }

  loadDeviceEventComments(deviceEvent: Event): void {
    this.deviceEventsApi.loadComments(deviceEvent);
  }

  addDeviceEventComment(deviceEvent: Event, comment: string): void {
    this.deviceEventsApi.addComment(deviceEvent, comment);
  }

  editDeviceEventComment(deviceEventId: string, comment: Log): void {
    this.deviceEventsApi.editComment(deviceEventId, comment);
  }

  deleteDeviceEventComment(deviceEvent: Event, commentId: string): void {
    this.deviceEventsApi.deleteComment(deviceEvent, commentId);
  }

  updateStatusByDeviceEventId(deviceEventIds: string[], status: string): void {
    this.deviceEventsApi.updateStatusByDeviceEventId(deviceEventIds, status);
  }
}
