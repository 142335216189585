import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { AuthConfig } from './lib/auth.config';
import { AuthModule } from './lib/auth.module';
import * as fromAuthReducers from './lib/state/reducers';
import { provideAuthState } from './lib/state/reducers';
import * as fromAuthSelectors from './lib/state/selectors';

export { LoginCallbackComponent } from './lib/components/login-callback/login-callback.component';
export { LoginChangeTemporaryPasswordFormComponent } from './lib/components/login-change-temporary-password-form/login-change-temporary-password-form.component';
export { LoginFormComponent } from './lib/components/login-form/login-form.component';
export { LoginPasswordRulesComponent } from './lib/components/login-password-rules/login-password-rules.component';
export { LoginResetPasswordFormComponent } from './lib/components/login-reset-password-form/login-reset-password-form.component';
export { LoginSelectAuthenticationComponent } from './lib/components/login-select-authentication/login-select-authentication.component';
export { LoginSelectProfileComponent } from './lib/components/login-select-profile/login-select-profile.component';
export { LoginSendCodeFormComponent } from './lib/components/login-send-code-form/login-send-code-form.component';
export { LoginShellComponent } from './lib/containers/login-shell/login-shell.component';

export { AuthorizationType, AuthorizationConcept } from './lib/authorization.types';
export { bypassHttpContextBusinessProfileIdHeader } from './lib/auth.interceptor';
export { AuthService } from './lib/services/auth.service';
export { AuthorizationService } from './lib/services/authorization.service';
export { AuthInterceptor } from './lib/auth.interceptor';

const fromAuth = {
  ...fromAuthReducers,
  ...fromAuthSelectors
};
export { fromAuth };

export * from './lib/state/actions';
export * from './lib/state/facades';
export * from './lib/services/auth.guard';
export * from './lib/auth.config';
export * from './lib/auth.module';

export function provideAuthModule(config?: Partial<AuthConfig>): EnvironmentProviders[] {
  return [...provideAuthState(), importProvidersFrom(AuthModule.forRoot(config))];
}
