import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

@Pipe({
  name: 'numberFormat',
  standalone: true
})
export class NumberFormatPipe extends DecimalPipe implements PipeTransform {
  private readonly defaultDigitsInfo = '1.0-3';
  private readonly defaultLocale = 'fr';

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly storage: LocalStorageService
  ) {
    super(locale);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override transform(value: any, digitsInfo?: string, locale?: string): any {
    const userAppNumberFormat = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberFormat;
    const userAppNumberOfDecimals = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberOfDecimals;

    let digitsInfoToUse = '';
    if (digitsInfo) {
      digitsInfoToUse = digitsInfo;
    } else if (!!userAppNumberOfDecimals) {
      digitsInfoToUse = this.defaultDigitsInfo.substring(0, this.defaultDigitsInfo.length - 1) + userAppNumberOfDecimals.toString();
    } else {
      digitsInfoToUse = this.defaultDigitsInfo;
    }
    return super.transform(value, digitsInfoToUse, userAppNumberFormat ?? this.defaultLocale);
  }
}
