import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'i4b-table-engine-variable-dispatch-cell',
  templateUrl: './variable-dispatch-cell.component.html',
  styleUrls: ['./variable-dispatch-cell.component.scss']
})
export class VariableDispatchCellComponent implements OnInit {
  @Input() data: { isDispatched: boolean; dispatchDestination: string };
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  tooltip: string;
  isDispatched: boolean;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.tooltip = this.translateService.instant(`ASSETS.VARIABLE.DISPATCH_TOOLTIP`, { dispatchDestination: this.data.dispatchDestination });
  }
}
