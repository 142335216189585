import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { Filter, FilterDynamicListMultipleSelectsInput, FilterDynamicListMultipleSelectsOptions } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AsyncAutocompleteMultipleSelectsComponent } from '../../../../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.component';
import { DynamicListFieldService } from '../../../services/dynamic-list-filed.service';
import { AbstractFilterEngineFieldComponent } from '../../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinner,
    AsyncAutocompleteMultipleSelectsComponent
  ],
  selector: 'iot-platform-ui-dynamic-list-field',
  templateUrl: './dynamic-list-field-multiple-select.component.html',
  styleUrls: ['./dynamic-list-field-multiple-selects.component.scss']
})
export class DynamicListFieldMultipleSelectsComponent extends AbstractFilterEngineFieldComponent<FilterDynamicListMultipleSelectsOptions> implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});
  showLoader = signal(false);
  categoryList = signal(null);
  labelList = signal(null);
  category = signal(null);

  protected dynamicListFieldService: DynamicListFieldService = inject(DynamicListFieldService);

  ngOnInit() {
    this.initFormGroup();
    this.setDynamicLists();
    this.resetFormEffect();
  }

  initFormGroup(): void {
    const data = this.data();
    const group = {};
    data.inputs.forEach((input: FilterDynamicListMultipleSelectsInput) => {
      group[input.id] = new UntypedFormControl(null);
    });
    this.form = new UntypedFormGroup(group);
  }

  setDynamicLists(): void {
    const data = this.data();
    data.inputs?.forEach((input: FilterDynamicListMultipleSelectsInput) => {
      if (input.url) {
        this.showLoader.set(true);
        this.categoryList.set(input);
        this.dynamicListFieldService
          .getDynamicList(input.url, input.sortMethod)
          .pipe(
            finalize(() => {
              this.showLoader.set(false);
            }),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe((dynamicList: Array<any>) => {
            input.display = dynamicList;
            if (data?.multiSelect) {
              this.categoryList.set(input);
            }
          });
      }
    });
  }

  getParentLabel(input: FilterDynamicListMultipleSelectsInput, previousLabel: string): string {
    previousLabel = this.form.get(input.id).value[input.labelToDisplay].concat(' - ', previousLabel);

    if (input.parentListId !== null) {
      return this.getParentLabel(
        this.data().inputs.find((parent) => parent.id === input.parentListId),
        previousLabel
      );
    } else {
      return previousLabel.substring(0, previousLabel.length - 3);
    }
  }

  onSelectionChange(input: FilterDynamicListMultipleSelectsInput, value: any): void {
    const data = this.data();
    if (input.filterBy) {
      const f: Filter = {};
      f.value = value[input.filterBy];
      f.label = this.getParentLabel(input, '');
      f.data = input.display;
      f.displayWrapper = data.displayWrapper;
      f.criteriaKey = data.criteriaKey;
      f.criteriaLabel = data.criteriaLabel;

      this.dispatchFilterEvent(f);
      this.form.controls[input.id].reset();
    } else {
      const childInput = data.inputs.find((i) => i.parentListId === input.id);
      childInput.display = this.dynamicListFieldService.sortDynamicList(value[childInput.parentListProperty], childInput.sortMethod, 'object');

      if (data.multiSelect) {
        this.category.set(value);
        this.labelList.set(childInput);
      }
    }
  }

  onMultiSelectionChange(value: any): void {
    const data = this.data();
    const f: Filter = {};
    f.criteriaKey = data.criteriaKey;
    f.criteriaLabel = data.criteriaLabel;
    f.value = get(value, [get(this.labelList(), 'filterBy')]);
    f.label = `${get(this.category(), 'name')} - ${value.name}`;
    this.dispatchFilterEvent(f);
  }

  private resetFormEffect() {
    effect(
      () => {
        const data = this.data();
        const currentFilters = this.currentFilters();
        const filters = currentFilters?.filter((f) => f.criteriaKey === data?.criteriaKey);
        if (data?.multiSelect && !filters?.length) {
          this.form.reset();
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }
}
