import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Device } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DetailPopupModule } from '../../detail-popup/detail-popup.module';
import { DeviceInfoStatusModule } from '../device-info-status/device-info-status.module';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, InfoDisplayPipe, TranslateModule, DeviceInfoStatusModule, DetailPopupModule],
  selector: 'iot-platform-ui-device-details-basic-info',
  templateUrl: './device-details-basic-info.component.html',
  styleUrls: ['./device-details-basic-info.component.scss']
})
export class DeviceDetailsBasicInfoComponent {
  device = input<Device | null>(null);
  displayMode = input<'vertical' | 'horizontal' | 'default'>('default');

  selectDevice = output<Device>();
}
