import { inject, Injectable } from '@angular/core';
import { TrackingSettings } from '../models/tracking-settings.model';
import { TRACKING_SETTINGS } from '../services/tracking.tokens';

@Injectable()
export class TrackingSettingsService {
  protected readonly trackingSettings: TrackingSettings[] = inject(TRACKING_SETTINGS);
  private settings!: TrackingSettings;

  constructor() {
    this.trackingSettings.forEach(
      (v) =>
        (this.settings = {
          ...this.settings,
          ...v,
          tags: {
            ...this.settings?.tags,
            ...v.tags
          }
        })
    );
  }

  getSettings(): TrackingSettings {
    return this.settings;
  }
}
