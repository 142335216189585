import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';
import { AbstractTopicsService } from './features/topics/services/abstract-topics.service';

export interface OnCallManagementSettings {
  eventDetailPopupComponent: Type<any>;
  topicsService: Type<AbstractTopicsService>;
}

export const ON_CALL_MANAGEMENT_SETTINGS = new InjectionToken<OnCallManagementSettings>('ON_CALL_MANAGEMENT_SETTINGS');

export function provideOnCallManagementSettings(settings: OnCallManagementSettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ON_CALL_MANAGEMENT_SETTINGS,
      useValue: {
        eventDetailPopupComponent: settings.eventDetailPopupComponent
      }
    },
    { provide: AbstractTopicsService, useClass: settings.topicsService }
  ]);
}
