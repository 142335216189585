import { Injectable } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, FollowedVariable } from '@iot-platform/models/i4b';

@Injectable()
export class AssetVariableDispatchUtil {
  static findDispatchDestination(tags: TagCategory[]): string {
    const dispatchTag = tags.find((tag: TagCategory) => tag.name.toLowerCase() === 'dispatch');
    if (dispatchTag) {
      return dispatchTag.labels[0].name;
    } else {
      return null;
    }
  }

  static isDispatched(assetVariable: AssetVariable | FollowedVariable, asset?: Asset, dispatchDestination?: string): boolean {
    return (
      asset?.status?.name?.toString() === 'production' &&
      assetVariable?.name?.trim()?.toLowerCase() === 'level' &&
      !!dispatchDestination &&
      dispatchDestination.trim().toLowerCase() !== 'none'
    );
  }
}
