import { createSelector } from '@ngrx/store';
import { authFeatures } from '../reducers';

export const { selectAuthApi: selectAuthApiState } = authFeatures;

export const selectLoggedIn = createSelector(selectAuthApiState, (state) => state.loggedIn);
export const selectCognitoUser = createSelector(selectAuthApiState, (state) => state.cognitoUser);
export const selectAccount = createSelector(selectAuthApiState, (state) => state.currentUser);
export const selectBusinessProfilesForAccount = createSelector(selectAuthApiState, (state) => state.businessProfiles);
export const selectSelectedBusinessProfileForAccount = createSelector(selectAuthApiState, (state) => state.selectedBusinessProfile);
export const selectSelectedEntityForSession = createSelector(selectAuthApiState, (state) => state.currentEntity);
export const selectSelectedBusinessProfileTimezone = createSelector(selectAuthApiState, (state) => state.selectedBusinessProfile?.timezoneDetails);
export const selectPreferences = createSelector(selectAuthApiState, (state) => state.preferences);
export const selectPrivileges = createSelector(selectAuthApiState, (state) => state.privileges);
export const selectCurrentUser = createSelector(selectAuthApiState, (state) => state.currentUser);
export const selectIdToken = createSelector(selectAuthApiState, (state) => state.idToken);
export const selectRefreshToken = createSelector(selectAuthApiState, (state) => state.refreshToken);
export const selectSsoTokenExpiresAt = createSelector(selectAuthApiState, (state) => state.ssoTokenExpiresAt);
export const selectLoggedOut = createSelector(selectAuthApiState, (state) => state.loggedOut);
export const selectUserId = createSelector(selectAuthApiState, (state) => state.userId);
export const selectIsUserAdmin = createSelector(selectAuthApiState, (state) => state.isUserAdmin);
export const selectBusinessProfileInitialized = createSelector(selectAuthApiState, (state) => state.businessProfileInitialized);
export const selectLoggedInWithSSO = createSelector(selectAuthApiState, (state) => state.loggedInWithSSO);
export const selectAccessToken = createSelector(selectAuthApiState, (state) => state.accessToken);
export const selectUserIsTopLevelAdmin = createSelector(
  selectIsUserAdmin,
  selectSelectedEntityForSession,
  (isUserAdmin, sessionEntity) => isUserAdmin && sessionEntity?.parentId === null
);
