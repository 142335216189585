import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ChatEvent, ChatMessage } from '@iot-platform/models/common';
import { DateFormatPipe } from '@iot-platform/pipes';

@Component({
  standalone: true,
  imports: [MatIcon, FlexLayoutModule, DateFormatPipe, MatButtonModule],
  selector: 'iot-platform-ui-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent {
  message = input.required<ChatMessage>();
  dispatchAction = output<ChatEvent>();
}
