import { Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createAction, props } from '@ngrx/store';

export const addGrid = createAction('[Admin Business Profile/Admin Page] Add Grid', props<{ gridToAdd: I4BGrid<I4BGridOptions, I4BGridData> }>());

export const updateGrid = createAction('[Admin Business Profile/Admin Page] Update Grid', props<{ gridToUpdate: I4BGrid<I4BGridOptions, I4BGridData> }>());

export const deleteGrid = createAction('[Admin Business Profile/Admin Page] Delete Grid', props<{ gridToDelete: I4BGrid<I4BGridOptions, I4BGridData> }>());

export const changePage = createAction('[Admin Business Profile/Admin Page] Change Grid Page', props<{ pagination: Pagination }>());
