import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { EscalationProtocolsDbActions, EscalationProtocolsUiActions } from '../actions';

export const escalationProtocolsUiFeatureKey = 'escalationProtocolsUi';

export interface State {
  error?: any;
  escalationProtocolsLoaded: boolean;
  escalationProtocolsLoading: boolean;
  topicSubscriptionsLoaded: boolean;
  topicSubscriptionsLoading: boolean;
}

export const initialState: State = {
  error: null,
  escalationProtocolsLoaded: false,
  escalationProtocolsLoading: false,
  topicSubscriptionsLoaded: false,
  topicSubscriptionsLoading: false
};

const escalationProtocolsUiReducer = createReducer(
  initialState,
  on(
    EscalationProtocolsUiActions.listEscalationProtocols,
    EscalationProtocolsUiActions.loadEscalationProtocolById,
    EscalationProtocolsUiActions.addEscalationProtocol,
    EscalationProtocolsUiActions.updateEscalationProtocol,
    EscalationProtocolsUiActions.deleteEscalationProtocol,
    (state: State) => ({
      ...state,
      escalationProtocolsLoaded: false,
      escalationProtocolsLoading: true
    })
  ),
  on(
    EscalationProtocolsDbActions.listEscalationProtocolsSuccess,
    EscalationProtocolsDbActions.loadEscalationProtocolByIdSuccess,
    EscalationProtocolsDbActions.addEscalationProtocolSuccess,
    EscalationProtocolsDbActions.updateEscalationProtocolSuccess,
    EscalationProtocolsDbActions.deleteEscalationProtocolSuccess,
    (state: State) => ({
      ...state,
      escalationProtocolsLoaded: true,
      escalationProtocolsLoading: false
    })
  ),
  on(
    EscalationProtocolsDbActions.listEscalationProtocolsFailure,
    EscalationProtocolsDbActions.loadEscalationProtocolByIdFailure,
    EscalationProtocolsDbActions.addEscalationProtocolFailure,
    EscalationProtocolsDbActions.updateEscalationProtocolFailure,
    EscalationProtocolsDbActions.deleteEscalationProtocolFailure,
    (state: State) => ({
      ...state,
      escalationProtocolsLoaded: false,
      escalationProtocolsLoading: false
    })
  ),
  on(
    EscalationProtocolsUiActions.loadTopicSubscriptionByProtocolId,
    EscalationProtocolsUiActions.updateEscalationProtocolTopicSubscriptions,
    (state: State) => ({
      ...state,
      topicSubscriptionsLoaded: false,
      topicSubscriptionsLoading: true
    })
  ),
  on(
    EscalationProtocolsDbActions.loadTopicSubscriptionByProtocolIdSuccess,
    EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsSuccess,
    (state: State) => ({
      ...state,
      topicSubscriptionsLoaded: true,
      topicSubscriptionsLoading: false
    })
  ),
  on(
    EscalationProtocolsDbActions.loadTopicSubscriptionByProtocolIdFailure,
    EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsFailure,
    (state: State) => ({
      ...state,
      topicSubscriptionsLoaded: false,
      topicSubscriptionsLoading: false
    })
  ),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return escalationProtocolsUiReducer(state, action);
}

export const getEscalationProtocolsLoaded = (state: State) => state.escalationProtocolsLoaded;
export const getEscalationProtocolsLoading = (state: State) => state.escalationProtocolsLoading;
export const getTopicSubscriptionsLoading = (state: State) => state.topicSubscriptionsLoading;
export const getTopicSubscriptionsLoaded = (state: State) => state.topicSubscriptionsLoaded;
