import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { Device, Site } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationApi } from '../../../../containers/+state/navigation.api';

@Component({
  selector: 'iot4bos-ui-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss']
})
export class DeviceOverviewComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('device_info_page');

  device$ = this.navigationApi.selectedDevice$;
  site$: Observable<Site> = this.navigationApi.site$;

  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canUpdateEvent: boolean;
  canReadEvent: boolean;
  canReadAuditTrail: boolean;
  userPermissions: { key: string; value: boolean }[] = [];

  configurationUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  destroyed$: Subject<boolean> = new Subject();

  // Data Guard
  dataGuardOpened = this.navigationApi.dataGuardOpened;

  constructor(
    private readonly navigationApi: NavigationApi,
    private readonly authorizationService: AuthorizationService,
    private readonly store: Store
  ) {
    this.canCreate = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
    this.canRead = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    this.canUpdate = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.UPDATE);
    this.canDelete = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.DELETE);
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
    this.canReadEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);
    this.canReadAuditTrail = this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);

    this.userPermissions = [
      { key: 'canUpdateEvent', value: this.canUpdateEvent },
      { key: 'canReadEvent', value: this.canReadEvent }
    ];
  }

  ngOnInit(): void {
    this.updateIncomingConnector();
  }

  closeAllPanels(): void {
    this.navigationApi.toggleDataGuard(false);
  }

  onSelectedTabChange(event: MatTabChangeEvent): void {
    this.analytic.log('tab_change', `${event.tab.textLabel}`);
  }

  onConfigureDeviceConnector(device: Device) {
    this.analytic.log('tab_device_info_actions', 'configure_connector');
    if (device.outgoingConnector?.configuration?.sharedAuth) {
      window.open(this.configurationUrl$.getValue());
    } else {
      window.open(device.incomingConnector?.configuration?.url);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private updateIncomingConnector() {
    combineLatest([
      this.device$,
      this.store.select(fromAuth.selectRefreshToken),
      this.store.select(fromAuth.selectSelectedBusinessProfileForAccount),
      this.store.select(fromAuth.selectUserId)
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([device, token, bP, userId]) => {
        if (device?.incomingConnector && token && bP && userId) {
          this.configurationUrl$.next(
            `${device.incomingConnector.configuration?.url}device/${userId}/${bP.id}?refreshToken=${token}&deviceIdentifier=${device.identifier}`
          );
        }
      });
  }
}
