<button
  (click)="click.emit()"
  [matTooltip]="isCurrentUserSubscribed() ? ('TOPICS.SHELL.UNSUBSCRIBE' | translate) : ('TOPICS.SHELL.SUBSCRIBE' | translate)"
  [ngClass]="{ subscribed: isCurrentUserSubscribed(), unsubscribed: !isCurrentUserSubscribed() }"
  class="regular-round-button"
  mat-icon-button
  matTooltipClass="regular-tooltip"
  matTooltipPosition="above"
>
  <mat-icon [svgIcon]="'subscribe'"></mat-icon>
</button>
