<div data-cy="grid-engine-icon-cell">
  @if (isIcon()) {
    <mat-icon
      [matTooltipClass]="'regular-tooltip'"
      [matTooltip]="
        (cellOptions()
          ? cellOptions() + '.' + params()?.value?.toLowerCase()
          : ''
        ) | translate
      "
      matTooltipPosition="above"
      [ngClass]="['icon-' + params()?.value, 'table-icon-content']"
      [svgIcon]="value()?.toLowerCase()"
    />
  } @else {
    <!-- Used for filter custom filter renderer -->
    <span>{{ value() }}</span>
  }
</div>
