import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Topic } from '@iot-platform/models/ocm';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopicSelfSubscriptionCellService {
  constructor(@Inject('environment') private environment, private http: HttpClient) {}

  public getSubscribersByTopic(topic: Topic): Observable<string[]> {
    return this.http
      .get<{ content: string[] }>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topic.id}/users`)
      .pipe(map((result) => result.content));
  }
}
