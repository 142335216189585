<div class="login-shell default-theme" data-cy="iot-platform-login-shell">
  <div class="login-shell-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%"></div>
    <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column">
      <h1 class="login-shell-title fw-600">{{ authConfig()?.appName }}</h1>

      @if (!loggedIn() && !stepEmail() && !stepPassword() && !loginWithEmailPassword()) {
        <iot-platform-login-select-authentication
          data-cy="iot-platform-login-select-authentication"
          fxFlex
          [ssoDisabled]="authConfig()?.ssoDisabled"
          (signInWithSSO)="onSignInWithSSO()"
          (signInWithEmailPassword)="onSignInWithEmailPassword()"
        />
      }

      @if (loginWithEmailPassword()) {
        <iot-platform-login-form
          data-cy="iot-platform-login-form"
          fxFlex
          [isI4BLogin]="true"
          [hasError]="errorSignIn()"
          (cancel)="onCancel()"
          (resetPassword)="onResetPassword()"
          (signIn)="onSignIn($event)"
        />
      }

      @if (loggedIn() && requireNewPassword()) {
        <iot-platform-login-change-temporary-password-form
          data-cy="iot-platform-login-change-temporary-password-form"
          [hasError]="errorPassword()"
          (cancel)="onCancel()"
          (changeTemporaryPassword)="onChangeTemporaryPassword($event)"
        />
      }

      @if (stepEmail()) {
        <iot-platform-login-send-code-form
          data-cy="iot-platform-login-send-code-form"
          [hasError]="errorEmail()"
          [isI4BLogin]="true"
          (cancel)="onCancel()"
          (sendCode)="onSendCode($event)"
        />
      }

      @if (stepPassword()) {
        <iot-platform-login-reset-password-form
          data-cy="iot-platform-login-reset-password-form"
          [username]="username()"
          [hasError]="errorPassword()"
          (cancel)="onCancel()"
          (sendCode)="onSendCode($event)"
          (changePassword)="onChangeForgottenPassword($event)"
        />
      }

      @if (loggedIn() && !requireNewPassword()) {
        <iot-platform-login-select-profile
          data-cy="iot-platform-login-select-profile"
          [businessProfiles]="businessProfiles()"
          [loading]="pending()"
          (selectProfile)="onSelectProfile($event)"
        />
      }
    </div>
  </div>
</div>
