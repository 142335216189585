import { LowerCasePipe, NgClass, NgStyle, UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, input, output, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { ArrayUtils } from '@iot-platform/iot-platform-utils';
import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { TagEditorComponent } from '../tag-editor/tag-editor.component';
import { SelectedTag, TagsByEntity } from './manage-tags-form.model';
import { ManageTagsFormService } from './manage-tags-form.service';

// TODO: Component should be refactored to be less complex and easy to maintain

interface ManageTagsFormData {
  concepts: string[];
  openOnConcept?: string;
  selectedTags: TagCategory[];
  objectName: string;
  currentEntityId: string;
  multiSelection: boolean;
  editable: boolean;
  withChildren: boolean;
  withParents: boolean;
  joinable: boolean;
  minimumSelectionCount?: number;
  enforceMandatoryCategories?: boolean;
}

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    UpperCasePipe,
    MatChipsModule,
    TagEditorComponent,
    LowerCasePipe,
    MatButtonModule,
    NgStyle,
    NgClass,
    MatSlideToggle,
    MatTooltip,
    MatProgressBar
  ],
  selector: 'iot-platform-ui-manage-tags-form',
  templateUrl: './manage-tags-form.component.html',
  styleUrls: ['./manage-tags-form.component.scss']
})
export class ManageTagsFormComponent {
  data = input<ManageTagsFormData>();
  updateForm = output<TagCategory[] | null>();

  timeoutId = undefined;
  // Components variables
  form: UntypedFormGroup = new UntypedFormGroup({
    concept: new UntypedFormControl('', [Validators.required]),
    mandatoryOnly: new UntypedFormControl(false)
  });
  tagsLoading: WritableSignal<boolean> = signal(true);
  allTags: WritableSignal<{ [concept: string]: TagsByEntity[] }> = signal({});
  selectedTags: WritableSignal<SelectedTag[]> = signal([]);
  tagsToDisplay: WritableSignal<{ [concept: string]: TagsByEntity[] }> = signal({});
  canClose: WritableSignal<boolean> = signal(false);
  canRemove: Signal<boolean> = computed(() => {
    const selected = this.selectedTags();
    const data = this.data();
    return selected.length > (data?.minimumSelectionCount ?? 0);
  });
  totalMissingMandatoryTags = computed(() => {
    const data = this.data();

    if (data.enforceMandatoryCategories) {
      const allTags = this.allTags();
      // TODO à faire avec tous les concepts
      return allTags[this.concept.value?.toUpperCase()]?.filter((tag: TagsByEntity) => tag.isMandatoryTagMissing).length;
    } else {
      return 0;
    }
  });
  mandatoryOnlyValueChange: Signal<boolean> = toSignal(this.mandatoryOnly.valueChanges);
  private readonly manageTagsFormService: ManageTagsFormService = inject(ManageTagsFormService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.initDataEffect();
    this.initAllTagsEffect();
    this.initSelectedTagsEffect();
    this.emitTagsToBeSavedEffect();
  }

  get concept(): AbstractControl {
    return this.form.get('concept') as AbstractControl;
  }

  get mandatoryOnly(): AbstractControl {
    return this.form.get('mandatoryOnly') as AbstractControl;
  }

  static getSelectedTagsByEntityTotal(tagCategories: TagCategory[]): number {
    return tagCategories.reduce((total: number, cat: TagCategory) => {
      total += cat.labels.filter((label) => label.selected).length;
      return total;
    }, 0);
  }

  static isMandatoryTagMissing(categories: TagCategory[], selectedTags: SelectedTag[]): boolean {
    const mandatoryCategories: TagCategory[] = categories.filter((cat) => cat.mandatory);
    return mandatoryCategories.reduce((acc: boolean, cat: TagCategory) => {
      const isMandatoryNotSelected = !selectedTags.find(
        (selectedTag) => selectedTag.categoryId === cat.id && !!cat.labels?.find((label) => label.id === selectedTag.id)
      );
      return acc || isMandatoryNotSelected;
    }, false);
  }

  static transformTagLabelIntoSelectedTag(tagLabel: TagLabel, category: TagCategory): SelectedTag {
    return {
      id: tagLabel.id,
      name: tagLabel.name,
      concept: category.concept,
      entityId: category.entityId,
      color: category.color,
      categoryId: category.id,
      categoryName: category.name,
      selected: true,
      label: { ...tagLabel, selected: true },
      category
    } as SelectedTag;
  }

  setTagsToDisplayToMandatoryOnly(allTags: { [concept: string]: TagsByEntity[] }): void {
    const concepts: string[] = Object.keys(allTags);
    const filteredResult: { [concept: string]: TagsByEntity[] } = concepts.reduce(
      (
        acc: {
          [concept: string]: TagsByEntity[];
        },
        concept: string
      ) => {
        const filteredTagsByEntities = allTags[concept].reduce((tagsByEntities: TagsByEntity[], value) => {
          const mandatoryCats = value.tagCategories.filter((cat) => cat.mandatory);
          if (mandatoryCats.length) {
            const newValue: TagsByEntity = { ...value, tagCategories: mandatoryCats };
            tagsByEntities.push(newValue);
          }
          return tagsByEntities;
        }, []);
        acc = { ...acc, [concept]: filteredTagsByEntities };
        return acc;
      },
      {}
    );
    this.tagsToDisplay.set(filteredResult);
  }

  updateTagsLabels(selectedTags: SelectedTag[]): void {
    this.allTags.update((allTags) => {
      const concepts: string[] = Object.keys(allTags);

      return concepts.reduce((acc: { [concept: string]: TagsByEntity[] }, concept: string) => {
        const updatedTagsByEntities = allTags[concept].map((value: TagsByEntity) => {
          value.tagCategories.forEach((tagCat) => {
            tagCat.labels?.map((label) => {
              const selected = selectedTags.find((tag) => tag.id === label.id);
              label.selected = !!selected;
              return label;
            });
          });
          value.totalSelected = ManageTagsFormComponent.getSelectedTagsByEntityTotal(value.tagCategories);
          value.isMandatoryTagMissing = ManageTagsFormComponent.isMandatoryTagMissing(value.tagCategories, selectedTags);
          return value;
        });
        acc = { ...acc, [concept]: updatedTagsByEntities };
        return acc;
      }, {});
    });
  }

  getSelectedTags(workingArr: TagCategory[]): SelectedTag[] {
    const working = [...workingArr];

    return working.reduce((acc, tagCat) => {
      const newLabs = tagCat.labels.map((label) => ManageTagsFormComponent.transformTagLabelIntoSelectedTag(label, tagCat));
      acc.push(...newLabs);
      return acc;
    }, []);
  }

  addSelectedTag(tagCategory: TagCategory, tagLabel: TagLabel): void {
    if (typeof this.timeoutId === 'number') {
      clearTimeout(this.timeoutId);
    }

    if (!tagLabel.selected) {
      const selectedTag: SelectedTag = ManageTagsFormComponent.transformTagLabelIntoSelectedTag(tagLabel, tagCategory);

      if (this.data().multiSelection) {
        this.timeoutId = setTimeout(
          () =>
            this.selectedTags.update((value) => {
              value.push(selectedTag);
              return [...value];
            }),
          10
        );
      } else {
        const indexOfTagToReplace = this.selectedTags().findIndex((l) => l.categoryId === selectedTag.categoryId);
        const tagToReplace = this.selectedTags().find((l) => l.categoryId === selectedTag.categoryId);
        if (!tagToReplace) {
          this.timeoutId = setTimeout(
            () =>
              this.selectedTags.update((value) => {
                value.push(selectedTag);
                return [...value];
              }),
            10
          );
        } else {
          this.timeoutId = setTimeout(
            () =>
              this.selectedTags.update((value) => {
                value.splice(indexOfTagToReplace, 1, selectedTag);
                return [...value];
              }),
            10
          );
        }
      }
    }
  }

  removeSelectedTag(tagToRemove: SelectedTag): void {
    if (typeof this.timeoutId === 'number') {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => this.selectedTags.update((currentSelectedTags) => currentSelectedTags.filter((tag) => tag.id !== tagToRemove.id)), 100);
  }

  getTagsToSave(): TagCategory[] {
    return this.selectedTags().reduce((acc: TagCategory[], selectedTag: SelectedTag) => {
      const foundIndex = acc.findIndex((cat: TagCategory) => cat.entityId === selectedTag.entityId);

      if (foundIndex === -1) {
        acc.push({
          name: selectedTag.categoryName,
          id: selectedTag.categoryId,
          concept: selectedTag.concept,
          color: selectedTag.color,
          labels: [{ id: selectedTag.id, name: selectedTag.name }]
        });
      } else {
        acc[foundIndex] = {
          ...acc[foundIndex],
          labels: [...acc[foundIndex].labels, { id: selectedTag.id, name: selectedTag.name }]
        };
      }

      return acc;
    }, []);
  }

  private initAllTagsEffect(): void {
    effect(
      () => {
        const allTags = this.allTags();
        const mandatoryOnly = this.mandatoryOnlyValueChange();
        if (allTags) {
          if (mandatoryOnly) {
            this.setTagsToDisplayToMandatoryOnly(allTags);
          } else {
            this.tagsToDisplay.set(allTags);
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  private initSelectedTagsEffect(): void {
    effect(
      () => {
        const selectedTags: SelectedTag[] = this.selectedTags();
        this.updateTagsLabels(selectedTags);
      },
      { allowSignalWrites: true }
    );
  }

  private initDataEffect(): void {
    effect(
      () => {
        const data = this.data();

        if (data) {
          if (data.concepts.length === 1) {
            this.concept.setValue(data.concepts[0]);
          }
          if (data.concepts.find((concept) => concept === data.openOnConcept)) {
            this.concept.setValue(data.openOnConcept);
          } else {
            this.concept.setValue(data.concepts[0]);
          }

          this.manageTagsFormService
            .getTagCategoriesByConceptSortedByEntity(data.concepts, data.currentEntityId, data.withChildren, data.withParents, data.joinable)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((tags) => {
              this.allTags.set(tags);
              this.tagsLoading.set(false);
              this.selectedTags.set(this.getSelectedTags(data.selectedTags ?? []));
            });
        }
      },
      { allowSignalWrites: true }
    );
  }

  private emitTagsToBeSavedEffect(): void {
    effect(() => {
      const currentSelectedTags = this.selectedTags();
      const totalMissingMandatoryTags = this.totalMissingMandatoryTags();
      const data = this.data();

      const currentSelectedTagsIds = currentSelectedTags.map((tag: TagCategory) => tag.id);
      const initialSelectedTags = data?.selectedTags ?? [];
      const initialTagsIds = initialSelectedTags.flatMap((tag: TagCategory) => tag.labels?.map((label) => label.id));
      if (initialSelectedTags.length === 0 && currentSelectedTags.length === 0) {
        this.updateForm.emit(null);
      }
      const identicalArrays = ArrayUtils.isEqual(initialTagsIds, currentSelectedTagsIds);

      if (!identicalArrays && !totalMissingMandatoryTags) {
        this.updateForm.emit(this.getTagsToSave());
      } else {
        this.updateForm.emit(null);
      }
    });
  }
}
