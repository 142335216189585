import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CustomHeaderParams } from '../../../models/custom-headers.params';
import { HeaderShellComponent } from '../header-shell/header-shell.component';

@Component({
  standalone: true,
  selector: 'grid-engine-icon-header-cell',
  templateUrl: './icon-header-cell.component.html',
  imports: [FlexLayoutModule, TranslateModule, HeaderShellComponent, MatIcon, MatTooltip],
  styleUrls: ['./icon-header-cell.component.scss']
})
export class IconHeaderCellComponent implements IHeaderAngularComp {
  params: WritableSignal<CustomHeaderParams> = signal(null);
  sorted: WritableSignal<string> = signal('');

  agInit(params: CustomHeaderParams): void {
    this.params.set(params);
    this.params().column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  refresh(): boolean {
    return false;
  }

  onSortRequested(order, event): void {
    const params = this.params();
    params.setSort(order, event.shiftKey);
  }

  onSortChanged(): void {
    const params = this.params();
    if (params.column.isSortAscending()) {
      this.sorted.set('asc');
    } else if (params.column.isSortDescending()) {
      this.sorted.set('desc');
    } else {
      this.sorted.set('');
    }
  }
}
