<i4b-table-engine-master-view-table data-cy='i4b-table-engine-master-view-engine'
  [sticky]="sticky"
  [observerSelectors]="observerSelectors"
  [bluePrint]="bluePrint$ | async"
  [metadata]="metadata"
  [masterView]="masterView$ | async"
  [tableData]="tableData$ | async"
  [selectedElement]="selectedElement"
  [checkedElements]="checkedElements"
  [useExternalData]="useExternalData"
  [useFullyLoadedDataset]="useFullyLoadedDataset"
  [userPermissions]="userPermissions"
  [pendingRequest$]="pendingRequest$ | async"
  [pageSizeOptions]="pageSizeOptions"
  [withLoadMoreButton]="withLoadMoreButton"
  [loading]="loading"
  (dispatchCellEvent)="onDispatchCellEvent($event)"
  (pageChange)="onPageChanged($event)"
  (sortChange)="onSortChanged($event)"
></i4b-table-engine-master-view-table>
