import { Sort } from '@angular/material/sort';

import { gridSortModel, PlatformRequest } from '@iot-platform/models/common';
import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEvents = createAction('[AssetEvents] Load AssetEvents', props<{ request: PlatformRequest }>());
//
export const loadMVSettings = createAction('[AssetEvents] Load AssetEvents Settings', props<{ settingName: string }>());
export const saveMVSettings = createAction('[AssetEvents] Save AssetEvents Settings', props<{ name: string; values: any }>());
//
export const loadSiteById = createAction('[AssetEvents] Load Site By Id', props<{ siteId: string }>());
//
export const saveTableState = createAction('[AssetEvents] Save Table State', props<{ tableState: { checked: AssetEvent[]; selected: AssetEvent } }>());
//
export const loadDeviceById = createAction('[AssetEvents] Load Device By Id', props<{ deviceId: string }>());
//
export const loadDeviceVariableById = createAction('[AssetEvents] Load Device Variable By Id', props<{ deviceVariableId: string }>());
//
export const loadAssetById = createAction('[AssetEvents] Load Asset By Id', props<{ assetId: string }>());
//
export const loadAssetVariableById = createAction('[AssetEvents] Load Asset Variable By Id', props<{ assetVariableId: string }>());
//
export const loadTagsByAssetEventId = createAction('[AssetEvents] Load Tags By AssetEvent Id', props<{ assetEventId: string }>());
//
export const updateStatusByAssetEventId = createAction(
  '[AssetEvents] Update Status By AssetEvent Id',
  props<{ status: { assetEventId: string; value: string } }>()
);
//
export const bulkUpdateStatusByAssetEventId = createAction(
  '[AssetEvents] Bulk Update Status By AssetEvent Id',
  props<{ assetEventIds: string[]; status: string }>()
);
export const newBulkUpdateStatusByAssetEventId = createAction(
  '[AssetEvents] Bulk Update Status By AssetEvent Id v2',
  props<{ assetEventIds: string[]; status: string }>()
);

export const saveInitialSort = createAction('[AssetEvents] Save Initial sort', props<{ initialSort: gridSortModel[] | Sort }>());
