import { Injectable } from '@angular/core';
import { PlatformRequest } from '@iot-platform/models/common';
import { Event } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { AbstractTopicsService } from '../services/abstract-topics.service';
import { AssetEventsByTopicLogsUiActions, AssetEventsByTopicUiActions, DeviceEventsByTopicLogsUiActions, DeviceEventsByTopicUiActions } from './actions';
import * as fromTopics from './reducers';

@Injectable({ providedIn: 'root' })
export class TopicsFacade {
  // MASTER VIEWS ASSET EVENT BY TOPIC
  formattedAssetEventsByTopic$ = this.store.select(fromTopics.getFormattedAssetEventsByTopic);
  formattedAssetEventsByTopicLoaded$ = this.store.select(fromTopics.getAssetEventsByTopicLoaded);
  totalActiveAssetEventsByTopic$ = this.store.select(fromTopics.getTotalActiveAssetEventsByTopic);
  totalActiveAssetEventsByTopicLoaded$ = this.store.select(fromTopics.getTotalActiveAssetEventsByTopicLoaded);
  selectedAssetEventByTopic$ = this.store.select(fromTopics.getSelectedAssetEventByTopic);
  assetEventsByTopicTableState$ = this.store.select(fromTopics.getAssetEventsByTopicTableState);
  assetEventsByTopicAutoRefresh$ = this.store.select(fromTopics.getAssetEventsByTopicAutoRefresh);
  assetEventsByTopicRefreshDelay$ = this.store.select(fromTopics.getAssetEventsByTopicRefreshDelay);
  mvAssetEventsByTopicSettings$ = this.store.select(fromTopics.getMvAssetEventsByTopicSettings);
  assetEventsByTopicPagination$ = this.store.select(fromTopics.getAssetEventsByTopicPagination);
  assetEventsByTopicLogsByAssetEventId$ = this.store.select(fromTopics.getAllAssetEventsByTopicLogs);
  assetEventsByTopicLogsByAssetEventIdLoaded$ = this.store.select(fromTopics.getAssetEventsByTopicLogsLoaded);
  assetEventByTopicStatus$ = this.store.select(fromTopics.getAssetEventByTopicStatus);

  // MASTER VIEWS DEVICE EVENT BY TOPIC
  formattedDeviceEventsByTopic$ = this.store.select(fromTopics.getFormattedDeviceEventsByTopic);
  formattedDeviceEventsByTopicLoaded$ = this.store.select(fromTopics.getDeviceEventsByTopicLoaded);
  totalActiveDeviceEventsByTopic$ = this.store.select(fromTopics.getTotalActiveDeviceEventsByTopic);
  totalActiveDeviceEventsByTopicLoaded$ = this.store.select(fromTopics.getTotalActiveDeviceEventsByTopicLoaded);
  selectedDeviceEventByTopic$ = this.store.select(fromTopics.getSelectedDeviceEventByTopic);
  deviceEventsByTopicTableState$ = this.store.select(fromTopics.getDeviceEventsByTopicTableState);
  deviceEventsByTopicAutoRefresh$ = this.store.select(fromTopics.getDeviceEventsByTopicAutoRefresh);
  deviceEventsByTopicRefreshDelay$ = this.store.select(fromTopics.getDeviceEventsByTopicRefreshDelay);
  mvDeviceEventsByTopicSettings$ = this.store.select(fromTopics.getMvDeviceEventsByTopicSettings);
  deviceEventsByTopicPagination$ = this.store.select(fromTopics.getDeviceEventsByTopicPagination);
  deviceEventsByTopicLogsByDeviceEventId$ = this.store.select(fromTopics.getAllDeviceEventsByTopicLogs);
  deviceEventsByTopicLogsByDeviceEventIdLoaded$ = this.store.select(fromTopics.getDeviceEventsByTopicLogsLoaded);
  deviceEventByTopicStatus$ = this.store.select(fromTopics.getDeviceEventByTopicStatus);

  constructor(
    private readonly store: Store,
    private readonly topicsService: AbstractTopicsService
  ) {}

  // MASTER VIEW EVENTS BY TOPIC

  loadEvents(params: PlatformRequest, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.loadAssetEventsByTopic({ request: params }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.loadDeviceEventsByTopic({ request: params }));
    }
  }

  loadMetadata(view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.loadMvAssetEventsByTopicSettings({ settingName: 'assetEventsByTopic' }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.loadMvDeviceEventsByTopicSettings({ settingName: 'deviceEventsByTopic' }));
    }
  }

  loadEventDetailPopupDataByEvent(event: Event, view: string, filteredBy: string) {
    if (view === 'asset-events') {
      this.topicsService.loadEventDetailPopupDataByAssetEvent(event);
    }
    if (view === 'device-events') {
      this.topicsService.loadEventDetailPopupDataByDeviceEvent(event);
    }
    this.loadLogs(event, view, filteredBy);
  }

  saveEventsTableState(tableState: { checked: Event[]; selected: Event }, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.saveTableStateByTopic({ tableState }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.saveTableStateByTopic({ tableState }));
    }
  }

  updateEventsStatus(eventIds: string[], status: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.bulkUpdateStatusByAssetEventIdByTopic({ assetEventIds: eventIds, status }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.bulkUpdateStatusByDeviceEventIdByTopic({ deviceEventIds: eventIds, status }));
    }
  }

  loadLogs(event: Event, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicLogsUiActions.loadLogsByAssetEvent({ assetEvent: event }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicLogsUiActions.loadLogsByDeviceEvent({ deviceEvent: event }));
    }
  }

  createLog(event: Event, value: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicLogsUiActions.createLogByAssetEvent({ comment: { assetEvent: event, value } }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicLogsUiActions.createLogByDeviceEvent({ comment: { deviceEvent: event, value } }));
    }
  }
}
