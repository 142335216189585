import { Component, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { WidgetAction, WidgetEvent, WidgetInstance } from '@iot-platform/models/widgets';
import { WidgetLoaderDirective } from '../../directives/widget-loader.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  standalone: true,
  imports: [WidgetLoaderDirective, TranslateModule, FlexLayoutModule, MatMenuModule, MatIconModule, MatButtonModule],
  selector: 'iot-platform-ui-widget-item',
  templateUrl: './widget-item.component.html',
  styleUrls: ['./widget-item.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'hostCssClass()'
  }
})
export class WidgetItemComponent implements OnInit {
  widget = input<WidgetInstance>();
  dispatchEvent = output<WidgetEvent>();

  hostCssClass: WritableSignal<string> = signal('');

  get menu() {
    return this.widget()?.options.menu;
  }

  ngOnInit(): void {
    this.hostCssClass.set(`widget-item_${this.widget()?.type.toLowerCase()}`);
  }

  onWidgetClick(): void {
    this.dispatchEvent.emit({ action: WidgetAction.PREVIEW, widget: this.widget() as WidgetInstance });
  }

  onDispatchEvent(action: WidgetAction): void {
    this.dispatchEvent.emit({ action, widget: this.widget() as WidgetInstance });
  }
}
