import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule, MatTooltip, NgClass, MatButtonModule, MatIcon],
  selector: 'iot-platform-ui-subscription-button',
  templateUrl: './subscription-button.component.html',
  styleUrls: ['./subscription-button.component.scss']
})
export class SubscriptionButtonComponent {
  isCurrentUserSubscribed = input<boolean>(false);

  // eslint-disable-next-line @angular-eslint/no-output-native
  click = output<void>();
}
