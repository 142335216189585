import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from '@iot-platform/notification';
import { UserPreferencesService } from '@iot-platform/users';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TeamPlanningsService } from '../../services/team-plannings.service';

import { TeamPlanningsDbActions, TeamPlanningsUiActions } from '../actions';

@Injectable()
export class TeamPlanningsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.list),
      switchMap((action) =>
        this.teamPlanningsService.getAll(action.request).pipe(
          map((response) => TeamPlanningsDbActions.listSuccess({ response })),
          catchError((error) => of(TeamPlanningsDbActions.listFailure({ error })))
        )
      )
    )
  );

  getOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.getOne),
      switchMap((action) =>
        this.teamPlanningsService.getOne(action.teamPlanningId).pipe(
          map((selectedTeamPlanning) => TeamPlanningsDbActions.getOneSuccess({ selectedTeamPlanning })),
          catchError((error) => of(TeamPlanningsDbActions.getOneFailure({ error })))
        )
      )
    )
  );

  addTeamPlanning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.addOne),
      concatMap((action) =>
        this.teamPlanningsService.addOne(action.toAdd).pipe(
          map((addedTeamPlanning) => TeamPlanningsDbActions.addOneSuccess({ addedTeamPlanning })),
          catchError((error) => of(TeamPlanningsDbActions.addOneFailure({ error })))
        )
      )
    )
  );

  updateTeamPlanning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.updateOne),
      concatMap((action) =>
        this.teamPlanningsService.updateOne(action.toUpdate).pipe(
          map((updatedTeamPlanning) => TeamPlanningsDbActions.updateOneSuccess({ updatedTeamPlanning })),
          catchError((error) => of(TeamPlanningsDbActions.updateOneFailure({ error })))
        )
      )
    )
  );

  deleteTeamPlanning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.deleteOne),
      concatMap((action) =>
        this.teamPlanningsService.deleteOne(action.toDelete).pipe(
          map((deletedTeamPlanning) => TeamPlanningsDbActions.deleteOneSuccess({ deletedTeamPlanning })),
          catchError((error) => of(TeamPlanningsDbActions.deleteOneFailure({ error })))
        )
      )
    )
  );

  deleteTeamPlanningSuccessThenNavigateToMV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamPlanningsDbActions.deleteOneSuccess),
        tap(() => this.router.navigate(['on-call-management', 'team-plannings']))
      ),
    { dispatch: false }
  );

  loadMVTeamPlanningsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.loadMVSettings),
      switchMap((action) =>
        this.userPreferencesService.loadActiveSettings(action.settingName).pipe(
          map((mvSettings) => TeamPlanningsDbActions.loadMVSettingsSuccess({ mvSettings })),
          catchError((error) => of(TeamPlanningsDbActions.loadMVSettingsFailure({ error })))
        )
      )
    )
  );

  navigateToDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.navigateToDetails),
      tap((action) => this.router.navigate(['on-call-management', 'team-plannings', action.toNavigateTo.id])),
      concatMap((action) => [
        TeamPlanningsUiActions.listRotationsByPlanningId({ teamPlanningId: action.toNavigateTo.id }),
        TeamPlanningsUiActions.listOverridesByPlanningId({ teamPlanningId: action.toNavigateTo.id })
      ])
    )
  );

  listRotations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.listRotationsByPlanningId),
      switchMap((action) =>
        this.teamPlanningsService.getRotationsByPlanningId(action.teamPlanningId).pipe(
          map((rotations) => TeamPlanningsDbActions.listRotationsByPlanningIdSuccess({ rotations })),
          catchError((error) => of(TeamPlanningsDbActions.listRotationsByPlanningIdFailure({ error })))
        )
      )
    )
  );

  addRotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.addRotation),
      concatMap((action) =>
        this.teamPlanningsService.addRotation(action.toAdd, action.teamPlanningId).pipe(
          map((addedRotation) => TeamPlanningsDbActions.addRotationSuccess({ addedRotation })),
          catchError((error) => of(TeamPlanningsDbActions.addRotationFailure({ error })))
        )
      )
    )
  );

  updateRotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.updateRotation),
      concatMap((action) =>
        this.teamPlanningsService.updateRotation(action.toUpdate, action.teamPlanningId).pipe(
          map((updatedRotation) => TeamPlanningsDbActions.updateRotationSuccess({ updatedRotation })),
          catchError((error) => of(TeamPlanningsDbActions.updateRotationFailure({ error })))
        )
      )
    )
  );

  deleteRotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.deleteRotation),
      concatMap((action) =>
        this.teamPlanningsService.deleteRotation(action.toDelete, action.teamPlanningId).pipe(
          map((deletedRotation) => TeamPlanningsDbActions.deleteRotationSuccess({ deletedRotation })),
          catchError((error) => of(TeamPlanningsDbActions.deleteRotationFailure({ error })))
        )
      )
    )
  );

  listOverrides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.listOverridesByPlanningId),
      switchMap((action) =>
        this.teamPlanningsService.getOverridesByPlanningId(action.teamPlanningId).pipe(
          map((overrides) => TeamPlanningsDbActions.listOverridesByPlanningIdSuccess({ overrides })),
          catchError((error) => of(TeamPlanningsDbActions.listOverridesByPlanningIdFailure({ error })))
        )
      )
    )
  );

  addOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.addOverride),
      concatMap((action) =>
        this.teamPlanningsService.addOverride(action.toAdd, action.teamPlanningId).pipe(
          map((addedOverride) => TeamPlanningsDbActions.addOverrideSuccess({ addedOverride })),
          catchError((error) => of(TeamPlanningsDbActions.addOverrideFailure({ error })))
        )
      )
    )
  );

  addManyOverrides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.addManyOverrides),
      concatMap((action) =>
        this.teamPlanningsService.addManyOverrides(action.toAdd, action.teamPlanningId).pipe(
          map((addedOverrides) => TeamPlanningsDbActions.addManyOverridesSuccess({ addedOverrides })),
          catchError((error) => of(TeamPlanningsDbActions.addManyOverridesFailure({ error })))
        )
      )
    )
  );

  updateOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.updateOverride),
      concatMap((action) =>
        this.teamPlanningsService.updateOverride(action.toUpdate, action.teamPlanningId).pipe(
          map((updatedOverride) => TeamPlanningsDbActions.updateOverrideSuccess({ updatedOverride })),
          catchError((error) => of(TeamPlanningsDbActions.updateOverrideFailure({ error })))
        )
      )
    )
  );

  updateManyOverrides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.updateManyOverrides),
      concatMap((action) =>
        this.teamPlanningsService.updateManyOverrides(action.toUpdate, action.teamPlanningId).pipe(
          map((updatedOverrides) => TeamPlanningsDbActions.updateManyOverridesSuccess({ updatedOverrides })),
          catchError((error) => of(TeamPlanningsDbActions.updateManyOverridesFailure({ error })))
        )
      )
    )
  );

  deleteOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamPlanningsUiActions.deleteOverride),
      concatMap((action) =>
        this.teamPlanningsService.deleteOverride(action.toDelete, action.teamPlanningId).pipe(
          map((deletedOverride) => TeamPlanningsDbActions.deleteOverrideSuccess({ deletedOverride })),
          catchError((error) => of(TeamPlanningsDbActions.deleteOverrideFailure({ error })))
        )
      )
    )
  );

  navigateToDetailsAfterCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamPlanningsDbActions.addOneSuccess),
        tap((action) => {
          this.router.navigate(['on-call-management', 'team-plannings', action.addedTeamPlanning.id]);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TeamPlanningsDbActions.addOneSuccess,
          TeamPlanningsDbActions.updateOneSuccess,
          TeamPlanningsDbActions.deleteOneSuccess,
          TeamPlanningsDbActions.addRotationSuccess,
          TeamPlanningsDbActions.updateRotationSuccess,
          TeamPlanningsDbActions.deleteRotationSuccess,
          TeamPlanningsDbActions.addOverrideSuccess,
          TeamPlanningsDbActions.addManyOverridesSuccess,
          TeamPlanningsDbActions.updateOverrideSuccess,
          TeamPlanningsDbActions.updateManyOverridesSuccess,
          TeamPlanningsDbActions.deleteOverrideSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TeamPlanningsDbActions.addOneFailure,
          TeamPlanningsDbActions.updateOneFailure,
          TeamPlanningsDbActions.listFailure,
          TeamPlanningsDbActions.deleteOneFailure,
          TeamPlanningsDbActions.getOneFailure,
          TeamPlanningsDbActions.listRotationsByPlanningIdFailure,
          TeamPlanningsDbActions.addRotationFailure,
          TeamPlanningsDbActions.updateRotationFailure,
          TeamPlanningsDbActions.deleteRotationFailure,
          TeamPlanningsDbActions.listOverridesByPlanningIdFailure,
          TeamPlanningsDbActions.addOverrideFailure,
          TeamPlanningsDbActions.addManyOverridesFailure,
          TeamPlanningsDbActions.updateOverrideFailure,
          TeamPlanningsDbActions.updateManyOverridesFailure,
          TeamPlanningsDbActions.deleteOverrideFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TeamPlanningsUiActions.list,
          TeamPlanningsUiActions.addOne,
          TeamPlanningsUiActions.updateOne,
          TeamPlanningsUiActions.deleteOne,
          TeamPlanningsUiActions.getOne,
          TeamPlanningsUiActions.listRotationsByPlanningId,
          TeamPlanningsUiActions.addRotation,
          TeamPlanningsUiActions.updateRotation,
          TeamPlanningsUiActions.deleteRotation,
          TeamPlanningsUiActions.listOverridesByPlanningId,
          TeamPlanningsUiActions.addOverride,
          TeamPlanningsUiActions.addManyOverrides,
          TeamPlanningsUiActions.updateOverride,
          TeamPlanningsUiActions.updateManyOverrides,
          TeamPlanningsUiActions.deleteOverride
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TeamPlanningsDbActions.listSuccess,
          TeamPlanningsDbActions.listFailure,
          TeamPlanningsDbActions.getOneSuccess,
          TeamPlanningsDbActions.getOneFailure,
          TeamPlanningsDbActions.addOneSuccess,
          TeamPlanningsDbActions.addOneFailure,
          TeamPlanningsDbActions.updateOneSuccess,
          TeamPlanningsDbActions.updateOneFailure,
          TeamPlanningsDbActions.deleteOneSuccess,
          TeamPlanningsDbActions.deleteOneFailure,
          TeamPlanningsDbActions.listRotationsByPlanningIdSuccess,
          TeamPlanningsDbActions.listRotationsByPlanningIdFailure,
          TeamPlanningsDbActions.addRotationSuccess,
          TeamPlanningsDbActions.addRotationFailure,
          TeamPlanningsDbActions.updateRotationSuccess,
          TeamPlanningsDbActions.updateRotationFailure,
          TeamPlanningsDbActions.deleteRotationSuccess,
          TeamPlanningsDbActions.deleteRotationFailure,
          TeamPlanningsDbActions.listOverridesByPlanningIdSuccess,
          TeamPlanningsDbActions.listOverridesByPlanningIdFailure,
          TeamPlanningsDbActions.addOverrideSuccess,
          TeamPlanningsDbActions.addOverrideFailure,
          TeamPlanningsDbActions.addManyOverridesSuccess,
          TeamPlanningsDbActions.addManyOverridesFailure,
          TeamPlanningsDbActions.updateOverrideSuccess,
          TeamPlanningsDbActions.updateOverrideFailure,
          TeamPlanningsDbActions.updateManyOverridesSuccess,
          TeamPlanningsDbActions.updateManyOverridesFailure,
          TeamPlanningsDbActions.deleteOverrideSuccess,
          TeamPlanningsDbActions.deleteOverrideFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly teamPlanningsService: TeamPlanningsService,
    private readonly notificationService: NotificationService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly router: Router
  ) {}
}
