import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, Provider } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Environment } from '@iot-platform/models/common';
import { filter, map, pairwise, shareReplay, startWith } from 'rxjs/operators';

export const WINDOW_REF = new InjectionToken('WINDOW_REF');

export const DummyWindowProviders: Provider[] = [
  {
    provide: WINDOW_REF,
    useValue: {
      open: () => ({}),
      URL: { createObjectURL: () => ({}) },
      location: { href: [] },
      scrollTo: () => ({})
    }
  }
];

export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT');

export const CURRENT_URL = new InjectionToken('Subscribe to Router Navigation End Url', {
  factory() {
    const router = inject(Router);

    return router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((evt: NavigationEnd) => evt.url)
    );
  }
});

export const PREVIOUS_URL = new InjectionToken('Subscribe to Routes Recognized Previous Url', {
  factory() {
    const router = inject(Router);

    return router.events.pipe(
      filter((e): e is RoutesRecognized => e instanceof RoutesRecognized),
      map((evt: RoutesRecognized) => evt.urlAfterRedirects),
      pairwise(),
      map((e) => e[0]),
      startWith(''),
      shareReplay(1)
    );
  }
});

export function provideDefaultProvidersToken(providers: { environment: Environment }): EnvironmentProviders {
  return makeEnvironmentProviders([
    { provide: WINDOW_REF, useValue: window },
    { provide: ENVIRONMENT, useValue: providers.environment },
    { provide: 'environment', useValue: providers.environment } // Used for shared libraries
  ]);
}
