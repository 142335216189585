import { BaseAssetVariable, LastValue } from '../common';
import { AssetVariableThresholds } from './asset-variable-thresholds.model';
import { Asset } from './asset.model';
import { Formula } from './formula.model';

export interface AssetVariable extends BaseAssetVariable {
  asset: Asset;
  followedNumber: number | null;
  formula: Formula;
  lastValue: LastValue | null;
  linked: boolean;
  thresholds: AssetVariableThresholds;
}

export interface AssetVariableCreationModel {
  asset: Asset;
  comment: string;
  followedNumber: number | null;
  formula?: Formula;
  lastValue?: LastValue | null;
  linked: boolean;
  name: string;
  thresholds?: AssetVariableThresholds;
  unit: string;
}

export const NORMALIZED_VARIABLE_NAMES: string[] = [
  'ASSET_AUTONOMY',
  'ASSET_AUTONOMY_STATUS',
  'AUTONOMY',
  'BACKUP_PG_1',
  'BACKUP_PG_2',
  'BACKUP_TANK_1',
  'BACKUP_TANK_2',
  'BACKUP_TANK_3',
  'BACKUP_TANK_4',
  'BACKUP_TANK_5',
  'BACKUP_TANK_6',
  'CO2_RATE',
  'COMPRESSED_AIR_FLOW',
  'CONSUMPTION',
  'CONTRACTUAL_FLOW',
  'CO_RATE',
  'CUMUL_LEVEL',
  'CUMUL_PRESSURE',
  'CUMUL_WEIGHT',
  'DEW_POINT',
  'EMERGENCY_PRESSURE',
  'LEFT_AUTONOMY',
  'LEFT_MINIMUM_PRESSURE',
  'LEFT_PRESSURE',
  'LEFT_VOLUME',
  'LEFT_WEIGHT',
  'LEVEL',
  'MONTHLY_PRODUCTION_DOWNTIME',
  'MONTHLY_PRODUCTION_TIME',
  'MONTHLY_SHORTFALL',
  'MONTHLY_SHUTDOWN_COUNT',
  'MONTHLY_SHUTDOWN_TIME',
  'NETWORK_PRESSURE',
  'PERSONNEL_ON_SITE',
  'PRESSURE',
  'PRODUCTION_FLOW',
  'PRODUCTION_PURITY_A',
  'PRODUCTION_PURITY_B',
  'PRODUCTION_VALVE',
  'PRODUCT_BL_PRESSURE',
  'PRODUCT_BL_PURITY',
  'PRODUCT_BL_TEMPERATURE',
  'RIGHT_AUTONOMY',
  'RIGHT_MINIMUM_PRESSURE',
  'RIGHT_PRESSURE',
  'RIGHT_VOLUME',
  'RIGHT_WEIGHT',
  'RUN_STEP',
  'SHORTFALL',
  'SHUTDOWN_STATUS',
  'TOTAL_FLOW',
  'VAPORIZATION_TEMPERATURE'
];

export interface NormalizedAssetVariablesByCategory {
  categoryName: string;
  variables: { name: string; unit: string; description: string }[];
}

export const NORMALIZED_ASSET_VARIABLES: NormalizedAssetVariablesByCategory[] = [
  {
    categoryName: 'bulk',
    variables: [
      { name: 'AUTONOMY', unit: '', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.AUTONOMY' },
      { name: 'BACKUP_TANK_1', unit: '%', description: '' },
      { name: 'BACKUP_TANK_2', unit: '%', description: '' },
      { name: 'BACKUP_TANK_3', unit: '%', description: '' },
      { name: 'BACKUP_TANK_4', unit: '%', description: '' },
      { name: 'BACKUP_TANK_5', unit: '%', description: '' },
      { name: 'BACKUP_TANK_6', unit: '%', description: '' },
      { name: 'CO2_RATE', unit: 'm3/h', description: '' },
      { name: 'CUMUL_LEVEL', unit: '%', description: '' },
      { name: 'CUMUL_PRESSURE', unit: 'bar', description: '' },
      { name: 'CUMUL_WEIGHT', unit: 'kg', description: '' },
      { name: 'LEVEL', unit: '%', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.LEVEL' },
      { name: 'LEVEL_DP', unit: 'bar', description: '' },
      { name: 'NETWORK_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.NETWORK_PRESSURE' },
      { name: 'PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.PRESSURE' },
      { name: 'VAPORIZATION_TEMPERATURE', unit: '°C', description: '' }
    ]
  },
  {
    categoryName: 'pg',
    variables: [
      { name: 'ASSET_AUTONOMY', unit: 'day', description: '' },
      {
        name: 'ASSET_AUTONOMY_STATUS',
        unit: '/[0]Critical/[1]Critical/[2]Critical/[3]Must Order/[4]Must Order/[5]Must Order/[6]Must Order/[7]Must Order/[8]Should Order/[9]Should Order/[10]Should Order/[11]Should Order/[12]Should Order/Ok',
        description: ''
      },
      { name: 'AUTONOMY', unit: '', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.AUTONOMY' },
      { name: 'BACKUP_PG_1', unit: 'bar', description: '' },
      { name: 'BACKUP_PG_2', unit: 'bar', description: '' },
      { name: 'CONSUMPTION', unit: '', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.CONSUMPTION' },
      { name: 'EMERGENCY_PRESSURE', unit: 'bar', description: '' },
      { name: 'LEFT_AUTONOMY', unit: 'day', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.LEFT_AUTONOMY' },
      { name: 'LEFT_MINIMUM_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.LEFT_MINIMUM_PRESSURE' },
      { name: 'LEFT_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.LEFT_PRESSURE' },
      { name: 'LEFT_VOLUME', unit: 'm3', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.LEFT_VOLUME' },
      { name: 'LEFT_WEIGHT', unit: 'kg', description: '' },
      { name: 'NETWORK_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.NETWORK_PRESSURE' },
      { name: 'RIGHT_AUTONOMY', unit: 'day', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.RIGHT_AUTONOMY' },
      { name: 'RIGHT_MINIMUM_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.RIGHT_MINIMUM_PRESSURE' },
      { name: 'RIGHT_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.RIGHT_PRESSURE' },
      { name: 'RIGHT_VOLUME', unit: 'm3', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.RIGHT_VOLUME' },
      { name: 'RIGHT_WEIGHT', unit: 'kg', description: '' }
    ]
  },
  {
    categoryName: 'onsite',
    variables: [
      { name: 'BACKUP_TANK_1', unit: '%', description: '' },
      { name: 'BACKUP_TANK_2', unit: '%', description: '' },
      { name: 'BACKUP_TANK_3', unit: '%', description: '' },
      { name: 'BACKUP_TANK_4', unit: '%', description: '' },
      { name: 'BACKUP_TANK_5', unit: '%', description: '' },
      { name: 'BACKUP_TANK_6', unit: '%', description: '' },
      { name: 'CO_RATE', unit: 'ppm', description: '' },
      { name: 'COMPRESSED_AIR_FLOW', unit: 'm3/h', description: '' },
      { name: 'CONTRACTUAL_FLOW', unit: 'ppm', description: '' },
      { name: 'DEW_POINT', unit: '°C', description: '' },
      { name: 'MONTHLY_PRODUCTION_DOWNTIME', unit: 'h', description: '' },
      { name: 'MONTHLY_PRODUCTION_TIME', unit: 'h', description: '' },
      { name: 'MONTHLY_SHORTFALL', unit: 'scf', description: '' },
      { name: 'MONTHLY_SHUTDOWN_COUNT', unit: '-', description: '' },
      { name: 'MONTHLY_SHUTDOWN_TIME', unit: 'h', description: '' },
      { name: 'NETWORK_PRESSURE', unit: 'bar', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.NETWORK_PRESSURE' },
      { name: 'PERSONNEL_ON_SITE', unit: '/Onsite/Offsite', description: '' },
      { name: 'PRODUCT_BL_PRESSURE', unit: 'scfh', description: '' },
      { name: 'PRODUCT_BL_PURITY', unit: 'ppm', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.PRODUCT_BL_PURITY' },
      { name: 'PRODUCT_BL_TEMPERATURE', unit: '°C', description: '' },
      { name: 'PRODUCTION_FLOW', unit: '/Closed/Open', description: '' },
      { name: 'PRODUCTION_PURITY_A', unit: 'bar', description: '' },
      { name: 'PRODUCTION_PURITY_B', unit: '%', description: '' },
      { name: 'PRODUCTION_VALVE', unit: '', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.PRODUCTION_VALVE' },
      { name: 'RUN_STEP', unit: '-', description: '' },
      { name: 'SHORTFALL', unit: 'scfh', description: '' },
      { name: 'SHUTDOWN_STATUS', unit: '', description: 'ASSETS.NORMALIZED_VARIABLES.DESCRIPTIONS.SHUTDOWN_STATUS' },
      { name: 'TOTAL_FLOW', unit: 'scfh', description: '' }
    ]
  }
];

export const FOLLOWED_NUMBERS: number[] = [1, 2, 3];
