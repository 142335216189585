import { Injectable } from '@angular/core';
import { Entity, Organization } from '@iot-platform/models/common';

import { SortUtil } from './sort.util';

@Injectable()
export class SortEntitiesUtil {
  static sortHierarchically(entities: Entity[] | Organization[]): Entity[] | Organization[] {
    const result = [];
    const newEntities = Object.assign([], entities).sort(SortUtil.sortByName);

    const array: number[] = newEntities.map((o: Entity | Organization) => o.level);
    const maxLevel = Math.max(...array);
    const minLevel = Math.min(...array);

    let lvl: number;

    for (lvl = minLevel; lvl <= maxLevel; lvl++) {
      let entitiesByLevel = [];
      let parentIdIndex: number = null;

      entitiesByLevel = newEntities.filter((e) => e.level === lvl);

      if (lvl !== minLevel) {
        entitiesByLevel.reverse();
      }

      entitiesByLevel.forEach((e) => {
        if (e.parentId) {
          parentIdIndex = result.findIndex((parentEntity) => parentEntity.id === e.parentId);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          parentIdIndex !== -1 ? result.splice(parentIdIndex + 1, 0, e) : result.push(e);
        } else {
          result.push(e);
        }
      });
    }

    return result;
  }

  static arrayToTree(entities: Organization[]) {
    const newEntities = entities
      .reduce((acc, value) => {
        const entityWithChildren = {
          ...value,
          children: []
        };
        acc.push(entityWithChildren);
        return acc;
      }, [])
      .sort(SortUtil.sortByName)
      .sort(SortUtil.sortByProperty('level'));

    const map = {};
    const result = [];
    let node;
    let i;

    for (i = 0; i < newEntities.length; i += 1) {
      map[newEntities[i].id] = i;
    }

    for (i = 0; i < newEntities.length; i += 1) {
      node = newEntities[i];
      if (node.parentId !== null) {
        if (newEntities[map[node.parentId]]) {
          newEntities[map[node.parentId]].children.push(node);
        } else {
          result.push(node);
        }
      } else {
        result.push(node);
      }
    }

    return result;
  }
}
