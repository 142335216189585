import { Injectable } from '@angular/core';
import { BaseFacade } from './base-facade.model';
import { DummyFeatureSelector } from './dummy-feature.selector';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DummyFacade extends BaseFacade<never, never, never> {
  constructor(
    protected override store: Store,
    protected override selector: DummyFeatureSelector
  ) {
    super(store, selector);
  }

  getAll() {
    noop();
  }

  setFilters() {
    noop();
  }
}
