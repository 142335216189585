import { computed, Injectable, Signal } from '@angular/core';
import { AuthorizationConcept, AuthorizationType } from '@iot-platform/auth';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { CommonApiRequest, FavoriteView, Filter, Pagination, Product, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Asset, CommandType, Log } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { I4BBaseFacade } from '../../../../models/ngrx/i4b-base-facade.model';
import { AssetsActions, AssetsCommentsActions } from '../actions';
import * as fromAssets from '../reducers';
import { AssetsSelectors } from '../selectors/assets.selectors';

@Injectable({
  providedIn: 'root'
})
export class AssetsFacade extends I4BBaseFacade<Asset, Pagination, Filter> {
  concept = 'assets';

  canCreate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.CREATE);
  });
  canRead = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.READ);
  });
  canUpdate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.UPDATE);
  });
  canDelete = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.DELETE);
  });

  grids$: Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.select(fromGrids.getAssetsGrids);
  grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.selectSignal(fromGrids.getAssetsGrids);
  grid$: Observable<I4BGrid<I4BGridOptions, I4BGridData> | undefined> = this.store.select(fromGrids.getDefaultAssetsGrid);
  grid: Signal<I4BGrid<I4BGridOptions, I4BGridData> | undefined> = this.store.selectSignal(fromGrids.getDefaultAssetsGrid);
  gridConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> = this.store.select(fromGrids.selectAssetGridsConfiguration);
  gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> = this.store.selectSignal(fromGrids.selectAssetGridsConfiguration);
  favoriteViews$: Observable<FavoriteView[]> = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssets);
  favoriteViews: Signal<FavoriteView[]> = this.store.selectSignal(fromFavoriteViews.getFavoriteViewsForMasterViewAssets);
  currentFavoriteView$: Observable<FavoriteView> = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewAssets);
  currentFavoriteView: Signal<FavoriteView> = this.store.selectSignal(fromFavoriteViews.getSelectedFavoriteViewForMasterViewAssets);
  favoriteViewConfiguration$: Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> = this.store.select(fromFavoriteViews.selectAssetFavoriteViewsConfiguration);
  favoriteViewConfiguration: Signal<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> = this.store.selectSignal(fromFavoriteViews.selectAssetFavoriteViewsConfiguration);
  // Specific
  filters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets);
  filters = this.store.selectSignal(fromFavoriteViews.getFiltersForMasterViewAssets);

  tags = this.store.selectSignal(this.selector.selectTagsByAsset);
  tags$ = this.store.select(this.selector.selectTagsByAsset);
  tagsLoading$ = this.store.select(this.selector.selectTagsLoading);
  tagsLoaded$ = this.store.select(this.selector.selectTagsLoaded);

  site$ = this.store.select(this.selector.selectSite);
  siteLoading$ = this.store.select(this.selector.selectSiteLoading);
  siteLoaded$ = this.store.select(this.selector.selectSiteLoaded);
  assetCreationByTemplateStatuses$ = this.store.select(this.selector.selectAssetCreationByTemplateStatuses);

  // Comments
  assetComments: Signal<Log[]> = this.store.selectSignal(fromAssets.selectAllAssetComments);
  assetCommentsLoading: Signal<boolean> = this.store.selectSignal(fromAssets.selectAssetCommentsLoading);

  constructor(
    protected store: Store,
    protected selector: AssetsSelectors
  ) {
    super(store, selector);
  }

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'assets' }));
  }

  addAsset(toAdd: Asset) {
    this.store.dispatch(AssetsActions.addOne({ toAdd }));
  }

  addAssetByTemplateId(assetTemplateId: string, siteId: string) {
    this.store.dispatch(AssetsActions.addOneByTemplateId({ assetTemplateId, siteId }));
  }

  loadAssetById(id: string) {
    this.store.dispatch(AssetsActions.getOne({ id }));
  }

  loadAssets(request: CommonApiRequest) {
    this.store.dispatch(GridsDbActions.loadGridData({ request: { concept: 'assets', ...request } }));
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(AssetsActions.getSiteById({ siteId }));
  }

  loadTagsByAssetId(assetId: string) {
    this.store.dispatch(AssetsActions.getTags({ assetId }));
  }

  updateTagsByAssetId(assetId: string, tags: TagCategory[]) {
    this.store.dispatch(AssetsActions.updateTagsByAssetId({ assetId, tags }));
  }

  updateAsset(asset: Asset) {
    this.store.dispatch(AssetsActions.updateOne({ toUpdate: asset }));
  }

  updateAssetFromMV(asset: Asset, request: CommonApiRequest) {
    this.store.dispatch(AssetsActions.updateOneFromMV({ assetToUpdate: asset, request }));
  }

  deleteAsset(asset: Asset) {
    this.store.dispatch(AssetsActions.deleteOne({ toDelete: asset }));
  }

  assignProductToAsset(product: Product, asset: Asset) {
    this.store.dispatch(AssetsActions.assignProductToAsset({ product, asset }));
  }

  removeProductFromAsset(product: Product, asset: Asset) {
    this.store.dispatch(AssetsActions.removeProductFromAsset({ product, asset }));
  }

  selectAsset(selectedAsset: Asset) {
    this.store.dispatch(AssetsActions.selectOne({ toSelect: selectedAsset }));
  }

  saveMVSettings(name: string, values: any) {
    this.store.dispatch(AssetsActions.saveMVAssetsSettings({ name, values }));
  }

  sendCommand(asset: Asset, command: { command: CommandType }) {
    this.store.dispatch(AssetsActions.sendCommand({ asset, command }));
  }

  bulkSendCommand(assetsIds: string[], command: { command: CommandType }): void {
    this.store.dispatch(AssetsActions.bulkSendCommand({ assetsIds, command }));
  }

  loadComments(asset: Asset): void {
    this.store.dispatch(AssetsCommentsActions.loadComments({ asset }));
  }

  addComment(assetId: string, comment: string): void {
    this.store.dispatch(AssetsCommentsActions.addComment({ assetId, comment }));
  }

  editComment(assetId: string, comment: Log): void {
    this.store.dispatch(AssetsCommentsActions.editComment({ assetId, comment }));
  }

  deleteComment(assetId: string, commentId: string): void {
    this.store.dispatch(AssetsCommentsActions.deleteComment({ assetId, commentId }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'assets', filters }));
  }

  getAll(): void {
    noop();
  }
}
