<div class="grid-icon-cell" fxLayoutAlign="center center">
  <div
    [matTooltipClass]="'warning-tooltip'"
    [matTooltipDisabled]="!params()?.value"
    [matTooltip]="
      'DEVICES.TABLE_CONTENT.CYCLICAL_CALL_FAULT_TOOLTIP' | translate: { dateValue: (params()?.data?.cyclicalCallFault?.datetime | dateFormat) ?? 'n/a' }
    "
    matTooltipPosition="above"
  >
    @if (params()?.value) {
      <mat-icon svgIcon="cyclicalCallFault" color="warn" class="grid-icon" />
    }
  </div>
</div>
