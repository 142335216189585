import { CommonApiRequest, Filter } from '@iot-platform/models/common';
import { Override, Rotation, TeamPlanning } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const list = createAction('[TeamPlannings] List Team Plannings', props<{ request: CommonApiRequest }>());
export const getOne = createAction('[TeamPlannings] Get Team Planning', props<{ teamPlanningId: string }>());

export const navigateToDetails = createAction('[TeamPlannings] Navigate To Details', props<{ toNavigateTo: TeamPlanning }>());
export const addOne = createAction('[TeamPlannings] Add Team Planning', props<{ toAdd: TeamPlanning }>());
export const updateOne = createAction('[TeamPlannings] Update Team Planning', props<{ toUpdate: TeamPlanning }>());
export const deleteOne = createAction('[TeamPlannings] Delete Team Planning', props<{ toDelete: TeamPlanning }>());
export const selectTeamPlanning = createAction('[TeamPlannings] Select Team Planning', props<{ teamPlanningId: string }>());

export const loadMVSettings = createAction('[TeamPlannings] Load Team Plannings Settings', props<{ settingName: string }>());
export const saveMVSettings = createAction('[TeamPlannings] Save Team Plannings Settings', props<{ name: string; values: any }>());

export const saveTeamPlanningFilters = createAction('[TeamPlannings] Save Team Plannings Filters', props<{ filters: Filter[] }>());
//
export const listRotationsByPlanningId = createAction('[TeamPlannings] List Rotations by Team Planning Id', props<{ teamPlanningId: string }>());
export const addRotation = createAction('[TeamPlannings] Add Rotation', props<{ toAdd: Rotation; teamPlanningId: string }>());
export const updateRotation = createAction('[TeamPlannings] Update Rotation', props<{ toUpdate: Rotation; teamPlanningId: string }>());
export const deleteRotation = createAction('[TeamPlannings] Delete Rotation', props<{ toDelete: Rotation; teamPlanningId: string }>());
//
export const listOverridesByPlanningId = createAction('[TeamPlannings] List Override by Team Planning Id', props<{ teamPlanningId: string }>());
export const addOverride = createAction('[TeamPlannings] Add Override', props<{ toAdd: Override; teamPlanningId: string }>());
export const addManyOverrides = createAction('[TeamPlannings] Add Many Overrides', props<{ toAdd: Override[]; teamPlanningId: string }>());
export const updateOverride = createAction('[TeamPlannings] Update Override', props<{ toUpdate: Override; teamPlanningId: string }>());
export const updateManyOverrides = createAction('[TeamPlannings] Update Many Overrides', props<{ toUpdate: Override[]; teamPlanningId: string }>());
export const deleteOverride = createAction('[TeamPlannings] Delete Override', props<{ toDelete: Override; teamPlanningId: string }>());
