import { I4BCellType, I4BStatusColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class I4BDeviceStatusColumn implements I4BColumn<I4BBasicColumnHeader, I4BStatusColumnConfiguration, I4BColumnOptions> {
  columnId = 'b9b4bd88-9fb3-11ec-9da8-0023a40210d0-device-status';
  className = I4BDeviceStatusColumn;

  dataLibs: DataLibrary[] = [DataLibrary.DEVICES];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC, displayName: 'STATUS' });
  configuration: I4BStatusColumnConfiguration = {
    isLink: false,
    position: '',
    sortProperty: '',
    id: 'status.name',
    translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.STATUS',
    concept: I4bCellConcept.DEVICES,
    isDefault: true,
    isConfigurable: true,
    width: 150,
    cell: {
      type: I4BCellType.TRANSLATED_CELL,
      options: {
        withStatusPadding: true,
        class: {
          running: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
          maintenance: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.4)', padding: '9px' },
          standby: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' },
          test_mode: { color: '#262836', 'background-color': 'rgba(255, 158, 0, 0.1)', padding: '9px' },
          decommissioned: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' }
        }
      }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BStatusColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        displayName: customHeader.displayName ? customHeader.displayName : this.header.displayName ? this.header.displayName : 'STATUS',
        ...this.header,
        ...customHeader
      });
    }

    if (customConfig) {
      this.configuration = {
        ...this.configuration,
        ...customConfig,
        width: customOptions?.width ? customOptions.width : customConfig.width ? customConfig.width : 150
      };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions, customId: customConfig.id ? customConfig.id : 'status.name' };
    }
  }
}
