import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromSites from '../reducers/sites.reducer';

@Injectable({
  providedIn: 'root'
})
export class SitesSelectors extends ApiFeatureSelector<fromSites.State, Site, Pagination, Filter> {
  selectTagsBySite: MemoizedSelector<fromSites.State, TagCategory[]> = createSelector(this.selectState, (state: fromSites.State): TagCategory[] => state.tags);
  selectAssetsBySite: MemoizedSelector<fromSites.State, PlatformResponse> = createSelector(
    this.selectState,
    (state: fromSites.State): PlatformResponse => state.assetsBySite
  );
  selectDevicesBySite: MemoizedSelector<fromSites.State, PlatformResponse> = createSelector(
    this.selectState,
    (state: fromSites.State): PlatformResponse => state.devicesBySite
  );

  selectAssetLoaded: MemoizedSelector<fromSites.State, boolean> = createSelector(this.selectState, (state: fromSites.State): boolean => state.assetsLoaded);
  selectAssetLoading: MemoizedSelector<fromSites.State, boolean> = createSelector(this.selectState, (state: fromSites.State): boolean => state.assetsLoading);
  selectDeviceLoaded: MemoizedSelector<fromSites.State, boolean> = createSelector(this.selectState, (state: fromSites.State): boolean => state.devicesLoaded);
  selectDeviceLoading: MemoizedSelector<fromSites.State, boolean> = createSelector(this.selectState, (state: fromSites.State): boolean => state.devicesLoading);
  selectTagsLoaded: MemoizedSelector<fromSites.State, boolean> = createSelector(this.selectState, (state: fromSites.State): boolean => state.tagsLoaded);
  selectTagsLoading: MemoizedSelector<fromSites.State, boolean> = createSelector(this.selectState, (state: fromSites.State): boolean => state.tagsLoading);
  selectError: MemoizedSelector<fromSites.State, any> = createSelector(this.selectState, (state: fromSites.State) => state.error);

  constructor() {
    super(fromSites.sitesV2feature.selectSitesV2State, fromSites.adapter);
  }
}
