<mat-chip-option
  (click)="onChipClick($event, chip())"
  [ngStyle]="{ color: color(), backgroundColor: bckgColor() }"
  [selectable]="false"
  class="chip"
  disableRipple="true"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
    @if (iconName()) {
      <mat-icon [svgIcon]="iconName()"></mat-icon>
    }
    <span class="chip-text">
      <span class="chip-category">{{ category() | translate }}</span> :
      @if (dateLabel()) {
        <span>{{ dateLabel() | dateFormat | translate }}</span>
      } @else {
        <span>{{ label() | translate }}</span>
      }
    </span>
    @if (chipButton()?.display) {
      <mat-icon (click)="onChipButtonClick($event, chip())" class="chip-button">{{ chipButton()?.name }}</mat-icon>
    }
  </div>
</mat-chip-option>
