import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetStatusService {
  status = null;

  constructor(@Inject('environment') private environment, public httpClient: HttpClient) {}

  getAll(): Observable<any> {
    if (this.status) {
      return new Observable<any>(subscriber => {
        subscriber.next(this.status);
      });
    } else {
      return this.httpClient.get(`${this.environment.api.url}${this.environment.api.endpoints.assetStatus}`).pipe(
        map((data: any) => data)
      );
    }
  }
}
