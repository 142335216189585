import { Component, DestroyRef, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CardLoaderModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { Filter, PlatformRequest } from '@iot-platform/models/common';
import {
  Asset,
  AssetStatusName,
  AssetVariable,
  AssetVariableThreshold,
  Device,
  DeviceVariable,
  FormulaParameters,
  NORMALIZED_ASSET_VARIABLES
} from '@iot-platform/models/i4b';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, TruncatePipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { map, of, switchMap, tap } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { GridEngineSettingsService } from '../../../services/grid-engine-settings.service';

// TODO to be moved under iot4bos scope
@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    CardLoaderModule,
    ValueUnitFormatPipe,
    DateFormatPipe,
    DetailPopupModule,
    InfoDisplayPipe,
    NumberFormatPipe,
    TruncatePipe,
    MatTooltip,
    MatIcon,
    MatIconButton,
    MatInput
  ],
  providers: [ValueUnitFormatPipe, DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, TruncatePipe],
  selector: 'grid-engine-asset-variable-configure-read-popup',
  templateUrl: './asset-variable-configure-read-popup.component.html',
  styleUrls: ['./asset-variable-configure-read-popup.component.scss']
})
export class AssetVariableConfigureReadPopupComponent implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  private readonly assetVariableService: AssetVariablesService = inject(AssetVariablesService);
  private readonly gridEngineSettingsService: GridEngineSettingsService = inject(GridEngineSettingsService);
  assetVariable: WritableSignal<AssetVariable> = signal(null);
  parameters: WritableSignal<{ key: string; value: number }[]> = signal([]);
  isLoading: WritableSignal<boolean> = signal(false);
  displaySourceAssetVariables: WritableSignal<boolean> = signal(false);
  assetSource: WritableSignal<Asset> = signal(null);
  displaySourceDeviceVariable: WritableSignal<boolean> = signal(false);
  deviceSource: WritableSignal<Device> = signal(null);
  variablesToDisplay: WritableSignal<AssetVariable[] | DeviceVariable[]> = signal([]);
  editThresholdsOn: WritableSignal<boolean> = signal(false);
  originalThresholds: WritableSignal<AssetVariableThreshold[]> = signal([]);
  workingOnThresholds: WritableSignal<AssetVariableThreshold[]> = signal([]);
  canUpdateAssetContent: WritableSignal<boolean> = signal(false);
  canUpdateAsset: WritableSignal<boolean> = signal(false);
  canUpdateThresholds: WritableSignal<boolean> = signal(false);
  normalizedVariable: WritableSignal<{ name: string; unit: string; description: string }> = signal(null);
  public data: { assetVariable: AssetVariable; asset: Asset } = inject(MAT_DIALOG_DATA);
  metadata: WritableSignal<AssetVariable> = signal(this.data.assetVariable);

  constructor() {
    this.canUpdateAssetContent.set(this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET_CONTENT, AuthorizationType.UPDATE));
    this.canUpdateAsset.set(this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.UPDATE));
    effect(
      () => {
        const canUpdateAsset = this.canUpdateAsset();
        const canUpdateAssetContent = this.canUpdateAssetContent();
        this.canUpdateThresholds.set((canUpdateAsset || canUpdateAssetContent) && this.data.asset.status.name !== AssetStatusName.decommissioned);
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    if (this.data && this.data.assetVariable.id) {
      this.isLoading.set(true);
      this.assetVariableService
        .getAssetVariableById(this.data.assetVariable.id)
        .pipe(
          tap((assetVariable: AssetVariable) => this.metadata.set(assetVariable)),
          switchMap((assetVariable: AssetVariable) => {
            this.assetVariable.set(assetVariable);
            const originalThresholds = assetVariable.thresholds?.values ?? [];
            this.originalThresholds.set([...originalThresholds]);
            this.workingOnThresholds.set([...originalThresholds]);

            this.parameters.set(this.getFormattedParameters(assetVariable.formula?.parameters));

            this.displaySourceAssetVariables.set(
              Object.values(assetVariable.formula?.srcVariables ?? {})
                .map((srcVar) => srcVar.type)
                .includes('asset-variable')
            );
            this.displaySourceDeviceVariable.set(
              Object.values(assetVariable.formula?.srcVariables ?? {})
                .map((srcVar) => srcVar.type)
                .includes('device-variable')
            );

            if (this.displaySourceAssetVariables()) {
              const assetId: string = Object.values(assetVariable.formula?.srcVariables ?? {})[0].originId as string;
              const assetVariablesIdsFilters: Filter[] = [
                ...new Set(
                  Object.values(assetVariable.formula?.srcVariables ?? {}).map((srcVar) => ({
                    criteriaKey: 'assetVariableId',
                    value: srcVar.variableId
                  }))
                )
              ];
              const request: PlatformRequest = {
                filters: assetVariablesIdsFilters,
                page: 0,
                limit: assetVariablesIdsFilters.length,
                urlParam: assetId
              };
              return this.assetVariableService.getAll(request).pipe(map((response) => response.data));
            } else if (this.displaySourceDeviceVariable()) {
              return this.assetVariableService
                .getDeviceVariableById(Object.values(assetVariable.formula?.srcVariables ?? {})[0].variableId ?? '')
                .pipe(map((deviceVar) => [deviceVar]));
            } else {
              return of(undefined);
            }
          }),
          finalize(() => this.isLoading.set(false)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((variablesToDisplay?: AssetVariable[] | DeviceVariable[]) => {
          if (variablesToDisplay) {
            this.variablesToDisplay.set(variablesToDisplay);
            if (this.displaySourceAssetVariables()) {
              this.assetSource.set((variablesToDisplay[0] as AssetVariable)?.asset);
            } else if (this.displaySourceDeviceVariable()) {
              this.deviceSource.set((variablesToDisplay[0] as DeviceVariable)?.device);
            }
          }
        });
      this.setNormalizedVariable();
    }
  }

  editThresholds() {
    this.editThresholdsOn.update((editThresholdsOn) => !editThresholdsOn);
    this.workingOnThresholds.set([...this.originalThresholds()]);
  }

  setNormalizedVariable(): void {
    let firstNormalizedVariable: { name: string; unit: string; description: string };
    NORMALIZED_ASSET_VARIABLES.find((element) => {
      const tempVar = element.variables.find((v) => v.name.toLowerCase() === this.data.assetVariable.name.toLowerCase());
      if (tempVar) {
        firstNormalizedVariable = tempVar;
      }
      return !!tempVar;
    });
    this.normalizedVariable.set(firstNormalizedVariable);
  }

  getTextualOperator(operator: string | undefined) {
    switch (operator) {
      case '=':
        return 'Equals';
      case '<':
        return 'Lower than';
      case '>':
        return 'Greater than';
      default:
        return '';
    }
  }

  saveThresholds() {
    this.editThresholdsOn.set(false);
    const assetVariable = this.assetVariable();
    const values = this.workingOnThresholds();
    const newAssetVariable: AssetVariable = {
      ...assetVariable,
      thresholds: { ...assetVariable.thresholds, values }
    };
    this.gridEngineSettingsService.updateAssetVariableThresholds(newAssetVariable);
    this.originalThresholds.set([...values]);
  }

  cancelThresholds() {
    this.editThresholdsOn.set(false);
    this.workingOnThresholds.set([...this.originalThresholds()]);
  }

  thresholdChanged(threshold: AssetVariableThreshold, input: number) {
    this.workingOnThresholds.update((workingOnThresholds) => {
      const toUpdate = {
        ...workingOnThresholds.find((t) => t.position === threshold.position),
        value: input * 1
      };
      workingOnThresholds[workingOnThresholds.findIndex((t) => t.position === threshold.position)] = toUpdate;
      return [...workingOnThresholds];
    });
  }

  private getFormattedParameters(parameters: FormulaParameters): { key: string; value: number }[] {
    const parametersEntries = Object.entries(parameters);
    return parametersEntries.map((p) => ({ key: p[0], value: p[1] }));
  }
}
