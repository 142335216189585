import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthApiActions, AuthPageActions } from '../actions';

export interface AuthPageState {
  error: any;
  pending: boolean;
  stepEmail: boolean;
  stepPassword: boolean;
  errorSignIn: boolean;
  errorEmail: boolean;
  errorPassword: string | undefined;
  requireNewPassword: boolean;
  signOutPending: boolean;
  accountLoading: boolean;
  displayEmailPasswordLogin: boolean;
}

export const initialState: AuthPageState = {
  error: null,
  pending: false,
  stepEmail: false,
  stepPassword: false,
  errorSignIn: false,
  errorEmail: false,
  errorPassword: undefined,
  requireNewPassword: false,
  signOutPending: false,
  accountLoading: false,
  displayEmailPasswordLogin: false
};

export const authPageFeature = createFeature({
  name: 'authPage',
  reducer: createReducer(
    initialState,
    on(AuthPageActions.setPending, (state: AuthPageState, { pending }) => ({ ...state, pending })),
    on(AuthPageActions.signIn, AuthPageActions.loadAccount, (state: AuthPageState) => ({
      ...state,
      error: null,
      pending: true,
      errorSignIn: false
    })),
    on(AuthApiActions.signInSuccess, AuthApiActions.loadAccountSuccess, (state: AuthPageState) => ({
      ...state,
      error: null,
      pending: false,
      errorSignIn: false,
      displayEmailPasswordLogin: false
    })),
    on(AuthApiActions.signInFailure, (state: AuthPageState, { error }) => ({
      ...state,
      error,
      errorSignIn: error,
      pending: false
    })),
    on(AuthPageActions.signOut, (state: AuthPageState) => ({
      ...state,
      error: null,
      pending: true,
      signOutPending: true,
      stepEmail: false
    })),
    on(AuthApiActions.signOutSuccess, AuthApiActions.signOutFailure, (state: AuthPageState) => ({
      ...state,
      signOutPending: false
    })),
    on(AuthPageActions.cancel, (state: AuthPageState) => ({
      ...state,
      error: null,
      pending: false,
      stepEmail: false,
      stepPassword: false,
      errorSignIn: false,
      errorEmail: false,
      errorPassword: undefined,
      requireNewPassword: false,
      displayEmailPasswordLogin: false
    })),
    on(AuthPageActions.resetPassword, (state: AuthPageState) => ({
      ...state,
      stepEmail: true,
      stepPassword: false,
      errorSignIn: false,
      displayEmailPasswordLogin: false
    })),
    on(AuthPageActions.changePassword, (state: AuthPageState) => ({ ...state })),
    on(AuthApiActions.changePasswordSuccess, (state: AuthPageState) => ({
      ...state,
      requireNewPassword: false
    })),
    on(AuthPageActions.forgotPassword, (state: AuthPageState) => ({
      ...state,
      stepEmail: false,
      stepPassword: true,
      displayEmailPasswordLogin: false
    })),
    on(AuthPageActions.updatePasswordWithCode, (state: AuthPageState) => ({
      ...state,
      errorPassword: undefined
    })),
    on(AuthApiActions.updatePasswordWithCodeSuccess, (state: AuthPageState) => ({
      ...state,
      stepEmail: false,
      stepPassword: false,
      displayEmailPasswordLogin: false
    })),
    on(AuthApiActions.updatePasswordWithCodeFailure, (state: AuthPageState, error) => ({
      ...state,
      errorPassword: error.error
    })),
    on(AuthPageActions.requireNewPassword, (state: AuthPageState) => ({
      ...state,
      requireNewPassword: true
    })),
    on(AuthApiActions.retrieveSessionFailure, () => initialState),
    on(AuthPageActions.loadAccount, (state: AuthPageState) => ({
      ...state,
      accountLoading: true
    })),
    on(AuthApiActions.loadAccountSuccess, (state: AuthPageState) => ({
      ...state,
      accountLoading: false
    })),
    on(AuthApiActions.loadAccountFailure, (state: AuthPageState) => ({
      ...state,
      accountLoading: false
    })),
    on(AuthPageActions.displayEmailPasswordLogin, (state: AuthPageState) => ({
      ...state,
      displayEmailPasswordLogin: true
    }))
  )
});
