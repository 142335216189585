import { FavoriteView, Pagination, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import * as fromAdminBusinessProfilesApi from './business-profiles-api.reducer';
import * as fromAdminFavoriteViewsByBusinessProfileApi from './business-profiles-favorite-views-api.reducer';
import * as fromAdminFavoriteViewsByBusinessProfilePage from './business-profiles-favorite-views-page.reducer';
import * as fromAdminGridsByBusinessProfileApi from './business-profiles-grids-api.reducer';
import * as fromAdminGridsByBusinessProfilePage from './business-profiles-grids-page.reducer';
import * as fromAdminMembersByBusinessProfileApi from './business-profiles-members-api.reducer';
import * as fromAdminMembersByBusinessProfilePage from './business-profiles-members-page.reducer';
import * as fromAdminBusinessProfilesPage from './business-profiles-page.reducer';
import * as fromAdminRolesByBusinessProfileApi from './business-profiles-roles-api.reducer';
import * as fromAdminRolesByBusinessProfilePage from './business-profiles-roles-page.reducer';
import * as fromAdminTagsByBusinessProfileApi from './business-profiles-tags-api.reducer';
import * as fromAdminTagsByBusinessProfilePage from './business-profiles-tags-page.reducer';

export const adminBusinessProfilesFeatureKey = 'adminBusinessProfiles';

export interface BusinessProfilesState {
  [fromAdminBusinessProfilesApi.adminBusinessProfilesApiFeatureKey]: fromAdminBusinessProfilesApi.State;
  [fromAdminBusinessProfilesPage.adminBusinessProfilesPageFeatureKey]: fromAdminBusinessProfilesPage.State;
  [fromAdminFavoriteViewsByBusinessProfileApi.adminBusinessProfilesFavoriteViewsApiFeatureKey]: fromAdminFavoriteViewsByBusinessProfileApi.State;
  [fromAdminFavoriteViewsByBusinessProfilePage.adminBusinessProfilesFavoriteViewsPageFeatureKey]: fromAdminFavoriteViewsByBusinessProfilePage.State;
  [fromAdminRolesByBusinessProfileApi.adminBusinessProfilesRolesApiFeatureKey]: fromAdminRolesByBusinessProfileApi.State;
  [fromAdminMembersByBusinessProfileApi.adminBusinessProfilesMembersApiFeatureKey]: fromAdminMembersByBusinessProfileApi.State;
  [fromAdminTagsByBusinessProfileApi.adminBusinessProfilesTagsApiFeatureKey]: fromAdminTagsByBusinessProfileApi.State;
  [fromAdminTagsByBusinessProfilePage.adminBusinessProfilesTagsPageFeatureKey]: fromAdminTagsByBusinessProfilePage.State;
  [fromAdminRolesByBusinessProfilePage.adminBusinessProfilesRolesPageFeatureKey]: fromAdminRolesByBusinessProfilePage.State;
  [fromAdminMembersByBusinessProfilePage.adminBusinessProfilesMembersPageFeatureKey]: fromAdminMembersByBusinessProfilePage.State;
  [fromAdminGridsByBusinessProfilePage.adminBusinessProfilesGridsPageFeatureKey]: fromAdminGridsByBusinessProfilePage.State;
  [fromAdminGridsByBusinessProfileApi.adminBusinessProfilesGridsApiFeatureKey]: fromAdminGridsByBusinessProfileApi.State;
}

export interface State {
  [adminBusinessProfilesFeatureKey]: BusinessProfilesState;
}

export function reducers(state: BusinessProfilesState | undefined, action: Action) {
  return combineReducers({
    [fromAdminBusinessProfilesApi.adminBusinessProfilesApiFeatureKey]: fromAdminBusinessProfilesApi.reducer,
    [fromAdminBusinessProfilesPage.adminBusinessProfilesPageFeatureKey]: fromAdminBusinessProfilesPage.reducer,
    [fromAdminFavoriteViewsByBusinessProfileApi.adminBusinessProfilesFavoriteViewsApiFeatureKey]: fromAdminFavoriteViewsByBusinessProfileApi.reducer,
    [fromAdminFavoriteViewsByBusinessProfilePage.adminBusinessProfilesFavoriteViewsPageFeatureKey]: fromAdminFavoriteViewsByBusinessProfilePage.reducer,
    [fromAdminRolesByBusinessProfileApi.adminBusinessProfilesRolesApiFeatureKey]: fromAdminRolesByBusinessProfileApi.reducer,
    [fromAdminMembersByBusinessProfileApi.adminBusinessProfilesMembersApiFeatureKey]: fromAdminMembersByBusinessProfileApi.reducer,
    [fromAdminTagsByBusinessProfileApi.adminBusinessProfilesTagsApiFeatureKey]: fromAdminTagsByBusinessProfileApi.reducer,
    [fromAdminTagsByBusinessProfilePage.adminBusinessProfilesTagsPageFeatureKey]: fromAdminTagsByBusinessProfilePage.reducer,
    [fromAdminRolesByBusinessProfilePage.adminBusinessProfilesRolesPageFeatureKey]: fromAdminRolesByBusinessProfilePage.reducer,
    [fromAdminMembersByBusinessProfilePage.adminBusinessProfilesMembersPageFeatureKey]: fromAdminMembersByBusinessProfilePage.reducer,
    [fromAdminGridsByBusinessProfilePage.adminBusinessProfilesGridsPageFeatureKey]: fromAdminGridsByBusinessProfilePage.reducer,
    [fromAdminGridsByBusinessProfileApi.adminBusinessProfilesGridsApiFeatureKey]: fromAdminGridsByBusinessProfileApi.reducer
  })(state, action);
}

export const selectBusinessProfilesState = createFeatureSelector<BusinessProfilesState>(adminBusinessProfilesFeatureKey);

/** *
 * GET ALL STATE COMPOSING BUSINESS PROFILES STATE
 */
export const getBusinessProfilesApiState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminBusinessProfilesApi.adminBusinessProfilesApiFeatureKey]
);

export const getBusinessProfilesPageState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminBusinessProfilesPage.adminBusinessProfilesPageFeatureKey]
);

export const getRolesByBusinessProfileApiState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminRolesByBusinessProfileApi.adminBusinessProfilesRolesApiFeatureKey]
);

export const getMembersByBusinessProfileApiState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminMembersByBusinessProfileApi.adminBusinessProfilesMembersApiFeatureKey]
);

export const getTagsByBusinessProfileApiState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminTagsByBusinessProfileApi.adminBusinessProfilesTagsApiFeatureKey]
);

export const getTagsByBusinessProfilePageState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminTagsByBusinessProfilePage.adminBusinessProfilesTagsPageFeatureKey]
);

export const getRolesByBusinessProfilePageState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminRolesByBusinessProfilePage.adminBusinessProfilesRolesPageFeatureKey]
);

export const getMembersByBusinessProfilePageState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminMembersByBusinessProfilePage.adminBusinessProfilesMembersPageFeatureKey]
);

export const getFavoriteViewsByBusinessProfileApiState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminFavoriteViewsByBusinessProfileApi.adminBusinessProfilesFavoriteViewsApiFeatureKey]
);

export const getFavoriteViewsByBusinessProfilePageState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminFavoriteViewsByBusinessProfilePage.adminBusinessProfilesFavoriteViewsPageFeatureKey]
);

export const selectGridsByBusinessProfilePageState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminGridsByBusinessProfilePage.adminBusinessProfilesGridsPageFeatureKey]
);

export const selectGridsByBusinessProfileApiState = createSelector(
  selectBusinessProfilesState,
  (state) => state[fromAdminGridsByBusinessProfileApi.adminBusinessProfilesGridsApiFeatureKey]
);

// END

export const getSelectedBusinessProfileId = createSelector(getBusinessProfilesApiState, fromAdminBusinessProfilesApi.getSelectedBusinessProfileId);

export const {
  selectIds: getIds,
  selectEntities: getBusinessProfilesEntities,
  selectAll: getAllBusinessProfiles,
  selectTotal: getTotalBusinessProfiles
} = fromAdminBusinessProfilesApi.adapter.getSelectors(getBusinessProfilesApiState);

export const {
  selectIds: getRolesIds,
  selectEntities: getRolesByBusinessProfileEntities,
  selectAll: getAllRolesByBusinessProfile,
  selectTotal: getTotalRolesByBusinessProfile
} = fromAdminRolesByBusinessProfileApi.adapter.getSelectors(getRolesByBusinessProfileApiState);

export const {
  selectIds: getMembersIds,
  selectEntities: getMembersByBusinessProfileEntities,
  selectAll: getAllMembersByBusinessProfile,
  selectTotal: getTotalMembersByBusinessProfile
} = fromAdminMembersByBusinessProfileApi.adapter.getSelectors(getMembersByBusinessProfileApiState);

export const {
  selectIds: getTagsIds,
  selectEntities: getTagsByBusinessProfileEntities,
  selectAll: getAllTagsByBusinessProfile,
  selectTotal: getTotalTagsByBusinessProfile
} = fromAdminTagsByBusinessProfileApi.adapter.getSelectors(getTagsByBusinessProfileApiState);

export const {
  selectIds: getFavoriteViewsIds,
  selectEntities: getFavoriteViewsByBusinessProfileEntities,
  selectAll: getAllFavoriteViewsByBusinessProfile,
  selectTotal: getTotalFavoriteViewsByBusinessProfile
} = fromAdminFavoriteViewsByBusinessProfileApi.adapter.getSelectors(getFavoriteViewsByBusinessProfileApiState);

export const {
  selectIds: getGridsIds,
  selectEntities: getGridsByBusinessProfileEntities,
  selectAll: getAllGridsByBusinessProfile,
  selectTotal: getTotalGridsByBusinessProfile
} = fromAdminGridsByBusinessProfileApi.adapter.getSelectors(selectGridsByBusinessProfileApiState);

export const getSelectedBusinessProfile = createSelector(
  getBusinessProfilesEntities,
  getSelectedBusinessProfileId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getFilteredBusinessProfiles = createSelector(getAllBusinessProfiles, getBusinessProfilesPageState, (businessProfiles, apiState) =>
  businessProfiles.filter((profile) => profile.name.toLowerCase().includes(apiState.searchString.toLowerCase()))
);

export const getSearchString = createSelector(getBusinessProfilesPageState, fromAdminBusinessProfilesPage.getSearchString);

export const getTagsPendingStatus = createSelector(getTagsByBusinessProfilePageState, fromAdminTagsByBusinessProfilePage.getPending);

export const getRolesPendingStatus = createSelector(getRolesByBusinessProfilePageState, fromAdminRolesByBusinessProfilePage.getPending);

export const getMembersPendingStatus = createSelector(getMembersByBusinessProfilePageState, fromAdminMembersByBusinessProfilePage.getPending);

export const getFavoriteViewsPendingStatus = createSelector(getFavoriteViewsByBusinessProfilePageState, fromAdminFavoriteViewsByBusinessProfilePage.getPending);

export const getFavoriteViewManagerPagination = createSelector(
  getFavoriteViewsByBusinessProfileApiState,
  fromAdminFavoriteViewsByBusinessProfileApi.getPagination
);

export const selectGridsPendingStatus = createSelector(selectGridsByBusinessProfilePageState, fromAdminGridsByBusinessProfilePage.getPending);

export const selectGridsManagerPagination = createSelector(selectGridsByBusinessProfileApiState, fromAdminGridsByBusinessProfileApi.getPagination);

export const getFavoriteViewAsPlatformResponse = createSelector(
  getAllFavoriteViewsByBusinessProfile,
  getFavoriteViewManagerPagination,
  (favoriteViews: FavoriteView[], pagination: Pagination) => {
    const response: PlatformResponse = {
      data: favoriteViews,
      maxPage: pagination.maxPage,
      hasMore: pagination.hasMore,
      total: pagination.total,
      limit: pagination.limit,
      currentPage: pagination.currentPage
    };
    return response;
  }
);

export const selectSharedFavoriteViewsAsPlatformResponse = createSelector(getFavoriteViewAsPlatformResponse, (platformResponse) => {
  const newData: FavoriteView[] = platformResponse.data.filter((favoriteView: FavoriteView) => favoriteView.shared);
  newData.sort(SortUtil.sortByName);
  const updatedResponse: PlatformResponse = {
    ...platformResponse,
    data: newData
  };
  return updatedResponse;
});

export const getBusinessProfilesPendingStatus = createSelector(getBusinessProfilesPageState, fromAdminMembersByBusinessProfilePage.getPending);

export const getAllTagsWithSortedLabels = createSelector(getAllTagsByBusinessProfile, (tagCategories: TagCategory[]) => {
  tagCategories.forEach((category: TagCategory) => {
    category.labels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
  });
  return tagCategories;
});

export const selectGridsAsPlatformResponse = createSelector(
  getAllGridsByBusinessProfile,
  selectGridsManagerPagination,
  (allGrids: I4BGrid<I4BGridOptions, I4BGridData>[], pagination: Pagination) => {
    const response: PlatformResponse = {
      data: allGrids,
      maxPage: pagination.maxPage,
      hasMore: pagination.hasMore,
      total: pagination.total,
      limit: pagination.limit,
      currentPage: pagination.currentPage
    };

    return response;
  }
);

export const selectSharedGridsByBusinessProfileAsPlatformResponse = createSelector(selectGridsAsPlatformResponse, (platformResponse) => {
  const newData = platformResponse.data.filter((grid: I4BGrid<I4BGridOptions, I4BGridData>) => grid.businessProfileId);
  newData.sort(SortUtil.sortByName);
  const updatedResponse: PlatformResponse = {
    ...platformResponse,
    data: newData
  };
  return updatedResponse;
});
