import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error: WritableSignal<string | { code: string; error: string } | any> = signal(null);
  success: WritableSignal<string | null> = signal(null);
  loader: WritableSignal<boolean> = signal(false);

  displaySuccess(value: string): void {
    this.success.set(null);
    this.success.set(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  displayError(error: string | { code: string; error: string } | any): void {
    this.error.set(null);
    this.error.set(error);
  }

  displayLoader(value: boolean): void {
    this.loader.set(value);
  }

  hideLoader(): void {
    this.loader.set(false);
  }

  showLoader(): void {
    this.loader.set(true);
  }
}
