import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserPreferencesDb from './preferences-db.reducer';
import * as fromUserPreferencesUi from './preferences-ui.reducer';

export const userPreferencesFeatureKey = 'userPreferences';

export interface UserPreferencesState {
  [fromUserPreferencesDb.userPreferencesDbFeatureKey]: fromUserPreferencesDb.State;
  [fromUserPreferencesUi.userPreferencesUiFeatureKey]: fromUserPreferencesUi.State;
}

export interface State {
  [userPreferencesFeatureKey]: UserPreferencesState;
}

export function reducers(state: UserPreferencesState | undefined, action: Action) {
  return combineReducers({
    [fromUserPreferencesDb.userPreferencesDbFeatureKey]: fromUserPreferencesDb.reducer,
    [fromUserPreferencesUi.userPreferencesUiFeatureKey]: fromUserPreferencesUi.reducer
  })(state, action);
}

export const selectUserPreferencesState = createFeatureSelector<UserPreferencesState>(userPreferencesFeatureKey);

export const selectUserPreferencesDbState = createSelector(
  selectUserPreferencesState,
  (state: UserPreferencesState) => state[fromUserPreferencesDb.userPreferencesDbFeatureKey]
);

export const selectUserPreferencesUiState = createSelector(
  selectUserPreferencesState,
  (state: UserPreferencesState) => state[fromUserPreferencesUi.userPreferencesUiFeatureKey]
);

export const getCurrentUser = createSelector(selectUserPreferencesDbState, fromUserPreferencesDb.getCurrentUser);
export const getPreferences = createSelector(selectUserPreferencesDbState, fromUserPreferencesDb.getPreferences);

export const getUserPreferencesError = createSelector(selectUserPreferencesUiState, fromUserPreferencesUi.getError);

export const getUserPreferencesLoading = createSelector(selectUserPreferencesUiState, fromUserPreferencesUi.getLoading);
