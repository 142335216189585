import { Injectable } from '@angular/core';
import { AbstractTrackingMapperService, GoogleAnalyticsTrackingData, GoogleAnalyticsTrackingTag, TrackingData, TrackingProviders } from '@iot-platform/core';

@Injectable()
export class GoogleAnalyticsMapperService implements AbstractTrackingMapperService<GoogleAnalyticsTrackingTag, GoogleAnalyticsTrackingData> {
  getTrackingProviderName(): TrackingProviders {
    return TrackingProviders.GoogleAnalytics;
  }

  getTrackingDataForProvider(trackingTag: GoogleAnalyticsTrackingTag, trackingData: TrackingData): GoogleAnalyticsTrackingData {
    const params: Partial<GoogleAnalyticsTrackingData> = {};
    params.event_category = trackingTag?.category;
    params.event_label = trackingTag?.label;
    params.value = trackingData?.value;
    params.non_interaction = true;
    return params as GoogleAnalyticsTrackingData;
  }
}
