import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Asset, Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-association-by-device',
  templateUrl: './association-by-device.component.html',
  styleUrls: ['./association-by-device.component.scss', '../asset-device-associations.component.scss']
})
export class AssociationByDeviceComponent {
  @Input() mainItem: Device;
  @Input() associatedItems: Asset[];
  @Input() canCreateAsset = false;
  @Input() canCreateDevice = false;
  @Input() navigationMode: boolean;
  @Input() selectedItemId: string;

  @Output() openSelectedItem: EventEmitter<{ action: string; data: Asset | Device }> = new EventEmitter();
}
