import { ColDef } from '@ag-grid-community/core';
import {
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGridFilterButtonType,
  I4BGridFilterOptions,
  I4BGridFilterType
} from '@iot-platform/models/grid-engine';
import { IconCellComponent } from '../components/cells/icon-cell/icon-cell.component';
import { LinkedCellComponent } from '../components/cells/linked-cell/linked-cell.component';

export class GridFiltersHelpers {
  static readonly textColumnFilter: Partial<ColDef> = {
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: [I4BGridFilterOptions.CONTAINS, I4BGridFilterOptions.NOT_CONTAINS, I4BGridFilterOptions.START_WITH, I4BGridFilterOptions.END_WITH],
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly iconColumnFilter: Partial<ColDef> = {
    filter: 'agSetColumnFilter',
    filterParams: {
      cellRenderer: IconCellComponent,
      cellRendererParams: { isFilterRenderer: true },
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly linkedColumnFilter: Partial<ColDef> = {
    filter: 'agSetColumnFilter',
    filterParams: {
      cellRenderer: LinkedCellComponent,
      cellRendererParams: { isFilterRenderer: true },
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly setColumnFilter: Partial<ColDef> = {
    filter: 'agSetColumnFilter',
    filterParams: {
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly booleanColumnFilter: Partial<ColDef> = {
    filter: 'agBooleanColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      filterOptions: [I4BGridFilterOptions.CONTAINS, I4BGridFilterOptions.NOT_CONTAINS, I4BGridFilterOptions.START_WITH, I4BGridFilterOptions.END_WITH],
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly numberColumnFilter: Partial<ColDef> = {
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly richVariableValueColumnFilter: Partial<ColDef> = {
    // TODO A rich variable cell custom filter
    // filter: RichVariableValueCellFilterComponent,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: [
        I4BGridFilterOptions.EQUALS,
        I4BGridFilterOptions.LESS_THAN,
        I4BGridFilterOptions.GREATER_THAN,
        I4BGridFilterOptions.LESS_THAN_OR_EQUAL,
        I4BGridFilterOptions.GREATER_THAN_OR_EQUAL
      ],
      maxNumConditions: 1,
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly dateColumnFilter: Partial<ColDef> = {
    filter: 'agDateColumnFilter',
    filterParams: {
      maxNumConditions: 1,
      browserDatePicker: true,
      maxValidYear: new Date().getFullYear(),
      inRangeFloatingFilterDateFormat: 'YYYY-MM-DD',
      comparator: this.dateComparator.bind(this),
      buttons: [I4BGridFilterButtonType.RESET]
    }
  };

  static readonly filtersMap = {
    [I4BGridFilterType.TEXT_COLUMN_FILTER]: this.textColumnFilter,
    [I4BGridFilterType.SET_COLUMN_FILTER]: this.setColumnFilter,
    [I4BGridFilterType.ICON_COLUMN_FILTER]: this.iconColumnFilter,
    [I4BGridFilterType.BOOLEAN_COLUMN_FILTER]: this.booleanColumnFilter,
    [I4BGridFilterType.NUMBER_COLUMN_FILTER]: this.numberColumnFilter,
    [I4BGridFilterType.DATE_COLUMN_FILTER]: this.dateColumnFilter,
    [I4BGridFilterType.RICH_VALUE_COLUMN_FILTER]: this.richVariableValueColumnFilter,
    [I4BGridFilterType.LINKED_COLUMN_FILTER]: this.linkedColumnFilter
  };

  static getFilter(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    if (col?.configuration?.filterParams?.filterType) {
      return this.filtersMap[col?.configuration?.filterParams?.filterType];
    }
    return this.getFilterByCellType(col.configuration.cell.type);
  }

  static getFilterByCellType(type: I4BCellType) {
    switch (type) {
      case I4BCellType.BASIC:
        return this.textColumnFilter;
      case I4BCellType.BOOLEAN_CELL:
        return this.booleanColumnFilter;
      case I4BCellType.BASIC_LINK:
        return this.textColumnFilter;
      case I4BCellType.DATE:
        return this.dateColumnFilter;
      case I4BCellType.NUMBER:
        return this.numberColumnFilter;
      case I4BCellType.TAG_CELL:
        return this.textColumnFilter;
      case I4BCellType.ICON:
        return this.iconColumnFilter;
      case I4BCellType.RICH_VARIABLE:
        return this.richVariableValueColumnFilter;
      case I4BCellType.LINKED_CELL:
        return this.linkedColumnFilter;
      default:
        return this.setColumnFilter;
    }
  }

  static dateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
    if (cellValue === null) {
      return -1;
    }
    const currentCellDate: Date = new Date(cellValue);
    const cellDate: Date = new Date(currentCellDate.getFullYear(), currentCellDate.getMonth(), currentCellDate.getDate());
    const filterDate: Date = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
    if (filterDate.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterDate) {
      return -1;
    }
    if (cellDate > filterDate) {
      return 1;
    }
  }
}
