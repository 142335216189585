import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
  standalone: true,
  selector: 'grid-engine-boolean-cell',
  templateUrl: './boolean-cell.component.html',
  imports: [MatCheckbox, FormsModule, NgClass, NgStyle, TranslateModule],
  styleUrls: ['./boolean-cell.component.scss']
})
export class BooleanCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);
  data: Signal<any> = computed(() => {
    const params = this.params();
    const cellOptions = params?.cellOptions;
    if (cellOptions?.mode === 'booleanAsText') {
      return params.value ? cellOptions?.dataToDisplayIfTrue : cellOptions?.dataToDisplayIfFalse;
    } else if (cellOptions?.mode === 'dataComparison') {
      return params.value === cellOptions?.comparisonKey ? cellOptions?.dataToDisplayIfTrue : cellOptions?.dataToDisplayIfFalse;
    }
    return params?.value;
  });
  translateKey: Signal<string> = computed(() => {
    const params = this.params();
    return params?.cellOptions?.translateKey ?? 'IOT_DICTIONARY.';
  });
  translateStyle: Signal<string> = computed(() => {
    const params = this.params();
    const data = this.data();
    return params?.cellOptions?.class ? params.cellOptions.class[data] : '';
  });

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
