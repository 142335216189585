import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { AssetVariable } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);

  getAssetVariableById(assetVariableId: string): Observable<AssetVariable> {
    return this.httpClient.get<AssetVariable>(`${this.environment.api.url}${this.environment.api.endpoints?.assetVariables}/${assetVariableId}`);
  }
}
