export const assetVariablesMetadataSettings = {
  metadata: {
    url: '',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'assetVariables',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 100,
      refreshDelay: 120,
      multipleSelection: true,
      renderer: 'grid',
      displayOption: 'boxed',
      selectionColumn: {
        id: 'selection',
        name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SELECTION',
        width: '3%',
        type: 'start center'
      },
      buttonColumn: {
        id: 'button',
        name: '',
        width: '3%',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [[{ key: 'canUpdateAsset', value: true }]],
        bulkActions: [
          {
            key: 'deleteVariablesBulk',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
          },
          {
            key: 'resetLastValueBulk',
            label: 'IOT_DICTIONARY.RESET_LAST_VALUE',
            disableConditions: [[{ key: 'formula.model', value: 'constant' }]],
            visibleConditions: [[{ key: 'canUpdateAsset', value: true }], [{ key: 'canResetLastValue', value: true }]]
          }
        ],
        singleActions: [
          {
            key: 'editVariable',
            label: 'IOT_DICTIONARY.EDIT',
            disableConditions: [[{ key: 'asset.status.name', value: 'decommissioned' }]],
            visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
          },
          {
            key: 'configureVariable',
            label: 'IOT_DICTIONARY.CONFIGURATION',
            disableConditions: [[{ key: 'asset.status.name', value: 'decommissioned' }]],
            visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
          },
          {
            key: 'deleteVariable',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
          },
          {
            key: 'resetLastValue',
            label: 'IOT_DICTIONARY.RESET_LAST_VALUE',
            disableConditions: [[{ key: 'formula.model', value: 'constant' }]],
            visibleConditions: [[{ key: 'canUpdateAsset', value: true }], [{ key: 'canResetLastValue', value: true }]]
          }
        ]
      },
      columns: []
    }
  },
  masterViewComments: {}
};
