<div class="call-log-message-content">
  @if (loading) {
    <div fxLayoutAlign="center center" fxFlex>
      <iot-platform-ui-card-loader fxFlex cdkFocusInitial></iot-platform-ui-card-loader>
    </div>
  } @else {
    <pre>{{ message | json }}</pre>
  }
  <button
    (click)="copyMessageToClipboard()"
    [matTooltip]="'DEVICES.CALL_LOG.COPY_MESSAGE' | translate"
    class="reverse-accent-button copy-button"
    mat-icon-button
    matTooltipClass="regular-tooltip"
  >
    <mat-icon>content_copy</mat-icon>
  </button>
</div>
