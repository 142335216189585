import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { combineReducers, createFeature, StoreModule } from '@ngrx/store';
import { AuthEffects } from '../effects/auth.effects';
import { AuthBusinessProfilesEffects } from '../effects/business-profiles.effects';
import { authApiFeature, AuthApiFeatureState } from './auth-api.reducer';
import { authBusinessProfilesApiFeature, AuthBusinessProfilesApiState } from './auth-business-profiles-api.reducer';
import { authBusinessProfilesPageFeature, AuthBusinessProfilesPageState } from './auth-business-profiles-page.reducer';
import { authPageFeature, AuthPageState } from './auth-page.reducer';

export interface AuthState {
  [authApiFeature.name]: AuthApiFeatureState;
  [authPageFeature.name]: AuthPageState;
  [authBusinessProfilesApiFeature.name]: AuthBusinessProfilesApiState;
  [authBusinessProfilesPageFeature.name]: AuthBusinessProfilesPageState;
}

export const authFeatures = createFeature<string, AuthState>({
  name: 'auth',
  reducer: combineReducers({
    [authApiFeature.name]: authApiFeature.reducer,
    [authPageFeature.name]: authPageFeature.reducer,
    [authBusinessProfilesApiFeature.name]: authBusinessProfilesApiFeature.reducer,
    [authBusinessProfilesPageFeature.name]: authBusinessProfilesPageFeature.reducer
  } as any)
});

export function provideAuthState(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(authFeatures.name, authFeatures.reducer), EffectsModule.forFeature([AuthEffects, AuthBusinessProfilesEffects]))
  ];
}
