export interface TimelineEvent {
  id?: string;
  timestamp: number;
  type: TimelineEventType;
  data: {
    payload?: string;
    signalQuality?: {
      rssi: number;
      snr: number;
      qualityRedundancy: number;
    };
    nbTestMsg?: number;
    testData?: {
      lora: { testMsg: number; rssi: number; qualityRedundancy: number }[];
      sigfox: { testMsg: number; rssi: number; qualityRedundancy: number }[];
    };
    shipToName?: string;
    shipToAltName?: string;
    subsidiary?: string;
    subsidiaryAltName?: string;
    device?: string;
    asset?: string;
    user?: string;
    initials?: string;
    userFirstName?: string;
    userLastName?: string;
    messageNetwork?: 'sigfox' | 'lora';
    refurbishing?: {
      [key: string]: boolean;
    };
    qualification?: {
      [key: string]: boolean;
    };
    packaging?: {
      [key: string]: boolean;
    };
    complementary_info?: {
      sharedLink: string;
      comments: string;
      editorContent?: string;
    };
  };
  deviceId?: string;
  assetId?: string;
  eventId?: string;
  active?: boolean;
  expanded?: boolean;
  operations?: { type: string; items: { key: string; value: boolean }[] }[];
}

export enum TimelineEventType {
  CU1 = 'CU1',
  CU2 = 'CU2',
  CU3 = 'CU3',
  CR1 = 'CR1',
  CR2 = 'CR2',
  CR3 = 'CR3',
  OU1 = 'OU1',
  OU2 = 'OU2',
  OU3 = 'OU3',
  OR1 = 'OR1',
  OR1A = 'OR1-a',
  OR1B = 'OR1-b',
  OR1C = 'OR1-c',
  OR1D = 'OR1-d',
  OR2 = 'OR2',
  OR3 = 'OR3',
  OR4 = 'OR4',
  OR5 = 'OR5',
  OR6 = 'OR6',
  UR1 = 'UR1',
  UR2 = 'UR2',
  UR3 = 'UR3',
  UR4 = 'UR4',
  UR5 = 'UR5',
  UR6 = 'UR6',
  UR7 = 'UR7',
  UR8 = 'UR8',
  ER1 = 'ER1',
  ER2 = 'ER2',
  ER3 = 'ER3',
  ER4 = 'ER4'
}
