import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { Contact } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PhoneNumberInputComponent } from '../forms';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCard,
    MatCardTitle,
    MatToolbar,
    MatIcon,
    TranslateModule,
    MatCardContent,
    MatSlideToggle,
    MatTooltip,
    MatFormField,
    PhoneNumberInputComponent,
    MatCardActions,
    MatButtonModule,
    ReactiveFormsModule,
    MatInput,
    NgClass,
    MatLabel,
    MatHint,
    MatError
  ],
  selector: 'iot-platform-ui-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  contactForm!: UntypedFormGroup;
  initialFormState: WritableSignal<string> = signal('');

  isSaveButtonDisabled: WritableSignal<boolean> = signal(true);

  dialogRef: MatDialogRef<ContactFormComponent> = inject(MatDialogRef<ContactFormComponent>);
  data: { contact: Contact; site: Site } = inject(MAT_DIALOG_DATA);
  private destroy: DestroyRef = inject(DestroyRef);
  private translateService: TranslateService = inject(TranslateService);

  get firstname() {
    return this.contactForm.get('firstname') as AbstractControl;
  }

  get lastname() {
    return this.contactForm.get('lastname') as AbstractControl;
  }

  get function() {
    return this.contactForm.get('function') as AbstractControl;
  }

  get phone() {
    return this.contactForm.get('phone') as AbstractControl;
  }

  get email() {
    return this.contactForm.get('email') as AbstractControl;
  }

  get comment() {
    return this.contactForm.get('comment') as AbstractControl;
  }

  get notificationViaEmail() {
    return this.contactForm.get('notificationViaEmail') as AbstractControl;
  }

  get notificationViaSms() {
    return this.contactForm.get('notificationViaSms') as AbstractControl;
  }

  get currentFormState(): string {
    return JSON.stringify({ contactForm: this.contactForm.value });
  }

  ngOnInit() {
    this.initForm();
    this.initialFormState.set(this.currentFormState);
    this.contactForm.valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe(() => this.isSaveButtonDisabled.set(this.getSaveButtonDisabled()));
  }

  initForm(): void {
    this.contactForm = new UntypedFormGroup({
      lastname: new UntypedFormControl(this.data.contact.lastname?.trim() ?? null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('\\S.*')
      ]),
      firstname: new UntypedFormControl(this.data.contact.firstname?.trim() ?? null, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('\\S.*')
      ]),
      function: new UntypedFormControl(this.data.contact.function ?? null, [Validators.maxLength(40)]),
      phone: new UntypedFormControl(this.data.contact.phone ?? null, [Validators.pattern(/^(\+|00)/), Validators.maxLength(20)]),
      email: new UntypedFormControl(this.data.contact.email ?? null, [
        // Angular's email validation considers emails with no LTD in domain names as VALID
        // we add a simple regex to fix this
        // for more info https://github.com/angular/angular/issues/17296
        Validators.email,
        this.validEmail(),
        Validators.maxLength(50),
        this.uniqueEmail()
      ]),
      comment: new UntypedFormControl(this.data.contact.comment ?? null, [Validators.maxLength(300)]),
      notificationViaEmail: new UntypedFormControl(this.data.contact.notificationSupport?.email ?? false),
      notificationViaSms: new UntypedFormControl(this.data.contact.notificationSupport?.sms ?? false)
    });
  }

  getLabels(type: string): string {
    switch (type) {
      case 'title': {
        return this.data.contact?.id
          ? this.translateService.instant('SITES.CONTACT_FORM.EDIT_CONTACT', {
              firstName: this.data.contact.firstname,
              lastName: this.data.contact.lastname
            })
          : this.translateService.instant('SITES.CONTACT_FORM.CREATE_CONTACT');
      }
      case 'action': {
        return this.translateService.instant('IOT_DICTIONARY.' + (this.data.contact?.id ? 'SAVE' : 'CREATE'));
      }
      default:
        return '';
    }
  }

  /* This offers a light validation, should be used in combination with `Validators.email`  */
  validEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => (!control.value ? null : /\S+@\S+\.\S+/.test(control.value) ? null : { email: true });
  }

  uniqueEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      this.data.site?.contacts?.find((contact) => contact.email === control.value?.toLowerCase() && (contact.id ? contact.id !== this.data.contact.id : true))
        ? { emailDuplicate: true }
        : null;
  }

  getSaveButtonDisabled(): boolean {
    return !this.formStateChanged() || !this.contactForm.valid || (!this.email.value && !this.phone.value);
  }

  save() {
    const contact: Contact = {
      id: this.data.contact.id,
      firstname: this.firstname.value?.trim(),
      lastname: this.lastname.value?.trim(),
      function: this.function.value?.trim(),
      phone: this.phone.value ? this.phone.value.trim() : null,
      email: this.email.value ? this.email.value.trim() : null,
      comment: this.comment.value,
      site: { id: this.data.site.id as string, name: this.data.site.name },
      notificationSupport: { email: this.notificationViaEmail.value, sms: this.notificationViaSms.value }
    };
    this.dialogRef.close(contact);
  }

  formStateChanged(): boolean {
    return this.initialFormState() !== this.currentFormState;
  }

  close() {
    this.dialogRef.close();
  }
}
