import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { AssetEvent } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AssetEventsByTopicDbActions, AssetEventsByTopicLogsDbActions, AssetEventsByTopicUiActions } from '../../actions';

export const assetEventsByTopicDbFeatureKey = 'assetEventsByTopicDb';

export interface State extends EntityState<AssetEvent> {
  selectedAssetEventId: string | null;
  checkedAssetEventIds: string[];
  previousTopicId: string | null;
  totalActiveEvents: number;
  error?: any;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  settings?: any;
  status?: AssetEvent;
}

export const adapter: EntityAdapter<AssetEvent> = createEntityAdapter<AssetEvent>({
  selectId: (assetEvent: AssetEvent) => assetEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedAssetEventId: null,
  checkedAssetEventIds: [],
  previousTopicId: null,
  totalActiveEvents: 0,
  error: null,
  pagination: { currentPage: 0, hasMore: false, limit: 100, maxPage: 0, total: 0 },
  settings: null
});

const assetEventsByTopicDbReducer = createReducer(
  initialState,

  on(AssetEventsByTopicUiActions.loadAssetEventsByTopic, (state: State) => ({ ...state })),
  on(AssetEventsByTopicDbActions.loadAssetEventsByTopicSuccess, (state: State, { response, topicId }) => {
    const newState = { ...state };
    if (!state.previousTopicId || (state.previousTopicId && state.previousTopicId !== topicId)) {
      newState.previousTopicId = topicId;
    }
    return adapter.setAll(response.data, {
      ...newState,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    });
  }),
  // ****
  on(AssetEventsByTopicDbActions.loadTotalActiveAssetEventsByTopicSuccess, (state: State, { totalActiveEvents }) => ({ ...state, totalActiveEvents })),
  // ****
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventSuccess, (state: State, { assetEvent }) =>
    adapter.updateOne({ id: assetEvent.id, changes: { totalComments: state.entities[state.selectedAssetEventId].totalComments + 1 } }, state)
  ),
  // ****
  on(AssetEventsByTopicDbActions.updateStatusByAssetEventIdByTopicSuccess, (state: State, { assetEvent }) =>
    adapter.updateOne({ id: assetEvent.id, changes: assetEvent }, { ...state, status: assetEvent })
  ),
  on(AssetEventsByTopicDbActions.bulkUpdateStatusByAssetEventIdByTopicSuccess, (state: State, { assetEvents }) => {
    const updates: Array<{ id: string; changes: any }> = assetEvents.reduce((acc, value) => {
      acc.push({ id: value.id, changes: value });
      return acc;
    }, []);

    return adapter.updateMany(updates, { ...state, status: assetEvents[0] });
  }),
  on(AssetEventsByTopicDbActions.saveTableByTopicStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedAssetEventId: selectedId,
    checkedAssetEventIds: checkedIds
  })),
  on(AssetEventsByTopicDbActions.loadMvAssetEventsByTopicSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByTopicDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedAssetEventId;
export const getCheckedIds = (state: State) => state.checkedAssetEventIds;
export const getPreviousTopicId = (state: State) => state.previousTopicId;
export const getTotalActiveEvents = (state: State) => state.totalActiveEvents;
export const getMvSettings = (state: State) => state.settings;
export const getStatus = (state: State) => state.status;
