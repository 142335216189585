<mat-toolbar [ngClass]="[size()]" class="breadcrumb" color="primary" fxLayoutAlign="start center">
  @if (!backButtonHidden()) {
    <div class="breadcrumb__back-button" fxFlex fxLayoutAlign="center center" (click)="back?.emit(data())">
      <mat-icon>keyboard_backspace</mat-icon>
    </div>
  }
  <div [ngClass]="{ 'breadcrumb__item--passive': passive() }" class="breadcrumb__item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    @if (loading()) {
      <mat-progress-spinner color="accent" mode="indeterminate" [diameter]="20"></mat-progress-spinner>
    } @else {
      <mat-icon [svgIcon]="icon()" color="accent"></mat-icon>
      @if (size() === toolbarSize.REGULAR) {
        <div fxLayout="column">
          <span class="breadcrumb__item-label" fxLayoutAlign="start center">{{ label() }}</span>
          <span class="breadcrumb__item-title" fxLayoutAlign="start center">{{ title() }}</span>
        </div>
      }
      @if (size() === toolbarSize.SMALL) {
        <span class="breadcrumb__item-title" fxLayoutAlign="start center">{{ title() }}</span>
        <span class="breadcrumb__item-label" fxLayoutAlign="start center">{{ label() }}</span>
      }
    }
  </div>
  <span fxFlex></span>
  <ng-content [breadcrumpActions]></ng-content>
</mat-toolbar>
