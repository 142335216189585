import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusInitial]',
  standalone: true
})
export class FocusInitialDirective implements AfterContentInit {
  @Input() focusInitial = false;

  constructor(private readonly el: ElementRef) {}

  ngAfterContentInit(): void {
    if (this.focusInitial) {
      this.el.nativeElement.focus();
    }
  }
}
