import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormulaType } from '@iot-platform/models/common';
import { DefaultFormulaSourceVariable, DeviceVariable, Formula, FormulaSourceVariable, MinMaxAdvancedParameters } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  selector: 'iot4bos-ui-asset-min-max-advanced-parameters-form',
  templateUrl: './min-max-advanced-parameters-form.component.html'
})
export class MinMaxAdvancedParametersFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() deviceVariable?: DeviceVariable;
  @Input() initialFormula!: Formula;
  @Input() readonly = false;

  @Output() dispatchFormula: EventEmitter<Formula | null> = new EventEmitter();

  parametersForm: UntypedFormGroup;

  assetVariableFormula: Formula = { model: FormulaType.MIN_MAX_ADVANCED, parameters: {}, srcVariables: {} };
  subscriptions: Subscription[] = [];

  get formulaMinMaxAdvancedMinRead(): AbstractControl {
    return this.parametersForm.get('formulaMinMaxAdvancedMinRead');
  }

  get formulaMinMaxAdvancedMinDisplay(): AbstractControl {
    return this.parametersForm.get('formulaMinMaxAdvancedMinDisplay');
  }

  get formulaMinMaxAdvancedMaxRead(): AbstractControl {
    return this.parametersForm.get('formulaMinMaxAdvancedMaxRead');
  }

  get formulaMinMaxAdvancedMaxDisplay(): AbstractControl {
    return this.parametersForm.get('formulaMinMaxAdvancedMaxDisplay');
  }

  ngOnInit() {
    const parameters = this.initialFormula?.parameters as MinMaxAdvancedParameters;

    this.parametersForm = new UntypedFormGroup({
      formulaMinMaxAdvancedMinRead: new UntypedFormControl(this.initialFormula ? parameters.minRead : null, [Validators.required]),
      formulaMinMaxAdvancedMinDisplay: new UntypedFormControl(this.initialFormula ? parameters.minDisplay : null, [Validators.required]),
      formulaMinMaxAdvancedMaxRead: new UntypedFormControl(this.initialFormula ? parameters.maxRead : null, [Validators.required]),
      formulaMinMaxAdvancedMaxDisplay: new UntypedFormControl(this.initialFormula ? parameters.maxDisplay : null, [Validators.required])
    });

    if (this.readonly) {
      this.parametersForm.disable();
    }

    if (this.deviceVariable) {
      (this.assetVariableFormula.srcVariables as DefaultFormulaSourceVariable)['0'] = {
        name: '0',
        variableId: this.deviceVariable.id,
        originId: this.deviceVariable.device?.id,
        type: 'device-variable'
      } as FormulaSourceVariable;
    }

    this.subscriptions.push(this.parametersForm.valueChanges.subscribe(() => this.sendFormula()));
    this.sendFormula();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.deviceVariable) {
      this.assetVariableFormula.srcVariables = {
        '0': {
          name: '0',
          type: 'device-variable',
          variableId: changes.deviceVariable.currentValue?.id ?? null,
          originId: changes.deviceVariable.currentValue?.device.id ?? null
        }
      };

      if (this.parametersForm) {
        this.sendFormula();
      }
    }
  }

  canCalculateMinMaxAdvancedFormula(): boolean {
    return (
      this.parametersForm.valid &&
      this.formulaMinMaxAdvancedMaxRead.value > this.formulaMinMaxAdvancedMinRead.value &&
      this.formulaMinMaxAdvancedMaxDisplay.value > this.formulaMinMaxAdvancedMinDisplay.value &&
      this.deviceVariable !== null
    );
  }

  createFormula(): void {
    this.assetVariableFormula.parameters = {
      maxRead: this.formulaMinMaxAdvancedMaxRead.value,
      maxDisplay: this.formulaMinMaxAdvancedMaxDisplay.value,
      minRead: this.formulaMinMaxAdvancedMinRead.value,
      minDisplay: this.formulaMinMaxAdvancedMinDisplay.value
    };
  }

  sendFormula(): void {
    if (this.canCalculateMinMaxAdvancedFormula()) {
      this.createFormula();
      this.dispatchFormula.emit(this.assetVariableFormula);
    } else {
      this.dispatchFormula.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
