import { Component, OnDestroy, OnInit, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { fromGrids, GridManagerUserPopupComponent, GridsDbActions } from '@iot-platform/grid-engine';
import {
  CONFIGURE_GRIDS_BUTTON_CONFIG,
  EXPORT_BUTTON_CONFIG,
  FAVORITE_VIEWS_MENU_BUTTON_CONFIG,
  FavoriteViewFormComponent,
  FilterEngineMode,
  GraphListPopupComponent,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  PopupComponent,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
  VariableChartDialogComponent
} from '@iot-platform/iot-platform-ui';
import {
  ChatEvent,
  CommonApiRequest,
  CommonIndexedPagination,
  FavoriteView,
  Filter,
  IotAction,
  IotToolbarEvent,
  MasterViewEngineEvent,
  TagCategory,
  ToolbarSize,
  UserAccount
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Asset, AssetEvent, AssetVariable, CommentContext, Concept, Device, DeviceVariable, FollowedVariable, Log, Site } from '@iot-platform/models/i4b';
import { fromUserPreferences, PreferencesActions } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AssetEventsApi } from '../../+state/asset-events.api';
import { EventDetailPopupComponent } from '../../../../components/event-detail-popup/event-detail-popup.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { setDisableFavoriteViewButtons } from '../../../../helpers/favorite-view.helpers';

@Component({
  selector: 'iot4bos-ui-asset-events-shell',
  templateUrl: './asset-events-shell.component.html',
  styleUrls: ['./asset-events-shell.component.scss']
})
export class AssetEventsShellComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('asset_events_shell');
  masterViewAssetEventsButtonList!: (IotToolbarDefaultButton | IotToolbarMenuButton)[];

  assetEventGridsConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = this.store.select(fromGrids.selectAssetEventGridsConfiguration);

  grid$ = this.assetEventGridsConfiguration$.pipe(map((conf) => conf.currentGrid));
  grids$ = this.assetEventsApi.grids$;
  grids: I4BGrid<I4BGridOptions, I4BGridData>[] = [];
  grid: I4BGrid<I4BGridOptions, I4BGridData>;
  totalAssetEvents$: Observable<number> = this.grid$.pipe(map((grid) => (grid?.data?.response?.pagination as CommonIndexedPagination)?.total | 0));
  assetEventsLoaded$: Observable<boolean> = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getDataLoadedByGrid(grid.id as string));
      } else {
        return of(false);
      }
    })
  );
  gridSort$ = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getSortByGrid(grid.id as string));
      } else {
        return of([]);
      }
    })
  );

  hasLeft = false;

  currentUser$: Observable<UserAccount> = this.store.select(fromUserPreferences.getCurrentUser);
  user!: UserAccount;
  currentFavoriteView: FavoriteView = {};

  assetEventFavoriteViewsConfiguration$: Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  }> = this.assetEventsApi.assetEventFavoriteViewsConfiguration$;

  currentFilters: Filter[] = [];
  currentFilters$: Observable<Filter[]> = this.assetEventsApi.currentFilters$;
  currentFavoriteView$: Observable<FavoriteView | undefined> = this.assetEventFavoriteViewsConfiguration$.pipe(map((conf) => conf.currentFavoriteView));

  filterEngineOpened = false;

  userPermissions: {
    key: string;
    value: boolean;
  }[];
  selectedRowId?: string;
  destroyed$: Subject<boolean> = new Subject();

  _timerValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  timerValue$: Observable<number> = this._timerValue$.asObservable().pipe(delay(0));

  subscriptions: Subscription[] = [];

  canUpdateEvent: boolean;
  canReadAsset = false;
  canReadSite = false;
  canUpdateBusinessProfile: boolean;
  FilterEngineMode = FilterEngineMode;
  // Comments
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  assetEventComments: Signal<Log[]> = this.assetEventsApi.assetEventComments;
  assetEventCommentsLoading: Signal<boolean> = this.assetEventsApi.assetEventCommentsLoading;
  commentsContexts!: CommentContext[];
  CONCEPT = Concept;

  deviceByDeviceEvent$!: Observable<Device>;
  deviceVariableByDeviceEvent$!: Observable<DeviceVariable>;
  assetByDeviceEvent$!: Observable<Asset>;
  assetVariableByDeviceEvent$!: Observable<AssetVariable>;
  siteLoaded$!: Observable<boolean>;
  deviceLoaded$!: Observable<boolean>;
  deviceVariableLoaded$!: Observable<boolean>;
  assetLoaded$!: Observable<boolean>;
  tagsByEvent$!: Observable<TagCategory[]>;
  tagsLoaded$!: Observable<boolean>;
  totalEvents = 0;

  selectedAssetEvent: WritableSignal<AssetEvent | null> = signal(null);

  canReadGraph;
  canReadEvent;

  toolbarSize: string = ToolbarSize.SMALL;

  constructor(
    private readonly dialog: MatDialog,
    private readonly navigationApi: NavigationApi,
    private readonly store: Store,
    private readonly assetEventsApi: AssetEventsApi,
    private readonly authorizationService: AuthorizationService,
    private readonly storage: LocalStorageService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
    this.canReadAsset = this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.READ);
    this.canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
    this.canUpdateBusinessProfile = this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.canReadGraph = this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.READ);
    this.canReadEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);

    this.userPermissions = [
      { key: 'canUpdateEvent', value: this.canUpdateEvent },
      { key: 'canReadGraph', value: this.canReadGraph },
      { key: 'canReadEvent', value: this.canReadEvent }
    ];
    this.handleScrollPosition();
    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.grid$.pipe(takeUntil(this.destroyed$)).subscribe((grid: I4BGrid<I4BGridOptions, I4BGridData>) => (this.grid = grid));
    this.grids$.pipe(takeUntil(this.destroyed$)).subscribe((grids: I4BGrid<I4BGridOptions, I4BGridData>[]) => (this.grids = grids));

    this.subscriptions.push(
      combineLatest([this.store.select(fromAuth.selectSelectedBusinessProfileForAccount), this.grids$, this.grid$]).subscribe(
        ([businessProfile, grids, grid]) => {
          if (businessProfile && grids && !grid) {
            this.selectDefaultGrid();
          }
        }
      )
    );

    combineLatest([this.currentUser$, this.currentFilters$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([currentUser, filters]) => {
        if (currentUser && currentUser.id) {
          this.currentFilters = filters ?? [];
          setDisableFavoriteViewButtons(
            this.masterViewAssetEventsButtonList,
            this.currentFilters,
            this.currentFavoriteView,
            this.canUpdateBusinessProfile,
            currentUser.id
          );
        }
      });

    combineLatest([this.currentUser$, this.currentFavoriteView$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([currentUser, favoriteView]) => {
        if (currentUser && currentUser.id) {
          this.currentFavoriteView = favoriteView ?? {};
          setDisableFavoriteViewButtons(
            this.masterViewAssetEventsButtonList,
            this.currentFilters,
            this.currentFavoriteView,
            this.canUpdateBusinessProfile,
            currentUser.id
          );
        }
      });

    this.assetEventsLoaded$.pipe(takeUntil(this.destroyed$)).subscribe((dataLoaded: boolean) => {
      this.masterViewAssetEventsButtonList.map((button) => button.icon !== 'filter_list' ?? (button.disabled = !dataLoaded));
    });

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (user) {
        this.user = user;
        this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
      }
    });
  }

  loadData(): void {
    this.grid$.pipe(take(1)).subscribe((grid) => {
      if (grid?.data) {
        const pagination: CommonIndexedPagination = grid.data.response.pagination as CommonIndexedPagination;
        const request: CommonApiRequest = {
          limit: pagination.limit,
          page: 0,
          filters: this.currentFilters,
          concept: grid.masterview.toLowerCase(),
          variables: grid.gridOptions.variableNames,
          tags: grid.gridOptions.tagIds
        };
        this.assetEventsApi.loadAssetEvents(request);
      }
    });
  }

  initToolbarButtonList(): void {
    this.masterViewAssetEventsButtonList = [
      new IotToolbarDefaultButton(TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, 0),
      new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 1),
      new IotToolbarDefaultButton(EXPORT_BUTTON_CONFIG, 2),
      new IotToolbarMenuButton(FAVORITE_VIEWS_MENU_BUTTON_CONFIG, 3),
      new IotToolbarDefaultButton(CONFIGURE_GRIDS_BUTTON_CONFIG, 4)
    ];
  }

  onCreateFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_add_favorite_view');
    const favoriteView: FavoriteView = {};
    favoriteView.masterView = 'asset-events';
    favoriteView.concept = 'EVENT';
    favoriteView.filters = this.currentFilters;

    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView, canUpdateBusinessProfile: this.canUpdateBusinessProfile, grids: this.grids },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.assetEventsApi.saveFavoriteView(response);
          this.analytic.log('toolbar_actions', 'add_favorite_view');
        }
      });
  }

  onEditFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_edit_favorite_view');
    const fv: FavoriteView = { ...this.currentFavoriteView };
    fv.filters = [...this.currentFilters];
    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView: fv, canUpdateBusinessProfile: this.canUpdateBusinessProfile, grids: this.grids },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.assetEventsApi.updateFavoriteView(response);
          this.analytic.log('toolbar_actions', 'update_favorite_view');
        }
      });
  }

  onDeleteFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_delete_favorite_view');
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: this.currentFavoriteView.name }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: boolean) => {
        if (response) {
          this.assetEventsApi.deleteFavoriteView(this.currentFavoriteView);
          this.clearAppliedFilters();
          this.analytic.log('toolbar_actions', 'delete_favorite_view');
        }
      });
  }

  onExportData(): void {
    const openSettingsSub$: Subject<void> = new Subject<void>();
    this.grid$.pipe(first(), takeUntil(openSettingsSub$)).subscribe((grid) => {
      if (grid) {
        this.analytic.log('toolbar_actions', 'export_data', `Export data : ${(grid.data.response.pagination as CommonIndexedPagination).total} elements`);
        grid.export({
          filters: this.currentFilters,
          totalElements: (grid.data.response.pagination as CommonIndexedPagination).total
        });
      }
      openSettingsSub$.next();
      openSettingsSub$.complete();
    });
  }

  onApplyGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    if (grid) {
      this.analytic.log('toolbar_actions', 'select_grid');
      this.clearCheckedItems();
      this.selectGridAndLoadData(grid.id as string, grid.masterview, this.currentFilters);
    }
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'open':
        this.selectItem(event.rawData.id);
        this.openEventDetail(event.rawData);
        break;
      case 'navigateToSite':
        this.openSiteDetail({ rowId: event.rawData.id, site: event.rawData.context.site });
        break;
      case 'navigateToAsset':
        this.openAssetDetail({
          rowId: event.rawData.id,
          asset: event.rawData.context.asset,
          site: event.rawData.context.site
        });
        break;
      case 'navigateToDevice':
        this.openDeviceDetail({
          rowId: event.rawData.id,
          device: event.rawData.context.device,
          site: event.rawData.context.site
        });
        break;
      case 'selectionChanged':
        this.checkItems(event.rawData);
        break;
      case 'openGraph':
        this.openGraph(event.rawData);
        break;
      case 'snooze':
      case 'acknowledge':
      case 'close':
        this.onUpdateStatus([event.rawData], event.type);
        break;
      case 'bulkSnooze':
        const eventsToSnooze = event.rawData.filter((e: AssetEvent) => e.status === 'active' && e.snoozeQuota !== 0);
        this.onBulkStatusUpdate(eventsToSnooze, 'snooze');
        break;
      case 'bulkAcknowledge':
        const eventsToAcknowledge = event.rawData.filter((e: AssetEvent) => e.status === 'active');
        this.onBulkStatusUpdate(eventsToAcknowledge, 'acknowledge');
        break;
      case 'bulkClose':
        const eventsToClose = event.rawData.filter((e: AssetEvent) => e.status === 'acknowledged');
        this.onBulkStatusUpdate(eventsToClose, 'close');
        break;
      case 'openComments':
        this.selectItem(event.rawData.id);
        this.onOpenComments(event.rawData);
        break;
      case 'openGraphsByVariable':
        this.openGraphsByVariable(event.rawData);
        break;
      default:
        break;
    }
  }

  openGraphsByVariable(data: { asset: Asset; followedVariable: FollowedVariable; siteId: string }) {
    this.analytic.log('grid_actions', 'open_graph_by_variable');
    this.dialog.open(GraphListPopupComponent, {
      width: '990px',
      disableClose: false,
      data
    });
  }

  onOpenComments(assetEvent: AssetEvent) {
    this.selectedAssetEvent.set(assetEvent);
    this.assetEventsApi.loadComments(assetEvent);
    this.analytic.log('grid_actions', 'open_comments');
    this.commentsContexts = [
      { name: Concept.EVENT, checked: true, disabled: false },
      { name: Concept.ASSET, checked: false, disabled: !this.canReadAsset },
      { name: Concept.SITE, checked: false, disabled: !this.canReadSite }
    ];
    this.sidenav.open();
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.onAddComment(event.value as string);
        break;
      case IotAction.EDIT:
        this.onEditComment(event.value as Log);
        break;
      case IotAction.DELETE:
        this.onDeleteComment(event.value as string);
        break;
      case IotAction.CLOSE:
        this.onCloseComments();
        break;
      default:
        break;
    }
  }

  onAddComment(comment: string) {
    this.analytic.log('grid_actions', 'add_comment');
    this.assetEventsApi.addComment(this.selectedAssetEvent() as AssetEvent, comment);
  }

  onEditComment(comment: Log) {
    this.assetEventsApi.editComment(this.selectedAssetEvent()?.id as string, comment);
  }

  onDeleteComment(commentId: string) {
    this.assetEventsApi.deleteComment(this.selectedAssetEvent() as AssetEvent, commentId);
  }

  onCloseComments() {
    this.toggleRefreshActivation(true);
    this.sidenav.close();
  }

  onUpdateStatus(assetEvents: AssetEvent[], status: string) {
    this.analytic.log('grid_actions', 'single_update_status', status);
    this.assetEventsApi.updateStatusByAssetEventId(
      assetEvents.map((assetEvent) => assetEvent.id),
      status
    );
  }

  onBulkStatusUpdate(assetEvents: AssetEvent[], status: string) {
    this.analytic.log('grid_actions', 'bulk_update_status', status);
    this.dialog
      .open(PopupComponent, {
        data: {
          type: 'confirm',
          value: this.translateService.instant('EVENTS.BULK_STATUS_UPDATE.CONFIRMATION_MESSAGES.' + status.toUpperCase(), { total: assetEvents.length })
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation && assetEvents.length) {
          this.analytic.log('cta_actions', 'bulk_update_status', `${status} : ${assetEvents.length}`);
          this.assetEventsApi.bulkUpdateStatusByAssetEventId(
            assetEvents.map((event) => event.id),
            status
          );
        }
      });
  }

  openEventDetail(assetEvent: AssetEvent) {
    this.analytic.log('grid_actions', 'open_event_detail');
    this.selectedAssetEvent.set(assetEvent);
    this.toggleRefreshActivation(false);
    this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(assetEvent);
    this.assetEventsApi.loadComments(assetEvent);

    const detailPopup = this.dialog.open(EventDetailPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: { eventType: 'asset-events', canUpdateEvent: this.canUpdateEvent, gridId: this.grid.id, event: assetEvent }
    });

    detailPopup.componentInstance.updateStatus.subscribe((status: string) => {
      this.analytic.log('popup_actions', 'single_update_status_from_detail_popup', status);
      this.assetEventsApi.updateStatusByAssetEventId([assetEvent.id], status);
    });

    detailPopup.componentInstance.navigateToSite.subscribe((site: Site) => {
      this.navigationApi.selectLeSite(site);
      this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'asset-event');
      this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, assetEvent.id);
      detailPopup.close();
    });

    detailPopup.componentInstance.addComment.subscribe((value: string) => {
      this.analytic.log('popup_actions', 'add_comment_from_detail_popup');
      this.onAddComment(value);
    });

    detailPopup.afterClosed().subscribe((_) => {
      this.toggleRefreshActivation(true);
    });
  }

  transformEventsInArray(events): AssetEvent[] {
    if (events.length > 0) {
      return events;
    }
    const eventAsArray = [];
    eventAsArray.push(events);
    return eventAsArray;
  }

  selectItem(itemId: string) {
    if (this.grid && itemId) {
      this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid.id as string, itemId }));
    }
  }

  checkItems(nodes: any[]) {
    if (nodes.length > 0) {
      this.toggleRefreshActivation(false);
      this.store.dispatch(
        GridsDbActions.checkItemsInGridData({
          gridId: this.grid.id as string,
          itemIds: nodes.map((node) => node.data.id)
        })
      );
    } else {
      this.toggleRefreshActivation(true);
    }
  }

  clearCheckedItems() {
    this.toggleRefreshActivation(true);
    this.store.dispatch(GridsDbActions.checkItemsInGridData({ gridId: this.grid.id as string, itemIds: [] }));
  }

  openSiteDetail({ rowId, site }: { rowId: string; site: Site }) {
    if (site.type === 'stock') {
      this.navigationApi.selectLeStock(site, {
        page: 0,
        limit: 25,
        filters: [{ criteriaKey: 'siteId', value: site.id }]
      });
    } else {
      this.navigationApi.selectLeSite(site);
    }
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'asset-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, rowId);
  }

  openAssetDetail(itemToNavigate: { rowId: string; asset: Asset; site: Site }) {
    this.navigationApi.selectAssetAvecLeSite({ ...itemToNavigate.asset, site: itemToNavigate.site });
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'asset-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, itemToNavigate.rowId);
  }

  openDeviceDetail(itemToNavigate: { rowId: string; device: Device; site: Site }) {
    this.navigationApi.selectDeviceAvecLeSite({ ...itemToNavigate.device, site: itemToNavigate.site });
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'asset-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, itemToNavigate.rowId);
  }

  openGraph(assetEvent: AssetEvent) {
    this.analytic.log('grid_actions', 'open_graph');
    this.toggleRefreshActivation(false);
    this.dialog
      .open(VariableChartDialogComponent, {
        width: '990px',
        data: {
          variables: [
            {
              ...assetEvent.context.assetVariable,
              asset: { id: assetEvent.context.asset?.id, name: assetEvent.context.asset?.name }
            }
          ],
          variableType: 'assetVariable'
        }
      })
      .afterClosed()
      .subscribe((_) => this.toggleRefreshActivation(true));
  }

  openGridSettings() {
    this.analytic.log('toolbar_actions', 'open_grid_settings');
    this.grid$
      .pipe(
        take(1),
        switchMap((gridToUpdate) => {
          this.toggleRefreshActivation(false);
          return this.dialog
            .open(GridManagerUserPopupComponent, {
              width: '1400px',
              disableClose: true,
              data: { grid: { ...gridToUpdate, data: null } }
            })
            .afterClosed();
        })
      )
      .subscribe((value: { action: string; grid: any }) => {
        this.toggleRefreshActivation(true);
        if (!!value) {
          if (value.action === 'DELETE') {
            this.analytic.log('grid_actions', 'delete_grid');
            this.store.dispatch(
              GridsDbActions.removeGrid({
                toRemove: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters }
                }
              })
            );
          }
          if (value.action === 'UPDATE') {
            this.analytic.log('grid_actions', 'update_grid');
            this.store.dispatch(
              GridsDbActions.updateGrid({
                toUpdate: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters }
                }
              })
            );
          }
          if (value.action === 'ADD') {
            this.analytic.log('grid_actions', 'add_grid');
            this.store.dispatch(
              GridsDbActions.addGrid({
                toAdd: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters }
                }
              })
            );
          }
        }
      });
  }

  onApplyFavoriteView(favoriteView: FavoriteView) {
    this.assetEventsApi.setCurrentFavoriteView(favoriteView);
    if (favoriteView) {
      this.analytic.log('toolbar_actions', 'select_favorite_view');
      this.selectGridAndLoadData(favoriteView.gridId as string, favoriteView.masterView as string, this.currentFilters);
    } else {
      this.selectDefaultGrid();
      this.analytic.log('toolbar_actions', 'reset_to_default_favorite_view');
    }
  }

  onApplyFilters(filters: Filter[]) {
    if (filters.length === 0) {
      this.onApplyFavoriteView(null);
    } else {
      this.assetEventsApi.setCurrentFilters(filters);
      const gaLog = Object.entries(filters)
        .map(([_, value]) => `${value['criteriaKey']}`)
        .join(', ');
      this.analytic.log('filters_actions', 'apply_filters', `Applied filters : ${gaLog}`);
    }
    this.loadData();
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    {
      switch (event.type) {
        case IotToolbarDispatchActionType.REFRESH_PAGE:
          this.onRefreshClicked();
          break;
        case IotToolbarDispatchActionType.EXPORT_DATA:
          this.onExportData();
          break;
        case IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS:
          this.openGridSettings();
          break;
        case IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW:
          this.onApplyFavoriteView(event.options);
          break;
        case IotToolbarDispatchActionType.APPLY_GRID:
          this.onApplyGrid(event.options.grid);
          break;
        case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
          this.onShowFilter();
          break;
        case IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW:
          this.onCreateFavoriteView();
          break;
        case IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW:
          this.onEditFavoriteView();
          break;
        case IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW:
          this.onDeleteFavoriteView();
          break;
        default:
          break;
      }
    }
  }

  onShowFilter(): void {
    this.analytic.log('toolbar_actions', this.filterEngineOpened ? 'close_filter_engine' : 'open_filter_engine');
    if (this.user) {
      this.filterEngineOpened = !this.filterEngineOpened;
      this.user.preferences = { ...this.user.preferences, filterEngineOpenByDefault: this.filterEngineOpened };
      this.store.dispatch(
        PreferencesActions.saveUserPreferences({
          user: this.user,
          preferences: this.user.preferences
        })
      );
    }
  }

  onRefreshClicked() {
    this.analytic.log('toolbar_actions', 'refresh');
    this.loadData();
  }

  clearAppliedFilters() {
    this.analytic.log('filters_actions', 'clear_filters');
    this.assetEventsApi.setCurrentFavoriteView(null);
    this.selectDefaultGrid();
  }

  ngOnDestroy() {
    this.hasLeft = true;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private handleScrollPosition() {
    const keepScrollPosition = this.router.getCurrentNavigation()?.extras.state?.keepScrollPosition;
    if (keepScrollPosition) {
      this.selectedRowId = this.storage.get(LocalStorageKeys.STORAGE_SELECTED_ROW_ID);
    }
  }

  private toggleRefreshActivation(refreshActivated: boolean): void {
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated }));
  }

  private selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[]) {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters }));
  }

  private selectDefaultGrid(): void {
    this.selectGridAndLoadData('default', 'asset-events', this.currentFilters);
  }
}
