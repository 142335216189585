import { createReducer, on } from '@ngrx/store';
import {
  BusinessProfilesApiActions,
  BusinessProfilesMembersApiActions,
  BusinessProfilesPageActions,
  BusinessProfilesRolesApiActions,
  BusinessProfilesTagsApiActions
} from '../actions';

export const adminBusinessProfilesPageFeatureKey = 'adminBusinessProfilesPage';

export interface State {
  error: any;
  pending: boolean;
  searchString?: string;
}

export const initialState: State = {
  error: null,
  pending: false,
  searchString: ''
};

export const getError = (state: State) => state.error;

export const reducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listBusinessProfiles, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesApiActions.listBusinessProfilesSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesApiActions.listBusinessProfilesFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesPageActions.listMembersByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesPageActions.listRolesByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesPageActions.listTagsByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesPageActions.filterBusinessProfiles, (state, { searchString }) => ({ ...state, searchString }))
);
export const getPending = (state: State) => state.pending;
export const getSearchString = (state: State) => state.searchString;
