import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardLoaderModule } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { DateFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { AbstractAuditTrailService } from '../../services/abstract-audit-trail.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot-platform-audit-trail-history-log-popup',
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCardModule,
    TranslateModule,
    MatToolbarModule,
    MatIconModule,
    CardLoaderModule,
    NgClass,
    UserNamePipe,
    MatButtonModule,
    MatTooltipModule,
    DateFormatPipe,
    JsonPipe
  ],
  templateUrl: './history-logs-popup.component.html',
  styleUrls: ['./history-logs-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryLogsPopupComponent {
  historyLogs: Signal<HistoryLog[] | undefined> = signal([]);
  historyLogsLoading: WritableSignal<boolean> = signal(true);
  historyLogsDetailById: { [id: string]: HistoryLogDetail } = {};
  selectedHistoryLog?: HistoryLogDetail;
  selectedLodLoading: WritableSignal<boolean> = signal(false);
  readonly dialogRef: MatDialogRef<HistoryLogsPopupComponent> = inject(MatDialogRef<HistoryLogsPopupComponent>);
  readonly data: { concept: string; elementId: string; elementName: string } = inject(MAT_DIALOG_DATA);
  protected readonly auditTrailService: AbstractAuditTrailService<unknown> = inject(AbstractAuditTrailService);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.historyLogs = toSignal(
      this.auditTrailService.getHistoryLogs(this.data).pipe(
        map((data) => data.sort(SortUtil.sortBy('datetime', false))),
        finalize(() => this.historyLogsLoading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
    );
  }

  selectHistoryLog(log: HistoryLog): void {
    if (!!this.historyLogsDetailById && !!this.historyLogsDetailById[log.id]) {
      this.selectedHistoryLog = this.historyLogsDetailById[log.id];
      this.selectedLodLoading.set(false);
    } else {
      this.selectedLodLoading.set(true);
      this.auditTrailService
        .getHistoryLogById({
          ...this.data,
          id: log.id
        })
        .pipe(
          finalize(() => this.selectedLodLoading.set(false)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((historyLogDetail: HistoryLogDetail) => {
          this.selectedHistoryLog = historyLogDetail;
          this.historyLogsDetailById[log.id] = historyLogDetail;
        });
    }
  }

  getParsedAttribute(object: unknown, attribute: string) {
    return get(object, attribute);
  }

  close(): void {
    this.dialogRef.close();
  }
}
