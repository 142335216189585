import { Sort, SortDirection } from '@angular/material/sort';
import { gridSortModel } from '@iot-platform/models/common';

export class SortModelAdapter {
  static toGridSortModel(sort: gridSortModel[] | Sort): gridSortModel[] {
    if (Array.isArray(sort)) {
      return sort;
    }
    return sort ? [{ colId: sort.active, sort: sort.direction }] : null;
  }

  static toTableSortModel(sort: gridSortModel[] | Sort): Sort {
    if (Array.isArray(sort)) {
      return sort.length ? { active: sort[0].colId, direction: sort[0].sort as SortDirection } : null;
    }
    return sort;
  }
}
