import { PlatformRequest } from '@iot-platform/models/common';

import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEventsBySite = createAction('[AssetEventsBySite] Load Asset Events By Site', props<{ request: PlatformRequest }>());
//
export const loadMvAssetEventsBySiteSettings = createAction('[AssetEventsBySite] Load MV AssetEventsBySite Settings', props<{ settingName: string }>());
//
export const loadTotalActiveAssetEventsBySite = createAction(
  '[AssetEventsBySite] Load Total Active Asset Events By Site',
  props<{ request: PlatformRequest }>()
);
//
export const saveTableStateBySite = createAction(
  '[AssetEventsBySite] Save Table State',
  props<{ tableState: { checked: AssetEvent[]; selected: AssetEvent } }>()
);
//
export const updateStatusByAssetEventIdBySite = createAction(
  '[AssetEventsBySite] Update Status By Asset Event Id',
  props<{ status: { assetEventId: string; value: string } }>()
);
//
export const bulkUpdateStatusByAssetEventIdBySite = createAction(
  '[AssetEventsBySite] Bulk Update Status By Asset Event Id',
  props<{ assetEventIds: string[]; status: string }>()
);
