import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { AbstractTrackingSettingsProviderService } from '../services/abstract-tracking-settings-provider.service';
import { TrackingService } from '../services/tracking.service';
import { TrackingActions } from './tracking.actions';

const trackEventById$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), trackingSettingsProviderService = inject(AbstractTrackingSettingsProviderService), trackingService = inject(TrackingService)) =>
    actions$.pipe(
      ofType(TrackingActions.trackEventById),
      withLatestFrom(
        trackingSettingsProviderService.getTrackingData$(),
        trackingSettingsProviderService.getCurrentUrl$(),
        trackingSettingsProviderService.getPreviousUrl$()
      ),
      tap(([{ eventId, additionalTrackingData }, trackingData, currentUrl, previousUrl]) => {
        trackingService.trackEvent(eventId, { ...trackingData, ...additionalTrackingData, currentUrl, previousUrl });
      })
    ),
  { functional: true, dispatch: false }
);

export const CoreTrackingEffects = {
  trackEventById$
};
