import { Injectable } from '@angular/core';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AdminProductCatalogsService } from '../../services/admin-product-catalogs.service';
import { ProductCatalogsDbActions, ProductCatalogsUiActions, ProductsUiActions } from '../actions';
import * as fromProducts from '../reducers';

@Injectable()
export class ProductCatalogsEffects {
  listProductCatalogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCatalogsUiActions.listProductCatalogs),
      switchMap((action) =>
        this.productCatalogsService.getAllProductCatalogs(action.request).pipe(
          map((response) => ProductCatalogsDbActions.listProductCatalogsSuccess({ response })),
          catchError((error) => of(ProductCatalogsDbActions.listProductCatalogsFailure({ error })))
        )
      )
    )
  );
  addProductCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCatalogsUiActions.addProductCatalog),
      concatMap((action) =>
        this.productCatalogsService.addProductCatalog(action.productCatalogToAdd).pipe(
          map((addedProductCatalog) => ProductCatalogsDbActions.addProductCatalogSuccess({ addedProductCatalog })),
          catchError((error) => of(ProductCatalogsDbActions.addProductCatalogFailure({ error })))
        )
      )
    )
  );
  updateProductCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCatalogsUiActions.updateProductCatalog),
      concatMap((action) =>
        this.productCatalogsService.updateProductCatalog(action.productCatalogToUpdate).pipe(
          map((updatedProductCatalog) => ProductCatalogsDbActions.updateProductCatalogSuccess({ updatedProductCatalog })),
          catchError((error) => of(ProductCatalogsDbActions.updateProductCatalogFailure({ error })))
        )
      )
    )
  );
  deleteProductCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCatalogsUiActions.deleteProductCatalog),
      concatMap((action) =>
        this.productCatalogsService.deleteProductCatalog(action.productCatalogToDelete).pipe(
          map((deletedProductCatalog) => ProductCatalogsDbActions.deleteProductCatalogSuccess({ deletedProductCatalog })),
          catchError((error) => of(ProductCatalogsDbActions.deleteProductCatalogFailure({ error })))
        )
      )
    )
  );
  deleteCatalogThenLoadProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCatalogsDbActions.deleteProductCatalogSuccess),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromProducts.getProductFilters)))),
      map(([, filters]) => ProductsUiActions.listProducts({ request: { limit: 500, filters, page: 0 } }))
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductCatalogsDbActions.addProductCatalogSuccess,
          ProductCatalogsDbActions.updateProductCatalogSuccess,
          ProductCatalogsDbActions.deleteProductCatalogSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );
  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductCatalogsDbActions.addProductCatalogFailure,
          ProductCatalogsDbActions.updateProductCatalogFailure,
          ProductCatalogsDbActions.deleteProductCatalogFailure,
          ProductCatalogsDbActions.listProductCatalogsFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );
  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductCatalogsUiActions.listProductCatalogs,
          ProductCatalogsUiActions.addProductCatalog,
          ProductCatalogsUiActions.updateProductCatalog,
          ProductCatalogsUiActions.deleteProductCatalog
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );
  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductCatalogsDbActions.listProductCatalogsSuccess,
          ProductCatalogsDbActions.listProductCatalogsFailure,
          ProductCatalogsDbActions.addProductCatalogSuccess,
          ProductCatalogsDbActions.addProductCatalogFailure,
          ProductCatalogsDbActions.updateProductCatalogSuccess,
          ProductCatalogsDbActions.updateProductCatalogFailure,
          ProductCatalogsDbActions.deleteProductCatalogSuccess,
          ProductCatalogsDbActions.deleteProductCatalogFailure
        ),
        tap(() => {
          this.notificationService.hideLoader();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private notificationService: NotificationService,
    private productCatalogsService: AdminProductCatalogsService
  ) {}
}
