@if (asset) {
  <section fxLayout="column" fxLayoutGap="1px">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ 'IOT_DICTIONARY.FOLLOWED_VARIABLE' | translate }}"
      style="padding-bottom: 10px"
    ></iot-platform-ui-detail-popup-section-header>
    @for (followedNumber of arrayToIterateForFollowedNumbers; track followedNumber) {
      <iot-platform-ui-detail-followed-variable
        [followedNumber]="followedNumber"
        [followedVariable]="asset?.followedVariables[followedNumber]"
      ></iot-platform-ui-detail-followed-variable>
    }
  </section>
}
