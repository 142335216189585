<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ getLabels('title') }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="contactForm" class="p-20">
      <div class="container">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20">
          <span class="site-contact_form--notification-label">{{ 'SITES.CONTACT_FORM.NOTIFICATION_SUPPORTS' | translate }}</span>
          <mat-slide-toggle color="accent" formControlName="notificationViaEmail">
            <mat-icon [matTooltip]="'IOT_DICTIONARY.EMAIL' | translate" matTooltipClass="regular_tooltip">mail </mat-icon>
          </mat-slide-toggle>
          <mat-slide-toggle color="accent" formControlName="notificationViaSms">
            <mat-icon [matTooltip]="'IOT_DICTIONARY.SMS' | translate" matTooltipClass="regular_tooltip"> sms</mat-icon>
          </mat-slide-toggle>
        </div>
        <mat-form-field color="accent">
          <mat-label>{{ 'SITES.CONTACT_FORM.FIRST_NAME' | translate }}</mat-label>
          <input #firstnameInput formControlName="firstname" matInput maxlength="50" pattern="\S.*" required />
          @if (firstname.invalid && (firstname.errors?.maxlength || firstname.errors?.pattern)) {
            <mat-error>{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ firstnameInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>{{ 'SITES.CONTACT_FORM.LAST_NAME' | translate }}</mat-label>
          <input #lastnameInput formControlName="lastname" matInput maxlength="50" pattern="\S.*" required />
          @if (lastname.invalid && (lastname.errors?.maxlength || lastname.errors?.pattern)) {
            <mat-error>{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ lastnameInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>{{ 'SITES.CONTACT_FORM.FUNCTION' | translate }}</mat-label>
          <input #jobTitleInput formControlName="function" matInput maxlength="40" />
          <mat-hint align="end">{{ jobTitleInput.value?.length || 0 }}/40</mat-hint>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>{{ 'SITES.CONTACT_FORM.EMAIL' | translate }}</mat-label>
          <input #emailInput formControlName="email" matInput maxlength="50" />
          @if (email.invalid) {
            <mat-error>{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.NOT_EMAIL' | translate }}</mat-error>
          }
          @if (email.invalid && email.errors?.emailDuplicate) {
            <mat-error>{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.DUPLICATE_EMAIL' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ emailInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>{{ 'SITES.CONTACT_FORM.PHONE' | translate }}</mat-label>
          <iot-platform-ui-phone-number-input formControlName="phone" maxlength="20"></iot-platform-ui-phone-number-input>
          @if (phone.invalid) {
            <mat-error>{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.NOT_PHONE' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ phone.value?.length || 0 }}/20</mat-hint>
          <mat-icon matSuffix matTooltip="{{ 'SITES.CONTACT_FORM.INFO_MESSAGE.PHONE_FORMAT' | translate }}"> info_outline </mat-icon>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>{{ 'SITES.CONTACT_FORM.COMMENT' | translate }}</mat-label>
          <textarea #commentInput formControlName="comment" matInput maxlength="300"></textarea>
          <mat-hint align="end">{{ commentInput.value?.length || 0 }}/300</mat-hint>
        </mat-form-field>
        <p
          [ngClass]="{ 'layout-visible': !email.value && !phone.value, 'layout-invisible': !!email.value || !!phone.value }"
          class="site-contact-form--email-sms-warning"
        >
          {{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.EMAIL_PHONE_NOT_BOTH_EMPTY' | translate }}
        </p>
        <p class="dialog-form-tips">{{ 'SITES.CONTACT_FORM.MANDATORY_FIELDS' | translate }}</p>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="isSaveButtonDisabled()" class="button-regular" color="accent" mat-raised-button>
      {{ getLabels('action') }}
    </button>
  </mat-card-actions>
</mat-card>
