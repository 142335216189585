import { BusinessProfile } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthApiActions, AuthBusinessProfilesApiActions, AuthBusinessProfilesPageActions, AuthPageActions } from '../actions';

export interface AuthBusinessProfilesApiState extends EntityState<BusinessProfile> {
  selectedBusinessProfileId: string | null;
}

export const adapter: EntityAdapter<BusinessProfile> = createEntityAdapter<BusinessProfile>({
  selectId: (businessProfile: BusinessProfile) => businessProfile.id,
  sortComparer: false
});

export const initialState: AuthBusinessProfilesApiState = adapter.getInitialState({
  selectedBusinessProfileId: null
});

export const authBusinessProfilesApiFeature = createFeature({
  name: 'authBusinessProfilesApi',
  reducer: createReducer(
    initialState,
    on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: AuthBusinessProfilesApiState, { selectedBusinessProfile }) => ({
      ...state,
      selectedBusinessProfileId: selectedBusinessProfile?.id
    })),
    on(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess, (state: AuthBusinessProfilesApiState, { selectedBusinessProfile }) => ({
      ...state,
      selectedBusinessProfile
    })),
    on(AuthBusinessProfilesPageActions.loadBusinessProfiles, (state: AuthBusinessProfilesApiState) => adapter.removeAll(state)),
    on(AuthBusinessProfilesApiActions.loadBusinessProfilesSuccess, (state: AuthBusinessProfilesApiState, { businessProfiles }) =>
      adapter.setAll(businessProfiles, state)
    ),
    on(AuthPageActions.signOut, () => initialState),
    on(AuthApiActions.retrieveSessionFailure, () => initialState)
  )
});
