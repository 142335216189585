import { PlatformResponse } from '@iot-platform/models/common';
import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadDeviceEventsBySiteSuccess = createAction(
  '[DeviceEventsBySite] Load Device Events By Site Success',
  props<{ response: PlatformResponse; siteId: string }>()
);
export const loadDeviceEventsBySiteFailure = createAction('[DeviceEventsBySite] Load Device Events By Site Failure', props<{ error: any }>());
//
export const loadTotalActiveDeviceEventsBySiteSuccess = createAction(
  '[DeviceEventsBySite] Load Total Active Device Events By Site Success',
  props<{ totalActiveEvents: number }>()
);
export const loadTotalActiveAssetEventsBySiteFailure = createAction(
  '[DeviceEventsBySite] Load Total Active Device Events By Site Failure',
  props<{ error: any }>()
);
//
export const saveTableBySiteStateSuccess = createAction('[DeviceEventsBySite] Save Table State Success', props<{ selectedId: string; checkedIds: string[] }>());
export const saveTableBySiteStateFailure = createAction('[DeviceEventsBySite] Save Table State Failure', props<{ error: any }>());

export const updateStatusByDeviceEventIdBySiteSuccess = createAction(
  '[DeviceEventsBySite] Update Status By Device Event Id Success',
  props<{ deviceEvent: DeviceEvent }>()
);
export const updateStatusByDeviceEventIdBySiteFailure = createAction('[DeviceEventsBySite] Update Status By Device Event Id Failure', props<{ error: any }>());
//
export const bulkUpdateStatusByDeviceEventIdBySiteSuccess = createAction(
  '[DeviceEventsBySite] Bulk Update Status By Device Event Id Success',
  props<{ deviceEvents: DeviceEvent[] }>()
);
export const bulkUpdateStatusByDeviceEventIdFailure = createAction(
  '[DeviceEventsBySite] Bulk Update Status By Device Event Id Failure',
  props<{ error: any }>()
);
//
export const loadMvDeviceEventsBySiteSettingsSuccess = createAction(
  '[DeviceEventsBySite] Load MV DeviceEventsBySite Settings Success',
  props<{ settings: any }>()
);
export const loadMvDeviceEventsBySiteSettingsFailure = createAction(
  '[DeviceEventsBySite] Load MV DeviceEventsBySite Settings Failure',
  props<{ error: any }>()
);
