import { CommonApiRequest, Filter } from '@iot-platform/models/common';
import { Calendar, OCMDayOff, OCMDayOffType } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listCalendars = createAction('[Calendars] List Calendars', props<{ request: CommonApiRequest }>());
export const getCalendar = createAction('[Calendars] Get Calendar', props<{ calendarId: string }>());

export const addCalendar = createAction('[Calendars] Add Calendar', props<{ calendarToAdd: Calendar }>());
export const updateCalendar = createAction('[Calendars] Update Calendar', props<{ calendarToUpdate: Calendar }>());
export const deleteCalendar = createAction('[Calendars] Delete Calendar', props<{ calendarToDelete: Calendar }>());
export const navigateToCalendarDetails = createAction('[Calendars] Navigate To Calendar Details', props<{ calendarToNavigate: Calendar }>());

export const loadMVSettings = createAction('[Calendars] Load Calendars Settings', props<{ settingName: string }>());

export const saveMVSettings = createAction('[Calendars] Save Calendars Settings', props<{ name: string; values: any }>());

export const saveCalendarsFilters = createAction('[Calendars] Save Calendars Filters', props<{ filters: Filter[] }>());

// Days off
export const listDaysOffByCalendarId = createAction('[Calendars] List Days Off by Calendar Id', props<{ calendarId: string }>());
export const addDayOff = createAction('[Calendars] Add Day Off', props<{ dayOff: OCMDayOff; calendarId: string }>());
export const updateDayOff = createAction('[Calendars] Update Day Off', props<{ calendarId: string; dayOff: OCMDayOff }>());
export const deleteDayOff = createAction('[Calendars] Delete Day Off', props<{ calendarId: string; dayOff: OCMDayOff }>());
export const importDaysOffByCalendarId = createAction(
  '[Calendars] Import Days Off',
  props<{ sourceCalendarId: string; calendarId: string; dayOffType: OCMDayOffType }>()
);
