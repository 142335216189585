import { PlatformResponse } from '@iot-platform/models/common';
import { Asset, Device, Site, SiteAssociationByAsset, SiteAssociationByDevice, SiteAssociations, SiteAssociationsWithData } from '@iot-platform/models/i4b';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import * as fromAssetEventsByAssetDb from './asset-events-by-asset/asset-events-by-asset-db.reducer';
import * as fromAssetEventsByAssetLogsDb from './asset-events-by-asset/asset-events-by-asset-logs-db.reducer';
import * as fromAssetEventsByAssetLogsUi from './asset-events-by-asset/asset-events-by-asset-logs-ui.reducer';
import * as fromAssetEventsByAssetUi from './asset-events-by-asset/asset-events-by-asset-ui.reducer';
import * as fromAssetEventsBySiteDb from './asset-events-by-site/asset-events-by-site-db.reducer';
import * as fromAssetEventsBySiteLogsDb from './asset-events-by-site/asset-events-by-site-logs-db.reducer';
import * as fromAssetEventsBySiteLogsUi from './asset-events-by-site/asset-events-by-site-logs-ui.reducer';
import * as fromAssetEventsBySiteUi from './asset-events-by-site/asset-events-by-site-ui.reducer';
import * as fromDeviceEventsByDeviceDb from './device-events-by-device/device-events-by-device-db.reducer';
import * as fromDeviceEventsByDeviceLogsDb from './device-events-by-device/device-events-by-device-logs-db.reducer';
import * as fromDeviceEventsByDeviceLogsUi from './device-events-by-device/device-events-by-device-logs-ui.reducer';
import * as fromDeviceEventsByDeviceUi from './device-events-by-device/device-events-by-device-ui.reducer';
import * as fromDeviceEventsBySiteDb from './device-events-by-site/device-events-by-site-db.reducer';
import * as fromDeviceEventsBySiteLogsDb from './device-events-by-site/device-events-by-site-logs-db.reducer';
import * as fromDeviceEventsBySiteLogsUi from './device-events-by-site/device-events-by-site-logs-ui.reducer';
import * as fromDeviceEventsBySiteUi from './device-events-by-site/device-events-by-site-ui.reducer';
import * as fromNavigationUi from './navigation-ui.reducer';
//
export const navigationFeatureKey = 'navigation';

export interface NavigationState {
  [fromNavigationUi.navigationUiFeatureKey]: fromNavigationUi.State;
  [fromAssetEventsByAssetDb.assetEventsByAssetDbFeatureKey]: fromAssetEventsByAssetDb.State;
  [fromAssetEventsByAssetUi.assetEventsByAssetUiFeatureKey]: fromAssetEventsByAssetUi.State;
  [fromAssetEventsByAssetLogsDb.assetEventsByAssetLogsDbFeatureKey]: fromAssetEventsByAssetLogsDb.State;
  [fromAssetEventsByAssetLogsUi.assetEventsByAssetLogsUiFeatureKey]: fromAssetEventsByAssetLogsUi.State;
  [fromAssetEventsBySiteDb.assetEventsBySiteDbFeatureKey]: fromAssetEventsBySiteDb.State;
  [fromAssetEventsBySiteUi.assetEventsBySiteUiFeatureKey]: fromAssetEventsBySiteUi.State;
  [fromAssetEventsBySiteLogsDb.assetEventsBySiteLogsDbFeatureKey]: fromAssetEventsBySiteLogsDb.State;
  [fromAssetEventsBySiteLogsUi.assetEventsBySiteLogsUiFeatureKey]: fromAssetEventsBySiteLogsUi.State;
  [fromDeviceEventsBySiteDb.deviceEventsBySiteDbFeatureKey]: fromDeviceEventsBySiteDb.State;
  [fromDeviceEventsBySiteUi.deviceEventsBySiteUiFeatureKey]: fromDeviceEventsBySiteUi.State;
  [fromDeviceEventsBySiteLogsDb.deviceEventsBySiteLogsDbFeatureKey]: fromDeviceEventsBySiteLogsDb.State;
  [fromDeviceEventsBySiteLogsUi.deviceEventsBySiteLogsUiFeatureKey]: fromDeviceEventsBySiteLogsUi.State;
  [fromDeviceEventsByDeviceDb.deviceEventsByDeviceDbFeatureKey]: fromDeviceEventsByDeviceDb.State;
  [fromDeviceEventsByDeviceUi.deviceEventsByDeviceUiFeatureKey]: fromDeviceEventsByDeviceUi.State;
  [fromDeviceEventsByDeviceLogsDb.deviceEventsByDeviceLogsDbFeatureKey]: fromDeviceEventsByDeviceLogsDb.State;
  [fromDeviceEventsByDeviceLogsUi.deviceEventsByDeviceLogsUiFeatureKey]: fromDeviceEventsByDeviceLogsUi.State;
}

export interface State {
  [navigationFeatureKey]: NavigationState;
}

export function reducers(state: NavigationState | undefined, action: Action) {
  return combineReducers({
    [fromNavigationUi.navigationUiFeatureKey]: fromNavigationUi.reducer,
    [fromAssetEventsByAssetDb.assetEventsByAssetDbFeatureKey]: fromAssetEventsByAssetDb.reducer,
    [fromAssetEventsByAssetUi.assetEventsByAssetUiFeatureKey]: fromAssetEventsByAssetUi.reducer,
    [fromAssetEventsByAssetLogsDb.assetEventsByAssetLogsDbFeatureKey]: fromAssetEventsByAssetLogsDb.reducer,
    [fromAssetEventsByAssetLogsUi.assetEventsByAssetLogsUiFeatureKey]: fromAssetEventsByAssetLogsUi.reducer,
    [fromAssetEventsBySiteDb.assetEventsBySiteDbFeatureKey]: fromAssetEventsBySiteDb.reducer,
    [fromAssetEventsBySiteUi.assetEventsBySiteUiFeatureKey]: fromAssetEventsBySiteUi.reducer,
    [fromAssetEventsBySiteLogsDb.assetEventsBySiteLogsDbFeatureKey]: fromAssetEventsBySiteLogsDb.reducer,
    [fromAssetEventsBySiteLogsUi.assetEventsBySiteLogsUiFeatureKey]: fromAssetEventsBySiteLogsUi.reducer,
    [fromDeviceEventsBySiteDb.deviceEventsBySiteDbFeatureKey]: fromDeviceEventsBySiteDb.reducer,
    [fromDeviceEventsBySiteUi.deviceEventsBySiteUiFeatureKey]: fromDeviceEventsBySiteUi.reducer,
    [fromDeviceEventsBySiteLogsDb.deviceEventsBySiteLogsDbFeatureKey]: fromDeviceEventsBySiteLogsDb.reducer,
    [fromDeviceEventsBySiteLogsUi.deviceEventsBySiteLogsUiFeatureKey]: fromDeviceEventsBySiteLogsUi.reducer,
    [fromDeviceEventsByDeviceDb.deviceEventsByDeviceDbFeatureKey]: fromDeviceEventsByDeviceDb.reducer,
    [fromDeviceEventsByDeviceUi.deviceEventsByDeviceUiFeatureKey]: fromDeviceEventsByDeviceUi.reducer,
    [fromDeviceEventsByDeviceLogsDb.deviceEventsByDeviceLogsDbFeatureKey]: fromDeviceEventsByDeviceLogsDb.reducer,
    [fromDeviceEventsByDeviceLogsUi.deviceEventsByDeviceLogsUiFeatureKey]: fromDeviceEventsByDeviceLogsUi.reducer
  })(state, action);
}

export const selectNavigationState = createFeatureSelector<NavigationState>(navigationFeatureKey);
export const selectNavigationUiState = createSelector(selectNavigationState, (state: NavigationState) => state[fromNavigationUi.navigationUiFeatureKey]);

export const getSite = createSelector(selectNavigationUiState, fromNavigationUi.getSite);
export const getSiteLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getSiteLoaded);
export const getSiteTags = createSelector(selectNavigationUiState, fromNavigationUi.getSiteTags);
export const getSiteTagsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getSiteTagsLoaded);

export const getSelectedAsset = createSelector(selectNavigationUiState, fromNavigationUi.getSelectedAsset);
export const getSelectedDevice = createSelector(selectNavigationUiState, fromNavigationUi.getSelectedDevice);

export const getAssets = createSelector(selectNavigationUiState, fromNavigationUi.getAssets);
export const getAssetsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetsLoaded);
export const getDevices = createSelector(selectNavigationUiState, fromNavigationUi.getDevices);
export const getDevicesLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDevicesLoaded);
export const getDevicesByStock = createSelector(selectNavigationUiState, fromNavigationUi.getDevicesByStock);

export const getSiteId = createSelector(selectNavigationUiState, fromNavigationUi.getSiteId);
export const getActiveId = createSelector(selectNavigationUiState, fromNavigationUi.getActiveId);
export const getOrigin = createSelector(selectNavigationUiState, fromNavigationUi.getOrigin);
export const getPageType = createSelector(selectNavigationUiState, fromNavigationUi.getPageType);

export const getAssetLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetLoaded);
export const getAssetTags = createSelector(selectNavigationUiState, fromNavigationUi.getAssetTags);
export const getAssetTagsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetTagsLoaded);
export const getAssetVariables = createSelector(selectNavigationUiState, (state) => state.assetVariables.sort(SortUtil.sortByName));
export const getAssetVariablesLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetVariablesLoaded);

export const getDeviceLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceLoaded);
export const getDeviceTags = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceTags);
export const getDeviceTagsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceTagsLoaded);
export const getDeviceVariables = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceVariables);
export const getDeviceVariablesTableFilters = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceVariablesTableFilters);
export const getDeviceVariablesLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceVariablesLoaded);

export const getSiteAssociations = createSelector(selectNavigationUiState, fromNavigationUi.getSiteAssociations);
export const getFullAssociations = createSelector(
  getSiteAssociations,
  getAssets,
  getDevices,
  getAssetsLoaded,
  getDevicesLoaded,
  (associations: SiteAssociations, assets: Asset[], devices: Device[], assetsLoaded, devicesLoaded) => {
    if (associations && assetsLoaded && devicesLoaded) {
      const fullAssociations: SiteAssociationsWithData = { assets: [], devices: [] };
      const orphanDevices: SiteAssociationByAsset = { asset: null, devices: [] };
      const orphanAssets: SiteAssociationByDevice = { device: null, assets: [] };

      fullAssociations.assets = assets.reduce((accumulator, asset) => {
        const fullAssociationByAsset: SiteAssociationByAsset = {
          asset,
          devices: associations.assets[asset.id]
            ? associations.assets[asset.id].reduce((acc, deviceId) => {
                const associatedDevice: Device = devices.find((d) => d.id === deviceId);
                acc.push(associatedDevice);
                return acc;
              }, [])
            : []
        };

        if (!associations.assets[asset.id] || !associations.assets[asset.id].length) {
          orphanAssets.assets.push(asset);
        }

        accumulator.push(fullAssociationByAsset);
        return accumulator;
      }, []);

      fullAssociations.devices = devices.reduce((accumulator, device) => {
        const fullAssociationByDevice: SiteAssociationByDevice = {
          device,
          assets: associations.devices[device.id]
            ? associations.devices[device.id].reduce((acc, assetId) => {
                const associatedAsset: Asset = assets.find((a) => a.id === assetId);
                acc.push(associatedAsset);

                return acc;
              }, [])
            : []
        };

        if (!associations.devices[device.id] || !associations.devices[device.id].length) {
          orphanDevices.devices.push(device);
        }
        accumulator.push(fullAssociationByDevice);
        return accumulator;
      }, []);

      if (orphanDevices.devices.length) {
        fullAssociations.assets.push(orphanDevices);
      }
      if (orphanAssets.assets.length) {
        fullAssociations.devices.push(orphanAssets);
      }

      return fullAssociations;
    }
  }
);

export const getFullAssociationsLoaded = createSelector(getFullAssociations, (associations) => !!(associations && associations.assets && associations.devices));

export const getData = createSelector(selectNavigationUiState, (state: fromNavigationUi.State) => {
  const data: { site: Site; assets: Asset[]; devices: Device[] } = { site: state.site, assets: state.assets, devices: state.devices };
  return data;
});

export const selectIsSiteReadyFromDeletion = createSelector(getAssets, getDevices, (assets: Asset[], devices: Device[]) => {
  const assetsNotDecommissioned = assets.filter((a) => a.status.name !== 'decommissioned');
  const devicesNotDecommissioned = devices.filter((d) => d.status?.name !== 'decommissioned');

  return !assetsNotDecommissioned.length && !devicesNotDecommissioned.length;
});

/// //////////////////////////
//  **** Stock Sites ****  //
/// //////////////////////////

export const selectDeviceCountByFamily = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceCountByFamily);
export const selectDeviceCountByFamilyLoading = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceCountByFamilyLoading);

// ////////////////
//  **** LOGS ****  //
/// ///////////////////

// ASSET EVENTS BY ASSET //

export const selectAssetEventsByAssetLogsDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsByAssetLogsDb.assetEventsByAssetLogsDbFeatureKey]
);
export const selectAssetEventsByAssetLogsUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsByAssetLogsUi.assetEventsByAssetLogsUiFeatureKey]
);
export const {
  selectIds: getAssetEventsByAssetLogsIds,
  selectEntities: getAssetEventsByAssetLogsEntities,
  selectAll: getAllAssetEventsByAssetLogs,
  selectTotal: getAssetEventsByAssetTotalLogs
} = fromAssetEventsBySiteLogsDb.adapter.getSelectors(selectAssetEventsByAssetLogsDbState);

export const getAssetEventsByAssetLog = createSelector(selectAssetEventsByAssetLogsDbState, fromAssetEventsByAssetLogsDb.getLog);
export const getAssetEventsByAssetLogsLoading = createSelector(selectAssetEventsByAssetLogsUiState, fromAssetEventsByAssetLogsUi.getLogsLoading);
export const getAssetEventsByAssetLogsLoaded = createSelector(selectAssetEventsByAssetLogsUiState, fromAssetEventsByAssetLogsUi.getLogsLoaded);

// ASSET EVENTS BY SITE //

export const selectAssetEventsBySiteLogsDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsBySiteLogsDb.assetEventsBySiteLogsDbFeatureKey]
);
export const selectAssetEventsBySiteLogsUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsBySiteLogsUi.assetEventsBySiteLogsUiFeatureKey]
);
export const {
  selectIds: getAssetEventsBySiteLogsIds,
  selectEntities: getAssetEventsBySiteLogsEntities,
  selectAll: getAllAssetEventsBySiteLogs,
  selectTotal: getAssetEventsBySiteTotalLogs
} = fromAssetEventsBySiteLogsDb.adapter.getSelectors(selectAssetEventsBySiteLogsDbState);

export const getAssetEventsBySiteLog = createSelector(selectAssetEventsBySiteLogsDbState, fromAssetEventsBySiteLogsDb.getLog);
export const getAssetEventsBySiteLogsLoading = createSelector(selectAssetEventsBySiteLogsUiState, fromAssetEventsBySiteLogsUi.getLogsLoading);
export const getAssetEventsBySiteLogsLoaded = createSelector(selectAssetEventsBySiteLogsUiState, fromAssetEventsBySiteLogsUi.getLogsLoaded);

// DEVICE EVENTS BY DEVICE //

export const selectDeviceEventsByDeviceLogsDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsByDeviceLogsDb.deviceEventsByDeviceLogsDbFeatureKey]
);
export const selectDeviceEventsByDeviceLogsUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsByDeviceLogsUi.deviceEventsByDeviceLogsUiFeatureKey]
);
export const {
  selectIds: getDeviceEventsByDeviceLogsIds,
  selectEntities: getDeviceEventsByDeviceLogsEntities,
  selectAll: getAllDeviceEventsByDeviceLogs,
  selectTotal: getDeviceEventsByDeviceTotalLogs
} = fromDeviceEventsByDeviceLogsDb.adapter.getSelectors(selectDeviceEventsByDeviceLogsDbState);

export const getDeviceEventsByDeviceLog = createSelector(selectDeviceEventsByDeviceLogsDbState, fromDeviceEventsByDeviceLogsDb.getLog);
export const getDeviceEventsByDeviceLogsLoading = createSelector(selectDeviceEventsByDeviceLogsUiState, fromDeviceEventsByDeviceLogsUi.getLogsLoading);
export const getDeviceEventsByDeviceLogsLoaded = createSelector(selectDeviceEventsByDeviceLogsUiState, fromDeviceEventsByDeviceLogsUi.getLogsLoaded);

// DEVICE EVENTS BY SITE //

export const selectDeviceEventsBySiteLogsDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsBySiteLogsDb.deviceEventsBySiteLogsDbFeatureKey]
);
export const selectDeviceEventsBySiteLogsUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsBySiteLogsUi.deviceEventsBySiteLogsUiFeatureKey]
);
export const {
  selectIds: getDeviceEventsBySiteLogsIds,
  selectEntities: getDeviceEventsBySiteLogsEntities,
  selectAll: getAllDeviceEventsBySiteLogs,
  selectTotal: getDeviceEventsBySiteTotalLogs
} = fromDeviceEventsBySiteLogsDb.adapter.getSelectors(selectDeviceEventsBySiteLogsDbState);

export const getDeviceEventsBySiteLog = createSelector(selectDeviceEventsBySiteLogsDbState, fromDeviceEventsBySiteLogsDb.getLog);
export const getDeviceEventsBySiteLogsLoading = createSelector(selectDeviceEventsBySiteLogsUiState, fromDeviceEventsBySiteLogsUi.getLogsLoading);
export const getDeviceEventsBySiteLogsLoaded = createSelector(selectDeviceEventsBySiteLogsUiState, fromDeviceEventsBySiteLogsUi.getLogsLoaded);

/// ///////////////////////////////////////////////
//  **** MASTER VIEW ASSET EVENT BY ASSET ****  //
/// ///////////////////////////////////////////////

export const selectAssetEventsByAssetDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsByAssetDb.assetEventsByAssetDbFeatureKey]
);
export const selectAssetEventsByAssetUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsByAssetUi.assetEventsByAssetUiFeatureKey]
);

export const {
  selectIds: getAssetEventsByAssetIds,
  selectEntities: getAssetEventsByAssetEntities,
  selectAll: getAllAssetEventsByAsset,
  selectTotal: getTotalAssetEventsByAsset
} = fromAssetEventsByAssetDb.adapter.getSelectors(selectAssetEventsByAssetDbState);

export const getSelectedAssetEventIdByAsset = createSelector(selectAssetEventsByAssetDbState, fromAssetEventsByAssetDb.getSelectedId);
export const getSelectedAssetEventByAsset = createSelector(
  getAssetEventsByAssetEntities,
  getSelectedAssetEventIdByAsset,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const getAssetEventsByAssetCheckedIds = createSelector(selectAssetEventsByAssetDbState, fromAssetEventsByAssetDb.getCheckedIds);

export const getAssetEventsByAssetPreviousAssetId = createSelector(selectAssetEventsByAssetDbState, fromAssetEventsByAssetDb.getPreviousAssetId);

export const getAssetEventsByAssetTableState = createSelector(
  selectAssetEventsByAssetDbState,
  getAllAssetEventsByAsset,
  getSelectedAssetEventByAsset,
  getAssetEventsByAssetCheckedIds,
  (state, allEvents, selected, checkedIds) => ({ selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) })
);

export const getMvAssetEventsByAssetSettings = createSelector(selectAssetEventsByAssetDbState, fromAssetEventsByAssetDb.getMvSettings);

export const getAssetEventsByAssetPagination = createSelector(
  selectAssetEventsByAssetDbState,
  getMvAssetEventsByAssetSettings,
  (state: fromAssetEventsByAssetDb.State, settings) =>
    settings && settings['masterViewTable']['bluePrint'].pageSize
      ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
      : state.pagination
);

export const getAssetEventsByAssetAutoRefresh = createSelector(getMvAssetEventsByAssetSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false
);

export const getAssetEventsByAssetRefreshDelay = createSelector(getMvAssetEventsByAssetSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120
);

export const getFormattedAssetEventsByAsset = createSelector(getAllAssetEventsByAsset, getAssetEventsByAssetPagination, (data: any, pagination: any) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getAssetEventByAssetStatus = createSelector(selectAssetEventsByAssetDbState, fromAssetEventsByAssetDb.getStatus);

export const getAssetEventsByAssetLoaded = createSelector(selectAssetEventsByAssetUiState, fromAssetEventsByAssetUi.getAssetEventsLoaded);
export const getAssetEventsByAssetLoading = createSelector(selectAssetEventsByAssetUiState, fromAssetEventsByAssetUi.getAssetEventsLoading);

export const getTotalActiveAssetEventsByAsset = createSelector(selectAssetEventsByAssetDbState, fromAssetEventsByAssetDb.getTotalActiveEvents);
export const getTotalActiveAssetEventsByAssetLoaded = createSelector(selectAssetEventsByAssetUiState, fromAssetEventsByAssetUi.getTotalActiveEventsLoaded);

/// //////////////////////////////////////////////
//  **** MASTER VIEW ASSET EVENT BY SITE ****  //
/// //////////////////////////////////////////////

export const selectAssetEventsBySiteDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsBySiteDb.assetEventsBySiteDbFeatureKey]
);
export const selectAssetEventsBySiteUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromAssetEventsBySiteUi.assetEventsBySiteUiFeatureKey]
);

export const {
  selectIds: getAssetEventsBySiteIds,
  selectEntities: getAssetEventsBySiteEntities,
  selectAll: getAllAssetEventsBySite,
  selectTotal: getTotalAssetEventsBySite
} = fromAssetEventsBySiteDb.adapter.getSelectors(selectAssetEventsBySiteDbState);

export const getSelectedAssetEventIdBySite = createSelector(selectAssetEventsBySiteDbState, fromAssetEventsBySiteDb.getSelectedId);
export const getSelectedAssetEventBySite = createSelector(
  getAssetEventsBySiteEntities,
  getSelectedAssetEventIdBySite,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const getAssetEventsBySiteCheckedIds = createSelector(selectAssetEventsBySiteDbState, fromAssetEventsBySiteDb.getCheckedIds);

export const getAssetEventsBySitePreviousSiteId = createSelector(selectAssetEventsBySiteDbState, fromAssetEventsBySiteDb.getPreviousSiteId);

export const getAssetEventsBySiteTableState = createSelector(
  selectAssetEventsBySiteDbState,
  getAllAssetEventsBySite,
  getSelectedAssetEventBySite,
  getAssetEventsBySiteCheckedIds,
  (state, allEvents, selected, checkedIds) => ({ selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) })
);

export const getMvAssetEventsBySiteSettings = createSelector(selectAssetEventsBySiteDbState, fromAssetEventsBySiteDb.getMvSettings);

export const getAssetEventsBySitePagination = createSelector(
  selectAssetEventsBySiteDbState,
  getMvAssetEventsBySiteSettings,
  (state: fromAssetEventsBySiteDb.State, settings) =>
    settings && settings['masterViewTable']['bluePrint'].pageSize
      ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
      : state.pagination
);

export const getAssetEventsBySiteAutoRefresh = createSelector(getMvAssetEventsBySiteSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false
);

export const getAssetEventsBySiteRefreshDelay = createSelector(getMvAssetEventsBySiteSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120
);

export const getFormattedAssetEventsBySite = createSelector(getAllAssetEventsBySite, getAssetEventsBySitePagination, (data: any, pagination: any) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getAssetEventBySiteStatus = createSelector(selectAssetEventsBySiteDbState, fromAssetEventsBySiteDb.getStatus);

export const getAssetEventsBySiteLoaded = createSelector(selectAssetEventsBySiteUiState, fromAssetEventsBySiteUi.getAssetEventsLoaded);
export const getAssetEventsBySiteLoading = createSelector(selectAssetEventsBySiteUiState, fromAssetEventsBySiteUi.getAssetEventsLoading);

export const getTotalActiveAssetEventsBySite = createSelector(selectAssetEventsBySiteDbState, fromAssetEventsBySiteDb.getTotalActiveEvents);
export const getTotalActiveAssetEventsBySiteLoaded = createSelector(selectAssetEventsBySiteUiState, fromAssetEventsBySiteUi.getTotalActiveEventsLoaded);

/// //////////////////////////////////////////////
//  **** MASTER VIEW DEVICE EVENT BY DEVICE ****  //
/// //////////////////////////////////////////////

export const selectDeviceEventsByDeviceDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsByDeviceDb.deviceEventsByDeviceDbFeatureKey]
);
export const selectDeviceEventsByDeviceUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsByDeviceUi.deviceEventsByDeviceUiFeatureKey]
);

export const {
  selectIds: getDeviceEventsByDeviceIds,
  selectEntities: getDeviceEventsByDeviceEntities,
  selectAll: getAllDeviceEventsByDevice,
  selectTotal: getTotalDeviceEventsByDevice
} = fromDeviceEventsByDeviceDb.adapter.getSelectors(selectDeviceEventsByDeviceDbState);

export const getSelectedDeviceEventIdByDevice = createSelector(selectDeviceEventsByDeviceDbState, fromDeviceEventsByDeviceDb.getSelectedId);
export const getSelectedDeviceEventByDevice = createSelector(
  getDeviceEventsByDeviceEntities,
  getSelectedDeviceEventIdByDevice,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const getDeviceEventsByDeviceCheckedIds = createSelector(selectDeviceEventsByDeviceDbState, fromDeviceEventsByDeviceDb.getCheckedIds);

export const getDeviceEventsByDevicePreviousDeviceId = createSelector(selectDeviceEventsByDeviceDbState, fromDeviceEventsByDeviceDb.getPreviousDeviceId);

export const getDeviceEventsByDeviceTableState = createSelector(
  selectDeviceEventsByDeviceDbState,
  getAllDeviceEventsByDevice,
  getSelectedDeviceEventByDevice,
  getDeviceEventsByDeviceCheckedIds,
  (state, allEvents, selected, checkedIds) => ({ selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) })
);

export const getMvDeviceEventsByDeviceSettings = createSelector(selectDeviceEventsByDeviceDbState, fromDeviceEventsByDeviceDb.getMvSettings);

export const getDeviceEventsByDevicePagination = createSelector(
  selectDeviceEventsByDeviceDbState,
  getMvDeviceEventsByDeviceSettings,
  (state: fromDeviceEventsByDeviceDb.State, settings) =>
    settings && settings['masterViewTable']['bluePrint'].pageSize
      ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
      : state.pagination
);

export const getDeviceEventsByDeviceAutoRefresh = createSelector(getMvDeviceEventsByDeviceSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false
);

export const getDeviceEventsByDeviceRefreshDelay = createSelector(getMvDeviceEventsByDeviceSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120
);

export const getFormattedDeviceEventsByDevice = createSelector(getAllDeviceEventsByDevice, getDeviceEventsByDevicePagination, (data: any, pagination: any) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getDeviceEventByDeviceStatus = createSelector(selectDeviceEventsByDeviceDbState, fromDeviceEventsByDeviceDb.getStatus);

export const getDeviceEventsByDeviceLoaded = createSelector(selectDeviceEventsByDeviceUiState, fromDeviceEventsByDeviceUi.getDeviceEventsLoaded);
export const getDeviceEventsByDeviceLoading = createSelector(selectDeviceEventsByDeviceUiState, fromDeviceEventsByDeviceUi.getDeviceEventsLoading);

export const getTotalActiveDeviceEventsByDevice = createSelector(selectDeviceEventsByDeviceDbState, fromDeviceEventsByDeviceDb.getTotalActiveEvents);
export const getTotalActiveDeviceEventsByDeviceLoaded = createSelector(
  selectDeviceEventsByDeviceUiState,
  fromDeviceEventsByDeviceUi.getTotalActiveEventsLoaded
);

/// //////////////////////////////////////////////
//  **** MASTER VIEW DEVICE EVENT BY SITE ****  //
/// //////////////////////////////////////////////

export const selectDeviceEventsBySiteDbState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsBySiteDb.deviceEventsBySiteDbFeatureKey]
);
export const selectDeviceEventsBySiteUiState = createSelector(
  selectNavigationState,
  (state: NavigationState) => state[fromDeviceEventsBySiteUi.deviceEventsBySiteUiFeatureKey]
);

export const {
  selectIds: getDeviceEventsBySiteIds,
  selectEntities: getDeviceEventsBySiteEntities,
  selectAll: getAllDeviceEventsBySite,
  selectTotal: getTotalDeviceEventsBySite
} = fromDeviceEventsBySiteDb.adapter.getSelectors(selectDeviceEventsBySiteDbState);

export const getSelectedDeviceEventIdBySite = createSelector(selectDeviceEventsBySiteDbState, fromDeviceEventsBySiteDb.getSelectedId);
export const getSelectedDeviceEventBySite = createSelector(
  getDeviceEventsBySiteEntities,
  getSelectedDeviceEventIdBySite,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const getDeviceEventsBySiteCheckedIds = createSelector(selectDeviceEventsBySiteDbState, fromDeviceEventsBySiteDb.getCheckedIds);

export const getDeviceEventsBySitePreviousSiteId = createSelector(selectDeviceEventsBySiteDbState, fromDeviceEventsBySiteDb.getPreviousSiteId);

export const getDeviceEventsBySiteTableState = createSelector(
  selectDeviceEventsBySiteDbState,
  getAllDeviceEventsBySite,
  getSelectedDeviceEventBySite,
  getDeviceEventsBySiteCheckedIds,
  (state, allEvents, selected, checkedIds) => ({ selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) })
);

export const getMvDeviceEventsBySiteSettings = createSelector(selectDeviceEventsBySiteDbState, fromDeviceEventsBySiteDb.getMvSettings);

export const getDeviceEventsBySitePagination = createSelector(
  selectDeviceEventsBySiteDbState,
  getMvDeviceEventsBySiteSettings,
  (state: fromDeviceEventsBySiteDb.State, settings) =>
    settings && settings['masterViewTable']['bluePrint'].pageSize
      ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
      : state.pagination
);

export const getDeviceEventsBySiteAutoRefresh = createSelector(getMvDeviceEventsBySiteSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false
);

export const getDeviceEventsBySiteRefreshDelay = createSelector(getMvDeviceEventsBySiteSettings, (settings) =>
  settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120
);

export const getFormattedDeviceEventsBySite = createSelector(getAllDeviceEventsBySite, getDeviceEventsBySitePagination, (data: any, pagination: any) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getDeviceEventBySiteStatus = createSelector(selectDeviceEventsBySiteDbState, fromDeviceEventsBySiteDb.getStatus);

export const getDeviceEventsBySiteLoaded = createSelector(selectDeviceEventsBySiteUiState, fromDeviceEventsBySiteUi.getDeviceEventsLoaded);
export const getDeviceEventsBySiteLoading = createSelector(selectDeviceEventsBySiteUiState, fromDeviceEventsBySiteUi.getDeviceEventsLoading);

export const getTotalActiveDeviceEventsBySite = createSelector(selectDeviceEventsBySiteDbState, fromDeviceEventsBySiteDb.getTotalActiveEvents);
export const getTotalActiveDeviceEventsBySiteLoaded = createSelector(selectDeviceEventsBySiteUiState, fromDeviceEventsBySiteUi.getTotalActiveEventsLoaded);
