<h2 class="login-regular-title">{{ 'LOGIN.CHANGE_PASSWORD.TITLE' | translate }}</h2>

<form [formGroup]="form" fxLayout="column">
  <mat-form-field color="accent" fxFlex="100%">
    <mat-icon matIconPrefix>lock</mat-icon>
    <mat-label>{{ 'LOGIN.CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</mat-label>
    <input (keyup)="sendPasswordToRules(newPassword.value)" formControlName="newPassword" matInput type="password" />
    @if (newPassword.invalid) {
      <mat-error>{{ 'LOGIN.ERROR_MESSAGE.PASSWORD' | translate }}</mat-error>
    }
  </mat-form-field>

  <mat-form-field color="accent" fxFlex="100%">
    <mat-icon matIconPrefix>lock</mat-icon>
    <mat-label>{{ 'LOGIN.CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate }}</mat-label>
    <input formControlName="confirmNewPassword" matInput type="password" />
    @if (confirmNewPassword.invalid) {
      <mat-error>{{ 'LOGIN.ERROR_MESSAGE.CONFIRM_PASSWORD' | translate }}</mat-error>
    }
  </mat-form-field>

  <iot-platform-login-password-rules [(password)]="password" />

  <div [class.error]="hasError()" class="layout-login-error" fxLayout="column">
    <em>Password failed</em>
    <span>{{ hasError()?.message }}</span>
  </div>

  <div class="layout-login-form-actions">
    <button (click)="onCancel()" class="layout-login-button" mat-button
            type="button">{{ 'LOGIN.BUTTON.CANCEL' | translate }}
    </button>
    <iot-platform-ui-progress-bar-button
      (clickEvent)="onChangeTemporaryPassword()"
      [disabled]="!form.valid"
      [enableGauge]="enableGauge()"
      [title]="'LOGIN.BUTTON.CONFIRM' | translate"
      type="submit"
    />
  </div>
</form>
