import { BaseUser } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const addMemberToBusinessProfile = createAction(
  '[Admin Business Profile/Members Dialog] Add Member To Business Profile',
  props<{ businessProfileId: string; memberToAdd: BaseUser }>()
);
export const removeMemberFromBusinessProfile = createAction(
  '[Admin Business Profile/Members Dialog] Remove Member From Business Profile',
  props<{ businessProfileId: string; memberToRemove: BaseUser }>()
);
