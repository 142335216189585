import { PlatformResponse } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createAction, props } from '@ngrx/store';

export const listGridsByBusinessProfileSuccess = createAction(
  '[Admin Business Profile/API] List Grids by Business Profile Success',
  props<{ response: PlatformResponse }>()
);
export const listGridsByBusinessProfileFailure = createAction('[Admin Business Profile/API] List Grids by Business Profile Failure', props<{ error: any }>());

export const addGridSuccess = createAction('[Admin Business Profile/Grids API] Add Grid Success', props<{ grid: I4BGrid<I4BGridOptions, I4BGridData> }>());
export const addGridFailure = createAction('[Admin Business Profile/Grids API] Add Grid Failure', props<{ error: any }>());
//
export const updateGridSuccess = createAction(
  '[Admin Business Profile/Grids API] Update Grid Success',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData> }>()
);
export const updateGridFailure = createAction('[Admin Business Profile/Grids API] Update Grid Failure', props<{ error: any }>());
//
export const deleteGridSuccess = createAction(
  '[Admin Business Profile/Grids API] Delete Grid Success',
  props<{ removed: I4BGrid<I4BGridOptions, I4BGridData> }>()
);
export const deleteGridFailure = createAction('[Admin Business Profile/Grids API] Delete Grid Failure', props<{ error: any }>());
