import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngxInit]'
})
export class NgxInitDirective {
  constructor(private templateRef: TemplateRef<any>, public viewContainer: ViewContainerRef) {}

  @Input() set ngxInit(val: any) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, { ngxInit: val });
  }
}
