import { ProcessCellForExportParams } from '@ag-grid-community/core';

export class BooleanCellHelpers {
  static getData(value, mode: string, dataToDisplayIfTrue, dataToDisplayIfFalse, comparisonKey: string): string | null {
    if (mode === 'booleanAsText') {
      return value ? dataToDisplayIfTrue : dataToDisplayIfFalse;
    } else if (mode === 'dataComparison') {
      return value === comparisonKey ? dataToDisplayIfTrue : dataToDisplayIfFalse;
    }
    return '';
  }

  static processValue(params: ProcessCellForExportParams): string {
    const { value, column } = params;
    const {
      cellOptions: { dataToDisplayIfFalse, dataToDisplayIfTrue, mode, translateKey, applyLowerCase, comparisonKey }
    } = column.getColDef().cellRendererParams;
    const result: string | null = BooleanCellHelpers.getData(value, mode, dataToDisplayIfTrue, dataToDisplayIfFalse, comparisonKey);
    return `${translateKey}${applyLowerCase ? result.toLowerCase() : result}`;
  }
}
