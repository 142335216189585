import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { BaseState, FavoriteView, Filter, INITIAL_BASE_STATE, Pagination, TagCategory } from '@iot-platform/models/common';
import { Asset, Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { NavigationActions } from '../../../../containers/+state/actions';
import { AssetsActions } from '../actions';

export interface State extends BaseState<Asset, Pagination, Filter> {
  tags: TagCategory[];
  tagsLoaded: boolean;
  tagsLoading: boolean;
  currentFavoriteView: FavoriteView | null;
  site: Site | null;
  siteLoading: boolean;
  siteLoaded: boolean;
  assetCreationByTemplateStatuses: {
    pending: boolean;
    completed: boolean;
  };
}

export const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  tags: [],
  tagsLoaded: false,
  tagsLoading: false,
  currentFavoriteView: null,
  site: null,
  siteLoading: false,
  siteLoaded: false,
  assetCreationByTemplateStatuses: {
    pending: false,
    completed: false
  }
});

export const assetsFeature = createFeature({
  name: 'assets',
  reducer: createReducer(
    initialState,
    // ********** //
    //    DATA    //
    // ********** //
    on(AssetsActions.selectOneSuccess, (state: State, { selected }) => ({ ...state, selectedId: selected.id as string })),
    on(AssetsActions.getOne, (state: State, { id }) => ({ ...state, selectedId: id })),
    on(AssetsActions.getOneSuccess, (state: State, { element }) => adapter.setOne(element, state)),
    on(AssetsActions.getMany, (state: State, { request }) => ({
      ...state,
      selectedId: null,
      pagination: { ...state.pagination, currentPage: request.page }
    })),
    on(AssetsActions.getManySuccess, (state: State, { response }) =>
      adapter.setAll(response.data, {
        ...state,
        pagination: { currentPage: response.currentPage, hasMore: response.hasMore, limit: response.limit, total: response.total, maxPage: response.maxPage }
      })
    ),
    on(AssetsActions.addOneSuccess, AssetsActions.addOneByTemplateIdSuccess, (state: State, { added }) =>
      adapter.addOne(added, { ...state, selectedId: added.id as string })
    ),
    on(AssetsActions.updateOneSuccess, (state: State, { updated }) => adapter.updateOne({ changes: updated, id: updated.id as string }, state)),
    on(AssetsActions.deleteOneSuccess, (state: State, { deleted }) => adapter.removeOne(deleted.id as string, state)),
    on(NavigationActions.loadData, (state: State) => ({ ...state, selectedId: null })),
    on(AssetsActions.getSiteByIdSuccess, (state: State, { site }) => ({ ...state, site })),
    on(AssetsActions.getTagsSuccess, AssetsActions.updateTagsByAssetIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
    on(AssetsActions.saveCurrentFavoriteView, (state: State, { currentFavoriteView }) => ({
      ...state,
      currentFavoriteView,
      filters: currentFavoriteView?.filters ?? []
    })),

    // ********** //
    //     UI     //
    // ********** //
    on(AssetsActions.getMany, AssetsActions.addOne, AssetsActions.getOne, AssetsActions.updateOne, AssetsActions.deleteOne, (state: State) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AssetsActions.getManySuccess, AssetsActions.getOneSuccess, AssetsActions.updateOneSuccess, AssetsActions.deleteOneSuccess, (state: State) => ({
      ...state,
      loading: false,
      loaded: true
    })),
    on(
      AssetsActions.getManyFailure,
      AssetsActions.getOneFailure,
      AssetsActions.updateOneFailure,
      AssetsActions.deleteOneFailure,
      (state: State, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    ),
    on(AssetsActions.getTags, AssetsActions.updateTagsByAssetId, (state: State) => ({
      ...state,
      tagsLoading: true,
      tagsLoaded: false
    })),
    on(AssetsActions.getTagsSuccess, AssetsActions.updateTagsByAssetIdSuccess, (state: State) => ({
      ...state,
      tagsLoading: false,
      tagsLoaded: true
    })),
    on(AssetsActions.getTagsFailure, AssetsActions.updateTagsByAssetIdFailure, (state: State, { error }) => ({
      ...state,
      tagsLoading: false,
      tagsLoaded: false,
      error
    })),
    on(AssetsActions.getMany, (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      siteLoading: false,
      siteLoaded: false,
      tagsLoading: false,
      tagsLoaded: false
    })),
    on(AssetsActions.getSiteById, (state: State) => ({ ...state, siteLoading: true, siteLoaded: false })),
    on(AssetsActions.getSiteByIdSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
    on(AssetsActions.getSiteByIdFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: false, error })),
    on(AssetsActions.addOneByTemplateId, (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      assetCreationByTemplateStatuses: {
        pending: true,
        completed: false
      }
    })),
    on(AssetsActions.addOneSuccess, AssetsActions.addOneByTemplateIdSuccess, (state: State) => ({
      ...state,
      loading: false,
      loaded: true,
      assetCreationByTemplateStatuses: {
        pending: false,
        completed: true
      }
    })),
    on(AssetsActions.addOneFailure, AssetsActions.addOneByTemplateIdFailure, (state: State, { error }) => ({
      ...state,
      loading: false,
      loaded: false,
      assetCreationByTemplateStatuses: {
        pending: false,
        completed: false
      },
      error
    })),
    on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
  )
});
