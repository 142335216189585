import { PlatformRequest } from '@iot-platform/models/common';

import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadDeviceEventsBySite = createAction('[DeviceEventsBySite] Load Device Events By Site', props<{ request: PlatformRequest }>());
//
export const loadMvDeviceEventsBySiteSettings = createAction('[DeviceEventsBySite] Load MV DeviceEventsBySite Settings', props<{ settingName: string }>());
//
export const loadTotalActiveDeviceEventsBySite = createAction(
  '[DeviceEventsBySite] Load Total Active Device Events By Site',
  props<{ request: PlatformRequest }>()
);
//
export const saveTableStateBySite = createAction(
  '[DeviceEventsBySite] Save Table State',
  props<{ tableState: { checked: DeviceEvent[]; selected: DeviceEvent } }>()
);
//
export const updateStatusByDeviceEventIdBySite = createAction(
  '[DeviceEventsBySite] Update Status By Device Event Id',
  props<{ status: { deviceEventId: string; value: string } }>()
);
//
export const bulkUpdateStatusByDeviceEventIdBySite = createAction(
  '[DeviceEventsBySite] Bulk Update Status By Device Event Id',
  props<{ deviceEventIds: string[]; status: string }>()
);
