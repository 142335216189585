<div class="full-width" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px">
  <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <iot4bos-ui-association-asset-card
      (clicked)="openSelectedItem.emit({ action: 'open-asset', data: mainItem })"
      [asset]="mainItem"
      [navigationMode]="navigationMode"
      [selectedItemId]="selectedItemId"
    ></iot4bos-ui-association-asset-card>
    @if (!mainItem) {
      <div class="no-association-message">{{ 'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate }}</div>
    }
  </section>
  <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    @for (associatedItem of associatedItems; track associatedItem) {
      <iot4bos-ui-association-device-card
        [device]="associatedItem"
        [navigationMode]="navigationMode"
        [selectedItemId]="selectedItemId"
        (clicked)="openSelectedItem.emit({ action: 'open-device', data: associatedItem })"
      ></iot4bos-ui-association-device-card>
    } @empty {
      <div class="no-association-message">{{ 'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate }}</div>
    }
  </section>
</div>
