export const deviceCallLogsMetadataSettings = {
  metadata: {
    url: '',
    params: {
      limit: '300',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'device-call-logs',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 300,
      refreshDelay: 120,
      multipleSelection: false,
      renderer: 'grid',
      displayOption: 'boxed',
      enableConditions: [],
      visibleConditions: [],
      buttonColumn: {
        id: 'button',
        name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.BUTTON',
        width: '5%',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [],
        singleActions: [
          {
            order: 1,
            key: 'downloadMessage',
            label: 'DEVICES.CALL_LOG.TABLE_CONTENT.BUTTONS.DOWNLOAD_MESSAGE',
            enableConditions: [],
            visibleConditions: []
          }
        ]
      },
      columns: [],
      expandedPanel: {}
    }
  },
  masterViewComments: {}
};
