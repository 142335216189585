import { NgModule } from '@angular/core';
import { SharedModule } from '@iot-platform/shared';
import { UsersListComponent } from './users-list.component';

@NgModule({
  declarations: [UsersListComponent],
  exports: [UsersListComponent],
  imports: [SharedModule]
})
export class UsersListModule {}
