import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { Log } from '@iot-platform/models/i4b';
import { DeviceEventsCommentsActions } from '../actions';

export interface State extends EntityState<Log> {
  selectedId: string | null;
  loading: boolean;
  loaded: boolean;
  error: unknown;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (entity: Log) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false,
  error: null
});

export const deviceEventsCommentsFeature = createFeature({
  name: 'deviceEventsComments',
  reducer: createReducer(
    initialState,
    on(
      DeviceEventsCommentsActions.loadComments,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      DeviceEventsCommentsActions.loadCommentsSuccess,
      (state: State, { comments }): State => adapter.setAll(comments, { ...state, loading: false, loaded: true })
    ),
    on(
      DeviceEventsCommentsActions.loadCommentsFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    ),
    //
    on(DeviceEventsCommentsActions.addComment, (state: State): State => ({ ...state, selectedId: null })),
    on(DeviceEventsCommentsActions.addCommentSuccess, (state: State, { comment }): State => adapter.addOne(comment, { ...state, selectedId: comment.id })),
    on(DeviceEventsCommentsActions.addCommentFailure, (state: State, { error }): State => ({ ...state, selectedId: null, error })),
    //
    on(DeviceEventsCommentsActions.editComment, (state: State): State => ({ ...state, selectedId: null })),
    on(
      DeviceEventsCommentsActions.editCommentSuccess,
      (state: State, { comment }): State => adapter.updateOne({ id: comment.id, changes: comment }, { ...state, selectedId: comment.id })
    ),
    on(DeviceEventsCommentsActions.editCommentFailure, (state: State, { error }): State => ({ ...state, selectedId: null, error })),
    //
    on(DeviceEventsCommentsActions.deleteComment, (state: State): State => ({ ...state, selectedId: null })),
    on(DeviceEventsCommentsActions.deleteCommentSuccess, (state: State, { commentId }): State => adapter.removeOne(commentId, { ...state, selectedId: null })),
    on(DeviceEventsCommentsActions.deleteCommentFailure, (state: State, { error }): State => ({ ...state, selectedId: null, error }))
  )
});
