import { Entity } from '@iot-platform/models/common';

export class GeneralHelpers {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static calculateIsEditable(object: any, sessionEntity: Entity): boolean {
    return (
      (object?.entity && sessionEntity && object.entity.id === sessionEntity.id) ||
      !!object?.entityPath?.find((entity: Entity) => entity.id === sessionEntity?.id)
    );
  }
}
