<div fxFlex fxLayout="column" class="ack-container">
  <div class="ack-header p-20" fxLayout="row" fxLayoutAlign="space-between center">
    <p class="ack-header-title fw-600">IoT4BOS</p>
    <img src="assets/images/logo-AL.png" alt="Air Liquide logo" style="width: 160px; height: 30px" />
  </div>

  @if(error()) {
    <div class="ack-empty-body p-20" fxFlex fxLayoutAlign="center start">
      <div class="ack-empty-label" fxLayoutAlign="center center">NO ACTION AVAILABLE ON THIS EVENT</div>
    </div>
  }
  @else if (event()) {
    <div class="p-20" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="ack-label" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="4px">
          <span>Event id: </span>
          <span class="fw-600">{{ event()?.id }} </span>
        </div>
        <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="4px">
          <div fxLayoutGap="4px">
            <span>Occurrence date: </span>
            <span class="fw-600"> {{ event()?.occurrenceTime | date: 'yyyy-MM-dd HH:mm:ss' }} </span>
          </div>

          <div fxLayoutGap="4px">
            <span>Reception date: </span>
            <span class="fw-600"> {{ event()?.receptionTime | date: 'yyyy-MM-dd HH:mm:ss' }} </span>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="center center">
        <button mat-raised-button (click)="acknowledge()" color="primary" [disabled]="!canAck()">CLICK TO ACKNOWLEDGE</button>
      </div>
    </div>
  }
</div>
