import { Filter } from '@iot-platform/models/common';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const AssetTemplatesActions = createActionGroup({
  source: 'Asset Templates',
  events: {
    'Add Asset Template': props<{ assetTemplate: AssetTemplate }>(),
    'Add Asset Template Success': props<{ assetTemplate: AssetTemplate }>(),
    'Add Asset Template Failure': props<{ error: unknown }>(),
    'Update Asset Template': props<{ assetTemplate: AssetTemplate }>(),
    'Update Asset Template Success': props<{ assetTemplate: AssetTemplate }>(),
    'Update Asset Template Failure': props<{ error: unknown }>(),
    'Update Template': props<{ assetTemplate: AssetTemplate }>(),
    'Update Template Success': props<{ assetTemplate: AssetTemplate }>(),
    'Update Template Failure': props<{ error: unknown }>(),
    'Update Asset Template Tags': props<{ assetTemplate: AssetTemplate }>(),
    'Update Asset Template Tags Success': props<{ assetTemplate: AssetTemplate }>(),
    'Update Asset Template Tags Failure': props<{ error: unknown }>(),
    'Configure Asset Template Products': props<{ assetTemplate: AssetTemplate }>(),
    'Configure Asset Template Products Success': props<{ assetTemplate: AssetTemplate }>(),
    'Configure Asset Template Products Failure': props<{ error: unknown }>(),
    'Remove Asset Template Products': props<{ assetTemplate: AssetTemplate }>(),
    'Remove Asset Template Products Success': props<{ assetTemplate: AssetTemplate }>(),
    'Remove Asset Template Products Failure': props<{ error: unknown }>(),
    'Configure Asset Template Optional Properties': props<{ assetTemplate: AssetTemplate }>(),
    'Configure Asset Template Optional Properties Success': props<{ assetTemplate: AssetTemplate }>(),
    'Configure Asset Template Optional Properties Failure': props<{ error: unknown }>(),
    'Add Asset Template Followed Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Add Asset Template Followed Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Add Asset Template Followed Variable Failure': props<{ error: unknown }>(),
    'Remove Asset Template Followed Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Remove Asset Template Followed Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Remove Asset Template Followed Variable Failure': props<{ error: unknown }>(),
    'Delete Asset Template': props<{ assetTemplate: AssetTemplate }>(),
    'Delete Asset Template Success': props<{ assetTemplate: AssetTemplate }>(),
    'Delete Asset Template Failure': props<{ error: unknown }>(),
    'Get Asset Template By Id': props<{ assetTemplateId: string }>(),
    'Get Asset Template By Id Success': props<{ assetTemplate: AssetTemplate }>(),
    'Get Asset Template By Id Failure': props<{ error: unknown }>(),
    'Create Asset Template Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Create Asset Template Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Create Asset Template Variable Failure': props<{ error: unknown }>(),
    'Update Asset Template Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Update Asset Template Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Update Asset Template Variable Failure': props<{ error: unknown }>(),
    'Configure Asset Template Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Configure Asset Template Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Configure Asset Template Variable Failure': props<{ error: unknown }>(),
    'Delete Asset Template Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Delete Asset Template Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Delete Asset Template Variable Failure': props<{ error: unknown }>(),
    'Bulk Delete Asset Template Variable': props<{ assetTemplate: AssetTemplate }>(),
    'Bulk Delete Asset Template Variable Success': props<{ assetTemplate: AssetTemplate }>(),
    'Bulk Delete Asset Template Variable Failure': props<{ error: unknown }>(),
    'Navigate To Asset Template Details': props<{ assetTemplate: AssetTemplate }>(),
    'Set Filters': props<{ filters: Filter[] }>()
  }
});
