import { Injectable, Signal, computed } from '@angular/core';
import { AuthorizationConcept, AuthorizationType } from '@iot-platform/auth';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { CommonApiRequest, FavoriteView, Filter, Pagination, PlatformRequest, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Log, Site } from '@iot-platform/models/i4b';

import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { I4BBaseFacade } from '../../../../models/ngrx/i4b-base-facade.model';
import { SitesCommentsActions, SitesDbActions } from '../actions';
import * as fromSites from '../reducers';
import { SitesSelectors } from '../selectors/sites.selectors';

@Injectable({
  providedIn: 'root'
})
export class SitesFacade extends I4BBaseFacade<Site, Pagination, Filter> {
  concept = 'sites';
  canCreate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.CREATE);
  });
  canRead = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
  });
  canUpdate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.UPDATE);
  });
  canDelete = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.DELETE);
  });

  grids$: Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.select(fromGrids.getSitesGrids);
  grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.selectSignal(fromGrids.getSitesGrids);
  grid$: Observable<I4BGrid<I4BGridOptions, I4BGridData> | undefined> = this.store.select(fromGrids.getDefaultSitesGrid);
  grid: Signal<I4BGrid<I4BGridOptions, I4BGridData> | undefined> = this.store.selectSignal(fromGrids.getDefaultSitesGrid);
  gridConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> = this.store.select(fromGrids.selectSiteGridsConfiguration);
  gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> = this.store.selectSignal(fromGrids.selectSiteGridsConfiguration);
  favoriteViews$: Observable<FavoriteView[]> = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewSites);
  favoriteViews: Signal<FavoriteView[]> = this.store.selectSignal(fromFavoriteViews.getFavoriteViewsForMasterViewSites);
  currentFavoriteView$: Observable<FavoriteView> = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewSites);
  currentFavoriteView: Signal<FavoriteView> = this.store.selectSignal(fromFavoriteViews.getSelectedFavoriteViewForMasterViewSites);
  favoriteViewConfiguration$: Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> = this.store.select(fromFavoriteViews.selectSiteFavoriteViewsConfiguration);
  favoriteViewConfiguration: Signal<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> = this.store.selectSignal(fromFavoriteViews.selectSiteFavoriteViewsConfiguration);
  // Specific
  filters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewSites);
  filters = this.store.selectSignal(fromFavoriteViews.getFiltersForMasterViewSites);
  assetsBySite$ = this.store.select(this.selector.selectAssetsBySite);
  devicesBySite$ = this.store.select(this.selector.selectDevicesBySite);
  assetLoaded$ = this.store.select(this.selector.selectAssetLoaded);
  deviceLoaded$ = this.store.select(this.selector.selectDeviceLoaded);
  tags$ = this.store.select(this.selector.selectTagsBySite);
  tagsLoaded$ = this.store.select(this.selector.selectTagsLoaded);
  siteComments: Signal<Log[]> = this.store.selectSignal(fromSites.selectAllSiteComments);
  siteCommentsLoading: Signal<boolean> = this.store.selectSignal(fromSites.selectSiteCommentsLoading);

  constructor(
    protected store: Store,
    protected selector: SitesSelectors
  ) {
    super(store, selector);
  }

  siteLoaded$ = (gridId: string) => this.store.select(fromGrids.getDataLoadedByGrid(gridId));

  addSite(site: Site) {
    this.store.dispatch(SitesDbActions.addSite({ siteToAdd: site }));
  }

  loadSites(request: CommonApiRequest) {
    this.store.dispatch(GridsDbActions.loadGridData({ request: { concept: 'sites', ...request } }));
  }

  loadAssetsBySiteId(request: PlatformRequest) {
    this.store.dispatch(SitesDbActions.loadAssetsBySiteId({ request }));
  }

  loadDevicesBySiteId(request: PlatformRequest) {
    this.store.dispatch(SitesDbActions.loadDevicesBySiteId({ request }));
  }

  loadAssociationsBySiteId(siteId: string) {
    this.store.dispatch(SitesDbActions.loadAssociationsBySiteId({ siteId }));
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(SitesDbActions.loadSiteById({ siteId }));
  }

  loadTagsBySiteId(siteId: string) {
    this.store.dispatch(SitesDbActions.loadTagsBySiteId({ siteId }));
  }

  selectSite(selectedSite: Site) {
    this.store.dispatch(SitesDbActions.selectSite({ selectedSite }));
  }

  updateSite(site: Site) {
    this.store.dispatch(SitesDbActions.updateSite({ siteToUpdate: site }));
  }

  updateTagsBySiteId(siteId: string, tags: TagCategory[]) {
    this.store.dispatch(SitesDbActions.updateTagsBySiteId({ siteId, tags }));
  }

  deleteSite(site: Site) {
    this.store.dispatch(SitesDbActions.deleteSite({ siteToDelete: site }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'sites', filters }));
  }

  loadComments(siteId: string): void {
    this.store.dispatch(SitesCommentsActions.loadComments({ siteId }));
  }

  addComment(siteId: string, comment: string): void {
    this.store.dispatch(SitesCommentsActions.addComment({ siteId, comment }));
  }

  editComment(siteId: string, comment: Log): void {
    this.store.dispatch(SitesCommentsActions.editComment({ siteId, comment }));
  }

  deleteComment(siteId: string, commentId: string): void {
    this.store.dispatch(SitesCommentsActions.deleteComment({ siteId, commentId }));
  }

  getAll(): void {
    noop();
  }
}
