<div fxLayout="column">
  <iot-platform-ui-detail-popup-section-header headerTitle="{{ 'IOT_DICTIONARY.routes' | translate }}"></iot-platform-ui-detail-popup-section-header>
  <div fxLayout="row" style="width: 100%">
    <div fxFlex fxLayout="column">
      <mat-form-field fxFlex>
        <mat-label>Days to display</mat-label>
        <select (change)="onLoadRoutes()" [(ngModel)]="selectedHours" matNativeControl required>
          @for (hour of hours; track hour) {
            <option [value]="hour.value">{{ hour.viewValue }}</option>
          }
        </select>
      </mat-form-field>
    </div>
  </div>
</div>
