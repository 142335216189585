import { EnvironmentProviders, makeEnvironmentProviders, signal, Type } from '@angular/core';
import { noop } from 'rxjs';
import { AbstractTagEditorService } from './abstract-tag-editor.service';
import { TagEditorFormService } from '../tag-editor-form/tag-editor-form.service';
export { AbstractTagEditorService };

export function provideTagEditorSettings(settings: { tagEditorService: Type<AbstractTagEditorService> }): EnvironmentProviders {
  return makeEnvironmentProviders([TagEditorFormService, { provide: AbstractTagEditorService, useClass: settings.tagEditorService }]);
}

export function provideTagEditorTestingSettings(): EnvironmentProviders {
  return makeEnvironmentProviders([
    TagEditorFormService,
    {
      provide: AbstractTagEditorService,
      useValue: {
        getCategories: () => signal([]),
        listTagsByOrganizationForEdition: () => noop(),
        addTagToOrganization: () => noop(),
        updateTagInOrganization: () => noop(),
        removeTagFromOrganization: () => noop()
      }
    }
  ]);
}
