import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-association-asset-card',
  templateUrl: './association-asset-card.component.html',
  styleUrls: ['../../association-card.component.scss']
})
export class AssociationAssetCardComponent {
  @Input() asset: Asset;
  @Input() navigationMode: boolean;
  @Input() selectedItemId: string;

  @Output() clicked: EventEmitter<Asset> = new EventEmitter();

  get imgPath(): string {
    return this.asset ? (this.asset.imageUrl ? this.asset.imageUrl : 'assets/images/asset/asset_default.png') : 'assets/images/asset/asset_default.png';
  }

  onClick() {
    this.clicked.emit(this.asset);
  }
}
