import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { EscalationProtocolLevel } from '@iot-platform/models/ocm';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { EscalationProtocolsDbActions, EscalationProtocolsUiActions } from '../actions';

export const escalationProtocolLevelsDbFeatureKey = 'escalationProtocolLevelsDb';

export interface State extends EntityState<EscalationProtocolLevel> {
  selectedEscalationProtocolLevelId: string;
  error: any;
}

export const adapter: EntityAdapter<EscalationProtocolLevel> = createEntityAdapter<EscalationProtocolLevel>({
  selectId: (level: EscalationProtocolLevel) => level.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedEscalationProtocolLevelId: null,
  error: null
});

export const escalationProtocolLevelsDbReducer = createReducer(
  initialState,
  on(EscalationProtocolsUiActions.listEscalationProtocolLevels, (_) => initialState),
  on(EscalationProtocolsDbActions.listEscalationProtocolLevelsSuccess, (state: State, { levels }) => adapter.setAll(levels, state)),
  //
  on(EscalationProtocolsDbActions.addEscalationProtocolLevelSuccess, (state: State, { addedLevel }) =>
    adapter.addOne(addedLevel, { ...state, selectedEscalationProtocolId: addedLevel.id })
  ),
  //
  on(EscalationProtocolsDbActions.updateEscalationProtocolLevelSuccess, (state: State, { updatedLevel }) =>
    adapter.upsertOne(updatedLevel, { ...state, selectedEscalationProtocolId: updatedLevel.id })
  ),
  //
  on(EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersSuccess, (state: State, { updatedLevels }) => adapter.upsertMany(updatedLevels, state)),
  //
  on(EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsSuccess, (state: State, { deletedLevel, updatedLevels }) =>
    adapter.upsertMany(updatedLevels, adapter.removeOne(deletedLevel.id, { ...state, selectedEscalationProtocolId: null }))
  ),
  //
  on(
    EscalationProtocolsDbActions.listEscalationProtocolLevelsFailure,
    EscalationProtocolsDbActions.addEscalationProtocolLevelFailure,
    EscalationProtocolsDbActions.updateEscalationProtocolLevelFailure,
    EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersFailure,
    EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsFailure,
    (state: State, { error }) => ({ ...state, error })
  ),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return escalationProtocolLevelsDbReducer(state, action);
}

export const getSelectedEscalationProtocolLevelId = (state: State) => state.selectedEscalationProtocolLevelId;
export const getError = (state: State) => state.error;
