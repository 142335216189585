import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgClass } from '@angular/common';
import { Component, effect, inject, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'grid-engine-linked-cell',
  templateUrl: './linked-cell.component.html',
  imports: [MatTooltip, MatIcon, NgClass],
  styleUrls: ['./linked-cell.component.scss']
})
export class LinkedCellComponent implements ICellRendererAngularComp {
  private translateService: TranslateService = inject(TranslateService);
  params: WritableSignal<ICellRendererParams> = signal(null);

  tooltip: WritableSignal<string> = signal('');
  value: WritableSignal<any> = signal(null);
  isIcon: WritableSignal<boolean> = signal(true);

  constructor() {
    effect(
      () => {
        const params = this.params();

        this.tooltip.set(this.translateService.instant(`ASSETS.VARIABLE.TOOLTIPS.${params?.value ? 'LINKED' : 'NOT_LINKED'}`));

        // Ag grid filter renderer transform boolean values to string
        if (`${params.value}` === 'true' || `${params.value}` === 'false') {
          this.isIcon.set(true);
          this.value.set(`${params.value}` === 'true');
        } else if (params.value === this.translateService.instant('AG_GRID.selectAll')) {
          this.isIcon.set(false);
          this.tooltip.set(null);
          this.value.set(params.value);
        } else {
          this.isIcon.set(false);
          this.tooltip.set(null);
          this.value.set((params as any).isFilterRenderer ? this.translateService.instant('AG_GRID.blanks') : params.value);
        }
      },
      { allowSignalWrites: true }
    );
  }

  agInit(params: ICellRendererParams & { isFilterRenderer?: boolean }): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
