import { Signal } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';

export abstract class AbstractTagEditorService {
  abstract getCategories(): Signal<TagCategory[]>;

  abstract getListTagsByOrganizationForEdition(organizationId: string, withParents: boolean, concept: string): void;

  abstract addTagToOrganization(tagCategory: TagCategory): void;

  abstract updateTagInOrganization(tagCategory: TagCategory): void;

  abstract removeTagFromOrganization(tagCategory: TagCategory): void;
}
