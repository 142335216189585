import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsByTopicDbActions, DeviceEventsByTopicUiActions } from '../../actions';
//
export const deviceEventsByTopicUiFeatureKey = 'deviceEventsByTopicUi';

export interface State {
  error?: any;
  deviceEventsLoaded: boolean;
  deviceEventsLoading: boolean;
  totalActiveEventsLoaded: boolean;
}

export const initialState: State = {
  error: null,
  deviceEventsLoaded: false,
  deviceEventsLoading: false,
  totalActiveEventsLoaded: false
};

const deviceEventsByTopicUiReducer = createReducer(
  initialState,
  on(DeviceEventsByTopicUiActions.loadDeviceEventsByTopic, (state: State) => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsByTopicDbActions.loadDeviceEventsByTopicSuccess, (state: State) => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true })),
  on(DeviceEventsByTopicDbActions.loadDeviceEventsByTopicFailure, (state: State, { error }) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: false,
    error
  })),
  // ****
  on(DeviceEventsByTopicUiActions.loadTotalActiveDeviceEventsByTopic, (state: State) => ({ ...state, totalActiveEventsLoaded: false })),
  on(DeviceEventsByTopicDbActions.loadTotalActiveDeviceEventsByTopicSuccess, (state: State) => ({ ...state, totalActiveEventsLoaded: true })),
  on(DeviceEventsByTopicDbActions.loadTotalActiveDeviceEventsByTopicFailure, (state: State, { error }) => ({
    ...state,
    totalActiveEventsLoaded: false,
    error
  })),
  // ****
  on(DeviceEventsByTopicUiActions.bulkUpdateStatusByDeviceEventIdByTopic, (state: State) => ({ ...state, deviceEventsLoaded: false })),
  on(DeviceEventsByTopicDbActions.updateStatusByDeviceEventIdByTopicSuccess, (state: State) => ({ ...state, deviceEventsLoaded: true })),
  on(DeviceEventsByTopicDbActions.updateStatusByDeviceEventIdByTopicFailure, (state: State, { error }) => ({ ...state, deviceEventsLoaded: false, error })),
  // ****
  on(DeviceEventsByTopicUiActions.loadMvDeviceEventsByTopicSettings, (state: State) => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsByTopicDbActions.loadMvDeviceEventsByTopicSettingsSuccess, (state: State) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: true
  })),
  on(DeviceEventsByTopicDbActions.loadMvDeviceEventsByTopicSettingsFailure, (state: State, { error }) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: false,
    error
  })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByTopicUiReducer(state, action);
}

export const getDeviceEventsLoaded = (state: State) => state.deviceEventsLoaded;
export const getDeviceEventsLoading = (state: State) => state.deviceEventsLoading;
export const getTotalActiveEventsLoaded = (state: State) => state.totalActiveEventsLoaded;
