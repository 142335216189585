import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import * as fromDevices from '../reducers/devices.reducer';

@Injectable({
  providedIn: 'root'
})
export class DevicesSelectors extends ApiFeatureSelector<fromDevices.State, Device, Pagination, Filter> {
  selectDeviceSite = fromDevices.devicesFeature.selectSite;
  selectDeviceSiteLoading = fromDevices.devicesFeature.selectSiteLoading;
  selectDeviceTags = fromDevices.devicesFeature.selectTags;
  selectDeviceTagsLoading = fromDevices.devicesFeature.selectTagsLoading;
  selectDeviceCallLogs = fromDevices.devicesFeature.selectCallLogs;

  constructor() {
    super(fromDevices.devicesFeature.selectDevicesState, fromDevices.adapter);
  }
}
