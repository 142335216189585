import { NgComponentOutlet, NgTemplateOutlet } from '@angular/common';
import { Component, effect, ElementRef, input, output, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChatBody, ChatEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { CardLoaderModule } from '../../card-loader/card-loader.module';
import { ChatMessageComponent } from '../chat-message/chat-message.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ChatMessageComponent, CardLoaderModule, NgComponentOutlet, NgTemplateOutlet],
  selector: 'iot-platform-ui-chat-body',
  templateUrl: './chat-body.component.html',
  styleUrls: ['./chat-body.component.scss']
})
export class ChatBodyComponent {
  body = input.required<ChatBody>();
  dispatchAction = output<ChatEvent>();

  @ViewChild('chatBody') chatBody!: ElementRef;

  constructor() {
    effect(() => {
      const body = this.body();
      if (body) {
        const timeout = setTimeout(() => {
          this.scrollHandler();
          clearTimeout(timeout);
        }, 100);
      }
    });
  }

  private scrollHandler() {
    if (this.chatBody) {
      const container = this.chatBody?.nativeElement;
      const scrollHeight: number = get(container, 'scrollHeight', 0);
      container.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    }
  }
}
