<div
  [ngClass]="[size()]"
  [ngStyle.lt-sm]="{ height: 'auto !important' }"
  class="iot-platform-toolbar"
  data-cy="iot-platform-ui-toolbar-v2"
  fxLayout="row wrap"
  fxLayoutAlign="end stretch"
  fxLayoutAlign.lt-sm="center center"
  fxLayoutGap="2px"
>
  @if (pageTypeOptions().visible) {
    <section
      [ngStyle.lt-sm]="{ border: 'none' }"
      class="iot-platform-toolbar-button-section iot-platform-toolbar-button-section--left"
      fxLayout="row"
      fxLayout.lt-sm="row wrap"
      fxLayoutAlign="end center"
    >
      <mat-button-toggle-group
        (change)="onTogglePageType($event)"
        [value]="pageTypeOptions().pageType"
        [hideSingleSelectionIndicator]="true"
        style="margin-left: 10px"
      >
        @for (option of pageTypeOptions().options; track $index) {
          <mat-button-toggle [matTooltip]="option.tooltip | translate" [value]="option.type"
                             class="reverse-accent-button" matTooltipClass="regular-tooltip">
            <mat-icon>{{ option.icon }}</mat-icon>
          </mat-button-toggle>
        }
      </mat-button-toggle-group>
      <span class="iot-platform-toolbar-button-section__right-separator"></span>
    </section>
  }

  @if (breadCrumbConfiguration()) {
    <section
      [ngStyle.lt-sm]="{ border: 'none' }"
      class="iot-platform-toolbar-section"
      fxFlex
      fxLayout="row"
      fxLayout.lt-sm="row wrap"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <iot-platform-ui-breadcrumb-item
        [entityName]="breadCrumbConfiguration()?.entityName"
        [icon]="breadCrumbConfiguration()?.icon"
        [name]="name()"
      ></iot-platform-ui-breadcrumb-item>
    </section>
  } @else if (name()) {
    <section
      [fxFlex.lt-sm]="pageTypeOptions().visible ? '40%' : ''"
      [ngClass]="[size()]"
      [ngStyle.lt-sm]="{ border: 'none' }"
      class="iot-platform-toolbar-section iot-platform-toolbar-title-section"
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-sm="center center"
      fxLayoutGap="10px"
    >
      @if (!currentFavoriteView()) {
        <span [ngStyle.lt-sm]="{ textAlign: 'center' }"
              class="iot-platform-toolbar-title fw-600">{{ total() | numberFormat }} {{ name() }}</span>
      } @else {
        <div fxLayout="column">
          <span [ngStyle.lt-sm]="{ textAlign: 'center' }"
                class="iot-platform-toolbar-first-line-title">{{ total() | numberFormat }} {{ name() }}</span>
          <span [ngStyle.lt-sm]="{ textAlign: 'center' }"
                class="iot-platform-toolbar-second-line-title">{{ currentFavoriteView()?.name }}</span>
        </div>
      }

      @if (!isDataLoaded()) {
        <mat-progress-spinner [diameter]="20" class="default-loader" color="accent"
                              mode="indeterminate"></mat-progress-spinner>
      } @else if (autoRefresh()?.displaySpinner) {
        <mat-progress-spinner
          [diameter]="20"
          [matTooltip]="(autoRefresh()?.timeLeft | number: '1.0-0') + ' s'"
          [value]="autoRefresh()?.counter"
          class="auto-refresh-spinner"
          color="accent"
          matTooltipClass="regular-tooltip"
          mode="determinate"
        ></mat-progress-spinner>
      }
    </section>
  }

  <section
    [ngStyle.lt-sm]="{ border: 'none' }"
    [style.background-color]="currentFavoriteView() ? currentFavoriteView()?.color + '50' : 'transparent'"
    class="iot-platform-toolbar-section"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayout.tl-sm="row wrap"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    @if (withFavoriteViews() && !!favoriteViewConfiguration()) {
      <div class="iot-platform-toolbar-favorite-view-section" fxLayout="row" fxLayoutAlign="end center">
        <mat-select
          (selectionChange)="onApplyFavoriteView($event)"
          [disabled]="groupedFavoriteViews()?.length === 0"
          [placeholder]="'IOT_TOOLBAR.FAVORITE_VIEW' | translate"
          [value]="currentFavoriteView()"
          [compareWith]="compareFn"
          class="iot-platform-toolbar-favorite-view-select"
          fxFlex.lt-sm="100%"
        >
          <mat-select-trigger fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <span [style.background-color]="currentFavoriteView()?.color"
                  class="iot-platform-toolbar-favorite-view-color"></span>
            <span class="iot-platform-toolbar-favorite-view-select-text">
              {{ currentFavoriteView()?.name | truncate: ['18', '...'] }}
            </span>
          </mat-select-trigger>
          <mat-option [value]="null" class="iot-platform-toolbar-favorite-view-option">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <span class="iot-platform-toolbar-favorite-view-select-text" fxFlex>Default View</span>
            </span>
          </mat-option>
          @for (group of groupedFavoriteViews(); track $index) {
            <mat-optgroup>
              <span class="iot-platform-toolbar-favorite-view-group-name">
                {{ group.name | translate }}
              </span>
              @for (favoriteView of group.favoriteViews; track favoriteView.id) {
                <mat-option [value]="favoriteView" class="iot-platform-toolbar-favorite-view-option"
                            style="height: 32px">
                  <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <span [style.background-color]="favoriteView.color"
                          class="iot-platform-toolbar-favorite-view-color"></span>
                    <span [innerHTML]="favoriteView.name" class="iot-platform-toolbar-favorite-view-select-text"
                          fxFlex></span>
                    @if (favoriteView.gridId) {
                      <mat-icon class="favorite-view-link-icon" color="accent" fxFlex="20" fxLayoutAlign="end center">link</mat-icon>
                    }
                  </span>
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-select>
        <div fxLayout="row" fxLayoutAlign="start center">
          @for (fvButton of favoriteviewButtons; track $index) {
            <iot-platform-ui-toolbar-button
              (buttonClick)="dispatchToolbarEvent.emit($event)"
              [button]="fvButton"
              class="inlined-button"
            ></iot-platform-ui-toolbar-button>
          }
        </div>
      </div>
    }
    <div class="iot-platform-toolbar-grid-favorite-view-loader-area">
      @if (!!gridsConfiguration() &&
      currentGrid()?.id &&
      currentFavoriteView()?.gridId === currentGrid()?.id &&
      !favoriteViewConfiguration()?.isFavoriteViewsLoading &&
      !gridsConfiguration()?.isGridsLoading &&
      pageTypeOptions().pageType === ToolbarPageType.GRID) {
        <mat-icon color="accent">link</mat-icon>
      }

      @if (favoriteViewConfiguration()?.isFavoriteViewsLoading || gridsConfiguration()?.isGridsLoading) {
        <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
      }
    </div>

    @switch (pageTypeOptions().pageType) {
      @case (ToolbarPageType.GRID) {
        @if (withGrids() && !!gridsConfiguration()) {
          <div class="iot-platform-toolbar-favorite-view-section" fxLayout="row" fxLayoutAlign="start center">
            <mat-select
              (selectionChange)="onApplyGrid($event)"
              [disabled]="gridsConfiguration()?.sortedGridsWithoutAppDefault?.length === 0"
              [placeholder]="'IOT_TOOLBAR.GRID' | translate"
              [value]="currentGrid()"
              class="iot-platform-toolbar-grid-select"
              fxFlex.lt-sm="100%"
              [compareWith]="compareFn"
            >
              <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
                <span class="iot-platform-toolbar-favorite-view-select-text">{{ currentGrid()?.name }}</span>
                <span class="iot-platform-toolbar-favorite-view-select-text">{{
                    currentGrid()?.isDefault ? ('GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_GRID' | translate) : ''
                  }}</span>
              </mat-select-trigger>

              @for (group of groupedGrids(); track group.name) {
                <mat-optgroup>
                  <span class="iot-platform-toolbar-favorite-view-group-name">
                    {{ group.name | translate }}
                  </span>
                  @for (grid of group.grids; track grid.id) {
                    <mat-option [value]="grid" class="iot-platform-toolbar-favorite-view-option" style="height: 32px">
                      <span fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <span class="iot-platform-toolbar-favorite-view-select-text">{{ grid.name }}</span>
                        <span class="iot-platform-toolbar-favorite-view-select-text" style="flex: 1">{{
                            grid?.isDefault ? ('GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_GRID' | translate) : ''
                          }}</span>
                      </span>
                    </mat-option>
                  }
                </mat-optgroup>
              }
            </mat-select>

            <div fxLayout="row" fxLayoutAlign="start center">
              @for (gridButton of gridButtons; track $index) {
                <iot-platform-ui-toolbar-button (buttonClick)="dispatchToolbarEvent.emit($event)" [button]="gridButton"
                                                class="inlined-button">
                </iot-platform-ui-toolbar-button>
              }
            </div>
          </div>
        }
      }
      @case (ToolbarPageType.DASHBOARD) {
        @if (dashboardsConfiguration()) {
          <div class="iot-platform-toolbar-favorite-view-section" fxLayout="row" fxLayoutAlign="start center">
            <mat-select
              (selectionChange)="onDashboardSelection($event)"
              [placeholder]="'IOT_TOOLBAR.DASHBOARD' | translate"
              [value]="dashboardsConfiguration()?.currentDashboard"
              [compareWith]="compareFn"
              class="iot-platform-toolbar-dashboard-select"
              fxFlex.lt-sm="100%"
            >
              @for (dashboard of dashboardsConfiguration()?.sortedDashboards; track dashboard.id) {
                <mat-option [value]="dashboard" class="iot-platform-toolbar-favorite-view-option" style="height: 32px">
                  <span fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <span [innerHTML]="dashboard.name | translate"
                          class="iot-platform-toolbar-favorite-view-select-text"></span>
                  </span>
                </mat-option>
              }
            </mat-select>
          </div>
        }
      }
    }
  </section>

  @if (filterText()?.visible) {
    <section
      [ngStyle.lt-sm]="{ border: 'none' }"
      class="iot-platform-toolbar-section iot-platform-toolbar-button-section"
      fxLayout="row"
      fxLayout.lt-sm="row wrap"
      fxLayout.lt-sm="row wrap"
      fxLayoutAlign="end center"
    >
      <div class="iot-platform-toolbar-filter-input" fxLayout="row" fxLayoutAlign="end center">
        <mat-icon>search</mat-icon>
        <input
          #filterQuery
          (keyup.enter)="filterTextControl?.patchValue(filterQuery?.value)"
          [focusInitial]="filterText()?.autoFocus"
          [formControl]="filterTextControl"
          [matTooltip]="filterText()?.tooltip || '' | translate"
          [placeholder]="filterText()?.placeholder || '' | translate"
          data-cy="iot-platform-ui-toolbar-v2-search-input"
          focusInitial
          matInput
          matTooltipClass="regular-tooltip"
        />
        @if (filterQuery?.value) {
          <button (click)="filterTextControl?.patchValue(null); filterQuery.value = ''; filterQuery?.focus()"
                  mat-icon-button>
            <mat-icon>clear</mat-icon>
          </button>
        }
      </div>
    </section>
  }

  @if (displayButtonSection()) {
    <section
      [ngClass]="{ 'iot-platform-toolbar-button-section-fixed-25': !!favoriteViewConfiguration() }"
      class="iot-platform-toolbar-section iot-platform-toolbar-button-section"
      fxLayout="row"
      fxLayout.lt-sm="row wrap"
      fxLayoutAlign="end center"
      fxLayoutAlign.lt-md="center center"
      fxLayoutGap="8px"
    >
      @for (button of orderedButtonList(); track $index) {
        <iot-platform-ui-toolbar-button (buttonClick)="dispatchToolbarEvent.emit($event)" [button]="button">
          <ng-container toolbarCustomStatusButtonItem>
            <ng-content select="[toolbarCustomStatusButton]"></ng-content>
          </ng-container>
        </iot-platform-ui-toolbar-button>
      }
    </section>
  }
  <ng-content select="[toolbarCustomSection]"></ng-content>
</div>
