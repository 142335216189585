import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const schedulerSelectedDevicesColumnSettings = [
  {
    order: 5,
    id: 'cyclicalCallFault.status',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE',
    catalogName: 'CYCLICAL CALL FAULT',
    default: true,
    sortable: true,
    sortProperty: 'cyclicalCallFault.status',
    headerType: HeaderType.EMPTY,
    cellType: I4BCellType.CYCLICAL_CALL_FAULT,
    isLink: false,
    width: '5%',
    cellWidth: '40',
    type: 'start center'
  },
  {
    order: 10,
    id: 'name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE',
    catalogName: 'DEVICE NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.ICON_AND_TEXT,
    headerIcon: 'device',
    cellType: I4BCellType.BASIC_LINK,
    clickEvent: {
      type: 'navigateToDevice',
      options: {}
    },
    isLink: true,
    width: '25%',
    cellWidth: '240',
    type: 'start center'
  },
  {
    order: 20,
    id: 'site.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE',
    catalogName: 'SITE NAME',
    default: true,
    sortable: true,
    sortProperty: 'site.name',
    headerType: HeaderType.ICON_AND_TEXT,
    headerIcon: 'site',
    cellType: I4BCellType.BASIC_LINK,
    clickEvent: {
      type: 'navigateToSite',
      options: {}
    },
    isLink: true,
    width: '25%',
    cellWidth: '240',
    type: 'start center'
  },
  {
    order: 30,
    id: 'entity.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_ENTITY',
    catalogName: 'ENTITY',
    default: true,
    sortable: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    sortProperty: 'entity.name',
    isLink: false,
    width: '30%',
    cellWidth: '200',
    type: 'start center'
  },
  {
    order: 40,
    id: 'type.family',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
    catalogName: 'DEVICE TYPE FAMILY',
    default: true,
    sortable: true,
    sortProperty: 'type.family',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 50,
    id: 'communication.support',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_SUPPORT',
    catalogName: 'SUPPORT',
    sortable: false,
    default: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 60,
    id: 'lastCallStatus.datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_CALL_DATE',
    catalogName: 'LAST CALL DATE',
    sortable: true,
    default: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: 'false',
    clickEvent: '',
    width: '13%',
    cellWidth: '165',
    type: 'start center'
  },
  {
    order: 70,
    id: 'outgoingConnector.id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_ID',
    catalogName: 'OUTGOING CONNECTOR ID',
    sortable: true,
    default: true,
    sortProperty: 'outgoingConnector.id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.OUTGOING_CONNECTOR,
    isLink: false,
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 80,
    id: 'lastCommandStatus.command',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_SENT',
    catalogName: 'LAST COMMAND SENT',
    sortable: true,
    default: true,
    sortProperty: 'lastCommandStatus.command',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 90,
    id: 'lastCommandStatus.datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_DATE',
    catalogName: 'LAST COMMAND DATE',
    sortable: true,
    default: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: 'false',
    clickEvent: '',
    width: '13%',
    cellWidth: '165',
    type: 'start center'
  },
  {
    order: 100,
    id: 'lastCommandStatus.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_STATUS',
    catalogName: 'LAST COMMAND STATUS',
    default: true,
    sortable: false,
    sortProperty: 'lastCommandStatus.name',
    headerType: HeaderType.BASIC,
    headerTooltip: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_STATUS',
    cellType: I4BCellType.LAST_COMMAND_STATUS_CELL,
    isLink: false,
    width: '10%',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 110,
    id: 'lastCommandStatus.status',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_MESSAGE',
    catalogName: 'LAST COMMAND MESSAGE',
    sortable: true,
    default: true,
    sortProperty: 'lastCommandStatus.status',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 120,
    id: 'lastCommandStatus.commandErrorReason',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_ERROR',
    catalogName: 'LAST COMMAND ERROR',
    sortable: true,
    default: true,
    sortProperty: 'lastCommandStatus.commandErrorReason',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  }
];
