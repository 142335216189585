import { createReducer, on } from '@ngrx/store';
import { AdminOrganizationsAdministratorsApiActions, AdminOrganizationsAdministratorsPageActions, AdminOrganizationsPageActions } from '../actions';

export const adminOrganizationsAdministratorsPageFeatureKey = 'adminOrganizationsAdministatorsPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsPageActions.selectOrganization, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminOrganizationsAdministratorsPageActions.listAdministratorsByOrganization, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminOrganizationsAdministratorsApiActions.listAdministratorsByOrganizationSuccess, (state: State) => ({
    ...state,
    error: null,
    pending: false
  })),
  on(AdminOrganizationsAdministratorsApiActions.listAdministratorsByOrganizationFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
