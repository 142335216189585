/* eslint-disable ngrx/prefix-selectors-with-select */
import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { Action, combineReducers, createFeatureSelector, createSelector, provideState } from '@ngrx/store';
import { AssetVariablesEffects } from '../effects/asset-variables.effects';
import { AssetsEffects } from '../effects/assets.effects';
import { assetVariablesFeature } from './asset-variables.reducer';
import { adapter as AssetsCommentsAdapter, assetsCommentsFeature, State as AssetsCommentsState } from './assets-comments.reducer';
import { assetsFeature } from './assets.reducer';

export function provideAssetsState(): EnvironmentProviders[] {
  return [provideState(assetsFeature.name, assetsFeature.reducer), provideEffects(AssetsEffects)];
}

export function provideAssetVariablesState(): EnvironmentProviders[] {
  return [provideState(assetVariablesFeature.name, assetVariablesFeature.reducer), provideEffects(AssetVariablesEffects)];
}

export const assetsFeatureKey = 'zzz_assets';

export interface AssetsState {
  [assetsCommentsFeature.name]: AssetsCommentsState;
}

export interface State {
  [assetsFeatureKey]: AssetsState;
}

export function reducers(state: AssetsState | undefined, action: Action) {
  return combineReducers({
    [assetsCommentsFeature.name]: assetsCommentsFeature.reducer
  })(state, action);
}

export const selectAssetsState = createFeatureSelector<AssetsState>(assetsFeatureKey);

// Comments
const selectAssetsCommentsState = createSelector(selectAssetsState, (state: AssetsState) => state[assetsCommentsFeature.name]);

export const {
  selectIds: selectAssetCommentsIds,
  selectEntities: selectAssetCommentsDictionary,
  selectAll: selectAllAssetComments,
  selectTotal: selectTotalAssetComments
} = AssetsCommentsAdapter.getSelectors(selectAssetsCommentsState);

export const selectAssetCommentsError = createSelector(selectAssetsCommentsState, (state: AssetsCommentsState) => state.error);
export const selectAssetCommentsLoading = createSelector(selectAssetsCommentsState, (state: AssetsCommentsState) => state.loading);
