import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@iot-platform/models/common';

import { SiteType } from '@iot-platform/models/i4b';

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteTypesService {
  constructor(
    @Inject('environment') private environment: Environment,
    public httpClient: HttpClient,
    private translateService: TranslateService
  ) {}

  getAll(): Observable<SiteType[]> {
    return this.httpClient
      .get<{ content: SiteType[] }>(this.environment.api.url + this.environment.api.endpoints.siteTypes + this.translateService.currentLang.toUpperCase())
      .pipe(map((data) => data.content));
  }
}
