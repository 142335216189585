import { Injectable } from '@angular/core';
import { ApiFeatureSelector, BusinessProfile, Filter, Pagination, UserAccount } from '@iot-platform/models/common';
import * as fromUsers from '../reducers/users.reducer';
import { createSelector } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UsersSelectors extends ApiFeatureSelector<fromUsers.State, UserAccount, Pagination, Filter> {
  selectBusinessProfilesByUser = createSelector(this.selectState, (state: fromUsers.State): BusinessProfile[] => state.businessProfilesByUser);

  constructor() {
    super(fromUsers.usersFeature.selectUsersState, fromUsers.adapter);
  }
}
