export const TrackingEventIds = {
  NAVIGATE_TO_SITES_MASTER_VIEW: 'sites',
  NAVIGATE_TO_SITE_OVERVIEW_PAGE: 'navigate_to_site_overview_page',
  NAVIGATE_TO_DEVICES_MASTER_VIEW: 'devices',
  REFRESH_DEVICES_DATA: 'refresh_devices_data',
  REFRESH_DEVICE_OVERVIEW_DATA: 'refresh_device_details_data',
  NAVIGATE_TO_DEVICES_MASTER_VIEW_FROM_DEVICE_OVERVIEW: 'go_back_to_devices',
  OPEN_DEVICE_EDIT_FORM: 'open_device_edit_form',
  OPEN_BULK_DEVICE_EDIT_FORM: 'open_bulk_device_edit_form',
  UPDATE_DEVICE_DETAILS: 'update_device_details',
  BULK_UPDATE_DEVICE_DETAILS: 'bulk_update_device_details',
  EXPORTS_DEVICES_DATA: 'export_devices_data',
  NAVIGATE_TO_DEVICE_OVERVIEW_PAGE: 'navigate_to_device_overview_page',
  NAVIGATE_TO_ASSETS_MASTER_VIEW: 'assets',
  NAVIGATE_TO_ASSET_OVERVIEW_PAGE: 'navigate_to_asset_overview_page',
  APPLY_DEVICES_FILTERS: 'apply_devices_filters',
  DEVICE_DETAILS_TAB_CHANGE: 'device_details_tab_change',
  OPEN_DEVICE_EDIT_FILES_FORM: 'open_device_edit_files_form',
  OPEN_BULK_DEVICES_EDIT_FILES_FORM: 'open_bulk_devices_edit_files_form',
  UPDATE_DEVICE_FILES: 'update_device_files',
  BULK_UPDATE_DEVICES_FILES: 'bulk_update_devices_files',
};
