import { NgModule } from '@angular/core';
import { SiteDetailAssetsComponent } from './site-detail-assets/site-detail-assets.component';
import { SiteDetailBasicInfoComponent } from './site-detail-basic-info.component.ts/site-detail-basic-info.component';
import { SiteDetailContactsComponent } from './site-detail-contacts/site-detail-contacts.component';
import { SiteDetailDevicesComponent } from './site-detail-devices/site-detail-devices.component';
import { SiteDetailTagsComponent } from './site-detail-tags/site-detail-tags.component';
import { SiteDetailsFullInfoComponent } from './site-details-full-info/site-details-full-info.component';

@NgModule({
  imports: [
    SiteDetailAssetsComponent,
    SiteDetailBasicInfoComponent,
    SiteDetailContactsComponent,
    SiteDetailDevicesComponent,
    SiteDetailTagsComponent,
    SiteDetailsFullInfoComponent
  ],
  exports: [
    SiteDetailBasicInfoComponent,
    SiteDetailContactsComponent,
    SiteDetailAssetsComponent,
    SiteDetailDevicesComponent,
    SiteDetailTagsComponent,
    SiteDetailsFullInfoComponent
  ]
})
export class SiteDetailsModule {}
