import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { get } from 'lodash';

export class TagCellHelpers {
  static processValue(params: ProcessCellForExportParams): string {
    const { column, node } = params;
    if (!!node.data.expandedTagCategories) {
      return get(node, `data.expandedTagCategories.${column.getColDef().field}.labels[0].name`, '');
    } else {
      return get(node, `data.tags.${column.getColDef().field}.labels[0].name`, '');
    }
  }
}
