import { PlatformResponse } from '@iot-platform/models/common';

import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEventsBySiteSuccess = createAction(
  '[AssetEventsBySite] Load Asset Events By Site Success',
  props<{ response: PlatformResponse; siteId: string }>()
);
export const loadAssetEventsBySiteFailure = createAction('[AssetEventsBySite] Load Asset Events By Site Failure', props<{ error: any }>());
//
export const loadTotalActiveAssetEventsBySiteSuccess = createAction(
  '[AssetEventsBySite] Load Total Active  Asset Events By Site Success',
  props<{ totalActiveEvents: number }>()
);
export const loadTotalActiveAssetEventsBySiteFailure = createAction(
  '[AssetEventsBySite] Load Total Active  Asset Events By Site Failure',
  props<{ error: any }>()
);
//
export const saveTableBySiteStateSuccess = createAction('[AssetEventsBySite] Save Table State Success', props<{ selectedId: string; checkedIds: string[] }>());
export const saveTableBySiteStateFailure = createAction('[AssetEventsBySite] Save Table State Failure', props<{ error: any }>());

export const updateStatusByAssetEventIdBySiteSuccess = createAction(
  '[AssetEventsBySite] Update Status By Asset Event Id Success',
  props<{ assetEvent: AssetEvent }>()
);
export const updateStatusByAssetEventIdBySiteFailure = createAction('[AssetEventsBySite] Update Status By Asset Event Id Failure', props<{ error: any }>());
//
export const bulkUpdateStatusByAssetEventIdBySiteSuccess = createAction(
  '[AssetEventsBySite] Bulk Update Status By Asset Event Id Success',
  props<{ assetEvents: AssetEvent[] }>()
);
export const bulkUpdateStatusByAssetEventIdFailure = createAction('[AssetEventsBySite] Bulk Update Status By Asset Event Id Failure', props<{ error: any }>());
//
export const loadMvAssetEventsBySiteSettingsSuccess = createAction(
  '[AssetEventsBySite] Load MV AssetEventsBySite Settings Success',
  props<{ settings: any }>()
);
export const loadMvAssetEventsBySiteSettingsFailure = createAction('[AssetEventsBySite] Load MV AssetEventsBySite Settings Failure', props<{ error: any }>());
