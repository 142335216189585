import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsDbActions, DeviceEventsUiActions } from '../actions';
import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';

export const deviceEventsUiFeatureKey = 'deviceEventsUi';

export interface State {
  error?: any;
  deviceEventsLoaded: boolean;
  deviceEventsLoading: boolean;
  siteLoading?: boolean;
  siteLoaded?: boolean;
  assetLoaded?: boolean;
  assetLoading?: boolean;
  assetVarLoaded?: boolean;
  assetVarLoading?: boolean;
  deviceLoaded?: boolean;
  deviceLoading?: boolean;
  deviceVarLoaded?: boolean;
  deviceVarLoading?: boolean;
  tagsLoaded?: boolean;
}

export const initialState: State = {
  error: null,
  deviceEventsLoaded: false,
  deviceEventsLoading: false,
  siteLoading: false,
  siteLoaded: false,
  assetLoaded: false,
  assetLoading: false,
  assetVarLoaded: false,
  assetVarLoading: false,
  deviceLoaded: false,
  deviceLoading: false,
  deviceVarLoaded: false,
  deviceVarLoading: false,
  tagsLoaded: false
};

const deviceEventsUiReducer = createReducer(
  initialState,
  on(DeviceEventsUiActions.loadDeviceEvents, (state: State): State => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsDbActions.loadDeviceEventsSuccess, (state: State): State => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true })),
  on(
    DeviceEventsDbActions.loadDeviceEventsFailure,
    (state: State, { error }): State => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true, error })
  ),
  // ****
  on(DeviceEventsUiActions.loadMVSettings, (state: State): State => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsDbActions.loadMVSettingsSuccess, (state: State): State => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true })),
  on(
    DeviceEventsDbActions.loadMVSettingsFailure,
    (state: State, { error }): State => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true, error })
  ),
  // ****
  on(DeviceEventsUiActions.saveMVSettings, (state: State): State => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsDbActions.saveMVSettingsSuccess, (state: State): State => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true })),
  on(
    DeviceEventsDbActions.saveMVSettingsFailure,
    (state: State, { error }): State => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true, error })
  ),
  // ****
  on(DeviceEventsUiActions.loadSiteById, (state: State): State => ({ ...state, siteLoading: true, siteLoaded: false })),
  on(DeviceEventsDbActions.loadSiteByIdSuccess, (state: State): State => ({ ...state, siteLoading: false, siteLoaded: true })),
  on(DeviceEventsDbActions.loadSiteByIdFailure, (state: State, { error }): State => ({ ...state, siteLoading: false, siteLoaded: true, error })),
  // ****
  on(DeviceEventsUiActions.loadAssetById, (state: State): State => ({ ...state, assetLoading: true, assetLoaded: false })),
  on(DeviceEventsDbActions.loadAssetByIdSuccess, (state: State): State => ({ ...state, assetLoading: false, assetLoaded: true, error: null })),
  on(DeviceEventsDbActions.loadAssetByIdFailure, (state: State, { error }): State => ({ ...state, assetLoading: false, assetLoaded: true, error })),
  // ****
  on(DeviceEventsUiActions.loadDeviceById, (state: State): State => ({ ...state, deviceLoading: true, deviceLoaded: false })),
  on(DeviceEventsDbActions.loadDeviceByIdSuccess, (state: State): State => ({ ...state, deviceLoading: false, deviceLoaded: true })),
  on(DeviceEventsDbActions.loadDeviceByIdFailure, (state: State, { error }): State => ({ ...state, deviceLoading: false, deviceLoaded: true, error })),
  // ****
  on(DeviceEventsUiActions.loadDeviceVariableById, (state: State): State => ({ ...state, deviceVarLoading: true, deviceVarLoaded: false })),
  on(DeviceEventsDbActions.loadDeviceVariableByIdSuccess, (state: State): State => ({ ...state, deviceVarLoading: false, deviceVarLoaded: true })),
  on(
    DeviceEventsDbActions.loadDeviceVariableByIdFailure,
    (state: State, { error }): State => ({ ...state, deviceVarLoading: false, deviceVarLoaded: true, error })
  ),
  // ****
  on(DeviceEventsUiActions.loadAssetVariableById, (state: State): State => ({ ...state, assetVarLoading: true, assetVarLoaded: false })),
  on(DeviceEventsDbActions.loadAssetVariableByIdSuccess, (state: State): State => ({ ...state, assetVarLoading: false, assetVarLoaded: true })),
  on(
    DeviceEventsDbActions.loadAssetVariableByIdFailure,
    (state: State, { error }): State => ({ ...state, assetVarLoading: false, assetVarLoaded: true, error })
  ),
  // ****
  on(DeviceEventsUiActions.loadTagsByDeviceEventId, (state: State): State => ({ ...state })),
  on(DeviceEventsDbActions.loadTagsByDeviceEventIdSuccess, (state: State): State => ({ ...state, tagsLoaded: true })),
  on(DeviceEventsDbActions.loadTagsByDeviceEventIdFailure, (state: State, { error }): State => ({ ...state, tagsLoaded: true, error })),
  // ****
  on(DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess, (state: State): State => ({ ...state, deviceEventsLoaded: true })),
  on(DeviceEventsDbActions.updateStatusByDeviceEventIdFailure, (state: State, { error }): State => ({ ...state, deviceEventsLoaded: true, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (): State => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsUiReducer(state, action);
}

export const getDeviceEventsLoaded = (state: State) => state.deviceEventsLoaded;
export const getDeviceEventsLoading = (state: State) => state.deviceEventsLoading;
export const getSiteLoaded = (state: State) => state.siteLoaded;
export const getSiteLoading = (state: State) => state.siteLoading;
export const getAssetLoaded = (state: State) => state.assetLoaded;
export const getAssetLoading = (state: State) => state.assetLoading;
export const getAssetVariableLoaded = (state: State) => state.assetVarLoaded;
export const getAssetVariableLoading = (state: State) => state.assetVarLoading;
export const getDeviceLoaded = (state: State) => state.deviceLoaded;
export const getDeviceLoading = (state: State) => state.deviceLoading;
export const getDeviceVariableLoaded = (state: State) => state.deviceVarLoaded;
export const getDeviceVariableLoading = (state: State) => state.deviceVarLoading;
export const getTagsLoaded = (state: State) => state.tagsLoaded;
