<mat-chip-set fxLayout="row wrap">
  @if (subscribersLoaded && dataToDisplay) {
    <mat-chip-option [disableRipple]="true" [ngStyle]="{ backgroundColor: '#4f79b7' }" [selectable]="false">
      {{ dataToDisplay | translate }}
    </mat-chip-option>
  }
</mat-chip-set>
@if (!subscribersLoaded) {
  <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
}
