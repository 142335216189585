import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Pagination, PlatformResponse } from '@iot-platform/models/common';
import { EscalationProtocol } from '@iot-platform/models/ocm';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEscalationProtocolLevelsDb from './escalation-protocol-levels-db.reducer';
import * as fromEscalationProtocolLevelsUi from './escalation-protocol-levels-ui.reducer';

import * as fromEscalationProtocolsDb from './escalation-protocols-db.reducer';
import * as fromEscalationProtocolsUi from './escalation-protocols-ui.reducer';

export const escalationProtocolsFeatureKey = 'escalationProtocols';

export interface EscalationProtocolsState {
  [fromEscalationProtocolsUi.escalationProtocolsUiFeatureKey]: fromEscalationProtocolsUi.State;
  [fromEscalationProtocolsDb.escalationProtocolsDbFeatureKey]: fromEscalationProtocolsDb.State;
  [fromEscalationProtocolLevelsUi.escalationProtocolLevelsUiFeatureKey]: fromEscalationProtocolLevelsUi.State;
  [fromEscalationProtocolLevelsDb.escalationProtocolLevelsDbFeatureKey]: fromEscalationProtocolLevelsDb.State;
}

export interface State {
  [escalationProtocolsFeatureKey]: EscalationProtocolsState;
}

export function reducers(state: EscalationProtocolsState | undefined, action: Action) {
  return combineReducers({
    [fromEscalationProtocolsUi.escalationProtocolsUiFeatureKey]: fromEscalationProtocolsUi.reducer,
    [fromEscalationProtocolsDb.escalationProtocolsDbFeatureKey]: fromEscalationProtocolsDb.reducer,
    [fromEscalationProtocolLevelsUi.escalationProtocolLevelsUiFeatureKey]: fromEscalationProtocolLevelsUi.reducer,
    [fromEscalationProtocolLevelsDb.escalationProtocolLevelsDbFeatureKey]: fromEscalationProtocolLevelsDb.reducer
  })(state, action);
}

export const selectEscalationProtocolsState = createFeatureSelector<EscalationProtocolsState>(escalationProtocolsFeatureKey);

export const selectEscalationProtocolsUiState = createSelector(selectEscalationProtocolsState, (state: EscalationProtocolsState) =>
  state ? state[fromEscalationProtocolsUi.escalationProtocolsUiFeatureKey] : null
);
export const selectEscalationProtocolsDbState = createSelector(selectEscalationProtocolsState, (state: EscalationProtocolsState) =>
  state ? state[fromEscalationProtocolsDb.escalationProtocolsDbFeatureKey] : null
);

export const {
  selectIds: getEscalationProtocolsIds,
  selectEntities: getEscalationProtocolsEntities,
  selectAll: getAllEscalationProtocols,
  selectTotal: getTotalEscalationProtocols
} = fromEscalationProtocolsDb.adapter.getSelectors(selectEscalationProtocolsDbState);

// ***** DB ***** //
export const getSelectedEscalationProtocolId = createSelector(selectEscalationProtocolsDbState, fromEscalationProtocolsDb.getSelectedEscalationProtocolId);
export const getSelectedEscalationProtocol = createSelector(
  getEscalationProtocolsEntities,
  getSelectedEscalationProtocolId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getPagination = createSelector(selectEscalationProtocolsDbState, fromEscalationProtocolsDb.getPagination);
export const getCurrentFilters = createSelector(selectEscalationProtocolsDbState, fromEscalationProtocolsDb.getCurrentFilters);
export const getError = createSelector(selectEscalationProtocolsDbState, fromEscalationProtocolsDb.getError);

export const getFormattedData = createSelector(getAllEscalationProtocols, getPagination, (data: EscalationProtocol[], pagination: Pagination) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});
export const getTotal = createSelector(selectEscalationProtocolsDbState, getPagination, (state, pagination) => pagination.total);

export const getTopicSubscriptions = createSelector(selectEscalationProtocolsDbState, fromEscalationProtocolsDb.getTopicSubscriptions);
export const getTopicSubscriptionsAsPlatformResponse = createSelector(getTopicSubscriptions, (response) => {
  const formattedData: PlatformResponse = {
    data: response?.data?.sort(SortUtil.sortByName),
    limit: 10,
    hasMore: false,
    maxPage: 0,
    total: response?.data?.length,
    currentPage: 0
  };

  return formattedData;
});
// ***** UI ***** //
export const getProtocolsLoaded = createSelector(selectEscalationProtocolsUiState, fromEscalationProtocolsUi.getEscalationProtocolsLoaded);
export const getProtocolsLoading = createSelector(selectEscalationProtocolsUiState, fromEscalationProtocolsUi.getEscalationProtocolsLoading);

export const getTopicSubscriptionsLoaded = createSelector(selectEscalationProtocolsUiState, fromEscalationProtocolsUi.getTopicSubscriptionsLoaded);
export const getTopicSubscriptionsLoading = createSelector(selectEscalationProtocolsUiState, fromEscalationProtocolsUi.getTopicSubscriptionsLoading);

// //////////////////////
// ***** LEVELS ***** //
// //////////////////////

export const selectEscalationProtocolLevelsUiState = createSelector(selectEscalationProtocolsState, (state: EscalationProtocolsState) =>
  state ? state[fromEscalationProtocolLevelsUi.escalationProtocolLevelsUiFeatureKey] : null
);
export const selectEscalationProtocolLevelsDbState = createSelector(selectEscalationProtocolsState, (state: EscalationProtocolsState) =>
  state ? state[fromEscalationProtocolLevelsDb.escalationProtocolLevelsDbFeatureKey] : null
);

export const {
  selectIds: getEscalationProtocolLevelsIds,
  selectEntities: getEscalationProtocolLevelsEntities,
  selectAll: getAllEscalationProtocolLevels,
  selectTotal: getTotalEscalationProtocolLevels
} = fromEscalationProtocolLevelsDb.adapter.getSelectors(selectEscalationProtocolLevelsDbState);

// ***** DB ***** //
export const selectSelectedEscalationProtocolLevelId = createSelector(
  selectEscalationProtocolLevelsDbState,
  fromEscalationProtocolLevelsDb.getSelectedEscalationProtocolLevelId
);

export const selectEscalationProtocolLevelsSorted = createSelector(getAllEscalationProtocolLevels, (levels) =>
  levels.sort(SortUtil.sortByProperty('levelOrder'))
);

// ***** UI ***** //
export const selectProtocolLevelsLoaded = createSelector(
  selectEscalationProtocolLevelsUiState,
  fromEscalationProtocolLevelsUi.getEscalationProtocolLevelsLoaded
);
export const selectProtocolLevelsLoading = createSelector(
  selectEscalationProtocolLevelsUiState,
  fromEscalationProtocolLevelsUi.getEscalationProtocolLevelsLoading
);
