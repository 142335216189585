import { DataGuardCheckError, DataGuardCheckStatus } from '@iot-platform/models/data-guard';
import { Concept } from '@iot-platform/models/i4b';

export class I4BDataGuardHelper {
  static getDataGuardError(errorKey: string, concept: Concept): DataGuardCheckError {
    const dict = I4BDataGuardHelper.getCompleteDictionary();
    return {
      status: dict[concept][errorKey].status,
      label: `DATA_GUARD.ERRORS_DICTIONARY.${concept}.${errorKey}.LABEL`,
      description: `DATA_GUARD.ERRORS_DICTIONARY.${concept}.${errorKey}.DESCRIPTION`,
      suggestedActions: dict[concept][errorKey].actions.map((a) => ({
        description: `DATA_GUARD.ERRORS_DICTIONARY.${concept}.${errorKey}.ACTIONS.${a}`
      }))
    };
  }

  private static getCompleteDictionary(): {
    [concept in Concept]: {
      [errorId: string]: { status: DataGuardCheckStatus; actions: string[] };
    };
  } {
    return {
      [Concept.ASSET]: {
        badVariableUnit: {
          actions: ['1'],
          status: 'error'
        },
        badVariableUnitAUTONOMY: {
          actions: ['1'],
          status: 'error'
        },
        badVariableUnitLEVEL: {
          actions: ['1'],
          status: 'error'
        },
        badVariableUnitPRESSURE: {
          actions: ['1'],
          status: 'error'
        },
        badVariableUnitTEMPERATURE: {
          actions: ['1'],
          status: 'error'
        },
        mandatoryTagMissing: {
          actions: ['1'],
          status: 'error'
        },
        neverEndingMaintenance: {
          actions: ['1'],
          status: 'warning'
        },
        undefinedBusinessId: {
          actions: ['1', '2'],
          status: 'warning'
        },
        undefinedDeliveryDate: {
          actions: ['1', '2'],
          status: 'warning'
        },
        undefinedProduct1: {
          actions: ['1', '2'],
          status: 'warning'
        },
        undefinedProduct2: {
          actions: ['1', '2'],
          status: 'warning'
        },
        undefinedQuantity1: {
          actions: ['1', '2'],
          status: 'warning'
        },
        undefinedQuantity2: {
          actions: ['1', '2'],
          status: 'warning'
        },
        undefinedShipTo: {
          actions: ['1', '2'],
          status: 'warning'
        },
        variableNotConfigured: {
          actions: ['1'],
          status: 'warning'
        }
      },
      [Concept.DEVICE]: {
        deviceNotDeleted: {
          actions: ['1'],
          status: 'warning'
        },
        neverEndingMaintenance: {
          actions: ['1'],
          status: 'warning'
        },
        stockDeviceInRunningStatus: {
          actions: ['1'],
          status: 'warning'
        },
        undeadDevice: {
          actions: ['1'],
          status: 'error'
        },
        undefinedAffiliate: {
          actions: ['1'],
          status: 'warning'
        }
      },
      [Concept.EVENT]: {},
      [Concept.SITE]: {
        mandatoryTagMissing: {
          status: 'error',
          actions: ['1']
        },
        lowAddressAccuracy: {
          status: 'warning',
          actions: ['1']
        }
      }
    };
  }
}
