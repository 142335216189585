import { I4BCellType, I4BColumnConfiguration } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BVariableColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';
import { RichVariableColumn } from './i4b-rich-variable.column';
import { I4BThresholdColumn } from './i4b-threshold.column';

const defaultDisplayThresholds = { 1: false, 2: false, 3: false, 4: false, 5: false };

export class AssetFollowedVariableWithCommentGroupColumn extends I4BBasicColumn {
  columnId = '3f58dd72-5f3e-11ec-9de0-acde48001122-asset-followed-variable-with-comment-group';
  className = AssetFollowedVariableWithCommentGroupColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BVariableColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'FOLLOWED VARIABLE FULL (' + customConfig.id + ')',
        type: HeaderType.BASIC
      },
      {
        id: customConfig.id,
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.FOLLOWED_VARIABLES,
        isDefault: customConfig.isDefault ? customConfig.isDefault : false,
        marryChildren: true,
        children: [
          new I4BBasicColumn(
            { displayName: 'NAME' },
            {
              id: 'followedVariables.' + customConfig.id + '.name',
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.name',
              cell: {
                type: I4BCellType.ASSET_VARIABLE_CELL,
                options: { datatype: 'asset' },
                event: { type: 'openChart', options: { followedNumber: customConfig.id } }
              }
            },
            { order: 0, customId: 'followedVariables.' + customConfig.id + '.name', isDisplayed: true }
          ),
          new I4BBasicColumn(
            { displayName: 'COMMENT' },
            {
              id: 'followedVariables.' + customConfig.id + '.comment',
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.comment',
              cell: { type: I4BCellType.BASIC }
            },
            { order: 2, customId: 'followedVariables.' + customConfig.id + '.comment', isDisplayed: true }
          ),
          new RichVariableColumn(
            { displayName: 'VALUE' },
            {
              id: 'followedVariables.' + customConfig.id,
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.lastValue.value',
              cell: {
                type: I4BCellType.RICH_VARIABLE,
                options: { dataType: 'lastValue', unit: 'unit', value: 'value', tooltip: 'datetime' },
                event: { type: 'openChart', options: { followedNumber: customConfig.id } },
                valueGetter: 'followedVariables.' + customConfig.id + '.lastValue.value'
              }
            },
            { order: 1, customId: 'followedVariables.' + customConfig.id, isDisplayed: true }
          ),
          new I4BBasicColumn(
            { displayName: 'DATETIME' },
            {
              id: 'followedVariables.' + customConfig.id + '.lastValue.datetime',
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.lastValue.datetime',
              cell: { type: I4BCellType.DATE }
            },
            { order: 2, customId: 'followedVariables.' + customConfig.id + '.lastValue.datetime', isDisplayed: customOptions.displayDateTime }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 1 } },
              isFollowedVariable: true
            },
            {
              order: 3,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 2 } },
              isFollowedVariable: true
            },
            {
              order: 4,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 3 } },
              isFollowedVariable: true
            },
            {
              order: 5,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 4 } },
              isFollowedVariable: true
            },
            {
              order: 6,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 5 } },
              isFollowedVariable: true
            },
            {
              order: 7,
              displayThresholds: customOptions.displayThresholds
            }
          )
        ]
      },
      { customId: customConfig.id, displayDateTime: true, displayThresholds: defaultDisplayThresholds, order: customOptions.order, ...customOptions }
    );
  }
}
