import { HttpClient } from '@angular/common/http';
import { inject, Injectable, ProviderToken, signal, WritableSignal } from '@angular/core';

import { Entity, Environment } from '@iot-platform/models/common';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntityCellService {
  private readonly environment: Environment = inject('environment' as unknown as ProviderToken<Environment>);
  private readonly httpClient: HttpClient = inject(HttpClient);
  cachedItems: WritableSignal<Entity[]> = signal([]);
  itemsIds = new Set<string>();

  getEntityById(entityId: string): Observable<Entity> {
    if (this.itemsIds.has(entityId)) {
      return of(this.cachedItems().find((t) => t.id === entityId));
    } else {
      return this.httpClient.get<Entity>(`${this.environment.api.url}/entities/${entityId}`).pipe(
        tap((item) => {
          this.itemsIds.add(item.id);
          this.cachedItems.update((cachedItems) => [...cachedItems, item]);
        })
      );
    }
  }
}
