<div class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon svgIcon="device"></mat-icon>
  @if (device().name) {
    <div class="solo">{{ device().name }}</div>
  }
  @if (device().type) {
    <div class="sola">{{ device().type.family }} {{ device().type.model }}</div>
  }
  <mat-icon (click)="onClick()" class="breadcrumb-item-close-button">close</mat-icon>
</div>
