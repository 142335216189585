<div class="event-timeline-log">
  <span class="event-timeline-log-status">{{ 'EVENTS.TIMELINE.CURRENT_STATUS.' + log.currentStatus.toUpperCase() | translate }}</span>
  <span class="event-timeline-log-name">{{ log.user.name }}</span>
  <span class="event-timeline-log-date">{{ log.datetime | dateFormat }}</span>
  @if (log.user.id) {
    <span class="event-timeline-log-photo"><mat-icon>person</mat-icon></span>
  } @else {
    <span class="event-timeline-log-photo"><mat-icon>wifi_tethering</mat-icon></span>
  }
</div>
