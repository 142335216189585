<div fxLayout="row wrap" fxLayoutGap="4px">
  @for (contact of selectedItem.expandedContacts; track contact.id) {
    <div
      class="related-contact-chip"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      [ngClass]="{ warning: contact['incorrectEmailSetting'] || contact['incorrectSmsSetting'] }"
    >
      <span [ngClass]="{ 'fw-800': contact.id === currentContact?.id }">{{ contact.firstname }}</span>
      <span [ngClass]="{ 'fw-800': contact.id === currentContact?.id }">{{ contact.lastname }}</span>
      @if (contact.notificationSupports.email) {
        <mat-icon
          [ngClass]="{ warning: contact.incorrectEmailSetting }"
          [matTooltip]="(contact.incorrectEmailSetting ? 'SITES.NOTIFICATIONS_TAB.INVALID_EMAIL' : 'IOT_DICTIONARY.EMAIL') | translate"
          [matTooltipClass]="contact.incorrectEmailSetting ? 'warning-tooltip' : 'regular-tooltip capitalize-first-letter'"
          >mail
        </mat-icon>
      }
      @if (contact.notificationSupports['sms']) {
        <mat-icon
          [ngClass]="{ warning: contact['incorrectSmsSetting'] }"
          [matTooltip]="(contact['incorrectSmsSetting'] ? 'SITES.NOTIFICATIONS_TAB.INVALID_PHONE_NUMBER' : 'IOT_DICTIONARY.SMS') | translate"
          [matTooltipClass]="contact['incorrectSmsSetting'] ? 'warning-tooltip' : 'regular-tooltip uppercase'"
          >sms
        </mat-icon>
      }
    </div>
  } @empty {
    <span>{{ 'SITES.NOTIFICATIONS_TAB.NO_CONTACT' | translate }}</span>
  }
</div>
