@if (withCounter()) {
  <div class="counter">
    {{ nbSelectedLabel() ?? 'GRAPH.CREATE_FORM.DEFAULT_VARIABLE_COUNTER_MESSAGE' | translate: { count: control()?.value?.length ?? 0 } }}
  </div>
}
@if (withDragAndDrop()) {
  <div
    cdkDropList
    fxLayout="column"
    class="dd-container"
    (cdkDropListDropped)="drop($event)"
    [class.empty]="!control()?.value?.length"
    [cdkDropListDisabled]="disabled()"
  >
    @if (variablesLoading()) {
      <mat-progress-spinner style="margin: auto" mode="indeterminate" color="accent" [diameter]="26"></mat-progress-spinner>
    } @else {
      @for (selectedVar of control()?.value; track selectedVar.id) {
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="dd-item"
          [ngClass]="{ withDragAndDrop: !disabled() }"
          [class.highlightFirstItem]="highlightFirstItem()"
          cdkDrag
        >
          <ng-container *ngTemplateOutlet="listitem; context: { $implicit: selectedVar }"></ng-container>
        </div>
      } @empty {
        <div class="empty-message" fxLayoutAlign="center center">
          {{ emptyMessage() ?? 'GRAPH.CREATE_FORM.DEFAULT_NO_SELECTED_VARIABLE_MESSAGE' | translate }}
        </div>
      }
    }
  </div>
} @else {
  <div fxLayout="column" class="dd-container" [class.empty]="!control()?.value?.length">
    @if (variablesLoading()) {
      <mat-progress-spinner style="margin: auto" mode="indeterminate" color="accent" [diameter]="26"></mat-progress-spinner>
    } @else {
      <div>
        @for (selectedVar of control()?.value; track selectedVar.id) {
          <div fxLayout="row" fxLayoutAlign="start center" class="dd-item" [class.highlightFirstItem]="highlightFirstItem">
            <ng-container *ngTemplateOutlet="listitem; context: { $implicit: selectedVar }"></ng-container>
          </div>
        }
        @if (!control()?.value?.length) {
          <div class="empty-message" fxLayoutAlign="center center">
            {{ emptyMessage() ?? 'GRAPH.CREATE_FORM.DEFAULT_NO_SELECTED_VARIABLE_MESSAGE' | translate }}
          </div>
        }
      </div>
    }
  </div>
}

<ng-template #listitem let-selectedVar>
  <div fxFlex fxLayout="column">
    <span data-test="item-name">{{ selectedVar.name }} - {{ selectedVar.asset?.name }}</span>
    @if (selectedVar?.lastValue) {
      <span fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>{{ selectedVar?.linked ? 'link' : selectedVar.formula?.model === 'constant' ? 'swap_horiz' : 'link_off' }}</mat-icon> &nbsp; &nbsp;
        <span>{{ selectedVar | valueUnitFormat }}</span>
        &nbsp;-
        {{ selectedVar?.lastValue?.datetime | dateFormat }}
      </span>
    }
  </div>
  @if (canDeleteVariables() && !disabled()) {
    <button type="button" mat-icon-button data-test="delete-item" (click)="removeSelectedVariable(selectedVar)">
      <mat-icon>delete</mat-icon>
    </button>
  }
</ng-template>
