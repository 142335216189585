import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { Filter, Pagination, PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { Calendar, EscalationProtocol, EscalationProtocolCreationModel, EscalationProtocolLevel, TeamPlanning, Topic } from '@iot-platform/models/ocm';

import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EscalationProtocolsService {
  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  getAll(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<any>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}`, { params }).pipe(
      map((response) => ({
        data: response.content,
        currentPage: response.page.curPage,
        limit: response.page.limit,
        hasMore: response.page.hasMore,
        total: response.page.total,
        maxPage: response.page.maxPage
      }))
    );
  }

  getEscalationProtocolById(protocolId: string): Observable<EscalationProtocol> {
    return this.httpClient.get<EscalationProtocol>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}`);
  }

  saveEscalationProtocol(protocolToAdd: EscalationProtocolCreationModel): Observable<EscalationProtocol> {
    return this.httpClient.post<EscalationProtocol>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}`, protocolToAdd);
  }

  updateEscalationProtocol(protocolToUpdate: EscalationProtocol): Observable<EscalationProtocol> {
    return this.httpClient.put<EscalationProtocol>(
      `${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolToUpdate.id}`,
      protocolToUpdate
    );
  }

  deleteEscalationProtocol(protocolToDelete: EscalationProtocol): Observable<EscalationProtocol> {
    return this.httpClient
      .delete<EscalationProtocol>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolToDelete.id}`)
      .pipe(map(() => protocolToDelete));
  }

  getEscalationProtocolLevels(protocolId: string): Observable<EscalationProtocolLevel[]> {
    return this.httpClient.get<EscalationProtocolLevel[]>(
      `${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}${this.environment.api.endpoints.levels}`
    );
  }

  addEscalationProtocolLevel(level: EscalationProtocolLevel, protocolId: string): Observable<EscalationProtocolLevel> {
    return this.httpClient.post<EscalationProtocolLevel>(
      `${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}${this.environment.api.endpoints.levels}`,
      level
    );
  }

  updateEscalationProtocolLevel(level: EscalationProtocolLevel, protocolId: string): Observable<EscalationProtocolLevel> {
    return this.httpClient.put<EscalationProtocolLevel>(
      `${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}${this.environment.api.endpoints.levels}/${level.id}`,
      level
    );
  }

  updateEscalationProtocolLevelsOrder(levelsToUpdate: EscalationProtocolLevel[], protocolId: string): Observable<EscalationProtocolLevel[]> {
    if (levelsToUpdate.length) {
      return forkJoin(levelsToUpdate.map((l) => this.updateEscalationProtocolLevel(l, protocolId)));
    } else {
      return of([]);
    }
  }

  deleteEscalationProtocolLevelThenUpdateLevels(
    level: EscalationProtocolLevel,
    protocolId: string,
    levelsToUpdate: EscalationProtocolLevel[]
  ): Observable<EscalationProtocolLevel[]> {
    return this.deleteEscalationProtocolLevel(level, protocolId).pipe(switchMap((_) => this.updateEscalationProtocolLevelsOrder(levelsToUpdate, protocolId)));
  }

  deleteEscalationProtocolLevel(level: EscalationProtocolLevel, protocolId: string): Observable<EscalationProtocolLevel> {
    return this.httpClient
      .delete<EscalationProtocolLevel>(
        `${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}${this.environment.api.endpoints.levels}/${level.id}`
      )
      .pipe(map(() => level));
  }

  saveTableState(selectedProtocol: EscalationProtocol): Observable<EscalationProtocol> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ESCALATION_PROTOCOL_TABLE_STATE_KEY, JSON.stringify(selectedProtocol));
    return of(selectedProtocol);
  }

  isProtocolNameUnique(initialName: string, name: string, entityId: string): Observable<boolean> {
    if (initialName.toLowerCase() === name.toLowerCase()) {
      return of(true);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('page', '0');
    params = params.append('name', name);
    params = params.append('entityId', entityId);

    return this.httpClient
      .get<{
        page: Pagination;
        content: EscalationProtocol;
      }>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}`, { params })
      .pipe(map((data: any) => data.content.findIndex((item) => item.name.toLowerCase() === name.toLowerCase()) === -1));
  }

  getCalendarsByEntityId(entityId: string): Observable<Calendar[]> {
    return this.httpClient
      .get<{
        page: any;
        content: Calendar[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}?entityId=${entityId}`)
      .pipe(map((data) => data.content));
  }

  getTeamPlanningsByEntityId(entityId: string): Observable<TeamPlanning[]> {
    return this.httpClient
      .get<{
        page: any;
        content: TeamPlanning[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}?entityId=${entityId}`)
      .pipe(map((data) => data.content));
  }

  getTopicSubscriptionsByProtocolId(protocolId: string): Observable<PlatformResponse> {
    return this.httpClient.get<Topic[]>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}/topics`).pipe(
      map((topics: Topic[]) => ({
        data: topics.sort(SortUtil.sortByName),
        limit: 10,
        maxPage: 0,
        hasMore: false,
        total: topics.length,
        currentPage: 0
      }))
    );
  }

  updateTopicSubscriptions(protocolId: string, topics: Topic[]): Observable<PlatformResponse> {
    return this.httpClient
      .put<Topic[]>(`${this.environment.api.url}${this.environment.api.endpoints.escalationProtocols}/${protocolId}/topics`, { topics })
      .pipe(
        map((newTopics: Topic[]) => ({
          data: newTopics,
          limit: 10,
          maxPage: 0,
          hasMore: false,
          total: newTopics.length,
          currentPage: 0
        }))
      );
  }

  getTopicsByEntityIdWithChildren(entityId: string): Observable<Topic[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('entityId', entityId);
    params = params.set('withChildren', 'true');

    return this.httpClient
      .get<{
        page: any;
        content: Topic[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints.topics}`, { params })
      .pipe(map((data) => data.content));
  }
}
