import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatToolbar } from '@angular/material/toolbar';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { CommonBulkOperationType } from '@iot-platform/models/i4b';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { BulkManageTagDialogService } from './bulk-manage-tag-dialog.service';

interface BulkManageTagDialogData {
  bulkOperation: CommonBulkOperationType;
  concept: string;
  entityId: string;
  selectionTotal: number;
}
@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCardModule,
    TranslateModule,
    NumberFormatPipe,
    MatFormFieldModule,
    MatIcon,
    MatToolbar,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressSpinner,
    MatButtonModule,
    DetailPopupModule
  ],
  selector: 'iot4bos-ui-bulk-manage-tag-dialog',
  templateUrl: './bulk-manage-tag-dialog.component.html',
  styleUrls: ['./bulk-manage-tag-dialog.component.scss']
})
export class BulkManageTagDialogComponent implements OnInit {
  // Injections
  private readonly bulkOperationOnTagsService: BulkManageTagDialogService = inject(BulkManageTagDialogService);
  private readonly fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  public dialogRef: MatDialogRef<BulkManageTagDialogComponent> = inject(MatDialogRef<BulkManageTagDialogComponent>);
  public data: BulkManageTagDialogData = inject(MAT_DIALOG_DATA);
  destroy = inject(DestroyRef);
  categories: WritableSignal<TagCategory[]> = signal([]);
  categoriesLoading: WritableSignal<boolean> = signal(true);
  labels: WritableSignal<TagLabel[]> = signal([]);
  //
  manageTagForm: WritableSignal<UntypedFormGroup> = signal(
    this.fb.group({
      category: [{ value: null, disabled: true }],
      label: [{ value: null, disabled: true }, [Validators.required]]
    })
  );

  get categoryControl() {
    return this.manageTagForm().get('category') as AbstractControl;
  }

  get labelControl() {
    return this.manageTagForm().get('label') as AbstractControl;
  }

  ngOnInit() {
    this.bulkOperationOnTagsService
      .getTagsByConceptAndEntityId(this.data.concept, this.data.entityId)
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: (categories) => {
          this.categories.set(
            categories
              .map((cat) => ({
                ...cat,
                labels: cat.labels?.sort(SortUtil.sortByName)
              }))
              .sort(SortUtil.sortByName)
          );
          this.categoriesLoading.set(false);
          this.categoryControl.enable();
        },
        error: () => this.categoriesLoading.set(false)
      });
  }

  onCategoryChange(event: MatSelectChange): void {
    this.labelControl.enable();
    this.labelControl.reset();
    this.labels.set(event.value.labels);
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.labelControl.value.id);
  }
}
