import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from '../../settings/custom.tokens';
import { GoogleAnalyticsTrackingData, TrackingData } from '../models/tracking-data.model';
import { TrackingProviders } from '../models/tracking-providers.enum';
import { GoogleAnalyticsTrackingTag, TrackingTag } from '../models/tracking-tag.model';
import { AbstractTrackingMapperService } from './abstract-tracking-mapper.service';
import { AbstractTrackingProvider } from './abstract-tracking-provider.service';
import { TRACKING_MAPPERS } from './tracking.tokens';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WindowWithGtag = Window & { gtag: any; dataLayer: object[] };

@Injectable()
export class GoogleAnalyticsService extends AbstractTrackingProvider {
  constructor(
    @Inject(WINDOW_REF) protected readonly window: WindowWithGtag,
    @Inject(TRACKING_MAPPERS) protected readonly mappers: AbstractTrackingMapperService<GoogleAnalyticsTrackingTag, GoogleAnalyticsTrackingData>[]
  ) {
    super(mappers, TrackingProviders.GoogleAnalytics);
  }

  track(tag: TrackingTag, trackingData: TrackingData): void {
    const gtmData: GoogleAnalyticsTrackingTag | undefined = tag?.getGoogleTag?.(trackingData);
    const gtmMappedData = this.mapper.getTrackingDataForProvider(gtmData, trackingData) as GoogleAnalyticsTrackingData;
    if (gtmData) {
      this.window.gtag('event', gtmData.eventName, gtmMappedData);
    }
  }
}
