import { computed, DestroyRef, inject, Injectable, Injector, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { AbstractMasterViewService, FeatureMasterViewEvent } from '@iot-platform/feature/master-view';
import { GridManagerUserPopupComponent, GridsDbActions } from '@iot-platform/grid-engine';
import {
  FavoriteViewFormComponent,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  PopupComponent
} from '@iot-platform/iot-platform-ui';
import {
  CommonGenericModel,
  CommonIndexedPagination,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  UserAccount
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { TrackingEventCategories, TrackingEventLabels } from '@iot-platform/models/i4b';
import { fromUserPreferences, PreferencesActions } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { noop, Observable, of } from 'rxjs';
import { I4BBaseFacade } from '../models/ngrx/i4b-base-facade.model';

@Injectable({
  providedIn: 'root'
})
export abstract class I4bMasterViewService<T extends CommonGenericModel> extends AbstractMasterViewService {
  public readonly dialog: MatDialog = inject(MatDialog);
  // TODO migrate to TrackingFacade
  analytic: AnalyticsService = new AnalyticsService(this.getGAEventName());
  //
  currentFavoriteView = this.masterViewFacade.currentFavoriteView;
  currentFilters = this.masterViewFacade.filters;
  grids = this.masterViewFacade.grids;
  gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> = this.masterViewFacade.gridConfiguration;
  grid = computed(() => this.gridConfiguration()?.currentGrid);
  defaultFilters: Filter[] = [];
  protected readonly store: Store = inject(Store);
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly injector: Injector = inject(Injector);

  protected constructor(protected readonly masterViewFacade: I4BBaseFacade<T, Pagination, Filter>) {
    super();
  }

  abstract getConcept(): string;

  abstract getMasterViewName(): string;

  abstract getGAEventName(): string;

  canUpdateBusinessProfile(): Observable<boolean> {
    return of(this.authFacade.canUpdateBusinessProfile());
  }

  getFavoriteViewsConfiguration(): Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> {
    return this.masterViewFacade.favoriteViewConfiguration$;
  }

  getGridConfiguration(): Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> {
    return this.masterViewFacade.gridConfiguration$;
  }

  getGrids(): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return this.masterViewFacade.grids$;
  }

  getInitialFilters(): Observable<Filter[]> {
    const currentFiltersOnInit: Filter[] = this.masterViewFacade.filters();
    const initialFilters: Filter[] = [...this.defaultFilters];
    currentFiltersOnInit.forEach((cf: Filter) => {
      if (!this.defaultFilters.find((f: Filter) => f.criteriaKey === cf.criteriaKey && f.value === cf.value)) {
        initialFilters.push(cf);
      }
    });
    return of(initialFilters);
  }

  getPermissions(): Observable<{ key: string; value: boolean }[]> {
    return of([]);
  }

  getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null> {
    return of(null);
  }

  getToolbarButtons(): Observable<(IotToolbarDefaultButton | IotToolbarMenuButton)[]> {
    return of([]);
  }

  onBusinessProfileChange(): void {
    noop();
  }

  onMasterViewEngineEvent(_event: FeatureMasterViewEvent): void {
    const event: MasterViewEngineEvent = _event.event as MasterViewEngineEvent;
    switch (event.type) {
      case 'applyFilters':
        this.onApplyFilters(event.options.filters);
        break;
      default:
        break;
    }
  }

  onToolbarEvent(_event: FeatureMasterViewEvent): void {
    const event: IotToolbarEvent = _event.event as IotToolbarEvent;
    switch (event.type) {
      case IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS:
        this.openGridSettings();
        break;
      case IotToolbarDispatchActionType.EXPORT_DATA:
        this.onExportData();
        break;
      case IotToolbarDispatchActionType.APPLY_GRID:
        this.analytic.log('toolbar_actions', 'select_grid');
        break;
      case IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW:
        this.onApplyFavoriteView(event.options);
        break;
      case IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW:
        this.onCreateFavoriteView();
        break;
      case IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW:
        this.onEditFavoriteView();
        break;
      case IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW:
        this.onDeleteFavoriteView();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
        this.onToggleFilter(_event);
        break;
      default:
        break;
    }
  }

  onApplyFilters(filters: Filter[]): void {
    if (filters.length === 0) {
      this.onApplyFavoriteView(null);
    } else {
      this.masterViewFacade.setFilters(filters);
    }
  }

  onExportData(): void {
    if (this.grid()) {
      this.analytic.log('toolbar_actions', 'export_data', `Export data : ${(this.grid()?.data.response.pagination as CommonIndexedPagination).total} elements`);
      (this.grid() as I4BGrid<I4BGridOptions, I4BGridData>).export({
        filters: this.currentFilters(),
        totalElements: (this.grid()?.data.response.pagination as CommonIndexedPagination).total
      });
    }
  }

  openGridSettings() {
    this.toggleRefreshActivated(false);
    this.dialog
      .open(GridManagerUserPopupComponent, {
        width: '1350px',
        maxWidth: '1350px',
        disableClose: true,
        data: { grid: { ...this.grid(), data: null } }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: { action: string; grid: any }) => {
        this.toggleRefreshActivated(true);
        if (!!value) {
          if (value.action === 'DELETE') {
            this.analytic.log('grid_actions', 'delete_grid');
            this.store.dispatch(
              GridsDbActions.removeGrid({
                toRemove: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters() }
                }
              })
            );
          }
          if (value.action === 'UPDATE') {
            this.analytic.log('grid_actions', 'udpate_grid');
            this.store.dispatch(
              GridsDbActions.updateGrid({
                toUpdate: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters() }
                }
              })
            );
          }
          if (value.action === 'ADD') {
            this.analytic.log('grid_actions', 'add_grid');
            this.store.dispatch(
              GridsDbActions.addGrid({
                toAdd: {
                  ...value.grid,
                  gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters() }
                }
              })
            );
          }
        }
      });
  }

  onApplyFavoriteView(favoriteView: FavoriteView) {
    this.masterViewFacade.setFavoriteView(favoriteView, this.getMasterViewName());
    if (favoriteView) {
      this.selectGridAndLoadData(favoriteView.gridId as string, favoriteView.masterView as string, this.currentFilters());
    } else {
      this.selectDefaultGrid();
    }
  }

  onCreateFavoriteView() {
    const favoriteView: FavoriteView = {};
    favoriteView.masterView = this.getMasterViewName();
    favoriteView.concept = this.getConcept();
    favoriteView.filters = this.currentFilters();

    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView, canUpdateBusinessProfile: this.authFacade.canUpdateBusinessProfile(), grids: this.grids() },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.masterViewFacade.addFavoriteView(response);
          this.analytic.log('toolbar_actions', 'add_favorite_view');
        }
      });
  }

  onEditFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_edit_favorite_view');
    const fv: FavoriteView = { ...this.currentFavoriteView() };
    fv.filters = [...this.currentFilters()];
    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: {
        favoriteView: fv,
        canUpdateBusinessProfile: this.authFacade.canUpdateBusinessProfile(),
        grids: this.grids()
      },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.masterViewFacade.updateFavoriteView(response);
          this.analytic.log('toolbar_actions', 'update_favorite_view');
        }
      });
  }

  onDeleteFavoriteView() {
    this.analytic.log('toolbar_actions', 'open_delete_favorite_view');
    this.toggleRefreshActivated(false);
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: this.currentFavoriteView().name }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: boolean) => {
        this.toggleRefreshActivated(true);
        if (response) {
          this.masterViewFacade.deleteFavoriteView(this.currentFavoriteView());
          this.clearAppliedFilters();
          this.analytic.log('toolbar_actions', 'delete_favorite_view');
        }
      });
  }

  clearAppliedFilters() {
    this.masterViewFacade.setFavoriteView(null, this.getMasterViewName());
    this.selectDefaultGrid();
  }

  onToggleFilter({ filterEngineOpened }): void {
    const user: UserAccount = this.store.selectSignal(fromUserPreferences.getCurrentUser)();
    this.analytic.log(
      TrackingEventCategories.TOOLBAR_ACTION,
      filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
    );
    if (user) {
      user.preferences = { ...user.preferences, filterEngineOpenByDefault: filterEngineOpened };
      this.store.dispatch(
        PreferencesActions.saveUserPreferences({
          user,
          preferences: user.preferences
        })
      );
    }
  }

  protected toggleRefreshActivated(refreshActivated: boolean): void {
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated }));
  }

  private selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[]) {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters }));
  }

  private selectDefaultGrid(): void {
    this.selectGridAndLoadData('default', this.getMasterViewName(), this.currentFilters());
  }
}
