import { Role } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const addRoleToBusinessProfile = createAction(
  '[Admin Business Profile/Roles Dialog] Add Role To Business Profile',
  props<{ businessProfileId: string; roleToAdd: Role }>()
);
export const removeRoleFromBusinessProfile = createAction(
  '[Admin Business Profile/Roles Dialog] Remove Role From Business Profile',
  props<{ businessProfileId: string; roleToRemove: Role }>()
);
