<h2
  [ngClass]="{ 'login-regular-title': !isI4BLogin(), 'layout-login-regular-title': isI4BLogin() }">{{ 'LOGIN.TITLE.GET_CODE' | translate }}</h2>

@if (!isI4BLogin()) {
  <p class="login-get-code-message">{{ 'LOGIN.HELP_MESSAGE.GET_CODE' | translate }}</p>
}

<form [formGroup]="form" [ngClass]="{ 'login-form-i4b': isI4BLogin() }" fxLayout="column">
  <mat-form-field color="accent" fxFlex="100%">
    <mat-icon matPrefix>email</mat-icon>
    <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
    <input (input)="onChangeEmail($event.target.value)" formControlName="email" matInput type="email" />
    @if (email.invalid) {
      <mat-error>{{ (email?.errors?.email ? 'FORM.ERROR_MESSAGE.INVALID_EMAIL' : 'LOGIN.ERROR_MESSAGE.EMAIL') | translate }}</mat-error>
    }
  </mat-form-field>

  <div [class.error]="hasError()" class="layout-login-error">
    <em>{{ 'LOGIN.ERROR_MESSAGE.INCORRECT_EMAIL' | translate }}</em>
    <span>{{ hasError()?.message }}</span>
  </div>
  @if (isI4BLogin()) {
    <p class="login-get-code-message-i4b">{{ 'LOGIN.HELP_MESSAGE.GET_CODE' | translate }}</p>
  }

  <div [ngClass]="{ 'layout-form-actions': isI4BLogin(), 'layout-login-form-actions': !isI4BLogin() }">
    <button (click)="onCancel()" class="layout-login-button" mat-button
            type="button">{{ 'LOGIN.BUTTON.CANCEL' | translate }}
    </button>
    <button (click)="onSendCode()" [disabled]="!form.valid" class="layout-login-button layout-login-button--submit"
            mat-raised-button type="submit">
      {{ 'LOGIN.BUTTON.SEND_CODE' | translate }}
    </button>
  </div>
</form>
