import { Injectable } from '@angular/core';

import { PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { DeviceEvent } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';
import { UserPreferencesService } from '@iot-platform/users';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DeviceEventsService } from '../../../../../../../shared/src/lib/services/device-events.service';

import { DeviceEventsByDeviceDbActions, DeviceEventsByDeviceUiActions, DeviceEventsBySiteDbActions, DeviceEventsBySiteUiActions } from '../actions';
import * as fromNavigation from '../reducers';

@Injectable()
export class DeviceEventsBySiteEffects {
  loadDeviceEventsBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.loadDeviceEventsBySite),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getDeviceEventsBySitePreviousSiteId)))),
      switchMap(([action, previousSiteId]) => {
        let request: PlatformRequest;
        if (!previousSiteId || previousSiteId === action.request.filters.find((filter) => filter.criteriaKey === 'siteId').value) {
          request = { ...action.request };
        }
        if (previousSiteId !== action.request.filters?.find((filter) => filter.criteriaKey === 'siteId')?.value) {
          request = { ...action.request, page: 0 };
        }
        return this.deviceEventsService.getDeviceEvents(request).pipe(
          map((response: PlatformResponse) =>
            DeviceEventsBySiteDbActions.loadDeviceEventsBySiteSuccess({
              response,
              siteId: action.request.filters.find((filter) => filter.criteriaKey === 'siteId').value
            })
          ),
          catchError((error) => of(DeviceEventsBySiteDbActions.loadDeviceEventsBySiteFailure({ error })))
        );
      })
    )
  );

  loadTotalActiveDeviceEventsBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.loadTotalActiveDeviceEventsBySite),
      switchMap((action) =>
        this.deviceEventsService.getDeviceEvents(action.request).pipe(
          map((response) => DeviceEventsBySiteDbActions.loadTotalActiveDeviceEventsBySiteSuccess({ totalActiveEvents: response.total })),
          catchError((error) => of(DeviceEventsBySiteDbActions.loadTotalActiveAssetEventsBySiteFailure({ error })))
        )
      )
    )
  );

  loadDeviceEventsBySiteAndLoadTotalActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.loadDeviceEventsBySite),
      map((action) => {
        const newRequest: PlatformRequest = {
          ...action.request,
          limit: 0,
          page: 0,
          filters: [...action.request.filters, { criteriaKey: 'eventStatus', value: 'active' }]
        };
        return DeviceEventsBySiteUiActions.loadTotalActiveDeviceEventsBySite({ request: newRequest });
      })
    )
  );

  updateStatusByDeviceEventIdBySiteThenLoadTotalActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getDeviceEventsBySitePreviousSiteId)))),
      map(([_, previousSiteId]) => {
        const newRequest: PlatformRequest = {
          limit: 0,
          page: 0,
          filters: [
            { criteriaKey: 'eventStatus', value: 'active' },
            { criteriaKey: 'siteId', value: previousSiteId }
          ]
        };
        return DeviceEventsBySiteUiActions.loadTotalActiveDeviceEventsBySite({ request: newRequest });
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.updateStatusByDeviceEventIdBySite),
      concatMap((action) =>
        this.deviceEventsService.putStatus(action.status).pipe(
          map((deviceEvent: DeviceEvent) => DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess({ deviceEvent })),
          catchError((error) => of(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.bulkUpdateStatusByDeviceEventIdBySite),
      concatMap((action) => this.deviceEventsService.bulkUpdateStatus(action.deviceEventIds, action.status)),
      mergeMap((results) =>
        results.pipe(
          map((deviceEvent) => DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess({ deviceEvent })),
          catchError((error) => of(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.saveTableStateBySite),
      switchMap((action) =>
        this.deviceEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: DeviceEvent; checked: DeviceEvent[] }) =>
            DeviceEventsBySiteDbActions.saveTableBySiteStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(DeviceEventsBySiteDbActions.saveTableBySiteStateFailure({ error })))
        )
      )
    )
  );

  loadMvDeviceEventsBySiteSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteUiActions.loadMvDeviceEventsBySiteSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => DeviceEventsBySiteDbActions.loadMvDeviceEventsBySiteSettingsSuccess({ settings })),
          catchError((error) => of(DeviceEventsBySiteDbActions.loadMvDeviceEventsBySiteSettingsFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsBySiteDbActions.saveTableBySiteStateFailure,
          DeviceEventsBySiteDbActions.loadDeviceEventsBySiteFailure,
          DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteFailure,
          DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsBySiteUiActions.loadDeviceEventsBySite,
          DeviceEventsBySiteUiActions.updateStatusByDeviceEventIdBySite,
          DeviceEventsByDeviceUiActions.loadMvDeviceEventsByDeviceSettings
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsBySiteDbActions.loadDeviceEventsBySiteSuccess,
          DeviceEventsBySiteDbActions.loadDeviceEventsBySiteFailure,
          DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess,
          DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteFailure,
          DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsSuccess,
          DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private deviceEventsService: DeviceEventsService,
    private notificationService: NotificationService,
    private userPrefService: UserPreferencesService,
    private store: Store
  ) {}
}
