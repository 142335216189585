import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { Entity, ProductCatalog } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-ui-backoffice-catalog-editor-form',
  templateUrl: './catalog-editor-form.component.html',
  styleUrls: ['./catalog-editor-form.component.scss']
})
export class CatalogEditorFormComponent implements OnInit, OnChanges {
  @Input() mode: string;
  @Input() catalog: ProductCatalog;
  @Input() entityList: Entity[];

  @Output() changeValue: EventEmitter<boolean> = new EventEmitter();
  @Output() save: EventEmitter<ProductCatalog> = new EventEmitter();
  @Output() remove: EventEmitter<ProductCatalog> = new EventEmitter();

  catalogForm: UntypedFormGroup;

  maximumEntitiesPerCatalog = 5;
  nameMaximumLength = 20;

  attachedEntitiesVisible: Entity[];
  attachedEntitiesNotVisible: Entity[];

  initialCatalogName: string;

  constructor(private dialog: MatDialog) {}

  get name(): AbstractControl {
    return this.catalogForm.get('name');
  }

  get entities(): AbstractControl {
    return this.catalogForm.get('entities');
  }

  ngOnInit(): void {
    this.buildForm();
    this.catalogForm.valueChanges.subscribe(() => this.changeValue.emit(false));

    if (this.catalog) {
      this.initialCatalogName = this.catalog.name;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('entityList') && changes.entityList.currentValue && this.catalog && this.catalogForm) {
      this.setAttachedEntities();
      this.catalogForm.controls.entities.patchValue([...this.attachedEntitiesNotVisible, ...this.attachedEntitiesVisible]);
      this.changeValue.emit(true);
    }

    if (changes.hasOwnProperty('catalog') && changes.catalog.currentValue && this.entityList) {
      this.setAttachedEntities();
      this.buildForm();
    }
  }

  setAttachedEntities(): void {
    this.attachedEntitiesVisible = this.entityList.filter((entity) => this.catalog.entities.find((e) => e.id === entity.id));
    this.attachedEntitiesNotVisible = this.catalog.entities.filter((entity) => !this.attachedEntitiesVisible.find((e) => e.id === entity.id));
  }

  getEntitiesFormatted() {
    return this.entities.value.map((entity) => entity.name).join(', ');
  }

  disableEntity(entity): boolean {
    if (this.entities.value && this.attachedEntitiesNotVisible && entity) {
      return (
        (!this.entities.value.find((e) => e.id === entity.id) && this.entities.value.length >= this.maximumEntitiesPerCatalog) ||
        !!this.attachedEntitiesNotVisible.find((e) => e.id === entity.id)
      );
    }
  }

  onRemove(catalog: ProductCatalog) {
    this.confirmDeletion(catalog);
  }

  onSave(catalog: ProductCatalog) {
    const newCatalog: ProductCatalog = {
      ...catalog,
      name: this.name.value.trim(),
      entities: [...this.entities.value]
    };
    this.save.emit(newCatalog);

    if (this.mode === 'ADD') {
      this.catalogForm.clearValidators();
      this.catalog = { ...this.catalog, name: '', entities: [] };
      this.buildForm();
    }
  }

  private buildForm() {
    this.catalogForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.catalog.name, [Validators.required, Validators.maxLength(this.nameMaximumLength)], []),
      entities: new UntypedFormControl(
        this.attachedEntitiesNotVisible && this.attachedEntitiesVisible ? [...this.attachedEntitiesNotVisible, ...this.attachedEntitiesVisible] : [],
        [Validators.required, Validators.maxLength(this.maximumEntitiesPerCatalog)]
      )
    });
  }

  private confirmDeletion(catalog: ProductCatalog) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: catalog.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.remove.emit(catalog);
      }
    });
  }
}
