import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'grid-engine-cyclical-call-fault-cell',
  templateUrl: './cyclical-call-fault-cell.component.html',
  imports: [FlexLayoutModule, TranslateModule, MatTooltip, DateFormatPipe, MatIcon],
  providers: [DateFormatPipe],
  styleUrls: ['./cyclical-call-fault-cell.component.scss']
})
export class CyclicalCallFaultCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<ICellRendererParams> = signal(null);

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
