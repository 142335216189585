import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { CommonApiListResponse, Environment, TagCategory } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BulkManageTagDialogService {
  constructor(
    @Inject('environment') private environment: Environment,
    public httpClient: HttpClient
  ) {}

  getTagsByConceptAndEntityId(concept: string, entityId: string): Observable<TagCategory[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('concept', concept.toUpperCase());
    params = params.set('withParents', true);
    params = params.set('entityId', entityId);

    return this.httpClient
      .get<CommonApiListResponse<TagCategory>>(`${this.environment.api.url}${this.environment.api.endpoints.tags}`, { params })
      .pipe(map((response) => response.content));
  }
}
