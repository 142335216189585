import { Log } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsByDeviceLogsDbActions, DeviceEventsByDeviceLogsUiActions, NavigationActions } from '../../actions';

export const deviceEventsByDeviceLogsDbFeatureKey = 'deviceEventsByDeviceLogsDb';

export interface State extends EntityState<Log> {
  selectedLogId: string | null;
  error?: any;
  log?: Log;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (log: Log) => log.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedLogId: null,
  error: null,
  log: null
});

const deviceEventsByDeviceLogsDbReducer = createReducer(
  initialState,
  on(NavigationActions.clearData, (): State => initialState),

  on(DeviceEventsByDeviceLogsUiActions.loadLogsByDeviceEvent, (state: State): State => ({ ...state })),
  on(DeviceEventsByDeviceLogsDbActions.loadLogsByDeviceEventSuccess, (state: State, { logs }): State => adapter.setAll(logs, state)),
  on(DeviceEventsByDeviceLogsDbActions.loadLogsByDeviceEventFailure, (state: State, { error }): State => ({ ...state, error })),
  // ****
  on(DeviceEventsByDeviceLogsDbActions.createLogByDeviceEventSuccess, (state: State, { log }): State => adapter.addOne(log, { ...state, log })),
  on(DeviceEventsByDeviceLogsDbActions.createLogByDeviceEventFailure, (state: State, { error }): State => ({ ...state, error }))
  // ****
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByDeviceLogsDbReducer(state, action);
}

export const getLog = (state: State) => state.log;
