import { inject, Injectable } from '@angular/core';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';
import { FollowedVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import * as moment from 'moment';
import { IotMapDisplayMode } from '../models/iot-map-display-mode.model';
import { IotMapMarkerPopup, IotMapMarkerPopupRawData } from '../models/iot-map-marker-popup.model';

@Injectable()
export class MapPopupService {
  translateService: TranslateService = inject(TranslateService);
  datePipe: DateFormatPipe = inject(DateFormatPipe);
  numberFormat: NumberFormatPipe = inject(NumberFormatPipe);

  getPopup(concept: string, displayMode: IotMapDisplayMode): IotMapMarkerPopup {
    switch (concept) {
      case 'assets':
        switch (displayMode) {
          case 'assetVariable':
            return new IotMapMarkerPopup()
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.siteName')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.asset'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.name')
              )
              .addTemplateRow('Variable', (rawData: IotMapMarkerPopupRawData) => {
                const isMultiStateVariable = this.isUnitMultipleState(get(rawData, 'feature.options.unit'));
                if (isMultiStateVariable) {
                  const toDisplay = ProcessMultipleStateVariablesUtil.getProcessedUnitForFollowedVariable({
                    unit: get(rawData, 'feature.options.unit'),
                    lastValue: {
                      value: get(rawData, 'feature.options.value'),
                      datetime: get(rawData, 'feature.options.datetime')
                    }
                  } as FollowedVariable);
                  return toDisplay;
                } else {
                  const d = this.numberFormat.transform(get(rawData, 'feature.options.value'));
                  return (d ? d : '-') + ' ' + get(rawData, 'feature.options.unit');
                }
              })
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.date'), (rawData: IotMapMarkerPopupRawData) =>
                this.datePipe.transform(get(rawData, 'feature.options.datetime'))
              );
          default:
            return new IotMapMarkerPopup()
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.siteName')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.asset'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.name')
              );
        }
      case 'sites': {
        switch (displayMode) {
          case 'assetsDevices':
            return new IotMapMarkerPopup()
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.name')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.assets'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.options.assets')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.devices'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.options.devices')
              );
          case 'activeEvents':
            return new IotMapMarkerPopup()
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.name')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.asset-events'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.options.assetActiveEvents')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.device-events'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.options.deviceActiveEvents')
              );
          case 'matchScore':
            return new IotMapMarkerPopup()
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
                get(rawData, 'feature.properties.name')
              )
              .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.matchScore'), (rawData: IotMapMarkerPopupRawData) => {
                let d = '-';
                if (get(rawData, 'feature.options.matchScore')) {
                  d = this.numberFormat.transform(100 * get(rawData, 'feature.options.matchScore'));
                  return (d ? d : '-') + ' ' + '%';
                }
                return d;
              });

          default:
            return new IotMapMarkerPopup().addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
              get(rawData, 'feature.properties.name')
            );
        }
      }
      case 'devices':
        return new IotMapMarkerPopup()
          .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
            get(rawData, 'feature.properties.siteName')
          )
          .addTemplateRow(this.translateService.instant('IOT_DICTIONARY.device'), (rawData: IotMapMarkerPopupRawData) =>
            get(rawData, 'feature.properties.name')
          );
      default:
        return new IotMapMarkerPopup().addTemplateRow(this.translateService.instant('IOT_DICTIONARY.site'), (rawData: IotMapMarkerPopupRawData) =>
          get(rawData, 'feature.properties.name')
        );
    }
  }

  getPopupRoute(properties: any): any {
    const popup =
      '<div>start: ' +
      this.datePipe.transform(properties.start) +
      '<br>' +
      'end: ' +
      this.datePipe.transform(properties.end) +
      '<br>' +
      'duration: ' +
      moment.duration(properties.durationMs).asHours().toFixed(2) +
      'h</div>';
    // const popup: IotMapMarkerPopup = new IotMapMarkerPopup().addTemplateRow('toto', (rawData: IotMapMarkerPopupRawData) => properties['start']);
    return popup;
  }

  private isUnitMultipleState(unit?: string): boolean {
    return !!(unit && unit[0] === '/');
  }
}
