import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';

import { ProductCatalogsDbActions, ProductCatalogsUiActions } from '../actions';

export const productCatalogsUiFeatureKey = 'productCatalogsUi';

export interface State {
  catalogsLoaded: boolean;
  catalogsLoading: boolean;
  error?: any;
}

export const initialState: State = {
  catalogsLoaded: false,
  catalogsLoading: false,
  error: null
};

const productCatalogsUiReducer = createReducer(
  initialState,
  on(ProductCatalogsUiActions.listProductCatalogs, (state: State) => ({ ...state, catalogsLoading: true, catalogsLoaded: false })),
  on(ProductCatalogsDbActions.listProductCatalogsSuccess, (state: State) => ({ ...state, catalogsLoading: false, catalogsLoaded: true })),
  on(ProductCatalogsDbActions.listProductCatalogsFailure, (state: State, { error }) => ({ ...state, error, catalogsLoaded: false, catalogsLoading: false })),
  //
  on(ProductCatalogsUiActions.addProductCatalog, (state: State) => ({ ...state, catalogsLoading: true, catalogsLoaded: false })),
  on(ProductCatalogsDbActions.addProductCatalogSuccess, (state: State) => ({ ...state, catalogsLoading: false, catalogsLoaded: true })),
  on(ProductCatalogsDbActions.addProductCatalogFailure, (state: State, { error }) => ({ ...state, error, catalogsLoaded: false, catalogsLoading: false })),
  //
  on(ProductCatalogsUiActions.updateProductCatalog, (state: State) => ({ ...state, catalogsLoading: true, catalogsLoaded: false })),
  on(ProductCatalogsDbActions.updateProductCatalogSuccess, (state: State) => ({ ...state, catalogsLoading: false, catalogsLoaded: true })),
  on(ProductCatalogsDbActions.updateProductCatalogFailure, (state: State, { error }) => ({ ...state, error, catalogsLoaded: false, catalogsLoading: false })),
  //
  on(ProductCatalogsUiActions.deleteProductCatalog, (state: State) => ({ ...state, catalogsLoading: true, catalogsLoaded: false })),
  on(ProductCatalogsDbActions.deleteProductCatalogSuccess, (state: State) => ({ ...state, catalogsLoading: false, catalogsLoaded: true })),
  on(ProductCatalogsDbActions.deleteProductCatalogFailure, (state: State, { error }) => ({ ...state, error, catalogsLoaded: false, catalogsLoading: false })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return productCatalogsUiReducer(state, action);
}

export const getCatalogsLoaded = (state: State) => state.catalogsLoaded;
export const getCatalogsLoading = (state: State) => state.catalogsLoading;
