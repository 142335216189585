import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardLoaderModule, DefaultCardComponent, IconModule } from '@iot-platform/iot-platform-ui';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { SharedModule } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { AssetDeviceAssociationItemFactory } from './asset-device-association-item/asset-device-association-item-factory';
import { AssetDeviceAssociationItemComponent } from './asset-device-association-item/asset-device-association-item.component';
import { AssetDeviceAssociationItemDirective } from './asset-device-association-item/asset-device-association-item.directive';
import { AssetDeviceAssociationsComponent } from './asset-device-associations.component';
import { AssociationByAssetComponent } from './association-by-asset/association-by-asset.component';
import { AssociationByDeviceComponent } from './association-by-device/association-by-device.component';
import { AssociationAssetCardComponent } from './cards/association-asset-card/association-asset-card.component';
import { AssociationDeviceCardComponent } from './cards/association-device-card/association-device-card.component';

@NgModule({
  imports: [CommonModule, SharedModule, IconModule, TranslateModule, DefaultCardComponent, CardLoaderModule, InfoDisplayPipe],
  declarations: [
    AssetDeviceAssociationsComponent,
    AssetDeviceAssociationItemDirective,
    AssetDeviceAssociationItemComponent,
    AssociationByAssetComponent,
    AssociationByDeviceComponent,
    AssociationAssetCardComponent,
    AssociationDeviceCardComponent
  ],
  providers: [AssetDeviceAssociationItemFactory],
  exports: [AssetDeviceAssociationsComponent, AssetDeviceAssociationItemComponent]
})
export class AssetDeviceAssociationsModule {}
