import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Filter, Pagination } from '@iot-platform/models/common';
import { Topic } from '@iot-platform/models/ocm';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TopicsDbActions, TopicsUiActions } from '../actions';
//
export const topicsDbFeatureKey = 'topicsDb';

export interface State extends EntityState<Topic> {
  selectedTopicId: string;
  pagination: Pagination;
  addedTopicForConfig: Topic;
  currentFilters: Filter[];
  error: any;
}

export const adapter: EntityAdapter<Topic> = createEntityAdapter<Topic>({
  selectId: (topic: Topic) => topic.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedTopicId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 100 },
  addedTopicForConfig: null,
  currentFilters: [],
  error: null
});

export const topicDbReducer = createReducer(
  initialState,
  on(TopicsUiActions.listTopics, (state) => ({ ...state, addedTopicForConfig: null })),
  on(TopicsDbActions.listTopicsSuccess, (state, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    })
  ),
  on(TopicsDbActions.listTopicsFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsUiActions.selectTopic, (state, { selectedTopic }) => ({ ...state, selectedTopicId: selectedTopic.id })),
  //
  on(TopicsDbActions.getTopicByIdSuccess, (state, { selectedTopic }) => adapter.upsertOne(selectedTopic, { ...state, selectedTopicId: selectedTopic.id })),
  on(TopicsDbActions.getTopicByIdFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsDbActions.addTopicSuccess, (state, { addedTopic }) =>
    adapter.addOne(addedTopic, { ...state, pagination: { ...state.pagination, total: state.pagination.total + 1 }, selectedTopicId: addedTopic.id })
  ),
  on(TopicsDbActions.addTopicFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsDbActions.addTopicThenConfigureSuccess, (state, { addedTopic }) =>
    adapter.addOne(addedTopic, {
      ...state,
      pagination: { ...state.pagination, total: state.pagination.total + 1 },
      selectedTopicId: addedTopic.id,
      addedTopicForConfig: addedTopic
    })
  ),
  on(TopicsDbActions.addTopicThenConfigureFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsUiActions.cancelAddTopicThenConfigure, (state) => ({ ...state, addedTopicForConfig: null })),
  //
  on(TopicsDbActions.updateTopicSuccess, (state, { updatedTopic }) =>
    adapter.updateOne({ id: updatedTopic.id, changes: updatedTopic }, { ...state, addedTopicForConfig: null })
  ),
  on(TopicsDbActions.updateTopicFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsDbActions.subscribeToTopicSuccess, (state, { updatedTopic }) => adapter.updateOne(updatedTopic, state)),
  on(TopicsDbActions.subscribeToTopicFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsDbActions.unsubscribeToTopicSuccess, (state, { updatedTopic }) => adapter.updateOne(updatedTopic, state)),
  on(TopicsDbActions.unsubscribeToTopicFailure, (state, { error }) => ({ ...state, error })),
  //
  on(TopicsDbActions.deleteTopicSuccess, (state, { deletedTopic }) => adapter.removeOne(deletedTopic.id, { ...state, selectedTopicId: null })),
  on(TopicsDbActions.deleteTopicFailure, (state, { error }) => ({ ...state, error })),
  on(TopicsUiActions.saveTopicFilters, (state: State, { filters }) => ({ ...state, currentFilters: filters })),
  on(TopicsUiActions.navigateToTopicDetails, (state, { toNavigateTo }) => ({ ...state, selectedTopicId: toNavigateTo.id })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return topicDbReducer(state, action);
}

export const getSelectedTopicId = (state: State) => state.selectedTopicId;
export const getPagination = (state: State) => state.pagination;
export const getAddedTopicForConfig = (state: State) => state.addedTopicForConfig;
export const getCurrentFilters = (state: State) => state.currentFilters;
