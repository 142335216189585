@if (fabIconButton()) {
  <button
    (click)="clickEvent.emit($event)"
    [color]="buttonColor()"
    [disabled]="disabled()"
    [matTooltip]="tooltip() | translate"
    [ngClass]="cssClasses()"
    class="progress-spinner-button"
    data-cy="iot-platform-ui-progress-bar-button"
    mat-mini-fab
  >
    <mat-icon>{{ icon() }}</mat-icon>
    @if (enableGauge()) {
      <mat-progress-spinner [color]="spinnerColor()" [diameter]="value()" [mode]="mode()" class="progress-spinner-button__gauge"></mat-progress-spinner>
    }
  </button>
} @else {
  <button
    (click)="clickEvent.emit($event)"
    [color]="buttonColor()"
    [disabled]="disabled()"
    [matTooltip]="tooltip() | translate"
    [ngClass]="cssClasses()"
    class="progress-bar-button"
    data-cy="iot-platform-ui-progress-bar-button"
    mat-raised-button
  >
    @if (icon()) {
      <mat-icon>{{ icon() }}</mat-icon>
    }
    @if (enableGauge()) {
      <mat-progress-bar
        [bufferValue]="bufferValue()"
        [color]="gaugeColor()"
        [mode]="mode()"
        [value]="value()"
        class="progress-bar-button-gauge"
      ></mat-progress-bar>
    }
    <span class="progress-bar-button-title">{{ title() }}</span>
  </button>
}
