import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';

import { Asset, AssetEvent, AssetVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, DateFormatPipe, NumberFormatPipe, InfoDisplayPipe, ValueUnitFormatPipe],
  selector: 'iot-platform-ui-detail-asset-variable-card',
  templateUrl: './detail-asset-variable-card.component.html',
  styleUrls: ['./detail-asset-variable-card.component.scss'],
  providers: [DateFormatPipe, NumberFormatPipe, InfoDisplayPipe, ValueUnitFormatPipe]
})
export class DetailAssetVariableCardComponent {
  asset = input<Asset>();
  assetVariable = input<AssetVariable>();
  event = input<AssetEvent>();

  eventVariableProcessedUnit: Signal<string | null> = computed(() => {
    const event = this.event();
    if (event.context?.assetVariable?.unit && event.context?.assetVariable?.unit[0] === '/') {
      const lastRecords =
        event.context.assetVariable.value !== null
          ? [
              {
                value: event.context.assetVariable.value,
                datetime: ''
              }
            ]
          : [];

      return ProcessMultipleStateVariablesUtil.getProcessedUnit({
        ...event.context.assetVariable,
        lastRecords
      } as never);
    }
    return null;
  });
}
