import { Action, createReducer, on } from '@ngrx/store';
import { ExportSpreadsheetsDbActions, ExportSpreadsheetsUiActions } from '../actions';

export const exportSpreadsheetsUiFeatureKey = 'exportsSpreadsheetsUi';

export interface State {
  exportsLoaded: boolean;
  exportsLoading: boolean;
}

export const initialState: State = {
  exportsLoaded: false,
  exportsLoading: false
};

export const exportSpreadsheetsUiReducer = createReducer(
  initialState,
  on(
    ExportSpreadsheetsUiActions.loadExportSpreadsheets,
    ExportSpreadsheetsUiActions.addExportSpreadsheet,
    ExportSpreadsheetsUiActions.updateExportSpreadsheet,
    ExportSpreadsheetsUiActions.deleteExportSpreadsheet,
    ExportSpreadsheetsUiActions.configureContactExportSpreadsheets,
    (state: State) => ({ ...state, exportsLoaded: false, exportsLoading: true })
  ),
  on(
    ExportSpreadsheetsDbActions.loadExportSpreadsheetsSuccess,
    ExportSpreadsheetsDbActions.addExportSpreadsheetSuccess,
    ExportSpreadsheetsDbActions.updateExportSpreadsheetSuccess,
    ExportSpreadsheetsDbActions.deleteExportSpreadsheetSuccess,
    ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsSuccess,
    (state: State) => ({ ...state, exportsLoaded: true, exportsLoading: false })
  ),
  on(
    ExportSpreadsheetsDbActions.loadExportSpreadsheetsFailure,
    ExportSpreadsheetsDbActions.addExportSpreadsheetFailure,
    ExportSpreadsheetsDbActions.updateExportSpreadsheetFailure,
    ExportSpreadsheetsDbActions.deleteExportSpreadsheetFailure,
    ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsFailure,
    (state: State) => ({ ...state, exportsLoaded: false, exportsLoading: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return exportSpreadsheetsUiReducer(state, action);
}

export const getExportSpreadsheetsLoaded = (state: State) => state.exportsLoaded;
export const getExportSpreadsheetsLoading = (state: State) => state.exportsLoading;
