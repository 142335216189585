import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle, MatDatepickerToggleIcon } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'iot4bos-ui-device-call-log-toolbar',
  templateUrl: './device-call-log-toolbar.component.html',
  styleUrls: ['./device-call-log-toolbar.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatIcon,
    MatPrefix,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepickerToggleIcon,
    MatDatepicker,
    MatButton,
    TranslateModule
  ]
})
export class DeviceCallLogToolbarComponent implements OnInit, OnDestroy {
  filterForm: UntypedFormGroup;
  sub: Subscription[] = [];
  minDate: Date;
  maxDate: Date;
  statusList: Set<string>;

  @Input() initialFilters$: Observable<{ statusList: Set<string>; minDate: Date; maxDate: Date }>;

  @Output() applyFilters: EventEmitter<{ status: string; startDate: Date; endDate: Date }> = new EventEmitter<{
    status: string;
    startDate: Date;
    endDate: Date;
  }>();

  get status(): AbstractControl {
    return this.filterForm.get('status');
  }

  get startDate(): AbstractControl {
    return this.filterForm.get('startDate');
  }

  get endDate(): AbstractControl {
    return this.filterForm.get('endDate');
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({
      status: new UntypedFormControl(),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl()
    });

    this.sub.push(
      this.initialFilters$.subscribe((filters: { statusList: Set<string>; minDate: Date; maxDate: Date }) => {
        this.statusList = filters.statusList;
        this.minDate = filters.minDate;
        this.maxDate = filters.maxDate;
      })
    );
  }

  resetFilters() {
    this.filterForm.reset();
    this.onFilterChange();
  }

  onFilterChange(): void {
    this.applyFilters.emit({
      status: this.status.value,
      startDate: this.startDate.value,
      endDate: this.endDate.value
    });
  }

  ngOnDestroy() {
    this.sub.forEach((s: Subscription) => s.unsubscribe());
  }
}
