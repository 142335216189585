<div class="login-password-rules-container" data-cy="iot-platform-login-password-rules">
  <div [class.checked]="!errors()?.format?.size" class="login-password-rules-rule">
    <mat-icon [textContent]="errors()?.format?.size ? 'error' : 'check'" class="login-password-rules-icon" />
    <span>{{ 'LOGIN.HELP_MESSAGE.SIZE' | translate }}</span>
  </div>
  <div [class.checked]="!errors()?.format?.lowercase" class="login-password-rules-rule">
    <mat-icon [textContent]="errors()?.format?.lowercase ? 'error' : 'check'" class="login-password-rules-icon" />
    <span>{{ 'LOGIN.HELP_MESSAGE.LOWERCASE' | translate }}</span>
  </div>
  <div [class.checked]="!errors()?.format?.uppercase" class="login-password-rules-rule">
    <mat-icon [textContent]="errors()?.format?.uppercase ? 'error' : 'check'" class="login-password-rules-icon" />
    <span>{{ 'LOGIN.HELP_MESSAGE.UPPERCASE' | translate }}</span>
  </div>
  <div [class.checked]="!errors()?.format?.number" class="login-password-rules-rule">
    <mat-icon [textContent]="errors()?.format?.number ? 'error' : 'check'" class="login-password-rules-icon" />
    <span>{{ 'LOGIN.HELP_MESSAGE.NUMBER' | translate }}</span>
  </div>
  <div [class.checked]="!errors()?.format?.special" class="login-password-rules-rule">
    <mat-icon [textContent]="errors()?.format?.special ? 'error' : 'check'" class="login-password-rules-icon" />
    <span>{{ 'LOGIN.HELP_MESSAGE.SPECIAL' | translate }}</span>
  </div>
</div>
