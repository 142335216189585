<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ data.title }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" color="accent">
    <grid-engine-grid-page
      style="height: 450px"
      (dispatchGridEvent)="onDispatchGridEvent($event)"
      [grid]="grid()"
      [hidePaginator]="true"
      class="layout-ag-grid-shell"
      fxFlex
    ></grid-engine-grid-page>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="selectedRowIndex() === -1" class="button-regular" color="accent" mat-raised-button>
      {{ 'ASSETS.VARIABLE_FORM.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
