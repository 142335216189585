import { SortUtil } from '@iot-platform/iot-platform-utils';
import { NormalizedAssetVariablesByCategory } from '@iot-platform/models/i4b';

export function sortNormalizedVariablesByCategory(
  normalizedVariablesByCategory: NormalizedAssetVariablesByCategory[],
  category?: string
): NormalizedAssetVariablesByCategory[] {
  normalizedVariablesByCategory.sort(SortUtil.sortByProperty('categoryName'));
  normalizedVariablesByCategory.forEach((element) => element.variables.sort(SortUtil.sortByName));
  const normalizedVariablesForSpecificCategory = normalizedVariablesByCategory.find(
    (element) => element.categoryName.toLowerCase() === category?.toLowerCase()
  );
  if (normalizedVariablesForSpecificCategory) {
    const normalizedVariablesForSpecificCategoryIndex = normalizedVariablesByCategory.indexOf(normalizedVariablesForSpecificCategory);
    const before = normalizedVariablesByCategory.slice(0, normalizedVariablesForSpecificCategoryIndex);
    const after = normalizedVariablesByCategory.slice(normalizedVariablesForSpecificCategoryIndex + 1);
    return [normalizedVariablesForSpecificCategory, ...before, ...after];
  } else {
    return normalizedVariablesByCategory;
  }
}
