import { UpperCasePipe } from '@angular/common';
import { Component, computed, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { WidgetsModule } from '@iot-platform/iot-platform-ui';
import { Graph } from '@iot-platform/models/common';
import { WidgetAction, WidgetEvent, WidgetInstance, WidgetTypes } from '@iot-platform/models/widgets';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'iot-platform-dashboards-graphs-shell',
  templateUrl: './graphs-shell.component.html',
  styleUrls: ['./graphs-shell.component.scss'],
  imports: [MatTooltip, MatIcon, TranslateModule, UpperCasePipe, FlexLayoutModule, MatIconButton, WidgetsModule, DateFormatPipe],
  providers: [DateFormatPipe]
})
export class GraphsShellComponent {
  graphs: Signal<Graph[]> = input.required<Graph[]>();
  enableDragAndDrop: Signal<boolean> = input<boolean>(false);

  addWidget = output<string>();
  openGraph = output<Graph>();
  editGraph = output<Graph>();
  deleteGraph = output<Graph>();
  widgetsOrderChange = output<unknown[]>();

  public widgets: Signal<WidgetInstance[]> = computed(() => {
    const graphs = this.graphs();
    return graphs.map((graph: Graph) => this.buildWidget(graph));
  });

  graphPermissions: Partial<{ create: boolean; read: boolean; update: boolean; delete: boolean }>;

  constructor(private readonly authorizationService: AuthorizationService) {
    this.graphPermissions = {
      create: this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.CREATE),
      update: this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.UPDATE),
      delete: this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.DELETE)
    };
  }

  public onDispatchEvent(event: WidgetEvent): void {
    const {
      action,
      widget: { data: graph }
    } = event;
    switch (action) {
      case WidgetAction.EDIT:
        this.editGraph.emit(graph);
        break;
      case WidgetAction.DELETE:
        this.deleteGraph.emit(graph);
        break;
      case WidgetAction.PREVIEW:
        this.openGraph.emit(graph);
        break;
      default:
        break;
    }
  }

  onWidgetsOrderChange(widgets: WidgetInstance[]): void {
    this.widgetsOrderChange.emit(widgets.map((w) => w.data));
  }

  private buildWidget(graph: Graph): WidgetInstance {
    return new WidgetInstance({
      type: WidgetTypes[graph.type],
      data: graph,
      options: WidgetInstance.getOptionsByType(graph, this.graphPermissions)
    });
  }
}
