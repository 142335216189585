import { I4BColumnOptions } from './i4b-column-options';

export class BasicColumnOptions implements I4BColumnOptions {
  order = 0;
  sortable = true;
  overrideHeaderName: string = null;
  width = 150;

  constructor(customOptions?: Partial<I4BColumnOptions>) {
    if (customOptions) {
      this.order = customOptions.order ? customOptions.order : this.order;
      this.sortable = customOptions.sortable ? customOptions.sortable : this.sortable;
      this.overrideHeaderName = customOptions.overrideHeaderName ?? customOptions.overrideHeaderName;
      this.width = customOptions.width ?? customOptions.width;
    }
  }
}
