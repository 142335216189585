<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">
        {{
          'ADMIN.DIALOG.EDIT_USER.TITLE_' + (data.user.isShared ? 'SHARED' : 'NOT_SHARED')
            | translate: { firstName: data.user.firstname, lastName: data.user.lastname }
        }}
      </h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20">
    <form [formGroup]="userForm" fxFlex fxLayout="column" fxLayoutGap="10px" class="">
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        @if (!data.user.isShared) {
          <mat-form-field fxFlex="30" color="accent">
            <mat-label>{{ 'ADMIN.DIALOG.ADD_USER.FIRST_NAME_PLACEHOLDER' | translate }}</mat-label>
            <input formControlName="firstname" matInput maxlength="30" required />
          </mat-form-field>
        }

        <mat-form-field fxFlex="30" color="accent">
          <mat-label>{{ 'ADMIN.DIALOG.ADD_USER.' + (data.user.isShared ? 'SHARED_ACCOUNT_NAME_PLACEHOLDER' : 'LAST_NAME_PLACEHOLDER') | translate }}</mat-label>
          <input matInput formControlName="lastname" maxlength="30" required />
        </mat-form-field>

        <mat-form-field color="accent" fxFlex="30">
          <mat-label>{{ 'USERS.PROFILE_INFO_FORM.PHONE_NUMBER' | translate }}</mat-label>
          <iot-platform-ui-phone-number-input formControlName="phoneNumber" maxlength="20" />
          <mat-hint align="end">{{ phoneNumberControl.value?.length || 0 }}/20</mat-hint>
          <mat-icon matSuffix matTooltip="{{ 'USERS.PROFILE_INFO_FORM.UI.INFO_MESSAGE.PHONE_FORMAT' | translate }}"> info_outline </mat-icon>
          @if (phoneNumberControl.invalid || phoneNumberControl.errors?.required) {
            <mat-error>
              {{ 'USERS.PROFILE_INFO_FORM.UI.ERRORS.VALID_PHONE_NUMBER' | translate }}
            </mat-error>
          }
        </mat-form-field>

        <mat-form-field fxFlex="30" color="accent">
          <mat-label>{{ 'USERS.PROFILE_INFO_FORM.PRIMARY_EMAIL' | translate }}</mat-label>
          <input formControlName="email" matInput type="email" />
          @if (userForm?.controls?.email?.invalid) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.INVALID_EMAIL' | translate }}</mat-error>
          }
        </mat-form-field>

        <mat-form-field fxFlex="30" color="accent">
          <mat-label>{{ 'USERS.PROFILE_INFO_FORM.SECONDARY_EMAIL' | translate }}</mat-label>
          <input formControlName="secondaryEmail" matInput type="email" />
          @if (secondaryEmailControl.invalid) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.INVALID_EMAIL' | translate }}</mat-error>
          }
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="40px">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="14px">
          <span>{{ 'USERS.PROFILE_PREFERENCES.NOTIFICATIONS' | translate }}</span>

          <div class="user-profile-preferences-notification-checkbox" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            @for (media of mediaOptions; track media.formControlName) {
              <mat-checkbox color="accent" [formControlName]="media.formControlName" ngDefaultControl>
                {{ media.label | translate }}
              </mat-checkbox>
            }
          </div>
        </div>
      </div>

      <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
    <button (click)="save()" [disabled]="isFormDisabled()" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
