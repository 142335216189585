import { Component, DestroyRef, effect, inject, model, output, Signal } from '@angular/core';
import { ColorPickerService } from './color-picker.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatGridListModule } from '@angular/material/grid-list';

@Component({
  standalone: true,
  imports: [MatGridListModule],
  selector: 'iot-platform-ui-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  private readonly colorPickerService: ColorPickerService = inject(ColorPickerService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  selectedColor = model<string>();
  selectColor = output<string>();

  colors: Signal<string[] | undefined> = toSignal(this.colorPickerService.getColorList().pipe(takeUntilDestroyed(this.destroyRef)));
  defaultColor: Signal<string | undefined> = toSignal(this.colorPickerService.getDefaultColor().pipe(takeUntilDestroyed(this.destroyRef)));

  initEffect = effect(
    () => {
      const colors = this.colors();
      const defaultColor = this.defaultColor();

      if (colors && defaultColor) {
        this.initSelectedColor();
      }
    },
    { allowSignalWrites: true }
  );

  onSelectColor(color: string) {
    this.selectedColor.set(color);
    this.selectColor.emit(this.selectedColor() as string);
  }

  private initSelectedColor(): void {
    const colors = this.colors();
    const defaultColor = this.defaultColor();
    const selectedColor = this.selectedColor();

    if (selectedColor && colors?.includes(selectedColor)) {
      this.selectColor.emit(this.selectedColor() as string);
    } else {
      this.onSelectColor(defaultColor as string);
    }
  }
}
