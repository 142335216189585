<section class="full-width organization-concept-section" fxLayout="row" fxLayoutAlign="center start">
  <div fxFlex="300px" fxLayout="column" fxLayoutGap="">
    @for (concept of allConceptsAvailable; track concept) {
      <iot4bos-ui-backoffice-organization-concepts-selection-checkbox
        [concept]="concept"
        [disabled]="readonly"
        (toggleConceptSelection)="onToggleConceptSelection($event)"
      >
      </iot4bos-ui-backoffice-organization-concepts-selection-checkbox>
    }
  </div>
</section>
