export enum CardEventType {
  THRESHOLD_SELECTION_CHANGE = 'thresholdSelectionChange',
  REMOVE_PRODUCT = 'removeProduct',
  ADD_PRODUCT = 'addProduct',
  ADD_GATEWAY = 'addGateway',
  REMOVE_GATEWAY = 'removeGateway',
  SYNCHRONIZE_GATEWAY = 'synchronizeGateways',
  ADD_CONTACT = 'addContact',
  UPDATE_CONTACT = 'updateContact',
  REMOVE_CONTACT = 'removeContact',
  ELEMENT_CLICKED = 'elementClicked'
}
