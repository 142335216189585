import { Component, computed, DestroyRef, effect, inject, input, output, Signal, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TagCategory } from '@iot-platform/models/common';
import { ColorPickerService } from '../../color-picker/color-picker.service';
import { TagEditorFormComponent } from '../tag-editor-form/tag-editor-form.component';
import { AbstractTagEditorService } from './abstract-tag-editor.service';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TagEditorFormComponent],
  selector: 'iot-platform-ui-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagEditorComponent {
  private readonly tagEditorService: AbstractTagEditorService = inject(AbstractTagEditorService);
  private readonly colorPickerService: ColorPickerService = inject(ColorPickerService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  organizationId = input<string>();
  concept = input<string>();
  changeValue = output<boolean>();

  colors: Signal<string[] | undefined> = toSignal(this.colorPickerService.getColorList().pipe(takeUntilDestroyed(this.destroyRef)));
  categories: Signal<TagCategory[]> = this.tagEditorService.getCategories();

  categoriesChangedEffect = effect(
    () => {
      const categories = this.categories();
      if (categories) {
        this.onChangeValue(true);
      }
    },
    { allowSignalWrites: true }
  );

  getListTagsEffect = effect(
    () => {
      const organizationId = this.organizationId();
      const concept = this.concept();
      if (organizationId && concept) {
        this.tagEditorService.getListTagsByOrganizationForEdition(organizationId, false, concept);
      }
    },
    { allowSignalWrites: true }
  );

  newCategory: Signal<TagCategory> = computed(() => {
    const entityId = this.organizationId();
    const concept = this.concept()?.toUpperCase();
    return { entityId, concept, labels: [], mandatory: false, color: '', name: '' };
  });

  onChangeValue(value: boolean): void {
    this.changeValue.emit(value);
  }

  onAddTagCategory(category: TagCategory): void {
    if (category) {
      this.tagEditorService.addTagToOrganization(category);
    }
  }

  onUpdateTagCategory(category: TagCategory): void {
    this.tagEditorService.updateTagInOrganization(category);
  }

  onRemove(category: TagCategory): void {
    this.tagEditorService.removeTagFromOrganization(category);
  }
}
