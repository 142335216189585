import { BaseAssetVariable } from './base-asset-variable.model';
import { BaseDeviceVariable } from './base-device-variable';
import { BaseSite } from './base-site';
import { CommonGenericModel } from './core';
import { Entity } from './entity.model';

export interface Graph extends CommonGenericModel {
  asset: { id: string } | null;
  description: string;
  displayOrder: number;
  entity: Entity;
  entity_path: Entity;
  referenceVariableId: string;
  site: BaseSite;
  size: GraphSize;
  type: GraphType;
  variables: BaseDeviceVariable[] | BaseAssetVariable[];
}

export enum GraphSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum GraphType {
  line_chart = 'line_chart',
  last_value = 'last_value'
}
