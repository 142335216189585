import { Component, effect, inject, Injector, output, signal, Signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxChange, MatCheckboxDefaultOptions, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CardLoaderModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, NumberFormatPipe } from '@iot-platform/pipes';
import * as Leaflet from 'leaflet';
import * as moment from 'moment';
import { IotGeoJsonRouteFeature } from '../../models/iot-geo-json-object.model';
import { MapPopupService } from '../../services/map-popup.service';
import { MapFacade } from '../../state/facades/map.facade';

export interface Route {
  start: string;
  end: string;
  durationMs: number;
  color: string;
  day: string;
  layer: Leaflet.Layer;
  layerLabel: string;
  checked: boolean;
  indeterminate: boolean;
  routes: Route[];
}

@Component({
  standalone: true,
  imports: [MatCheckboxModule, CardLoaderModule, FlexLayoutModule, FormsModule, MatFormFieldModule, MatInputModule, DateFormatPipe, NumberFormatPipe],
  selector: 'iot-platform-maps-map-panel-info-popup',
  templateUrl: './map-panel-info-popup.component.html',
  styleUrls: ['./map-panel-info-popup.component.scss'],
  providers: [
    DateFormatPipe,
    NumberFormatPipe,
    MapPopupService,
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions
    }
  ]
})
export class MapPanelInfoPopupComponent {
  private readonly mapFacade: MapFacade = inject(MapFacade);
  private readonly mapPopupService: MapPopupService = inject(MapPopupService);
  private readonly injector: Injector = inject(Injector);

  displaySegments = output<any>();

  gRoutes: WritableSignal<any> = signal([]);
  currentRoutes: Signal<IotGeoJsonRouteFeature[]> = this.mapFacade.currentRoutes;
  hasRoutes: Signal<boolean> = this.mapFacade.hasRoutes;
  routesLoaded: Signal<boolean> = this.mapFacade.routesLoaded;
  routesLoading: Signal<boolean> = this.mapFacade.routesLoading;

  initRoutesEffect = effect(
    () => {
      const currentRoutes = this.currentRoutes();
      const hasRoutes = this.hasRoutes();
      const routesLoaded = this.routesLoaded();
      const routesLoading = this.routesLoading();

      if (hasRoutes && routesLoaded && !routesLoading && currentRoutes.length > 0) {
        this.initRoutes(currentRoutes);
      } else {
        this.gRoutes.set([]);
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  initRoutes(featureRoutes: IotGeoJsonRouteFeature[]) {
    const routes: Route[] = [];
    if (featureRoutes.length > 0) {
      featureRoutes
        ?.filter((feature) => feature?.properties.durationMs > 0 && feature.geometry.type === 'LineString')
        .forEach((route: IotGeoJsonRouteFeature, index) => {
          const colors: string[] = ['#0096FF', '#0047AB', '#6F8FAF', '#1434A4', '#6082B6', '#1F51FF', '#0F52BA', '#4169E1', '#4682B4', '#0437F2'];

          const routeColor = colors[index % colors.length];
          const routeLayer: Leaflet.GeoJSON = Leaflet.geoJson(route, {
            style: () => ({
              stroke: true,
              weight: 5,
              color: routeColor
            }),
            onEachFeature: (f, layer) => {
              layer.bindPopup(this.mapPopupService.getPopupRoute(f.properties));
              layer.on({});
            }
          });
          const layerLabel: string = moment(route.properties['start']).format('HH:mm:ss') + ' - ' + moment(route.properties['end']).format('HH:mm:ss');
          const day: string = moment(route.properties['start']).format('DD/MM/yyyy');
          routes.push({
            color: routeColor,
            layerLabel,
            layer: routeLayer,
            day,
            checked: false, // moment(route.properties['start']).isSame(moment(), 'day'), boxes are checked but routes are not added to map
            indeterminate: false,
            routes: [],
            start: route.properties['start'],
            end: route.properties['end'],
            durationMs: route.properties['durationMs']
          });
        });

      const gRoutes = routes.reduce((acc: any, route: any) => {
        const day = route.day;
        if (!acc.find((r) => r.day === day)) {
          acc.push({ day, routes: [route] });
        } else {
          acc.find((r) => r.day === day).routes.push(route);
        }

        const sortedRoutes = acc.sort((a: any, b: any) => {
          a = moment(a.date);
          b = moment(b.date);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        return sortedRoutes;
      }, []);

      const todayRoute: Route = gRoutes.find((r) => r.day === moment().format('DD/MM/yyyy'));

      if (todayRoute) {
        this.setAll(todayRoute, true);
      }

      this.gRoutes.set(gRoutes);
    }
  }

  someSubRoutesChecked(route: Route): boolean {
    return route.routes.some((t) => t.checked) && !route.routes.every((t) => t.checked);
  }

  allSubRoutesChecked(route: Route): boolean {
    return route.routes.every((t) => t.checked);
  }

  setAll(dayRoute: Route, checked: boolean) {
    if (dayRoute.routes === null) {
      return;
    }

    dayRoute.routes.forEach((t) => (t.checked = checked));
    this.displaySegments.emit({
      layers: dayRoute.routes.map((route) => route.layer),
      action: checked ? 'add' : 'remove'
    });
  }

  onRouteChanged(event: MatCheckboxChange, selectedRoute: any) {
    this.displaySegments.emit({ layers: [selectedRoute.layer], action: event.checked ? 'add' : 'remove' });
  }

  getDuration(subRoute: Route) {
    return moment.duration(subRoute.durationMs).asHours().toFixed(0) + 'h';
  }

  clear() {
    this.gRoutes.set([]);
    this.displaySegments.emit({ layers: [], action: 'remove' });
  }
}
