import { Injectable } from '@angular/core';
import { ApiFeatureSelector } from './api-feature-selector.model';
import { createFeature, createReducer } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { INITIAL_BASE_STATE } from './base-state.model';

export const dummyAdapter = createEntityAdapter<never>();

export const dummyFeatureReducer = createFeature({
  name: 'dummyFeatureReducer',
  reducer: createReducer(
    dummyAdapter.getInitialState({
      ...INITIAL_BASE_STATE,
      pagination: {
        currentPage: 0,
        hasMore: false,
        limit: 0,
        maxPage: 0,
        total: 0
      }
    })
  )
});

@Injectable({
  providedIn: 'root'
})
export class DummyFeatureSelector extends ApiFeatureSelector<any, never, never, never> {
  constructor() {
    super(dummyFeatureReducer.selectDummyFeatureReducerState, dummyAdapter);
  }
}
