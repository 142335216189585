<div class="chat-filters-container p-20" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  @for (context of commentsFilters; track context.name) {
    <mat-checkbox
      [(ngModel)]="context.checked"
      [disabled]="context.disabled"
      (change)="filterChange.emit(commentsFilters); onFilterChange?.(commentsFilters)"
      >{{ context.name | uppercase }}</mat-checkbox
    >
  }
</div>
