import { Injectable, Type } from '@angular/core';
import {
  CountryAutocompleteFieldComponent,
  DateIntervalFieldComponent,
  DynamicListFieldMultipleSelectsComponent,
  DynamicListFieldSingleSelectComponent,
  InputFieldComponent,
  MultipleInputsFieldComponent,
  SelectFieldComponent,
  TimezoneAutocompleteFieldComponent
} from './fields';

@Injectable()
export class FilterComponentFactory {
  getComponent(elementType: string): Type<any> | null {
    switch (elementType) {
      case 'input':
        return InputFieldComponent;
      case 'multiple-inputs':
        return MultipleInputsFieldComponent;
      case 'select':
        return SelectFieldComponent;
      case 'date-interval':
        return DateIntervalFieldComponent;
      case 'dynamic-list-single-select':
        return DynamicListFieldSingleSelectComponent;
      case 'dynamic-list-multiple-selects':
        return DynamicListFieldMultipleSelectsComponent;
      case 'timezone-autocomplete':
        return TimezoneAutocompleteFieldComponent;
      case 'country-autocomplete':
        return CountryAutocompleteFieldComponent;
      default:
        return null;
    }
  }
}
