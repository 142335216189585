import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { DaliaFirmwareButtonColumn, DaliaFirmwareVersionColumn, I4BBasicColumn, I4BColumn, I4BSelectionColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { FirmwaresGridData, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_FIRMWARES_COLUMNS_FOR_DALIA_FIRMWARES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new I4BBasicColumn(
    { displayName: 'DEVICE MODEL' },
    {
      id: 'model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      autoSize: true,
      sortProperty: 'model',
      concept: I4bCellConcept.FIRMWARES
    },
    {
      order: 1,
      width: 80
    }
  ),
  new DaliaFirmwareVersionColumn(
    { displayName: 'VERSION' },
    {
      id: 'version',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VERSION',
      cell: {
        type: I4BCellType.BASIC,
        options: {
          toFixed: 3
        }
      },
      isDefault: true,
      sortProperty: 'version',
      concept: I4bCellConcept.FIRMWARES
    },
    {
      order: 2
    }
  ),
  new I4BBasicColumn(
    {
      displayName: 'AUTO DEPLOY'
    },
    {
      id: 'autoDeploy',
      sortProperty: 'autoDeploy',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.AUTO_DEPLOY',
      isDefault: true,
      concept: I4bCellConcept.FIRMWARES,
      cell: {
        type: I4BCellType.BASIC,
        options: {
          capitalize: true,
          textAlign: 'center',
          translatedValues: {
            true: { color: '#333333', backgroundColor: '#8AC478', value: 'IOT_DICTIONARY.true' },
            false: { backgroundColor: '#0000000a', value: 'IOT_DICTIONARY.false' }
          }
        }
      }
    },
    { order: 3, width: 60 }
  ),
  new I4BBasicColumn(
    { displayName: 'NUMBER OF DEVICES' },
    {
      id: 'totalCurrentDevices',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.NUMBER_OF_DEVICES',
      cell: {
        type: I4BCellType.BASIC,
        options: {
          textAlign: 'center'
        }
      },
      isDefault: true,
      sortProperty: 'totalCurrentDevices',
      concept: I4bCellConcept.FIRMWARES
    },
    {
      order: 4,
      width: 90
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'description',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'description',
      concept: I4bCellConcept.FIRMWARES,
      autoSize: true
    },
    {
      order: 5
    }
  )
];

export class DefaultDaliaFirmwaresGrid implements I4BGrid<I4BGridOptions, FirmwaresGridData> {
  masterview = 'dalia-firmwares';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: FirmwaresGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false, delay: 120 },
    gridSort: [
      { colId: 'version', sort: 'desc', sortIndex: 1 },
      { colId: 'name', sort: 'asc', sortIndex: 2 },
    ],
    buttonColumn: { enabled: false, className: new DaliaFirmwareButtonColumn() },
    selectionColumn: { enabled: false, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}
