import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class TotalColumn extends I4BBasicColumn {
  columnId = '171bc694-5c1e-11ec-80a5-0023a40210d0-total';
  className = TotalColumn;
  dataLibs: [DataLibrary.SITES, DataLibrary.ASSETS, DataLibrary.DEVICES];

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        ...customHeader,
        displayName: customOptions.overrideHeaderName ?? customHeader.displayName ?? 'TOTAL',
        type: HeaderType.ICON
      },
      { width: customOptions?.width ?? 90, cell: { type: I4BCellType.NUMBER }, ...customConfig },
      { customId: customConfig.id ?? '', ...customOptions }
    );
  }
}
