import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Filter, Pagination, PlatformResponse } from '@iot-platform/models/common';
import { EscalationProtocol } from '@iot-platform/models/ocm';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { EscalationProtocolsDbActions, EscalationProtocolsUiActions } from '../actions';

export const escalationProtocolsDbFeatureKey = 'escalationProtocolsDb';

export interface State extends EntityState<EscalationProtocol> {
  selectedEscalationProtocolId: string;
  pagination: Pagination;
  topicSubscriptions: PlatformResponse;
  currentFilters: Filter[];
  error: any;
}

export const adapter: EntityAdapter<EscalationProtocol> = createEntityAdapter<EscalationProtocol>({
  selectId: (escalationProtocol: EscalationProtocol) => escalationProtocol.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedEscalationProtocolId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 100 },
  topicSubscriptions: null,
  currentFilters: [],
  error: null
});

export const escalationProtocolsDbReducer = createReducer(
  initialState,
  on(EscalationProtocolsDbActions.listEscalationProtocolsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        total: response.total,
        hasMore: response.hasMore,
        maxPage: response.maxPage,
        limit: response.limit
      }
    })
  ),
  on(EscalationProtocolsDbActions.listEscalationProtocolsFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(EscalationProtocolsDbActions.loadEscalationProtocolByIdSuccess, (state: State, { protocol }) =>
    adapter.upsertOne(protocol, { ...state, selectedEscalationProtocolId: protocol.id })
  ),
  on(EscalationProtocolsDbActions.loadEscalationProtocolByIdFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(EscalationProtocolsDbActions.selectEscalationProtocolSuccess, (state: State, { selectedProtocol }) => ({
    ...state,
    selectedEscalationProtocolId: selectedProtocol.id
  })),
  on(EscalationProtocolsDbActions.selectEscalationProtocolFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(EscalationProtocolsDbActions.addEscalationProtocolSuccess, (state: State, { addedProtocol }) =>
    adapter.addOne(addedProtocol, {
      ...state,
      pagination: { ...state.pagination, total: state.pagination.total + 1 },
      selectedEscalationProtocolId: addedProtocol.id
    })
  ),
  on(EscalationProtocolsDbActions.addEscalationProtocolFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(EscalationProtocolsDbActions.updateEscalationProtocolSuccess, (state: State, { updatedProtocol }) =>
    adapter.updateOne(
      { id: updatedProtocol.id, changes: updatedProtocol },
      {
        ...state,
        selectedEscalationProtocolId: updatedProtocol.id
      }
    )
  ),
  on(EscalationProtocolsDbActions.updateEscalationProtocolFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(EscalationProtocolsDbActions.deleteEscalationProtocolSuccess, (state: State, { deletedProtocol }) =>
    adapter.removeOne(deletedProtocol.id, { ...state, selectedEscalationProtocolId: null })
  ),
  on(EscalationProtocolsDbActions.deleteEscalationProtocolFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(EscalationProtocolsUiActions.navigateToEscalationProtocolDetails, (state: State, { protocolToNavigate }) => ({
    ...state,
    selectedEscalationProtocolId: protocolToNavigate.id
  })),
  //
  on(EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsSuccess, (state: State, { updatedTopics }) => ({
    ...state,
    topicSubscriptions: updatedTopics
  })),
  on(EscalationProtocolsDbActions.updateEscalationProtocolTopicSubscriptionsFailure, (state: State, { error }) => ({ ...state, error })),
  on(EscalationProtocolsDbActions.loadTopicSubscriptionByProtocolIdSuccess, (state: State, { topics }) => ({ ...state, topicSubscriptions: topics })),
  on(EscalationProtocolsDbActions.loadTopicSubscriptionByProtocolIdFailure, (state: State, { error }) => ({ ...state, error })),
  on(EscalationProtocolsUiActions.saveEscalationProtocolFilters, (state: State, { filters }) => ({ ...state, currentFilters: filters })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return escalationProtocolsDbReducer(state, action);
}

export const getSelectedEscalationProtocolId = (state: State) => state.selectedEscalationProtocolId;
export const getPagination = (state: State) => state.pagination;
export const getCurrentFilters = (state: State) => state.currentFilters;
export const getTopicSubscriptions = (state: State) => state.topicSubscriptions;
export const getError = (state: State) => state.error;
