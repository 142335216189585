@if (routesLoading()) {
  <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-card h-40'"></iot-platform-ui-card-loader>
} @else if (gRoutes()) {
  <div [class]="'day-section'" fxLayout="column">
    @for (dayRoute of gRoutes(); track dayRoute) {
      <section>
        <span class="main-route-section">
          <mat-checkbox (change)="setAll(dayRoute, $event.checked)" [checked]="allSubRoutesChecked(dayRoute)" [indeterminate]="someSubRoutesChecked(dayRoute)">
            {{ dayRoute.day }}
          </mat-checkbox>
        </span>
        <span>
          <ul class="sub-route-section">
            @for (subRoute of dayRoute.routes; track subRoute) {
              <li>
                <mat-checkbox (change)="onRouteChanged($event, subRoute)" [(ngModel)]="subRoute.checked">
                  <span fxLayout="row" fxLayoutAlign="start center">
                    <p>{{ subRoute.layerLabel }}</p>
                    <p [style.background-color]="subRoute.color" class="sub-route-color"></p>
                    <p>{{ getDuration(subRoute) }}</p>
                  </span>
                </mat-checkbox>
              </li>
            }
          </ul>
        </span>
      </section>
    }
  </div>
}
@if (!hasRoutes() && !routesLoading() && routesLoaded() && gRoutes().length === 0) {
  <div>
    <span>No routes found for period</span>
  </div>
}
