export const TrackingEventCategories = {
  FILTER_ACTION: 'filters_actions',
  TOOLBAR_ACTION: 'toolbar_actions',
  GRID_ACTION: 'grid_actions',
  CALL_TO_ACTION: 'cta_actions',
  MAP_ACTION: 'map_actions',
  NAVIGATION: 'navigation',
  TAB_DEVICE_INFO_ACTION: 'tab_device_info_actions',
  TAB_INFOS_ACTION: 'tab_infos_actions',
  TAB_CONFIGURATION_ACTION: 'tab_configuration_actions',
  TAB_VARIABLES_ACTION: 'tab_variables_actions',
  TAB_CHANGE: 'tab_change',
  POPUP_ACTIONS: 'popup_actions'
};
