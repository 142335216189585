import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@iot-platform/notification';
import { UserPreferencesService } from '@iot-platform/users';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CalendarsService } from '../../services/calendars.service';
import { CalendarsDbActions, CalendarsUiActions } from '../actions';
import * as fromCalendars from '../reducers';

@Injectable()
export class CalendarsEffects {
  listAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.listCalendars),
      switchMap((action) =>
        this.calendarsService.getAll(action.request).pipe(
          map((response) => CalendarsDbActions.listCalendarsSuccess({ response })),
          catchError((error) => of(CalendarsDbActions.listCalendarsFailure({ error })))
        )
      )
    )
  );

  getCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.getCalendar),
      switchMap((action) =>
        this.calendarsService.getOne(action.calendarId).pipe(
          map((selectedCalendar) => CalendarsDbActions.getCalendarSuccess({ selectedCalendar })),
          catchError((error) => of(CalendarsDbActions.getCalendarFailure({ error })))
        )
      )
    )
  );

  addCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.addCalendar),
      concatMap((action) =>
        this.calendarsService.addOne(action.calendarToAdd).pipe(
          map((addedCalendar) => CalendarsDbActions.addCalendarSuccess({ addedCalendar })),
          catchError((error) => of(CalendarsDbActions.addCalendarFailure({ error })))
        )
      )
    )
  );

  updateCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.updateCalendar),
      concatMap((action) =>
        this.calendarsService.updateOne(action.calendarToUpdate).pipe(
          map((updatedCalendar) => CalendarsDbActions.updateCalendarSuccess({ updatedCalendar })),
          catchError((error) => of(CalendarsDbActions.updateCalendarFailure({ error })))
        )
      )
    )
  );

  deleteCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.deleteCalendar),
      concatMap((action) =>
        this.calendarsService.deleteOne(action.calendarToDelete).pipe(
          map((deletedCalendar) => CalendarsDbActions.deleteCalendarSuccess({ deletedCalendar })),
          catchError((error) => of(CalendarsDbActions.deleteCalendarFailure({ error })))
        )
      )
    )
  );

  deleteCalendarSuccessThenNavigateToMV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarsDbActions.deleteCalendarSuccess),
        tap(() => this.router.navigate(['on-call-management', 'calendars']))
      ),
    { dispatch: false }
  );

  actionsOnCalendarThenList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsDbActions.deleteCalendarSuccess),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(fromCalendars.getPagination), this.store.select(fromCalendars.getCalendarsFilters)))
      ),
      map(([_, pagination, filters]) => CalendarsUiActions.listCalendars({ request: { limit: pagination.limit, page: pagination.currentPage, filters } }))
    )
  );

  navigateToCalendarDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.navigateToCalendarDetails),
      tap((action) => this.router.navigate(['on-call-management', 'calendars', action.calendarToNavigate.id])),
      map((action) => CalendarsUiActions.listDaysOffByCalendarId({ calendarId: action.calendarToNavigate.id }))
    )
  );

  navigateToCalendarDetailsAfterCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarsDbActions.addCalendarSuccess),
        tap((action) => {
          this.router.navigate(['on-call-management', 'calendars', action.addedCalendar.id]);
        })
      ),
    { dispatch: false }
  );

  loadMvCalendarsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.loadMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((mvSettings) => CalendarsDbActions.loadMVSettingsSuccess({ mvSettings })),
          catchError((error) => of(CalendarsDbActions.loadMVSettingsFailure({ error })))
        )
      )
    )
  );

  listDaysOffByCalendarId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.listDaysOffByCalendarId),
      switchMap((action) =>
        this.calendarsService.getDaysOffByCalendarId(action.calendarId).pipe(
          map((daysOff) => CalendarsDbActions.listDaysOffByCalendarIdSuccess({ daysOff })),
          catchError((error) => of(CalendarsDbActions.listDaysOffByCalendarIdFailure({ error })))
        )
      )
    )
  );

  addDayOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.addDayOff),
      concatMap((action) =>
        this.calendarsService.addDayOff(action.dayOff, action.calendarId).pipe(
          map((addedDayOff) => CalendarsDbActions.addDayOffSuccess({ addedDayOff })),
          catchError((error) => of(CalendarsDbActions.addDayOffFailure({ error })))
        )
      )
    )
  );

  updateDayOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.updateDayOff),
      concatMap((action) =>
        this.calendarsService.updateDayOff(action.dayOff, action.calendarId).pipe(
          map((updatedDayOff) => CalendarsDbActions.updateDayOffSuccess({ updatedDayOff })),
          catchError((error) => of(CalendarsDbActions.updateDayOffFailure({ error })))
        )
      )
    )
  );

  deleteDayOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.deleteDayOff),
      concatMap((action) =>
        this.calendarsService.deleteDayOff(action.dayOff, action.calendarId).pipe(
          map((deletedDayOff) => CalendarsDbActions.deleteDayOffSuccess({ deletedDayOff })),
          catchError((error) => of(CalendarsDbActions.deleteDayOffFailure({ error })))
        )
      )
    )
  );

  importDaysOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarsUiActions.importDaysOffByCalendarId),
      concatMap((action) =>
        this.calendarsService.importDaysOffByCalendarId(action.sourceCalendarId, action.calendarId, action.dayOffType).pipe(
          map((daysOff) => CalendarsDbActions.importDaysOffByCalendarIdSuccess({ daysOff })),
          catchError((error) => of(CalendarsDbActions.importDaysOffByCalendarIdFailure({ error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CalendarsDbActions.addCalendarSuccess,
          CalendarsDbActions.updateCalendarSuccess,
          CalendarsDbActions.deleteCalendarSuccess,
          CalendarsDbActions.addDayOffSuccess,
          CalendarsDbActions.updateDayOffSuccess,
          CalendarsDbActions.deleteDayOffSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CalendarsDbActions.addCalendarFailure,
          CalendarsDbActions.updateCalendarFailure,
          CalendarsDbActions.listCalendarsFailure,
          CalendarsDbActions.deleteCalendarFailure,
          CalendarsDbActions.getCalendarFailure,
          CalendarsDbActions.listDaysOffByCalendarIdFailure,
          CalendarsDbActions.addDayOffFailure,
          CalendarsDbActions.updateDayOffFailure,
          CalendarsDbActions.deleteDayOffFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CalendarsUiActions.listCalendars,
          CalendarsUiActions.addCalendar,
          CalendarsUiActions.updateCalendar,
          CalendarsUiActions.deleteCalendar,
          CalendarsUiActions.getCalendar,
          CalendarsUiActions.listDaysOffByCalendarId,
          CalendarsUiActions.addDayOff,
          CalendarsUiActions.updateDayOff,
          CalendarsUiActions.deleteDayOff
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CalendarsDbActions.listCalendarsSuccess,
          CalendarsDbActions.listCalendarsFailure,
          CalendarsDbActions.getCalendarSuccess,
          CalendarsDbActions.getCalendarFailure,
          CalendarsDbActions.addCalendarSuccess,
          CalendarsDbActions.addCalendarFailure,
          CalendarsDbActions.updateCalendarSuccess,
          CalendarsDbActions.updateCalendarFailure,
          CalendarsDbActions.deleteCalendarSuccess,
          CalendarsDbActions.deleteCalendarFailure,
          CalendarsDbActions.listDaysOffByCalendarIdFailure,
          CalendarsDbActions.addDayOffFailure,
          CalendarsDbActions.updateDayOffFailure,
          CalendarsDbActions.deleteDayOffFailure,
          CalendarsDbActions.listDaysOffByCalendarIdSuccess,
          CalendarsDbActions.addDayOffSuccess,
          CalendarsDbActions.updateDayOffSuccess,
          CalendarsDbActions.deleteDayOffSuccess
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly calendarsService: CalendarsService,
    private readonly notificationService: NotificationService,
    private readonly userPrefService: UserPreferencesService,
    private readonly router: Router
  ) {}
}
