import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthPageActions = createActionGroup({
  source: '[Auth] Page Actions',
  events: {
    signInWithSso: emptyProps(),
    signIn: props<{ username: string; password: string }>(),
    retrieveSsoSession: props<{ idToken: string; accessToken: string; refreshToken: string }>(),
    refreshSsoTokens: props<{ refreshToken: string }>(),
    validateSsoTokens: props<{ idToken: string; accessToken: string; refreshToken: string; expiresIn: number }>(),
    loadAccount: emptyProps(),
    changePassword: props<{ user: any; newPassword: string }>(),
    forgotPassword: props<{ username: string }>(),
    updatePasswordWithCode: props<{ username: string; code: string; password: string }>(),
    requireNewPassword: props<{ username: string }>(),
    cancel: emptyProps(),
    resetPassword: emptyProps(),
    loadPrivileges: emptyProps(),
    signOut: emptyProps(),
    displayEmailPasswordLogin: emptyProps(),
    setPending: props<{ pending: boolean }>()
  }
});
