import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountValidationService {
  constructor(@Inject('environment') private environment, private http: HttpClient, private route: ActivatedRoute) {}

  validateSharedAccount(): Observable<any> {
    return this.route.queryParams.pipe(
      switchMap((params) => this.http.post<any>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${params.user_id}/verification`, {
          token: params.token
        }))
    );
  }
}
