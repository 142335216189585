import { SidenavMenuBlock } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { BusinessProfile } from '@iot-platform/models/common';

export abstract class AbstractAppShellService {
  abstract getNavigationTree(): Observable<SidenavMenuBlock[]>;

  abstract getCurrentBusinessProfile(): Observable<BusinessProfile>;

  abstract closePreferences(): void;

  abstract openReleaseNotes(): void;

  abstract openMyProfile(): void;

  abstract onBusinessProfileReady(businessProfile?: BusinessProfile): void;

  abstract onBusinessProfileChange(): void;

  abstract returnHome(): void;
}
