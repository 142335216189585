import { TagCategory } from '@iot-platform/models/common';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const listTagsByOrganizationSuccess = createAction(
  '[Organizations API] Get Tags By Organization Success',
  props<{
    tags: TagCategory[];
  }>()
);
export const listTagsByOrganizationFailure = createAction(
  '[Organizations API] Get Tags By Organization  Failure',
  props<{
    error: any;
  }>()
);

export const listTagsByOrganizationForEditionSuccess = createAction(
  '[Organizations API] Get Tags By Organization For Edition Success',
  props<{ tags: TagCategory[] }>()
);
export const listTagsByOrganizationForEditionFailure = createAction(
  '[Organizations API] Get Tags By Organization For Edition Failure',
  props<{ error: any }>()
);

export const addTagToOrganizationSuccess = createAction(
  '[Admin Page/Tags API] Add Tag to Organization Success',
  props<{
    newTag: TagCategory;
  }>()
);
export const addTagToOrganizationFailure = createAction(
  '[Admin Page/Tags API] Add Tag to Organization Failure',
  props<{
    error: any;
  }>()
);

export const addTagToOrganizationForEditionSuccess = createAction(
  '[Admin Page/Tags API] Add Tag to Organization ForEdition Success',
  props<{ newTag: TagCategory }>()
);
export const addTagToOrganizationForEditionFailure = createAction(
  '[Admin Page/Tags API] Add Tag to Organization ForEdition Failure',
  props<{
    error: any;
  }>()
);

export const updateTagInOrganizationForEditionSuccess = createAction(
  '[Admin Page/Tags API] Update Tag in Organization ForEdition Success',
  props<{ updatedTag: Update<TagCategory> }>()
);
export const updateTagInOrganizationForEditionFailure = createAction(
  '[Admin Page/Tags API] Update Tag in Organization ForEdition Failure',
  props<{ error: any }>()
);

export const removeTagFromOganizationForEditionSuccess = createAction(
  '[Admin Page/Tags API] Remove Tag from Organization ForEdition Success',
  props<{ removedTag: TagCategory }>()
);
export const removeTagFromOganizationForEditionFailure = createAction(
  '[Admin Page/Tags API] Remove Tag from Organization ForEdition Failure',
  props<{ error: any }>()
);

export const updateTagInOrganizationSuccess = createAction(
  '[Admin Page/Tags API] Update Tag in Organization Success',
  props<{ updatedTag: Update<TagCategory> }>()
);
export const updateTagInOrganizationFailure = createAction(
  '[Admin Page/Tags API] Update Tag in Organization Failure',
  props<{
    error: any;
  }>()
);

export const removeTagFromOganizationSuccess = createAction(
  '[Admin Page/Tags API] Remove Tag from Organization Success',
  props<{
    removedTag: TagCategory;
  }>()
);
export const removeTagFromOganizationFailure = createAction(
  '[Admin Page/Tags API] Remove Tag from Organization Failure',
  props<{
    error: any;
  }>()
);
