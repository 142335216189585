import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadExportSpreadsheets = createAction('[ExportSpreadsheets] Load Export Spreadsheets', props<{ siteId: string }>());
export const selectExportSpreadsheet = createAction(
  '[ExportSpreadsheets] Select Export Spreadsheet',
  props<{ exportSpreadsheetToSelect: ExportSpreadsheet }>()
);
export const addExportSpreadsheet = createAction(
  '[ExportSpreadsheets] Add Export Spreadsheet',
  props<{ exportSpreadsheetToAdd: ExportSpreadsheet; siteId: string }>()
);
export const updateExportSpreadsheet = createAction(
  '[ExportSpreadsheets] Update Export Spreadsheet',
  props<{ exportSpreadsheetToUpdate: ExportSpreadsheet; siteId: string }>()
);
export const deleteExportSpreadsheet = createAction(
  '[ExportSpreadsheets] Delete Export Spreadsheet',
  props<{ exportSpreadsheetToDelete: ExportSpreadsheet; siteId: string }>()
);
export const configureContactExportSpreadsheets = createAction(
  '[ExportSpreadsheets] Configure Contact Export Spreadsheets',
  props<{ exportSpreadsheetsToUpdate: ExportSpreadsheet[]; siteId: string }>()
);
