export enum ToolbarPageType {
  DASHBOARD = 'DASHBOARD',
  GRID = 'GRID',
  MAP = 'MAP'
}

export interface ToolbarPageTypeOption {
  icon: string;
  tooltip: string;
  type: ToolbarPageType;
}

export const DEFAULT_PAGE_TYPE_OPTION_GRID: ToolbarPageTypeOption = {
  icon: 'table_view',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.DISPLAY_GRID',
  type: ToolbarPageType.GRID
};

export const DEFAULT_PAGE_TYPE_OPTION_MAP: ToolbarPageTypeOption = {
  icon: 'pin_drop',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.DISPLAY_MAP',
  type: ToolbarPageType.MAP
};

export const DEFAULT_PAGE_TYPE_OPTION_DASHBOARD: ToolbarPageTypeOption = {
  icon: 'dashboard',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.DISPLAY_DASHBOARD',
  type: ToolbarPageType.DASHBOARD
};
