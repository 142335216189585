<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ASSETS.MANAGE_PRODUCT_FORM.TITLE' | translate: { assetName: data.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    @if (!catalogs() || !products()) {
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    }
    <form [formGroup]="form" class="full-width p-20" fxLayout="column" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start" fxLayoutGap="20px">
        @if (data.configureProduct1) {
          <div fxLayout="column" [fxFlex]="data.configureProduct1 && data.configureProduct2 ? '50%' : '100%'" fxLayoutGap="10px">
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'ASSETS.MANAGE_PRODUCT_FORM.CATALOG' | translate }}</mat-label>
              <mat-select formControlName="catalog1" (selectionChange)="onSelectCatalog1()" [compareWith]="compareFn" required>
                @for (catalog1 of catalogs(); track catalog1.id) {
                  <mat-option [value]="catalog1"> {{ catalog1.name }} </mat-option>
                }
              </mat-select>
              @if (catalog1.invalid) {
                <mat-error>{{ 'ASSETS.MANAGE_PRODUCT_FORM.PRODUCT_ERROR' | translate }}</mat-error>
              }
            </mat-form-field>

            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'ASSETS.MANAGE_PRODUCT_FORM.PRODUCT1' | translate }}</mat-label>
              <input
                [matAutocomplete]="autoProduct1"
                formControlName="product1"
                matInput
                required
                type="text"
                (keyup)="filterProductListByKeyword($event.target.value, product1List, catalog1)"
              />
              <mat-autocomplete #autoProduct1="matAutocomplete" [displayWith]="displayFn">
                @for (product1 of product1List(); track product1) {
                  <mat-option [value]="product1"> {{ product1.identifier }} : {{ product1.name }} </mat-option>
                }
              </mat-autocomplete>
              @if (product1.invalid) {
                <mat-error>{{ 'ASSETS.MANAGE_PRODUCT_FORM.PRODUCT_ERROR' | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        }
        @if (data.configureProduct2) {
          <div fxLayout="column" [fxFlex]="data.configureProduct1 && data.configureProduct2 ? '50%' : '100%'" fxLayoutGap="10px">
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'ASSETS.MANAGE_PRODUCT_FORM.CATALOG' | translate }}</mat-label>
              <mat-select formControlName="catalog2" (selectionChange)="onSelectCatalog2()" [compareWith]="compareFn" required>
                @for (catalog2 of catalogs(); track catalog2.id) {
                  <mat-option [value]="catalog2"> {{ catalog2.name }} </mat-option>
                }
              </mat-select>
              @if (catalog2.invalid) {
                <mat-error>{{ 'ASSETS.MANAGE_PRODUCT_FORM.PRODUCT_ERROR' | translate }}</mat-error>
              }
            </mat-form-field>

            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'ASSETS.MANAGE_PRODUCT_FORM.PRODUCT2' | translate }}</mat-label>
              <input
                [matAutocomplete]="autoProduct2"
                formControlName="product2"
                matInput
                required
                type="text"
                (keyup)="filterProductListByKeyword($event.target.value, product2List, catalog2)"
              />
              <mat-autocomplete #autoProduct2="matAutocomplete" [displayWith]="displayFn">
                @for (product2 of product2List(); track product2) {
                  <mat-option [value]="product2"> {{ product2.identifier }} : {{ product2.name }} </mat-option>
                }
              </mat-autocomplete>
              @if (product2.invalid) {
                <mat-error>{{ 'ASSETS.MANAGE_PRODUCT_FORM.PRODUCT_ERROR' | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        }
      </div>
      <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="form.invalid" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.UPDATE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
