import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
  selector: 'i4b-table-engine-comment-cell',
  templateUrl: './comment-cell.component.html',
  styleUrls: ['./comment-cell.component.scss']
})
export class CommentCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    this.dispatchEvent.emit({ type: 'openComments', options: {}, rawData: this.rawData });
  }
}
