import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteMultipleSelectsModule } from './async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { AsyncAutocompleteModule } from './async-autocomplete/async-autocomplete.module';
import { BreadcrumbItemModule } from './breadcrumb-item/breadcrumb-item.module';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { BuildInfoComponent } from './build-info';
import { CardLoaderModule } from './card-loader/card-loader.module';
import { ChipModule } from './chip/chip.module';
import { ColorPickerModule } from './color-picker/color-picker.module';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { ContactFormModule } from './contact-form/contact-form.module';
import { CountryAutocompleteModule } from './country-autocomplete/country-autocomplete.module';
import { DetailPopupCardsModule } from './detail-popup-cards/detail-popup-cards.module';
import { DetailPopupModule } from './detail-popup/detail-popup.module';
import { DeviceDetailsModule } from './device-details/device-details.module';
import { EventSeverityDisplayModule } from './event-severity-display/event-severity-display.module';
import { FilterEngineModule } from './filter-engine';
import { IconModule } from './icon/icon.module';
import { ImageSelectorModule } from './image-selector/image-selector.module';
import { PanelComponent } from './panel';
import { PopupModule } from './popup/popup.module';
import { ProgressBarButtonModule } from './progress-bar-button/progress-bar-button.module';
import { ReleaseNotesComponent } from './release-notes';
import { SidenavModule } from './sidenav';
import { SiteDetailsModule } from './site-details/site-details.module';
import { SpinnerBackgroundModule } from './spinner-background/spinner-background.module';
import { SubscriptionButtonModule } from './subscription-button/subscription-button.module';
import { ManageTagsDialogComponent } from './tags/manage-tags-dialog/manage-tags-dialog.component';
import { ManageTagsFormComponent } from './tags/manage-tags-form/manage-tags-form.component';
import { TagCategoryListByConceptModule } from './tags/tag-category-list-by-concept/tag-category-list-by-concept.module';
import { TimezoneAutocompleteMultipleSelectsModule } from './timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.module';
import { TimezoneAutocompleteModule } from './timezone-autocomplete/timezone-autocomplete.module';
import { ToolbarV2Module } from './toolbar-v2/toolbar-v2.module';
import { YearPickerModule } from './year-picker/year-picker.module';

@NgModule({
  imports: [
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatGridListModule,
    MatProgressBarModule,
    FlexLayoutModule,
    CommonModule,
    TranslateModule,
    CardLoaderModule,
    ImageSelectorModule,
    ChipModule,
    IconModule,
    ColorPickerModule,
    FilterEngineModule,
    PopupModule,
    ManageTagsFormComponent,
    ManageTagsDialogComponent,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    SpinnerBackgroundModule,
    MatProgressSpinnerModule,
    ReleaseNotesComponent,
    DetailPopupModule,
    EventSeverityDisplayModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    BreadcrumbModule,
    BreadcrumbItemModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    SubscriptionButtonModule,
    ToolbarV2Module,
    SiteDetailsModule,
    DetailPopupCardsModule,
    ContactCardComponent,
    ContactFormModule,
    BuildInfoComponent,
    SidenavModule,
    PanelComponent,
    YearPickerModule
  ],
  exports: [
    ImageSelectorModule,
    CardLoaderModule,
    IconModule,
    ColorPickerModule,
    FilterEngineModule,
    ChipModule,
    PopupModule,
    ManageTagsFormComponent,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    SpinnerBackgroundModule,
    ReleaseNotesComponent,
    DetailPopupModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    BreadcrumbItemModule,
    SubscriptionButtonModule,
    ToolbarV2Module,
    SiteDetailsModule,
    DeviceDetailsModule,
    DetailPopupCardsModule,
    YearPickerModule,
    ContactCardComponent,
    ContactFormModule,
    BuildInfoComponent,
    SidenavModule,
    PanelComponent,
    ManageTagsDialogComponent
  ]
})
export class UiModule {}
