import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const assetEventsBySiteColumnSettings = [
  {
    order: 0,
    id: 'delay',
    name: '',
    catalogName: 'DELAY',
    default: true,
    sortable: false,
    headerType: HeaderType.ICON,
    headerIcon: 'delay',
    cellType: I4BCellType.DYNAMIC_ICON_CELL,
    cellTypeOptions: {
      icon: 'delay',
      class: 'table-icon-content',
      tooltip: {
        key: 'occurrenceTime'
      }
    },
    isLink: false,
    clickEvent: '',
    width: '3%',
    cellWidth: '20',
    type: 'start center'
  },
  {
    order: 1,
    id: 'occurrenceTime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OCCURRENCE_TIME',
    catalogName: 'OCCURRENCE TIME',
    default: true,
    sortable: true,
    sortProperty: 'occurrenceTime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    width: '12%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 2,
    id: 'receptionTime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.RECEPTION_TIME',
    catalogName: 'RECEPTION TIME',
    default: true,
    sortable: true,
    sortProperty: 'receptionTime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    width: '12%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 4,
    id: 'context.asset.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET',
    catalogName: 'ASSET NAME',
    default: true,
    headerIcon: 'asset',
    sortable: true,
    sortProperty: 'context.asset.name',
    headerType: HeaderType.ICON_AND_TEXT,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigateToAsset'
    },
    width: '15%',
    cellWidth: '150',
    type: 'start center'
  },
  {
    order: 5,
    id: 'context.assetVariable.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE',
    catalogName: 'ASSET VARIABLE NAME',
    default: true,
    headerIcon: 'variable',
    sortable: false,
    headerType: HeaderType.ICON_AND_TEXT,
    cellType: I4BCellType.ASSET_VARIABLE_CELL,
    cellTypeOptions: {
      dataType: 'event'
    },
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '10%',
    cellWidth: '150',
    type: 'start center',
    valueGetter: 'context.assetVariable'
  },
  {
    order: 6,
    id: 'context.assetVariable',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VALUE',
    catalogName: 'ASSET VARIABLE VALUE',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.RICH_VARIABLE,
    valueGetter: 'context.assetVariable.value',
    cellTypeOptions: {
      dataType: '',
      unit: 'unit',
      value: 'value',
      tooltip: 'datetime'
    },
    isLink: true,
    clickEvent: {
      type: 'openGraph',
      options: {}
    },
    width: '8%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 8,
    id: 'type',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TYPE',
    catalogName: 'EVENT TYPE',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      translateKey: 'EVENTS.EVENT_TYPES.'
    },
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 9,
    id: 'severity',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SEVERITY',
    catalogName: 'SEVERITY',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.SEVERITY_CELL,
    cellTypeOptions: {},
    isLink: false,
    width: '7%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 10,
    id: 'class',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CLASS',
    catalogName: 'EVENT CLASS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '9%',
    cellWidth: '110',
    type: 'start center'
  },
  {
    order: 11,
    id: 'totalComments',
    name: '',
    catalogName: 'COMMENTS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.COMMENT,
    isLink: false,
    width: '4%',
    cellWidth: '40',
    type: 'center center'
  },
  {
    order: 12,
    id: 'status',
    name: '',
    catalogName: 'EVENT STATUS',
    default: true,
    sortable: false,
    sortProperty: 'status',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ICON,
    cellTypeOptions: {
      tooltip: 'EVENTS.STATUS'
    },
    isLink: false,
    width: '4%',
    cellWidth: '40',
    type: 'center center'
  },
  {
    order: 13,
    id: 'acknowledge.datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ACKNOWLEDGE_DATETIME',
    catalogName: 'ACKNOWLEDGE TIME',
    default: false,
    sortable: true,
    sortProperty: 'acknowledge.datetime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },

  {
    order: 14,
    id: 'acknowledge.user',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ACKNOWLEDGE_USER',
    catalogName: 'ACKNOWLEDGE USER',
    sortable: false,
    default: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.USER_CELL,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '160',
    type: 'start center'
  },
  {
    order: 15,
    id: 'close.datetime',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CLOSE_DATETIME',
    catalogName: 'CLOSE TIME',
    sortable: true,
    sortProperty: 'close.datetime',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 16,
    id: 'close.user',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CLOSE_USER',
    catalogName: 'CLOSE USER',
    sortable: false,
    default: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.USER_CELL,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '160',
    type: 'start center'
  },
  {
    order: 17,
    id: 'context.site.id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_ID',
    catalogName: 'TECHNICAL SITE ID',
    sortable: true,
    default: false,
    sortProperty: 'context.site.id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigateToSite'
    },
    width: '10%',
    cellWidth: '250',
    type: 'start center'
  },
  {
    order: 18,
    id: 'context.site.type',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_TYPE',
    catalogName: 'SITE TYPE',
    sortable: true,
    sortProperty: 'context.site.type',
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      translateKey: 'SITES.CARD.TYPES.'
    },
    isLink: false,
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 19,
    id: 'context.asset.id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_ID',
    catalogName: 'TECHNICAL ASSET ID',
    sortable: true,
    default: false,
    sortProperty: 'context.asset.id',
    headerType: HeaderType.ICON_AND_TEXT,
    headerIcon: 'asset',
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigateToAsset'
    },
    width: '14%',
    cellWidth: '250',
    type: 'start center'
  },
  {
    order: 20,
    id: 'context.asset.status',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_STATUS',
    catalogName: 'ASSET STATUS',
    sortable: true,
    default: false,
    sortProperty: 'context.asset.status',
    headerType: HeaderType.ICON_AND_TEXT,
    headerIcon: 'asset',
    cellType: 'translatedCell',
    cellTypeOptions: {
      withStatusPadding: true,
      class: {
        production: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
        maintenance: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' },
        decommissioned: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' }
      }
    },
    isLink: false,
    width: '10%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 21,
    id: 'context.entity.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
    catalogName: 'ENTITY NAME',
    sortable: true,
    default: false,
    sortProperty: 'context.entity.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '130',
    type: 'start center'
  },
  {
    order: 22,
    id: 'connector',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CONNECTOR',
    catalogName: 'CONNECTOR ID',
    sortable: false,
    default: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '80',
    type: 'start center'
  },
  {
    order: 7,
    id: 'label',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LABEL',
    catalogName: 'LABEL',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    clickEvent: '',
    width: '10%',
    cellWidth: '80',
    type: 'start center'
  }
];
