@if (total()) {
  <ul style="overflow: auto; max-height: 224px">
    @for (asset of assets(); track asset) {
      <li (click)="onClick(asset)" class="detail-assets-card-item" fxLayout="row" fxLayoutAlign="start center" matRipple
          matRippleColor="rgba(241,249,249,0.4)">
        <mat-icon class="detail-assets-card-icon" color="#394c5a" svgIcon="asset"></mat-icon>
        <div class="detail-assets-card-container">
          <span class="detail-assets-card-name">{{ asset.name }}</span>
          <span class="detail-assets-card-reference"
          >{{ 'DETAIL_ASSETS_CARD_COMPONENT.SHIP_TO' | translate }} {{ asset.erpReference.shipTo | infoDisplay }}</span
          >
        </div>
      </li>
    }
  </ul>
} @else {
  <div class="detail-assets-card">
    <div class="detail-assets-card-empty" fxLayout="row" fxLayoutAlign="center center">
      {{ 'DETAIL_ASSETS_CARD_COMPONENT.NO_DATA' | translate }} {{ 'IOT_DICTIONARY.asset' | translate }}
    </div>
  </div>
}
