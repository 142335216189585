import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BusinessProfilesService } from '../../services/business-profiles.service';
import { BusinessProfilesTagsApiActions, BusinessProfilesTagsPageActions } from '../actions';

@Injectable()
export class AdminBusinessProfilesTagsEffects {
  updateTagsForBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesTagsPageActions.updateTagsForBusinessProfile),
      mergeMap((action) =>
        this.businessProfilesService.updateTagsForBusinessProfile(action.businessProfileId, action.tagsToAdd).pipe(
          map((addedTags) => BusinessProfilesTagsApiActions.updateTagsForBusinessProfileSuccess({ addedTags })),
          catchError((error) => of(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private businessProfilesService: BusinessProfilesService
  ) {}
}
