import { Injectable } from '@angular/core';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { fromAdminBusinessProfiles } from '@iot-platform/iot4bos/ui/backoffice';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions, FavoriteViewsService } from '@iot-platform/shared/components';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BusinessProfilesFavoriteViewsApiActions, BusinessProfilesFavoriteViewsPageActions, BusinessProfilesPageActions } from '../actions';

@Injectable()
export class AdminBusinessProfilesFavoriteViewsEffects {
  updateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.updateFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.updateFavoriteView(action.favoriteViewToUpdate).pipe(
          map((updatedFavoriteView) => BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewSuccess({ updatedFavoriteView })),
          catchError((error) => of(FavoriteViewsActions.updateFavoriteViewError({ error })))
        )
      )
    )
  );

  deleteFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.deleteFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.deleteFavoriteView(action.favoriteViewToDelete).pipe(
          map((deletedFavoriteView) => BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewSuccess({ deletedFavoriteView })),
          catchError((error) => of(FavoriteViewsActions.deleteFavoriteViewError({ error })))
        )
      )
    )
  );

  addFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.addFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.saveFavoriteView(action.favoriteViewToAdd).pipe(
          concatMap((addedFavoriteView) => of(addedFavoriteView).pipe(withLatestFrom(this.store.select(fromAdminBusinessProfiles.getSelectedBusinessProfile)))),
          map(([addedFavoriteView, selectedBP]) => {
            if (addedFavoriteView.businessProfileId === selectedBP?.id) {
              return BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess({ addedFavoriteView });
            }
            return BusinessProfilesFavoriteViewsApiActions.addFavoriteViewInAnotherBusinessProfileSuccess();
          }),
          catchError((error) => of(FavoriteViewsActions.addFavoriteViewError({ error })))
        )
      )
    )
  );

  duplicateGridThenAddFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.duplicateGridThenAddFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.duplicateAndShareGrid(action.grid, action.favoriteViewToAdd).pipe(
          switchMap(({ grid, favoriteView }) =>
            this.favoriteViewsService.saveFavoriteView({ ...favoriteView, gridId: grid.id }).pipe(
              concatMap((addedFavoriteView) =>
                of(addedFavoriteView).pipe(withLatestFrom(this.store.select(fromAdminBusinessProfiles.getSelectedBusinessProfile)))
              ),
              map(([addedFavoriteView, selectedBP]) => {
                if (addedFavoriteView.businessProfileId === selectedBP?.id) {
                  return BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess({ addedFavoriteView, grid });
                }
                return BusinessProfilesFavoriteViewsApiActions.duplicateGridThenAddFavoriteViewSuccess();
              }),
              catchError((error) => of(FavoriteViewsActions.addFavoriteViewError({ error })))
            )
          ),
          catchError((error) => of(BusinessProfilesFavoriteViewsApiActions.duplicateGridThenAddFavoriteViewFailure({ error })))
        )
      )
    )
  );

  duplicateGridThenUpdateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.duplicateGridThenUpdateFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.duplicateAndShareGrid(action.grid, action.favoriteViewToUpdate).pipe(
          switchMap(({ grid, favoriteView }) =>
            this.favoriteViewsService.updateFavoriteView({ ...favoriteView, gridId: grid.id }).pipe(
              map((duplicatedFavoriteView) =>
                BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewSuccess({ updatedFavoriteView: duplicatedFavoriteView, grid })
              ),
              catchError((error) => of(FavoriteViewsActions.updateFavoriteViewError({ error })))
            )
          ),
          catchError((error) => of(BusinessProfilesFavoriteViewsApiActions.duplicateGridThenUpdateFavoriteViewFailure({ error })))
        )
      )
    )
  );

  duplicateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.duplicateFavoriteView),
      switchMap((action) =>
        this.favoriteViewsService.saveFavoriteView(action.favoriteViewToDuplicate).pipe(
          concatMap((duplicatedFavoriteView) =>
            of(duplicatedFavoriteView).pipe(withLatestFrom(this.store.select(fromAdminBusinessProfiles.getSelectedBusinessProfile)))
          ),
          map(([duplicatedFavoriteView, selectedBP]) => {
            if (duplicatedFavoriteView.businessProfileId === selectedBP?.id) {
              return BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewSuccess({ duplicatedFavoriteView });
            }
            return BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewInAnotherBusinessProfileSuccess();
          }),
          catchError((error) => of(FavoriteViewsActions.duplicateFavoriteViewError({ error })))
        )
      )
    )
  );

  duplicateGridThenFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsPageActions.duplicateGridAndFavoriteView),
      switchMap((action) =>
        this.favoriteViewsService.duplicateAndShareGrid(action.gridToDuplicate, action.favoriteViewToDuplicate).pipe(
          switchMap(({ grid, favoriteView }) =>
            this.favoriteViewsService.saveFavoriteView({ ...favoriteView, gridId: grid.id }).pipe(
              map(() => BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewInAnotherBusinessProfileSuccess()),
              catchError((error) => of(FavoriteViewsActions.duplicateFavoriteViewError({ error })))
            )
          ),
          catchError((error) => of(BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewFailure({ error })))
        )
      )
    )
  );

  addFavoriteViewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess),
      concatMap(({ addedFavoriteView, grid }) =>
        !grid
          ? [FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: addedFavoriteView })]
          : [FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: addedFavoriteView }), GridsDbActions.loadGrids({ concept: addedFavoriteView.concept })]
      )
    )
  );

  synchronizeFavoriteViewAfterSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.synchronizeFavoriteViewAfterSave),
      map(({ favoriteView }) => BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess({ addedFavoriteView: favoriteView }))
    )
  );

  updateFavoriteViewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewSuccess),
      concatMap(({ updatedFavoriteView, grid }) =>
        !grid
          ? [FavoriteViewsActions.updateFavoriteViewSuccess({ favoriteView: updatedFavoriteView })]
          : [
              FavoriteViewsActions.updateFavoriteViewSuccess({ favoriteView: updatedFavoriteView }),
              GridsDbActions.loadGrids({ concept: updatedFavoriteView.concept })
            ]
      )
    )
  );

  deleteFavoriteViewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewSuccess),
      map(({ deletedFavoriteView }) => FavoriteViewsActions.deleteFavoriteViewSuccess({ deletedFavoriteView }))
    )
  );

  duplicateFavoriteViewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewSuccess),
      map(({ duplicatedFavoriteView }) => FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: duplicatedFavoriteView }))
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess,
          BusinessProfilesFavoriteViewsApiActions.addFavoriteViewInAnotherBusinessProfileSuccess,
          BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewSuccess,
          BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewSuccess,
          BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewInAnotherBusinessProfileSuccess,
          BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewSuccess,
          BusinessProfilesFavoriteViewsApiActions.duplicateGridThenAddFavoriteViewSuccess,
          BusinessProfilesFavoriteViewsApiActions.duplicateGridThenUpdateFavoriteViewSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesFavoriteViewsApiActions.addFavoriteViewFailure,
          BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewFailure,
          BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewFailure,
          BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewFailure,
          BusinessProfilesFavoriteViewsApiActions.duplicateGridThenAddFavoriteViewFailure,
          BusinessProfilesFavoriteViewsApiActions.duplicateGridThenUpdateFavoriteViewFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileSuccess,
          BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly favoriteViewsService: FavoriteViewsService,
    private readonly store: Store
  ) {}
}
