import { HeaderType } from '@iot-platform/models/grid-engine';

export const escalationProtocolsMetadataSettings = {
  metadata: {
    url: '/escalation-protocols'
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'escalationProtocols',
    expanded: false
  },
  masterViewTable: {
    bluePrint: {
      buttonColumn: {
        id: 'button',
        order: 90,
        width: '6%',
        headerType: HeaderType.BASIC,
        cellType: 'button',
        type: 'end center',
        enableConditions: [],
        visibleConditions: [
          [
            { key: 'canUpdateProtocol', value: true },
            { key: 'canDeleteProtocol', value: true }
          ]
        ],
        singleActions: [
          {
            key: 'edit',
            label: 'IOT_DICTIONARY.EDIT',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateProtocol', value: true }]]
          },
          {
            key: 'navigate',
            label: 'IOT_DICTIONARY.CONFIGURE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateProtocol', value: true }]]
          },
          {
            key: 'delete',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canDeleteProtocol', value: true }]]
          }
        ]
      },
      columns: [],
      expandedPanel: {}
    }
  },
  masterViewComments: {}
};
