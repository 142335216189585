@if (currentUser()) {
  <div class="user-profile-info-container">
    <div class="user-profile-info-section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h2 class="fw-600">
        {{ 'USERS.PROFILE_PREFERENCES.API_KEYS' | translate }}
        @if (apiKeys().length) {
          <span>{{ '(' + apiKeys().length + '/2)' }}</span>
        }
      </h2>
      <hr class="regular-1px-line" fxFlex />
      <button
        (click)="onAddApiKey()"
        [disabled]="maxApiKeysReached()"
        [matTooltip]="'USERS.PROFILE_PREFERENCES.ADD_API_KEY_TOOLTIP' | translate"
        class="regular-round-button"
        id="apiKeyAddButton"
        mat-icon-button
        matTooltipClass="regular-tooltip"
        matTooltipPosition="above"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="api-keys" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <div fxFlex>
        <a [href]="synapseApiCatalogUrl" class="api-keys__link" target="_blank">
          {{ 'USERS.PROFILE_PREFERENCES.SYNAPSE_API_CATALOG' | translate }}
        </a>
      </div>
      <div fxFlex>
        <ul>
          @for (key of apiKeys(); track key; let index = $index) {
            <li>
              <iot-platform-ui-detail-popup-display-property [property]="'key ' + (index + 1) + ' '" [value]="key.id">
                <div class="api-keys__buttons" fxFlex fxLayout="row" fxLayoutAlign="end center">
                  <button
                    (click)="onGetApikey(key.id)"
                    [matTooltip]="'USERS.PROFILE_PREFERENCES.GET_API_KEY_TOOLTIP' | translate"
                    id="apiKeyGetButton"
                    mat-icon-button
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="above"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    (click)="onDeleteApiKey(key.id)"
                    [matTooltip]="'USERS.PROFILE_PREFERENCES.DELETE_API_KEY_TOOLTIP' | translate"
                    id="apiKeyDeleteButton"
                    mat-icon-button
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="above"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </iot-platform-ui-detail-popup-display-property>
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}
