import { I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { I4BAssetEventsButtonColumn, I4BColumn, I4BSelectionColumn } from './definitions';
import { AssetEventsGridData, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export class DefaultActiveAssetEventsPopupGrid implements I4BGrid<I4BGridOptions, AssetEventsGridData> {
  masterview = 'active-asset-events-popup';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name!: string;
  userId!: string;
  data!: AssetEventsGridData;
  gridOptions: I4BGridOptions = {
    endPoint: '/asset-events',
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [],
    buttonColumn: { enabled: true, className: new I4BAssetEventsButtonColumn() },
    selectionColumn: {
      enabled: true,
      className: new I4BSelectionColumn()
    }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
