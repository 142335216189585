import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const EMAIL_PATTERN =
  /^(?=.{1,254}$)(?=.{1,64}@)[-0-9A-Z_a-z+]+(\.[-0-9A-Z_a-z+]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z])?)+$/;

export class EmailValidators {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => (!control.value ? null : EMAIL_PATTERN.test(control.value) ? null : { email: true });
  }
}
