import { NgClass, NgStyle } from '@angular/common';
import { Component, Input, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot-platform-ui-status-button',
  standalone: true,
  imports: [MatButtonModule, NgClass, FlexLayoutModule, MatTooltip, TranslateModule, NgStyle],
  templateUrl: './status-button.component.html',
  styleUrl: './status-button.component.scss'
})
export class StatusButtonComponent {
  cssClassName = input<string>('');
  style = input<any>();
  color = input<string>('primary');
  tooltip = input<string>('');
  disabled = input<boolean>(false);
  preventMainAction = input<boolean>(false);
  actionLabel = input<string>();
  actionSubLabel = input<string>();
  displayNestedAction = input<boolean>(false);
  nestedActionLabel = input<string>();
  @Input() onDispatchEvent: () => void; // Used for dynamic injection inputs

  dispatchEvent = output<void>();
}
