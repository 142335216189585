<div class="concept-name" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon [svgIcon]="concept()" class="tag-category-list-concept-icon"></mat-icon>
  <span>{{ 'TAG_CATEGORY_LIST_COMPONENT.CONCEPTS.' + concept() | translate | uppercase }}</span>
  <hr class="regular-1px-line" fxFlex />
  @if (canManageTags()) {
    @if (tagCategoriesByConcept()?.length) {
      <button
        [matTooltip]="'TAG_CATEGORY_LIST_COMPONENT.TOOLTIP_EDIT' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button"
        (click)="onManageTags()"
      >
        <mat-icon>edit</mat-icon>
      </button>
    } @else {
      <button
        [matTooltip]="(canCreate() ? 'TAG_CATEGORY_LIST_COMPONENT.TOOLTIP_CREATE' : 'TAG_CATEGORY_LIST_COMPONENT.TOOLTIP_ADD') | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button"
        (click)="onManageTags()"
      >
        <mat-icon>add</mat-icon>
      </button>
    }
  }
</div>
@for (tagCategory of tagCategoriesByConcept(); track tagCategory.id) {
  <div fxLayout="row" fxFlayoutAlign="start center" class="tag-category">
    @if (labelOnly()) {
      <span fxFlex="15%" class="tag-category-name"
        >{{ tagCategory.name }}
        @if (tagCategory.mandatory) {
          <span class="mandatory-warning">&nbsp;*</span>
        }
      </span>
    }
    <iot-platform-ui-tag-label-list [tagCategory]="tagCategory" [labelOnly]="labelOnly()"></iot-platform-ui-tag-label-list>
  </div>
}
