import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, CommonGenericModel, Environment, Filter } from '@iot-platform/models/common';
import { Dashboard, DashboardWidget } from '@iot-platform/models/dashboards';
import { catchError, forkJoin, map, Observable, of, retry, switchMap } from 'rxjs';

export interface BackendGroupingResponse {
  data: { legend: string; color: string; count: number }[];
  options?: { showLegends: boolean };
}

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);

  getDashboardData(dashboard: Dashboard, filters: Filter[]): Observable<DashboardWidget[]> {
    return forkJoin(dashboard.widgets.map((w) => this.getWidgetData(dashboard.concept, w, filters)));
  }

  getWidgetData(concept: string, widget: DashboardWidget, filters: Filter[]): Observable<DashboardWidget> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    if (filters) {
      filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    widget.mandatoryFilters.forEach((filter) => {
      params = params.append(filter.criteriaKey, filter.value);
    });

    if (widget.options?.preliminaryRequest) {
      let preliminaryRequestParams: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

      if (widget.options.preliminaryRequest.filters) {
        widget.options.preliminaryRequest.filters.forEach((filter) => {
          preliminaryRequestParams = preliminaryRequestParams.append(filter.criteriaKey, filter.value);
        });
      }

      return this.http
        .get<
          CommonApiListResponse<CommonGenericModel>
        >(`${this.environment.api.url}${widget.options.preliminaryRequest.endpoint}`, { params: preliminaryRequestParams })
        .pipe(
          map((response) => response.content),
          switchMap((responseData) => {
            if (widget.options.matchingObject) {
              const matchingObject = responseData.find(
                (el) => el[widget.options.matchingObject.matchingProperty] === widget.options.matchingObject.matchingValue
              );
              if (matchingObject) {
                params = params.set(widget.options.matchingObject.criteriaKey, matchingObject[widget.options.matchingObject.criteriaValue]);
              }
            }
            return this.getFormattedWidgetData(concept, widget, params);
          })
        );
    } else {
      return this.getFormattedWidgetData(concept, widget, params);
    }
  }

  getFormattedWidgetData(concept: string, widget: DashboardWidget, params: HttpParams): Observable<DashboardWidget> {
    return this.http.get<BackendGroupingResponse>(`${this.environment.api.url}${this.environment.api.endpoints.widgets}/${concept}`, { params }).pipe(
      retry(1),
      catchError(() => of({ ...widget, data: [] } as DashboardWidget)),
      map((response: BackendGroupingResponse) => ({
        ...widget,
        data: response.data.filter((d) => d.count > 0).sort(SortUtil.sortBy('count', false)),
        options: { ...widget.options, ...response.options }
      }))
    );
  }
}
