import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, signal, WritableSignal } from '@angular/core';
import { UserNamePipe } from '@iot-platform/pipes';

@Component({
  selector: 'grid-engine-user-cell',
  standalone: true,
  templateUrl: './user-cell.component.html',
  imports: [UserNamePipe],
  providers: [UserNamePipe],
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<ICellRendererParams> = signal(null);

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
