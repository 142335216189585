export interface AssetVariableThresholds {
  operator: string | null;
  values: AssetVariableThreshold[];
}

export interface AssetVariableThreshold {
  calculate?: {
    formulaName?: string;
  };
  cellColor?: string;
  lineColor?: string;
  name?: string;
  position?: number;
  value?: number;
}

export enum AssetVariableThresholdOperator {
  INFERIOR = '<',
  EQUAL = '=',
  SUPERIOR = '>'
}

export enum ThresholdType {
  STATIC = 'static',
  DYNAMIC = 'dynamic'
}
