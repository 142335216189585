import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';

import { Asset, Device, SiteAssociationByAsset, SiteAssociationByDevice } from '@iot-platform/models/i4b';

import { AssetDeviceAssociationItemDefaultComponent } from './asset-device-association-item-default.component';
import { AssetDeviceAssociationItemFactory } from './asset-device-association-item-factory';
import { AssetDeviceAssociationItemDirective } from './asset-device-association-item.directive';

@Component({
  selector: 'iot4bos-ui-asset-device-association-item',
  templateUrl: './asset-device-association-item.component.html',
  styleUrls: ['./asset-device-association-item.component.scss']
})
export class AssetDeviceAssociationItemComponent implements OnInit, OnChanges {
  @Input() association!: SiteAssociationByDevice | SiteAssociationByAsset;
  @Input() displayBy!: string;
  @Input() navigationMode!: boolean;
  @Input() selectedItemId!: string;
  @Input() canCreateAsset = false;
  @Input() canCreateDevice = false;

  @Output() dispatchAction: EventEmitter<{ action: string; data: Asset | Device }> = new EventEmitter();

  @ViewChild(AssetDeviceAssociationItemDirective, { static: true }) iot4bosAssetDeviceAssociation!: AssetDeviceAssociationItemDirective;

  constructor(private readonly assetDeviceAssociationFactory: AssetDeviceAssociationItemFactory) {}

  ngOnInit() {
    this.createAssetDeviceAssociation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedItemId')) {
      this.createAssetDeviceAssociation();
    }
  }

  initViewContainerRef(): ViewContainerRef {
    const viewContainerRef = this.iot4bosAssetDeviceAssociation.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef;
  }

  createAssetDeviceAssociation() {
    const componentRef = this.assetDeviceAssociationFactory.createComponent(this.displayBy, this.initViewContainerRef());
    if (componentRef) {
      this.configure(this.displayBy, componentRef.instance);
      componentRef.instance.openSelectedItem.subscribe((event) => {
        if (event) {
          this.dispatchAction.emit(event);
        }
      });
    }
  }

  configure(type: string, component: AssetDeviceAssociationItemDefaultComponent): AssetDeviceAssociationItemDefaultComponent | null {
    component.canCreateAsset = this.canCreateAsset;
    component.canCreateDevice = this.canCreateDevice;
    component.navigationMode = this.navigationMode;
    component.selectedItemId = this.selectedItemId;

    if (type === 'assets') {
      component.mainItem = (this.association as SiteAssociationByAsset).asset;
      component.associatedItems = (this.association as SiteAssociationByAsset).devices;
      return component;
    }

    if (type === 'devices') {
      component.mainItem = (this.association as SiteAssociationByDevice).device;
      component.associatedItems = (this.association as SiteAssociationByDevice).assets;
      return component;
    }

    return null;
  }
}
