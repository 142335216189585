import { PlatformRequest, ProductCatalog } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listProductCatalogs = createAction('[Product Catalogs] List Product Catalogs', props<{ request: PlatformRequest }>());
//
export const addProductCatalog = createAction('[Product Catalogs] Add Product Catalog', props<{ productCatalogToAdd: ProductCatalog }>());
//
export const updateProductCatalog = createAction('[Product Catalogs] Update Product Catalog', props<{ productCatalogToUpdate: ProductCatalog }>());
//
export const deleteProductCatalog = createAction('[Product Catalogs] Delete Product Catalog', props<{ productCatalogToDelete: ProductCatalog }>());
