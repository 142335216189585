export const deviceVariablesMetadataSettings = {
  metadata: {
    url: '/variables',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'variables',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 10,
      refreshDelay: 120,
      multipleSelection: true,
      renderer: 'table',
      displayOption: 'boxed',
      selectionColumn: {
        id: 'selection',
        name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SELECTION',
        width: '4%',
        type: 'start center'
      },
      buttonColumn: {
        id: 'button',
        name: '',
        width: '2%',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [[{ key: 'canUpdateDevice', value: true }]],
        bulkActions: [
          {
            order: 0,
            key: 'deleteBulk',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
          },
          {
            order: 1,
            key: 'resetLastValueBulk',
            label: 'IOT_DICTIONARY.RESET_LAST_VALUE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateDevice', value: true }], [{ key: 'canResetLastValue', value: true }]]
          }
        ],
        singleActions: [
          {
            order: 1,
            key: 'delete',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
          },
          {
            order: 2,
            key: 'resetLastValue',
            label: 'IOT_DICTIONARY.RESET_LAST_VALUE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateDevice', value: true }], [{ key: 'canResetLastValue', value: true }]]
          }
        ]
      },
      columns: [],
      expandedPanel: {}
    }
  },
  masterViewComments: {}
};
