export enum LocalStorageKeys {
  STORAGE_SELECTED_ROW_ID = 'selectedRowId',
  STORAGE_MV_ORIGIN_KEY = 'mvorigin',
  STORAGE_ORIGIN_KEY = 'origin',
  STORAGE_PRIVILEGES_KEY = 'privileges',
  STORAGE_BUSINESS_PROFILE_KEY = 'business-profile',
  STORAGE_ID_TOKEN_KEY = 'idToken',
  STORAGE_SESSION_KEY = 'session',
  STORAGE_ACTIVE_SITE_ID_KEY = 'activeSiteId',
  STORAGE_ACTIVE_ITEM_ID_KEY = 'activeItemId',
  STORAGE_PAGE_TYPE_KEY = 'pageType',
  STORAGE_SITES_PAGE_TYPE_KEY = 'sites-pageType',
  STORAGE_ASSETS_PAGE_TYPE_KEY = 'assets-pageType',
  STORAGE_DEVICES_PAGE_TYPE_KEY = 'devices-pageType',
  STORAGE_SIDEBAR_OPENED_KEY = 'sidebar.opened',
  STORAGE_MV_ASSETS_TABLE_STATE_KEY = 'mv-assets.table-state',
  STORAGE_MV_ASSET_EVENTS_TABLE_STATE_KEY = 'mv-asset-events.table-state',
  STORAGE_MV_DEVICE_EVENTS_TABLE_STATE_KEY = 'mv-device-events.table-state',
  STORAGE_MV_CONNECTORS_TABLE_STATE_KEY = 'mv-connectors.table-state',
  STORAGE_MV_DEVICES_TABLE_STATE_KEY = 'mv-devices.table-state',
  STORAGE_MV_SITES_TABLE_STATE_KEY = 'mv-sites.table-state',
  STORAGE_MV_ESCALATION_PROTOCOL_TABLE_STATE_KEY = 'mv-escalation-protocol.table-state',
  STORAGE_SSO_TOKEN_ID = 'id_token',
  STORAGE_SSO_ACCESS_TOKEN = 'access_token',
  STORAGE_SSO_TOKEN_TYPE = 'token_type',
  STORAGE_SSO_REFRESH_TOKEN = 'refresh_token',
  STORAGE_SSO_AUTH_SOURCE = 'auth_source',
  STORAGE_SSO_LOGGED_IN_WITH_SSO = 'logged_in_with_SSO',
  STORAGE_SSO_EXPIRES_IN = 'token_expires_in',
  STORAGE_SSO_EXPIRES_AT = 'token_expires_at',
  STORAGE_SSO_LOGGED_IN_AT = 'logged_in_at',
  STORAGE_USER_PREFERENCES = 'userPreferences',
  STORAGE_APP_BUILD_INFO = 'app-build-info',
  STORAGE_HIDE_RECYCLING_POPUP = 'hide-recycling-popup',
  STORAGE_MAP_LAYERS_KEY = 'map-layers'
}
