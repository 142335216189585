<div [ngClass]="color" class="iot-platform-ui-popup popup {{ data.cssClass }}">
  <div class="popup-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="popup-title">{{ popupInfo().title }}</h1>
    @if (data.type === 'delete' || 'reset' || 'subscribe' || 'unsubscribe') {
      <button (click)="close(false)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>

  @if (data.type !== 'subscribe' && data.type !== 'unsubscribe') {
    <div class="popup-content" fxLayout="column" fxLayoutAlign="start ">
      <span class="popup-value">{{ popupMessage() | translate }}</span>
      <span class="popup-message">{{ popupInfo().message }}</span>
    </div>
  }

  @if (data.type === 'subscribe' || data.type === 'unsubscribe') {
    <div class="popup-content" fxLayout="column" fxLayoutAlign="center center">
      <span class="popup-message">{{ popupInfo().message }}</span>
      <div class="topic-name" fxLayout="row">
        <mat-icon [svgIcon]="'subscribe'" class="icon"></mat-icon>
        <span class="popup-value">{{ data.value | translate }}</span>
      </div>
    </div>
  }

  <div class="popup-footer" fxLayout="row" fxLayoutAlign="end center">
    @if (data.type === 'delete') {
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popupInfo().cancel }}</button>
      <button (click)="close(true)" class="popup-delete" mat-button>{{ popupInfo().delete }}</button>
    }

    @if (data.type === 'subscribe') {
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popupInfo().cancel }}</button>
      <button (click)="close(true)" class="popup-subscribe" mat-button>{{ popupInfo().subscribe }}</button>
    }

    @if (data.type === 'unsubscribe') {
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popupInfo().cancel }}</button>
      <button (click)="close(true)" class="popup-unsubscribe" mat-button>{{ popupInfo().unsubscribe }}</button>
    }

    @if (data.type === 'reset') {
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popupInfo().cancel }}</button>
      <button (click)="close(true)" class="popup-reset" mat-button>{{ popupInfo().reset }}</button>
    }

    @if (data.type === 'info') {
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popupInfo().cancel }}</button>
      <button (click)="close(true)" class="popup-info" mat-button>{{ popupInfo().confirm }}</button>
    }

    @if (data.type === 'confirm') {
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popupInfo().cancel }}</button>
      <button (click)="close(true)" class="popup-confirm" mat-button>{{ popupInfo().confirm }}</button>
    }

    @if (data.type === 'error') {
      <button (click)="close(false)" class="popup-close" mat-button>{{ popupInfo().close }}</button>
    }
  </div>
</div>
