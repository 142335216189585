import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FavoriteView } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-ui-home-favorite-view',
  templateUrl: './home-favorite-view.component.html',
  styleUrls: ['./home-favorite-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeFavoriteViewComponent {
  @Input() favoriteView: FavoriteView;
  @Input() isLoading: boolean;

  @Output() navigateTo: EventEmitter<FavoriteView> = new EventEmitter<FavoriteView>();
  @Output() editFavoriteView: EventEmitter<FavoriteView> = new EventEmitter<FavoriteView>();
  @Output() deleteFavoriteView: EventEmitter<FavoriteView> = new EventEmitter<FavoriteView>();

  onFavoriteViewClicked(): void {
    this.navigateTo.emit(this.favoriteView);
  }
}
