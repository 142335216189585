export const schedulerSelectedDevicesMetadataSettings = {
  metadata: {
    url: '/schedulers',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'devicesByScheduler',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      renderer: 'table',
      displayOption: 'boxed',
      autoRefresh: false,
      pageSize: 10,
      refreshDelay: 120,
      multipleSelection: true,
      selectionColumn: {
        id: 'selection',
        name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SELECTION',
        width: '2%',
        type: 'start center'
      },
      buttonColumn: {
        id: 'button',
        name: '',
        width: '2%',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [[{ key: 'canUpdateScheduler', value: true }]],
        bulkActions: [
          {
            key: 'removeDevices',
            label: 'SCHEDULERS.MASTER_VIEW.CTA_LABELS.REMOVE_DEVICES',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateScheduler', value: true }]]
          }
        ],
        singleActions: [
          {
            key: 'removeDevice',
            label: 'SCHEDULERS.MASTER_VIEW.CTA_LABELS.REMOVE_DEVICE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateScheduler', value: true }]]
          }
        ]
      },
      columns: []
    }
  },
  masterViewComments: {}
};
