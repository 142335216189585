import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FavoriteView } from '@iot-platform/models/common';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private httpClient: HttpClient) {}

  getFavoriteViews(): FavoriteView[] {
    return;
  }
}
