import { Subscriber } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const listProtocolSubscribedToTopicByTopicIdSuccess = createAction(
  '[Topics] List Escalation Protocols Subscribed to Topic By Topic Id Success',
  props<{ subscribers: Subscriber[] }>()
);
export const listProtocolSubscribedToTopicByTopicIdFailure = createAction(
  '[Topics] List Escalation Protocols Subscribed to Topic By Topic Id Failure',
  props<{ error: any }>()
);
//
export const subscribeToTopicByProtocolIdSuccess = createAction(
  '[Topics] Subscribe to Topic By Escalation Protocol Id Success',
  props<{ subscribedProtocol: Subscriber }>()
);
export const subscribeToTopicByProtocolIdFailure = createAction('[Topics] Subscribe to Topic By Escalation Protocol Id Failure', props<{ error: any }>());
//
export const unsubscribeToTopicByProtocolIdSuccess = createAction(
  '[Topics] Unsubscribe to Topic By Escalation Protocol Id Success',
  props<{ unsubscribedProtocol: Subscriber }>()
);
export const unsubscribeToTopicByProtocolIdFailure = createAction('[Topics] Unsubscribe to Topic By Escalation Protocol Id Failure', props<{ error: any }>());
