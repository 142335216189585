import { Sort } from '@angular/material/sort';

import { gridSortModel, PlatformRequest } from '@iot-platform/models/common';
import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadDeviceEvents = createAction('[DeviceEvents] Load DeviceEvents', props<{ request: PlatformRequest }>());
//
export const loadMVSettings = createAction('[DeviceEvents] Load DeviceEvents Settings', props<{ settingName: string }>());
export const saveMVSettings = createAction('[DeviceEvents] Save DeviceEvents Settings', props<{ name: string; values: any }>());
//
export const loadSiteById = createAction('[DeviceEvents] Load Site By Id', props<{ siteId: string }>());
//
export const saveTableState = createAction('[DeviceEvents] Save Table State', props<{ tableState: { selected: DeviceEvent; checked: DeviceEvent[] } }>());
//
export const loadDeviceById = createAction('[DeviceEvents] Load Device By Id', props<{ deviceId: string }>());
//
export const loadDeviceVariableById = createAction('[DeviceEvents] Load Device Variable By Id', props<{ deviceVariableId: string }>());
//
export const loadAssetById = createAction('[DeviceEvents] Load Asset By Id', props<{ assetId: string }>());
//
export const loadAssetVariableById = createAction('[DeviceEvents] Load Asset Variable By Id', props<{ assetVariableId: string }>());
//
export const loadTagsByDeviceEventId = createAction('[DeviceEvents] Load Tags By DeviceEvent Id', props<{ deviceEventId: string }>());
//
export const updateStatusByDeviceEventId = createAction(
  '[DeviceEvents] Update Status By DeviceEvent Id',
  props<{ status: { deviceEventId: string; value: string } }>()
);
//
export const bulkUpdateStatusByDeviceEventId = createAction(
  '[DeviceEvents] Bulk Update Status By DeviceEvent Id',
  props<{ deviceEventIds: string[]; status: string }>()
);

export const newBulkUpdateStatusByDeviceEventId = createAction(
  '[DeviceEvents] Bulk Update Status By Device Event Id v2',
  props<{ deviceEventIds: string[]; status: string }>()
);

export const saveInitialSort = createAction('[DeviceEvents] Save Initial sort', props<{ initialSort: gridSortModel[] | Sort }>());
