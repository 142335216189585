import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { DataGuardCheck, DataGuardCheckDetail, DataGuardCheckDetailApiResponse } from '@iot-platform/models/data-guard';
import { Concept, Site } from '@iot-platform/models/i4b';
import { combineLatest, debounceTime, of, switchMap } from 'rxjs';
import { NavigationApi } from '../../+state/navigation.api';
import { AbstractI4BDataGuardService } from '../../../services/abstract-i4b-data-guard.service';

@Injectable({
  providedIn: 'root'
})
export class DataGuardSiteService extends AbstractI4BDataGuardService<Site, Site> {
  // Injections
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  // Inheritance implementation
  concept: Concept = Concept.SITE;
  sourceElementForDetailedChecks = this.navigationApi.site;
  sourceElementForOverviewChecks = this.navigationApi.site;
  isOverviewTabActive = signal(true);
  isDetailTabActive = signal(false);
  loadingTrigger = this.navigationApi.dataGuardOpened;
  loadingTrigger$ = toObservable(this.loadingTrigger);
  checkDetailURL: Signal<string> = computed(() => {
    const site = this.sourceElementForDetailedChecks();
    if (site) {
      return `${this.environment.api.url}${this.environment.api.endpoints.sites}/${site.id}${this.environment.api.endpoints.dataGuardChecks}`;
    }
  });
  checkOverviewURL: Signal<string> = computed(() => {
    const site = this.sourceElementForOverviewChecks();
    if (site) {
      return `${this.environment.api.url}${this.environment.api.endpoints.dataGuardChecks}${this.environment.api.endpoints.sites}/${site.id}`;
    }
  });

  checkDetailURL$ = toObservable(this.checkDetailURL);
  sourceElementForDetailedChecks$ = toObservable(this.sourceElementForDetailedChecks);
  isDetailTabDisabled$ = toObservable(this.isDetailTabDisabled);
  detailedChecks = toSignal(
    combineLatest([this.loadingTrigger$, this.checkDetailURL$, this.sourceElementForDetailedChecks$, this.isDetailTabDisabled$]).pipe(
      debounceTime(10),
      switchMap(([trigger, url, element, tabDisabled]) => {
        if (trigger && url && element && !tabDisabled) {
          return this.getDataGuardCheckDetail(element, url);
        } else {
          return of(undefined);
        }
      })
    )
  );
  checkOverviewURL$ = toObservable(this.checkOverviewURL);
  sourceElementForOverviewChecks$ = toObservable(this.sourceElementForOverviewChecks);
  isOverviewTabDisabled$ = toObservable(this.isOverviewTabDisabled);
  overviewChecks = toSignal(
    combineLatest([
      this.loadingTrigger$,
      this.checkOverviewURL$,
      this.sourceElementForOverviewChecks$,
      this.sourceElementForDetailedChecks$,
      this.isOverviewTabDisabled$
    ]).pipe(
      debounceTime(10),
      switchMap(([trigger, url, sourceElementForOverviewChecks, sourceElementForDetailedChecks, tabDisabled]) => {
        if (trigger && url && sourceElementForOverviewChecks && sourceElementForDetailedChecks && !tabDisabled) {
          return this.getDataGuardChecksOverview(sourceElementForOverviewChecks, sourceElementForDetailedChecks, url);
        } else {
          return of(undefined);
        }
      })
    )
  );

  closeDataGuard(): void {
    this.navigationApi.toggleDataGuard(false);
  }

  navigateTo(check: DataGuardCheck): void {
    if (check.source.concept === 'asset') {
      this.navigationApi.selectAsset(this.sourceElementForOverviewChecks().id, check.source.id);
      this.navigationApi.toggleDataGuard(false);
    } else if (check.source.concept === 'device') {
      this.navigationApi.selectDevice(this.sourceElementForOverviewChecks().id, check.source.id);
      this.navigationApi.toggleDataGuard(false);
    }
  }

  processCheckDetailsFromApiResponse(apiResponse: DataGuardCheckDetailApiResponse): DataGuardCheckDetail[] {
    const categories: string[] = Object.keys(apiResponse.checks);

    return categories.reduce((acc: DataGuardCheckDetail[], category: string) => {
      const checkDetail: DataGuardCheckDetail = {
        category,
        statusCount: DataGuardSiteService.getChecksStatusesCounts(apiResponse.checks[category]),
        errors: this.getFullCheckErrors(apiResponse.checks[category])
      };
      acc.push(checkDetail);
      return acc;
    }, []);
  }
}
