<section fxFlex fxLayout="column" fxLayoutGap="10px">
  <iot-platform-ui-detail-popup-section-header
    headerTitle=" {{ (totalDevices() > 1 ? 'IOT_DICTIONARY.devices' : 'IOT_DICTIONARY.device') | translate }} ({{ totalDevices() }})"
  ></iot-platform-ui-detail-popup-section-header>
  @if (loaded()) {
    <iot-platform-ui-detail-devices-card
      (selectItem)="selectDevice.emit($event)"
      [devices]="devices()"
      [ngClass]="{ invisible: !loaded(), visible: loaded() }"
      [total]="totalDevices()"
    ></iot-platform-ui-detail-devices-card>
  } @else {
    <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-card h-60'"></iot-platform-ui-card-loader>
  }
</section>
