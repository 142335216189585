import { PlatformRequest } from '@iot-platform/models/common';
import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

//
export const loadAssetEventsByTopic = createAction('[AssetEventsByTopic] Load Asset Events By Topic', props<{ request: PlatformRequest }>());
export const loadMvAssetEventsByTopicSettings = createAction('[AssetEventsByTopic] Load MV AssetEventsByTopic Settings', props<{ settingName: string }>());
export const loadTotalActiveAssetEventsByTopic = createAction(
  '[AssetEventsByTopic] Load Total Active Asset Events By Topic',
  props<{ request: PlatformRequest }>()
);
export const saveTableStateByTopic = createAction(
  '[AssetEventsByTopic] Save Table State',
  props<{ tableState: { checked: AssetEvent[]; selected: AssetEvent } }>()
);
export const updateStatusByAssetEventIdByTopic = createAction(
  '[AssetEventsByTopic] Update Status By Asset Event Id',
  props<{ status: { assetEventId: string; value: string } }>()
);
export const bulkUpdateStatusByAssetEventIdByTopic = createAction(
  '[AssetEventsByTopic] Bulk Update Status By Asset Event Id',
  props<{ assetEventIds: string[]; status: string }>()
);
