import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { AbstractDataGuardService } from '@iot-platform/feature/data-guard';
import { CommonGenericModel } from '@iot-platform/models/common';
import {
  DataGuardCheck,
  DataGuardCheckDetail,
  DataGuardCheckDetailApiResponse,
  DataGuardCheckError,
  DataGuardChecksOverviewApiResponse,
  DataGuardCheckStatus,
  DataGuardEventType
} from '@iot-platform/models/data-guard';
import { Concept, SiteType } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { I4BDataGuardHelper } from '../helpers/data-guard/i4b-data-guard.helper';

enum DataGuardOverviewChecksApiResponseKey {
  site = 'site',
  assets = 'assets',
  devices = 'devices'
}

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractI4BDataGuardService<T extends CommonGenericModel, K extends CommonGenericModel> extends AbstractDataGuardService {
  // Abstract elements
  abstract sourceElementForDetailedChecks: Signal<T>;
  abstract sourceElementForOverviewChecks: Signal<K>;
  abstract checkDetailURL: Signal<string>;
  abstract checkOverviewURL: Signal<string>;
  abstract concept: Concept;

  abstract navigateTo(check: DataGuardCheck): void;

  abstract closeDataGuard(): void;

  abstract processCheckDetailsFromApiResponse(apiResponse: DataGuardCheckDetailApiResponse): DataGuardCheckDetail[];

  abstract loadingTrigger: Signal<boolean>;
  abstract isOverviewTabActive: Signal<boolean>;
  abstract isDetailTabActive: Signal<boolean>;
  // Injections
  private readonly http: HttpClient = inject(HttpClient);

  // Inheritance implementation

  dispatchDataGuardEvent(event): void {
    switch (event.type) {
      case DataGuardEventType.CLICK_ON_CHECK:
        this.navigateTo(event.check);
        break;
      case DataGuardEventType.CLICK_ON_CLOSE_BUTTON:
        this.closeDataGuard();
        break;
      default:
        break;
    }
  }

  // Custom variables and methods
  overviewContainsError: Signal<boolean> = computed(() => {
    const checks = this.overviewChecks();
    if (checks) {
      return !!checks.filter((check: DataGuardCheck) => check.status === 'warning' || check.status === 'error').length;
    } else {
      return false;
    }
  });

  detailContainsError: Signal<boolean> = computed(() => {
    const check = this.detailedChecks();
    if (check) {
      return check.status === 'warning' || check.status === 'error';
    } else {
      return false;
    }
  });
  isDetailTabDisabled: Signal<boolean> = computed(() => {
    const sourceElement = this.sourceElementForDetailedChecks();
    return get(sourceElement, 'type') === SiteType.MOBILE_FLEET;
  });

  isOverviewTabDisabled: Signal<boolean> = computed(() => {
    const sourceElement = this.sourceElementForOverviewChecks();
    return get(sourceElement, 'type') === SiteType.STOCK;
  });

  static getSourceFromBackEndGrouping(key: DataGuardOverviewChecksApiResponseKey | Concept, id: string | null, name: string): DataGuardCheck['source'] {
    switch (key) {
      case DataGuardOverviewChecksApiResponseKey.site:
        return { concept: 'site', icon: 'site', id, name };
      case DataGuardOverviewChecksApiResponseKey.assets:
        return { concept: 'asset', icon: 'asset', id, name };
      case DataGuardOverviewChecksApiResponseKey.devices:
        return { concept: 'device', icon: 'device', id, name };
      default:
        return { concept: key, icon: key, id, name };
    }
  }

  static getChecksStatusesCounts(checkList: { [errorKey: string]: DataGuardCheckStatus }): { [status in DataGuardCheckStatus]: number } {
    return Object.values(checkList).reduce((acc, value) => {
      acc[value] = acc[value] ? acc[value] + 1 : 1;
      return acc;
    }, {}) as { [status in DataGuardCheckStatus]: number };
  }

  getDataGuardChecksOverview(sourceElementForOverviewChecks: K, sourceElementForDetailedChecks: T, url): Observable<DataGuardCheck[]> {
    return this.http.get<DataGuardChecksOverviewApiResponse>(url).pipe(
      map((apiResponse: DataGuardChecksOverviewApiResponse) => {
        const apiResponseKeys: DataGuardOverviewChecksApiResponseKey[] = Object.keys(apiResponse) as DataGuardOverviewChecksApiResponseKey[];
        return apiResponseKeys.reduce((acc: DataGuardCheck[], apiResponseKey: DataGuardOverviewChecksApiResponseKey) => {
          const elementsByConcept = Object.entries(apiResponse[apiResponseKey]);
          const processedChecks: DataGuardCheck[] = elementsByConcept.map((e) => ({
            status: e[1].status,
            source: AbstractI4BDataGuardService.getSourceFromBackEndGrouping(
              apiResponseKey,
              sourceElementForDetailedChecks.id === e[0] ? null : e[0],
              e[1].name
            )
          }));
          acc.push(...processedChecks);
          return acc;
        }, []);
      })
    );
  }

  getDataGuardCheckDetail(sourceElementForDetailedChecks: T, url: string): Observable<DataGuardCheck> {
    return this.http.get<DataGuardCheckDetailApiResponse>(url).pipe(
      map((apiResponse: DataGuardCheckDetailApiResponse) => ({
        status: apiResponse.status,
        source: AbstractI4BDataGuardService.getSourceFromBackEndGrouping(
          this.concept,
          sourceElementForDetailedChecks.id as string,
          sourceElementForDetailedChecks.name as string
        ),
        details: this.processCheckDetailsFromApiResponse(apiResponse)
      }))
    );
  }

  getFullCheckErrors(checkList: { [errorKey: string]: DataGuardCheckStatus }): DataGuardCheckError[] {
    return Object.keys(checkList).reduce((acc, errorKey) => {
      const processedError: DataGuardCheckError = I4BDataGuardHelper.getDataGuardError(errorKey, this.concept);
      acc.push(processedError);
      return acc;
    }, []);
  }
}
