import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { UserPreferencesService } from '@iot-platform/users';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MasterViewEngineService {
  constructor(
    @Inject('environment') private environment: Environment,
    private httpClient: HttpClient,
    private userPrefsService: UserPreferencesService
  ) {}

  getMasterView(masterViewName: string, isPath: boolean = false, useExternalMetadata: boolean = false): Observable<any> {
    if (!isPath) {
      return this.httpClient.get<any>(`assets/engines/master-views/${masterViewName}.json`);
    } else if (!useExternalMetadata) {
      return this.userPrefsService.loadMySettings(masterViewName);
    } else {
      return of(null);
    }
  }

  getElementPropertyByPath(path: string, element): string {
    const clonedElement = Object.assign({}, element);

    return path.split('.').reduce((acc, value) => {
      if (acc) {
        return (acc = acc[value]);
      } else {
        return acc;
      }
    }, clonedElement);
  }

  getData(masterViewName: string, contextElementName: any, metadata: any, contextElement: any, extraParam?: any): Observable<any> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    // const askedPage = metadata['params'].page;

    if (metadata['params']) {
      for (const [key, value] of Object.entries(metadata['params'])) {
        if (key === 'dynamicParams') {
          for (const [dynParamKey, dynParamValue] of Object.entries(value)) {
            params = params.append(dynParamKey, this.getElementPropertyByPath(dynParamValue, contextElement));
          }
        }

        if (value !== 'contextElementName' && key !== 'dynamicParams') {
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          params = params.append(key, value.toString());
        }
      }
    }

    //  console.log('PARAMS', params);

    let urlToGo = metadata['url'].includes('/api/v2') /* ugly hack to handle favrorite views, DO BETTER */
      ? metadata['url']
      : `${this.environment.api.url}${metadata['url']}`;

    if (extraParam && urlToGo.includes('#EXTRA_PARAM')) {
      urlToGo = urlToGo.replace('#EXTRA_PARAM', extraParam);
    }

    // console.log('ORL_TO_GO', urlToGo);
    return this.httpClient.get<any[]>(urlToGo, { params }).pipe(
      // tap((data: any) => console.log(data.content)),
      switchMap((data: any) =>
        of({
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        })
      )
    );
  }
}
