import { PlatformResponse } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAssetEventsDb from './asset-events-db.reducer';
import * as fromAssetEventsUi from './asset-events-ui.reducer';
import { assetEventsCommentsFeature, State as AssetEventsCommentsState, adapter as AssetEventsCommentsAdapter } from './asset-events-comments.reducer';

export const assetEventsFeatureKey = 'assetEvents';

export interface AssetEventsState {
  [fromAssetEventsDb.assetEventsDbFeatureKey]: fromAssetEventsDb.State;
  [fromAssetEventsUi.assetEventsUiFeatureKey]: fromAssetEventsUi.State;
  [assetEventsCommentsFeature.name]: AssetEventsCommentsState;
}

export interface State {
  [assetEventsFeatureKey]: AssetEventsState;
}

export function reducers(state: AssetEventsState | undefined, action: Action) {
  return combineReducers({
    [fromAssetEventsDb.assetEventsDbFeatureKey]: fromAssetEventsDb.reducer,
    [fromAssetEventsUi.assetEventsUiFeatureKey]: fromAssetEventsUi.reducer,
    [assetEventsCommentsFeature.name]: assetEventsCommentsFeature.reducer
  })(state, action);
}

export const selectAssetEventsState = createFeatureSelector<AssetEventsState>(assetEventsFeatureKey);

export const selectAssetEventsDbState = createSelector(selectAssetEventsState, (state: AssetEventsState) => state[fromAssetEventsDb.assetEventsDbFeatureKey]);
export const selectAssetEventsUiState = createSelector(selectAssetEventsState, (state: AssetEventsState) => state[fromAssetEventsUi.assetEventsUiFeatureKey]);

export const {
  selectIds: getAssetEventsIds,
  selectEntities: getAssetEventsEntities,
  selectAll: getAllAssetEvents,
  selectTotal: getTotalAssetEvents
} = fromAssetEventsDb.adapter.getSelectors(selectAssetEventsDbState);

export const getSelectedAssetEventId = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getSelectedId);

export const getSelectedAssetEvent = createSelector(
  getAssetEventsEntities,
  getSelectedAssetEventId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

// ***** DATA
export const getMVSettings = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getSettings);
export const getSite = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getSite);
export const getAsset = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getAsset);
export const getAssetVariable = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getAssetVariable);
export const getDevice = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getDevice);
export const getDeviceVariable = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getDeviceVariable);

export const getTags = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getTags);

export const getCheckedIds = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getCheckedIds);

export const getTableState = createSelector(
  selectAssetEventsDbState,
  getAllAssetEvents,
  getSelectedAssetEvent,
  getCheckedIds,
  (state, allEvents, selected, checkedIds) => ({ selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) })
);

export const getPagination = createSelector(selectAssetEventsDbState, getMVSettings, (state, settings) =>
  settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination
);
export const getAutoRefresh = createSelector(getMVSettings, (settings) => (settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false));

export const getRefreshDelay = createSelector(getMVSettings, (settings) => (settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120));
export const getStatus = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getStatus);
export const getInitialSort = createSelector(selectAssetEventsDbState, (state: fromAssetEventsDb.State) => state.initialSort);

export const getFormattedAssetEvents = createSelector(getAllAssetEvents, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

// ***** UI
export const getError = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getSiteLoaded);

export const getAssetEventsLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetEventsLoaded);
export const getAssetEventsLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetEventsLoading);
export const getSiteLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getSiteLoaded);
export const getSiteLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getSiteLoading);
export const getAssetLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetLoaded);
export const getAssetLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetLoading);
export const getAssetVariableLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetVariableLoaded);
export const getAssetVariableLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetVariableLoading);
export const getDeviceLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceLoaded);
export const getDeviceLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceLoading);
export const getDeviceVariableLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceVariableLoaded);
export const getDeviceVariableLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceVariableLoading);
export const getTagsLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getTagsLoaded);

// Comments
const selectAssetEventsCommentsState = createSelector(selectAssetEventsState, (state: AssetEventsState) => state[assetEventsCommentsFeature.name]);

export const {
  selectIds: selectAssetEventCommentsIds,
  selectEntities: selectAssetEventCommentsDictionary,
  selectAll: selectAllAssetEventComments,
  selectTotal: selectTotalAssetEventComments
} = AssetEventsCommentsAdapter.getSelectors(selectAssetEventsCommentsState);

export const selectAssetEventCommentsError = createSelector(selectAssetEventsCommentsState, (state: AssetEventsCommentsState) => state.error);
export const selectAssetEventCommentsLoading = createSelector(selectAssetEventsCommentsState, (state: AssetEventsCommentsState) => state.loading);
export const selectAssetEventCommentsLoaded = createSelector(selectAssetEventsCommentsState, (state: AssetEventsCommentsState) => state.loaded);
