<div class="home-container">
  <section class="">
    <div class="home-favorite-view-list-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h2 class="">{{ 'MY_PAGE.TITLE.SHARED' | translate | uppercase }}</h2>
      <div class="regular-1px-line" fxFlex></div>
    </div>
    <div class="views-container" fxLayout="row wrap" fxLayoutGap="20px">
      @for (favoriteView of sharedFavoriteViews$ | async; track favoriteView.id) {
        <iot4bos-ui-home-favorite-view [favoriteView]="favoriteView" (navigateTo)="navigateTo($event)" [isLoading]="fvLoading"></iot4bos-ui-home-favorite-view>
      } @empty {
        <div class="no-favorite-view-message">{{ 'MY_PAGE.NO_BP_FV' | translate | uppercase }}</div>
      }
    </div>
  </section>
  <section class="">
    <div class="home-favorite-view-list-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h2>{{ 'MY_PAGE.TITLE.NOT_SHARED' | translate | uppercase }}</h2>
      <div class="regular-1px-line" fxFlex></div>
    </div>
    <div class="views-container" fxLayout="row wrap" fxLayoutGap="20px">
      @for (favoriteView of notSharedFavoriteViews$ | async; track favoriteView.id) {
        <iot4bos-ui-home-favorite-view [favoriteView]="favoriteView" (navigateTo)="navigateTo($event)" [isLoading]="fvLoading"></iot4bos-ui-home-favorite-view>
      } @empty {
        <div class="no-favorite-view-message">{{ 'MY_PAGE.NO_PERSONAL_FV' | translate | uppercase }}</div>
      }
    </div>
  </section>
</div>
