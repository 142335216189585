import { DeviceEvent } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DeviceEventsByDeviceDbActions, DeviceEventsByDeviceLogsDbActions, DeviceEventsByDeviceUiActions, NavigationActions } from '../../actions';

export const deviceEventsByDeviceDbFeatureKey = 'deviceEventsByDeviceDb';

export interface State extends EntityState<DeviceEvent> {
  selectedDeviceEventId: string | null;
  checkedDeviceEventIds: string[];
  previousDeviceId: string;
  totalActiveEvents: number;
  error?: any;
  pagination?: {
    currentPage: number;
    hasMore: boolean;
    limit: number;
    maxPage: number;
    total: number;
  };
  settings: any;
  status?: DeviceEvent;
}

export const adapter: EntityAdapter<DeviceEvent> = createEntityAdapter<DeviceEvent>({
  selectId: (deviceEvent: DeviceEvent) => deviceEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedDeviceEventId: null,
  checkedDeviceEventIds: [],
  previousDeviceId: null,
  totalActiveEvents: 0,
  error: null,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 },
  settings: null
});

const deviceEventsByDeviceDbReducer = createReducer(
  initialState,
  on(NavigationActions.clearData, () => initialState),

  on(DeviceEventsByDeviceUiActions.loadDeviceEventsByDevice, (state: State) => ({ ...state })),
  on(DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceSuccess, (state: State, { response, deviceId }) => {
    const newState = { ...state };
    if (!state.previousDeviceId || (state.previousDeviceId && state.previousDeviceId !== deviceId)) {
      newState.previousDeviceId = deviceId;
    }
    return adapter.setAll(response.data, {
      ...newState,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    });
  }),
  // ****
  on(DeviceEventsByDeviceDbActions.loadTotalActiveDeviceEventsByDeviceSuccess, (state: State, { totalActiveEvents }) => ({ ...state, totalActiveEvents })),
  // ****
  on(DeviceEventsByDeviceLogsDbActions.createLogByDeviceEventSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: { totalComments: state.entities[state.selectedDeviceEventId].totalComments + 1 } }, state)
  ),
  // ****
  on(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess, (state: State, { deviceEvent }) =>
    adapter.updateOne({ id: deviceEvent.id, changes: deviceEvent }, { ...state, status: deviceEvent })
  ),
  on(DeviceEventsByDeviceDbActions.bulkUpdateStatusByDeviceEventIdByDeviceSuccess, (state: State, { deviceEvents }) => {
    const updates: {
      id: string;
      changes: any;
    }[] = deviceEvents.reduce((acc, value) => {
      acc.push({ id: value.id, changes: value });
      return acc;
    }, []);

    return adapter.updateMany(updates, { ...state, status: deviceEvents[0] });
  }),
  on(DeviceEventsByDeviceDbActions.saveTableByDeviceStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedDeviceEventId: selectedId,
    checkedDeviceEventIds: checkedIds
  })),
  on(DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsSuccess, (state: State, { settings }) => ({ ...state, settings }))
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByDeviceDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedDeviceEventId;
export const getCheckedIds = (state: State) => state.checkedDeviceEventIds;
export const getPreviousDeviceId = (state: State) => state.previousDeviceId;
export const getTotalActiveEvents = (state: State) => state.totalActiveEvents;
export const getMvSettings = (state: State) => state.settings;
export const getStatus = (state: State) => state.status;
