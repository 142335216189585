<div
  [ngClass]="{ isCurrent: !overviewCheck().source.id }"
  class="overview-check overview-check--container"
  data-cy="iot-platform-feature-data-guard-overview-check"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <iot-platform-ui-status-icon [status]="overviewCheck().status" />
  @if (overviewCheck().source.icon) {
    <mat-icon [svgIcon]="overviewCheck().source.icon"></mat-icon>
  }
  <span class="overview-check--name">{{ overviewCheck().source.name }}</span>
  @if (!overviewCheck().source.id) {
    <mat-icon class="overview-check--current-element-icon">my_location</mat-icon>
  }
</div>
