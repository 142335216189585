import { TagCategory } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listTagsByBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Tags API] List Tags by Business Profile Success',
  props<{ tags: TagCategory[] }>()
);
export const listTagsByBusinessProfileFailure = createAction(
  '[Admin Business Profile/Tags API] List Tags by Business Profile Failure',
  props<{ error: any }>()
);

export const updateTagsForBusinessProfileSuccess = createAction(
  '[Admin Business Profile/Tags API] Update Tags For Business Profile Success',
  props<{ addedTags: TagCategory[] }>()
);
export const updateTagsForBusinessProfileFailure = createAction(
  '[Admin Business Profile/Tags API] Update Tags For Business Profile Failure',
  props<{ error: any }>()
);
