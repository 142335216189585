import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormulaType } from '@iot-platform/models/common';
import { ConstantParameters, Formula } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  selector: 'iot4bos-ui-asset-constant-parameters-form',
  templateUrl: './constant-parameters-form.component.html'
})
export class ConstantParametersFormComponent implements OnInit, OnDestroy {
  @Input() initialFormula!: Formula;
  @Input() readonly = false;

  @Output() dispatchFormula: EventEmitter<Formula | null> = new EventEmitter();

  parametersForm: UntypedFormGroup = new UntypedFormGroup({
    constantValue: new UntypedFormControl()
  });

  assetVariableFormula: Formula = { model: null, parameters: { constantValue: null }, srcVariables: {} };
  subscriptions: Subscription[] = [];

  get constantValue(): AbstractControl {
    return this.parametersForm.get('constantValue');
  }

  ngOnInit() {
    const parameters = this.initialFormula?.parameters as ConstantParameters;
    this.constantValue.setValue(this.initialFormula ? parameters.constantValue : null);
    this.constantValue.setValidators([Validators.required]);

    if (this.readonly) {
      this.parametersForm.disable();
    }

    this.subscriptions.push(this.parametersForm.valueChanges.pipe(debounceTime(500)).subscribe(() => this.sendFormula()));
    this.sendFormula();
  }

  createFormula(): void {
    this.assetVariableFormula = {
      ...this.assetVariableFormula,
      model: FormulaType.CONSTANT,
      parameters: { constantValue: this.constantValue.value }
    };
  }

  sendFormula(): void {
    if (this.constantValue?.value) {
      this.createFormula();
      this.dispatchFormula.emit(this.assetVariableFormula);
    } else {
      this.dispatchFormula.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
