import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';

export class CreatedGroupColumn extends I4BBasicColumn {
  columnId = 'e1f820e6-9f86-11ec-9da8-0023a40210d0-created-group';
  className = CreatedGroupColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'CREATED FULL',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig.id,
        concept: customConfig.concept,
        isDefault: false,
        marryChildren: true,
        children: [
          new I4BBasicColumn(
            { displayName: 'CREATED AT', type: HeaderType.BASIC },
            {
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_AT',
              id: 'createdAt',
              isDefault: false,
              sortProperty: 'createdAt',
              concept: customConfig.concept,
              cell: { type: I4BCellType.DATE }
            },
            {
              order: 980
            }
          ),
          new I4BBasicColumn(
            { displayName: 'CREATED BY', type: HeaderType.BASIC },
            {
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_BY',
              id: 'createdBy',
              isDefault: false,
              sortProperty: 'createdBy',
              concept: customConfig.concept,
              width: 165,
              cell: {
                type: I4BCellType.USER_CELL
              }
            },
            {
              order: 981
            }
          )
        ]
      },
      { ...customOptions, customId: customConfig.id }
    );
  }
}
