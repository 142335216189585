import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { DaliaDeviceTemplatesButtonColumn, I4BBasicColumn, I4BColumn, I4BSelectionColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { DaliaDeviceTemplatesGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_DEVICE_TEMPLATES_COLUMNS_FOR_DALIA_DEVICE_TEMPLATES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new I4BBasicColumn(
    { displayName: 'NAME' },
    {
      id: 'name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.NAME',
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToTemplateDetails' } },
      isDefault: true,
      autoSize: true,
      sortProperty: 'name',
      concept: I4bCellConcept.DEVICE_TEMPLATES
    },
    {
      order: 1
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'description',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      autoSize: true,
      sortProperty: 'description',
      concept: I4bCellConcept.DEVICE_TEMPLATES
    },
    {
      order: 2
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ENTITY NAME' },
    {
      id: 'entity.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'entity.name',
      concept: I4bCellConcept.DEVICE_TEMPLATES
    },
    {
      order: 3
    }
  ),
  new I4BBasicColumn(
    { displayName: 'NUMBER OF VARIABLES' },
    {
      id: 'totalVariables',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.NUMBER_OF_VARIABLES',
      cell: {
        type: I4BCellType.BASIC,
        options: {
          textAlign: 'center'
        }
      },
      isDefault: true,
      sortProperty: 'totalVariables',
      concept: I4bCellConcept.DEVICE_TEMPLATES
    },
    {
      order: 4
    }
  ),
  new I4BBasicColumn(
    { displayName: 'NUMBER OF ASSETS' },
    {
      id: 'totalTanks',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.NUMBER_OF_TANKS',
      cell: {
        type: I4BCellType.BASIC,
        options: {
          textAlign: 'center'
        }
      },
      isDefault: true,
      sortProperty: 'totalTanks',
      concept: I4bCellConcept.DEVICE_TEMPLATES
    },
    {
      order: 5
    }
  ),
  new I4BBasicColumn(
    { displayName: 'NUMBER OF IMPORTS' },
    {
      id: 'occurence',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.NUMBER_OF_USE',
      cell: {
        type: I4BCellType.BASIC,
        options: {
          textAlign: 'center'
        }
      },
      isDefault: true,
      sortProperty: 'occurence',
      concept: I4bCellConcept.DEVICE_TEMPLATES
    },
    {
      order: 6
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST USED AT', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_USED_AT',
      id: 'lastUsedAt',
      isDefault: true,
      sortProperty: 'lastUsedAt',
      concept: I4bCellConcept.DEVICE_TEMPLATES,
      width: 200,
      cell: {
        type: I4BCellType.DATE
      }
    },
    {
      order: 7
    }
  )
];

export class DefaultDaliaDeviceTemplatesGrid implements I4BGrid<I4BGridOptions, DaliaDeviceTemplatesGridData> {
  masterview = 'dalia-device-templates';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: DaliaDeviceTemplatesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: true, className: new DaliaDeviceTemplatesButtonColumn() },
    selectionColumn: { enabled: false, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}
