<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign=" center">
      <h1 class="dialog-card-title">{{ 'EXPORTS.TITLE' | translate }}</h1>
    </mat-toolbar>
  </mat-card-title>
  @if (loading()) {
    <mat-progress-bar [value]="progressBarValue()" class="exports-loader" color="accent" mode="buffer" />
  }
  <form [formGroup]="form" class="full-width">
    <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="10px">
      <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'EXPORTS.TYPE' | translate }}</mat-label>
        <mat-select (selectionChange)="onTypeChange($event)" formControlName="type" required>
          @for (t of types; track t) {
            <mat-option [value]="t">{{ t }}</mat-option>
          }
        </mat-select>
        @if (type.invalid) {
          <mat-error>{{ 'EXPORTS.ERRORS.TYPE' | translate }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field color="accent" floatLabel="never" fxFlex>
        <span matTextPrefix>{{ time }}-</span>
        <mat-label>{{ 'EXPORTS.NAME' | translate }}</mat-label>
        <input formControlName="name" matInput pattern="\S.*" required />
        @if (name.invalid && name.errors.pattern) {
          <mat-error>{{ 'EXPORTS.ERRORS.NAME' | translate }}</mat-error>
        }
      </mat-form-field>
      @if (data?.totalElements > 80000) {
        <p class="export-limit-warning">{{ 'EXPORTS.LIMIT' | translate }}</p>
      }
      <p class="dialog-form-tips">{{ 'EXPORTS.TIPS' | translate }}</p>
    </mat-card-content>
    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
      <button (click)="close()" class="button-regular" mat-button type="button">{{ 'EXPORTS.CANCEL' | translate }}
      </button>
      <button (click)="submit()" [disabled]="form.invalid || (loading())" class="button-regular" color="accent"
              mat-raised-button type="submit">
        {{ (canDownload() ? 'EXPORTS.DOWNLOAD' : 'EXPORTS.CLOSE') | translate }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
