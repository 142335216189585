import { Injectable } from '@angular/core';
import { ApiSelector, DynamicDataResponse, Filter } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { IotGeoJsonFeature } from '../../models/iot-geo-json-object.model';
import * as fromMain from '../reducers';
import * as fromMap from '../reducers/map.reducer';

@Injectable({
  providedIn: 'root'
})
export class MapSelectors extends ApiSelector<fromMain.State, IotGeoJsonFeature, unknown, Filter> {
  selectConcept: MemoizedSelector<fromMap.State, string> = createSelector(this.selectState, (state: fromMap.State) => state.concept);
  selectFeatureCollection: MemoizedSelector<fromMap.State, { [concept: string]: IotGeoJsonFeature[] }> = createSelector(
    this.selectState,
    (state: fromMap.State) => state.featureCollection
  );
  selectCurrentFeatures: MemoizedSelector<fromMap.State, IotGeoJsonFeature[]> = createSelector(
    this.selectState,
    (state: fromMap.State) => state.featureCollection[state.concept] || []
  );
  selectCurrentRoutes = createSelector(this.selectState, (state: fromMap.State) => state.currentRoutes);
  selectRoutesLoading = createSelector(this.selectState, (state: fromMap.State) => state.routesLoading);

  selectRoutesLoaded = createSelector(this.selectState, (state: fromMap.State) => state.routesLoaded);
  selectHasRoutes = createSelector(this.selectState, (state: fromMap.State) => state.hasRoutes);
  selectCurrentFeature: MemoizedSelector<fromMap.State, IotGeoJsonFeature> = createSelector(this.selectState, (state: fromMap.State) => state.currentFeature);
  selectData: MemoizedSelector<fromMap.State, DynamicDataResponse> = createSelector(this.selectState, (state: fromMap.State) => state.data);
  refresh: MemoizedSelector<fromMap.State, boolean> = createSelector(this.selectState, (state: fromMap.State) => state.refresh);

  constructor() {
    super(fromMain.featureKey, fromMap.featureKey, fromMap.adapter);
  }
}
