import { Filter, Pagination, Product } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';
import { ProductsDbActions, ProductsUiActions } from '../actions';

export const productsDbFeatureKey = 'productsDb';

export interface State extends EntityState<Product> {
  selectedProductId: string;
  pagination: Pagination;
  productFilters: Filter[];
  error: any;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (product: Product) => product.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedProductId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 100 },
  productFilters: [],
  error: null
});

export const productsDbReducer = createReducer(
  initialState,
  on(ProductsUiActions.listProducts, (state: State, { request }) => ({ ...state, productFilters: request.filters })),
  on(ProductsDbActions.listProductsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: 100,
        maxPage: response.maxPage,
        total: response.total,
        next: null,
        prev: null
      }
    })
  ),
  on(ProductsDbActions.listProductsFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductsDbActions.addProductSuccess, (state: State, { addedProduct }) => adapter.addOne(addedProduct, state)),
  on(ProductsDbActions.addProductFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductsDbActions.updateProductSuccess, (state: State, { updatedProduct }) =>
    adapter.updateOne({ id: updatedProduct.id, changes: updatedProduct }, state)
  ),
  on(ProductsDbActions.updateProductFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductsDbActions.deleteProductSuccess, (state: State, { deletedProduct }) => adapter.removeOne(deletedProduct.id, state)),
  on(ProductsDbActions.deleteProductFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductsUiActions.saveProductFilters, (state: State, { filters }) => ({ ...state, productFilters: filters })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return productsDbReducer(state, action);
}

export const getSelectedProductId = (state: State) => state.selectedProductId;
export const getPagination = (state: State) => state.pagination;
export const getProductFilters = (state: State) => state.productFilters;
