import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { Device, VariablesTableFilters } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatIcon,
    MatLabel,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    MatFormField,
    MatButton,
    MatInput,
    ReactiveFormsModule,
    MatPrefix
  ],
  selector: 'iot-platform-ui-device-info-toolbar',
  templateUrl: './device-info-toolbar.component.html',
  styleUrls: ['./device-info-toolbar.component.scss']
})
export class DeviceInfoToolbarComponent {
  device = input<Device>();
  deviceVariableGroups = input<{ key: string; value: string }[]>([]);
  deviceVariableFiles = input<{ key: string; value: string }[]>([]);
  variableUnits = input<string[]>([]);
  filters = input<Partial<VariablesTableFilters>>();
  total = input<number>();
  totalFiltered = input<number>();
  displayLoader = input<boolean>();
  withNameFilter = input<boolean>(false);
  withDescriptionFilter = input<boolean>(false);
  withUnitFilter = input<boolean>(false);
  withGroupFilter = input<boolean>(false);
  withFileFilter = input<boolean>(false);
  withLinkedFilter = input<boolean>(false);
  //
  applyFilters = output<Partial<VariablesTableFilters>>();
  clearFilters = output<string>();

  toolbarForm: WritableSignal<UntypedFormGroup | undefined> = signal(undefined);
  readonly LINK_OPTIONS = ['all', true, false];

  constructor() {
    this.initFiltersEffect();
  }

  onFilterNameValueChange(event): void {
    this.applyFilters.emit({ ...this.filters(), name: event.target.value });
  }

  onFilterDescriptionValueChange(event): void {
    this.applyFilters.emit({ ...this.filters(), description: event.target.value });
  }

  onGroupSelectionChange(event: MatSelectChange): void {
    const groupUsedToFilter = this.deviceVariableGroups().find((g) => g.key === event.value);
    this.applyFilters.emit({
      ...this.filters(),
      group: groupUsedToFilter?.value !== undefined ? groupUsedToFilter : null
    });
  }

  onFileSelectionChange(event: MatSelectChange): void {
    const fileUsedToFilter = this.deviceVariableFiles().find((f) => f.key === event.value);
    this.applyFilters.emit({
      ...this.filters(),
      file: fileUsedToFilter?.value !== undefined ? fileUsedToFilter : null
    });
  }

  onLinkedSelectionChange(event: MatSelectChange): void {
    this.applyFilters.emit({
      ...this.filters(),
      linked: event.value === true || event.value === false ? event.value : null
    });
  }

  onUnitSelectionChange(event: MatSelectChange): void {
    this.applyFilters.emit({ ...this.filters(), unit: event.value });
  }

  clear(): void {
    this.toolbarForm.update((form) => {
      form.reset();
      return form;
    });
    this.clearFilters.emit('');
  }

  private initFiltersEffect(): void {
    effect(
      () => {
        const filters: Partial<VariablesTableFilters> = this.filters();

        this.toolbarForm.set(
          new UntypedFormGroup({
            name: new UntypedFormControl(filters?.name ?? ''),
            linked: new UntypedFormControl(filters?.linked ?? null),
            description: new UntypedFormControl(filters?.description ?? ''),
            group: new UntypedFormControl(filters?.group ? filters.group.key : null),
            file: new UntypedFormControl(filters?.file ? filters.file.key : null),
            unit: new UntypedFormControl(filters?.unit ?? null)
          })
        );
      },
      { allowSignalWrites: true }
    );
  }
}
