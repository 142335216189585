import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserProfileApiKeysInfoPopupComponent } from './components/user-profile-api-keys-info-popup/user-profile-api-keys-info-popup.component';
import { UserProfileApiKeysComponent } from './components/user-profile-api-keys/user-profile-api-keys.component';
import { UserProfileInfoFormComponent } from './components/user-profile-info-form/user-profile-info-form.component';
import { UserProfilePreferencesFormComponent } from './components/user-profile-preferences-form/user-profile-preferences-form.component';
import { UserProfileShellComponent } from './containers/user-profile-shell/user-profile-shell.component';
import { ProfileEffects } from './state/effects/profile.effects';
import * as fromUserProfile from './state/reducers';

@NgModule({
  imports: [
    UserProfileShellComponent,
    UserProfilePreferencesFormComponent,
    UserProfileApiKeysComponent,
    UserProfileApiKeysInfoPopupComponent,
    UserProfileInfoFormComponent,
    StoreModule.forFeature(fromUserProfile.userProfileFeatureKey, fromUserProfile.reducers),
    EffectsModule.forFeature([ProfileEffects])
  ],
  providers: []
})
export class ProfileModule {}
