import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'i4b-table-engine-active-events-cell',
  templateUrl: './active-events-cell.component.html',
  styleUrls: ['./active-events-cell.component.scss']
})
export class ActiveEventsCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  tooltip: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this.data !== null) {
      this.tooltip = this.translateService.instant(this.data > 1 ? 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENTS' : 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENT', {
        totalEvents: this.data
      });
    }
  }
}
