import { Pagination } from '@iot-platform/models/common';
import { AssetEvent } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AssetEventsByAssetDbActions, AssetEventsByAssetLogsDbActions, AssetEventsByAssetUiActions, NavigationActions } from '../../actions';

export const assetEventsByAssetDbFeatureKey = 'assetEventsByAssetDb';

export interface State extends EntityState<AssetEvent> {
  selectedAssetEventId: string | null;
  checkedAssetEventIds: string[];
  previousAssetId: string;
  totalActiveEvents: number;
  error?: any;
  pagination?: Pagination;
  settings?: any;
  status?: AssetEvent;
}

export const adapter: EntityAdapter<AssetEvent> = createEntityAdapter<AssetEvent>({
  selectId: (assetEvent: AssetEvent) => assetEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedAssetEventId: null,
  checkedAssetEventIds: [],
  previousAssetId: null,
  totalActiveEvents: 0,
  error: null,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 },
  settings: null
});

const assetEventsByAssetDbReducer = createReducer(
  initialState,
  on(NavigationActions.clearData, AssetEventsByAssetUiActions.clearData, () => initialState),

  on(AssetEventsByAssetUiActions.loadAssetEventsByAsset, (state: State) => ({ ...state })),
  on(AssetEventsByAssetDbActions.loadAssetEventsByAssetSuccess, (state: State, { response, assetId }) => {
    const newState = { ...state };
    if (!state.previousAssetId || (state.previousAssetId && state.previousAssetId !== assetId)) {
      newState.previousAssetId = assetId;
    }
    return adapter.setAll(response.data, {
      ...newState,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    });
  }),
  // ****
  on(AssetEventsByAssetDbActions.loadTotalActiveAssetEventsByAssetSuccess, (state: State, { totalActiveEvents }) => ({ ...state, totalActiveEvents })),
  // ****
  on(AssetEventsByAssetLogsDbActions.createLogByAssetEventSuccess, (state: State, { assetEvent }) =>
    adapter.updateOne({ id: assetEvent.id, changes: { totalComments: state.entities[state.selectedAssetEventId].totalComments + 1 } }, state)
  ),
  // ****
  on(AssetEventsByAssetDbActions.updateStatusByAssetEventIdByAssetSuccess, (state: State, { assetEvent }) =>
    adapter.updateOne({ id: assetEvent.id, changes: assetEvent }, { ...state, status: assetEvent })
  ),
  on(AssetEventsByAssetDbActions.bulkUpdateStatusByAssetEventIdByAssetSuccess, (state: State, { assetEvents }) => {
    const updates: {
      id: string;
      changes: any;
    }[] = assetEvents.reduce((acc, value) => {
      acc.push({ id: value.id, changes: value });
      return acc;
    }, []);

    return adapter.updateMany(updates, { ...state, status: assetEvents[0] });
  }),
  on(AssetEventsByAssetDbActions.saveTableByAssetStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedAssetEventId: selectedId,
    checkedAssetEventIds: checkedIds
  })),
  on(AssetEventsByAssetDbActions.loadMvAssetEventsByAssetSettingsSuccess, (state: State, { settings }) => ({ ...state, settings }))
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByAssetDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedAssetEventId;
export const getCheckedIds = (state: State) => state.checkedAssetEventIds;
export const getPreviousAssetId = (state: State) => state.previousAssetId;
export const getTotalActiveEvents = (state: State) => state.totalActiveEvents;
export const getMvSettings = (state: State) => state.settings;
export const getStatus = (state: State) => state.status;
