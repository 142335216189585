import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import { RichValueCellParams } from '../models/rich-value-cell.params';

export class RichVariableValueCellHelpers {
  static getVariableFromExportParams(params: ProcessCellForExportParams) {
    const { column, node } = params;

    if (column.getColDef().field.includes('context')) {
      const contextVariable: {
        id: string;
        name: string;
        value: number;
        unit: string;
      } = get(node.data, column.getColDef().field);
      return { ...contextVariable, lastValue: { value: contextVariable?.value, datetime: '' } };
    } else {
      return get(node.data, column.getColDef().field, node.data);
    }
  }

  static getVariable(params: RichValueCellParams): Partial<AssetVariable> | Partial<DeviceVariable> | Partial<FollowedVariable> {
    if (params?.colDef?.field?.includes('context')) {
      const contextVariable: {
        id: string;
        name: string;
        value: number;
        unit: string;
      } = get(params.data, params.colDef.field);
      return { ...contextVariable, lastValue: { value: contextVariable?.value, datetime: '' } };
    } else {
      return !!params?.colDef?.field ? get(params.data, params.colDef.field) : params?.data;
    }
  }
}
