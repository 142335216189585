import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const productCatalogsColumnSettings = [
  {
    order: 20,
    id: 'name',
    name: 'IOT_DICTIONARY.NAME',
    catalogName: 'PRODUCT NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    type: 'start center'
  },
  {
    order: 10,
    id: 'identifier',
    name: 'ADMIN.PRODUCT_CATALOGS.PRODUCT_ID',
    catalogName: 'PRODUCT ID',
    default: true,
    sortable: true,
    sortProperty: 'identifier',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    type: 'start center'
  },
  {
    order: 30,
    id: 'catalog.name',
    name: 'ADMIN.PRODUCT_CATALOGS.CATALOG_NAME',
    catalogName: 'CATALOG NAME',
    default: true,
    sortable: true,
    sortProperty: 'catalog.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    cellTypeOptions: {},
    isLink: false,
    width: '',
    type: 'start center'
  },
  {
    order: 40,
    id: 'catalog.entities',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ATTACHED_ENTITIES',
    catalogName: 'ATTACHED ENTITIES',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.ARRAY_OF_OBJECTS,
    cellTypeOptions: {
      attributes: ['name']
    },
    isLink: false,
    width: '',
    type: 'start center'
  }
];
