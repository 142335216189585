<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="warn" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'COMMENTS.DELETE_COMMENT' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <div class="p-20">
      <p align="center" class="fw-600" style="margin-bottom: 10px">{{ 'COMMENTS.DELETE_HINT' | translate }}</p>
      <p align="center" style="overflow-wrap: break-word">"{{ data.comment }}"</p>
    </div>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
    <button (click)="confirm()" class="button-regular" color="warn" mat-raised-button>
      {{ 'IOT_DICTIONARY.DELETE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
