import { I4BCellType, I4BRichVariableColumnConfiguration } from '../configurations';
import { DataLibrary, I4bCellConcept } from '../enums';
import { I4BBasicColumnHeader } from '../headers';
import { HeaderType, I4BColumnHeader } from '../models';
import { BasicColumnOptions, I4BColumnOptions } from '../options';
import { I4BColumn } from './i4b-column';
import { CellClassParams, CellStyle, ValueGetterParams } from '@ag-grid-community/core';
import { get } from 'lodash';

export class ExpandedVariableColumn implements I4BColumn<I4BBasicColumnHeader, I4BRichVariableColumnConfiguration, I4BColumnOptions> {
  columnId = '7z348742-6b09-11ec-87d5-acda48401122-expanded-variable';
  className = ExpandedVariableColumn;
  dataLibs: DataLibrary[] = [DataLibrary.DEVICES];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC });
  configuration: I4BRichVariableColumnConfiguration = {
    concept: I4bCellConcept.DEVICES,
    id: null,
    isConfigurable: true,
    isDefault: true,
    isLink: true,
    position: null,
    sortProperty: null,
    width: 160,
    cell: {
      type: I4BCellType.VARIABLE_VALUE_CELL,
      options: {},
      event: { type: 'openChart', options: null }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: false });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BRichVariableColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }
    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig };
    }
    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }

    this.configuration.cell.valueGetter = (params: ValueGetterParams) => {
      const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
      const value = get(params, `data.${params.colDef.field}`);
      if (cellOptions?.translatedValues) {
        const options = get(cellOptions, `translatedValues[${value}]`);
        return options ? options.value : null;
      } else if (cellOptions?.combineWithUnit) {
        const unit = get(params, `data.${this.options.customId}.unit`);
        return value ? `${value} ${unit ?? ''}` : null;
      } else {
        return value;
      }
    };

    this.configuration.cell.cellStyle = (params: CellClassParams): CellStyle => {
      const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
      const value = get(params, `data.${params.colDef.field}`);
      const cssOptions: any = {};
      if (cellOptions?.textAlign) {
        cssOptions.textAlign = cellOptions.textAlign;
      }
      const translatedOptions = get(cellOptions, `translatedValues[${value}]`);
      if (translatedOptions?.backgroundColor) {
        cssOptions.backgroundColor = translatedOptions.backgroundColor;
      }
      if (translatedOptions?.color) {
        cssOptions.color = translatedOptions.color;
      }
      return cssOptions;
    };
  }
}
