import { NgModule } from '@angular/core';

import { SharedModule } from '@iot-platform/shared';

import { OrganizationConceptsSelectionComponent } from './organization-concepts-selection.component';
import { OrganizationConceptsSelectionCheckboxComponent } from './organization-concepts-selection-checkbox/organization-concepts-selection-checkbox.component';

@NgModule({
  declarations: [OrganizationConceptsSelectionComponent, OrganizationConceptsSelectionCheckboxComponent],
  exports: [OrganizationConceptsSelectionComponent],
  imports: [SharedModule]
})
export class OrganizationConceptsSelectionModule {}
