export const schedulersMetadataSettings = {
  metadata: {
    url: '/schedulers',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'schedulers',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 10,
      refreshDelay: 120,
      multipleSelection: false,
      renderer: 'grid',
      displayOption: 'boxed',
      buttonColumn: {
        id: 'button',
        name: '',
        width: '40px',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [[{ key: 'canUpdate', value: true }]],
        singleActions: [
          {
            order: 10,
            key: 'edit',
            label: 'IOT_DICTIONARY.EDIT',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          },
          {
            order: 20,
            key: 'configure',
            label: 'IOT_DICTIONARY.CONFIGURE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          },
          {
            order: 30,
            key: 'delete',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canDelete', value: true }]]
          },
          {
            order: 25,
            key: 'addDevices',
            label: 'SCHEDULERS.MASTER_VIEW.CTA_LABELS.ADD_DEVICES',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          },
          {
            order: 27,
            key: 'removeDevices',
            label: 'SCHEDULERS.MASTER_VIEW.CTA_LABELS.REMOVE_DEVICES',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          },
          {
            order: 5,
            key: 'launchNow',
            label: 'IOT_DICTIONARY.LAUNCH_NOW',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdate', value: true }]]
          }
        ]
      },
      columns: []
    }
  },
  masterViewComments: {}
};
