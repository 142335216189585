import { Component, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'iot-platform-ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  display = input(false);
}
