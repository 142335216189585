@if (!!assetVariable) {
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    class="asset-variable-parameter"
    [ngClass]="{
      'valid-unit': !isUnitInvalid,
      'invalid-unit': isUnitInvalid,
      'untouched-form': !isTouched
    }"
  >
    <mat-icon
      [matTooltip]="invalidUnitTooltip"
      [matTooltipDisabled]="!isUnitInvalid"
      matTooltipClass="warning-tooltip"
      [ngClass]="{
        'valid-unit-icon': !isUnitInvalid,
        'invalid-unit-icon': isUnitInvalid
      }"
    >
      {{ isUnitInvalid ? 'error_outline' : 'check' }}
    </mat-icon>
    <span fxFlex>{{ assetVariable.name }} ({{ assetVariable.unit }})</span>
    @if (assetVariable.lastValue) {
      <span>{{ assetVariable | valueUnitFormat }} </span>
      <span>{{ assetVariable.lastValue.datetime | dateFormat }} </span>
    }
    <button mat-icon-button class="regular-round-button" (click)="clearFormControl.emit()"><mat-icon>close</mat-icon></button>
  </div>
} @else {
  <div class="asset-variable-parameter-placeholder" [ngClass]="{ 'first-empty-field': isFirstEmptyParameter }" fxLayout="row" fxLayoutAlign="start center">
    <span>{{ placeholder }}</span>
  </div>
}
