import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'i4b-table-engine-translated-cell',
  templateUrl: './translated-cell.component.html',
  styleUrls: ['./translated-cell.component.scss']
})
export class TranslatedCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;

  @Output() dispatchEvent;

  translateKey: string;
  translateStyle: any;

  ngOnInit() {
    this.translateKey = this.cellOptions?.translateKey ? this.cellOptions.translateKey : 'IOT_DICTIONARY.';
    this.translateStyle = this.cellOptions?.class ? this.cellOptions.class[this.snakeCaseToCamelCase(this.data)] : '';
  }

  snakeCaseToCamelCase(snakeCaseString: string): string {
    const parts = snakeCaseString.split('_');
    return parts.reduce((acc: string, value: string, index) => {
      if (index === 0) {
        acc = value.toLowerCase();
      } else {
        acc = `${acc}${value[0].toUpperCase()}${value.slice(1)}`;
      }
      return acc;
    }, '');
  }
}
