import { Signal } from '@angular/core';
import { IotMapDisplayMode, IotMapEvent, MapNavigationEvent, MapNavigationModelType } from '@iot-platform/iot-platform-maps';
import { IotToolbarDispatchActionType } from '@iot-platform/iot-platform-ui';
import { IotToolbarEvent } from '@iot-platform/models/common';
import { FeatureMasterViewEvent } from '../models/master-view-event.model';

export abstract class AbstractMasterViewMapService {
  abstract getMapConcept(): Signal<string>;
  abstract getMapDisplayMode(): Signal<IotMapDisplayMode>;

  abstract refreshMap(): void;
  abstract onMapEvent(event: IotMapEvent): void;
  abstract onDispatchMapNavigationEvent(event: MapNavigationEvent<MapNavigationModelType>): void;

  onToolbarEvent(_event: FeatureMasterViewEvent): void {
    const event: IotToolbarEvent = _event.event as IotToolbarEvent;
    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE.toString():
        this.refreshMap();
        break;
    }
  }
}
