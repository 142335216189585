import { createReducer, on } from '@ngrx/store';

import { BusinessProfilesGridsApiActions, BusinessProfilesGridsPageActions, BusinessProfilesPageActions } from '../actions';

export const adminBusinessProfilesGridsPageFeatureKey = 'adminBusinessProfilesGridsPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listGridsByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesGridsApiActions.listGridsByBusinessProfileSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesGridsApiActions.listGridsByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesGridsPageActions.addGrid, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesGridsApiActions.addGridSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesGridsApiActions.addGridFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesGridsPageActions.updateGrid, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesGridsApiActions.updateGridSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesGridsApiActions.updateGridFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesGridsPageActions.deleteGrid, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesGridsApiActions.deleteGridSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesGridsApiActions.deleteGridFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
