<mat-card class="dialog-card" fxLayout="column">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'MANAGE_TAGS_FORM.TITLE' | translate: { value: data.objectName } }}</h1>

      <button (click)="dialogRef.close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="column">
    <iot-platform-ui-manage-tags-form (updateForm)="onFormChange($event)" [data]="data" />
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    @if (data.editable) {
      <button (click)="dialogRef.close()" class="button-regular" color="accent" mat-raised-button>
        {{ 'MANAGE_TAGS_FORM.DONE' | translate }}
      </button>
    } @else {
      <button (click)="dialogRef.close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
      <button (click)="dialogRef.close(tagsToSave())" [disabled]="!tagsToSave()" class="button-regular" color="accent" mat-raised-button>
        {{ 'IOT_DICTIONARY.CONFIRM' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>
