import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import { DiagnosticVariableCellOptionsDisplayModeType, DiagnosticVariableColumn } from '@iot-platform/models/grid-engine';
import { DeviceVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe, InfoDisplayPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { get } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { CustomDiagnosticVariableCellParams } from '../../../models/custom-diagnostic-variable-cell.params';

@Component({
  standalone: true,
  selector: 'grid-engine-diagnostic-variable-cell',
  templateUrl: './diagnostic-variable-cell.component.html',
  imports: [NgClass, InfoDisplayPipe, DateFormatPipe],
  providers: [InfoDisplayPipe, DateFormatPipe],
  styleUrls: ['./diagnostic-variable-cell.component.scss']
})
export class DiagnosticVariableCellComponent implements ICellRendererAngularComp {
  private readonly valueUnitFormatPipe: ValueUnitFormatPipe = inject(ValueUnitFormatPipe);

  params: WritableSignal<CustomDiagnosticVariableCellParams> = signal(null);
  displayMode: Signal<DiagnosticVariableCellOptionsDisplayModeType> = computed(() => {
    const params = this.params();
    return get(params, ['cellOptions', 'displayMode']);
  });
  variableCategory: Signal<string> = computed(() => {
    const params = this.params();
    return get(params, ['cellOptions', 'variableCategory']);
  });

  variable: Signal<DeviceVariable> = computed(() => {
    const params = this.params();
    return DiagnosticVariableColumn.getDiagnosticVariableByCategory(
      get(params, ['cellOptions', 'variableCategory'], ''),
      get(params, ['data', 'expandedVariables'], {})
    ) as DeviceVariable;
  });

  value: Signal<any> = computed(() => {
    const params = this.params();
    const variable = this.variable();
    if (get(params, ['cellOptions', 'translatedValues'])) {
      return params.value;
    }
    return this.valueUnitFormatPipe.transform(variable);
  });

  isRecentDay: WritableSignal<boolean> = signal(false);
  isRecentHour: WritableSignal<boolean> = signal(false);
  isFutureDay: WritableSignal<boolean> = signal(false);
  isNextDay: WritableSignal<boolean> = signal(false);

  constructor() {
    effect(
      () => {
        const displayMode = this.displayMode();
        const variable = this.variable();
        if (displayMode === 'datetime') {
          this.processDateIntervals(moment(), variable?.lastValue?.datetime);
        }
      },
      { allowSignalWrites: true }
    );
  }

  agInit(params: CustomDiagnosticVariableCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    const params = this.params();
    const variable = this.variable();
    params.dispatchEvent({
      type: params.eventConfiguration.type,
      options: {
        ...params.eventConfiguration.options,
        variableName: variable.name.toLowerCase()
      },
      rawData: params.data
    });
  }

  processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay.set(DateIntervalUtils.isDateInThePastDay(now, date));
    this.isRecentHour.set(DateIntervalUtils.isDateInThePastHour(now, date));
    this.isNextDay.set(DateIntervalUtils.isDateInTheNextDay(now, date));
    this.isFutureDay.set(DateIntervalUtils.isDateInTheNextDay(now, date));
  }
}
