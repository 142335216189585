export class GetUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static get = (value: any, path: string, defaultValue?: any) =>
    String(path)
      .split('.')
      .reduce((acc: string, v: string) => {
        try {
          acc = acc[v] !== undefined && acc[v] !== null ? acc[v] : defaultValue;
        } catch (e) {
          return defaultValue;
        }
        return acc;
      }, value);
}
