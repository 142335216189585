import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';

import { AssociationByAssetComponent } from '../association-by-asset/association-by-asset.component';
import { AssociationByDeviceComponent } from '../association-by-device/association-by-device.component';
import { AssetDeviceAssociationItemDefaultComponent } from './asset-device-association-item-default.component';

@Injectable()
export class AssetDeviceAssociationItemFactory {
  readonly selector!: string;
  readonly componentType!: Type<any>;
  readonly ngContentSelectors: string[] = [];
  readonly inputs!: {
    propName: string;
    templateName: string;
  }[];
  readonly outputs!: {
    propName: string;
    templateName: string;
  }[];

  createComponent(elementType: string, viewContainerRef: ViewContainerRef): ComponentRef<AssetDeviceAssociationItemDefaultComponent> | null {
    switch (elementType) {
      case 'assets':
        return viewContainerRef.createComponent(AssociationByAssetComponent);
      case 'devices':
        return viewContainerRef.createComponent(AssociationByDeviceComponent);
      default:
        return null;
    }
  }
}
