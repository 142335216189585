import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { OrganizationsService } from '../../services/organizations.service';
import {
  AdminOrganizationsRolesApiActions,
  AdminOrganizationsRolesPageActions,
  AdminOrganizationsTagsApiActions,
  AdminOrganizationsTagsPageActions
} from '../actions';

@Injectable()
export class AdminOrganizationsTagsEffects {
  listTagsByOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.listTagsByOrganizationForEdition),
      mergeMap((action) =>
        this.organizationsService.getTagsByOrganization(action.organizationId, action.withParents, action.concept).pipe(
          map((tags) => AdminOrganizationsTagsApiActions.listTagsByOrganizationForEditionSuccess({ tags })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.listTagsByOrganizationForEditionFailure({ error })))
        )
      )
    )
  );

  listTagsInEditModeByOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.listTagsByOrganization),
      mergeMap((action) =>
        this.organizationsService.getTagsByOrganization(action.organizationId, action.withParents, action.concept).pipe(
          map((tags) => AdminOrganizationsTagsApiActions.listTagsByOrganizationSuccess({ tags })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.listTagsByOrganizationFailure({ error })))
        )
      )
    )
  );

  updateTagCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.updateTagInOrganization),
      mergeMap((action) =>
        this.organizationsService.updateTagInOrganization(action.tagToUpdate).pipe(
          map((updatedTagCategory) =>
            AdminOrganizationsTagsApiActions.updateTagInOrganizationSuccess({
              updatedTag: { id: updatedTagCategory.id, changes: updatedTagCategory }
            })
          ),
          catchError((error) => of(AdminOrganizationsTagsApiActions.updateTagInOrganizationFailure({ error })))
        )
      )
    )
  );

  updateTagInOrganizationForEdition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.updateTagInOrganizationForEdition),
      mergeMap((action) =>
        this.organizationsService.updateTagInOrganization(action.tagToUpdate).pipe(
          map((updatedTagCategory) =>
            AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionSuccess({
              updatedTag: { id: updatedTagCategory.id, changes: updatedTagCategory }
            })
          ),
          catchError((error) => of(AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionFailure({ error })))
        )
      )
    )
  );

  addTagCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.addTagToOrganization),
      mergeMap((action) =>
        this.organizationsService.addTagToOrganization(action.tagToAdd).pipe(
          map((addedTag) => AdminOrganizationsTagsApiActions.addTagToOrganizationSuccess({ newTag: addedTag })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.addTagToOrganizationFailure({ error })))
        )
      )
    )
  );

  addTagToOrganizationForEdition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.addTagToOrganizationForEdition),
      mergeMap((action) =>
        this.organizationsService.addTagToOrganization(action.tagToAdd).pipe(
          map((addedTag) => AdminOrganizationsTagsApiActions.addTagToOrganizationForEditionSuccess({ newTag: addedTag })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.addTagToOrganizationForEditionFailure({ error })))
        )
      )
    )
  );

  removeTagCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.removeTagFromOrganization),
      mergeMap((action) =>
        this.organizationsService.removeTagFromOrganization(action.tagToRemove).pipe(
          map((removedTag) => AdminOrganizationsTagsApiActions.removeTagFromOganizationSuccess({ removedTag })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.removeTagFromOganizationFailure({ error })))
        )
      )
    )
  );

  removeTagFromOrganizationForEdition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsTagsPageActions.removeTagFromOrganizationForEdition),
      mergeMap((action) =>
        this.organizationsService.removeTagFromOrganization(action.tagToRemove).pipe(
          map((removedTag) => AdminOrganizationsTagsApiActions.removeTagFromOganizationForEditionSuccess({ removedTag })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.removeTagFromOganizationForEditionFailure({ error })))
        )
      )
    )
  );

  successedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesApiActions.addRoleToOrganizationSuccess,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationSuccess,
          AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionSuccess,
          AdminOrganizationsTagsApiActions.addTagToOrganizationForEditionSuccess,
          AdminOrganizationsTagsApiActions.removeTagFromOganizationForEditionSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesApiActions.addRoleToOrganizationFailure,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationFailure,
          AdminOrganizationsRolesApiActions.addRoleToOrganizationFailure,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationFailure,
          AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionFailure,
          AdminOrganizationsTagsApiActions.addTagToOrganizationForEditionFailure
          /* AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionFailure,
                              AdminOrganizationsTagsApiActions.removeTagFromOganizationForEditionFailure*/
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesPageActions.listRolesByOrganization,
          AdminOrganizationsTagsPageActions.addTagToOrganizationForEdition,
          AdminOrganizationsTagsPageActions.updateTagInOrganizationForEdition,
          AdminOrganizationsTagsPageActions.removeTagFromOrganizationForEdition
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsRolesApiActions.listRolesByOrganizationSuccess,
          AdminOrganizationsRolesApiActions.addRoleToOrganizationSuccess,
          AdminOrganizationsRolesApiActions.removeRoleFromOrganizationSuccess,
          AdminOrganizationsTagsApiActions.updateTagInOrganizationForEditionSuccess,
          AdminOrganizationsTagsApiActions.addTagToOrganizationForEditionSuccess,
          AdminOrganizationsTagsApiActions.removeTagFromOganizationForEditionSuccess
        ),
        tap(() => this.notificationService.displayLoader(false))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService
  ) {}
}
