import { CellPosition, GridApi, HeaderPosition, NavigateToNextHeaderParams } from '@ag-grid-community/core';
import { GridNavigationType } from '@iot-platform/models/grid-engine';

export class AgGridNavigationHelpers {
  static moveHeaderFocusUpDown = (previousHeader: HeaderPosition, headerRowCount: number, isUp: boolean) => {
    const previousColumn = previousHeader.column;
    const lastRowIndex = previousHeader.headerRowIndex;
    let nextRowIndex = isUp ? lastRowIndex - 1 : lastRowIndex + 1;
    let nextColumn;
    if (nextRowIndex === -1) {
      return previousHeader;
    }
    if (nextRowIndex === headerRowCount) {
      nextRowIndex = -1;
    }
    const parentColumn = previousColumn.getParent();
    if (isUp) {
      nextColumn = parentColumn || previousColumn;
    } else {
      nextColumn = (previousColumn as any).children ? (previousColumn as any).children[0] : previousColumn;
    }
    return {
      headerRowIndex: nextRowIndex,
      column: nextColumn
    };
  };

  static moveCellFocusUpDown = (api: GridApi, key: string, previousCell: CellPosition, nextCellPosition: CellPosition, backwards?: boolean) => {
    const renderedRowCount = api.getModel().getRowCount();
    let nextRowIndex;

    if (key === GridNavigationType.TAB_TO_NEXT_CELL.toString()) {
      const lastRowIndex = previousCell.rowIndex;
      nextRowIndex = backwards ? lastRowIndex - 1 : lastRowIndex + 1;
      if (nextRowIndex < 0) {
        nextRowIndex = -1;
      }
      if (nextRowIndex >= renderedRowCount) {
        nextRowIndex = renderedRowCount - 1;
      }
      return {
        rowIndex: nextRowIndex,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned
      };
    } else if (key === GridNavigationType.KEY_UP.toString()) {
      nextRowIndex = previousCell.rowIndex - 1;
      if (nextRowIndex < -1) {
        return null;
      } // returning null means don't navigate
      return {
        rowIndex: nextRowIndex,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned
      };
    } else if (key === GridNavigationType.KEY_DOWN.toString()) {
      nextRowIndex = previousCell.rowIndex + 1;
      if (nextRowIndex >= renderedRowCount) {
        return null;
      } // returning null means don't navigate
      return {
        rowIndex: nextRowIndex,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned
      };
    } else if (key === GridNavigationType.KEY_LEFT.toString() || key === GridNavigationType.KEY_RIGHT.toString()) {
      return nextCellPosition;
    }
  };

  static processNextHeader = (params: NavigateToNextHeaderParams) => {
    const nextHeader = params.nextHeaderPosition;
    const processedNextHeader = AgGridNavigationHelpers.moveHeaderFocusUpDown(
      params.previousHeaderPosition,
      params.headerRowCount,
      params.key === GridNavigationType.KEY_UP.toString()
    );
    return {
      nextHeader,
      processedNextHeader
    };
  };
}
