import { Component, computed, EventEmitter, inject, Input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@iot-platform/auth';
import { AbstractMasterViewDrawerDetailsComponent } from '@iot-platform/feature/master-view';
import { ChatComponent } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Chat, ChatCustomComponent, ChatEvent, ChatMessage, IotAction } from '@iot-platform/models/common';
import { CommentContext, Concept, Log, LogType } from '@iot-platform/models/i4b';
import { noop } from 'rxjs';
import { CommentsFiltersComponent } from '../comments-filters/comments-filters.component';
import { DeleteCommentDialogComponent } from '../delete-comment-dialog/delete-comment-dialog.component';
import { EditCommentDialogComponent } from '../edit-comment-dialog/edit-comment-dialog.component';
import { EndMaintenanceCommentComponent } from '../end-maintenance-comment/end-maintenance-comment.component';
import { StartMaintenanceCommentComponent } from '../start-maintenance-comment/start-maintenance-comment.component';

@Component({
  standalone: true,
  imports: [ChatComponent],
  selector: 'iot4bos-ui-comments-panel',
  templateUrl: './comments-panel.component.html'
})
export class CommentsPanelComponent extends AbstractMasterViewDrawerDetailsComponent {
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  allComments: WritableSignal<Log[]> = signal([]);
  filteredComments: WritableSignal<Log[]> = signal([]);
  commentsLoading: WritableSignal<boolean> = signal(true);
  chat: Signal<Chat>;
  maintenanceCommentStyle = {
    backgroundColor: 'rgb(226, 216, 229, .1)',
    padding: '6px',
    margin: '-7px 0',
    borderLeft: '6px solid rgb(226, 216, 229)'
  };
  @Input() withHeader = true;
  @Input() headerBackgroundColor = '#4f7ab7';
  @Input() headerTextColor = '#fff';
  @Input() withFilters = false;
  @Input() filters: CommentContext[] = [];
  @Input() withFooter = true;
  @Input() footerBackgroundColor = '#4f7ab7';
  @Input() footerTextColor = '#fff';
  @Input() canEditComment = false;
  @Input() canDeleteComment = false;
  @Input() defaultConcept!: Concept;
  @Input() panelHeight = '100%';
  @Input() panelWidth = '310px';
  @Output() commentsEvent: EventEmitter<ChatEvent> = new EventEmitter<ChatEvent>();
  public readonly dialog: MatDialog = inject(MatDialog);

  constructor() {
    super();
    this.chat = computed(() => ({
      header: this.withHeader
        ? {
            title: signal('COMMENTS.TITLE_WITHOUT_TOTAL'),
            icon: signal('comment'),
            count: signal(this.filteredComments()?.length),
            loading: this.commentsLoading,
            backgroundColor: signal(this.headerBackgroundColor),
            textColor: signal(this.headerTextColor),
            action: signal({
              name: signal(IotAction.CLOSE),
              icon: signal('close'),
              canDo: signal(true)
            })
          }
        : undefined,
      body: {
        header: this.withFilters
          ? {
              ref: CommentsFiltersComponent,
              inputs: {
                commentsFilters: this.filters,
                onFilterChange: (filters: CommentContext[]): void => {
                  this.filterComments(this.allComments(), filters);
                }
              }
            }
          : undefined,
        content: {
          messages: signal(
            this.filteredComments()?.map((comment: Log): ChatMessage | ChatCustomComponent => {
              if (comment.type === LogType.USER) {
                return {
                  id: signal(comment.id),
                  text: signal(comment.comment),
                  author: signal(comment.user?.name),
                  date: signal(comment.datetime),
                  icon: signal(comment.icon),
                  withActions: signal(
                    (this.canEditComment && this.authFacade.currentUser().id === comment.user.id && comment.concept === this.defaultConcept) ||
                      (this.canDeleteComment && this.authFacade.currentUser().id === comment.user.id && comment.concept === this.defaultConcept)
                  ),
                  actions: signal([
                    {
                      name: signal(IotAction.EDIT),
                      icon: signal('edit'),
                      canDo: signal(this.canEditComment && this.authFacade.currentUser().id === comment.user.id && comment.concept === this.defaultConcept)
                    },
                    {
                      name: signal(IotAction.DELETE),
                      icon: signal('delete'),
                      canDo: signal(this.canDeleteComment && this.authFacade.currentUser().id === comment.user.id && comment.concept === this.defaultConcept)
                    }
                  ]),
                  customStyle: signal(this.isMaintenanceComment(comment) ? this.maintenanceCommentStyle : null)
                };
              } else if (comment.type === LogType.START_MAINTENANCE) {
                return {
                  ref: StartMaintenanceCommentComponent,
                  inputs: { log: comment }
                };
              } else if (comment.type === LogType.END_MAINTENANCE) {
                return {
                  ref: EndMaintenanceCommentComponent,
                  inputs: { comment }
                };
              }
            })
          ),
          emptyBodyLabel: signal('COMMENTS.NO_COMMENTS_FOUND'),
          loading: this.commentsLoading
        }
      },
      footer: this.withFooter
        ? {
            label: signal('COMMENTS.INPUT_PLACEHOLDER'),
            backgroundColor: signal(this.footerBackgroundColor),
            textColor: signal(this.footerTextColor),
            action: signal({
              name: signal(IotAction.ADD),
              icon: signal('send'),
              canDo: signal(true)
            })
          }
        : undefined,
      options: {
        height: signal(this.panelHeight),
        width: signal(this.panelWidth)
      }
    }));
  }

  @Input() set comments(value: Log[]) {
    this.allComments.set(value);
    this.filterComments(value, this.filters);
  }

  @Input() set loading(value: boolean) {
    this.commentsLoading.set(value);
  }

  @Input() dispatchCommentsEventAsInput: (event: ChatEvent) => void = () => noop();

  @Input() toggleDrawerDetails: (event: boolean) => void = () => noop();

  onDispatchAction(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.commentsEvent.emit({ name: event.name, value: event.value });
        this.dispatchCommentsEventAsInput({ name: event.name, value: event.value });
        break;
      case IotAction.EDIT:
        this.editComment(event.value as string);
        break;
      case IotAction.DELETE:
        this.deleteComment(event.value as string);
        break;
      case IotAction.CLOSE:
        this.commentsEvent.emit({ name: event.name });
        this.toggleDrawerDetails(false);
        break;
      default:
        break;
    }
  }

  editComment(id: string): void {
    const comment = this.allComments().find((log: Log) => log.id === id);

    this.dialog
      .open(EditCommentDialogComponent, {
        width: '400px',
        disableClose: true,
        data: { comment: comment.comment }
      })
      .afterClosed()
      .subscribe((result: string) => {
        if (result) {
          this.commentsEvent.emit({ name: IotAction.EDIT, value: { ...comment, comment: result } });
          this.dispatchCommentsEventAsInput({ name: IotAction.EDIT, value: { ...comment, comment: result } });
        }
      });
  }

  deleteComment(id: string): void {
    const comment = this.allComments().find((log: Log) => log.id === id);

    this.dialog
      .open(DeleteCommentDialogComponent, {
        width: '400px',
        disableClose: true,
        data: { comment: comment.comment }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.commentsEvent.emit({ name: IotAction.DELETE, value: comment.id });
          this.dispatchCommentsEventAsInput({ name: IotAction.DELETE, value: comment.id });
        }
      });
  }

  filterComments(comments: Log[], contexts: CommentContext[]): void {
    this.filteredComments.set(
      comments
        ?.filter((log: Log) => log.type === LogType.USER || log.type === LogType.START_MAINTENANCE || log.type === LogType.END_MAINTENANCE)
        ?.filter((comment: Log) =>
          this.withFilters ? contexts?.some((context: CommentContext) => context.checked && context.name === comment.concept) : comment
        )
        ?.sort(SortUtil.sortBy('datetime', true))
    );
  }

  isMaintenanceComment(comment: Log): boolean {
    const comIndex = this.filteredComments().findIndex((c) => c.id === comment.id);

    const commentsBefore = this.filteredComments().slice(0, comIndex);
    commentsBefore.reverse();
    const commentsAfter = this.filteredComments().slice(comIndex + 1);
    //
    const firstStartMaintenanceBeforeIndex = commentsBefore.findIndex((l) => l.type === LogType.START_MAINTENANCE);
    const firstEndMaintenanceBeforeIndex = commentsBefore.findIndex((l) => l.type === LogType.END_MAINTENANCE);

    const firstStartMaintenanceAfterIndex = commentsAfter.findIndex((l) => l.type === LogType.START_MAINTENANCE);
    const firstEndMaintenanceAfterIndex = commentsAfter.findIndex((l) => l.type === LogType.END_MAINTENANCE);

    if (firstStartMaintenanceBeforeIndex !== -1) {
      return firstEndMaintenanceBeforeIndex !== -1 ? firstStartMaintenanceBeforeIndex < firstEndMaintenanceBeforeIndex : true;
    } else {
      if (firstEndMaintenanceAfterIndex !== -1) {
        return firstStartMaintenanceAfterIndex !== -1 ? firstEndMaintenanceAfterIndex < firstStartMaintenanceAfterIndex : true;
      } else {
        return false;
      }
    }
  }
}
