import { UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { PhoneNumberInputComponent } from '@iot-platform/iot-platform-ui';
import { EmailValidators } from '@iot-platform/iot-platform-utils';
import { UserAccount } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCardModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIcon,
    ReactiveFormsModule,
    MatCheckbox,
    PhoneNumberInputComponent,
    MatTooltip,
    MatToolbar,
    UpperCasePipe,
    MatInputModule
  ],
  selector: 'iot-platform-users-user-profile-info-form',
  templateUrl: './user-profile-info-form.component.html',
  styleUrls: ['./user-profile-info-form.component.scss']
})
export class UserProfileInfoFormComponent implements OnInit {
  readonly dialogRef: MatDialogRef<UserProfileInfoFormComponent> = inject(MatDialogRef<UserProfileInfoFormComponent>);
  private readonly destroy = inject(DestroyRef);
  data: { user: UserAccount } = inject(MAT_DIALOG_DATA);

  userForm: UntypedFormGroup;
  initialFormState!: string;
  mediaOptions: { formControlName: string; label: string }[] = [
    { formControlName: 'notificationSMS', label: 'USERS.PROFILE_PREFERENCES.SMS' },
    { formControlName: 'notificationEmail', label: 'USERS.PROFILE_PREFERENCES.EMAIL' },
    { formControlName: 'notificationSecondaryEmail', label: 'USERS.PROFILE_PREFERENCES.SECONDARYEMAIL' }
  ];
  isFormDisabled = signal(true);

  get firstnameControl(): AbstractControl {
    return this.userForm.get('firstname');
  }

  get lastnameControl(): AbstractControl {
    return this.userForm.get('lastname');
  }

  get secondaryEmailControl(): AbstractControl {
    return this.userForm.get('secondaryEmail');
  }

  get phoneNumberControl(): AbstractControl {
    return this.userForm.get('phoneNumber');
  }

  get notificationSMSControl(): AbstractControl {
    return this.userForm.get('notificationSMS');
  }

  get notificationEmailControl(): AbstractControl {
    return this.userForm.get('notificationEmail');
  }

  get notificationSecondaryEmailControl(): AbstractControl {
    return this.userForm.get('notificationSecondaryEmail');
  }

  get currentFormState(): string {
    return JSON.stringify({ contactForm: this.userForm.value });
  }

  ngOnInit() {
    this.userForm = new UntypedFormGroup({
      firstname: new UntypedFormControl({ value: this.data.user.firstname, disabled: true }, [Validators.required]),
      lastname: new UntypedFormControl({ value: this.data.user.lastname, disabled: true }, [Validators.required]),
      email: new UntypedFormControl({ value: this.data.user.email, disabled: true }, [Validators.required, EmailValidators.isValid()], []),
      secondaryEmail: new UntypedFormControl(this.data.user.secondaryEmail ?? '', [EmailValidators.isValid()]),
      phoneNumber: new UntypedFormControl(this.data.user.phoneNumber ?? '', [Validators.pattern(/^(\+|00)/), Validators.maxLength(20)]),
      notificationSMS: new UntypedFormControl(this.data.user.notifications.sms),
      notificationEmail: new UntypedFormControl(this.data.user.notifications.email),
      notificationSecondaryEmail: new UntypedFormControl(this.data.user.notifications.secondaryEmail)
    });

    this.initialFormState = this.currentFormState;

    this.userForm.valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe((changes) => {
      if (changes.notificationSMS) {
        this.phoneNumberControl.addValidators([Validators.required]);
      } else {
        this.phoneNumberControl.removeValidators([Validators.required]);
      }
      if (changes.notificationSecondaryEmail) {
        this.secondaryEmailControl.addValidators([Validators.required]);
      } else {
        this.secondaryEmailControl.removeValidators([Validators.required]);
      }
      this.phoneNumberControl.markAsTouched();
      this.phoneNumberControl.markAsDirty();
      this.secondaryEmailControl.markAsTouched();
      this.phoneNumberControl.updateValueAndValidity({ onlySelf: true });
      this.secondaryEmailControl.updateValueAndValidity({ onlySelf: true });
      this.isFormDisabled.set(this.getIsFormDisabledValue());
    });
  }

  getIsFormDisabledValue(): boolean {
    return !this.formStateChanged() || !!Object.values(this.userForm.controls).find((control) => !!control.errors);
  }

  formStateChanged(): boolean {
    return this.initialFormState !== this.currentFormState;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({
      ...this.data.user,
      secondaryEmail: this.secondaryEmailControl.getRawValue().length ? this.secondaryEmailControl.getRawValue() : null,
      phoneNumber: this.phoneNumberControl.getRawValue().length ? this.phoneNumberControl.getRawValue() : null,
      notifications: {
        ...this.data.user.notifications,
        sms: this.notificationSMSControl.getRawValue(),
        email: this.notificationEmailControl.getRawValue(),
        secondaryEmail: this.notificationSecondaryEmailControl.getRawValue()
      }
    });
  }
}
