@if (isVisible) {
  <button
    mat-menu-item
    data-cy="i4b-table-engine-call-to-action-menu-item-btn"
    [disabled]="!isEnabled || isDisabled"
    (click)="onSingleActionClick(element, action.key)"
  >
    {{ action.label | translate | uppercase }}
  </button>
}
