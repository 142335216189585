<form [formGroup]="catalogForm" class="tag-editor-form-container" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    @if (mode === 'UPDATE') {
      <button
        (click)="onRemove(catalog)"
        [matTooltip]="'TAG_EDITOR.REMOVE' | translate"
        class="regular-round-button"
        color="warn"
        mat-icon-button
        matTooltipClass="warning-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon>delete</mat-icon>
      </button>
    }
  </div>
  <mat-form-field color="accent" fxFlex="200px">
    <mat-label>{{ 'TAG_EDITOR.CATEGORY_NAME' | translate }}</mat-label>
    <input #nameInput [value]="catalog.name" formControlName="name" matInput maxlength="20" required />
    @if (name.invalid && name.errors.duplicate) {
      <mat-error>
        {{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_CATEGORY' | translate }}
      </mat-error>
    }
    <mat-hint align="end"
      ><span>{{ nameInput.value.length || 0 }}/{{ nameMaximumLength }}</span></mat-hint
    >
  </mat-form-field>
  <mat-form-field color="accent" fxFlex>
    <mat-label>{{ 'ADMIN.PRODUCT_CATALOGS.FORM.ATTACHED_ENTITIES' | translate }}</mat-label>
    <mat-select formControlName="entities" multiple placeholder="" required>
      @if (entities.value) {
        <mat-select-trigger>{{ getEntitiesFormatted() }}</mat-select-trigger>
      }
      @for (entity of attachedEntitiesNotVisible?.concat(entityList); track entity.id) {
        <mat-option [disabled]="disableEntity(entity)" [value]="entity">{{ entity?.label }}</mat-option>
      }
    </mat-select>
    @if (entities.value.length > maximumEntitiesPerCatalog) {
      <mat-error>{{ 'ADMIN.PRODUCT_CATALOGS.FORM.TOO_MANY_ENTITIES' | translate }}</mat-error>
    }
    <mat-hint align="end" fxLayoutGap="10px">
      <span>{{ entities.value?.length || 0 }}/{{ maximumEntitiesPerCatalog }}</span>
    </mat-hint>
  </mat-form-field>
  @if (mode === 'UPDATE') {
    <button
      (click)="onSave(catalog)"
      [disabled]="!catalogForm.valid || (catalogForm.valid && !catalogForm.dirty)"
      class="button-regular"
      color="accent"
      mat-raised-button
    >
      {{ 'TAG_EDITOR.UPDATE' | translate | uppercase }}
    </button>
  } @else if (mode === 'ADD') {
    <button (click)="onSave(catalog)" [disabled]="!catalogForm.valid" class="button-regular" color="accent" mat-raised-button>
      {{ 'TAG_EDITOR.ADD' | translate | uppercase }}
    </button>
  }
</form>
