import { FavoriteView, Pagination } from '@iot-platform/models/common';
import { FavoriteViewsActions } from '@iot-platform/shared/components';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  BusinessProfilesApiActions,
  BusinessProfilesFavoriteViewsApiActions,
  BusinessProfilesFavoriteViewsPageActions,
  BusinessProfilesPageActions
} from '../actions/';

export const adminBusinessProfilesFavoriteViewsApiFeatureKey = 'adminBusinessProfilesFavoriteViewsApi';

export interface State extends EntityState<FavoriteView> {
  selectedFavoriteViewId: string | null;
  pagination: Pagination;
  error: any;
}

export const adapter: EntityAdapter<FavoriteView> = createEntityAdapter<FavoriteView>({
  selectId: (favoriteView: FavoriteView) => favoriteView.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedFavoriteViewId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 10 },
  error: null
});

export const adminBusinessProfilesFavoriteViewsReducer = createReducer(
  initialState,
  on(BusinessProfilesApiActions.selectBusinessProfile, (state) => adapter.removeAll(state)),

  on(BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile, (state) => adapter.removeAll(state)),
  on(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileSuccess, (state, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        total: response.total,
        hasMore: response.hasMore,
        limit: 10,
        currentPage: response.currentPage,
        maxPage: response.maxPage
      }
    })
  ),
  on(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess, (state, { addedFavoriteView }) => adapter.addOne(addedFavoriteView, state)),
  on(BusinessProfilesFavoriteViewsApiActions.addFavoriteViewFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewSuccess, (state, { updatedFavoriteView }) =>
    adapter.updateOne({ id: updatedFavoriteView.id, changes: updatedFavoriteView }, state)
  ),
  on(FavoriteViewsActions.updateFavoriteViewSuccess, (state, { favoriteView }) => adapter.updateOne({ id: favoriteView.id, changes: favoriteView }, state)),
  on(BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewFailure, (state, { error }) => ({ ...state, error })),
  //
  on(BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewSuccess, (state, { duplicatedFavoriteView }) =>
    adapter.addOne(duplicatedFavoriteView, state)
  ),
  on(BusinessProfilesFavoriteViewsApiActions.duplicateFavoriteViewFailure, (state, { error }) => ({ ...state, error })),
  //
  on(BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewSuccess, (state, { deletedFavoriteView }) =>
    adapter.removeOne(deletedFavoriteView.id, { ...state, pagination: { ...state.pagination, total: state.pagination.total - 1 } })
  ),
  on(FavoriteViewsActions.deleteFavoriteViewSuccess, (state, { deletedFavoriteView }) => adapter.removeOne(deletedFavoriteView.id, state)),
  on(BusinessProfilesFavoriteViewsPageActions.changePage, (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, currentPage: pagination.currentPage }
  })),
  on(BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return adminBusinessProfilesFavoriteViewsReducer(state, action);
}

export const getSelectedFavoriteViewId = (state: State) => state.selectedFavoriteViewId;
export const getPagination = (state: State) => state.pagination;
