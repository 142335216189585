import { NgClass } from '@angular/common';
import { Component, input, output, Signal, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar, ProgressBarMode } from '@angular/material/progress-bar';
import { MatProgressSpinner, ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatProgressBar, MatProgressSpinner, MatIcon, TranslateModule, MatTooltip, NgClass],
  selector: 'iot-platform-ui-progress-bar-button',
  templateUrl: './progress-bar-button.component.html',
  styleUrls: ['./progress-bar-button.component.scss']
})
export class ProgressBarButtonComponent {
  title = input<string>('');
  disabled = input<boolean>(false);
  enableGauge = input<boolean>(false);
  gaugeColor = input<string>('primary');
  fabIconButton = input<boolean>(false);
  icon = input<string>();
  buttonColor = input<string>();
  spinnerColor = input<string>('accent');
  cssClasses = input<string>();
  tooltip = input<string>();

  clickEvent = output<Event>();

  value: Signal<number> = signal(50);
  bufferValue: Signal<number> = signal(100);
  mode: Signal<ProgressBarMode | ProgressSpinnerMode> = signal('indeterminate');
}
