import { NgModule } from '@angular/core';
import { DetailAssetVariableCardComponent } from './detail-asset-variable-card/detail-asset-variable-card.component';
import { DetailAssetsCardComponent } from './detail-assets-card/detail-assets-card.component';
import { DetailCardComponent } from './detail-card/detail-card.component';
import { DetailContactCardComponent } from './detail-contact-card/detail-contact-card.component';
import { DetailDeviceCardComponent } from './detail-device-card/detail-device-card.component';
import { DetailDeviceVariableCardComponent } from './detail-device-variable-card/detail-device-variable-card.component';
import { DetailDevicesCardComponent } from './detail-devices-card/detail-devices-card.component';
import { DetailFollowedVariableComponent } from './detail-followed-variable/detail-followed-variable.component';
import { DetailPanelComponent } from './detail-panel/detail-panel.component';
import { DetailSimpleCardComponent } from './detail-simple-card/detail-simple-card.component';
import { DetailSiteCardComponent } from './detail-site-card/detail-site-card.component';

@NgModule({
  imports: [
    DetailAssetVariableCardComponent,
    DetailAssetsCardComponent,
    DetailCardComponent,
    DetailContactCardComponent,
    DetailDeviceCardComponent,
    DetailDeviceVariableCardComponent,
    DetailDevicesCardComponent,
    DetailFollowedVariableComponent,
    DetailPanelComponent,
    DetailSimpleCardComponent,
    DetailSiteCardComponent
  ],
  exports: [
    DetailCardComponent,
    DetailAssetVariableCardComponent,
    DetailAssetsCardComponent,
    DetailContactCardComponent,
    DetailDeviceCardComponent,
    DetailDeviceVariableCardComponent,
    DetailDevicesCardComponent,
    DetailPanelComponent,
    DetailSimpleCardComponent,
    DetailSiteCardComponent,
    DetailFollowedVariableComponent
  ]
})
export class DetailPopupCardsModule {}
