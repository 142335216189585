import { DEFAULT_PHONE_NUMBER_REGIN_CODE, PhoneNumber, PhoneNumberPossibleReason, PhoneNumberType } from '@iot-platform/models/common';
import { PhoneNumber as BasePhoneNumber, PhoneNumberFormat, PhoneNumberType as BasePhoneNumberType, PhoneNumberUtil, RegionCode } from 'google-libphonenumber';

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
const validationResult = PhoneNumberUtil.ValidationResult;

export class PhoneNumberUtils {
  static getNumberTypeString = (number: BasePhoneNumber): PhoneNumberType => {
    const phoneNumberTypeMap: {
      [key: number]: PhoneNumberType;
    } = {
      [BasePhoneNumberType.FIXED_LINE]: 'FIXED_LINE',
      [BasePhoneNumberType.MOBILE]: 'MOBILE',
      [BasePhoneNumberType.FIXED_LINE_OR_MOBILE]: 'FIXED_LINE_OR_MOBILE',
      [BasePhoneNumberType.TOLL_FREE]: 'TOLL_FREE',
      [BasePhoneNumberType.PREMIUM_RATE]: 'PREMIUM_RATE',
      [BasePhoneNumberType.SHARED_COST]: 'SHARED_COST',
      [BasePhoneNumberType.VOIP]: 'VOIP',
      [BasePhoneNumberType.PERSONAL_NUMBER]: 'PERSONAL_NUMBER',
      [BasePhoneNumberType.PAGER]: 'PAGER',
      [BasePhoneNumberType.UAN]: 'UAN',
      [BasePhoneNumberType.UNKNOWN]: 'UNKNOWN'
    };
    return phoneNumberTypeMap[phoneUtil.getNumberType(number)];
  };

  static getPossibleReasonString = (isPossibleReason: PhoneNumberUtil.ValidationResult): PhoneNumberPossibleReason => {
    let reason: PhoneNumberPossibleReason = null;
    switch (isPossibleReason) {
      case validationResult.INVALID_COUNTRY_CODE:
        reason = 'INVALID_COUNTRY_CODE';
        break;
      case validationResult.TOO_SHORT:
        reason = 'TOO_SHORT';
        break;
      case validationResult.TOO_LONG:
        reason = 'TOO_LONG';
        break;
      case validationResult.INVALID_LENGTH:
        reason = 'INVALID_LENGTH';
        break;
    }
    return reason;
  };

  static parsePhoneNumber(phoneNumber: string, regionCode = DEFAULT_PHONE_NUMBER_REGIN_CODE): PhoneNumber {
    const result: PhoneNumber = {
      regionCode: null,
      reason: null,
      isValid: false,
      numberType: null,
      originalFormat: null,
      nationalFormat: null,
      e164Format: null,
      internationalFormat: null
    };
    let isValid = false;
    try {
      const number: BasePhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
      const isPossible: boolean = phoneUtil.isPossibleNumber(number);
      const isPossibleReason = phoneUtil.isPossibleNumberWithReason(number);
      isValid = phoneUtil.isValidNumber(number);
      regionCode = phoneUtil.getRegionCodeForNumber(number);
      const numberType = this.getNumberTypeString(number);
      const hasRegionCode = regionCode && regionCode !== ('ZZ' as RegionCode);
      if (isPossible) {
        // Checking as isValid() fails if possible local only.
        if (isPossibleReason === validationResult.IS_POSSIBLE_LOCAL_ONLY) {
          isValid = false;
        } else if (isValid && hasRegionCode) {
          isValid = phoneUtil.isValidNumberForRegion(number, regionCode);
        }
      } else {
        // IS_POSSIBLE shouldn't happen, since we only call this if _not_ possible.
        // Note: Numbers that are not possible have type UNKNOWN, an unknown region, and are considered invalid.
        result.reason = this.getPossibleReasonString(isPossibleReason);
      }
      result.numberType = numberType;
      result.isValid = isValid;
      result.regionCode = regionCode;
      const PNF = PhoneNumberFormat;
      result.originalFormat = phoneUtil.formatInOriginalFormat(number, regionCode);
      result.nationalFormat = phoneUtil.format(number, PNF.NATIONAL);
      if (isValid) {
        result.e164Format = phoneUtil.format(number, PNF.E164);
        result.internationalFormat = phoneUtil.format(number, PNF.INTERNATIONAL);
        // result.usaFormat = phoneUtil.formatOutOfCountryCallingNumber(number, 'US');
      }
    } catch (_) {
      result.reason = 'UNKNOWN';
      result.isValid = false;
    }
    return result;
  }
}
