import { KeyValuePipe, NgComponentOutlet, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, computed, inject, Signal, TemplateRef, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverviewCardComponent } from '@iot-platform/iot-platform-ui';
import { DataGuardCheck, DataGuardEvent, DataGuardEventType } from '@iot-platform/models/data-guard';
import { TranslateModule } from '@ngx-translate/core';
import { DetailTabComponent } from '../../components/detail-tab/detail-tab.component';
import { OverviewCheckComponent } from '../../components/overview-check/overview-check.component';
import { OverviewTabComponent } from '../../components/overview-tab/overview-tab.component';
import { DataGuardSettings } from '../../models';
import { AbstractDataGuardService } from '../../services/abstract-data-guard.service';
import { DATA_GUARD_SETTINGS } from '../../settings/data-guard.provider';

@Component({
  selector: 'iot-platform-feature-data-guard',
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatIcon,
    MatTabsModule,
    TranslateModule,
    UpperCasePipe,
    MatTooltipModule,
    MatButtonModule,
    KeyValuePipe,
    MatProgressSpinner,
    OverviewCardComponent,
    OverviewCheckComponent,
    NgComponentOutlet,
    OverviewTabComponent,
    NgTemplateOutlet,
    DetailTabComponent
  ],
  templateUrl: './data-guard.component.html',
  styleUrl: './data-guard.component.scss'
})
export class DataGuardComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('overview') overview: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('detail') detail: TemplateRef<any>;
  protected readonly abstractService: AbstractDataGuardService = inject(AbstractDataGuardService);
  protected readonly settings: DataGuardSettings = inject(DATA_GUARD_SETTINGS);
  detailedChecks: Signal<DataGuardCheck> = this.abstractService.detailedChecks;
  overviewChecks: Signal<DataGuardCheck[]> = this.abstractService.overviewChecks;
  overviewChecksLoading = computed(() => {
    const checks = this.overviewChecks();
    return !checks;
  });
  detailedChecksLoading = computed(() => {
    const checks = this.detailedChecks();
    return !checks;
  });
  selectedTabIndex = computed(() => {
    const tabsSettings = this.settings.content?.tabs;
    const indexOfFirstActiveTab = Object.values(tabsSettings ?? {}).findIndex((tab) => tab?.active?.());
    return indexOfFirstActiveTab !== -1 ? indexOfFirstActiveTab : 0;
  });

  closeDrawerClicked(): void {
    this.abstractService.dispatchDataGuardEvent({ type: DataGuardEventType.CLICK_ON_CLOSE_BUTTON });
  }

  onDispatchOverviewTabEvent(event: DataGuardEvent): void {
    this.abstractService.dispatchDataGuardEvent(event);
  }
}
