import { BaseState, UserAccount, BusinessProfile, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { UsersActions } from '../actions/users.actions';

export interface State extends BaseState<UserAccount, Pagination, Filter> {
  selectedUser: UserAccount | null;
  businessProfilesByUser: BusinessProfile[];
}

export const adapter: EntityAdapter<UserAccount> = createEntityAdapter<UserAccount>({
  selectId: (entity: UserAccount) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  selectedUser: null,
  businessProfilesByUser: [],
  pagination: { currentPage: 0, hasMore: false, limit: 100, maxPage: 0, total: 0 }
});

export const usersFeature = createFeature({
  name: 'users',
  reducer: createReducer(
    initialState,
    on(
      UsersActions.getUserByIdSuccess,
      UsersActions.addUserSuccess,
      UsersActions.updateUserSuccess,
      UsersActions.enableUserSuccess,
      UsersActions.disableUserSuccess,
      UsersActions.resendUserValidationLinkSuccess,
      (state: State, { user: entity }): State => ({ ...state, entity })
    ),
    on(
      UsersActions.getBusinessProfilesByUserIdSuccess,
      (state: State, { businessProfiles }): State => ({ ...state, businessProfilesByUser: businessProfiles })
    ),
    on(UsersActions.setFilters, (state: State, { filters }): State => ({ ...state, filters: [...filters] }))
  )
});
