// AoT requires an exported function for factories
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as localeFr from '@angular/common/locales/global/fr';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { forkJoin } from 'rxjs';

export interface ModuleOptions {
  langs?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class I18nOptions {
  public langs = [];
}

export const FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<ModuleOptions>('forRoot() I18n configuration.');

export function provideI18nOptions(options?: ModuleOptions): I18nOptions {
  const i18nOptions = new I18nOptions();
  if (options) {
    if (options.langs instanceof Array) {
      i18nOptions.langs = options.langs;
    }
  }
  return i18nOptions;
}

registerLocaleData(localeFr, 'fr-FR');

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function TranslateInitializerFactory(translateService: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const options: I18nOptions = injector.get(I18nOptions);
    const languages = options.langs && !!options.langs.length ? [...options.langs] : ['en'];
    const defaultLang = 'en';

    translateService.addLangs(languages);

    // Wait for all translations to be loaded
    await forkJoin(languages.filter((l: string) => l !== defaultLang).map((l: string) => translateService.use(l))).toPromise();

    // Set default language
    translateService.setDefaultLang(defaultLang);
    translateService.currentLang = '';
    await translateService.use(defaultLang).toPromise();
  };
}
