import { computed, inject, Injectable, Signal } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { BaseFacade, Filter } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import * as Leaflet from 'leaflet';
import { IotGeoJsonFeature, IotMapRequest } from '../../models';
import { IotGeoJsonRouteFeature } from '../../models/iot-geo-json-object.model';
import { MapActions } from '../actions';
import { MapSelectors } from '../selectors/map.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapFacade extends BaseFacade<IotGeoJsonFeature, unknown, Filter> {
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  concept$: Observable<string> = this.store.select(this.selector.selectConcept);
  currentFeatures: Signal<IotGeoJsonFeature[]> = this.store.selectSignal(this.selector.selectCurrentFeatures);
  currentRoutes: Signal<IotGeoJsonRouteFeature[]> = this.store.selectSignal(this.selector.selectCurrentRoutes);
  routesLoading: Signal<boolean> = this.store.selectSignal(this.selector.selectRoutesLoading);
  routesLoaded: Signal<boolean> = this.store.selectSignal(this.selector.selectRoutesLoaded);
  hasRoutes: Signal<boolean> = this.store.selectSignal(this.selector.selectHasRoutes);
  refresh: Signal<boolean> = this.store.selectSignal(this.selector.refresh);
  maploading: Signal<boolean> = this.store.selectSignal(this.selector.selectLoading);
  canReadContacts = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ);
  });

  constructor(
    protected override store: Store,
    protected override selector: MapSelectors
  ) {
    super(store, selector);
  }

  getAll(request: IotMapRequest): void {
    this.store.dispatch(MapActions.loadGeoLocations(request));
  }

  loadRoute(assetId: string, limit: number, start: Date, end: Date, minutesBetweenSegments: number, order: string): void {
    this.store.dispatch(MapActions.loadRoute({ assetId, limit, start, end, minutesBetweenSegments, order }));
  }

  refreshMap(): void {
    this.store.dispatch(MapActions.refreshMap());
  }

  loadMarkerDetails(feature: IotGeoJsonFeature): void {
    this.store.dispatch(MapActions.loadMarkerDetails({ feature }));
  }

  setFilters(filters: Filter[]): void {
    this.store.dispatch(MapActions.setFilters({ filters }));
  }

  setConcept(concept: string): void {
    this.store.dispatch(MapActions.setConcept({ concept }));
  }

  saveMapUiState(state: {
    center: Leaflet.LatLng;
    zoom: number;
    selectedMarker: Leaflet.Marker;
    hasLeavedMap: boolean;
    isReturningFromOutside: boolean;
  }): void {
    this.store.dispatch(MapActions.saveMapUiState({ mapUiState: state }));
  }

  clearRoutes() {
    this.store.dispatch(MapActions.clearRoutes());
  }
}
