import { Signal, Type, WritableSignal } from '@angular/core';
import { AbstractDataGuardService } from '../services/abstract-data-guard.service';

export interface DataGuardHeaderSettings {
  enabled: Signal<boolean> | WritableSignal<boolean>;
  title: Signal<string> | WritableSignal<string>;
  svgIcon: Signal<string> | WritableSignal<string>;
  infoIcon: Signal<boolean> | WritableSignal<boolean>;
  infoTooltip: Signal<string> | WritableSignal<string>;
  closeButton: Signal<boolean> | WritableSignal<boolean>;
}

export interface DataGuardContentSettings {
  tabs: { [tabKey in DataGuardTabID]: DataGuardTabSettings };
}

export interface DataGuardTabSettings {
  active: Signal<boolean> | WritableSignal<boolean>;
  disabled: Signal<boolean> | WritableSignal<boolean>;
  label: Signal<string> | WritableSignal<string>;
  containsError: Signal<boolean> | WritableSignal<boolean>;
}

export enum DataGuardTabID {
  DETAIL = 'detail',
  OVERVIEW = 'overview'
}

export class DataGuardSettings {
  header: Partial<DataGuardHeaderSettings>;
  content: Partial<DataGuardContentSettings>;
}

export type DataGuardSettingsFactory = (
  options?: Partial<{
    dataGuardService: Type<AbstractDataGuardService>;
  }>
) => Partial<DataGuardSettings>;
