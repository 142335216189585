import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ContactsEffects } from '../effects/contacts.effects';
import * as fromContacts from './contacts.reducer';

export function provideContactsState(): EnvironmentProviders[] {
  return [provideState(fromContacts.contactsFeature.name, fromContacts.contactsFeature.reducer), provideEffects(ContactsEffects)];
}

export { fromContacts };
