import { ChangeDetectionStrategy, Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractFollowedVariableAddPopupService } from './abstract-followed-variable-add-popup.service';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, MatCardModule, MatIconModule, MatToolbarModule, MatButtonModule, GridEngineModule],
  selector: 'iot4bos-ui-asset-followed-variable-add-popup',
  templateUrl: './followed-variable-add-popup.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowedVariableAddPopupComponent implements OnInit {
  grid!: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>>;
  selectedRowIndex: WritableSignal<number> = signal(-1);

  constructor(
    private readonly followedVariableAddPopupService: AbstractFollowedVariableAddPopupService,
    public readonly dialogRef: MatDialogRef<FollowedVariableAddPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
  ) {}

  ngOnInit(): void {
    this.initGridEffect();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDispatchGridEvent({ event }: { event: any }) {
    switch (event.type) {
      case 'rowClicked':
        this.selectedRowIndex.set(+event.rowIndex);
        break;
      default:
        break;
    }
  }

  save(): void {
    this.dialogRef.close(this.selectedRowIndex());
  }

  close(): void {
    this.dialogRef.close();
  }

  private initGridEffect() {
    this.grid = signal(this.followedVariableAddPopupService.initGrid());
  }
}
