import { NgModule } from '@angular/core';
import { SharedModule } from '@iot-platform/shared';
import { RolesListComponent } from './roles-list.component';

@NgModule({
  declarations: [RolesListComponent],
  imports: [SharedModule],
  exports: [RolesListComponent]
})
export class RolesListModule {}
