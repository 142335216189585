import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { User  } from '@iot-platform/models/common';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductCellService {
  products$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  products: any[] = null;

  constructor(@Inject('environment') private environment, private http: HttpClient) {
    this.http
      .get<any>(`${this.environment.api.url}/products?page=0&limit=1000`)
      .pipe(map((result) => result.content))
      .subscribe((items) => {
        this.products = [...items];
        this.products$.next(this.products);
      });
  }

  public getProductById(productId): Observable<any> {
    return this.products$.pipe(
      filter((items) => items.length > 0),
      map((items) => items.find((item) => item.id === productId))
    );
  }
}
