import { Component, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot-platform-ui-button-header',
  templateUrl: './button-header.component.html',
  styleUrls: ['./button-header.component.scss']
})
export class ButtonHeaderComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;

  @Output() dispatchEvent;
}
