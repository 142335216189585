import { CommonApiResponse, Pagination } from '@iot-platform/models/common';
import {
  I4BBasicColumn,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridOptions
} from '@iot-platform/models/grid-engine';
import { AssetTemplateVariable } from '@iot-platform/models/i4b';

export interface UnfollowedVariablesGridData {
  response: CommonApiResponse<AssetTemplateVariable, Pagination>;
}

export class UnfollowedVariablesGrid implements I4BGrid<I4BGridOptions, UnfollowedVariablesGridData> {
  masterview = '';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = '';
  userId = null;
  data: UnfollowedVariablesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 1000,
    multipleSelection: false,
    autoRefresh: { enabled: false }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
    new I4BBasicColumn(
      { displayName: 'NAME' },
      {
        id: 'name',
        isDefault: true,
        sortProperty: 'name',
        cell: { type: I4BCellType.BASIC }
      },
      { order: 0 }
    ),
    new I4BBasicColumn(
      { displayName: 'COMMENT' },
      {
        id: 'comment',
        isDefault: true,
        sortProperty: 'comment',
        cell: { type: I4BCellType.BASIC }
      },
      { order: 1 }
    ),
    new I4BBasicColumn(
      { displayName: 'THRESHOLD 1' },
      {
        id: 'thresholds.values.0.value',
        isDefault: true,
        sortProperty: 'thresholds.values.0.value',
        cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL }
      },
      { order: 2 }
    ),
    new I4BBasicColumn(
      { displayName: 'THRESHOLD 2' },
      {
        id: 'thresholds.values.1.value',
        isDefault: true,
        sortProperty: 'thresholds.values.1.value',
        cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL }
      },
      { order: 3 }
    )
  ];
}
