import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'i4b-table-engine-linked-cell',
  templateUrl: './linked-cell.component.html',
  styleUrls: ['./linked-cell.component.scss']
})
export class LinkedCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  tooltip: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.tooltip = this.translateService.instant(`ASSETS.VARIABLE.TOOLTIPS.${this.data ? 'LINKED' : 'NOT_LINKED'}`);
  }
}
