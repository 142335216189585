@if (!data) {
  <span class="count-cell-value">-</span>
}
@if (data && data.length) {
  <span class="count-cell-value">{{ data.length | numberFormat }}</span>
}
@if (data && !data.length) {
  <span class="count-cell-value">-</span>
}
<!--<span class="mv-cell-value" *ngIf="data && unit && !isLink && unit !== undefined && unit !== null">{{ data + ' ' + unit }}</span>
<span class="mv-cell-value link" *ngIf="data && isLink"><ng-content></ng-content>{{ data }}</span>-->
