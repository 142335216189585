<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" style="display: flex; align-items: center; justify-content: space-between">
      <h1 class="dialog-card-title">GRAPHS FOR "{{ data.followedVariable.name }}"</h1>
      <div>
        <button (click)="dialogRef.close()" cdkFocusInitial class="regular-round-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </mat-card-title>
</mat-card>
<mat-dialog-content>
  @if (loading()) {
    <iot-platform-ui-card-loader [backgroundClass]="'h-140'" fxFlex></iot-platform-ui-card-loader>
  } @else {
    <iot-platform-ui-widgets-container
      (dispatchEvent)="onDispatchEvent($event.widget.data, $event.action)"
      [widgets]="widgets()"
    ></iot-platform-ui-widgets-container>
  }
</mat-dialog-content>
