import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';

export class EventDurationCellHelpers {
  static processValue(translateService: TranslateService, params: ProcessCellForExportParams): string {
    const {
      value: { days, hours, minutes, seconds }
    } = params;
    if (seconds < 0) {
      return 'N/A';
    }
    const d = days > 0 ? `${days}${translateService.instant('EVENTS.TIMELINE.TIME.DAY')} ` : '';
    const h = hours > 0 ? `${hours}${translateService.instant('EVENTS.TIMELINE.TIME.HOUR')} ` : '';
    const m = minutes > 0 ? `${minutes}${translateService.instant('EVENTS.TIMELINE.TIME.MINUTE')} ` : '';
    const s = seconds > 0 ? `${seconds}${translateService.instant('EVENTS.TIMELINE.TIME.SECOND')}` : '';
    return `${d}${h}${m}${s}`;
  }
}
