export class MapSvgHelper {
  public static getCCFSvgIcon = (size: string, displayCCF: number, childCount: number) => (
      '<svg width="' +
      size +
      '" height="' +
      size +
      '" viewBox="0 0 42 42" class="donut">' +
      '  <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>' +
      '  <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="#EEEEEE"></circle>' +
      '  <text x="50%" y="50%" text-anchor="middle" fill="#394C5A" stroke-width="1px" dy=".3em" font-family="Arial">' +
      childCount +
      '</text>' +
      '  <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#6EAC4A" stroke-width="3" stroke-dasharray="100" stroke-dashoffset="0"></circle>\n' +
      '  <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#BF5F68" stroke-width="3" stroke-dasharray="' +
      displayCCF +
      ' 100" transform="rotate(-90) translate(-42)""></circle>\n' +
      '</svg>'
    );

  public static getStandardSvgIcon = (size: string, childCount: number) => (
      '<svg width="' +
      size +
      '" height="' +
      size +
      '" viewBox="0 0 42 42" class="donut">' +
      '  <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>' +
      '  <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="#EEEEEE"></circle>' +
      '  <text x="50%" y="50%" text-anchor="middle" fill="#394C5A" stroke-width="1px" dy=".3em" font-family="Arial">' +
      childCount +
      '</text>' +
      '  <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#4F78B7" stroke-width="3" stroke-dasharray="100" stroke-dashoffset="0"></circle>' +
      '</svg>'
    );

  public static getVariableSvgIcon = (
    size: string,
    childCount: number,
    values: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[]
  ) => (
      '<svg width="' +
      size +
      '" height="' +
      size +
      '" viewBox="0 0 42 42" class="donut">' +
      '  <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#EEEEEE" stroke-width="5" stroke-dasharray="100" stroke-dashoffset="0"></circle>' +
      '  <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>' +
      '  <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="#EEEEEE"></circle>' +
      '  <text x="50%" y="50%" text-anchor="middle" fill="#394C5A" stroke-width="1px" dy=".3em" font-family="Arial">' +
      childCount +
      '</text>' +
      this.getCircles(values) +
      '</svg>'
    );

  private static getCircles = (values: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[]) => {
    let result = '';
    const total = values.reduce((acc, value) => acc + value.count, 0);
    if (values.length > 0) {
      values.forEach((value, index) => {
        const newCount = (value.count / total) * 100;
        let offset = 0;
        for (let i = index; i > 0; i--) {
          offset += (values[i - 1].count / total) * 100;
        }
        const newValue = {
          ...value,
          count: newCount,
          strokeDashOffset: 100 - offset + 25
        };
        result += this.getCircle(newValue);
      });
    }

    return result;
  };

  private static getCircle = (value: { color: string; strokeDashOffset: number; strokeDashArray: string; count: number }) => {
    const offset = 100 - value.count;
    const strokeDashArray = value.count + ' ' + offset;
    return (
      '<circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="' +
      value.color +
      '" stroke-width="3" stroke-dasharray="' +
      strokeDashArray +
      '" stroke-dashoffset="' +
      value.strokeDashOffset +
      '"></circle>'
    );
  };
}
