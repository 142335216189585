import { I4BCellType, I4BColumnConfiguration } from './configurations/i4b-column-configuration';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { I4BAssetTemplatesButtonColumn } from './definitions';
import { I4BBasicColumn } from './definitions/i4b-basic.column';
import { I4BColumn } from './definitions/i4b-column';
import { I4bCellConcept } from './enums';
import { I4BColumnHeader } from './models/i4b-column-header.model';
import { AssetTemplatesGridData } from './models/i4b-grid-data.model';
import { I4BGridOptions } from './models/i4b-grid-options.model';
import { I4BGrid } from './models/i4b-grid.model';
import { I4BColumnOptions } from './options/i4b-column-options';

export const DEFAULT_ASSET_TEMPLATES_COLUMNS_FOR_ASSET_TEMPLATES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new I4BBasicColumn(
    { displayName: 'NAME' },
    {
      id: 'name',
      isDefault: true,
      sortProperty: 'name',
      concept: I4bCellConcept.ASSET_TEMPLATES,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToAssetTemplate' } }
    },
    { order: 1 }
  ),

  new I4BBasicColumn(
    { displayName: 'CATEGORY' },
    {
      id: 'category',
      isDefault: true,
      sortProperty: 'category',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.ASSET_TEMPLATES
    },
    { order: 2 }
  ),

  new I4BBasicColumn(
    { displayName: 'ENTITY' },
    {
      id: 'entity.name',
      isDefault: true,
      sortProperty: 'entity.name',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.ASSET_TEMPLATES
    },
    { order: 3 }
  ),

  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'description',
      isDefault: true,
      sortProperty: 'description',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.ASSET_TEMPLATES,
      autoSize: true
    },
    { order: 4 }
  )
];

export class DefaultAssetTemplatesGrid implements I4BGrid<I4BGridOptions, AssetTemplatesGridData> {
  masterview = 'asset-templates';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = 'asset-templates';
  userId = '';
  data: AssetTemplatesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new I4BAssetTemplatesButtonColumn() },
    gridSort: []
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];
}
