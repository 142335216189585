import { Organization } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listOrganizationsSuccess = createAction('[Organizations API] List ALL Organizations Success', props<{ organizations: Organization[] }>());
export const listOrganizationsFailure = createAction('[Organizations API] List ALL Organizations Failure', props<{ error: any }>());

export const selectOrganizationSuccess = createAction(
  '[Organizations API] Select Organization And request details Success',
  props<{ selectedOrganization: Organization }>()
);
export const selectOrganizationFailure = createAction('[Organizations API] Select Organization And request details Failure', props<{ error: any }>());

export const addOrganizationSuccess = createAction('[Organizations/Admin Page] ADD Organization Success', props<{ organization: Organization }>());
export const addOrganizationFailure = createAction('[Organizations/Admin Page] ADD Organization Failure', props<{ error: any }>());

export const updateOrganizationSuccess = createAction('[Organizations/Admin Page] Update Organization Success', props<{ updatedOrganization: Organization }>());
export const updateOrganizationFailure = createAction('[Organizations/Admin Page] Update Organization Failure', props<{ error: any }>());

export const deleteOrganizationSuccess = createAction('[Organizations/Admin Page] Delete Organization Success', props<{ removedOrganization: Organization }>());
export const deleteOrganizationFailure = createAction('[Organizations/Admin Page] Delete Organization Failure', props<{ error: any }>());
