import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AssetTemplatesActions } from '../actions/asset-templates.actions';

export type State = BaseState<AssetTemplate, Pagination, Filter>;

export const adapter: EntityAdapter<AssetTemplate> = createEntityAdapter<AssetTemplate>({
  selectId: (entity: AssetTemplate) => entity.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 100, maxPage: 0, total: 0 }
});

export const assetTemplatesFeature = createFeature({
  name: 'assetTemplates',
  reducer: createReducer(
    initialState,
    on(AssetTemplatesActions.setFilters, (state: State, { filters }) => ({ ...state, filters })),
    on(
      AssetTemplatesActions.getAssetTemplateByIdSuccess,
      AssetTemplatesActions.addAssetTemplateSuccess,
      AssetTemplatesActions.updateAssetTemplateSuccess,
      AssetTemplatesActions.updateTemplateSuccess,
      AssetTemplatesActions.updateAssetTemplateTagsSuccess,
      AssetTemplatesActions.configureAssetTemplateProductsSuccess,
      AssetTemplatesActions.removeAssetTemplateProductsSuccess,
      AssetTemplatesActions.configureAssetTemplateOptionalPropertiesSuccess,
      AssetTemplatesActions.addAssetTemplateFollowedVariableSuccess,
      AssetTemplatesActions.removeAssetTemplateFollowedVariableSuccess,
      AssetTemplatesActions.createAssetTemplateVariableSuccess,
      AssetTemplatesActions.updateAssetTemplateVariableSuccess,
      AssetTemplatesActions.configureAssetTemplateVariableSuccess,
      AssetTemplatesActions.deleteAssetTemplateVariableSuccess,
      AssetTemplatesActions.bulkDeleteAssetTemplateVariableSuccess,
      AssetTemplatesActions.navigateToAssetTemplateDetails,
      (state: State, { assetTemplate: entity }): State => ({ ...state, entity })
    )
  )
});
