import { get } from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ConditionProcessorUtil {
  static processConditionsWithPermission = (conditions: any, userPermissions: { key: string; value: boolean }[]) => {
    if (!conditions || (conditions && conditions.length === 0)) {
      return true;
    } else {
      return conditions.reduce((andConditionArrayResult, orConditionArray) => {
        const orConditionResult = orConditionArray.reduce((conditionResult, condition) => {
          const currentPermission = userPermissions.find((permission) => permission.key === condition.key);
          if (currentPermission) {
            conditionResult = conditionResult || currentPermission.value === condition.value;
          }
          return conditionResult;
        }, false);

        return andConditionArrayResult && orConditionResult;
      }, true);
    }
  };

  static processConditionsWithElement = (conditions: any, element: any, forEnable: boolean) => {
    if (!conditions || (conditions && conditions.length === 0) || !element) {
      return forEnable;
    } else {
      return conditions.reduce((andConditionArrayResult, orConditionArray) => {
        const orConditionResult = orConditionArray.reduce((conditionResult, condition) => {
          const currentPermissionResult = ConditionProcessorUtil.getValue(element, condition.key) === ConditionProcessorUtil.getValue(condition, 'value');
          conditionResult = conditionResult || currentPermissionResult;
          return conditionResult;
        }, false);

        return andConditionArrayResult && orConditionResult;
      }, true);
    }
  };

  private static getValue(element: any, key: string): string {
    const value = get(element, key, '');
    return value ? value.toString().toLowerCase() : '';
  }
}
