import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';

import { DeviceEventsBySiteDbActions, DeviceEventsBySiteUiActions } from '../../actions';

export const deviceEventsBySiteUiFeatureKey = 'deviceEventsBySiteUi';

export interface State {
  error?: any;
  deviceEventsLoaded: boolean;
  deviceEventsLoading: boolean;
  totalActiveEventsLoaded: boolean;
}

export const initialState: State = {
  error: null,
  deviceEventsLoaded: false,
  deviceEventsLoading: false,
  totalActiveEventsLoaded: false
};

const deviceEventsBySiteUiReducer = createReducer(
  initialState,
  on(DeviceEventsBySiteUiActions.loadDeviceEventsBySite, (state: State) => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsBySiteDbActions.loadDeviceEventsBySiteSuccess, (state: State) => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true })),
  on(DeviceEventsBySiteDbActions.loadDeviceEventsBySiteFailure, (state: State, { error }) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: false,
    error
  })),
  // ****
  on(DeviceEventsBySiteUiActions.loadTotalActiveDeviceEventsBySite, (state: State) => ({ ...state, totalActiveEventsLoaded: false })),
  on(DeviceEventsBySiteDbActions.loadTotalActiveDeviceEventsBySiteSuccess, (state: State) => ({ ...state, totalActiveEventsLoaded: true })),
  on(DeviceEventsBySiteDbActions.loadTotalActiveAssetEventsBySiteFailure, (state: State, { error }) => ({ ...state, totalActiveEventsLoaded: false, error })),
  // ****
  on(DeviceEventsBySiteUiActions.bulkUpdateStatusByDeviceEventIdBySite, (state: State) => ({ ...state, deviceEventsLoaded: false })),
  on(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteSuccess, (state: State) => ({ ...state, deviceEventsLoaded: true })),
  on(DeviceEventsBySiteDbActions.updateStatusByDeviceEventIdBySiteFailure, (state: State, { error }) => ({ ...state, deviceEventsLoaded: false, error })),
  // ****
  on(DeviceEventsBySiteUiActions.loadMvDeviceEventsBySiteSettings, (state: State) => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsBySiteDbActions.loadMvDeviceEventsBySiteSettingsSuccess, (state: State) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: true
  })),
  on(DeviceEventsBySiteDbActions.loadMvDeviceEventsBySiteSettingsFailure, (state: State, { error }) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: false,
    error
  })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsBySiteUiReducer(state, action);
}

export const getDeviceEventsLoaded = (state: State) => state.deviceEventsLoaded;
export const getDeviceEventsLoading = (state: State) => state.deviceEventsLoading;
export const getTotalActiveEventsLoaded = (state: State) => state.totalActiveEventsLoaded;
