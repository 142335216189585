<form [formGroup]="parametersForm" class="asset-variable-formula-result three-lines" fxLayout="column">
  <span class="asset-variable-formula-linear-info">{{ 'ASSETS.VARIABLE_FORM.DP_IN_BAR' | translate }}</span>
  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field color="accent" fxFlex="30%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.HEAD_HEIGHT' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaLinearHeadHeight" matInput min="0.01" type="number" />
    </mat-form-field>
    <mat-form-field color="accent" fxFlex="30%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.DIAMETER' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaLinearDiameter" matInput min="0.01" type="number" />
    </mat-form-field>
    <mat-form-field color="accent" fxFlex="30%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.LENGTH' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaLinearLength" matInput min="0.01" type="number" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field color="accent" fxFlex="30%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.LIQUID_GAS' | translate }}</mat-label>
      <mat-select class="asset-variable-formula-input" formControlName="formulaLinearDensity">
        @for (gas of gasList$ | async; track gas.key) {
          <mat-option [value]="gas.value">{{ 'ASSETS.VARIABLE_FORM.GAS_LIST.' + gas.key | translate }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field color="accent" fxFlex="30%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.MAX_READ' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaLinearMaxRead" matInput min="0.01" type="number" />
    </mat-form-field>
    <mat-form-field color="accent" fxFlex="30%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.MAX_DISPLAY' | translate }}</mat-label>
      <input class="asset-variable-formula-input" formControlName="formulaLinearMaxDisplay" matInput min="0.01" type="number" />
    </mat-form-field>
  </div>
</form>
