import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule],
  selector: 'iot-platform-maps-map-spinner',
  templateUrl: './map-spinner.component.html',
  styleUrls: ['./map-spinner.component.scss']
})
export class MapSpinnerComponent {
  loading = input<boolean>(false);
  label = input<string>();
}
