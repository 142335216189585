import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { GridManagerAdminPopupComponent, GridsService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  MANAGE_MASTER_VIEWS_BUTTON_CONFIG,
  ManageTagsDialogComponent,
  PopupComponent
} from '@iot-platform/iot-platform-ui';

import { BusinessProfile, Entity, IotToolbarEvent, Organization, Role, TagCategory, ToolbarSize } from '@iot-platform/models/common';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';

import { map, switchMap, tap } from 'rxjs/operators';
import { AdminSettingsPopupComponent } from '../../../../../../../table-engine/src/lib/components/table/settings/admin-settings-popup/admin-settings-popup.component';

import { DialogAdministratorAddFormComponent } from '../../../components/dialogs/dialog-administrator-add-form/dialog-administrator-add-form.component';
import { DialogBusinessProfileAddFormComponent } from '../../../components/dialogs/dialog-business-profile-add-form/dialog-business-profile-add-form.component';
import { DialogManageConceptsFormComponent } from '../../../components/dialogs/dialog-manage-concepts-form/dialog-manage-concepts-form.component';
import { DialogOrganizationAddFormComponent } from '../../../components/dialogs/dialog-organization-add-form/dialog-organization-add-form.component';
import { DialogRoleAddFormComponent } from '../../../components/dialogs/dialog-role-add-form/dialog-role-add-form.component';
import * as fromOrganizations from '../../admin-organizations/state/reducers';

import {
  AdminOrganizationsBusinessProfilesPageActions,
  AdminOrganizationsPageActions,
  AdminOrganizationsRolesPageActions,
  AdminOrganizationsTagsPageActions
} from '../state/actions';

@Component({
  selector: 'iot4bos-ui-backoffice-admin-organizations',
  templateUrl: './admin-organizations.component.html',
  styleUrls: ['../../../style/admin.style.scss', './admin-organizations.component.scss']
})
export class AdminOrganizationsComponent implements OnInit {
  organizations$ = this.store.select(fromOrganizations.getAllOrganizations);
  selectedOrganization$ = this.store.select(fromOrganizations.getSelectedOrganization);
  analytics: AnalyticsService = new AnalyticsService('admin_organizations_shell');
  roles$ = this.store.select(fromOrganizations.getAllRoles);
  businessProfiles$ = this.store.select(fromOrganizations.getAllBusinessProfiles);
  tags$ = this.store.select(fromOrganizations.getAllTagsWithSortedLabels);
  administrators$ = this.store.select(fromOrganizations.getAllAdministrators);
  selectedRole$ = this.store.select(fromOrganizations.getSelectedRole);
  isAdmin$ = this.store.select(fromAuth.selectIsUserAdmin);
  administratorsPendingStatus$ = this.store.select(fromOrganizations.getAdministratorsPendingStatus);
  businessProfilesPendingStatus$ = this.store.select(fromOrganizations.getBusinessProfilesPendingStatus);
  rolesPendingStatus$ = this.store.select(fromOrganizations.getRolesPendingStatus);
  tagsPendingStatus$ = this.store.select(fromOrganizations.getTagsPendingStatus);
  organizationsPendingStatus$ = this.store.select(fromOrganizations.getAdminOrganizationsPagePending);
  canCreateOrganization: boolean;
  canUpdateOrganization: boolean;
  isTopLevelAdmin$ = this.store.select(fromAuth.selectUserIsTopLevelAdmin);
  adminConceptsFromEntitySession$ = this.store.select(fromAuth.selectSelectedEntityForSession).pipe(map((organization) => organization.adminConcepts));
  organizationsButtonList!: (IotToolbarDefaultButton | IotToolbarMenuButton)[];
  toolbarSize: string = ToolbarSize.SMALL;
  private selectedOrganization;
  private selectedOrganizationForSession: Entity;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly authz: AuthorizationService,
    private readonly gridsService: GridsService,
    private readonly translateService: TranslateService
  ) {
    this.canCreateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.CREATE);
    this.canUpdateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.UPDATE);
    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.adminConceptsFromEntitySession$ = this.store.select(fromAuth.selectSelectedEntityForSession).pipe(
      tap((orga) => (this.selectedOrganizationForSession = orga)),
      map((organization) => (organization ? organization.adminConcepts : []))
    );

    this.store.select(fromAuth.selectAccount).subscribe((account) => {
      if (account) {
        this.store.dispatch(AdminOrganizationsPageActions.listOrganizations());
      }
    });

    this.selectedOrganization$.subscribe((org) => (this.selectedOrganization = org));

    this.isTopLevelAdmin$.subscribe((isTopLevelAdmin: boolean) => {
      this.organizationsButtonList.map((button) => {
        if (button instanceof IotToolbarMenuButton) {
          button.displayButton = isTopLevelAdmin;
        }
      });
    });
  }

  initToolbarButtonList(): void {
    this.organizationsButtonList = [
      new IotToolbarDefaultButton(
        {
          ...ADD_BUTTON_CONFIG,
          displayButton: this.canCreateOrganization,
          tooltip: 'ADMIN.ORGANIZATIONS.DETAIL.ADD_ORGANIZATION'
        },
        1
      ),
      new IotToolbarMenuButton(MANAGE_MASTER_VIEWS_BUTTON_CONFIG, 0)
    ];
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.analytics.log('toolbar_actions', 'open_add_orga');
        this.onAddOrganization(null);
        break;
      case IotToolbarDispatchActionType.OPEN_MASTER_VIEW_ADMIN_SETTINGS:
        this.openAdminSettings();
        break;
      case IotToolbarDispatchActionType.OPEN_GRID_ADMIN_SETTINGS:
        this.openGridSettings();
        break;
      default:
        break;
    }
  }

  onSelectOrganization(selectedOrganization: Organization) {
    this.selectedOrganization = selectedOrganization;
    this.store.dispatch(AdminOrganizationsPageActions.selectOrganization({ selectedOrganizationId: selectedOrganization.id }));
  }

  onSelectRole(selectedRole: Role) {
    this.store.dispatch(AdminOrganizationsRolesPageActions.selectRoleInOrganization({ selectedRoleId: selectedRole.id }));
  }

  onRenameRole(selectedRole: Role) {
    this.analytics.log('role_tab_actions', 'open_rename_role');
    const dialogRef = this.dialog.open(DialogRoleAddFormComponent, {
      width: '550px',
      data: { organization: this.selectedOrganization, roleToUpdate: selectedRole }
    });

    dialogRef.afterClosed().subscribe((updated: Role) => {
      this.analytics.log('role_tab_actions', 'rename_role');
      this.store.dispatch(AdminOrganizationsRolesPageActions.updateRoleForOrganization({ roleToUpdate: updated }));
    });
  }

  onAuthorizationsChanged(roleToUpdate: Role) {
    this.analytics.log('role_tab_actions', 'update_role');
    this.store.dispatch(AdminOrganizationsRolesPageActions.updateRoleForOrganization({ roleToUpdate }));
  }

  onAddBusinessProfile(organization: Organization) {
    this.analytics.log('bp_tab_actions', 'open_add_bp');
    const dialogRef = this.dialog.open(DialogBusinessProfileAddFormComponent, {
      width: '550px',
      data: { organization }
    });

    dialogRef.afterClosed().subscribe((newBP: BusinessProfile) => {
      if (newBP) {
        this.analytics.log('bp_tab_actions', 'add_bp');
        this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.addBusinessProfileToOrganization({ businessProfileToAdd: newBP }));
      }
    });
  }

  onAddRole(organization: Organization) {
    this.analytics.log('role_tab_actions', 'open_add_role');
    const dialogRef = this.dialog.open(DialogRoleAddFormComponent, {
      width: '550px',
      data: { organization }
    });

    dialogRef.afterClosed().subscribe((newRole: Role) => {
      if (newRole) {
        this.analytics.log('role_tab_actions', 'add_role');
        this.store.dispatch(AdminOrganizationsRolesPageActions.addRoleToOrganization({ roleToAdd: newRole }));
      }
    });
  }

  onAddAdministratorToOrganization() {
    this.analytics.log('admin_tab_actions', 'open_manage_admin');
    this.dialog.open(DialogAdministratorAddFormComponent, {
      width: '900px',
      data: { organization: this.selectedOrganization }
    });

    /* dialogRef.afterClosed().subscribe((newAdministrator: User) => {
          if (newAdministrator) {
            this.store.dispatch(OrganizationsPageActions.addAdministratorToOrganization({ administratorToAdd: newAdministrator }));
          }
        });*/
  }

  onUpdateOrganization(updatedOrganization: Organization) {
    this.analytics.log('toolbar_actions', 'update_orga');
    this.store.dispatch(AdminOrganizationsPageActions.updateOrganization({ organizationToUpdate: updatedOrganization }));
  }

  onDeleteBusinessProfile(businessProfile: BusinessProfile) {
    this.analytics.log('bp_tab_actions', 'delete_bp');
    this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.removeBusinessProfileFromOrganization({ businessProfileToRemove: businessProfile }));
  }

  onSelectBusinessProfile(businessProfile: BusinessProfile) {
    this.analytics.log('bp_tab_actions', 'navigate_to_edit_bp');
    this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.navigateToSelectedBusinessProfile({ businessProfile }));
  }

  onDeleteRole(role: Role) {
    this.analytics.log('role_tab_actions', 'delete_role');
    this.store.dispatch(AdminOrganizationsRolesPageActions.removeRoleFromOrganization({ roleToRemove: role }));
  }

  onAddOrganization(parentOrganization: Organization | null) {
    this.dialog
      .open(DialogOrganizationAddFormComponent, {
        data: {
          type: 'organization',
          organization: parentOrganization
        },
        width: '500px'
      })
      .afterClosed()
      .subscribe((newOrganization) => {
        if (newOrganization) {
          this.analytics.log('toolbar_actions', 'add_orga');
          this.store.dispatch(AdminOrganizationsPageActions.addOrganization({ newOrganization }));
        }
      });
  }

  onDeleteOrganization(organizationToDelete: Organization) {
    this.analytics.log('toolbar_actions', 'delete_orga');
    this.store.dispatch(AdminOrganizationsPageActions.deleteOrganization({ organizationToDelete }));
  }

  onAddTags(tagCategory: TagCategory) {
    if (tagCategory.id) {
      this.store.dispatch(AdminOrganizationsTagsPageActions.addTagToOrganization({ tagToAdd: tagCategory }));
    } else {
      this.store.dispatch(AdminOrganizationsTagsPageActions.updateTagInOrganization({ tagToUpdate: tagCategory }));
    }
  }

  onManageTagsForOrganization(event: { concept: string; organization: Organization; conceptList: string[] }) {
    this.analytics.log('tag_tab_actions', 'open_manage_tags');
    const dialogRef = this.dialog.open(ManageTagsDialogComponent, {
      width: '1230px',
      disableClose: true,
      data: {
        concepts: event.conceptList,
        openOnConcept: event.concept,
        selectedTags: [],
        objectName: event.organization.name,
        currentEntityId: event.organization.id,
        multiSelection: true,
        editable: true,
        withChildren: false,
        joinable: false,
        withParents: true
      }
    });

    /* dialogRef.componentInstance.saveTagCategory.subscribe(tagCategory => {
          if (!tagCategory.id) {
            this.store.dispatch(AdminOrganizationsTagsPageActions.addTagToOrganization({ tagToAdd: tagCategory }));
          } else {
            this.store.dispatch(AdminOrganizationsTagsPageActions.updateTagInOrganization({ tagToUpdate: tagCategory }));
          }
          dialogRef.componentInstance.loadAllTags();
        });

        dialogRef.componentInstance.removeTagCategory.subscribe((tagCategory: TagCategory) => {
          if (tagCategory) {
            this.store.dispatch(AdminOrganizationsTagsPageActions.removeTagFromOrganization({ tagToRemove: tagCategory }));
            dialogRef.componentInstance.loadAllTags();
          }
        });*/

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(
        AdminOrganizationsTagsPageActions.listTagsByOrganization({
          organizationId: event.organization.id,
          withParents: false
        })
      );
    });
  }

  onManageConceptsForOrganization(data: { organization: Organization; adminConceptsFromEntitySession: any[] }): void {
    this.analytics.log('admin_tab_actions', 'open_manage_concepts');
    this.dialog
      .open(DialogManageConceptsFormComponent, {
        width: '500px',
        disableClose: true,
        data: { organization: data.organization, adminConceptsFromEntitySession: data.adminConceptsFromEntitySession }
      })
      .afterClosed()
      .subscribe((organizationToUpdate) => {
        if (organizationToUpdate) {
          this.analytics.log('admin_tab_actions', 'manage_concepts');
          this.store.dispatch(AdminOrganizationsPageActions.updateOrganization({ organizationToUpdate: Object.assign({}, organizationToUpdate) }));
        }
      });
  }

  openAdminSettings() {
    this.analytics.log('toolbar_actions', 'open_manage_mv_settings', 'cc1 and table-engine');
    this.dialog
      .open(AdminSettingsPopupComponent, {
        width: '850px',
        data: {
          settingName: 'assets'
        }
      })
      .afterClosed()
      .subscribe();
  }

  openGridSettings() {
    this.analytics.log('toolbar_actions', 'open_manage_grid_settings');
    this.dialog
      .open(GridManagerAdminPopupComponent, {
        width: '1350px',
        maxWidth: '1350px',
        disableClose: true,
        data: { grid: null, selectedOrganization: this.selectedOrganizationForSession }
      })
      .afterClosed()
      .pipe(
        switchMap((gridToUpdate) => {
          if (gridToUpdate) {
            this.analytics.log('toolbar_actions', 'update_grid');
            return this.gridsService.updateGrid(gridToUpdate);
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  onLockUnlockOrganization({ organization, isLocked }): void {
    this.analytics.log('toolbar_actions', 'open_lock_unlock_orga', isLocked ? 'lock' : 'unlock');
    const confirmMessage = isLocked
      ? 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION_CONFIRM_MESSAGE'
      : 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION_CONFIRM_MESSAGE';
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'confirm', value: this.translateService.instant(confirmMessage, { name: organization.name }) }
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.analytics.log('toolbar_actions', isLocked ? 'lock_orga' : 'unlock_orga');
          this.onUpdateOrganization({ ...organization, isLocked });
        }
      });
  }
}
