@if ((isDataLoaded$ | async) && !displayErrorMessage) {
  <span
    [matTooltip]="dataToDisplay$ | async"
    [matTooltipPosition]="cellOptions?.tooltipPosition ? cellOptions?.tooltipPosition : 'above'"
    matTooltipClass="regular-tooltip"
    [ngClass]="cssClasses$ | async"
    class="dynamic-data-cell"
    (click)="onClick($event)"
    >{{ dataToDisplay$ | async | infoDisplay }}</span
  >
}
@if ((isDataLoaded$ | async) && displayErrorMessage) {
  <span class="dynamic-data-cell--error-message">{{ errorMessage }}</span>
}
@if (!(isDataLoaded$ | async)) {
  <mat-progress-spinner color="accent" mode="indeterminate" [diameter]="20"></mat-progress-spinner>
}
