import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, Environment, TagCategory } from '@iot-platform/models/common';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagsByEntity } from './manage-tags-form.model';

// TODO: Too much calls, service should be refactored to make one request to get the desired data

@Injectable({
  providedIn: 'root'
})
export class ManageTagsFormService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);

  static hasMandatoryCategories(categories: TagCategory[]): boolean {
    return !!categories.find((cat: TagCategory) => cat.mandatory);
  }

  getTags(): Observable<TagCategory[]> {
    return this.httpClient
      .get<CommonApiListResponse<TagCategory>>(`${this.environment.api.url}${this.environment.api.endpoints?.tags}`)
      .pipe(map((response: CommonApiListResponse<TagCategory>) => response.content));
  }

  getTagCategoriesByConcept(
    concepts: string[],
    entityId: string,
    withChildren: boolean,
    withParents: boolean,
    joinable: boolean
  ): Observable<{ [concept: string]: TagCategory[] }> {
    let params: HttpParams = new HttpParams();
    params = params.set('entityId', entityId);
    params = params.set('withParents', withParents.toString());
    params = params.set('withChildren', withChildren.toString());
    params = params.set('joinable', joinable.toString());

    const result: { [concept: string]: TagCategory[] } = {};

    if (concepts) {
      concepts.forEach((concept) => {
        params = params.append('concept', concept.toUpperCase());
      });

      concepts.forEach((concept) => {
        result[concept.toUpperCase()] = [];
      });
    }

    return this.httpClient.get<CommonApiListResponse<TagCategory>>(`${this.environment.api.url}${this.environment.api.endpoints?.tags}`, { params }).pipe(
      map((response: CommonApiListResponse<TagCategory>) => {
        if (response.content) {
          return response.content.reduce((acc: { [concept: string]: TagCategory[] }, value: TagCategory) => {
            acc[value.concept as string].push(value);
            return acc;
          }, result);
        } else {
          return result;
        }
      })
    );
  }

  getTagCategoriesByConceptSortedByEntity(
    concepts: string[],
    currentEntityId: string,
    withChildren: boolean = false,
    withParents: boolean,
    joinable: boolean
  ): Observable<{ [concept: string]: TagsByEntity[] }> {
    const result: any[] = [];
    const combined$ = combineLatest([
      this.getEntityTree(currentEntityId, withChildren),
      this.getTagCategoriesByConcept(concepts, currentEntityId, withChildren, withParents, joinable)
    ]);

    const finalResult: { [concept: string]: TagCategory[] } = {};
    concepts.forEach((concept) => {
      finalResult[concept.toUpperCase()] = [];
    });

    return combined$.pipe(
      map(([entityTree, tagCategoriesGroupedByConcept]) => {
        for (const [, val] of Object.entries(entityTree)) {
          for (const [concept, tagCategories] of Object.entries(tagCategoriesGroupedByConcept)) {
            const categories: TagCategory[] = [];
            if (Array.isArray(tagCategories)) {
              tagCategories.forEach((cat) => {
                if (cat.entityId === val.id) {
                  categories.push(cat);
                }
              });
            }

            result.push({
              entityName: val.name,
              entityId: val.id,
              index: val.level,
              concept,
              tagCategories: categories
            });
          }
        }

        return result.reduce((acc, value) => {
          acc[value.concept].push({
            entityName: value.entityName,
            entityId: value.entityId,
            index: value.index,
            tagCategories: this.fullySortTagCategories(value.tagCategories as TagCategory[]),
            isMandatoryTagMissing: ManageTagsFormService.hasMandatoryCategories(value.tagCategories),
            totalSelected: 0
          });
          return acc;
        }, finalResult);
      })
    );
  }

  getEntityTree(
    currentEntityId: string,
    withChildren: boolean
  ): Observable<
    {
      id: string;
      name: string;
      parentId: string;
      level: number;
      label: string;
    }[]
  > {
    return this.httpClient
      .get<
        { id: string; name: string; parentId: string; level: number; label: string }[]
      >(`${this.environment.api.url}${this.environment.api.endpoints?.entities}/${currentEntityId}/tree?withChildren=${withChildren}`)
      .pipe(map((entities) => entities.sort(SortUtil.sortByProperty('level'))));
  }

  fullySortTagCategories(categories: TagCategory[]): TagCategory[] {
    const fullySortedTags: TagCategory[] = [...categories];
    fullySortedTags.sort(SortUtil.sortByName);
    fullySortedTags.forEach((category: TagCategory) => {
      category.labels?.sort(SortUtil.sortByName);
    });
    return fullySortedTags;
  }
}
