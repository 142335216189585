import { PlatformRequest } from '@iot-platform/models/common';
import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

//
export const loadDeviceEventsByTopic = createAction('[DeviceEventsByTopic] Load Device Events By Topic', props<{ request: PlatformRequest }>());
export const loadMvDeviceEventsByTopicSettings = createAction('[DeviceEventsByTopic] Load MV DeviceEventsByTopic Settings', props<{ settingName: string }>());
export const loadTotalActiveDeviceEventsByTopic = createAction(
  '[DeviceEventsByTopic] Load Total Active Device Events By Topic',
  props<{ request: PlatformRequest }>()
);
export const saveTableStateByTopic = createAction(
  '[DeviceEventsByTopic] Save Table State',
  props<{ tableState: { checked: DeviceEvent[]; selected: DeviceEvent } }>()
);
export const updateStatusByDeviceEventIdByTopic = createAction(
  '[DeviceEventsByTopic] Update Status By Device Event Id',
  props<{ status: { deviceEventId: string; value: string } }>()
);
export const bulkUpdateStatusByDeviceEventIdByTopic = createAction(
  '[DeviceEventsByTopic] Bulk Update Status By Device Event Id',
  props<{ deviceEventIds: string[]; status: string }>()
);
