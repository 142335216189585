<form [formGroup]="form" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-form-field class="input-field" color="accent">
    <mat-label>{{ data()?.criteriaLabel | translate }}</mat-label>
    <input formControlName="text" matInput />
    <button (click)="onInputValidation()" [disabled]="disabled() || !text.value" aria-label="Add" color="accent" mat-icon-button
            matTextSuffix type="submit">
      <mat-icon style="font-size: 20px">add_circle</mat-icon>
    </button>
  </mat-form-field>
</form>
