import { createAction } from '@ngrx/store';
import { TrackingData } from '../models/tracking-data.model';
import { TrackingEventId } from '../models/tracking-tag.model';

const trackEventById = createAction('[Tracking] Track event by id', (eventId: TrackingEventId, additionalTrackingData: TrackingData = {}) => ({
  eventId,
  additionalTrackingData
}));

export const TrackingActions = {
  trackEventById
};
