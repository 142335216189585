export const assetEventsByTopicMetadataSettings = {
  metadata: {
    url: '/asset-events',
    params: {
      limit: '100',
      page: '0',
      dynamicParams: {
        siteId: 'id'
      },
      view: 'asset-events'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'asset-events-by-topic',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 100,
      refreshDelay: 120,
      multipleSelection: true,
      selectionColumn: {
        id: 'selection',
        name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SELECTION',
        width: '2%',
        type: 'start center'
      },
      buttonColumn: {
        id: 'button',
        name: '',
        width: '2%',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [[{ key: 'canUpdateEvent', value: true }]],
        bulkActions: [
          {
            key: 'snooze',
            label: 'EVENTS.TABLE.BUTTONS.SNOOZE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
          },
          {
            key: 'acknowledge',
            label: 'EVENTS.TABLE.BUTTONS.ACKNOWLEDGE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
          },
          {
            key: 'close',
            label: 'EVENTS.TABLE.BUTTONS.CLOSE',
            enableConditions: [],
            visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
          }
        ],
        singleActions: [
          {
            key: 'snooze',
            label: 'EVENTS.TABLE.BUTTONS.SNOOZE',
            enableConditions: [[{ key: 'status', value: 'active' }]],
            disableConditions: [[{ key: 'snoozeQuota', value: 0 }]],
            visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
          },
          {
            key: 'acknowledge',
            label: 'EVENTS.TABLE.BUTTONS.ACKNOWLEDGE',
            enableConditions: [[{ key: 'status', value: 'active' }]],
            visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
          },
          {
            key: 'close',
            label: 'EVENTS.TABLE.BUTTONS.CLOSE',
            enableConditions: [[{ key: 'status', value: 'acknowledged' }]],
            disableConditions: [
              [
                { key: 'status', value: 'closed' },
                { key: 'status', value: 'snoozed' }
              ]
            ],
            visibleConditions: [[{ key: 'canUpdateEvent', value: true }]]
          }
        ]
      },
      columns: []
    }
  },
  masterViewComments: {}
};
