<section fxLayout="row" fxLayoutGap="10px" style="font-size: 10px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px" style="color: #b0b0b0">
    <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.CREATED_AT' | translate }}</span>
    <span class="capitalize-first-letter fw-600">{{ createdAt() | dateFormat }}</span>
    @if (createdBy()) {
      <span>{{ 'IOT_DICTIONARY.BY' | translate }}</span>
      <span class="capitalize-first-letter fw-600">{{ createdBy() | userName }}</span>
    }
  </div>
  @if (updatedAt()) {
    <div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start center" style="color: #b0b0b0">
      <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.UPDATED_AT' | translate }}</span>
      <span class="capitalize-first-letter fw-600">{{ updatedAt() | dateFormat }}</span>
      @if (updatedBy()) {
        <span>{{ 'IOT_DICTIONARY.BY' | translate }}</span>
        <span class="capitalize-first-letter fw-600">{{ updatedBy() | userName }}</span>
      }
    </div>
  }
</section>
