import { Device, Log } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceCommentsActions = createActionGroup({
  source: 'Device Comments',
  events: {
    'Load comments': props<{ device: Device }>(),
    'Load comments Success': props<{ comments: Log[] }>(),
    'Load comments Failure': props<{ error: unknown }>(),

    'Add comment': props<{ deviceId: string; comment: string }>(),
    'Add comment Success': props<{ comment: Log }>(),
    'Add comment Failure': props<{ error: unknown }>(),

    'Edit comment': props<{ deviceId: string; comment: Log }>(),
    'Edit comment Success': props<{ comment: Log }>(),
    'Edit comment Failure': props<{ error: unknown }>(),

    'Delete comment': props<{ deviceId: string; commentId: string }>(),
    'Delete comment Success': props<{ commentId: string }>(),
    'Delete comment Failure': props<{ error: unknown }>()
  }
});
