import { inject } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ContactsService } from '../../services/contacts.service';
import { ContactsActions } from '../actions';

const loadContacts$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), contactsService = inject(ContactsService)) =>
    actions$.pipe(
      ofType(ContactsActions.loadContacts),
      concatMap((action) =>
        contactsService.loadContacts(action.siteId).pipe(
          map((contacts: Contact[]) => ContactsActions.loadContactsSuccess({ contacts })),
          catchError((error) => of(ContactsActions.loadContactsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addContact$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), contactsService = inject(ContactsService)) =>
    actions$.pipe(
      ofType(ContactsActions.addContact),
      concatMap((action) =>
        contactsService.addContact(action.contact).pipe(
          map((addedContact: Contact) => ContactsActions.addContactSuccess({ addedContact })),
          catchError((error) => of(ContactsActions.addContactFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateContact$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), contactsService = inject(ContactsService)) =>
    actions$.pipe(
      ofType(ContactsActions.updateContact),
      concatMap((action) =>
        contactsService.updateContact(action.contact).pipe(
          map((updatedContact: Contact) => ContactsActions.updateContactSuccess({ updatedContact })),
          catchError((error) => of(ContactsActions.updateContactFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteContact$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), contactsService = inject(ContactsService)) =>
    actions$.pipe(
      ofType(ContactsActions.deleteContact),
      concatMap((action) =>
        contactsService.deleteContact(action.contact).pipe(
          concatMap((deletedContact: Contact) => [ContactsActions.deleteContactSuccess({ deletedContact })]),
          catchError((error) => of(ContactsActions.deleteContactFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(ContactsActions.addContact, ContactsActions.updateContact, ContactsActions.deleteContact),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        ContactsActions.addContactSuccess,
        ContactsActions.updateContactSuccess,
        ContactsActions.deleteContactSuccess,
        ContactsActions.addContactFailure,
        ContactsActions.updateContactFailure,
        ContactsActions.deleteContactFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        ContactsActions.loadContactsFailure,
        ContactsActions.addContactFailure,
        ContactsActions.updateContactFailure,
        ContactsActions.deleteContactFailure
      ),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(ContactsActions.addContactSuccess, ContactsActions.updateContactSuccess, ContactsActions.deleteContactSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const ContactsEffects = {
  loadContacts$,
  addContact$,
  updateContact$,
  deleteContact$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
