import { HttpClient } from '@angular/common/http';
import { inject, Injectable, ProviderToken } from '@angular/core';
import { Environment } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceIccidService {
  protected readonly environment: Environment = inject('environment' as unknown as ProviderToken<Environment>);
  protected readonly http: HttpClient = inject(HttpClient);

  getOperatorInfo(deviceId: string): Observable<object> {
    return this.http.get(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}${this.environment.api.endpoints.operatorInfo}`);
  }
}
