import { Injectable } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { ContactNotification } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';

import { NotificationsUiActions } from '../actions';
import * as fromNotifications from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class NotificationsFacade {
  public notificationsLoaded$ = this.store.select(fromNotifications.selectNotificationsLoaded);
  public notificationsLoading$ = this.store.select(fromNotifications.selectNotificationsLoading);
  public notifications$ = this.store.select(fromNotifications.selectNotificationsWithExpandedContacts);
  public totalNotifications$ = this.store.select(fromNotifications.selectTotalNotifications);
  public selectedNotification$ = this.store.select(fromNotifications.selectSelectedNotification);

  constructor(private readonly store: Store) {}

  public loadNotifications(siteId: string): void {
    this.store.dispatch(NotificationsUiActions.loadNotifications({ siteId }));
  }

  public selectNotification(notificationToSelect: ContactNotification): void {
    this.store.dispatch(NotificationsUiActions.selectNotification({ notificationToSelect }));
  }

  public addNotification(notificationToAdd: ContactNotification, siteId: string, contactIdsToSub: string[]): void {
    this.store.dispatch(NotificationsUiActions.addNotification({ notificationToAdd, siteId, contactIdsToSub }));
  }

  public updateNotification(notificationToUpdate: ContactNotification, siteId: string, contactIdsToSub: string[], contactIdsToUnsub: string[]): void {
    this.store.dispatch(NotificationsUiActions.updateNotification({ notificationToUpdate, siteId, contactIdsToSub, contactIdsToUnsub }));
  }

  public deleteNotification(notificationToDelete: ContactNotification, siteId: string): void {
    this.store.dispatch(NotificationsUiActions.deleteNotification({ notificationToDelete, siteId }));
  }

  public configureContactNotifications(contact: Contact, notificationIdsToBeSubTo: string[], notificationIdsToBeUnsubFrom: string[]): void {
    this.store.dispatch(NotificationsUiActions.configureNotifications({ contact, notificationIdsToBeSubTo, notificationIdsToBeUnsubFrom }));
  }
}
