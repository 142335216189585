import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BusinessProfile, Entity, Organization } from '@iot-platform/models/common';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { EntitiesService } from '../../../../../../../shared/src/lib/entities.service';

@Component({
  selector: 'iot4bos-ui-backoffice-dialog-organization-add-form',
  templateUrl: './dialog-organization-add-form.component.html',
  styleUrls: ['./dialog-organization-add-form.component.scss']
})
export class DialogOrganizationAddFormComponent implements OnInit, OnDestroy {
  businessProfileFrom: UntypedFormGroup;
  sortedEntities: Entity[] = [];
  initialEntity?: Entity;
  entitiesLoading = true;
  initialName = '';

  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  displayDuplicateMessage$: Subject<boolean> = new Subject<boolean>();

  destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DialogOrganizationAddFormComponent>,
    private entitiesService: EntitiesService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { organization: Organization; businessProfile: BusinessProfile; type?: string }
  ) {
    const keyboard$ = fromEvent(document, 'keyup');

    keyboard$
      .pipe(
        takeUntil(this.destroy$),
        tap((_) => this.isDisabled$.next(true)),
        debounceTime(300),
        switchMap(() => {
          if (this.nameControl.value && this.organizationControl.value) {
            return this.checkOrganizationNameUnicity();
          } else {
            return of(null);
          }
        })
      )
      .subscribe();
  }

  get title$(): Observable<string> {
    if (this.data.type === 'organization') {
      return this.translateService.get('ADMIN.DIALOG.ADD_ORGANIZATION.TITLE_CREATE');
    }
  }

  get nameControl(): AbstractControl {
    return this.businessProfileFrom.get('nameControl');
  }

  get organizationControl(): AbstractControl {
    return this.businessProfileFrom.get('organizationControl');
  }

  get withSiteStockControl(): AbstractControl {
    return this.businessProfileFrom.get('withSiteStockControl');
  }

  get withSiteMobileFleetControl(): AbstractControl {
    return this.businessProfileFrom.get('withMobileFleetControl') as AbstractControl;
  }

  ngOnInit() {
    this.businessProfileFrom = new UntypedFormGroup({
      organizationControl: new UntypedFormControl(this.data.organization ? { value: '', disabled: true } : { value: '', disabled: false }, [
        Validators.required
      ]),
      nameControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.name : '', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('\\S.*')
      ]),
      withSiteStockControl: new UntypedFormControl(true),
      withMobileFleetControl: new UntypedFormControl(true)
    });

    this.entitiesService
      .getHierarchicallySortedEntities()
      .pipe(takeUntil(this.destroy$))
      .subscribe((entities) => {
        this.sortedEntities = entities;
        this.entitiesLoading = false;
        this.initialEntity = this.sortedEntities.find((e) => e.id === this.data.organization?.id);
        this.organizationControl.setValue(this.initialEntity);
      });
  }

  checkOrganizationNameUnicity() {
    return this.entitiesService.getEntityNameDuplicatesTotal(this.nameControl.value.trim()).pipe(
      map((total) => total > 0),
      tap((b) => {
        this.isDisabled$.next(b);
        this.displayDuplicateMessage$.next(b);
      })
    );
  }

  checkNameUnicity() {
    if (this.nameControl.value && this.organizationControl.value) {
      this.checkOrganizationNameUnicity().pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  onEntitySelection(entity: Entity) {
    if (entity) {
      this.organizationControl.setValue(entity);
      this.checkNameUnicity();
    }
  }

  resetEntity() {
    this.organizationControl.reset();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newOrga: Organization = {
      name: this.nameControl.value.trim(),
      parentId: this.organizationControl.value.id,
      withSiteStock: this.withSiteStockControl.value,
      withSiteMobileFleet: this.withSiteMobileFleetControl.value
    };
    this.dialogRef.close(newOrga);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
