import { Subscriber } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const listUserslSubscribedToTopicByTopicIdSuccess = createAction(
  '[Topics] List Users Subscribed to Topic By Topic Id Success',
  props<{ subscribers: Subscriber[] }>()
);
export const listUsersubscribedToTopicByTopicIdFailure = createAction('[Topics] List Users Subscribed to Topic By Topic Id Failure', props<{ error: any }>());
//
export const subscribeToTopicByUserIdSuccess = createAction('[Topics] Subscribe to Topic By User Id Success', props<{ subscribedUser: Subscriber }>());
export const subscribeToTopicByUserIdFailure = createAction('[Topics] Subscribe to Topic By User Id Failure', props<{ error: any }>());
//
export const unsubscribeToTopicByUserIdSuccess = createAction('[Topics] Unsubscribe to Topic By User Id Success', props<{ unsubscribedUser: Subscriber }>());
export const unsubscribeToTopicByUserIdFailure = createAction('[Topics] Unsubscribe to Topic By User Id Failure', props<{ error: any }>());
