import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { EscalationProtocolsDbActions, EscalationProtocolsUiActions } from '../actions';

export const escalationProtocolLevelsUiFeatureKey = 'escalationProtocolLevelsUi';

export interface State {
  error?: any;
  escalationProtocolLevelsLoaded: boolean;
  escalationProtocolLevelsLoading: boolean;
}

export const initialState: State = {
  error: null,
  escalationProtocolLevelsLoaded: false,
  escalationProtocolLevelsLoading: false
};

const escalationProtocolLevelsUiReducer = createReducer(
  initialState,

  on(
    EscalationProtocolsUiActions.listEscalationProtocolLevels,
    EscalationProtocolsUiActions.addEscalationProtocolLevel,
    EscalationProtocolsUiActions.updateEscalationProtocolLevel,
    EscalationProtocolsUiActions.updateEscalationProtocolLevelsOrders,
    EscalationProtocolsUiActions.deleteEscalationProtocolLevelThenUpdateLevels,
    (state: State) => ({
      ...state,
      escalationProtocolLevelsLoaded: false,
      escalationProtocolLevelsLoading: true
    })
  ),
  on(
    EscalationProtocolsDbActions.listEscalationProtocolLevelsSuccess,
    EscalationProtocolsDbActions.addEscalationProtocolLevelSuccess,
    EscalationProtocolsDbActions.updateEscalationProtocolLevelSuccess,
    EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersSuccess,
    EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsSuccess,
    (state: State) => ({
      ...state,
      escalationProtocolLevelsLoaded: true,
      escalationProtocolLevelsLoading: false
    })
  ),
  on(
    EscalationProtocolsDbActions.listEscalationProtocolLevelsFailure,
    EscalationProtocolsDbActions.addEscalationProtocolLevelFailure,
    EscalationProtocolsDbActions.updateEscalationProtocolLevelFailure,
    EscalationProtocolsDbActions.updateEscalationProtocolLevelsOrdersFailure,
    EscalationProtocolsDbActions.deleteEscalationProtocolLevelThenUpdateLevelsFailure,
    (state: State) => ({
      ...state,
      escalationProtocolLevelsLoaded: false,
      escalationProtocolLevelsLoading: false
    })
  ),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return escalationProtocolLevelsUiReducer(state, action);
}

export const getEscalationProtocolLevelsLoaded = (state: State) => state?.escalationProtocolLevelsLoaded;
export const getEscalationProtocolLevelsLoading = (state: State) => state?.escalationProtocolLevelsLoading;
