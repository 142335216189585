import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { TotalColumn } from './i4b-total.column';

export class TotalEventsColumn extends TotalColumn {
  columnId = 'd815d72c-5c1e-11ec-80a5-0023a40210d0-total-events';
  className = TotalEventsColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        ...customHeader,
        displayName: customOptions?.overrideHeaderName ?? customHeader?.displayName ?? 'TOTAL EVENTS',
        icon: customHeader?.icon ?? 'asset-event'
      },
      { ...customConfig, id: customConfig?.id ?? 'totalEvents' },
      { customId: customConfig?.id ?? 'totalEvents', ...customOptions }
    );
  }
}
