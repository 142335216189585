<form [formGroup]="parametersForm" class="asset-variable-formula-result two-lines" fxLayout="row" fxLayoutGap="25px">
  <mat-form-field color="accent" fxFlex>
    <mat-label>{{ 'ASSETS.VARIABLE_FORM.MAX_READ' | translate }}</mat-label>
    <input class="asset-variable-formula-input" formControlName="formulaMaxRead" matInput min="0.01" type="number" />
  </mat-form-field>

  <mat-form-field color="accent" fxFlex>
    <mat-label>{{ 'ASSETS.VARIABLE_FORM.MAX_DISPLAY' | translate }}</mat-label>
    <input class="asset-variable-formula-input" formControlName="formulaMaxDisplay" matInput min="0.01" type="number" />
  </mat-form-field>
</form>
