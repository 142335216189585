<div (click)="onWidgetClick()" class="widget-item" fxLayout="column">
  <div [widget]="widget()" fxFlex iotPlatformUiWidgetLoader></div>
  @if (menu?.enabled) {
    <div class="call-to-action" fxFlex>
      <button
        #menuTrigger="matMenuTrigger"
        (click)="$event?.stopPropagation()"
        [class.active]="menuTrigger.menuOpen"
        [matMenuTriggerFor]="widgetMenu"
        class="menu"
        mat-icon-button
      >
        <mat-icon fxAlign="end">more_vert</mat-icon>
      </button>

      <mat-menu #widgetMenu>
        @for (item of menu?.actions; track $index) {
          <button (click)="onDispatchEvent(item?.name)" [disabled]="!item?.enabled" mat-menu-item>
            {{ item?.label | translate }}
          </button>
        }
      </mat-menu>
    </div>
  }
</div>
