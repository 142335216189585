import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { Log } from '@iot-platform/models/i4b';
import { AssetsCommentsActions } from '../actions';

export interface State extends EntityState<Log> {
  selectedId: string | null;
  loading: boolean;
  loaded: boolean;
  error: unknown;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (entity: Log) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false,
  error: null
});

export const assetsCommentsFeature = createFeature({
  name: 'assetsComments',
  reducer: createReducer(
    initialState,
    on(
      AssetsCommentsActions.loadComments,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(AssetsCommentsActions.loadCommentsSuccess, (state: State, { comments }): State => adapter.setAll(comments, { ...state, loading: false, loaded: true })),
    on(
      AssetsCommentsActions.loadCommentsFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    ),
    //
    on(AssetsCommentsActions.addComment, (state: State): State => ({ ...state, selectedId: null })),
    on(AssetsCommentsActions.addCommentSuccess, (state: State, { comment }): State => adapter.addOne(comment, { ...state, selectedId: comment.id })),
    on(AssetsCommentsActions.addCommentFailure, (state: State, { error }): State => ({ ...state, selectedId: null, error })),
    //
    on(AssetsCommentsActions.editComment, (state: State): State => ({ ...state, selectedId: null })),
    on(
      AssetsCommentsActions.editCommentSuccess,
      (state: State, { comment }): State => adapter.updateOne({ id: comment.id, changes: comment }, { ...state, selectedId: comment.id })
    ),
    on(AssetsCommentsActions.editCommentFailure, (state: State, { error }): State => ({ ...state, selectedId: null, error })),
    //
    on(AssetsCommentsActions.deleteComment, (state: State): State => ({ ...state, selectedId: null })),
    on(AssetsCommentsActions.deleteCommentSuccess, (state: State, { commentId }): State => adapter.removeOne(commentId, { ...state, selectedId: null })),
    on(AssetsCommentsActions.deleteCommentFailure, (state: State, { error }): State => ({ ...state, selectedId: null, error }))
  )
});
