import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesApiActions, AuthBusinessProfilesPageActions } from '../actions';

export interface AuthBusinessProfilesPageState {
  error: any;
  pending: boolean;
}

export const initialState: AuthBusinessProfilesPageState = {
  error: null,
  pending: false
};

export const authBusinessProfilesPageFeature = createFeature({
  name: 'authBusinessProfilesPage',
  reducer: createReducer(
    initialState,
    on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: AuthBusinessProfilesPageState) => ({
      ...state,
      error: null,
      pending: true
    })),
    on(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess, (state: AuthBusinessProfilesPageState) => ({
      ...state,
      error: null,
      pending: false
    })),
    on(AuthBusinessProfilesApiActions.selectBusinessProfileFailure, (state: AuthBusinessProfilesPageState, { error }) => ({
      ...state,
      error,
      pending: false
    })),

    on(AuthBusinessProfilesPageActions.loadBusinessProfiles, (state: AuthBusinessProfilesPageState) => ({
      ...state,
      error: null,
      pending: true
    })),
    on(AuthBusinessProfilesApiActions.loadBusinessProfilesSuccess, (state: AuthBusinessProfilesPageState) => ({
      ...state,
      error: null,
      pending: false
    })),
    on(AuthBusinessProfilesApiActions.loadBusinessProfilesFailure, (state: AuthBusinessProfilesPageState, { error }) => ({
      ...state,
      error,
      pending: false
    }))
  )
});
