import { Injectable } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AssetEventsBySiteLogsDbActions, AssetEventsBySiteLogsUiActions } from '../actions';
import { AssetEventsService } from '@iot-platform/shared/services';

@Injectable()
export class AssetEventsBySiteLogsEffects {
  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteLogsUiActions.loadLogsByAssetEvent),
      switchMap((action) =>
        this.assetEventsService.loadComments(action.assetEvent).pipe(
          map((logs: Log[]) => AssetEventsBySiteLogsDbActions.loadLogsByAssetEventSuccess({ logs })),
          catchError((error) => of(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventFailure({ error })))
        )
      )
    )
  );

  createLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteLogsUiActions.createLogByAssetEvent),
      concatMap((action) =>
        this.assetEventsService.addComment(action.comment.assetEvent.id, action.comment.value).pipe(
          map((log: Log) => AssetEventsBySiteLogsDbActions.createLogByAssetEventSuccess({ log, assetEvent: action.comment.assetEvent })),
          catchError((error) => of(AssetEventsBySiteLogsDbActions.createLogByAssetEventFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsBySiteLogsDbActions.createLogByAssetEventSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventFailure, AssetEventsBySiteLogsDbActions.createLogByAssetEventFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private assetEventsService: AssetEventsService,
    private notificationService: NotificationService
  ) {}
}
