import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'i4b-table-engine-followed-number-cell',
  templateUrl: './followed-number-cell.component.html',
  styleUrls: ['./followed-number-cell.component.scss']
})
export class FollowedNumberCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;
  tooltip: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this.data !== null) {
      this.tooltip = this.translateService.instant('ASSETS.VARIABLE.FOLLOWED.CARD_TITLE', { followedRank: this.data });
    }
  }
}
