import { Inject, Injectable } from '@angular/core';
import { Environment } from '@iot-platform/models/common';
import { WINDOW_REF } from '../../settings/custom.tokens';
import { HotjarAnalyticsTrackingData, TrackingData } from '../models/tracking-data.model';
import { TrackingProviders } from '../models/tracking-providers.enum';
import { HotjarAnalyticsTrackingTag, TrackingTag } from '../models/tracking-tag.model';
import { AbstractTrackingMapperService } from './abstract-tracking-mapper.service';
import { AbstractTrackingProvider } from './abstract-tracking-provider.service';
import { TRACKING_MAPPERS } from './tracking.tokens';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WindowWithDidomi = Window & { didomiOnReady: ((didomi: any) => void)[] };

/**
 * This service is not used yet
 */
@Injectable({ providedIn: 'root' })
export class HotjarAnalyticsService extends AbstractTrackingProvider {
  constructor(
    @Inject('environment') private readonly environment: Environment,
    @Inject(WINDOW_REF) protected readonly window: WindowWithDidomi,
    @Inject(TRACKING_MAPPERS) protected readonly mappers: AbstractTrackingMapperService<HotjarAnalyticsTrackingTag, HotjarAnalyticsTrackingData>[]
  ) {
    super(mappers, TrackingProviders.HotjarAnalytics);
  }

  track(tag: TrackingTag, trackingData: TrackingData): void {
    const htmData: HotjarAnalyticsTrackingTag | undefined = tag?.getHotjarTag?.(trackingData);
    const htmMappedData = this.mapper.getTrackingDataForProvider(htmData, trackingData) as HotjarAnalyticsTrackingData;
    if (htmData) {
      if (!this.environment.production) {
        console.table({ ...htmData, ...htmMappedData });
      }
    }
  }
}
