/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { ContactsActions, ContactsService } from '@iot-platform/data-access/contacts';
import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ExportSpreadsheetsDbActions, ExportSpreadsheetsUiActions } from '../actions';

@Injectable()
export class ExportSpreadsheetsEffects {
  loadExportSpreadsheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportSpreadsheetsUiActions.loadExportSpreadsheets),
      switchMap((action) =>
        this.contactsService.getExportSpreadsheetsBySite(action.siteId).pipe(
          map((exports: ExportSpreadsheet[]) => ExportSpreadsheetsDbActions.loadExportSpreadsheetsSuccess({ exports })),
          catchError((error) => of(ExportSpreadsheetsDbActions.loadExportSpreadsheetsFailure({ error })))
        )
      )
    )
  );

  addExportSpreadsheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportSpreadsheetsUiActions.addExportSpreadsheet),
      concatMap((action) =>
        this.contactsService.addExportSpreadsheet(action.exportSpreadsheetToAdd, action.siteId).pipe(
          map((addedExportSpreadsheet: ExportSpreadsheet) => ExportSpreadsheetsDbActions.addExportSpreadsheetSuccess({ addedExportSpreadsheet })),
          catchError((error) => of(ExportSpreadsheetsDbActions.addExportSpreadsheetFailure({ error })))
        )
      )
    )
  );

  updateExportSpreadsheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportSpreadsheetsUiActions.updateExportSpreadsheet),
      concatMap((action) =>
        this.contactsService.updateExportSpreadsheet(action.exportSpreadsheetToUpdate, action.siteId).pipe(
          map((updatedExportSpreadsheet: ExportSpreadsheet) => ExportSpreadsheetsDbActions.updateExportSpreadsheetSuccess({ updatedExportSpreadsheet })),
          catchError((error) => of(ExportSpreadsheetsDbActions.updateExportSpreadsheetFailure({ error })))
        )
      )
    )
  );

  deleteExportSpreadsheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportSpreadsheetsUiActions.deleteExportSpreadsheet),
      concatMap((action) =>
        this.contactsService.deleteExportSpreadsheet(action.exportSpreadsheetToDelete, action.siteId).pipe(
          map((deletedExportSpreadsheet: ExportSpreadsheet) => ExportSpreadsheetsDbActions.deleteExportSpreadsheetSuccess({ deletedExportSpreadsheet })),
          catchError((error) => of(ExportSpreadsheetsDbActions.deleteExportSpreadsheetFailure({ error })))
        )
      )
    )
  );

  addThenSelectNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportSpreadsheetsDbActions.addExportSpreadsheetSuccess),
      map((action) => ExportSpreadsheetsUiActions.selectExportSpreadsheet({ exportSpreadsheetToSelect: action.addedExportSpreadsheet }))
    )
  );

  configureContactExportSpreadsheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportSpreadsheetsUiActions.configureContactExportSpreadsheets),
      concatMap((action) =>
        this.contactsService.updateExportSpreadsheets(action.exportSpreadsheetsToUpdate, action.siteId).pipe(
          map((updatedExportSpreadsheets: ExportSpreadsheet[]) =>
            ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsSuccess({ updatedExportSpreadsheets })
          ),
          catchError((error) => of(ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsFailure({ error })))
        )
      )
    )
  );

  deleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsActions.deleteContactSuccess),
      map((action) => ExportSpreadsheetsUiActions.loadExportSpreadsheets({ siteId: action.deletedContact.site?.id as string }))
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ExportSpreadsheetsUiActions.addExportSpreadsheet,
          ExportSpreadsheetsUiActions.updateExportSpreadsheet,
          ExportSpreadsheetsUiActions.deleteExportSpreadsheet,
          ExportSpreadsheetsUiActions.configureContactExportSpreadsheets
        ),
        tap(() => this.notificationService.displayLoader(true))
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ExportSpreadsheetsDbActions.addExportSpreadsheetSuccess,
          ExportSpreadsheetsDbActions.addExportSpreadsheetFailure,
          ExportSpreadsheetsDbActions.updateExportSpreadsheetSuccess,
          ExportSpreadsheetsDbActions.updateExportSpreadsheetFailure,
          ExportSpreadsheetsDbActions.deleteExportSpreadsheetSuccess,
          ExportSpreadsheetsDbActions.deleteExportSpreadsheetFailure,
          ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsSuccess,
          ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsFailure
        ),
        tap(() => this.notificationService.displayLoader(false))
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ExportSpreadsheetsDbActions.addExportSpreadsheetSuccess,
          ExportSpreadsheetsDbActions.updateExportSpreadsheetSuccess,
          ExportSpreadsheetsDbActions.deleteExportSpreadsheetSuccess,
          ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ExportSpreadsheetsDbActions.loadExportSpreadsheetsFailure,
          ExportSpreadsheetsDbActions.addExportSpreadsheetFailure,
          ExportSpreadsheetsDbActions.updateExportSpreadsheetFailure,
          ExportSpreadsheetsDbActions.deleteExportSpreadsheetFailure,
          ExportSpreadsheetsDbActions.configureContactExportSpreadsheetsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly contactsService: ContactsService,
    private readonly notificationService: NotificationService
  ) {}
}
