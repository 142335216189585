import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';
//
export const topicsColumnSettings = [
  {
    order: 10,
    id: 'name',
    name: 'IOT_DICTIONARY.NAME',
    catalogName: 'NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    cellWidth: '240px',
    type: 'start center'
  },
  {
    order: 20,
    id: 'entity.name',
    name: 'IOT_DICTIONARY.ENTITY',
    catalogName: 'ENTITY',
    default: true,
    sortable: true,
    sortProperty: 'entity.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '',
    type: 'start center'
  },
  {
    order: 30,
    id: 'view',
    name: 'IOT_DICTIONARY.VIEW',
    catalogName: 'VIEW',
    default: true,
    sortable: true,
    sortProperty: 'view',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.TRANSLATED_CELL,
    isLink: false,
    cellWidth: '100px',
    type: 'start center'
  },
  {
    order: 35,
    id: 'emailTemplateId',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EMAIL_TEMPLATE',
    catalogName: 'EMAIL TEMPLATE',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.EMAIL_TEMPLATE,
    isLink: false,
    cellWidth: '150px',
    type: 'start center'
  },
  {
    order: 40,
    id: 'createdBy',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_BY',
    catalogName: 'CREATED BY',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.USER_CELL,
    isLink: false,
    clickEvent: '',
    width: '',
    type: 'start center'
  },
  {
    order: 45,
    id: 'totalUsers',
    name: 'TOPICS.DETAIL_POPUP.SUBSCRIBERS',
    catalogName: 'SUBSCRIBERS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    clickEvent: '',
    cellWidth: '100px',
    type: 'end center'
  },
  {
    order: 50,
    id: 'status',
    name: 'IOT_DICTIONARY.STATUS',
    catalogName: 'STATUS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      withStatusPadding: true,
      class: {
        inactive: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' },
        active: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' }
      }
    },
    isLink: false,
    cellWidth: '100px',
    type: 'center center'
  },
  {
    order: 60,
    id: 'id',
    name: '',
    catalogName: 'SUBSCRIPTION',
    default: true,
    sortable: false,
    pinned: 'right',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.TOPIC_SELF_SUBSCRIPTION_CELL,
    isLink: false,
    clickEvent: {
      type: 'switchSubscription',
      options: {}
    },
    maxWidth: '80',
    cellWidth: '80',
    type: 'center center',
    valueGetter: 'totalUsers'
  },
  {
    order: 70,
    id: 'totalEscalationProtocols',
    name: 'TOPICS.SHELL.ESCALATION_PROTOCOLS',
    catalogName: 'RELATED ESCALATION PROTOCOLS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    clickEvent: '',
    cellWidth: '',
    type: 'end center'
  },
  {
    order: 80,
    id: 'totalEventsLast24Hours',
    name: 'TOPICS.SHELL.EVENTS_24H',
    catalogName: 'EVENTS 24H',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    clickEvent: '',
    cellWidth: '100px',
    type: 'end center'
  },
  {
    order: 90,
    id: 'subscribedAt',
    name: 'TOPICS.SHELL.SUBSCRIPTION_DATE',
    catalogName: 'SUBSCRIPTION DATE',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    clickEvent: '',
    cellWidth: '',
    type: 'end center'
  }
];
