<div (click)="onDispatchEvent($event)" fxFlexFill fxLayout="row" fxLayoutAlign="start center">
  <div [class.clickable]="!minimalDisplay" class="basic-click-cell">
    @if (!isMultipleStateVariable && cellOptions.dataType === 'lastRecord') {
      <span class="fw-600"> {{ data[0]?.value | numberFormat | infoDisplay }} {{ rawData['unit'] }} </span>
    }
    @if (!isMultipleStateVariable && cellOptions.dataType === 'lastValue') {
      <span class="fw-600"> {{ data?.value | numberFormat | infoDisplay }} {{ rawData['unit'] }} </span>
    }
    @if (!isMultipleStateVariable && cellOptions.dataType !== 'lastRecord' && cellOptions.dataType !== 'lastValue') {
      <span class="fw-600"> {{ data?.value | numberFormat | infoDisplay }} {{ data?.unit }} </span>
    }
    @if (isMultipleStateVariable) {
      <span class="fw-600">{{ processedValue }}</span>
    }
  </div>
  @if (!minimalDisplay) {
    <div>
      @if (data?.totalActiveEvents > 0) {
        <mat-icon class="icon-notification" color="warn" (click)="openFollowedVariableEvents($event)">notifications_active </mat-icon>
      }
      @if (canReadGraph && !!data?.totalGraphs) {
        <mat-icon class="icon-graphs" (click)="openGraphsByVariable($event)">assessment</mat-icon>
      }
    </div>
  }
</div>
