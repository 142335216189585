<div data-cy='iot-platform-ui-date-interval-field' fxLayout="row wrap" fxLayoutAlign="space-between center">
  <iot-platform-ui-date-range
    (dateRangeChange)='onDateRangeChange($event)'
    [clearDateRangeFilters$]='clearDateRangeFilters$'
    [dateFormatterPattern]='data()?.dateFormatterPattern'
    data-cy='iot-platform-ui-date-interval-field-date-range'
    fxLayoutAlign="space-around center"
    placeholderClear='FILTER_ENGINE.CLEAR'
    placeholderFrom='FILTER_ENGINE.DATE_PLACEHOLDER_FROM'
    placeholderHours='FILTER_ENGINE.DATE_PLACEHOLDER_HOURS'
    placeholderMinutes='FILTER_ENGINE.DATE_PLACEHOLDER_MINUTES'
    placeholderTo='FILTER_ENGINE.DATE_PLACEHOLDER_TO' />
  <button
    (click)="onValidation()"
    [disabled]="disabled() || (!startDate && !endDate)"
    color="accent"
    data-cy='iot-platform-ui-date-interval-field-add-btn'
    mat-icon-button
  >
    <mat-icon>add_circle</mat-icon>
  </button>
</div>
