import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsDbActions, NotificationsUiActions } from '../actions';

export const notificationsUiFeatureKey = 'notificationsUi';

export interface State {
  notificationsLoaded: boolean;
  notificationsLoading: boolean;
}

export const initialState: State = {
  notificationsLoaded: false,
  notificationsLoading: false
};

export const notificationsUiReducer = createReducer(
  initialState,
  on(
    NotificationsUiActions.loadNotifications,
    NotificationsUiActions.addNotification,
    NotificationsUiActions.updateNotification,
    NotificationsUiActions.deleteNotification,
    NotificationsUiActions.configureNotifications,
    (state: State) => ({ ...state, notificationsLoaded: false, notificationsLoading: true })
  ),
  on(
    NotificationsDbActions.loadNotificationsSuccess,
    NotificationsDbActions.addNotificationSuccess,
    NotificationsDbActions.updateNotificationSuccess,
    NotificationsDbActions.deleteNotificationSuccess,
    NotificationsDbActions.configureNotificationsSuccess,
    (state: State) => ({ ...state, notificationsLoaded: true, notificationsLoading: false })
  ),
  on(
    NotificationsDbActions.loadNotificationsFailure,
    NotificationsDbActions.addNotificationFailure,
    NotificationsDbActions.updateNotificationFailure,
    NotificationsDbActions.deleteNotificationFailure,
    NotificationsDbActions.configureNotificationsFailure,
    (state: State) => ({ ...state, notificationsLoaded: false, notificationsLoading: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return notificationsUiReducer(state, action);
}

export const getNotificationsLoaded = (state: State) => state.notificationsLoaded;
export const getNotificationsLoading = (state: State) => state.notificationsLoading;
