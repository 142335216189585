import { CommonGenericModel, FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createAction, props } from '@ngrx/store';

export const addFavoriteView = createAction('[Favorite Views] Add Favorite View', props<{ favoriteView: FavoriteView }>());
export const addFavoriteViewSuccess = createAction('[Favorite Views] Add Favorite View Success', props<{ favoriteView: FavoriteView }>());
export const synchronizeFavoriteViewAfterSave = createAction('[Favorite Views] Synchronize favorite view after save', props<{ favoriteView: FavoriteView }>());
export const addFavoriteViewError = createAction('[Favorite Views] Add Favorite View Error', props<{ error: any }>());

export const loadFavoriteViews = createAction('[Favorite Views] Load Favorite Views');
export const loadFavoriteViewsSuccess = createAction('[Favorite Views] Load Favorite Views Success', props<{ favoriteViews: FavoriteView[] }>());
export const loadFavoriteViewsError = createAction('[Favorite Views] Load Favorite Views Error', props<{ error: any }>());

export const loadFavoriteViewsWithCount = createAction('[Favorite Views] Load Favorite Views With Count');
export const loadFavoriteViewsWithCountSuccess = createAction(
  '[Favorite Views] Load Favorite Views With Count Success',
  props<{ favoriteViews: FavoriteView[] }>()
);
export const loadFavoriteViewsWithCountError = createAction('[Favorite Views] Load Favorite Views With Count Error', props<{ error: any }>());

export const getCountForFavoriteView = createAction('[Favorite Views] Get Count For Favorite View', props<{ favoriteView: FavoriteView }>());
export const getCountForFavoriteViewSuccess = createAction('[Favorite Views] Get Count For Favorite View Success', props<{ favoriteView: FavoriteView }>());
export const getCountForFavoriteViewFailure = createAction('[Favorite Views] Get Count For Favorite View Failure', props<{ error: any }>());

export const setCurrentFavoriteView = createAction('[Favorite Views] Set Current Favorite View', props<{ masterView: string; favoriteView: FavoriteView }>());

export const setCurrentFilters = createAction('[Favorite Views] Set Current Filters', props<{ masterView: string; filters: Filter[] }>());

export const updateFavoriteView = createAction('[Favorite Views] Update Favorite View', props<{ favoriteView: any }>());
export const updateFavoriteViewSuccess = createAction('[Favorite Views] Update Favorite View Success', props<{ favoriteView: FavoriteView }>());
export const updateFavoriteViewError = createAction('[Favorite Views] Update Favorite View Error', props<{ error: any }>());

export const deleteFavoriteView = createAction('[Favorite Views] Delete Favorite View', props<{ favoriteView: FavoriteView }>());
export const deleteFavoriteViewSuccess = createAction('[Favorite Views] Delete Favorite View Success', props<{ deletedFavoriteView: FavoriteView }>());
export const deleteFavoriteViewError = createAction('[Favorite Views] Delete Favorite View Error', props<{ error: any }>());

export const duplicateFavoriteView = createAction('[Favorite Views] Duplicate Favorite View', props<{ favoriteViewToDuplicate: FavoriteView }>());
export const duplicateFavoriteViewSuccess = createAction('[Favorite Views] Duplicate Favorite View Success', props<{ duplicatedFavoriteView: FavoriteView }>());
export const duplicateFavoriteViewError = createAction('[Favorite Views] Duplicate Favorite View Error', props<{ error: any }>());
//
export const shareGridThenAddFavoriteView = createAction(
  '[Favorite Views] Share Grid then Add Favorite View',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }>()
);
export const shareGridThenAddFavoriteViewSuccess = createAction(
  '[Favorite Views] Share Grid then Add Favorite View Success',
  props<{ favoriteView: FavoriteView }>()
);
export const shareGridThenAddFavoriteViewError = createAction('[Favorite Views] Share Grid then Add Favorite View Error', props<{ error: any }>());
//
export const shareGridThenUpdateFavoriteView = createAction(
  '[Favorite Views] Share Grid then Update Favorite View',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }>()
);
export const shareGridThenUpdateFavoriteViewSuccess = createAction(
  '[Favorite Views] Share Grid then Update Favorite View Success',
  props<{ favoriteView: FavoriteView }>()
);
export const shareGridThenUpdateFavoriteViewError = createAction('[Favorite Views] Share Grid then Update Favorite View Error', props<{ error: any }>());

export const shareGridThenUpdateGridSuccess = createAction('[Favorite Views] Share Grid then Update Grid Success', props<{ grid: CommonGenericModel }>());

export const addFavoriteViewFromMV = createAction('[Favorite Views] Add Favorite View from MV', props<{ favoriteView: FavoriteView }>());
export const addFavoriteViewFromMVSuccess = createAction('[Favorite Views] Add Favorite View from MV Success', props<{ favoriteView: FavoriteView }>());
export const addFavoriteViewFromMVError = createAction('[Favorite Views] Add Favorite View from MV Error', props<{ error: any }>());

export const updateFavoriteViewFromMV = createAction('[Favorite Views] Update Favorite View from MV', props<{ favoriteView: FavoriteView }>());
export const updateFavoriteViewFromMVSuccess = createAction('[Favorite Views] Update Favorite View from MV Success', props<{ favoriteView: FavoriteView }>());
export const updateFavoriteViewFromMVError = createAction('[Favorite Views] Update Favorite View from MV Error', props<{ error: any }>());

export const shareGridThenAddFavoriteViewFromMV = createAction(
  '[Favorite Views] Share Grid then Add Favorite View from MV',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }>()
);
export const shareGridThenAddFavoriteViewFromMVError = createAction(
  '[Favorite Views] Share Grid then Add Favorite View from MV Error',
  props<{ error: any }>()
);
//
export const shareGridThenUpdateFavoriteViewFromMV = createAction(
  '[Favorite Views] Share Grid then Update Favorite View from MV',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }>()
);
export const shareGridThenUpdateFavoriteViewFromMVError = createAction(
  '[Favorite Views] Share Grid then Update Favorite View from MV Error',
  props<{ error: any }>()
);
