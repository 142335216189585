import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, ExportParams, GridApi, GridOptions, ProcessCellForExportParams, ProcessHeaderForExportParams } from '@ag-grid-community/core';
import { ChangeDetectorRef, Component, effect, EventEmitter, inject, model } from '@angular/core';
import { DiagnosticVariableColumn, ExportType, I4BCellType } from '@iot-platform/models/grid-engine';
import { MasterView } from '@iot-platform/models/i4b';
import { DateFormatPipe, NumberFormatPipe, UserNamePipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { capitalize, get } from 'lodash';
import { FRAMEWORK_COMPONENTS } from '../../constants/grid-settings.constants';
import { ArrayOfObjectsCellHelpers } from '../../helpers/array-of-objects-cell.helpers';
import { AssetVariableCellHelpers } from '../../helpers/asset-variable-cell.helpers';
import { BooleanCellHelpers } from '../../helpers/boolean-cell.helpers';
import { CyclicalCallFaultCellHelpers } from '../../helpers/cyclical-call-fault-cell.helpers';
import { DateCellHelpers } from '../../helpers/date-cell.helpers';
import { DiagnosticVariableCellHelper, DiagnosticVariableExportType } from '../../helpers/diagnostic-variable-cell.helper';
import { EventDurationCellHelpers } from '../../helpers/event-duration-cell.helpers';
import { RichVariableValueCellHelpers } from '../../helpers/rich-variable-value-cell.helpers';
import { TagCellHelpers } from '../../helpers/tag-cell.helpers';
import { TranslatedCellHelpers } from '../../helpers/translated-cell.helpers';

/* eslint-disable  @typescript-eslint/no-explicit-any */
@Component({
  standalone: true,
  selector: 'grid-engine-grid-export-component',
  template: ` <ag-grid-angular [rowData]="rowData()" [gridOptions]="gridOptions" [columnDefs]="columnDefs()" (gridReady)="onGridReady($event)" /> `,
  imports: [AgGridAngular, DateFormatPipe, UserNamePipe, ValueUnitFormatPipe, NumberFormatPipe],
  providers: [DateFormatPipe, UserNamePipe, ValueUnitFormatPipe, NumberFormatPipe]
})
export class GridExportComponent {
  private readonly valueUnitFormatPipe: ValueUnitFormatPipe = inject(ValueUnitFormatPipe);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);
  private readonly userNamePipe: UserNamePipe = inject(UserNamePipe);
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridReady: EventEmitter<void> = new EventEmitter<void>();
  public ref: ChangeDetectorRef = inject(ChangeDetectorRef);

  rowData = model<any[]>([]);
  columnDefs = model<ColDef[]>([]);
  gridMeta = model<MasterView>();
  fileName = model<string>('');

  constructor() {
    this.gridOptions = {
      components: FRAMEWORK_COMPONENTS
    };
  }

  get exportParams(): ExportParams<any> {
    return {
      fileName: this.fileName(),
      processHeaderCallback: (params: ProcessHeaderForExportParams) => this.processHeader(params),
      processCellCallback: (params: ProcessCellForExportParams) => this.processCell(params)
    };
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridReady.emit();
  }

  exportData(type: ExportType): void {
    if (type === ExportType.XLSX) {
      this.gridApi.exportDataAsExcel(this.exportParams);
    } else {
      this.gridApi.exportDataAsCsv(this.exportParams);
    }
  }

  private processHeader(params: ProcessHeaderForExportParams): string {
    const gridMeta = this.gridMeta();
    const { column } = params;
    const colMeta = gridMeta.masterViewTable.bluePrint.columns.find((c) => c.id === column.getColId());
    const headerName: string =
      get(colMeta, 'options.overrideHeaderName', null) ||
      get(colMeta, 'header.displayName', null) ||
      get(colMeta, 'catalogName', null) ||
      column.getColDef().headerName;
    if (headerName && !!headerName.length) {
      return capitalize(this.translateService.instant(headerName));
    }
    return capitalize(column.getColId());
  }

  private processDateCell(value): string {
    return DateCellHelpers.processValue(value, this.dateFormatPipe.transform.bind(this.dateFormatPipe));
  }

  private processCell(params: ProcessCellForExportParams): string {
    const { value, column } = params;
    const cellRenderer = column.getColDef().cellRenderer;
    if (typeof cellRenderer === 'function') {
      return cellRenderer(params);
    }
    switch (cellRenderer) {
      case I4BCellType.RICH_VARIABLE: {
        const {
          cellOptions: { displayUnit: dUnit }
        } = params.column.getColDef().cellRendererParams;

        const displayUnit = dUnit ?? true;
        const variable = RichVariableValueCellHelpers.getVariableFromExportParams(params as unknown as any);
        return this.valueUnitFormatPipe.transform(variable as unknown as any, displayUnit);
      }
      case I4BCellType.DATE: {
        return this.processDateCell(value);
      }
      case I4BCellType.CYCLICAL_CALL_FAULT: {
        return value ? this.processDateCell(CyclicalCallFaultCellHelpers.processValue(params)) : '';
      }
      case I4BCellType.BOOLEAN_CELL: {
        const v: string = BooleanCellHelpers.processValue(params);
        return this.translateService.instant(v);
      }
      case I4BCellType.TRANSLATED_CELL: {
        return value ? this.translateService.instant(TranslatedCellHelpers.processValue(params)) : '';
      }
      case I4BCellType.ARRAY_OF_OBJECTS: {
        return ArrayOfObjectsCellHelpers.processValue(params);
      }
      case I4BCellType.TAG_CELL: {
        return TagCellHelpers.processValue(params);
      }
      case I4BCellType.ASSET_VARIABLE_CELL: {
        return AssetVariableCellHelpers.processValue(params);
      }
      case I4BCellType.EVENT_DURATION_CELL: {
        return EventDurationCellHelpers.processValue(this.translateService, params);
      }
      case I4BCellType.DIAGNOSTIC_VARIABLE: {
        const valueToProcessByType: {
          value: string;
          type: DiagnosticVariableExportType;
        } = DiagnosticVariableCellHelper.getValueToProcessByType(params);
        if (valueToProcessByType.type === 'datetime') {
          return this.processDateCell(valueToProcessByType.value);
        } else if (valueToProcessByType.type === 'value') {
          const translatedValues = get(params, ['column', 'colDef', 'cellRendererParams', 'cellOptions', 'translatedValues']);
          if (translatedValues) {
            return params?.value;
          }
          const variableCategory = get(params, ['column', 'colDef', 'cellRendererParams', 'cellOptions', 'variableCategory'], '');
          const variable = DiagnosticVariableColumn.getDiagnosticVariableByCategory(variableCategory, get(params, ['node', 'data', 'expandedVariables'], {}));
          return this.valueUnitFormatPipe.transform(variable as any, get(params, ['column', 'colDef', 'cellRendererParams', 'cellOptions', 'displayUnit']));
        }
        return valueToProcessByType.value;
      }
      case I4BCellType.USER_CELL:
        return this.userNamePipe.transform(value);
      default: {
        return value;
      }
    }
  }
}
