@if (event) {
  <mat-card-content
    class="p-20 mve-detail-popup-container full-height"
    data-cy="iot4bos-ui-event-detail-popup"
    fxLayout="column"
    fxLayoutAlign=""
    fxLayoutGap="10px"
  >
    <section fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="25" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
        <div class="mve-detail-popup-title" id="title-event">
          <span class="capitalize-first-letter">{{ 'EVENTS.DETAIL_POPUP.EVENT_ID' | translate }}:&nbsp;</span>
          <span cdkFocusInitial class="fw-600">{{ event.id | infoDisplay }}</span>
        </div>
        <div class="event-detail-popup-status">
          <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.STATUS' | translate }}:</span>
          <span [class]="'fw-600 capitalize-first-letter event-detail-popup-status-' + event?.status" class="fw-600">{{
              'EVENTS.STATUS.' + event?.status | translate
            }}</span>
        </div>
      </div>
      <div fxFlex="75" fxLayout="row" fxLayoutGap="6px" style="height: fit-content">
        <div class="with-border" fxFlex="65%" fxLayout="column">
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.LABEL' | translate }}:"
            value="{{ event?.label | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'IOT_DICTIONARY.TYPE' | translate }}:"
            value="{{ 'EVENTS.EVENT_TYPES.' + event?.type | translate }}"
          ></iot-platform-ui-detail-popup-display-property>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.SEVERITY' | translate }}:</span>
            <iot-platform-ui-event-severity-display
              [severity]="event?.severity"></iot-platform-ui-event-severity-display>
          </div>
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'IOT_DICTIONARY.NOTE' | translate }}:"
            value="{{ event?.eventNote | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
        </div>
        <div class="with-border" fxFlex="35%" fxLayout="column">
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.OCCURRENCE_DATE' | translate }}:"
            value="{{ event?.occurrenceTime | dateFormat | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.RECEPTION_DATE' | translate }}:"
            value="{{ event?.receptionTime | dateFormat | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.ACKNOWLEDGE_DATE' | translate }}:"
            value="{{ event?.acknowledge?.datetime | dateFormat | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.CLOSE_DATE' | translate }}:"
            value="{{ event?.close?.datetime | dateFormat | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
        </div>
      </div>
    </section>
    <section>
      <mat-tab-group color="accent" mat-align-tabs="center">
        <mat-tab label="{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.INFORMATION' | translate | uppercase }}">
          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container" fxLayout="column" fxLayoutGap="20px">
              <section fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-section-header
                  headerTitle="{{ (eventTags?.length <= 1 ? 'IOT_DICTIONARY.TAG' : 'IOT_DICTIONARY.TAGS') | translate }} ({{ eventTags?.length }})"
                ></iot-platform-ui-detail-popup-section-header>
                <div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  @if (!isTagsLoaded) {
                    <iot-platform-ui-card-loader
                      [backgroundClass]="'tag-loader mv-detail'"></iot-platform-ui-card-loader>
                  } @else {
                    <mat-chip-set class="event-detail-popup-tag-list">
                      @for (tag of eventTags; track tag) {
                        <iot-platform-ui-chip
                          [chipButton]="{ display: false }"
                          [chip]="tag"
                          [isTag]="true"
                          [ngClass]="{ invisible: !isTagsLoaded, visible: isTagsLoaded }"
                        ></iot-platform-ui-chip>
                      } @empty {
                        <span>{{ 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG' | translate }}</span>
                      }
                    </mat-chip-set>
                  }
                </div>
              </section>
              <div fxLayout="row" fxLayoutGap="10px">
                <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                  <iot-platform-ui-detail-popup-section-header
                    headerTitle="{{ 'IOT_DICTIONARY.site' | translate }}"
                  ></iot-platform-ui-detail-popup-section-header>
                  @if (!isSiteLoaded) {
                    <iot-platform-ui-card-loader
                      [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
                  } @else {
                    <iot-platform-ui-detail-site-card (selectSite)="navigateToSite.emit($event)"
                                                      [site]="eventSite" />
                  }
                </section>
                <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                  <iot-platform-ui-detail-popup-section-header
                    headerTitle="{{ 'IOT_DICTIONARY.asset' | translate }}"
                  ></iot-platform-ui-detail-popup-section-header>
                  @if (!isAssetLoaded) {
                    <iot-platform-ui-card-loader
                      [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
                  } @else {
                    <iot-platform-ui-detail-asset-variable-card
                      [assetVariable]="eventAssetVariable"
                      [asset]="eventAsset"
                      [event]="event"
                      [ngClass]="{ invisible: !isAssetLoaded, visible: isAssetLoaded }"
                    ></iot-platform-ui-detail-asset-variable-card>
                  }
                </section>
                <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                  <iot-platform-ui-detail-popup-section-header
                    headerTitle="{{ 'IOT_DICTIONARY.device' | translate }}"
                  ></iot-platform-ui-detail-popup-section-header>
                  @if (!isDeviceLoaded) {
                    <iot-platform-ui-card-loader
                      [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
                  } @else {
                    <iot-platform-ui-detail-device-variable-card
                      [deviceVariable]="eventDeviceVariable"
                      [device]="eventDevice"
                      [event]="event"
                      [ngClass]="{ invisible: !isDeviceLoaded, visible: isDeviceLoaded }"
                    ></iot-platform-ui-detail-device-variable-card>
                  }
                </section>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TIMELINE' | translate | uppercase }}">
          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container">
              @if (!isLogsLoaded) {
                <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-no-bg h-240'"></iot-platform-ui-card-loader>
              } @else {
                <iot4bos-ui-event-timeline
                  (updateStatus)="updateStatus.emit($event)"
                  [canUpdateEvent]="data.canUpdateEvent"
                  [event]="event"
                  [logs]="eventLogs"
                  [ngClass]="{ invisible: !isLogsLoaded, visible: isLogsLoaded }"
                ></iot4bos-ui-event-timeline>
              }
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.COMMENTS' | translate | uppercase }}</ng-template>
          <ng-template matTabLabel>
            {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.COMMENTS' | translate | uppercase }}
            @if (event.totalComments > 0) {
              ({{ event.totalComments }})
            }
          </ng-template>
          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container event-detail-popup-tab-comments">
              <iot4bos-ui-comments-panel
                (commentsEvent)="onCommentsEvent($event)"
                [comments]="eventLogs"
                [footerBackgroundColor]="'#fff'"
                [footerTextColor]="'#394c5a'"
                [loading]="!isLogsLoaded"
                [panelWidth]="'100%'"
                [withFooter]="data.canUpdateEvent"
                [withHeader]="false"
              >
              </iot4bos-ui-comments-panel>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            @if (!eventRelatedTopicsLoaded || eventRelatedTopics?.length < 2) {
              <span>
                {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TOPIC' | translate | uppercase }}
                <span>({{ eventRelatedTopics?.length | infoDisplay }})</span>
              </span>
            }
            @if (eventRelatedTopicsLoaded && eventRelatedTopics?.length >= 2) {
              <span>
                {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TOPICS' | translate | uppercase }}
                ({{ eventRelatedTopics?.length | infoDisplay }})
              </span>
            }
          </ng-template>
          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container">
              @if (!eventRelatedTopicsLoaded) {
                <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-no-bg h-240'"></iot-platform-ui-card-loader>
              } @else {
                <iot4bos-ui-event-topic-list [topics]="eventRelatedTopics"></iot4bos-ui-event-topic-list>
              }
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </section>
  </mat-card-content>
}
