import { TemplateDefLink } from '@iot-platform/models/common';
import { get } from 'lodash';
import * as moment from 'moment';
import { GetUtils } from './get.utils';

export class TemplateDefUtils {
  static objectValuePredicate = ({ value }): unknown => {
    const values = value ? Object.values(value) : [];
    return values?.length ? values[0] : null;
  };

  static concatPredicate = ({ element, def }): unknown => def.attrKeys.map((key: string) => GetUtils.get(element, key, '')).join(def.separator);

  static booleanPredicate = ({ value }): string => (value ? 'IOT_DICTIONARY.YES' : 'IOT_DICTIONARY.NO');

  static timeFormatPredicate = ({ value }): string => moment(value * 1000).format('YYYY-MM-DD HH:mm:ss');

  static dateFormatPredicate = ({ value }): string => (moment(value).isValid() ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-');

  static shortDateFormatPredicate = ({ value }): string => (moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '-');

  static longDateFormatPredicate = ({ value }) => moment(value).format('LL');

  static getQueryParams = ({ data, def }) => TemplateDefLink.getQueryParams({ data, def });

  static getPathParams = ({ data, def }) => TemplateDefLink.getPathParams({ data, def });

  static getParams = ({ def }) => TemplateDefLink.getParams({ def });

  static hasParams = ({ data, def }): boolean => {
    const values = this.getParams({ def })
      .map(({ attr }) => get(data, attr))
      .filter((value: string) => value !== null && value !== undefined);
    return !!values.length;
  };

  static trimPredicate = ({ value }) => value?.toString()?.trim() ?? null;
}
