import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@iot-platform/shared';

import { RolesCrudCheckboxComponent } from './roles-crud-checkbox/roles-crud-checkbox.component';
import { RolesCrudComponent } from './roles-crud.component';

@NgModule({
  declarations: [RolesCrudComponent, RolesCrudCheckboxComponent],
  exports: [RolesCrudComponent, RolesCrudCheckboxComponent],
  imports: [CommonModule, SharedModule]
})
export class RolesCrudModule {}
