import { AssetVariable, AssetVariableCreationModel, I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { I4BActionHelper } from '../../../../helpers/ngrx';

class AssetVariablesActionHelper extends I4BActionHelper<AssetVariable> {}
const assetVariablesActionHelper = new AssetVariablesActionHelper();

export const AssetVariablesActions = createActionGroup({
  source: 'AssetVariables',

  events: {
    ...assetVariablesActionHelper.getDefaultEvents(),
    'Add One': props<{ toAdd: AssetVariableCreationModel }>(),

    'Get All': props<{ assetId: string }>(),
    'Get All Success': props<{ allVariables: AssetVariable[] }>(),
    'Get All Failure': props<{ error: any }>(),
    'Update Followed Asset Variable': props<{ toUpdate: AssetVariable }>(),
    'Update Followed Asset Variable Success': props<{ updated: AssetVariable }>(),
    'Update Followed Asset Variable Failure': props<{ error: any }>(),
    'Update Thresholds': props<{ toUpdate: AssetVariable }>(),
    'Update Thresholds Success': props<{ updated: AssetVariable }>(),
    'Update Thresholds Failure': props<{ error: any }>(),
    'Reset Last Values': props<{ assetVariables: AssetVariable[] }>(),
    'Reset Last Values - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Reset Last Values Failure': props<{ error: any }>(),
    'Delete Many': props<{ toDelete: AssetVariable[] }>(),
    'Delete Many - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Delete Many Failure': props<{ error: any }>(),
    'Break Formula': props<{ id: string }>(),
    'Break Formula Success': emptyProps(),
    'Break Formula Failure': props<{ error: any }>()
  }
});
