import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { CardLoaderModule, OverviewCardComponent, StatusIconComponent } from '@iot-platform/iot-platform-ui';
import { OverviewCard, OverViewCardSection } from '@iot-platform/models/common';
import { DataGuardCheck, DataGuardCheckDetail } from '@iot-platform/models/data-guard';
import { CheckDetailCategoryHeaderComponent } from '../check-detail-category-header/check-detail-category-header.component';
import { CheckErrorComponent } from '../check-error/check-error.component';
import { OverviewCheckComponent } from '../overview-check/overview-check.component';

@Component({
  selector: 'iot-platform-feature-data-guard-detail-tab',
  standalone: true,
  imports: [FlexLayoutModule, CardLoaderModule, OverviewCheckComponent, MatIcon, StatusIconComponent, OverviewCardComponent],
  templateUrl: './detail-tab.component.html',
  styleUrl: './detail-tab.component.scss'
})
export class DetailTabComponent {
  detailedChecks = input<DataGuardCheck>();
  loading = input<boolean>();

  detailedChecksCard: Signal<OverviewCard> = computed(() => {
    const detailedChecks = this.detailedChecks();
    const loading = this.loading();
    if (loading) {
      return {} as OverviewCard;
    } else {
      return this.getCheckOverviewCard(detailedChecks);
    }
  });

  private getCheckOverviewCard(detailedChecks: DataGuardCheck): OverviewCard {
    return {
      cssClass: 'no-box-shadow',
      expansionPanelsOptions: {
        enableCache: false,
        clearOnDestroy: true,
        key: 'tata',
        entityId: undefined
      },
      justifyContent: true,
      sections: this.getOverviewSections(detailedChecks.details)
    };
  }

  private getOverviewSections(checkDetails: DataGuardCheckDetail[]): Partial<OverViewCardSection>[] {
    const sections: Partial<OverViewCardSection>[] = [];

    checkDetails.forEach((detail: DataGuardCheckDetail) => {
      sections.push({
        titleComponentRef: {
          ref: CheckDetailCategoryHeaderComponent,
          inputs: { category: detail.category, statusesCounts: detail.statusCount }
        },
        collapsible: !!detail.errors.length,
        expanded: false,
        properties: detail.errors.map((checkError) => ({
          componentRef: {
            ref: CheckErrorComponent,
            inputs: { checkError }
          }
        }))
      });
    });

    return sections;
  }
}
