import { PlatformResponse } from '@iot-platform/models/common';

import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEventsByTopicSuccess = createAction(
  '[AssetEventsByTopic] Load Asset Events By Topic Success',
  props<{ response: PlatformResponse; topicId: string }>()
);
export const loadAssetEventsByTopicFailure = createAction('[AssetEventsByTopic] Load Asset Events By Topic Failure', props<{ error: any }>());
export const loadTotalActiveAssetEventsByTopicSuccess = createAction(
  '[AssetEventsByTopic] Load Total Active  Asset Events By Topic Success',
  props<{ totalActiveEvents: number }>()
);
export const loadTotalActiveAssetEventsByTopicFailure = createAction(
  '[AssetEventsByTopic] Load Total Active  Asset Events By Topic Failure',
  props<{ error: any }>()
);
export const saveTableByTopicStateSuccess = createAction(
  '[AssetEventsByTopic] Save Table State Success',
  props<{ selectedId: string; checkedIds: string[] }>()
);
export const saveTableByTopicStateFailure = createAction('[AssetEventsByTopic] Save Table State Failure', props<{ error: any }>());
export const updateStatusByAssetEventIdByTopicSuccess = createAction(
  '[AssetEventsByTopic] Update Status By Asset Event Id Success',
  props<{ assetEvent: AssetEvent }>()
);
export const updateStatusByAssetEventIdByTopicFailure = createAction('[AssetEventsByTopic] Update Status By Asset Event Id Failure', props<{ error: any }>());
export const bulkUpdateStatusByAssetEventIdByTopicSuccess = createAction(
  '[AssetEventsByTopic] Bulk Update Status By Asset Event Id Success',
  props<{ assetEvents: AssetEvent[] }>()
);
export const bulkUpdateStatusByAssetEventIdFailure = createAction('[AssetEventsByTopic] Bulk Update Status By Asset Event Id Failure', props<{ error: any }>());
export const loadMvAssetEventsByTopicSettingsSuccess = createAction(
  '[AssetEventsByTopic] Load MV AssetEventsByTopic Settings Success',
  props<{ settings: any }>()
);
export const loadMvAssetEventsByTopicSettingsFailure = createAction(
  '[AssetEventsByTopic] Load MV AssetEventsByTopic Settings Failure',
  props<{ error: any }>()
);
