<section [fxLayout]="displayMode === 'default' ? 'row' : 'column'" fxLayoutGap="20px">
  <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
    <span (click)="selectAsset.emit(asset)" class="mve-detail-popup-title fw-600 mve-detail-popup-title-clickable">{{ asset?.name }}</span>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'ASSETS.CARD.STATUS' | translate }}"
      value="{{ 'IOT_DICTIONARY.' + asset?.status?.name | infoDisplay | translate }}"
    ></iot-platform-ui-detail-popup-display-property>
  </div>

  <div fxFlex="50%" fxLayout="column">
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'ASSETS.CARD.ID' | translate }}"
      value="{{ asset?.businessId | infoDisplay }}"
    ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'IOT_DICTIONARY.SHIPTO' | translate }}:"
      value="{{ asset?.erpReference.shipTo | infoDisplay }}"
    ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'IOT_DICTIONARY.PRODUCT' | translate }}:"
      value="{{ asset?.product ? asset?.product?.identifier + ':' + asset?.product?.name : '-' }}"
    ></iot-platform-ui-detail-popup-display-property>
  </div>
</section>
