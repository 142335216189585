import { effect, Injectable } from '@angular/core';
import { OverViewCardSection } from '@iot-platform/models/common';

import { ComponentStore, provideComponentStore } from '@ngrx/component-store';
import { get } from 'lodash';

interface OverviewCardState {
  [key: string]: {
    // Save expansion panel for each item when entityId is defined
    [entityId: string]: {
      [index: number]: {
        expanded: boolean
      }
    } |
      // Save expansion panel for group of items when entityId is not defined
      {
        [sectionKey: string]: {
          expanded: boolean
        }
      };
  } | null;
}

@Injectable({ providedIn: 'root' })
export class OverviewCardStore extends ComponentStore<OverviewCardState> {
  expansionPanelsState: Partial<OverviewCardState> = {};

  expandChange = this.updater((state: OverviewCardState, value: {
    key: string,
    entityId: string,
    section: OverViewCardSection,
    index: number;
    expanded: boolean;
  }): OverviewCardState => {
    const key = value.key;
    const entityId = value.entityId;
    const viewState = get(state, [key], {});
    let newState = { ...state };

    if (entityId !== null && entityId !== undefined) {
      const entityState = get(viewState, [entityId], {});
      const panelState = get(entityState, [value.index], {});
      newState = {
        [key]: {
          ...viewState,
          [entityId]: {
            ...entityState,
            [value.index]: {
              ...panelState,
              expanded: value.expanded
            }
          }
        }
      };
    } else if (value.section?.key) {
      newState = {
        [key]: {
          ...viewState,
          [value.section.key]: {
            expanded: value.expanded
          }
        } as {
          [sectionKey: string]: {
            expanded: boolean
          }
        }
      };
    }
    return {
      ...newState
    };
  });

  clear = this.updater((state: OverviewCardState, key: string): OverviewCardState => ({
    ...state,
    [key]: null
  }));

  constructor() {
    super({});
    effect(() => {
      this.expansionPanelsState = this.state();
    }, { allowSignalWrites: true });
  }

  getPanelExpandedByIndex = (key: string, entityId: string, section: OverViewCardSection, index: number, defaultValue = true) => {
    const expanded = get(this.expansionPanelsState, [key, entityId, index, 'expanded'], get(this.expansionPanelsState, [key, section.key, 'expanded']));
    if (expanded !== undefined) {
      return expanded;
    }
    return defaultValue;
  };
}

export const provideOverviewCardState = () => provideComponentStore(OverviewCardStore);
