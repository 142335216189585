<div data-cy="iot-platform-ui-toolbar-button">
  @if (button().type === IotToolbarButtonType.STATUS_BUTTON) {
    @if (button().displayButton && !(button().disabled && button().removable)) {
      @if (button().customTemplate) {
        <ng-content select="[toolbarCustomStatusButtonItem]"></ng-content>
      } @else {
        <iot-platform-ui-status-button
          cssClassName="{{ 'iot-platform-ui-toolbar-button-' + button().dispatchAction?.type }} {{ button().cssClassName }}"
          (dispatchEvent)="onButtonClick(button().dispatchAction)"
          [color]="button().color"
          [disabled]="button().disabled"
          [matTooltip]="button().tooltip | translate"
          [actionLabel]="button().actionLabel"
          [actionSubLabel]="button().actionSubLabel"
          [displayNestedAction]="button().displayNestedAction"
          [nestedActionLabel]="button().nestedActionLabel"
        />
      }
    }
  } @else if (button().type === IotToolbarButtonType.MENU_BUTTON && button().displayButton) {
    <button
      [matMenuTriggerFor]="buttonMenu"
      [disabled]="button().disabled"
      [matTooltip]="button().tooltip | translate"
      class="regular-round-button reverse-accent-button"
      mat-icon-button
      matTooltipClass="regular-tooltip"
    >
      <mat-icon>{{ button().icon }}</mat-icon>
    </button>

    <mat-menu #buttonMenu="matMenu">
      @for (menuOption of button().menuOptions; track menuOption.label) {
        @if (menuOption?.divider) {
          <mat-divider></mat-divider>
        }
        <button (click)="onMenuOptionClick(menuOption.dispatchAction)" [disabled]="menuOption.disableOption" [matTooltip]="menuOption.tooltip" mat-menu-item>
          {{ menuOption.label | translate }}
        </button>
      }
    </mat-menu>
  } @else if (button().displayButton && !(button().disabled && button().removable)) {
    <button
      (click)="onButtonClick(button().dispatchAction)"
      [class]="'regular-round-button reverse-accent-button iot-platform-ui-toolbar-button-' + button().dispatchAction?.type"
      [disabled]="button().disabled"
      [matTooltip]="button().tooltip | translate"
      [ngClass]="computedClasses()"
      mat-icon-button
      matTooltipClass="regular-tooltip"
    >
      <mat-icon [svgIcon]="button().svgIcon" fxFlex>{{ button().icon }}</mat-icon>
    </button>
  }
</div>
