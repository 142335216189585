<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'COMMENTS.EDIT_COMMENT' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="form" class="p-20" fxLayout="row" fxLayoutAlign="start center" fxFlex>
      <mat-form-field class="comment-field" color="accent" hideRequiredMarker fxFlex>
        <mat-label>{{ 'ASSETS.VARIABLE_FORM.COMMENT' | translate }}</mat-label>
        <textarea #commentInput class="comment-input" formControlName="comment" matInput maxlength="300"></textarea>
        <mat-hint align="end">{{ commentInput.value.length || 0 }}/300</mat-hint>
        <mat-hint align="start" class="comment-hint">{{ 'COMMENTS.EDIT_HINT' | translate }}</mat-hint>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="form?.invalid || comment.getRawValue() === data.comment" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
