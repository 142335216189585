import { ColDef, GridApi, IRowNode, RowClickedEvent, RowNode } from '@ag-grid-community/core';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { CloneUtils } from '@iot-platform/iot-platform-utils';
import { TranslateService } from '@ngx-translate/core';
import exportFromJSON from 'export-from-json';
import { unflatten } from 'flat';
import { isEqual as _isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { TranslationCreateFormComponent } from '../../components/translation-create-form/translation-create-form.component';
import { TranslationDetailsComponent } from '../../components/translation-details/translation-details.component';
import { TranslationEditFormComponent } from '../../components/translation-edit-form/translation-edit-form.component';
import { TranslationPreviewChangesComponent } from '../../components/translation-preview-changes/translation-preview-changes.component';
import { TranslationTableViewComponent } from '../../components/translation-table-view/translation-table-view.component';
import { TranslationToolbarComponent } from '../../components/translation-toolbar/translation-toolbar.component';
import { TranslationDictionary } from '../../models/translation-dictionary.model';
import { TranslationManagementService } from '../../services/translation-management.service';

@Component({
  selector: 'i18n-translation-overview',
  templateUrl: './translation-shell.component.html',
  styleUrls: ['./translation-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslationToolbarComponent, TranslationTableViewComponent, AsyncPipe]
})
export class TranslationShellComponent implements OnInit, OnDestroy {
  public dictionaries$: Observable<TranslationDictionary[]>;
  public languages: string[];
  public showClearSelectedButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public editMode: boolean;
  public selectedRows: RowNode[] = [];
  public gridApi: GridApi;
  subscriptions = new Subscription();
  private popupSize = 600;

  constructor(
    private readonly dialog: MatDialog,
    private readonly translationManagementService: TranslationManagementService,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.languages = this.translationManagementService.getLanguages();
    this.dictionaries$ = this.translationManagementService.getDictionaries();
  }

  public onGridReady({ gridApi }): void {
    this.gridApi = gridApi;
  }

  public onSwitchEditMode(editMode): void {
    this.editMode = editMode;
  }

  public onRowClicked(event: RowClickedEvent): void {
    if (this.editMode) {
      this.openEditForm(event);
    } else {
      this.showDetails(event);
    }
  }

  public onSelectionChanged(selectionRows: RowNode[]): void {
    this.selectedRows = [...selectionRows];
    this.showClearSelectedButton$.next(this.selectedRows && !!this.selectedRows.length);
  }

  public onClear(): void {
    this.subscriptions.add(
      this.dialog
        .open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: { type: 'confirm', value: 'ADMIN.TRANSLATION_MANAGEMENT.OVERVIEW.CLEAR_CONFIRM_MESSAGE' }
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.confirmClear();
          }
        })
    );
  }

  public onExport(action: string): void {
    const { updatedRows, dictionaries } = this.getUpdatedRows();
    this.gridApi.deselectAll();
    if (!updatedRows || !updatedRows.length) {
      this.downloadTranslations(action, dictionaries);
    } else {
      this.subscriptions.add(
        this.dialog
          .open(TranslationPreviewChangesComponent, {
            width: `${this.popupSize}px`,
            disableClose: true,
            data: { action, updatedRows, languages: this.languages }
          })
          .afterClosed()
          .subscribe((confirmed) => {
            if (confirmed) {
              this.downloadTranslations(action, dictionaries);
            }
          })
      );
    }
  }

  public onAddLanguage(): void {
    this.subscriptions.add(
      this.dialog
        .open(TranslationCreateFormComponent, {
          width: `${this.popupSize}px`,
          disableClose: true,
          data: {
            languages: this.languages
          }
        })
        .afterClosed()
        .subscribe((newValue) => {
          if (newValue) {
            this.gridApi.deselectAll();
            this.addLanguage(newValue);
          }
        })
    );
  }

  public addLanguage(lang: string): void {
    const columnDefs: ColDef[] = [...this.gridApi.getColumnDefs()];
    columnDefs.push({
      colId: lang,
      headerName: lang.toUpperCase(),
      field: `${lang}.value`,
      tooltipField: `${lang}.value`
    });
    this.gridApi.updateGridOptions({ columnDefs });

    this.gridApi.forEachNode((node: RowNode) => {
      const data = { ...node.data };
      data[lang] = { value: '', missing: false };
      node.setData(data);
    });
    this.gridApi.redrawRows();
    this.languages.push(lang);
  }

  public onDeleteLanguage(lang: string): void {
    this.subscriptions.add(
      this.translateService
        .get('ADMIN.TRANSLATION_MANAGEMENT.OVERVIEW.DELETE_CONFIRM_MESSAGE', { value: lang.toUpperCase() })
        .pipe(
          concatMap((message: string) =>
            this.dialog
              .open(PopupComponent, {
                width: '500px',
                disableClose: true,
                data: { type: 'confirm', value: message }
              })
              .afterClosed()
          )
        )
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.confirmDelete(lang);
          }
        })
    );
  }

  public confirmDelete(lang: string): void {
    const columnDefs: ColDef[] = [...this.gridApi.getColumnDefs()].filter((col: ColDef) => col.colId !== lang);
    this.gridApi.updateGridOptions({ columnDefs });
    this.gridApi.forEachNode((node: RowNode) => {
      const data = { ...node.data };
      delete data[lang];
      node.setData(data);
    });
    this.gridApi.redrawRows();
    this.languages = [...this.languages].filter((l) => l !== lang);
  }

  private downloadTranslations(action: string, dictionaries): void {
    const exportType = exportFromJSON.types.json;
    if (action !== 'all') {
      exportFromJSON({ data: unflatten(dictionaries[action]), fileName: action, exportType, fields: [] });
    } else {
      this.languages.forEach((lang: string) => {
        exportFromJSON({ data: unflatten(dictionaries[lang]), fileName: lang, exportType, fields: [] });
      });
    }
  }

  private getUpdatedRows() {
    const dictionaries = {};
    const updatedRows: RowNode[] = [];
    this.gridApi.forEachNode(({ data }) => {
      if (data.changed) {
        updatedRows.push(data);
      }
      this.languages.forEach((lang: string) => {
        if (!dictionaries[lang]) {
          dictionaries[lang] = {};
        }
        dictionaries[lang][data.key] = data[lang].value;
      });
    });
    return {
      updatedRows,
      dictionaries
    };
  }

  private openEditForm(rowEvent: RowClickedEvent): void {
    this.subscriptions.add(
      this.dialog
        .open(TranslationEditFormComponent, {
          width: `${this.popupSize}px`,
          disableClose: true,
          data: {
            languages: this.languages,
            rowData: { ...CloneUtils.cloneData(rowEvent.data) }
          }
        })
        .afterClosed()
        .subscribe((newValues) => {
          if (newValues) {
            this.gridApi.deselectAll();
            this.setRowNodeData(rowEvent.node, newValues);
          }
        })
    );
  }

  private confirmClear(): void {
    this.selectedRows.forEach((rowNode: RowNode) => {
      const data = CloneUtils.cloneData(rowNode.data);
      this.languages.forEach((lang: string) => {
        data[lang].value = '';
      });
      this.setRowNodeData(rowNode, data);
    });
    this.gridApi.deselectAll();
  }

  private setRowNodeData(node: IRowNode, newValues): void {
    const isEqual: boolean = _isEqual(node.data, newValues);
    if (node && !isEqual) {
      const rowData = {
        ...newValues,
        changed: node.data && node.data.changed ? node.data.changed : !isEqual
      };
      node.setData(rowData);
      const rowNodes = [node];
      const params = {
        force: true,
        rowNodes
      };
      this.gridApi.refreshCells(params);
    }
  }

  private showDetails(rowEvent: RowClickedEvent): void {
    this.dialog.open(TranslationDetailsComponent, {
      width: `${this.popupSize}px`,
      disableClose: false,
      data: {
        languages: this.languages,
        rowData: { ...rowEvent.data }
      }
    });
  }
}
