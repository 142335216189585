@if (data) {
  <div
    class="translated-cell"
    [ngStyle]="translateStyle"
    [matTooltip]="translateKey + (cellOptions?.applyLowerCase ? data.toLowerCase() : data) | translate"
    matTooltipPosition="above"
    matTooltipClass="regular-tooltip"
  >
    <span>
      {{ translateKey + (cellOptions?.applyLowerCase ? data.toLowerCase() : data) | translate }}
    </span>
  </div>
}
@if (!data) {
  <div class="translated-cell" [ngStyle]="translateStyle">-</div>
}
