import { TagCategory } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listTagsByOrganization = createAction(
  '[Organizations/Admin Page] List Tags By Organization',
  props<{ organizationId: string; withParents: boolean; concept?: string }>()
);
export const listTagsByOrganizationForEdition = createAction(
  '[Organizations/Admin Page] List Tags By Organization For Edition',
  props<{ organizationId: string; withParents: boolean; concept?: string }>()
);

export const addTagToOrganization = createAction('[Organizations/Admin Page] Add Tag to Organization', props<{ tagToAdd: TagCategory }>());
export const updateTagInOrganization = createAction('[Organizations/Admin Page] Update Tag In Organization', props<{ tagToUpdate: TagCategory }>());
export const removeTagFromOrganization = createAction('[Organizations/Admin Page] Remove Tag From Organization', props<{ tagToRemove: TagCategory }>());

export const addTagToOrganizationForEdition = createAction('[Organizations/Admin Page] Add Tag to Organization ForEdition', props<{ tagToAdd: TagCategory }>());
export const updateTagInOrganizationForEdition = createAction(
  '[Organizations/Admin Page] Update Tag In Organization ForEdition',
  props<{ tagToUpdate: TagCategory }>()
);
export const removeTagFromOrganizationForEdition = createAction(
  '[Organizations/Admin Page] Remove Tag From Organization ForEdition',
  props<{ tagToRemove: TagCategory }>()
);
