import { Component, computed, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Asset, Device, Site, SiteType } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { AssetBreadcrumbItemComponent } from './asset-breadcrumb-item/asset-breadcrumb-item.component';
import { DeviceBreadcrumbItemComponent } from './device-breadcrumb-item/device-breadcrumb-item.component';
import { SiteBreadcrumbItemComponent } from './site-breadcrumb-item/site-breadcrumb-item.component';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    SiteBreadcrumbItemComponent,
    AssetBreadcrumbItemComponent,
    DeviceBreadcrumbItemComponent,
    TranslateModule
  ],
  selector: 'iot4bos-ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss', './breadcrumb-item.scss']
})
export class BreadcrumbComponent {
  site = input<Site>();
  selectedAsset = input<Asset>();
  selectedDevice = input<Device>();
  canReadDisplayedConcept = input<boolean>(false);
  activeItemId = input<string>('');
  mvorigin = input<string>('');

  // eslint-disable-next-line @angular-eslint/no-output-native
  load = output<{ origin: string; activeId: string; requestedSiteId: string }>();
  returnToOrigin = output<void>();
  refreshSite = output<Site>();
  refreshAsset = output<Asset>();
  refreshDevice = output<Device>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  close = output<void>();
  toggleAssociationsPanel = output<boolean>();
  toggleCommentPanel = output<{ concept: 'site' | 'asset' | 'device'; element: Site | Asset | Device }>();
  toggleDataGuard = output<void>();

  displayDataGuardBadge: Signal<boolean> = computed(() => {
    const site = this.site();
    const asset = this.selectedAsset();
    const device = this.selectedDevice();

    if (asset) {
      return asset.checkStatus === 'error' || asset.checkStatus === 'warning';
    } else if (device) {
      return device.checkStatus === 'error' || device.checkStatus === 'warning';
    } else if (site && site.type !== SiteType.STOCK) {
      return site.checkStatus === 'error' || site.checkStatus === 'warning';
    } else {
      return false;
    }
  });

  navigate(path: string, ids: { activeId: string; requestedSiteId: string }) {
    this.load.emit({ origin: path, activeId: ids.requestedSiteId, requestedSiteId: ids.requestedSiteId });
  }

  onReturnToOriginClick() {
    this.returnToOrigin.emit();
  }

  onRefreshAssetClick() {
    this.refreshAsset.emit(this.selectedAsset());
  }

  onRefreshDeviceClick() {
    this.refreshDevice.emit(this.selectedDevice());
  }

  onOpenAssociations() {
    this.toggleAssociationsPanel.emit(true);
  }

  onRefreshSiteClick() {
    this.refreshSite.emit(this.site());
  }

  onDataGuardClick() {
    this.toggleDataGuard.emit();
  }

  onCommentClicked() {
    const payload: { concept: 'site' | 'asset' | 'device'; element: Site | Asset | Device } = this.selectedAsset()
      ? { concept: 'asset', element: this.selectedAsset() }
      : this.selectedDevice()
        ? { concept: 'device', element: this.selectedDevice() }
        : { concept: 'site', element: this.site() as Site };
    this.toggleCommentPanel.emit(payload);
  }

  protected readonly SiteType = SiteType;
}
