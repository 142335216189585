<mat-card class="dialog-card timeseries-widget">
  @if (widgetOptions()?.header?.enabled) {
    <mat-card-title class="dialog-card-header">
      <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
        <h1 class="dialog-card-title timeseries-widget_title">{{ widgetOptions()?.header?.title }}</h1>
      </mat-toolbar>
    </mat-card-title>
  }

  <mat-card-content class="dialog-card-content" fxLayout="row">
    <iot-platform-ui-variable-chart
      [data]="widgetOptions()?.variables"
      [options]="widgetOptions()"
      class="timeseries-widget_chart timeseries-widget_chart--{{ widgetOptions()?.size?.toLowerCase() }}"
      variableType="assetVariable"
    />
  </mat-card-content>

  @if (widgetOptions()?.footer?.enabled) {
    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="center center">
      <div class="timeseries-widget_legend">{{ widgetOptions()?.footer?.legend }}</div>
    </mat-card-actions>
  }
</mat-card>
