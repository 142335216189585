import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadUserPreferences = createAction('[Users/Preferences] Load UserPreferences');
export const loadUserPreferencesSuccess = createAction(
  '[Users/Preferences] Load UserPreferences Success',
  props<{ user: UserAccount; preferences: UserPreferences }>()
);
export const loadUserPreferencesFailure = createAction('[Users/Preferences] Load UserPreferences Failure', props<{ error: any }>());

export const saveUserPreferences = createAction(
  '[Users/Preferences] Save UserPreferences',
  props<{ user: UserAccount; preferences: Partial<UserPreferences> }>()
);
export const saveUserPreferencesSuccess = createAction(
  '[Users/Preferences] Save UserPreferences Success',
  props<{ user: UserAccount; preferences: UserPreferences }>()
);
export const saveUserPreferencesFailure = createAction('[Users/Preferences] Save UserPreferences Failure', props<{ error: any }>());

export const changeLanguage = createAction('[Users/Preferences] Change Language', props<{ lang: string }>());
