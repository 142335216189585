export enum FollowedVariableCardType {
  ADD = 'ADD',
  PREVIEW = 'PREVIEW'
}

export interface FollowedVariableCard {
  type: FollowedVariableCardType;
  followedNumber: number;
  name: string;
  followedNumberIcon: string;
  linkedIcon: string;
}
