import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { Asset } from '@iot-platform/models/i4b';

@Component({
  standalone: true,
  imports: [MatIconModule, FlexLayoutModule],
  selector: 'iot4bos-ui-asset-breadcrumb-item',
  templateUrl: './asset-breadcrumb-item.component.html',
  styleUrls: ['./asset-breadcrumb-item.component.scss', '../breadcrumb-item.scss']
})
export class AssetBreadcrumbItemComponent {
  asset = input<Asset>();
  active = input<boolean>(true);

  navigate = output<{ activeId: string; requestedSiteId: string }>();

  onClick() {
    this.navigate.emit({ activeId: this.asset().site.id, requestedSiteId: this.asset().site.id });
  }

  close() {
    this.navigate.emit({ activeId: this.asset().site.id, requestedSiteId: this.asset().site.id });
  }
}
