import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MasterView } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GridEngineService {
  constructor(private readonly http: HttpClient) {}

  loadStaticMetaData(masterViewName: string): Observable<MasterView> {
    return this.http.get<MasterView>(`assets/engines/master-views/${masterViewName}.json`);
  }
}
