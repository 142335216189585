import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { TotalColumn } from './i4b-total.column';

export class TotalDevicesColumn extends TotalColumn {
  columnId = 'a4ea36ae-5c1e-11ec-80a5-0023a40210d0-total-device';
  className = TotalDevicesColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      { displayName: 'TOTAL DEVICES', icon: 'device', ...customHeader },
      { ...customConfig, id: customConfig.id ? customConfig.id : 'totalDevices' },
      { customId: customConfig.id ? customConfig.id : 'totalDevices', ...customOptions }
    );
  }
}
