@if (contacts()?.length > 0) {
  <ul style="overflow: auto; max-height: 224px">
    @for (contact of contacts(); track contact) {
      <li class="detail-contact-card-item">
        <div class="detail-contact-card-container" fxLayout="column">
          <span class="detail-contact-card-item-info fw-600"
          >{{ contact.firstname | infoDisplay }} {{ contact | infoDisplay: 'brackets' : 'lastname' : 'function' }}</span
          >
          <span class="detail-contact-card-item-info">{{ contact.phone | infoDisplay }}</span>
          <span class="detail-contact-card-item-info">{{ contact.email | infoDisplay }}</span>
        </div>
      </li>
    }
  </ul>
} @else {
  <div class="detail-contact-card">
    <div class="detail-contact-card-empty" fxLayout="row" fxLayoutAlign="center center">
      {{ 'DETAIL_ASSETS_CARD_COMPONENT.NO_DATA' | translate }} {{ 'IOT_DICTIONARY.CONTACT' | translate }}
    </div>
  </div>
}
