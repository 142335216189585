import { inject } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { AssetEventsService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, of, map, tap, concatMap, switchMap } from 'rxjs';
import { AssetEventsCommentsActions } from '../actions';
import { NotificationService } from '@iot-platform/notification';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { CommonGenericModel } from '@iot-platform/models/common';

const loadAssetEventComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.loadComments),
      concatMap(({ assetEvent }) =>
        assetEventsService.loadComments(assetEvent).pipe(
          map((comments: Log[]) => AssetEventsCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(AssetEventsCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.addComment),
      concatMap(({ assetEvent, comment }) =>
        assetEventsService.addComment(assetEvent.id, comment).pipe(
          switchMap((addedComment: Log) => [
            AssetEventsCommentsActions.addCommentSuccess({ comment: addedComment }),
            GridsDbActions.updateItemInAllGridsData({
              updatedItem: { ...assetEvent, totalComments: assetEvent.totalComments + 1 } as CommonGenericModel,
              concept: 'asset-events'
            })
          ]),
          catchError((error) => of(AssetEventsCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.editComment),
      concatMap(({ assetEventId, comment }) =>
        assetEventsService.editComment(assetEventId, comment).pipe(
          map((editedComment: Log) => AssetEventsCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(AssetEventsCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetEventsService = inject(AssetEventsService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.deleteComment),
      concatMap(({ assetEvent, commentId }) =>
        assetEventsService.deleteComment(assetEvent.id, commentId).pipe(
          switchMap((deletedCommentId: string) => [
            AssetEventsCommentsActions.deleteCommentSuccess({ commentId: deletedCommentId }),
            GridsDbActions.updateItemInAllGridsData({
              updatedItem: { ...assetEvent, totalComments: assetEvent.totalComments > 0 ? assetEvent.totalComments - 1 : 0 } as CommonGenericModel,
              concept: 'asset-events'
            })
          ]),
          catchError((error) => of(AssetEventsCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetEventsCommentsActions.loadComments,
        AssetEventsCommentsActions.addComment,
        AssetEventsCommentsActions.editComment,
        AssetEventsCommentsActions.deleteComment
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetEventsCommentsActions.loadCommentsSuccess,
        AssetEventsCommentsActions.loadCommentsFailure,
        AssetEventsCommentsActions.addCommentSuccess,
        AssetEventsCommentsActions.addCommentFailure,
        AssetEventsCommentsActions.editCommentSuccess,
        AssetEventsCommentsActions.editCommentFailure,
        AssetEventsCommentsActions.deleteCommentSuccess,
        AssetEventsCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AssetEventsCommentsActions.addCommentSuccess, AssetEventsCommentsActions.editCommentSuccess, AssetEventsCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetEventsCommentsActions.loadCommentsFailure,
        AssetEventsCommentsActions.addCommentFailure,
        AssetEventsCommentsActions.editCommentFailure,
        AssetEventsCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const AssetEventsCommentsEffects = {
  loadAssetEventComments$,
  addComment$,
  editComment$,
  deleteComment$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
