<div fxLayout="row" fxLayoutAlign="end center" style="padding: 10px 20px 10px 0px; color: #4b4a4a; border-top: 0">
  <span style="margin: 0 20px 0 20px">Items per page : {{ pagination()?.limit }} </span>
  <span style="margin: 0 20px 0 20px"> {{ getPaginationFooter() }}</span>

  <button (click)="navigateFirst()" [disabled]="currentPage() === 0" mat-icon-button>
    <mat-icon>first_page</mat-icon>
  </button>

  <button (click)="navigatePrevious()" [disabled]="currentPage() === 0" mat-icon-button>
    <mat-icon>chevron_left</mat-icon>
  </button>

  <span>{{ 'AG_GRID.page' | translate }} {{ (currentPage() ? currentPage() + 1 : 1) | numberFormat }} of {{ (pagination()?.maxPage + 1) | numberFormat }}</span>

  <button (click)="navigateNext()" [disabled]="currentPage() === pagination()?.maxPage" mat-icon-button>
    <mat-icon>chevron_right</mat-icon>
  </button>

  <button (click)="navigateLast()"
          [disabled]="pagination()?.hasMore === false || currentPage() === pagination()?.maxPage" mat-icon-button>
    <mat-icon>last_page</mat-icon>
  </button>
</div>
