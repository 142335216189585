import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component, signal, WritableSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderShellComponent } from '../header-shell/header-shell.component';

@Component({
  standalone: true,
  selector: 'grid-engine-basic-header-cell',
  templateUrl: './basic-header-cell.component.html',
  imports: [HeaderShellComponent, TranslateModule],
  styleUrls: ['./basic-header-cell.component.scss']
})
export class BasicHeaderCellComponent implements IHeaderAngularComp {
  params: WritableSignal<IHeaderParams> = signal(null);

  agInit(params: IHeaderParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
