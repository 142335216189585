import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';

import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
  standalone: true,
  selector: 'grid-engine-color-cell',
  templateUrl: './color-cell.component.html',
  styleUrls: ['./color-cell.component.scss']
})
export class ColorCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
