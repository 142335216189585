<span
  data-cy="i4b-table-engine-basic-link-cell"
  [ngClass]="{ visited: cellOptions && cellOptions?.linkStyling, link_padding: cellOptions && cellOptions?.padding }"
  class="basic-click-cell"
  [matTooltip]="data | translate"
  [matTooltipPosition]="cellOptions?.tooltipPosition ? cellOptions?.tooltipPosition : 'above'"
  matTooltipClass="regular-tooltip"
  (click)="onDispatchEvent($event)"
  >{{ data | infoDisplay | translate }}
</span>
