import { Injectable } from '@angular/core';
import { fromGrids } from '@iot-platform/grid-engine';
import { BaseFacade, Filter, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { AssetTemplatesActions } from '../actions/asset-templates.actions';
import { AssetTemplatesSelectors } from '../selectors/asset-templates.selectors';

@Injectable({
  providedIn: 'root'
})
export class AssetTemplatesFacade extends BaseFacade<AssetTemplate, Pagination, Filter> {
  grids$: Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.select(fromGrids.selectAssetTemplateGrids);

  gridConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = this.store.select(fromGrids.selectAssetTemplateGridsConfiguration);

  constructor(
    protected override store: Store,
    protected override selector: AssetTemplatesSelectors
  ) {
    super(store, selector);
  }

  setFilters(filters: Filter[]): void {
    this.store.dispatch(AssetTemplatesActions.setFilters({ filters }));
  }

  getAll(): void {
    noop();
  }

  addAssetTemplate(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.addAssetTemplate({ assetTemplate }));
  }

  updateAssetTemplate(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.updateAssetTemplate({ assetTemplate }));
  }

  updateTemplate(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.updateTemplate({ assetTemplate }));
  }

  updateAssetTemplateTags(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.updateAssetTemplateTags({ assetTemplate }));
  }

  configureAssetTemplateProducts(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.configureAssetTemplateProducts({ assetTemplate }));
  }

  removeAssetTemplateProducts(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.removeAssetTemplateProducts({ assetTemplate }));
  }

  configureAssetTemplateOptionalProperties(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.configureAssetTemplateOptionalProperties({ assetTemplate }));
  }

  addAssetTemplateFollowedVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.addAssetTemplateFollowedVariable({ assetTemplate }));
  }

  removeAssetTemplateFollowedVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.removeAssetTemplateFollowedVariable({ assetTemplate }));
  }

  deleteAssetTemplate(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.deleteAssetTemplate({ assetTemplate }));
  }

  navigateToAssetTemplateDetails(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.navigateToAssetTemplateDetails({ assetTemplate }));
  }

  getAssetTemplateById(id: string): void {
    this.store.dispatch(AssetTemplatesActions.getAssetTemplateById({ assetTemplateId: id }));
  }

  createAssetTemplateVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.createAssetTemplateVariable({ assetTemplate }));
  }

  updateAssetTemplateVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.updateAssetTemplateVariable({ assetTemplate }));
  }

  configureAssetTemplateVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.configureAssetTemplateVariable({ assetTemplate }));
  }

  deleteAssetTemplateVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.deleteAssetTemplateVariable({ assetTemplate }));
  }

  bulkDeleteAssetTemplateVariable(assetTemplate: AssetTemplate): void {
    this.store.dispatch(AssetTemplatesActions.bulkDeleteAssetTemplateVariable({ assetTemplate }));
  }
}
