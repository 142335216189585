@if (params()?.cellOptions?.mode === 'checkbox') {
  <mat-checkbox [checked]="data()" color="accent" [disabled]="params()?.cellOptions?.disabled" />
} @else if (data()) {
  <div [ngClass]="{ 'translated-cell': true, withStatusPadding: params()?.cellOptions?.withStatusPadding }" [ngStyle]="translateStyle()">
    <span>
      {{ translateKey() + (params()?.cellOptions?.applyLowerCase ? data()?.toLowerCase() : data()) | translate }}
    </span>
  </div>
} @else {
  <div class="translated-cell" [ngStyle]="translateStyle()">-</div>
}
