import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'i4b-table-engine-basic-cell',
  templateUrl: './basic-cell.component.html',
  styleUrls: ['./basic-cell.component.scss']
})
export class BasicCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() cellOptions?: any;
  @Input() columnFormat: any;

  @Output() dispatchEvent;

  maxLength = 40;
  dataType: string;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit() {
    this.dataType = typeof this.data;
    if (!this.data && this.cellOptions && this.cellOptions.default) {
      this.data = this.translate.instant(this.cellOptions.default);
    }
  }
}
