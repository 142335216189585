import { computed, inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractAuthFacade, BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { combineLatest, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AuthorizationConcept, AuthorizationType } from '../../authorization.types';
import { AuthorizationService } from '../../services/authorization.service';
import { AuthApiActions } from '../actions/auth-api.actions';
import { AuthBusinessProfilesPageActions } from '../actions/auth-business-profiles-page.actions';
import { AuthPageActions } from '../actions/auth-page.actions';
import {
  selectAccessToken,
  selectBusinessProfilesForAccount,
  selectCognitoUser,
  selectCurrentUser,
  selectIdToken,
  selectLoggedIn,
  selectLoggedInWithSSO,
  selectPrivileges,
  selectRefreshToken,
  selectSelectedBusinessProfileForAccount,
  selectSelectedEntityForSession,
  selectSsoTokenExpiresAt,
  selectUserId
} from '../selectors/auth-api.selectors';
import { selectSelectedBusinessProfileId } from '../selectors/auth-business-profiles-api.selectors';
import {
  selectAuthPageErrorEmail,
  selectAuthPageErrorPassword,
  selectAuthPageErrorSignIn,
  selectAuthPagePending,
  selectAuthPageRequireNewPassword,
  selectAuthPageStepEmail,
  selectAuthPageStepPassword,
  selectDisplayEmailPasswordLogin
} from '../selectors/auth-page.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade extends AbstractAuthFacade {
  protected readonly store: Store = inject(Store);

  loggedIn$: Observable<boolean> = this.store.select(selectLoggedIn);
  idToken$: Observable<string> = this.store.select(selectIdToken);
  accessToken$: Observable<string> = this.store.select(selectAccessToken);
  selectedBusinessProfileId$: Observable<string> = this.store.select(selectSelectedBusinessProfileId);
  loggedInWithSSO$: Observable<boolean> = this.store.select(selectLoggedInWithSSO);
  refreshToken$: Observable<string> = this.store.select(selectRefreshToken);
  ssoTokenExpiresAt$: Observable<string> = this.store.select(selectSsoTokenExpiresAt);
  cognitoUser$: Observable<CognitoUser> = this.store.select(selectCognitoUser);
  userId$: Observable<string> = this.store.select(selectUserId);
  authPageStepPassword$: Observable<boolean> = this.store.select(selectAuthPageStepPassword);
  authPageStepEmail$: Observable<boolean> = this.store.select(selectAuthPageStepEmail);
  authPageRequireNewPassword$: Observable<boolean> = this.store.select(selectAuthPageRequireNewPassword);
  authPageErrorSignIn$: Observable<boolean> = this.store.select(selectAuthPageErrorSignIn);
  authPageErrorEmail$: Observable<boolean> = this.store.select(selectAuthPageErrorEmail);
  authPageErrorPassword$: Observable<string> = this.store.select(selectAuthPageErrorPassword);
  displayEmailPasswordLogin$: Observable<boolean> = this.store.select(selectDisplayEmailPasswordLogin);

  loggedIn: Signal<boolean> = this.store.selectSignal(selectLoggedIn);
  idToken: Signal<string> = this.store.selectSignal(selectIdToken);
  accessToken: Signal<string> = this.store.selectSignal(selectAccessToken);
  selectedBusinessProfileId: Signal<string> = this.store.selectSignal(selectSelectedBusinessProfileId);
  loggedInWithSSO: Signal<boolean> = this.store.selectSignal(selectLoggedInWithSSO);
  refreshToken: Signal<string> = this.store.selectSignal(selectRefreshToken);
  ssoTokenExpiresAt: Signal<string> = this.store.selectSignal(selectSsoTokenExpiresAt);
  cognitoUser: Signal<CognitoUser> = this.store.selectSignal(selectCognitoUser);
  userId: Signal<string> = this.store.selectSignal(selectUserId);
  authPageStepPassword: Signal<boolean> = this.store.selectSignal(selectAuthPageStepPassword);
  authPageStepEmail: Signal<boolean> = this.store.selectSignal(selectAuthPageStepEmail);
  authPageRequireNewPassword: Signal<boolean> = this.store.selectSignal(selectAuthPageRequireNewPassword);
  authPageErrorSignIn: Signal<boolean> = this.store.selectSignal(selectAuthPageErrorSignIn);
  authPageErrorEmail: Signal<boolean> = this.store.selectSignal(selectAuthPageErrorEmail);
  authPageErrorPassword: Signal<string> = this.store.selectSignal(selectAuthPageErrorPassword);
  displayEmailPasswordLogin: Signal<boolean> = this.store.selectSignal(selectDisplayEmailPasswordLogin);

  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  constructor() {
    super();
    this.authPending$ = this.store.select(selectAuthPagePending);
    this.authPending = this.store.selectSignal(selectAuthPagePending);
    this.currentUser$ = this.store.select(selectCurrentUser);
    this.currentUser = this.store.selectSignal(selectCurrentUser);
    this.privileges$ = this.store.select(selectPrivileges);
    this.privileges = this.store.selectSignal(selectPrivileges);
    this.selectedBusinessProfile$ = this.store.select(selectSelectedBusinessProfileForAccount);
    this.selectedBusinessProfile = this.store.selectSignal(selectSelectedBusinessProfileForAccount);
    this.businessProfiles$ = this.store.select(selectBusinessProfilesForAccount);
    this.businessProfiles = this.store.selectSignal(selectBusinessProfilesForAccount);
    this.selectedEntityForSession$ = this.store.select(selectSelectedEntityForSession);
    this.selectedEntityForSession = this.store.selectSignal(selectSelectedEntityForSession);
    this.canUpdateBusinessProfile = computed(() => {
      this.privileges();
      return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    });

    this.listenForBusinessProfileChanges();
    this.initEligibleBusinessProfiles();
  }

  listenForBusinessProfileChanges(): void {
    this.isBusinessProfileChanged$ = combineLatest([this.selectedBusinessProfile$, this.store.select(selectSelectedBusinessProfileForAccount)]).pipe(
      map(([oldBp, newBp]) => oldBp && newBp && oldBp.id !== newBp.id)
    );
    this.isBusinessProfileChanged = toSignal(
      this.isBusinessProfileChanged$.pipe(
        // Should add some delay otherwise the signal will take always the last value
        delay(100)
      )
    );
  }

  initEligibleBusinessProfiles(): void {
    this.eligibleBusinessProfiles$ = this.businessProfiles$.pipe(
      map((businessProfiles: BusinessProfile[]) => businessProfiles && businessProfiles.filter((bp) => bp.level === 2))
    );
    this.eligibleBusinessProfiles = toSignal(this.eligibleBusinessProfiles$);
  }

  loadPrivilegesSuccess(privileges: { [key: string]: string[] }): void {
    this.store.dispatch(AuthApiActions.loadPrivilegesSuccess({ privileges }));
  }

  selectBusinessProfile(selectedBusinessProfile: BusinessProfile, withRedirect: boolean): void {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile,
        withRedirect
      })
    );
  }

  signOut(): void {
    this.store.dispatch(AuthPageActions.signOut());
  }
}
