import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  EnvironmentInjector,
  inject,
  Injector,
  input,
  OnInit,
  runInInjectionContext,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimelineEventType } from '@iot-platform/models/qlixbi';
import { RestrictInputDirective } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { FormControlType } from '../form-control-type.model';
import { FormField } from '../form-field.model';

@Component({
  selector: 'iot-platform-ui-form-field',
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    RestrictInputDirective,
    TranslateModule,
    NgTemplateOutlet,
    MatTooltipModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCheckbox
  ],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss'
})
export class FormFieldComponent implements OnInit {
  // Workaround the following error
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly environmentInjector: EnvironmentInjector = inject(EnvironmentInjector);
  private readonly TimelineEventType = TimelineEventType;
  control = input<UntypedFormControl>();
  field = input<FormField>();
  FormControlType = FormControlType;
  trackByFn: (index: number, item: unknown) => unknown;
  displayByFn: (item: unknown) => string;
  defaultDebounceTime = 300;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  autocompleteFilteredOptions: WritableSignal<any[]> = signal([]);
  autocompleteSelectedItems: Signal<any[]> = computed(() => this.autocompleteFilteredOptions().filter((item) => item.selected));
  controlValueChange: Signal<any>;
  _required$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line no-underscore-dangle
  required$: Observable<boolean> = this._required$.asObservable();
  // Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'false'. Current value: 'true'.
  required = toSignal(this.required$.pipe(delay(0), takeUntilDestroyed(this.destroyRef)));

  constructor() {
    effect(() => {
      const field = this.field();
      // eslint-disable-next-line no-underscore-dangle
      this._required$.next(field?.required?.() ?? false);
    });
  }

  ngOnInit() {
    runInInjectionContext(this.environmentInjector, () => {
      const control = this.control();
      const field = this.field();
      if (control && field) {
        this.trackByFn = this.trackBy.bind(this);
        this.displayByFn = this.autoCompleteDisplayWrapper.bind(this);

        this.initRequiredEffect();
        this.initDisabledEffect();
        this.initInitialItemEffect();

        if (field?.type === FormControlType.AUTO_COMPLETE) {
          this.controlValueChange = toSignal(
            control?.valueChanges?.pipe(
              debounceTime(field?.debounceTime?.() ?? this.defaultDebounceTime),
              filter((term) => typeof term === 'string'),
              tap((term: string) => {
                if (!term?.length || (field?.minLength && term.length < field.minLength())) {
                  this.clearAutocomplete();
                }
              }),
              filter((term: string) => {
                if (field?.minLength) {
                  return term?.length >= field.minLength();
                }
                return true;
              }),
              tap((value) => field?.valueChange?.(value)),
              distinctUntilChanged(),
              takeUntilDestroyed(this.destroyRef)
            )
          );
          this.filterAutocompleteEffects();
          this.initAutoCompleteItemsEffects();
        } else {
          this.controlValueChange = toSignal(
            control.valueChanges.pipe(
              debounceTime(field.debounceTime?.() ?? this.defaultDebounceTime),
              tap((value) => field.valueChange?.(value)),
              distinctUntilChanged(),
              takeUntilDestroyed(this.destroyRef)
            )
          );
        }
      }
    });
  }

  trackBy = (_: number, item: unknown) => this.field()?.trackBy?.(item);

  onUndoBtnClicked(): void {
    const field = this.field();
    const control = this.control();
    control?.setValue(field?.undo?.initialValue?.());
    field?.undo?.onClick?.(field);
  }

  toggleAutoCompleteSelection(item: any): void {
    item.selected = !item.selected;
    this.autocompleteFilteredOptions.update((autocompleteFilteredOptions) => {
      const index = autocompleteFilteredOptions.findIndex((option) => option === item);
      if (index !== -1) {
        autocompleteFilteredOptions[index].selected = item.selected;
      }
      return [...autocompleteFilteredOptions];
    });
    this.field()?.selectionChange?.({ option: { value: item } });
  }

  filterAutocomplete(searchTerm: unknown): void {
    const value = searchTerm !== null && searchTerm !== undefined ? searchTerm : '';
    const values = (this.field()?.items?.() as any[])?.filter((item: any) => {
      const searchStr = typeof value === 'string' ? value : JSON.stringify(value);
      return JSON.stringify(item)?.toLowerCase().indexOf(searchStr?.toLowerCase()) !== -1;
    });
    this.autocompleteFilteredOptions.set(values);
  }

  clearAutocomplete($event?: any): void {
    $event?.stopPropagation?.();
    this.control()?.reset?.();
    this.inputAutoComplete?.nativeElement?.focus();
    this.field()?.onReset?.();
  }

  onAutoCompleteOptionsSelected(event: any): void {
    const field = this.field();
    const control = this.control();
    control?.setValue(event?.option?.value);
    if (!field?.multiple?.()) {
      // For multi select the event will be triggered in this method toggleAutoCompleteSelection
      field?.selectionChange?.(event);
    }
    if (field?.clearOnSelect?.()) {
      this.clearAutocomplete();
    }
  }

  private initDisabledEffect() {
    effect(
      () => {
        const field = this.field();
        const control = this.control();
        const disabled = field?.disabled;
        if (disabled && (!field?.disableOnlyOptions || !field?.disableOnlyOptions?.())) {
          if (disabled()) {
            control?.disable?.();
          } else {
            control?.enable?.();
          }
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initRequiredEffect() {
    effect(
      () => {
        const field = this.field();
        const control = this.control();
        const required = field?.required;
        if (required) {
          if (required()) {
            control?.addValidators?.(Validators.required);
          } else {
            control?.removeValidators?.(Validators.required);
          }
          control?.updateValueAndValidity?.();
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initInitialItemEffect() {
    effect(
      () => {
        const field = this.field();
        const control = this.control();
        if (field?.initialItem) {
          const initialItem = field.initialItem();
          control?.setValue(initialItem);
          if (initialItem !== undefined && field.type === FormControlType.AUTO_COMPLETE) {
            field?.selectionChange?.({ option: { value: initialItem } });
          }
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private filterAutocompleteEffects() {
    effect(
      () => {
        const value = this.controlValueChange();
        this.filterAutocomplete(value);
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initAutoCompleteItemsEffects() {
    effect(
      () => {
        this.field()?.items?.();
        this.filterAutocomplete(null);
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private autoCompleteDisplayWrapper(item: unknown) {
    return this.field()?.displayBy?.(item) as string;
  }
}
