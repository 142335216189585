import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetModelName } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageSelectorService {
  constructor(private http: HttpClient) {}

  getGalleryByImageCategory(galleryPath: string, imageCategory?: string, excludedImages: string[] = []): Observable<string[]> {
    return this.http.get<string[]>(`assets/data/` + galleryPath).pipe(
      map((data) => {
        let gallery = data;
        if (imageCategory) {
          gallery = gallery.reduce((acc: string[], imagePath: string) => {
            const imageName = imagePath.split('/').pop();
            const category = imageName?.split('_').shift();
            if (
              !category ||
              imageName?.includes(`${imageCategory}_general`) ||
              imageName?.includes('default') ||
              category === imageCategory ||
              (imageCategory === AssetModelName.BLANK && category === 'asset')
            ) {
              acc.push(imagePath);
            }
            return acc;
          }, []);
        }
        return gallery.filter((imagePath: string) => !excludedImages.some((excludedImage: string) => imagePath.split('/').pop()?.includes(excludedImage)));
      })
    );
  }
}
