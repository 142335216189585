<h2 class="login-regular-title">{{ 'LOGIN.CHANGE_PASSWORD.TITLE' | translate }}</h2>

<form [formGroup]="form" fxLayout="column">
  <mat-form-field color="accent" fxFlex="100%">
    <mat-icon matIconPrefix>verified_user</mat-icon>
    <mat-label>{{ 'LOGIN.CODE' | translate }}</mat-label>
    <input formControlName="code" matInput type="text" />
    <mat-icon
      (click)="onSendCode($event)"
      [matTooltip]="'LOGIN.TOOLTIP' | translate"
      class="layout-pointer-cursor layout-button-rotation"
      matIconSuffix
      matTooltipClass="regular-tooltip"
    >replay
    </mat-icon>
    @if (codeControl.invalid) {
      <mat-error>{{ 'LOGIN.ERROR_MESSAGE.CODE' | translate }}</mat-error>
    }
    <mat-hint>{{ 'LOGIN.HELP_MESSAGE.CHECK_EMAIL' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field color="accent" fxFlex="100%">
    <mat-icon matIconPrefix>lock</mat-icon>
    <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
    <input (keyup)="sendPasswordToRules(passwordControl.value)" formControlName="password" matInput type="password" />
    @if (passwordControl.invalid) {
      <mat-error>{{ 'LOGIN.ERROR_MESSAGE.PASSWORD' | translate }}</mat-error>
    }
  </mat-form-field>

  <mat-form-field color="accent" fxFlex="100%">
    <mat-icon matIconPrefix>lock</mat-icon>
    <mat-label>{{ 'LOGIN.CONFIRM_PASSWORD' | translate }}</mat-label>
    <input formControlName="confirmPassword" matInput type="password" />
    @if (confirmPasswordControl.invalid) {
      <mat-error>{{ 'LOGIN.ERROR_MESSAGE.CONFIRM_PASSWORD' | translate }}</mat-error>
    }
  </mat-form-field>

  <iot-platform-login-password-rules [(password)]="password" />

  <div [class.error]="hasError()" class="layout-login-error" fxLayout="column">
    <em>{{ 'LOGIN.ERROR_MESSAGE.RESETTING_PASSWORD' | translate }}</em>
    <span>{{ hasError()?.message }}</span>
  </div>

  <div class="layout-login-form-actions">
    <button (click)="onCancel()" class="layout-login-button" mat-button
            type="button">{{ 'LOGIN.BUTTON.CANCEL' | translate }}
    </button>
    <iot-platform-ui-progress-bar-button
      (clickEvent)="onChangePassword()"
      [disabled]="!form.valid"
      [enableGauge]="enableGauge()"
      [title]="'LOGIN.BUTTON.CONFIRM' | translate"
      type="submit"
    />
  </div>
</form>
