<form [formGroup]="form" class="tag-editor-form-container" fxFlex fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    @if (mode() === 'UPDATE') {
      <button
        (click)="onRemoveTagCategory()"
        [matTooltip]="'TAG_EDITOR.REMOVE' | translate"
        class="regular-round-button"
        color="warn"
        mat-icon-button
        matTooltipClass="warning-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon>delete</mat-icon>
      </button>
    }

    <mat-form-field color="accent" fxFlex="70px">
      <mat-label>{{ 'TAG_EDITOR.COLOR' | translate }}</mat-label>
      <mat-select [value]="tagCategory()?.color" (selectionChange)="onColorChange($event.value)" formControlName="color" required>
        <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
          <span [style.background-color]="tagCategory()?.color" class="tag-category-color-round"></span>
        </mat-select-trigger>
        @for (tagColor of colors(); track $index) {
          <mat-option [value]="tagColor">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <span [style.background-color]="tagColor" class="tag-category-color-round"></span>
            </span>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <mat-form-field color="accent" fxFlex="200px">
    <mat-label>{{ 'TAG_EDITOR.CATEGORY_NAME' | translate }}</mat-label>
    <input #categoryInput (keyup.enter)="chipList.focus(); $event.stopPropagation()" formControlName="name" matInput maxlength="20" required />
    @if (name.invalid && name.errors?.nameExists) {
      <mat-error> {{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_CATEGORY' | translate }} </mat-error>
    }
    <mat-hint align="end"
      ><span>{{ categoryInput.value.length || 0 }}/{{ tagNameMaxLength() }}</span></mat-hint
    >
  </mat-form-field>

  <mat-form-field color="accent" fxFlex>
    <mat-label>{{ 'TAG_EDITOR.TAG_NAMES' | translate }}</mat-label>
    <mat-chip-grid #chipList fxLayout="column" formControlName="labels">
      @for (label of tagCategory()?.labels; track $index) {
        <mat-chip-row
          (dblclick)="onEditLabel(label, $index)"
          (removed)="onRemoveLabel($index)"
          [ngStyle]="{ backgroundColor: tagCategory()?.color }"
          [removable]="true"
          [highlighted]="true"
        >
          {{ label.name }}
          <button [attr.aria-label]="label" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        #labelInput
        (input)="labelValue.set(labelInput.value)"
        (matChipInputTokenEnd)="onAddLabel($event)"
        [matChipInputAddOnBlur]="true"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes()"
        maxlength="20"
      />
    </mat-chip-grid>

    <mat-hint align="end" fxLayoutGap="20px">
      <span>{{ labelInput.value.length || 0 }}/{{ tagNameMaxLength() }}</span>
      <span [ngClass]="{ 'max-reached': tagCategory()?.labels?.length === maximumTagPerCategory() }"
        >{{ 'TAG_EDITOR.TAGS_PER_CATEGORY' | translate }} {{ tagCategory()?.labels?.length || 0 }} /{{ maximumTagPerCategory() }}</span
      >
      @if (isLabelDuplicate()) {
        <span class="tag-editor-duplicate-warning">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_LABEL' | translate }}</span>
      }
    </mat-hint>
  </mat-form-field>

  @if (mode() === 'UPDATE') {
    <button (click)="onSave()" [disabled]="form.invalid || form.pending || (form.valid && !form.dirty)" class="button-regular" color="accent" mat-raised-button>
      {{ 'TAG_EDITOR.UPDATE' | translate | uppercase }}
    </button>
  } @else if (mode() === 'ADD') {
    <button (click)="onSave()" [disabled]="form.invalid || form.pending" class="button-regular" color="accent" mat-raised-button>
      {{ 'TAG_EDITOR.ADD' | translate | uppercase }}
    </button>
  }
</form>
