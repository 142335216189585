<grid-engine-header-shell [params]="params()">
  <div [matTooltip]="params()?.headerTooltip | translate"
    class="icon-header-cell"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="above"
    style="text-align: center"
  >
    <mat-icon [svgIcon]="params()?.headerIcon" class="header-icon" />
  </div>
</grid-engine-header-shell>
