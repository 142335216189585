import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const schedulersColumnSettings = [
  {
    order: 1,
    id: 'name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_NAME',
    catalogName: 'SCHEDULER NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigate',
      options: {
        targetType: 'scheduler',
        targetId: 'id'
      }
    },
    width: '30%',
    cellWidth: '200',
    type: 'start center'
  },
  {
    order: 2,
    id: 'command',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_COMMAND',
    catalogName: 'COMMAND',
    default: true,
    sortable: false,
    sortProperty: 'command',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '30%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 3,
    id: 'status',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_STATUS',
    catalogName: 'STATUS',
    default: true,
    sortable: true,
    sortProperty: 'status',
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    cellTypeOptions: {
      withStatusPadding: true,
      translateKey: 'SCHEDULERS.MASTER_VIEW.STATUS.',
      class: {
        active: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
        disabled: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' }
      }
    },
    isLink: false,
    width: '30%',
    cellWidth: '170',
    type: 'start center'
  },
  {
    order: 4,
    id: 'cron',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_CRON',
    catalogName: 'CRON',
    default: false,
    sortable: true,
    sortProperty: 'cron',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '30%',
    cellWidth: '130',
    type: 'start center'
  },
  {
    order: 5,
    id: 'cron',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OCCURRENCE',
    catalogName: 'OCCURRENCE',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.CRON_READABLE_CELL,
    isLink: false,
    cellWidth: '200',
    type: 'start center'
  },
  {
    order: 6,
    id: 'devices.totalCount',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TOTAL_DEVICES',
    catalogName: 'DEVICES',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    width: '30%',
    cellWidth: '80',
    type: 'center center'
  },
  {
    order: 7,
    id: 'entity.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_ENTITY',
    catalogName: 'ENTITY',
    default: true,
    sortable: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    sortProperty: 'entity.name',
    isLink: false,
    width: '30%',
    cellWidth: '200',
    type: 'start center'
  },
  {
    order: 8,
    id: 'timezone',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_TIMEZONE',
    catalogName: 'TIMEZONE',
    default: true,
    sortable: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    sortProperty: 'timezone',
    isLink: false,
    width: '30%',
    cellWidth: '160',
    type: 'start center'
  },
  {
    order: 9,
    id: 'lastRun',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_LAST_RUN',
    catalogName: 'LAST RUN',
    default: true,
    sortable: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    sortProperty: 'lastRun',
    isLink: false,
    width: '30%',
    cellWidth: '165',
    type: 'start center'
  },
  {
    order: 10,
    id: 'nextRun',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SCHEDULER_NEXT_RUN',
    catalogName: 'NEXT RUN',
    default: true,
    sortable: true,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    sortProperty: 'nextRun',
    isLink: false,
    width: '30%',
    cellWidth: '165',
    type: 'start center'
  }
];
