import { EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders, signal, Type } from '@angular/core';
import { LocalStorageService } from '@iot-platform/core';
import { FilterEngineMode, ToolbarPageType } from '@iot-platform/iot-platform-ui';
import { AbstractAuthFacade, BaseFacade, DummyFacade, Filter, Pagination, provideDummyState } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { FeatureMasterViewSettingsFactory } from '../models/master-view-settings-factory.model';
import { FeatureMasterViewDrawerDetailsLayout, FeatureMasterViewSettings } from '../models/master-view-settings.model';
import { AbstractMasterViewDashboardService } from '../services/abstract-master-view-dashboard.service';
import { AbstractMasterViewMapService } from '../services/abstract-master-view-map.service';
import { AbstractMasterViewService } from '../services/abstract-master-view.service';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const FEATURE_MASTER_VIEW_SETTINGS = new InjectionToken<FeatureMasterViewSettings[]>('FEATURE_MASTER_VIEW_SETTINGS');
export const FEATURE_MASTER_VIEW_SERVICE = new InjectionToken<AbstractMasterViewService[]>('FEATURE_MASTER_VIEW_SERVICE');
export const FEATURE_MASTER_VIEW_AUTH_FACADE = new InjectionToken<AbstractAuthFacade[]>('FEATURE_MASTER_VIEW_AUTH_FACADE');
export const FEATURE_MASTER_VIEW_FACADE = new InjectionToken<BaseFacade<any, any, any>[]>('FEATURE_MASTER_VIEW_FACADE');
export const FEATURE_MASTER_VIEW_DASHBOARD_SERVICE = new InjectionToken<AbstractMasterViewDashboardService[]>('FEATURE_MASTER_VIEW_DASHBOARD_SERVICE');
export const FEATURE_MASTER_VIEW_MAP_SERVICE = new InjectionToken<AbstractMasterViewMapService[]>('FEATURE_MASTER_VIEW_MAP_SERVICE');

export const FEATURE_MASTER_VIEW_SETTINGS_FACTORY = new InjectionToken<
  {
    masterViewService: Type<AbstractMasterViewService>;
    authFacade: Type<AbstractAuthFacade>;
    masterViewFacade?: Type<BaseFacade<any, any, any>>;
    settingsFactory: FeatureMasterViewSettingsFactory;
    masterViewDashboardService?: Type<AbstractMasterViewDashboardService>;
    masterViewMapService?: Type<AbstractMasterViewMapService>;
  }[]
>('FEATURE_MASTER_VIEW_SETTINGS_FACTORY');

const getMasterViewSettings = (settings: Partial<FeatureMasterViewSettings>) => {
  const fullSettings: FeatureMasterViewSettings = {
    masterViewName: settings.masterViewName,
    grid: {
      staticDefinitionType: settings?.grid?.staticDefinitionType ?? signal(false),
      hidePaginator: settings?.grid?.hidePaginator ?? signal(false),
      style: settings?.grid?.style ?? signal(null)
    },
    toolbar: {
      enabled: settings?.toolbar?.enabled ?? signal(true),
      toolbarName: settings?.toolbar?.toolbarName ?? signal(''),
      withFavoriteViews: settings?.toolbar?.withFavoriteViews ?? signal(true),
      withGrids: settings?.toolbar?.withGrids ?? signal(true),
      pageTypeOptions: {
        visible: settings?.toolbar?.pageTypeOptions?.visible ?? signal(false),
        defaultPageType: settings?.toolbar?.pageTypeOptions?.defaultPageType ?? signal(ToolbarPageType.GRID),
        options: settings?.toolbar?.pageTypeOptions?.options ?? signal([])
      }
    },
    sectionHeader: {
      enabled: settings?.sectionHeader?.enabled ?? signal(false),
      title: settings?.sectionHeader?.title ?? signal(null),
      icon: settings?.sectionHeader?.icon ?? signal(null),
      svgIcon: settings?.sectionHeader?.svgIcon ?? signal(null),
      cssClassName: settings?.sectionHeader?.cssClassName ?? signal(null),
      styles: settings?.sectionHeader?.styles ?? signal(null),
      actions: settings?.sectionHeader?.actions ?? signal([])
    },
    filterEngine: {
      enabled: settings?.filterEngine?.enabled ?? signal(true),
      displayActionButtons: settings?.filterEngine?.displayActionButtons ?? signal(true),
      withFavoriteFilters: settings?.filterEngine?.withFavoriteFilters ?? signal(true),
      clearOnDestroy: settings?.filterEngine?.clearOnDestroy ?? signal(false),
      mode: settings?.filterEngine?.mode ?? signal(FilterEngineMode.CLASSIC)
    },
    drawerDetails: {
      enabled: settings?.drawerDetails?.enabled ?? signal(false),
      position: settings?.drawerDetails?.position ?? signal('start'),
      mode: settings?.drawerDetails?.mode ?? signal('side'),
      opened: settings?.drawerDetails?.opened ?? signal(false),
      openedOnDestroy: settings?.drawerDetails?.openedOnDestroy ?? signal(false),
      toggle: settings?.drawerDetails?.toggle ?? signal(false),
      hasBackdrop: settings?.drawerDetails?.hasBackdrop ?? signal(false),
      closeOnBackdropClick: settings?.drawerDetails?.closeOnBackdropClick ?? signal(false),
      customOpeningEventType: settings?.drawerDetails?.customOpeningEventType ?? signal(null),
      componentRef: settings?.drawerDetails?.componentRef ?? signal(null),
      layout: settings?.drawerDetails?.layout ?? signal(FeatureMasterViewDrawerDetailsLayout.GRID)
    },
    dashboard: {
      enabled: settings?.dashboard?.enabled ?? signal(false)
    },
    map: {
      enabled: settings?.map?.enabled ?? signal(false)
    }
  };

  return fullSettings;
};

export function provideFeatureMasterViewSettingsFactory(settings: {
  masterViewService: Type<AbstractMasterViewService>;
  authFacade: Type<AbstractAuthFacade>;
  masterViewFacade?: Type<BaseFacade<any, any, any>>;
  settingsFactory: FeatureMasterViewSettingsFactory;
  masterViewDashboardService?: Type<AbstractMasterViewDashboardService>;
  masterViewMapService?: Type<AbstractMasterViewMapService>;
}): EnvironmentProviders {
  const providers = [
    {
      provide: FEATURE_MASTER_VIEW_SETTINGS_FACTORY,
      useValue: settings,
      multi: true
    },
    {
      provide: FEATURE_MASTER_VIEW_SERVICE,
      useClass: settings.masterViewService,
      multi: true
    },
    {
      provide: FEATURE_MASTER_VIEW_AUTH_FACADE,
      useClass: settings.authFacade,
      multi: true
    },
    {
      provide: FEATURE_MASTER_VIEW_FACADE,
      useClass: settings.masterViewFacade ?? DummyFacade,
      multi: true
    },
    {
      provide: FEATURE_MASTER_VIEW_SETTINGS,
      multi: true,
      useFactory: (
        store: Store,
        storage: LocalStorageService,
        authFacades: AbstractAuthFacade[],
        masterViewFacades: BaseFacade<any, any, any>[],
        masterViewServices: AbstractMasterViewService[]
      ): Partial<FeatureMasterViewSettings> => {
        const authFacade = authFacades.find((item) => settings.authFacade && item instanceof settings.authFacade) ?? authFacades[0];
        const masterViewFacade =
          masterViewFacades.find((item) => settings.masterViewFacade && item instanceof settings.masterViewFacade) ?? masterViewFacades[0];
        const masterViewService =
          masterViewServices.find((item) => settings.masterViewService && item instanceof settings.masterViewService) ?? masterViewServices[0];
        const factorySettings = settings.settingsFactory({
          store,
          storage,
          authFacade,
          masterViewFacade,
          masterViewService
        });
        return getMasterViewSettings(factorySettings);
      },
      deps: [Store, LocalStorageService, FEATURE_MASTER_VIEW_AUTH_FACADE, FEATURE_MASTER_VIEW_FACADE, FEATURE_MASTER_VIEW_SERVICE]
    },
    !settings.masterViewFacade ? [provideDummyState()] : [],
    settings.masterViewDashboardService
      ? { provide: FEATURE_MASTER_VIEW_DASHBOARD_SERVICE, useClass: settings.masterViewDashboardService, multi: true }
      : { provide: FEATURE_MASTER_VIEW_DASHBOARD_SERVICE, useValue: {}, multi: true },
    settings.masterViewMapService
      ? { provide: FEATURE_MASTER_VIEW_MAP_SERVICE, useClass: settings.masterViewMapService, multi: true }
      : { provide: FEATURE_MASTER_VIEW_MAP_SERVICE, useValue: {}, multi: true }
  ];

  return makeEnvironmentProviders(providers);
}

export function provideFeatureMasterViewTestingSettingsFactory(settings: {
  settings: Partial<FeatureMasterViewSettings>;
  masterViewService: any;
  authFacade: any;
  masterViewFacade?: any;
  masterViewDashboardService?: any;
  masterViewMapService?: any;
}): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FEATURE_MASTER_VIEW_SETTINGS_FACTORY,
      useValue: [
        {
          authFacade: AbstractAuthFacade,
          masterViewFacade: BaseFacade<any, any, any>,
          masterViewService: AbstractMasterViewService,
          settingsFactory: () => getMasterViewSettings(settings.settings),
          masterViewDashboardService: AbstractMasterViewDashboardService,
          masterViewMapService: AbstractMasterViewDashboardService
        }
      ]
    },
    {
      provide: FEATURE_MASTER_VIEW_SERVICE,
      useValue: [settings.masterViewService]
    },
    {
      provide: FEATURE_MASTER_VIEW_AUTH_FACADE,
      useValue: [settings.authFacade]
    },
    {
      provide: FEATURE_MASTER_VIEW_SETTINGS,
      useValue: [getMasterViewSettings(settings.settings)]
    },
    {
      provide: FEATURE_MASTER_VIEW_DASHBOARD_SERVICE,
      useValue: [settings.masterViewDashboardService]
    },
    {
      provide: FEATURE_MASTER_VIEW_MAP_SERVICE,
      useValue: [settings.masterViewMapService]
    },
    !settings.masterViewFacade
      ? [
          provideDummyState(),
          {
            provide: FEATURE_MASTER_VIEW_FACADE,
            useValue: [
              {
                all: signal([]),
                platformResponse: signal({ data: [] }),
                total: signal(0),
                loaded: signal(true),
                loading: signal(false),
                filters: signal([]),
                pagination: signal(null),
                getAll: () => noop(),
                setFilters: () => noop()
              }
            ]
          }
        ]
      : {
          provide: FEATURE_MASTER_VIEW_FACADE,
          useValue: [settings.masterViewFacade]
        }
  ]);
}

export const getMasterViewInjectionTokens = (masterView: string) => {
  const allAuthFacades: AbstractAuthFacade[] = inject(FEATURE_MASTER_VIEW_AUTH_FACADE);
  const allMasterViewServices: AbstractMasterViewService[] = inject(FEATURE_MASTER_VIEW_SERVICE);
  const allMasterViewDashboardServices: AbstractMasterViewDashboardService[] = inject(FEATURE_MASTER_VIEW_DASHBOARD_SERVICE);
  const allMasterViewMapServices: AbstractMasterViewMapService[] = inject(FEATURE_MASTER_VIEW_MAP_SERVICE);

  const allFacades: BaseFacade<any, any, any>[] = inject(FEATURE_MASTER_VIEW_FACADE) as BaseFacade<any, Pagination, Filter>[];
  const allSettings: FeatureMasterViewSettings[] = inject(FEATURE_MASTER_VIEW_SETTINGS);
  const allSettingsFactories = inject(FEATURE_MASTER_VIEW_SETTINGS_FACTORY);
  const storage = inject(LocalStorageService);
  const store = inject(Store);

  const settingsFactories = allSettingsFactories.map((sf) => {
    const masterViewService = allMasterViewServices.find((item) => sf.masterViewService && item instanceof sf.masterViewService) ?? allMasterViewServices[0];
    const masterViewFacade = allFacades.find((item) => sf.masterViewFacade && item instanceof sf.masterViewFacade) ?? allFacades[0];
    const authFacade = allAuthFacades.find((item) => sf.authFacade && item instanceof sf.authFacade) ?? allAuthFacades[0];
    const masterViewDashboardService =
      allMasterViewDashboardServices.find((item) => sf.masterViewDashboardService && item instanceof sf.masterViewDashboardService) ??
      allMasterViewDashboardServices[0];
    const masterViewMapService =
      allMasterViewMapServices.find((item) => sf.masterViewMapService && item instanceof sf.masterViewMapService) ?? allMasterViewMapServices[0];

    const { masterViewName } = sf.settingsFactory({
      store,
      storage,
      authFacade,
      masterViewFacade,
      masterViewService
    });
    return {
      masterViewName: masterViewName(),
      settings: allSettings.find((item) => item.masterViewName() === masterViewName()) ?? allSettings[0],
      masterViewService,
      masterViewFacade,
      authFacade,
      masterViewDashboardService,
      masterViewMapService
    };
  });

  const settingsFactory = settingsFactories.find(({ masterViewName }) => masterViewName === masterView);
  return settingsFactory ?? settingsFactories[0];
};
