<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'DASHBOARDS.DATA_TABLE_DIALOG.TITLE' | translate }}</h1>
      <button (click)="exit()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20 dashboard-data-table-dialog--table-body" fxLayout="column">
    <table class="full-width fs-12">
      <thead>
        <th class="dashboard-data-table-dialog--title-header">{{ data.title }}</th>
        <th class="dashboard-data-table-dialog--count-header">{{ data.total | numberFormat }}</th>
      </thead>
      <tbody class="">
        @for (item of data.data; track item.name; let e = $even) {
          <tr [ngClass]="{ 'dashboard-data-table-dialog--even-row': e }">
            @if (data.showLegends) {
              <td>{{ item.name }}</td>
            } @else {
              <td><span class="dashboard-data-table-dialog--color-cell" [style]="{ 'background-color': item.color ?? '#B0B0B0' }"></span></td>
            }
            <td class="dashboard-data-table-dialog--count-cell">
              {{ item.count | numberFormat }}&nbsp;({{ (item.count * 100) / data.total | numberFormat }}%)
            </td>
          </tr>
        }
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
