<div fxLayoutAlign="start center">
  @if (params()?.value === null || params()?.value === undefined) {
    <span class="number-cell-value">-</span>
  } @else if (!params()?.isLink && !params()?.unit) {
    <span class="number-cell-value" [matTooltip]="params()?.value | numberFormat" matTooltipClass="regular-tooltip"
          matTooltipPosition="above">{{
        params()?.value | numberFormat
      }}</span>
  }
</div>
