import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'grid-engine-followed-number-cell',
  templateUrl: './followed-number-cell.component.html',
  imports: [MatTooltip, MatIcon],
  styleUrls: ['./followed-number-cell.component.scss']
})
export class FollowedNumberCellComponent implements ICellRendererAngularComp {
  private readonly translateService: TranslateService = inject(TranslateService);

  params: WritableSignal<ICellRendererParams> = signal(null);
  tooltip: Signal<string> = computed(() => {
    const params = this.params();
    if (params?.value !== null) {
      return this.translateService.instant('ASSETS.VARIABLE.FOLLOWED.CARD_TITLE', { followedRank: params.value });
    }
    return '';
  });

  agInit(params: ICellRendererParams) {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
