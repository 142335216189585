import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
  selector: 'i4b-table-engine-asset-variable-cell',
  templateUrl: './asset-variable-cell.component.html',
  styleUrls: ['./asset-variable-cell.component.scss']
})
export class AssetVariableCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() columnId: string;
  @Input() clickEvent: any;
  @Input() cellOptions: any;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    this.dispatchEvent.emit({
      type: this.clickEvent.type,
      options: this.clickEvent.options,
      rawData: this.rawData
    });
  }
}
