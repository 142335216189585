import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe } from '@iot-platform/pipes';

@Component({
  standalone: true,
  selector: 'iot-platform-ui-variable-item',
  templateUrl: './variable-item.component.html',
  imports: [FlexLayoutModule, MatCheckbox, DateFormatPipe],
  providers: [DateFormatPipe],
  styleUrls: ['./variable-item.component.scss']
})
export class VariableItemComponent {
  variable = input<DeviceVariable | AssetVariable>();
  selected = input<boolean>(false);
  locked = input<boolean>(false);

  toggle = output<any>();

  onChange(event: MatCheckboxChange): void {
    this.toggle.emit(event.checked);
  }
}
