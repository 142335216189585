<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.TITLE_USER_POPUP' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content">
    <form [formGroup]="columnsForm" class="p-20" fxFlex="calc(100% - 20px)" fxLayout="row" fxLayout.lt-md="column"
          fxLayoutGap="10px">
      <div fxFlex="20" fxFlex.lt-md="100%" fxLayout="column" fxLayout.gt-md="row wrap" fxLayoutAlign="space-between ">
        <section fxFlex fxLayout="column" fxLayoutGap="10px">
          @if (!data.isAdminMode) {
            <mat-radio-group (change)="onNewGridChange($event)" [value]="isNewGrid()" color="accent" fxLayout="column">
              @if (allGrids().length > 0) {
                <mat-radio-button [value]="false">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.UPDATE_MY_GRIDS' | translate }}
                </mat-radio-button>
              }
              <mat-radio-button [value]="true">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.ADD_NEW_GRID' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          }
          <div fxLayout="column" fxLayoutGap="10px">
            @if (!isNewGrid() && !data.isAdminMode) {
              <mat-form-field color="accent" fxFlex>
                <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SELECT_A_GRID' | translate }}</mat-label>
                <mat-select (selectionChange)="onSelectGridToUpdate($event)" formControlName="gridToUpdate">
                  <mat-select-trigger>
                    <span>{{ gridToUpdate.value?.name }}</span>
                  </mat-select-trigger>
                  @for (grid of allGrids(); track grid.id) {
                    <mat-option [value]="grid">
                      <span class="grid-select-item" fxFlex title="{{ grid.name }}">
                        <span>{{ grid.name }}</span>
                        <span
                          class="select-grid-is-default">{{ grid.isDefault ? ('GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_GRID' | translate) : '' }}</span>
                      </span>
                      <mat-icon class="icon-centered">{{ grid.businessProfileId ? 'groups' : 'person' }}</mat-icon>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.NAME' | translate }}</mat-label>
              <input #nameInput formControlName="name" matInput maxlength="50"
                     placeholder="{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.NAME' | translate }}" required />
              <mat-hint align="end">{{ nameInput.value?.length || 0 }}/50</mat-hint>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>{{ 'GRID_ENGINE.DATA_LIBRARIES' | translate }}</mat-label>
              <mat-select (selectionChange)="onLibraryChange($event)" formControlName="selectedLibrary">
                @for (datalib of selectedViewDef()?.dataLibraries; track datalib) {
                  <mat-option [value]="datalib">
                    {{ 'GRID_ENGINE.CONCEPT_LIST.' + datalib.libraryName | translate }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </section>
        <section fxFlex fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <mat-form-field color="accent" fxFlex="48%" fxFlex.lt-sm="100%">
              <mat-label>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.PAGINATION' | translate }}</mat-label>
              <mat-select formControlName="pageSize">
                @for (pageSize of gridPageSizeList; track pageSize) {
                  <mat-option [value]="pageSize">{{ pageSize }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field color="accent" fxFlex="48%" fxFlex.lt-sm="100%">
              <mat-label>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.AUTO_REFRESH' | translate }}</mat-label>
              <mat-select formControlName="autoRefreshDelay">
                <mat-option [value]="0">{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.NO_REFRESH' | translate }}
                </mat-option>
                @for (delay of delayList; track delay) {
                  <mat-option [value]="delay">{{ delay }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (!data.isAdminMode) {
            <mat-slide-toggle
              [disabled]="!!gridToUpdate.value?.businessProfileId || isShared.value"
              color="accent"
              formControlName="isDefault"
              fxLayout="row wrap"
            >
              <div class="slide-toggle-label">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SET_AS_DEFAULT_GRID' | translate }}
              </div>
            </mat-slide-toggle>
          }
          @if (canUpdateBp()) {
            <div class="share-grid" fxFlex>
              <mat-slide-toggle
                [disabled]="!!gridToUpdate.value?.businessProfileId || gridToUpdate.value?.isDefault || isDefault.value"
                color="accent"
                formControlName="isShared"
              >
                <div class="slide-toggle-label">
                  {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SHARE_WITHIN_BP' | translate: { bpName: selectedBusinessProfile()?.name } }}
                </div>
              </mat-slide-toggle>
              <mat-error
                class="share-warning-message">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SHARE_WARNING' | translate }}
              </mat-error>
            </div>
          }
        </section>
      </div>
      <div fxFlex="80" fxFlex.lt-md="100%" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div fxFlex="38" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutGap="10px">
          <p>
            {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.AVAILABLE_COLUMNS' | translate: { totalColumns: availableColumns()?.length ?? '' } }}
          </p>
          <div
            #availableColumnsList="cdkDropList"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListConnectedTo]="[selectedColumnsList]"
            [cdkDropListData]="availableColumns()"
            cdkDropList
            class="dd-list"
          >
            @for (column of availableColumns(); track $index) {
              <div [cdkDragData]="column" cdkDrag cdkDragHandle class="accordeon-item dd-item"
                   fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxLayoutAlign="start center" fxFlex="80" fxLayoutGap="10px">
                  <p class="concept"
                     fxFlex="34">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                  <p fxFlex="64">{{ column.header?.displayName }}</p>
                </div>
                <button fxFlex (click)="onAddSelectedColumn($event, column)" mat-icon-button>
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            }
          </div>
        </div>
        <div fxFlex="58" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutGap="10px">
          <div fxLayoutAlign="space-between center">
            <p>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SELECTED_COLUMNS' | translate: { totalColumns: selectedColumns().length ?? '' } }}</p>
            <p (click)="setDefaultView()"
               class="link">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_COLUMNS' | translate }}</p>
          </div>
          <div
            #selectedColumnsList="cdkDropList"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListConnectedTo]="[availableColumnsList]"
            [cdkDropListData]="selectedColumns()"
            cdkDropList
            class="dd-list"
          >
            @for (column of selectedColumns(); track index; let index = $index) {
              <mat-expansion-panel [cdkDragData]="column" cdkDrag cdkDragLockAxis="y" fxLayout="column">
                <mat-expansion-panel-header class="accordeon-item" fxLayoutGap="10px">
                  <div fxFlex fxLayoutAlign="start center">
                    <mat-panel-title fxFlex="30" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <p>{{ index + 1 }}</p>
                      <p
                        class="concept">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                    </mat-panel-title>
                    <mat-panel-description fxFlex="grow" fxLayoutAlign="start center">
                      {{ getHeaderValue(column) }}
                    </mat-panel-description>
                    @if (!!column.options.sort) {
                      <mat-panel-description class="sort-summary" fxLayoutAlign="start center">
                        {{ getSortValue(column.options.sort) !== null ? getSortValue(column.options.sort) + 1 : null }}
                        <mat-icon>{{ column.options.sort.sort === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
                      </mat-panel-description>
                    }
                    <button (click)="onRemoveSelectedColumn($event, column)" color="warn" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                    </button>
                    <mat-icon cdkDragHandle class="dd-handle-icon" color="accent">drag_indicator</mat-icon>
                  </div>
                </mat-expansion-panel-header>
                <div fxLayout="column">
                  @if (column?.configuration?.isConfigurable) {
                    <div class="dd-item--expanded-area" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-form-field color="accent" fxFlex>
                        <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.CUSTOM_HEADER' | translate }}</mat-label>
                        <input #customHeader [value]="column.options.overrideHeaderName ?? null" matInput />
                      </mat-form-field>
                      <button
                        (click)="applyNewHeader(column, customHeader.value.trim())"
                        [disabled]="!customHeader.value.trim() || customHeader.value === column.options.overrideHeaderName"
                        color="accent"
                        mat-button
                        type="button"
                      >
                        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                      </button>
                      <button (click)="resetHeaderName(column)" color="warn" mat-icon-button>
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  }
                  @if (!column?.configuration?.children) {
                    <div class="dd-item--expanded-area" fxLayout="row" fxLayoutAlign="space-between center"
                         fxLayoutGap="10px">
                      <mat-form-field color="accent" fxFlex="35">
                        <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SORT_PRIORITY' | translate }}</mat-label>
                        <mat-select #sortOrder [value]="getSortValue(column.options.sort)">
                          @for (sortOrder of [0, 1, 2]; track sortOrder) {
                            <mat-option [disabled]="disableSelectedColumnsForSort(sortOrder)" [value]="sortOrder">
                              {{ sortOrder + 1 }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field color="accent" fxFlex="35">
                        <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SORT_DIRECTION' | translate }}</mat-label>
                        <mat-select #sortType [value]="column.options.sort?.sort ?? 'asc'">
                          @for (sortType of ['asc', 'desc']; track sortType) {
                            <mat-option
                              [value]="sortType">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SORT_' + sortType.toUpperCase() | translate }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <button
                        (click)="applySortPreferences(column, sortOrder.value, sortType.value)"
                        [disabled]="sortOrder.value === null"
                        color="accent"
                        mat-button
                        type="button"
                      >
                        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                      </button>
                      <button (click)="resetGridSort(column)" [disabled]="!column.options.sort" color="warn"
                              mat-icon-button>
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  }
                  @if (column?.configuration?.concept === I4bCellConcept.GROUPED_VARIABLES ||
                  column?.configuration?.concept === I4bCellConcept.FOLLOWED_VARIABLES ||
                  column?.configuration?.concept === I4bCellConcept.DIAGNOSTIC_VARIABLES) {
                    <div class="dd-item--expanded-area dd-item--variable-group" fxLayout="row"
                         fxLayoutAlign="start center" fxLayoutGap="20px">
                      <mat-slide-toggle
                        (change)="column.options!['displayDateTime'] = $event.checked"
                        [checked]="column?.options?.['displayDateTime']"
                        color="accent"
                        fxLayout="row wrap"
                      >
                        <div
                          class="slide-toggle-label">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DISPLAY_DATE_TIME' | translate }}
                        </div>
                      </mat-slide-toggle>
                      @if (!!column.options['displayThresholds']) {
                        <mat-form-field color="accent" fxFlex="30%">
                          <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DISPLAY_THRESHOLDS' | translate }}</mat-label>
                          <mat-select
                            [formControlName]="column.columnId + column.header.displayName + '-displayThresholds'"
                            multiple>
                            @for (thresholdPosition of thresholdList; track thresholdPosition) {
                              <mat-option
                                (onSelectionChange)="column.options['displayThresholds'][thresholdPosition] = $event.source.selected"
                                [id]="thresholdPosition.toString(10)"
                                [value]="thresholdPosition"
                              >{{ thresholdPosition }}
                              </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      }
                    </div>
                  }
                </div>
              </mat-expansion-panel>
            }
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    @if (notEnoughRights()) {
      <p class="submission-disabled-message" fxFlex>
        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.NOT_ENOUGH_RIGHTS_MESSAGE' | translate }}
      </p>
    }
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    @if (!data.isAdminMode) {
      <button (click)="openConfirmPopup()" [disabled]="isDeleteDisabled()" class="button-regular delete-btn"
              color="warn" mat-button>
        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DELETE_GRID' | translate }}
      </button>
    }
    <button (click)="save()" [disabled]="isSubmissionDisabled()" class="button-regular save-btn" color="accent"
            mat-raised-button>
      {{ actionButtonLabel() | translate }}
    </button>
  </mat-card-actions>
</mat-card>
