<mat-card class="dialog-card optional-properties">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ASSETS.MANAGE_PROPERTIES_FORM.TITLE' | translate: { assetName: data.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="column">
    <div class="optional-properties__label" fxFlex>
      {{ 'ASSETS.MANAGE_PROPERTIES_FORM.LABEL' | translate }}
    </div>
    <div class="optional-properties__options" fxFlexFill fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start">
      @for(propertie of properties; track propertie) {
      <div fxFlex="33%" fxFlex.xs="100%" fxLayoutGap="20px">
        <mat-checkbox (change)="onSelectionChange($event)" [checked]="propertie.value" [value]="propertie.key" fxFlex>{{
          'ASSETS.MANAGE_PROPERTIES_FORM.' + propertie.key | translate
        }}</mat-checkbox>
      </div>
      }
    </div>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!isPropertiesEdited" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
