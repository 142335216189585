@if (device) {
  <div class="device-info-wrapper">
    <iot4bos-ui-device-card
      (activate)="onActivate()"
      (commandExec)="onCommandExec(device, $event)"
      (configure)="configureDevice($event)"
      (decommissionDevice)="decommissionDevice()"
      (deleteDevice)="deleteDevice($event)"
      (edit)="editDevice()"
      (editMaintenance)="onStartOrEditMaintenance()"
      (endMaintenance)="onEndMaintenance()"
      (iccidClick)="openIccid()"
      (moveTo)="moveDevice($event)"
      (openChangeLog)="openChangeLog()"
      (reset)="resetDevice($event)"
      (startMaintenance)="onStartOrEditMaintenance()"
      [canCreate]="canCreate"
      [canDelete]="canDelete"
      [canReadAuditTrail]="canReadAuditTrail"
      [canRead]="canRead"
      [canUpdate]="canUpdate"
      [device]="device"
      [isResetDisabled]="isResetDisabled$ | async"
    >
    </iot4bos-ui-device-card>
    <div class="device-info-status-area" fxLayoutAlign="end center" fxLayoutGap="20px">
      <iot-platform-ui-device-latest-command-status [device]="device"></iot-platform-ui-device-latest-command-status>
      <iot-platform-ui-device-info-status [device]="device"></iot-platform-ui-device-info-status>
    </div>
    <iot-platform-ui-panel
      (clicked)="manageTags()"
      [buttonTooltip]="'MANAGE_TAGS_FORM.CONFIGURE_TAGS' | translate"
      [hasButton]="displayManageTagsButton() && !!(tagsLoaded$ | async)"
      [icon]="'settings'"
      [title]="'MANAGE_TAGS_FORM.TAGS' | translate"
    >
      @if (!(tagsLoaded$ | async)) {
        <iot-platform-ui-card-loader [backgroundClass]="'tag-loader info-page'" panelGrid></iot-platform-ui-card-loader>
      } @else {
        <mat-chip-set panelContent>
          @for (tag of tags; track tag.id) {
            <iot-platform-ui-chip [chipButton]="{ display: false }" [chip]="tag" [isTag]="true"></iot-platform-ui-chip>
          }
        </mat-chip-set>
      }
    </iot-platform-ui-panel>
  </div>
}
