import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder } from '@iot-platform/core';

import { Topic } from '@iot-platform/models/ocm';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventDetailPopupService {
  constructor(@Inject('environment') private environment, public httpClient: HttpClient) {}

  getTopicsByEventId(eventId: string): Observable<Topic[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('eventId', eventId);

    return this.httpClient
      .get<{ page: any; content: Topic[] }>(`${this.environment.api.url}${this.environment.api.endpoints.topics}`, { params })
      .pipe(map((response) => response.content));
  }
}
