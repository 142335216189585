import { AbstractControl, ValidationErrors } from '@angular/forms';

export class AuthValidators {
  static checkPassword(password: AbstractControl | string | null): ValidationErrors | null {
    let hasError = false;
    const errors = {
      format: {
        size: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false
      }
    };
    let passwordValue: string;

    if (typeof password === 'string') {
      passwordValue = password;
    } else {
      passwordValue = password?.value;
    }

    if (!/^.{8,128}$/.test(passwordValue)) {
      hasError = true;
      errors.format.size = true;
    }
    if (!/^(?=.*[a-z]).*$/.test(passwordValue) || passwordValue === null) {
      hasError = true;
      errors.format.lowercase = true;
    }
    if (!/^(?=.*[A-Z]).*$/.test(passwordValue)) {
      hasError = true;
      errors.format.uppercase = true;
    }
    if (!/^(?=.*[0-9]).*$/.test(passwordValue)) {
      hasError = true;
      errors.format.number = true;
    }
    if (!/^(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]).*$/.test(passwordValue)) {
      hasError = true;
      errors.format.special = true;
    }
    return hasError ? errors : null;
  }
}
