<mat-card class="dialog-card catalog-panel-container mat-elevation-z0">
  <mat-card-title class="dialog-card-header sticky">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.TITLE' | translate }}</h1>
      @if (canUpdate || canDelete) {
        <button
          (click)="editCatalogs.emit()"
          [matTooltip]="
            (catalogs.length ? 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.EDIT_CATALOGS' : 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.CREATE_CATALOG') | translate
          "
          class="regular-round-button"
          mat-icon-button
          matTooltipClass="regular-tooltip"
        >
          <mat-icon>{{ catalogs.length ? 'edit' : 'add' }}</mat-icon>
        </button>
      }
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="catalog-panel-content p-20" fxLayout="column" fxLayoutGap="16px">
    @for (catalog of catalogs; track catalog.id) {
      <div class="catalog-panel-catalog-card" fxLayout="column" fxLayoutGap="6px">
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'IOT_DICTIONARY.NAME' | translate }}:"
          value="{{ catalog.name }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.ATTACHED_ENTITIES' | translate }}:"
          value="{{ displayEntities(catalog.entities) | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
      </div>
    }
  </mat-card-content>
</mat-card>
