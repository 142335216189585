import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, signal, Type } from '@angular/core';
import { DataGuardSettings, DataGuardSettingsFactory } from '@iot-platform/feature/data-guard';
import { AbstractDataGuardService } from '../services/abstract-data-guard.service';

export const DATA_GUARD_SETTINGS = new InjectionToken<DataGuardSettings>('DATA_GUARD_SETTINGS');
// export const DATA_GUARD_SERVICE = new InjectionToken<AbstractDataGuardService>('DATA_GUARD_SERVICE');
// export const DATA_GUARD_NAVIGATION_API = new InjectionToken<any>('DATA_GUARD_NAVIGATION_API');

const getDataGuardSettings = (settings: Partial<DataGuardSettings>) => {
  const defaultSettings: DataGuardSettings = {
    header: {
      enabled: settings.header?.enabled ?? signal(true),
      title: settings.header?.title ?? signal(''),
      closeButton: settings.header?.closeButton ?? signal(true),
      infoIcon: settings.header?.infoIcon ?? signal(false),
      infoTooltip: settings.header?.infoTooltip ?? signal(null),
      svgIcon: settings.header?.svgIcon ?? signal('data_guard')
    },
    content: {
      ...settings.content
    }
  };
  return defaultSettings;
};

export function provideDataGuardSettingsFactory(settings: {
  dataGuardService: Type<AbstractDataGuardService>;
  settingsFactory: DataGuardSettingsFactory;
}): EnvironmentProviders {
  const providers = [
    { provide: AbstractDataGuardService, useClass: settings.dataGuardService, multi: false },
    {
      provide: DATA_GUARD_SETTINGS,
      multi: false,
      useFactory: (dataGuardService: Type<AbstractDataGuardService>): Partial<DataGuardSettings> => {
        const customSettingsFromFactory = settings.settingsFactory({ dataGuardService });
        return getDataGuardSettings(customSettingsFromFactory);
      },
      deps: [AbstractDataGuardService]
    }
  ];
  return makeEnvironmentProviders(providers);
}
