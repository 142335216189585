<mat-sidenav-container (backdropClick)="onCloseComments()" data-cy="iot4bos-ui-asset-events-shell" fxFlex
                       hasBackdrop="true">
  <mat-sidenav-content fxLayout="column">
    <iot-platform-ui-toolbar-v2
      (dispatchToolbarEvent)="onToolbarEvent($event)"
      [autoRefresh]="{
        counter: 100 - ((timerValue$ | async) / (assetEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay) * 100,
        timeLeft: (assetEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay - (timerValue$ | async),
        displaySpinner: (assetEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.enabled
      }"
      [buttonList]="masterViewAssetEventsButtonList"
      [favoriteViewConfiguration]="assetEventFavoriteViewsConfiguration$ | async"
      [gridsConfiguration]="assetEventGridsConfiguration$ | async"
      [isDataLoaded]="assetEventsLoaded$ | async"
      [name]="'EVENTS.ASSET_EVENTS' | translate"
      [size]="toolbarSize"
      [total]="totalAssetEvents$ | async"
    />

    <iot-platform-ui-filter-engine
      (applyFilters)="onApplyFilters($event)"
      [currentFavoriteView]="currentFavoriteView$ | async"
      [currentFilters]="currentFilters$ | async"
      [displayActionButtons]="true"
      [expanded]="filterEngineOpened"
      [masterView]="'asset-events'"
      [mode]="FilterEngineMode.FAVORITE"
      [withFavoriteFilters]="true"
    />

    <grid-engine-grid-manager
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      (timerValueChanged)="_timerValue$.next($event)"
      [currentFilters]="currentFilters$ | async"
      [gridSort]="gridSort$ | async"
      [grid]="grid$ | async"
      [userPermissions]="userPermissions"
      [visibleNodeId]="selectedRowId"
      class="layout-master-view-engine-container"
    />
  </mat-sidenav-content>

  <mat-sidenav #sidenav disableClose position="end">
    <iot4bos-ui-comments-panel
      (commentsEvent)="onCommentsEvent($event)"
      [canDeleteComment]="canUpdateEvent"
      [canEditComment]="canUpdateEvent"
      [comments]="assetEventComments()"
      [defaultConcept]="CONCEPT.EVENT"
      [filters]="commentsContexts"
      [loading]="assetEventCommentsLoading()"
      [withFilters]="true"
      [withFooter]="canUpdateEvent"
      data-cy="iot4bos-ui-asset-events-comments"
    />
  </mat-sidenav>
</mat-sidenav-container>
