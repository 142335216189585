@if (asset) {
  <div class="association-object-card" [ngClass]="{ selected: selectedItemId === asset.id }" (click)="onClick()" fxLayout="row wrap">
    @if (!navigationMode) {
      <div fxFlex="120px"><img class="association-object-img" [src]="imgPath" alt="" /></div>
    }
    <div fxFlex fxLayout="column" fxLayoutGap="6px" class="association-object-card-info">
      <p class="association-object-card-title">
        <span class="association-object-card-title">{{ asset.name | infoDisplay }}</span>
        <span class="association-object-card-title secondary-info">{{ asset.businessId }}</span>
      </p>
      <div fxLayout="row" fxLayout.sm="column" fxLayoutGap="5px">
        <ul fxLayout="column" [fxFlex]="asset.product1 || asset.product2 ? '45%' : '100%'" fxFlex.sm="100%">
          <li fxLayout="row" fxLayoutGap="3px">
            <span [fxFlex]="!(asset.product1 || asset.product2) ? '14%' : '30%'" fxFlex.sm="26%" class="association-object-property">{{
              'SITES.ASSOCIATIONS.SHIP_TO' | translate
            }}</span>
            <span [fxFlex]="!(asset.product1 || asset.product2) ? '86%' : '70%'" fxFlex.sm="74%" class="asset-card-value">{{
              asset.erpReference.shipTo | infoDisplay
            }}</span>
          </li>
          <li fxLayout="row" fxLayoutGap="3px">
            <span [fxFlex]="!(asset.product1 || asset.product2) ? '14%' : '30%'" fxFlex.sm="26%" class="association-object-property">{{
              'SITES.ASSOCIATIONS.STATUS' | translate
            }}</span>
            <span [fxFlex]="!(asset.product1 || asset.product2) ? '86%' : '70%'" fxFlex.sm="74%" class="asset-card-value">{{
              asset.status.name | infoDisplay
            }}</span>
          </li>
        </ul>
        @if (asset.product1 || asset.product2) {
          <ul fxLayout="column" [fxFlex]="asset.product1 || asset.product2 ? '55%' : '100%'" fxFlex.sm="100%">
            @if (asset.product1) {
              <li fxLayout="row" fxLayoutGap="3px">
                <span fxFlex="32%" fxFlex.sm="26%" class="association-object-property">{{ 'ASSETS.CARD.PRODUCT_1' | translate }}</span>
                <span fxFlex="68%" fxFlex.sm="74%" class="asset-card-value" [matTooltip]="asset.product1?.name | infoDisplay">{{
                  asset.product1?.name | infoDisplay
                }}</span>
              </li>
            }
            @if (asset.product2) {
              <li fxLayout="row" fxLayoutGap="3px">
                <span fxFlex="32%" fxFlex.sm="26%" class="association-object-property">{{ 'ASSETS.CARD.PRODUCT_2' | translate }}</span>
                <span fxFlex="68%" fxFlex.sm="74%" class="asset-card-value" [matTooltip]="asset.product2?.name">{{ asset.product2?.name | infoDisplay }}</span>
              </li>
            }
          </ul>
        }
      </div>
    </div>
  </div>
}
