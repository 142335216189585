import { Contact } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const ContactsActions = createActionGroup({
  source: 'Contacts',
  events: {
    'Load Contacts': props<{ siteId: string }>(),
    'Load Contacts Success': props<{ contacts: Contact[] }>(),
    'Load Contacts Failure': props<{ error: unknown }>(),

    'Add Contact': props<{ contact: Contact }>(),
    'Add Contact Success': props<{ addedContact: Contact }>(),
    'Add Contact Failure': props<{ error: unknown }>(),

    'Update Contact': props<{ contact: Contact }>(),
    'Update Contact Success': props<{ updatedContact: Contact }>(),
    'Update Contact Failure': props<{ error: unknown }>(),

    'Delete Contact': props<{ contact: Contact }>(),
    'Delete Contact Success': props<{ deletedContact: Contact }>(),
    'Delete Contact Failure': props<{ error: unknown }>()
  }
});
