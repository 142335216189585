import { Component, DestroyRef, inject, input, output, signal, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BuildInfo } from '@iot-platform/models/common';
import { shareReplay } from 'rxjs/operators';
import { BuildInfoService } from './services/build-info.service';

@Component({
  standalone: true,
  imports: [FlexLayoutModule],
  selector: 'iot-platform-ui-build-info',
  templateUrl: './build-info.component.html',
  styleUrls: ['./build-info.component.scss']
})
export class BuildInfoComponent {
  label = input<string>();
  build = input<BuildInfo>();
  showBuildNumber = input<boolean>(true);
  openReleaseNotes = output<boolean>();
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly buildInfoService: BuildInfoService = inject(BuildInfoService);
  buildInfo: Signal<BuildInfo> = this.build()
    ? signal(this.build())
    : toSignal(this.buildInfoService.getBuildInfo().pipe(shareReplay(1), takeUntilDestroyed(this.destroyRef)));
}
