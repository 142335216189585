<div [ngClass]="getTimelineState()" class="event-timeline" data-cy="iot4bos-ui-event-timeline">
  <ul class="event-timeline-list">
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">{{ 'EVENTS.TIMELINE.STATE.CREATE' | translate }}</span>
          <span class="event-timeline-icon"><mat-icon>wifi_tethering</mat-icon></span>
          @if (time?.occurrenceTime) {
            <span class="event-timeline-time">{{ time?.occurrenceTime | dateFormat }}</span>
          }
        </div>
      </div>
      <div class="event-timeline-info">
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.OCCURRENCE_TIME' | translate }}</span>
          <span class="event-timeline-card-content">{{ event?.occurrenceTime | dateFormat }}</span>
        </div>
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.RAW_DATA' | translate }}</span>
          <span class="event-timeline-card-code"
            ><code>{{ event?.rawData | json }}</code></span
          >
        </div>
      </div>
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">{{ 'EVENTS.TIMELINE.STATE.ACTIVE' | translate }}</span>
          <span class="event-timeline-icon"><mat-icon>notifications_active</mat-icon></span>
          @if (time.receptionTime?.days >= 0) {
            <span class="event-timeline-time">
              @if (time.receptionTime.days) {
                <span>{{ time.receptionTime.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
              }
              @if (time.receptionTime.hours) {
                <span>{{ time.receptionTime.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
              }
              @if (time.receptionTime.minutes) {
                <span>{{ time.receptionTime.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
              }
              @if (time.receptionTime.seconds) {
                <span>{{ time.receptionTime.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
              }
            </span>
          }
          @if (time.receptionTime?.days < 0) {
            <span class="event-timeline-time"> N/A </span>
          }
        </div>
      </div>
      <div class="event-timeline-info">
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.RECEPTION_TIME' | translate }}</span>
          <span class="event-timeline-card-content">{{ event.receptionTime | dateFormat }}</span>
        </div>
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.CONNECTOR' | translate }}</span>
          <span class="event-timeline-card-content">{{ event.connector | infoDisplay }}</span>
        </div>
      </div>
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">
            <button
              (click)="updateStatus.emit('snooze')"
              [disabled]="snoozeButtonDisableStatus"
              class="event-timeline-status-change-button"
              color="accent"
              data-cy="iot4bos-ui-event-timeline-snooze-button"
              mat-raised-button
            >
              {{ 'EVENTS.TIMELINE.STATE.SNOOZE' | translate }}
            </button>
          </span>
          <span class="event-timeline-icon"><mat-icon>notifications_paused</mat-icon></span>
          @if (time.snooze) {
            <span class="event-timeline-time">
              @if (time.snooze.days) {
                <span>{{ time.snooze.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
              }
              @if (time.snooze.hours) {
                <span>{{ time.snooze.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
              }
              @if (time.snooze.minutes) {
                <span>{{ time.snooze.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
              }
              @if (time.snooze.seconds) {
                <span>{{ time.snooze.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
              }
            </span>
          }
        </div>
      </div>
      @if (!!snoozedLogs.length) {
        <div class="event-timeline-info">
          @for (snoozedLog of snoozedLogs; track snoozedLog; let i = $index) {
            <iot4bos-ui-event-timeline-log [log]="snoozedLog" [ngClass]="getSnoozeState(i)"></iot4bos-ui-event-timeline-log>
          }
        </div>
      }
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">
            <button
              (click)="updateStatus.emit('acknowledge')"
              [disabled]="acknowledgeButtonDisableStatus"
              class="event-timeline-status-change-button"
              color="accent"
              data-cy="iot4bos-ui-event-timeline-acknowledge-button"
              mat-raised-button
            >
              {{ 'EVENTS.TABLE.BUTTONS.ACKNOWLEDGE' | translate }}
            </button>
          </span>
          <span class="event-timeline-icon"><mat-icon>notifications_none</mat-icon></span>
          @if (time.acknowledge) {
            <span class="event-timeline-time">
              @if (time.acknowledge.days) {
                <span>{{ time?.acknowledge?.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
              }
              @if (time.acknowledge.hours) {
                <span>{{ time?.acknowledge?.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
              }
              @if (time.acknowledge.minutes) {
                <span>{{ time?.acknowledge?.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
              }
              @if (time.acknowledge.seconds) {
                <span>{{ time?.acknowledge?.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
              }
            </span>
          }
        </div>
      </div>
      @if (!!acknowledgedLogs.length) {
        <div class="event-timeline-info">
          @for (acknowledgedLog of acknowledgedLogs; track acknowledgedLog; let i = $index) {
            <iot4bos-ui-event-timeline-log [log]="acknowledgedLog" [class.current]="status === 'acknowledged' && i === 0"></iot4bos-ui-event-timeline-log>
          }
        </div>
      }
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">
            <button
              (click)="updateStatus.emit('close')"
              [disabled]="closeButtonDisableStatus"
              class="event-timeline-status-change-button"
              color="accent"
              data-cy="iot4bos-ui-event-timeline-close-button"
              mat-raised-button
            >
              {{ 'EVENTS.TABLE.BUTTONS.CLOSE' | translate }}
            </button>
          </span>
          <span class="event-timeline-icon"><mat-icon>notifications_off</mat-icon></span>
          @if (time.close) {
            <span class="event-timeline-time">
              @if (time.close.days) {
                <span>{{ time.close.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
              }
              @if (time.close.hours) {
                <span>{{ time.close.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
              }
              @if (time.close.minutes) {
                <span>{{ time.close.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
              }
              @if (time.close.seconds) {
                <span>{{ time.close.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
              }
            </span>
          }
        </div>
      </div>
      @if (!!closedLogs.length) {
        <div class="event-timeline-info">
          @for (closedLog of closedLogs; track closedLog; let i = $index) {
            <iot4bos-ui-event-timeline-log [log]="closedLog" [class.current]="status === 'closed' && i === 0"></iot4bos-ui-event-timeline-log>
          }
        </div>
      }
    </li>
  </ul>
</div>
