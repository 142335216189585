@if (list.length) {
  <ul fxLayout="column">
    @for (item of list; track item) {
      <li fxLayoutGap="10px" fxLayoutAlign="start center">
        @if (displayCheckbox) {
          <mat-checkbox [checked]="checkboxStatusByItem[item.id]" (change)="onItemSelection(item, $event)"></mat-checkbox>
        }
        <span fxFlex class="list-item" [ngClass]="{ selected: item.id === selectedItem?.id }" (click)="selectItem.emit(item)">{{ item.name }}</span>
      </li>
    }
  </ul>
}
