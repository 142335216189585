import { AssetEvent, Log } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadLogsByAssetEventSuccess = createAction('[AssetEventsByAsset] Load Logs By Asset Event Success', props<{ logs: Log[] }>());
export const loadLogsByAssetEventFailure = createAction('[AssetEventsByAsset] Load Logs By Asset Event Failure', props<{ error: any }>());
//
export const createLogByAssetEventSuccess = createAction(
  '[AssetEventsByAsset] Create Log By Asset Event Success',
  props<{ log: Log; assetEvent: AssetEvent }>()
);
export const createLogByAssetEventFailure = createAction('[AssetEventsByAsset] Create Log By Asset Event Failure', props<{ error: any }>());
