<div class="stock-management-shell-wrapper" fxFlex fxLayout="column">
  @if (site) {
    <iot4bos-ui-stock-card
      [site]="site"
      [deviceCountByFamily]="deviceCountByFamily$ | async"
      [deviceCountByFamilyLoading]="deviceCountByFamilyLoading$ | async"
      [totalDevices]="gridTotalElements$ | async"
      [totalDevicesLoading]="!(grid$ | async)"
    ></iot4bos-ui-stock-card>
  }
  <div class="layout-master-view-engine-container stock-grid-wrapper">
    <grid-engine-grid-manager
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      [currentFilters]="currentFilters"
      [gridSort]="gridSort$ | async"
      [grid]="grid$ | async"
      [userPermissions]="userPermissions"
      fxFlex
    >
    </grid-engine-grid-manager>
  </div>
</div>
