import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { AssetVariable } from '@iot-platform/models/i4b';
import * as fromAssetVariables from '../reducers/asset-variables.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetVariablesSelectors extends ApiFeatureSelector<fromAssetVariables.State, AssetVariable, Pagination, Filter> {
  constructor() {
    super(fromAssetVariables.assetVariablesFeature.selectAssetVariablesState, fromAssetVariables.adapter);
  }
}
