import { Host, Injectable, Optional } from '@angular/core';
import { AssetVariable } from '@iot-platform/models/i4b';
import { AbstractGridAssetVariableConfigureReadPopupService } from '../services/abstract-grid-asset-variable-configure-read-popup.service';

@Injectable({ providedIn: 'root' })
export class GridEngineSettingsService {
  constructor(
    @Host()
    @Optional()
    private readonly assetVariableConfigureReadPopupService: AbstractGridAssetVariableConfigureReadPopupService
  ) {}

  updateAssetVariableThresholds(assetVariable: AssetVariable): void {
    this.assetVariableConfigureReadPopupService?.updateAssetVariableThresholds?.(assetVariable);
  }
}
