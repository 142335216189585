import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcknowledgeService {
  constructor(@Inject('environment') private environment, private http: HttpClient, private route: ActivatedRoute) {}

  getEvent(): Observable<Event> {
    return this.route.paramMap.pipe(
      switchMap((params) => this.http.get<Event>(`${this.environment.api.url}${this.environment.api.endpoints.ack}/${params.get('tokenId')}`))
    );
  }

  acknowledgeEvent(event: Event): Observable<Event> {
    return this.route.paramMap.pipe(
      switchMap((params) => this.http.put<Event>(`${this.environment.api.url}${this.environment.api.endpoints.ack}/${params.get('tokenId')}`, { event }))
    );
  }
}
