import { InjectionToken } from '@angular/core';
import { AnalyticsData } from '../models/tracking-data.model';
import { TrackingSettings } from '../models/tracking-settings.model';
import { AnalyticsTag } from '../models/tracking-tag.model';
import { AbstractTrackingMapperService } from '../services/abstract-tracking-mapper.service';
import { AbstractTrackingProvider } from '../services/abstract-tracking-provider.service';

export const TRACKING_SETTINGS = new InjectionToken<TrackingSettings[]>('TRACKING_SETTINGS');
export const TRACKING_PROVIDERS = new InjectionToken<AbstractTrackingProvider[]>('TRACKING_PROVIDERS');
export const TRACKING_MAPPERS = new InjectionToken<AbstractTrackingMapperService<AnalyticsTag, AnalyticsData>[]>('TRACKING_MAPPERS');
