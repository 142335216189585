import { createReducer, on } from '@ngrx/store';

import { BusinessProfilesPageActions, BusinessProfilesTagsApiActions, BusinessProfilesTagsPageActions } from '../actions';

export const adminBusinessProfilesTagsPageFeatureKey = 'adminBusinessProfilesTagsPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const getError = (state: State) => state.error;

export const reducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listTagsByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesTagsPageActions.updateTagsForBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);
export const getPending = (state: State) => state.pending;
