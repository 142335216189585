<div
  [ngClass]="{ 'default-card--with-custom-btn': withCustomButton() }"
  class="default-card {{ backgroundClass() }}"
  fxLayout="row"
  fxLayoutAlign="center center"
  matRipple
>
  @if (withCustomButton()) {
    <span class="default-card__custom-button">
      <ng-content select="[cardCustomButton]" />
    </span>
  } @else {
    <mat-icon class="default-card-icon">add</mat-icon>
  }
  <span class="default-card-title">{{ title() }}</span>
</div>
