<div class="account-validation-container" fxFlex fxLayout="column">
  <div class="account-validation-header p-20" fxLayout="row" fxLayoutAlign="space-between center">
    <p class="account-validation-header-title fw-600">IoT4BOS</p>
    <img src="assets/images/logo-AL.png" style="width: 160px; height: 30px" />
  </div>
  @if (!error) {
    <div class="p-20" fxLayout="column" fxLayoutGap="40px">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="account-validation-label" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center" fxLayoutGap="4px">
          <span style="font-size: 20px; margin-top: 20px">This account has been validated.</span>
        </div>
      </div>
    </div>
  } @else {
    <div class="p-20" fxFlex fxLayoutAlign="center start">
      <div class="account-validation-empty-label" fxLayoutAlign="center center">THERE WAS AN ERROR OR THIS ACCOUNT IS ALREADY ACTIVE.</div>
    </div>
  }
</div>
