import { Injectable, Signal, computed } from '@angular/core';
import { GridsDbActions, fromGrids } from '@iot-platform/grid-engine';
import { BaseFacade, BusinessProfile, Filter, Pagination, UserAccount } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { Observable, noop } from 'rxjs';
import { UsersActions } from '../actions/users.actions';
import { UsersSelectors } from '../selectors/users.selectors';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';

@Injectable({
  providedIn: 'root'
})
export class UsersFacade extends BaseFacade<UserAccount, Pagination, Filter> {
  businessProfilesByUser: Signal<BusinessProfile[]> = this.store.selectSignal(this.selector.selectBusinessProfilesByUser);
  grids$: Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.select(fromGrids.selectUsersGrids);
  gridConfiguration$ = this.store.select(fromGrids.selectUsersGridsConfiguration);
  gridConfiguration = this.store.selectSignal(fromGrids.selectUsersGridsConfiguration);
  canCreateUser: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.CREATE);
  });
  canUpdateUser: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.UPDATE);
  });
  canUpdateUserStatus: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.USER_STATUS, AuthorizationType.UPDATE);
  });
  canReadHistory: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);
  });

  constructor(
    protected override store: Store,
    protected override selector: UsersSelectors,
    protected readonly authFacade: AuthFacade,
    protected readonly authorizationService: AuthorizationService
  ) {
    super(store, selector);
  }

  getAll(): void {
    noop();
  }

  setFilters(filters: Filter[]): void {
    this.store.dispatch(UsersActions.setFilters({ filters }));
  }

  getUserById(userId: string): void {
    this.store.dispatch(UsersActions.getUserById({ userId }));
  }

  addUser(user: UserAccount): void {
    this.store.dispatch(UsersActions.addUser({ user }));
  }

  updateUser(user: UserAccount): void {
    this.store.dispatch(UsersActions.updateUser({ user }));
  }

  enableUser(user: UserAccount): void {
    this.store.dispatch(UsersActions.enableUser({ user }));
  }

  disableUser(user: UserAccount): void {
    this.store.dispatch(UsersActions.disableUser({ user }));
  }

  resendUserValidationLink(user: UserAccount): void {
    this.store.dispatch(UsersActions.resendUserValidationLink({ user }));
  }

  navigateToUserDetails(userId: string): void {
    this.store.dispatch(UsersActions.navigateToUserDetails({ userId }));
  }

  getBusinessProfilesByUserId(userId: string): void {
    this.store.dispatch(UsersActions.getBusinessProfilesByUserId({ userId }));
  }

  addUserToCurrentGrid(item: UserAccount): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(GridsDbActions.addItemInGridData({ gridId, item }));
    }
  }

  updateUserInCurrentGrid(item: UserAccount): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(GridsDbActions.updateItemInGridData({ gridId, item, concept: 'users' }));
    }
  }
}
