import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { Device } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, InfoDisplayPipe, NgClass],
  providers: [InfoDisplayPipe],
  selector: 'iot-platform-ui-detail-device-card',
  templateUrl: './detail-device-card.component.html',
  styleUrls: ['./detail-device-card.component.scss']
})
export class DetailDeviceCardComponent {
  device = input<Device>();
  context = input<string>();
}
