<div [class]="checkError().status" class="detailed-error detailed-error--container" data-cy="iot-platform-feature-data-guard-check-error" fxLayout="column" fxLayoutGap="6px">
  @if (checkError().title) {
    <div class="ellipsis">
      <span class="fw-600 fs-14" [matTooltip]="checkError().title" matTooltipClass="regular-tooltip">{{ checkError().title }}</span>
    </div>
  }

  <span class="fs-12 detailed-error--label" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <span class="fw-600">{{ checkError().label | translate }}</span>
    @if (checkError().description) {
      <mat-icon [matTooltip]="checkError().description | translate" matTooltipClass="regular-tooltip">info_outlined</mat-icon>
    }
  </span>

  @for (suggestedAction of checkError().suggestedActions; track suggestedAction) {
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="detailed-error--suggested-actions">
      <span class="detailed-error--action-label">{{ 'DATA_GUARD.DETAILED_CHECK.ACTION' | translate }}</span>
      <div fxFlex class="ellipsis">
        <span>{{ suggestedAction.description | translate }}</span>
      </div>
    </div>
  } @empty {
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="detailed-error--suggested-actions">
      <span class="detailed-error--no-action-label">{{ 'DATA_GUARD.DETAILED_CHECK.NO_ACTION' | translate }}</span>
    </div>
  }
</div>
