import { inject, Injectable } from '@angular/core';
import { AbstractVariableCreateFormService } from '@iot-platform/iot4bos/ui/asset';
import { AssetTemplateVariable } from '@iot-platform/models/i4b';
import { Observable, of } from 'rxjs';
import { AssetTemplatesFacade } from '../state/facade/asset-templates.facade';

@Injectable({
  providedIn: 'root'
})
export class VariableCreateFormService extends AbstractVariableCreateFormService {
  private readonly assetTemplatesFacade: AssetTemplatesFacade = inject(AssetTemplatesFacade);

  isNameUnique(name: string): Observable<boolean> {
    return of(this.assetTemplatesFacade.currentEntity()?.template.variables.findIndex((variable: AssetTemplateVariable) => variable.name === name) === -1);
  }
}
