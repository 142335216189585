import { Filter, PlatformRequest } from '@iot-platform/models/common';
import { Topic } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listTopics = createAction('[Topics] List Topics', props<{ request: PlatformRequest }>());
export const selectTopic = createAction('[Topics] Select Topic', props<{ selectedTopic: Topic }>());
export const getTopicById = createAction('[Topics] Get Topic By ID', props<{ topicId: string }>());
//
export const addTopic = createAction('[Topics] Add Topic', props<{ topicToAdd: Topic }>());
export const addTopicToProtocol = createAction('[Topics] Add Topic to Protocol', props<{ topicToAddToProtocol: Topic }>());
export const addTopicThenConfigure = createAction('[Topics] Add Topic then open configure popup', props<{ topicToAdd: Topic }>());
export const cancelAddTopicThenConfigure = createAction('[Topics] Cancel Add Topic then open configure popup');

export const saveTopicFilters = createAction('[Topics] Save Topic Filters', props<{ filters: Filter[] }>());

export const updateTopic = createAction('[Topics] Update Topic', props<{ topicToUpdate: Topic }>());
export const deleteTopic = createAction('[Topics] Delete Topic', props<{ topicToDelete: Topic }>());

export const subscribeToTopic = createAction('[Topics] Subscribe to Topic', props<{ topic: Topic }>());
export const unsubscribeToTopic = createAction('[Topics] Unsubscribe to Topic', props<{ topic: Topic }>());

export const navigateToTopicDetails = createAction('[Topics] Navigate To Details', props<{ toNavigateTo: Topic }>());
