import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { DISABLE_ERROR_MESSAGES_AFTER_LOGOUT, NotificationComponent } from './notification.component';

@NgModule({
  imports: [NotificationComponent, LoaderComponent],
  exports: [NotificationComponent, LoaderComponent]
})
export class NotificationModule {
  static forRoot(config: { disableErrorMessagesAfterLogout: boolean }): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [{ provide: DISABLE_ERROR_MESSAGES_AFTER_LOGOUT, useValue: config.disableErrorMessagesAfterLogout }]
    };
  }
}
