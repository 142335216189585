import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iot4bos-ui-backoffice-filter-bp-by-name',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Input() searchString: string;

  @Output() filter: EventEmitter<string> = new EventEmitter();
}
