import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { Environment, LightWeightUser, UserAccount } from '@iot-platform/models/common';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { map, retry, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CachedUsersService {
  cachedUsers$: BehaviorSubject<LightWeightUser[]> = new BehaviorSubject<LightWeightUser[]>([]);

  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly http: HttpClient
  ) {}

  loadUsers(page = 0): void {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('page', page);
    this.http
      .get<{ content: LightWeightUser[]; page: { limit: number; hasMore: boolean; curPage: number; maxPage: number } }>(
        `${this.environment.api.url}${this.environment.api.endpoints.usersLightWeight}`,
        { params }
      )
      .pipe(
        retry(2),
        tap((response) => {
          this.cachedUsers$.next([...this.cachedUsers$.getValue(), ...response.content]);

          if (response.page.hasMore && response.page.curPage !== response.page.maxPage - 1) {
            this.loadUsers(page + 1);
          }
        })
      )
      .subscribe();
  }

  public getUserById(userId: string): Observable<LightWeightUser | undefined> {
    return this.cachedUsers$.pipe(
      switchMap((users) => {
        const user: LightWeightUser | undefined = users.find((u) => u.id === userId);

        if (user) {
          return of(user);
        } else {
          return this.http.get<UserAccount>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${userId}`).pipe(
            map((u) => ({ id: u.id, firstname: u.firstname, lastname: u.lastname, status: u.status })),
            catchError((error) => of(error)),
            tap((u: LightWeightUser) => {
              let result = u;
              if (result instanceof HttpErrorResponse) {
                result = { id: userId, status: 'error', firstname: '', lastname: '' };
              }
              return this.cachedUsers$.next([...this.cachedUsers$.getValue(), result]);
            })
          );
        }
      })
    );
  }
}
