import { JsonPipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CardLoaderModule, PopupComponent } from '@iot-platform/iot-platform-ui';
import { NotificationService } from '@iot-platform/notification';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, of } from 'rxjs';
import { DeviceIccidService } from '../../services/device-iccid.service';

@Component({
  standalone: true,
  selector: 'iot4bos-ui-device-iccid',
  templateUrl: './device-iccid.component.html',
  imports: [FlexLayoutModule, JsonPipe, CardLoaderModule, MatIconModule, MatButtonModule],
  styleUrls: ['./device-iccid.component.scss']
})
export class DeviceIccidComponent implements OnInit {
  message: WritableSignal<unknown> = signal(null);
  loading: WritableSignal<boolean> = signal(true);
  public dialog: MatDialog = inject(MatDialog);
  public data: { deviceId: string } = inject(MAT_DIALOG_DATA);
  public matDialogRef: MatDialogRef<DeviceIccidComponent> = inject(MatDialogRef);
  protected readonly notificationService: NotificationService = inject(NotificationService);
  protected readonly translateService: TranslateService = inject(TranslateService);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly deviceIccidService: DeviceIccidService = inject(DeviceIccidService);

  ngOnInit(): void {
    this.deviceIccidService
      .getOperatorInfo(this.data.deviceId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => {
          this.onError(error);
          return of(null);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe((message) => this.message.set(message));
  }

  copyMessageToClipboard() {
    navigator.clipboard
      .writeText(JSON.stringify(this.message(), null, 2))
      .then(() => this.notificationService.displaySuccess(this.translateService.instant('DEVICES.CALL_LOG.MESSAGE_COPIED')));
  }

  private onError(error: unknown): void {
    this.matDialogRef.close();
    this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: {
        type: 'error',
        value: error
      }
    });
  }
}
