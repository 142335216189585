import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SidenavBlockComponent } from './sidenav-block/sidenav-block.component';
import { SidenavItemsComponent } from './sidenav-items/sidenav-items.component';

@NgModule({
  imports: [RouterModule, TranslateModule, MatButtonModule, MatIconModule, MatMenuModule, MatListModule, NgClass],
  declarations: [SidenavBlockComponent, SidenavItemsComponent],
  exports: [SidenavBlockComponent, SidenavItemsComponent]
})
export class SidenavModule {}
