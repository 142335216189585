import { UpperCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'i18n-translation-preview-changes',
  templateUrl: './translation-preview-changes.component.html',
  styleUrls: ['./translation-preview-changes.component.scss'],
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatToolbar,
    FlexLayoutModule,
    MatIconButton,
    MatIcon,
    MatCardContent,
    DetailPopupModule,
    MatCardActions,
    MatButton,
    UpperCasePipe,
    TranslateModule,
    InfoDisplayPipe
  ]
})
export class TranslationPreviewChangesComponent implements OnInit {
  public translations: { key: string; languages: { key: string; value: string; missing: boolean }[] }[] = [];

  constructor(
    public matDialogRef: MatDialogRef<TranslationPreviewChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { updatedRows; languages: string[]; action: string }
  ) {}

  public ngOnInit(): void {
    this.translations = this.data.updatedRows.map((item) => {
      const languages = this.data.languages
        .filter((lang: string) => (this.data.action !== 'all' ? lang === this.data.action : true))
        .map((lang: string) => ({
          key: lang,
          ...item[lang]
        }));
      return {
        key: item.key,
        languages
      };
    });
  }

  public close(confirmed?: boolean): void {
    this.matDialogRef.close(confirmed);
  }
}
