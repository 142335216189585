import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BSitesButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.SITES,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          bulkActions: [
            {
              order: 10,
              key: 'bulkAddTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.ADD_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateSite', value: true }]]
            },
            {
              order: 15,
              key: 'bulkReplaceTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.REPLACE_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateSite', value: true }]]
            },
            {
              order: 20,
              key: 'bulkRemoveTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.REMOVE_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateSite', value: true }]]
            }
          ],
          singleActions: [],
          visibleConditions: [[{ key: 'canUpdateSite', value: true }]],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
