@switch (formulaType){ @case (FormulaType.MIN_MAX){
<iot4bos-ui-asset-min-max-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></iot4bos-ui-asset-min-max-parameters-form>
} @case (FormulaType.MIN_MAX_ADVANCED){
<iot4bos-ui-asset-min-max-advanced-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></iot4bos-ui-asset-min-max-advanced-parameters-form>
} @case (FormulaType.SLOPE_SHIFT){
<iot4bos-ui-asset-slope-shift-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></iot4bos-ui-asset-slope-shift-parameters-form>
} @case (FormulaType.LINEARIZATION){
<iot4bos-ui-asset-linearization-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></iot4bos-ui-asset-linearization-parameters-form>
} @case (FormulaType.CONSTANT){
<iot4bos-ui-asset-constant-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  (dispatchFormula)="dispatchFormula.emit($event)"
></iot4bos-ui-asset-constant-parameters-form>
} @case (FormulaType.CONSUMPTION_FOR_PG){
<iot4bos-ui-asset-consumption-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></iot4bos-ui-asset-consumption-parameters-form>
} @case (FormulaType.SHORTFALL){
<iot4bos-ui-asset-shortfall-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></iot4bos-ui-asset-shortfall-parameters-form>
} @case (FormulaType.AUTONOMY_FOR_PG){
<iot4bos-ui-asset-autonomy-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></iot4bos-ui-asset-autonomy-parameters-form>
} @case (FormulaType.SUM){
<iot4bos-ui-asset-sum-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></iot4bos-ui-asset-sum-parameters-form>
} }
