import { formatDate } from '@angular/common';
import { Filter } from '@iot-platform/models/common';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';

export class DateIntervalUtils {
  static isDateInThePastDay = (momentReference: Moment, date: string): boolean => {
    const hoursDifference = momentReference.diff(moment(date), 'seconds');
    return hoursDifference >= 0 && hoursDifference < 24 * 3600;
  };

  static isDateInThePastHour = (momentReference: Moment, date: string): boolean => {
    const hoursDifference = momentReference.diff(moment(date), 'seconds');
    return hoursDifference < 3600 && hoursDifference >= 0;
  };

  static isDateInTheNextDay = (momentReference: Moment, date: string): boolean => {
    const hoursDifference = momentReference.diff(moment(date), 'seconds');
    return hoursDifference < 0 && hoursDifference > -24 * 3600;
  };

  static isDateInTheFutureDays = (momentReference: Moment, date: string): boolean => {
    const hoursDifference = momentReference.diff(moment(date), 'seconds');
    return hoursDifference < 0;
  };

  static getArrayOfConsecutiveNumbers(start: number, end: number): number[] {
    const array: number[] = [];
    for (let i = start; i < end + 1; i++) {
      array.push(i);
    }
    return array;
  }

  static padWithZero(element): string {
    const result = element.toString();
    return result.length === 1 ? result.padStart(2, '0') : result;
  }

  static getDateFormatted(date: Date): string {
    return date.getFullYear() + '-' + DateIntervalUtils.padWithZero(date.getMonth() + 1) + '-' + DateIntervalUtils.padWithZero(date.getDate());
  }

  static getTimeFormatted(hours: number, minutes: number, seconds: number, timezone: string): string {
    return (
      'T' +
      DateIntervalUtils.padWithZero(hours) +
      ':' +
      DateIntervalUtils.padWithZero(minutes) +
      ':' +
      DateIntervalUtils.padWithZero(seconds) +
      timezone.replace(/:/gi, '')
    );
  }

  static getDateAndTimeFormattedToFilter(date: Date, hours: number, minutes: number, seconds: number, timezone: string): string {
    try {
      return formatDate(
        DateIntervalUtils.getDateFormatted(date) + DateIntervalUtils.getTimeFormatted(hours, minutes, seconds, timezone),
        'yyyy-MM-dd HH:mm:ss',
        'en-US',
        'UTC'
      );
    } catch (ex) {
      return null;
    }
  }

  static getDateAndTimeFormattedToDisplay(date: Date, hours: number, minutes: number, seconds: number, timezone: string): string {
    try {
      return formatDate(
        DateIntervalUtils.getDateFormatted(date) + DateIntervalUtils.getTimeFormatted(hours, minutes, seconds, timezone),
        'yyyy-MM-dd HH:mm:ssZ',
        'en-US',
        timezone.replace(/:/gi, '')
      );
    } catch (ex) {
      return null;
    }
  }

  static getDateFilter(date: Date, hours: number, minutes: number, seconds: number, timezone: string): { value: string; dateLabel: string } {
    const value = DateIntervalUtils.getDateAndTimeFormattedToFilter(date, hours, minutes, seconds, timezone);
    const dateLabel = DateIntervalUtils.getDateAndTimeFormattedToDisplay(date, hours, minutes, seconds, timezone);
    return { value, dateLabel };
  }

  static getYesterdayDateFilter(timezone): Filter {
    const yesterdaysDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const dateFilter: Filter = {
      criteriaKey: 'eventReceptionTimeStart',
      value: DateIntervalUtils.getDateFilter(
        yesterdaysDate,
        yesterdaysDate.getHours(),
        yesterdaysDate.getMinutes(),
        yesterdaysDate.getSeconds(),
        timezone.offset
      ).value
    };

    return dateFilter;
  }

  static diffDates = (
    startDate: string | Date | moment.Moment,
    endDate: string | Date | moment.Moment
  ): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } => {
    const d1 = moment(startDate);
    const d2 = moment(endDate);
    const duration: moment.Duration = moment.duration(d2.diff(d1));
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };
  };
}
