import { Signal } from '@angular/core';
import { Asset, AssetEvent, Device, DeviceEvent, Log, Site } from '@iot-platform/models/i4b';

export abstract class AbstractTopicsService {
  abstract assetEventComments: Signal<Log[]>;
  abstract assetEventCommentsLoading: Signal<boolean>;
  abstract deviceEventComments: Signal<Log[]>;
  abstract deviceEventCommentsLoading: Signal<boolean>;

  abstract loadEventDetailPopupDataByAssetEvent(assetEvent: AssetEvent): void;
  abstract loadEventDetailPopupDataByDeviceEvent(deviceEvent: DeviceEvent): void;
  abstract selectAssetAvecLeSite(selectedAsset: Asset): void;
  abstract selectDeviceAvecLeSite(selectedDevice: Device): void;
  abstract openAssetDetail(asset: Asset, origin: string): void;
  abstract openDeviceDetail(device: Device, origin: string): void;
  abstract openSiteDetail(site: Site, origin: string): void;

  abstract loadAssetEventComments(assetEvent: AssetEvent): void;
  abstract addAssetEventComment(assetEvent: AssetEvent, comment: string): void;
  abstract editAssetEventComment(assetEventId: string, comment: Log): void;
  abstract deleteAssetEventComment(assetEvent: AssetEvent, commentId: string): void;
  abstract updateStatusByAssetEventId(assetEventIds: string[], status: string): void;

  abstract loadDeviceEventComments(deviceEvent: DeviceEvent): void;
  abstract addDeviceEventComment(deviceEvent: DeviceEvent, comment: string): void;
  abstract editDeviceEventComment(deviceEventId: string, comment: Log): void;
  abstract deleteDeviceEventComment(deviceEvent: DeviceEvent, commentId: string): void;
  abstract updateStatusByDeviceEventId(deviceEventIds: string[], status: string): void;
}
