<div
  class="detailed-check-category-header--container"
  data-cy="iot-platform-feature-data-guard-detailed-check-category-header"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="6px"
>
  <span class="capitalize-first-letter fw-600 fs-14">{{ 'DATA_GUARD.DETAILED_CHECK.CATEGORIES.' + category() | translate }}</span>
  @for (status of statusesCounts() | keyvalue: customOrder; track status.key) {
    <iot-platform-ui-status-icon [status]="status.key" />
    <span class="fw-600 fs-12">({{ status.value }})</span>
  } @empty {
    <iot-platform-ui-status-icon [status]="'ok'" />
  }
</div>
