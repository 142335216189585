import { DeviceEvent, Log } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadLogsByDeviceEventSuccess = createAction('[DeviceEventsBySite] Load Logs By Device Event Success', props<{ logs: Log[] }>());
export const loadLogsByDeviceEventFailure = createAction('[DeviceEventsBySite] Load Logs By Device Event Failure', props<{ error: any }>());
//
export const createLogByDeviceEventSuccess = createAction(
  '[DeviceEventsBySite] Create Log By Device Event Success',
  props<{ log: Log; deviceEvent: DeviceEvent }>()
);
export const createLogByDeviceEventFailure = createAction('[DeviceEventsBySite] Create Log By Device Event Failure', props<{ error: any }>());
