import { BusinessProfile, Organization } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listBusinessProfilesByOrganization = createAction(
  '[Organizations/Admin Page] List Business Profiles By Organization',
  props<{ organizationId: string }>()
);
export const addBusinessProfileToOrganization = createAction(
  '[Organizations/Admin Page] Add Business Profile to Organization',
  props<{ businessProfileToAdd: BusinessProfile }>()
);
export const removeBusinessProfileFromOrganization = createAction(
  '[Organizations/Admin Page] Remove Business Profile From Organization',
  props<{ businessProfileToRemove: BusinessProfile }>()
);
export const showAddBusinessProfileDialog = createAction(
  '[Organizations/Admin Page] Show Add Business Profile Dialog',
  props<{ organization: Organization }>()
);

export const navigateToSelectedBusinessProfile = createAction(
  '[Organizations/Admin Page] Navigate to Selected Business Profile',
  props<{ businessProfile: BusinessProfile }>()
);
