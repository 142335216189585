import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromFavoriteViews from './+state/reducers';

import { FavoriteViewsEffects } from './+state/effects/favorite-views.effects';

import { FavoriteViewsService } from './services/favorite-views.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* StoreModule.forFeature(FAVORITE_VIEWS_FEATURE_KEY, reducer),*/
    StoreModule.forFeature(fromFavoriteViews.favoriteViewsFeatureKey, fromFavoriteViews.reducers),
    EffectsModule.forFeature([FavoriteViewsEffects])
  ],
  providers: [FavoriteViewsService, ]
})
export class FavoriteViewsModule {}
