import { MasterViewBluePrint } from './master-view-blue-print.model';

export const MASTERVIEWS_WITH_FAVORITEVIEW = ['sites', 'assets', 'devices', 'device-events', 'asset-events'];

export interface MasterView {
  metadata?: any;
  masterViewToolbar: any;
  masterViewFilterEngine: NonNullable<unknown>;
  masterViewTable: {
    bluePrint?: MasterViewBluePrint;
  };
  masterViewComments: NonNullable<unknown>;
}
