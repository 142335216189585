import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { CommandType, Device, DeviceStatusName } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements OnInit, OnChanges {
  @Input() device!: Device;
  @Input() canCreate = false;
  @Input() canDelete = false;
  @Input() canUpdate = false;
  @Input() canRead = false;
  @Input() canReadAuditTrail = false;
  @Input() isResetDisabled: boolean;

  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() moveTo: EventEmitter<Device> = new EventEmitter<Device>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() reset: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() decommissionDevice: EventEmitter<void> = new EventEmitter();
  @Output() deleteDevice: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() configure: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() commandExec: EventEmitter<CommandType> = new EventEmitter<CommandType>();
  @Output() openChangeLog: EventEmitter<void> = new EventEmitter();
  @Output() iccidClick: EventEmitter<void> = new EventEmitter();
  @Output() activate: EventEmitter<void> = new EventEmitter();
  @Output() startMaintenance: EventEmitter<void> = new EventEmitter();
  @Output() editMaintenance: EventEmitter<void> = new EventEmitter();
  @Output() endMaintenance: EventEmitter<void> = new EventEmitter();

  CommandType = CommandType;
  DeviceStatusName = DeviceStatusName;
  commandList: string[] = [];
  isCommandDisabled = false;
  ALLOWED_COMMANDS: { command: CommandType; displayRight: boolean }[] = [];
  computedURL = '';

  get imgPath(): string {
    return this.device.imageUrl ? this.device.imageUrl : 'assets/images/device/Device_Default.png';
  }

  ngOnInit() {
    this.ALLOWED_COMMANDS = [
      { command: CommandType.SELFCONF, displayRight: this.canUpdate },
      { command: CommandType.REFRESH, displayRight: this.canRead },
      { command: CommandType.UNBLOCK, displayRight: this.canUpdate },
      { command: CommandType.GET_ALARM, displayRight: this.canUpdate }
    ];
    this.setIsCommandDisabled(this.device);
    this.setCommandList(this.device);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device?.currentValue) {
      this.setIsCommandDisabled(this.device);
      this.setCommandList(this.device);
      this.setComputedURL(changes.device?.currentValue);
    }
  }

  getDisplayCTA(): boolean {
    return (
      this.getDisplayEditButton() ||
      this.getDisplayResetButton() ||
      this.getDisplayMoveToButton() ||
      this.getDisplayDecommissionButton() ||
      this.getDisplayConfigureButton() ||
      this.getDisplayCommandButtons() ||
      this.getDisplayDeleteButton() ||
      this.getDisplayHistoryButton() ||
      this.getDisplayActivateButton() ||
      this.getDisplayStartMaintenanceButton() ||
      this.getDisplayEditOrEndMaintenanceButton()
    );
  }

  getDisplayEditButton(): boolean {
    return this.canUpdate && this.device.status?.name !== DeviceStatusName.decommissioned && this.device.status?.name !== DeviceStatusName.test_mode;
  }

  getDisplayResetButton(): boolean {
    return this.canUpdate && this.device.status?.name !== DeviceStatusName.decommissioned && this.device.status?.name !== DeviceStatusName.test_mode;
  }

  getDisplayMoveToButton(): boolean {
    return this.canUpdate && this.device.status?.name !== DeviceStatusName.test_mode;
  }

  getDisplayDecommissionButton(): boolean {
    return this.canDelete && this.device.status?.name !== DeviceStatusName.test_mode && this.device.status?.name !== DeviceStatusName.decommissioned;
  }

  getDisplayConfigureButton(): boolean {
    return this.canCreate && !!this.device.incomingConnector?.configuration?.url && this.device.status?.name !== DeviceStatusName.decommissioned;
  }

  getDisplayCommandButtons(): boolean {
    return this.device.status?.name !== DeviceStatusName.decommissioned && this.device.status?.name !== DeviceStatusName.test_mode && !!this.commandList.length;
  }

  getDisplayDeleteButton(): boolean {
    return this.canDelete;
  }

  getDisplayHistoryButton(): boolean {
    return this.canReadAuditTrail;
  }

  getDisplayActivateButton(): boolean {
    return this.canCreate && this.device.status?.name === DeviceStatusName.test_mode && !!this.device.affiliate;
  }

  getDisplayStartMaintenanceButton(): boolean {
    return this.canUpdate && (this.device.status?.name === DeviceStatusName.standby || this.device.status?.name === DeviceStatusName.running);
  }

  getDisplayEditOrEndMaintenanceButton(): boolean {
    return this.canUpdate && this.device.status?.name === DeviceStatusName.maintenance;
  }

  setCommandList(device: Device): void {
    this.commandList = Object.keys(CommandType).filter((command) => {
      const allowedCommand = this.ALLOWED_COMMANDS.find((aC) => aC.command === CommandType[command]);
      return device.outgoingConnector?.requestConfiguration?.commands?.includes(CommandType[command]) && allowedCommand?.displayRight;
    });
  }

  setIsCommandDisabled(device: Device): void {
    if (device.outgoingConnector?.requestConfiguration?.authentication === 'login') {
      this.isCommandDisabled = !device.credential?.login || !device.credential?.password;
    }
  }

  setComputedURL(device: Device): void {
    switch (device?.communication?.httpport) {
      case 80:
        this.computedURL = `http://${device?.communication?.endpoint}`;
        break;
      case 443:
        this.computedURL = `https://${device?.communication?.endpoint}`;
        break;
      default:
        this.computedURL = device?.communication?.httpport
          ? `http://${device?.communication?.endpoint}:${device?.communication?.httpport}`
          : `http://${device?.communication?.endpoint}`;
        break;
    }
  }

  onActivate(): void {
    this.activate.emit();
  }

  onEdit(): void {
    this.edit.emit();
  }

  onMoveTo(): void {
    this.moveTo.emit(this.device);
  }

  isDeleteDisabled(): boolean {
    if (this.device && this.device.status) {
      return this.device.status.name !== DeviceStatusName.decommissioned;
    }
    return true;
  }

  onOpenChangeLog(): void {
    this.openChangeLog.emit();
  }

  onIccidClick(): void {
    this.iccidClick.emit();
  }
}
