import { Graph, GraphType } from '@iot-platform/models/common';
import { AssetVariable } from '@iot-platform/models/i4b';
import { WidgetAction } from './widget-action.enum';
import { LastValueWidgetOptions, TimeseriesWidgetOptions } from './widget-options.model';
import { WidgetSize } from './widget-size.enum';
import { WidgetTypes } from './widget-types.enum';

export type WidgetOptions = TimeseriesWidgetOptions | LastValueWidgetOptions;

export interface IWidgetInstance {
  type: WidgetTypes;
  data: any;
  options?: WidgetOptions;
}

export class WidgetInstance implements IWidgetInstance {
  public type: WidgetTypes;
  public data: any;
  public options: WidgetOptions;

  constructor(init?: Partial<IWidgetInstance>) {
    Object.assign(this, init);
    this.options = {
      ...WidgetInstance.emptyOptions,
      ...this.options
    };
  }

  static get emptyOptions(): WidgetOptions {
    return {
      size: WidgetSize.xlarge,
      menu: {
        enabled: false,
        actions: []
      },
      variables: [],
      header: {
        enabled: false,
        title: null
      },
      footer: {
        enabled: false,
        legend: null
      },
      chart: {
        searchBar: {
          enabled: false
        },
        legend: {
          enabled: false,
          labelFormat: null
        },
        export: {
          enabled: false
        }
      }
    };
  }

  static getOptionsByType(graph: Graph, graphPermissions: Partial<{ create: boolean; read: boolean; update: boolean; delete: boolean }>): WidgetOptions {
    const { name, variables } = graph;
    let options: WidgetOptions = {
      size: WidgetSize[graph.size],
      menu: {
        enabled: true,
        actions: [
          { name: WidgetAction.EDIT, label: 'GRAPH.EDIT', enabled: graphPermissions.update },
          { name: WidgetAction.DELETE, label: 'GRAPH.DELETE', enabled: graphPermissions.delete && !graph.asset?.id }
        ]
      }
    } as WidgetOptions;

    switch (graph.type) {
      case GraphType.last_value:
        options = {
          ...options,
          variable: variables[0] as AssetVariable,
          footer: {
            enabled: false,
            legend: ''
          }
        };
        break;
      case GraphType.line_chart:
      default:
        options = {
          ...options,
          footer: {
            enabled: true,
            legend: `${name} (${variables.length})`
          },
          chart: { searchBar: { enabled: false }, legend: { labelFormat: (params) => `${params.asset.name} - ${params.name}` } },
          variables
        };
        break;
    }
    return options;
  }
}
