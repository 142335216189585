import { Action, createReducer, on } from '@ngrx/store';
import { GraphsDbActions } from '../actions';

export const graphsUiFeatureKey = 'graphsUi';

export interface State {
  graphLoaded: boolean;
  graphLoading: boolean;
}

export const initialState: State = {
  graphLoaded: false,
  graphLoading: false
};

const graphsUiReducer = createReducer(
  initialState,
  on(
    GraphsDbActions.loadGraphsBySiteId,
    GraphsDbActions.loadGraphById,
    GraphsDbActions.addGraph,
    GraphsDbActions.updateGraph,
    GraphsDbActions.deleteGraph,
    GraphsDbActions.updateGraphsDisplayOrder,
    (state: State) => ({ ...state, graphLoaded: false, graphLoading: true })
  ),
  on(
    GraphsDbActions.loadGraphsBySiteIdSuccess,
    GraphsDbActions.loadGraphByIdSuccess,
    GraphsDbActions.addGraphSuccess,
    GraphsDbActions.updateGraphSuccess,
    GraphsDbActions.deleteGraphSuccess,
    GraphsDbActions.updateGraphsDisplayOrderSuccess,
    (state: State) => ({ ...state, graphLoaded: true, graphLoading: false })
  ),
  on(
    GraphsDbActions.loadGraphsBySiteIdFailure,
    GraphsDbActions.loadGraphByIdFailure,
    GraphsDbActions.addGraphFailure,
    GraphsDbActions.updateGraphFailure,
    GraphsDbActions.deleteGraphFailure,
    GraphsDbActions.updateGraphsDisplayOrderFailure,
    (state: State) => ({ ...state, graphLoaded: false, graphLoading: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return graphsUiReducer(state, action);
}

export const getGraphLoaded = (state: State) => state.graphLoaded;
export const getGraphLoading = (state: State) => state.graphLoading;
