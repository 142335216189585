import { Component, effect, input, model, OnInit } from '@angular/core';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { Filter } from '@iot-platform/models/common';
import { get } from 'lodash';
import { AsyncAutocompleteComponent } from '../async-autocomplete/async-autocomplete.component';
import { FormFieldComponent } from '../forms/form-field/form-field.component';

@Component({
  standalone: true,
  imports: [FormFieldComponent],
  selector: 'iot-platform-ui-async-autocomplete-multiple-selects',
  template: ` <iot-platform-ui-form-field [control]="control" [field]="field()" /> `,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ]
})
export class AsyncAutocompleteMultipleSelectsComponent extends AsyncAutocompleteComponent implements OnInit {
  currentFilters = input<Filter[]>([]);
  multiSelect = input<boolean>(true);
  maxFilters = model<number>();

  constructor() {
    super();
    effect(
      () => {
        const currentFilters = this.currentFilters();
        const multiSelect = this.multiSelect();
        const displayKey = this.displayKey();
        if (multiSelect) {
          this.data().forEach((option: any) => {
            const found = currentFilters.find((filter: Filter) => (displayKey ? get(option, displayKey, 'id') === filter.value : option.key === filter.value));
            option.selected = !!found;
          }, []);
        }
      },
      { allowSignalWrites: true }
    );
    effect(
      () => {
        const maxFilters = this.maxFilters();
        const data = this.data();
        if (maxFilters === null || maxFilters === undefined) {
          this.maxFilters.set(data.length);
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.field.update((field) => ({
      ...field,
      disableOnlyOptions: this.disableOnlyOptions,
      maxSelectedItems: this.maxFilters,
      multiple: this.multiSelect
    }));
  }
}
