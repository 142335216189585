import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbar } from '@angular/material/toolbar';
import { UserAccount, UserDateFormats } from '@iot-platform/models/common';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UserProfileService } from '../../services/user-profile.service';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatCardModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIcon,
    MatToolbar,
    ReactiveFormsModule,
    DateFormatPipe
  ],
  selector: 'iot-platform-users-user-profile-preferences-form',
  templateUrl: './user-profile-preferences-form.component.html'
})
export class UserProfilePreferencesFormComponent implements OnInit {
  readonly dialogRef: MatDialogRef<UserProfilePreferencesFormComponent> = inject(MatDialogRef<UserProfilePreferencesFormComponent>);
  private readonly userProfileService: UserProfileService = inject(UserProfileService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroy: DestroyRef = inject(DestroyRef);
  data: { user: UserAccount } = inject(MAT_DIALOG_DATA);

  form: UntypedFormGroup;
  availableLanguages: string[] = this.translateService.langs;
  numberFormatList: string[] = ['fr', 'en'];
  dateFormatList: UserDateFormats[] = [];
  defaultNumberOfDecimals = 3;
  maxNumberOfDecimals = 15;
  numberOfDecimalsList: number[] = [...Array(this.maxNumberOfDecimals).keys()].map((x) => ++x);
  currentDate = moment();

  get appLanguage(): AbstractControl {
    return this.form.get('appLanguage');
  }

  get appNumberFormat(): AbstractControl {
    return this.form.get('appNumberFormat');
  }

  get appNumberOfDecimals(): AbstractControl {
    return this.form.get('appNumberOfDecimals');
  }

  get appDateFormats(): AbstractControl {
    return this.form.get('appDateFormats');
  }

  ngOnInit() {
    this.initForm();
    this.initDateFormatList();
  }

  save(): void {
    this.dialogRef.close({
      ...this.data.user,
      preferences: {
        ...this.data.user.preferences,
        appLanguage: this.appLanguage.value,
        appNumberFormat: this.appNumberFormat.value,
        appNumberOfDecimals: this.appNumberOfDecimals.value,
        appDateFormats: this.appDateFormats.value
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      appLanguage: new UntypedFormControl(this.data?.user?.preferences?.appLanguage ?? 'en'),
      appNumberFormat: new UntypedFormControl(this.data?.user?.preferences?.appNumberFormat ?? 'fr'),
      appNumberOfDecimals: new UntypedFormControl(this.data?.user?.preferences?.appNumberOfDecimals ?? this.defaultNumberOfDecimals),
      appDateFormats: new UntypedFormControl(this.data?.user?.preferences?.appDateFormats ?? {})
    });
  }

  private initDateFormatList(): void {
    this.userProfileService
      .getAppDateFormats()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((formats) => {
        this.dateFormatList = formats;
        if (this.form) {
          this.appDateFormats.setValue(
            this.dateFormatList.find((f) => this.data?.user?.preferences?.appDateFormats?.momentFullFormat === f.momentFullFormat) ?? this.dateFormatList[0]
          );
        }
      });
  }
}
