import { PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { props } from '@ngrx/store';

export class I4BActionHelper<T> {
  getDefaultEvents() {
    return {
      'Add One': props<{ toAdd: T }>(),
      'Add One Success': props<{ added: T }>(),
      'Add One Failure': props<{ error: unknown }>(),
      'Update One': props<{ toUpdate: T }>(),
      'Update One Success': props<{ updated: T }>(),
      'Update One Failure': props<{ error: unknown }>(),
      'Delete One': props<{ toDelete: T }>(),
      'Delete One Success': props<{ deleted: T }>(),
      'Delete One Failure': props<{ error: unknown }>(),
      'Get One': props<{ id: string }>(),
      'Get One Success': props<{ element: T }>(),
      'Get One Failure': props<{ error: unknown }>(),
      'Get Many': props<{ request: PlatformRequest }>(),
      'Get Many Success': props<{ response: PlatformResponse }>(),
      'Get Many Failure': props<{ error: any }>(),
      'Select One': props<{ toSelect: T }>(),
      'Select One Success': props<{ selected: T }>(),
      'Select One Failure': props<{ error: any }>()
    };
  }
}
