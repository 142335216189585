import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import {
  BaseUser,
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  CommonCRUDService,
  CommonIndexedPagination,
  CommonPagination,
  Environment,
  Filter
} from '@iot-platform/models/common';
import { Override, Rotation, TeamPlanning, TeamPlanningRotationOverrideStatus } from '@iot-platform/models/ocm';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamPlanningsService implements CommonCRUDService<TeamPlanning, CommonPagination> {
  constructor(
    @Inject('environment') private environment: Environment,
    private http: HttpClient
  ) {}

  getAll(request: CommonApiRequest): Observable<CommonApiResponse<TeamPlanning, CommonIndexedPagination>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<CommonApiListResponse<TeamPlanning>>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}`, { params }).pipe(
      map((response) => ({
        data: response.content,
        pagination: {
          currentPage: response.page.curPage,
          hasMore: response.page.hasMore,
          limit: response.page.limit,
          maxPage: response.page.maxPage,
          total: response.page.total
        }
      }))
    );
  }

  addOne(toAdd: TeamPlanning): Observable<TeamPlanning> {
    return this.http.post<TeamPlanning>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}`, toAdd);
  }

  updateOne(toUpdate: TeamPlanning): Observable<TeamPlanning> {
    return this.http.put<TeamPlanning>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${toUpdate.id}`, toUpdate);
  }

  deleteOne(toDelete: TeamPlanning): Observable<TeamPlanning> {
    return this.http
      .delete<TeamPlanning>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${toDelete.id}`)
      .pipe(map(() => toDelete));
  }

  getOne(teamPlanningId: string): Observable<TeamPlanning> {
    return this.http.get<TeamPlanning>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${teamPlanningId}`);
  }

  isTeamPlanningNameUnique(initialName: string, name: string, entityId: string): Observable<boolean> {
    if (initialName.toLowerCase() === name.toLowerCase()) {
      return of(true);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('page', '0');
    params = params.append('name', name);
    params = params.append('entityId', entityId);

    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}`, { params })
      .pipe(map((data: any) => data.content.findIndex((item) => item.name.toLowerCase() === name.toLowerCase()) === -1));
  }

  loadMembersByBusinessProfileId(businessProfileId: string): Observable<BaseUser[]> {
    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}/users?businessProfileId=${businessProfileId}`)
      .pipe(map((response) => response.content));
  }

  private static calculateStatus(iterable: Rotation | Override): TeamPlanningRotationOverrideStatus {
    if (moment().isBetween(moment(iterable.localStartDate), moment(iterable.localEndDate), 'day', '[]')) {
      return TeamPlanningRotationOverrideStatus.current;
    } else if (moment().isAfter(moment(iterable.localEndDate))) {
      return TeamPlanningRotationOverrideStatus.past;
    } else if (moment().isBefore(moment(iterable.localStartDate))) {
      return TeamPlanningRotationOverrideStatus.scheduled;
    }
  }

  private static addStatusToRotationsAndExceptions(obj) {
    obj.status = TeamPlanningsService.calculateStatus(obj);
    return obj;
  }

  getRotationsByPlanningId(planningId: string): Observable<Rotation[]> {
    return this.http
      .get<
        CommonApiListResponse<Rotation>
      >(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.rotations}`)
      .pipe(map((response) => response.content.map((rotation) => TeamPlanningsService.addStatusToRotationsAndExceptions(rotation))));
  }

  addRotation(toAdd: Rotation, planningId: string): Observable<Rotation> {
    return this.http
      .post<Rotation>(
        `${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.rotations}`,
        toAdd
      )
      .pipe(map((rotation) => TeamPlanningsService.addStatusToRotationsAndExceptions(rotation)));
  }

  updateRotation(toUpdate: Rotation, planningId: string): Observable<Rotation> {
    return this.http
      .put<Rotation>(
        `${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.rotations}/${toUpdate.id}`,
        toUpdate
      )
      .pipe(map((rotation) => TeamPlanningsService.addStatusToRotationsAndExceptions(rotation)));
  }

  deleteRotation(toDelete: Rotation, planningId: string): Observable<Rotation> {
    return this.http
      .delete<Rotation>(
        `${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.rotations}/${toDelete.id}`
      )
      .pipe(map((_) => toDelete));
  }

  getOverridesByPlanningId(planningId: string): Observable<Override[]> {
    return this.http
      .get<
        CommonApiListResponse<Override>
      >(`${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.overrides}`)
      .pipe(map((response) => response.content.map((override) => TeamPlanningsService.addStatusToRotationsAndExceptions(override))));
  }

  addOverride(toAdd: Override, planningId: string): Observable<Override> {
    return this.http
      .post<Override>(
        `${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.overrides}`,
        toAdd
      )
      .pipe(map((override) => TeamPlanningsService.addStatusToRotationsAndExceptions(override)));
  }

  addManyOverrides(toAdd: Override[], planningId: string): Observable<Override[]> {
    return forkJoin(toAdd.map((override: Override) => this.addOverride(override, planningId)));
  }

  updateOverride(toUpdate: Override, planningId: string): Observable<Override> {
    return this.http
      .put<Override>(
        `${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.overrides}/${toUpdate.id}`,
        toUpdate
      )
      .pipe(map((override) => TeamPlanningsService.addStatusToRotationsAndExceptions(override)));
  }

  updateManyOverrides(toUpdate: Override[], planningId: string): Observable<Override[]> {
    return forkJoin(toUpdate.map((override: Override) => this.updateOverride(override, planningId)));
  }

  deleteOverride(toDelete: Override, planningId: string): Observable<Override> {
    return this.http
      .delete<Override>(
        `${this.environment.api.url}${this.environment.api.endpoints.teamPlannings}/${planningId}${this.environment.api.endpoints.overrides}/${toDelete.id}`
      )
      .pipe(map((_) => toDelete));
  }
}
