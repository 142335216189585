import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';

import { AssetEventsByAssetDbActions, AssetEventsByAssetUiActions } from '../../actions';

export const assetEventsByAssetUiFeatureKey = 'assetEventsByAssetUi';

export interface State {
  error?: any;
  assetEventsLoaded: boolean;
  assetEventsLoading: boolean;
  totalActiveEventsLoaded: boolean;
}

export const initialState: State = {
  error: null,
  assetEventsLoaded: false,
  assetEventsLoading: false,
  totalActiveEventsLoaded: false
};

const assetEventsByAssetUiReducer = createReducer(
  initialState,
  on(AssetEventsByAssetUiActions.loadAssetEventsByAsset, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsByAssetDbActions.loadAssetEventsByAssetSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsByAssetDbActions.loadAssetEventsByAssetFailure, (state: State, { error }) => ({
    ...state,
    assetEventsLoading: false,
    assetEventsLoaded: false,
    error
  })),
  // ****
  on(AssetEventsByAssetUiActions.loadTotalActiveAssetEventsByAsset, (state: State) => ({ ...state, totalActiveEventsLoaded: false })),
  on(AssetEventsByAssetDbActions.loadTotalActiveAssetEventsByAssetSuccess, (state: State) => ({ ...state, totalActiveEventsLoaded: true })),
  on(AssetEventsByAssetDbActions.loadTotalActiveAssetEventsByAssetFailure, (state: State, { error }) => ({ ...state, totalActiveEventsLoaded: false, error })),
  // // ****
  on(AssetEventsByAssetUiActions.bulkUpdateStatusByAssetEventIdByAsset, (state: State) => ({ ...state, assetEventsLoaded: false })),
  on(AssetEventsByAssetDbActions.updateStatusByAssetEventIdByAssetSuccess, (state: State) => ({ ...state, assetEventsLoaded: true })),
  on(AssetEventsByAssetDbActions.updateStatusByAssetEventIdByAssetFailure, (state: State, { error }) => ({ ...state, assetEventsLoaded: false, error })),
  // ****
  on(AssetEventsByAssetUiActions.loadMvAssetEventsByAssetSettings, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsByAssetDbActions.loadMvAssetEventsByAssetSettingsSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsByAssetDbActions.loadMvAssetEventsByAssetSettingsFailure, (state: State, { error }) => ({
    ...state,
    assetEventsLoading: false,
    assetEventsLoaded: false,
    error
  })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByAssetUiReducer(state, action);
}

export const getAssetEventsLoaded = (state: State) => state.assetEventsLoaded;
export const getAssetEventsLoading = (state: State) => state.assetEventsLoading;
export const getTotalActiveEventsLoaded = (state: State) => state.totalActiveEventsLoaded;
