<div data-cy="iot4bos-ui-event-topic-list">
  <section class="event-topic-list-header fw-600" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <span fxFlex="18px"></span>
    <span class="capitalize-first-letter" fxFlex="35%">{{ 'IOT_DICTIONARY.NAME' | translate }}</span>
    <span class="capitalize-first-letter" fxFlex="35%">{{ 'IOT_DICTIONARY.ENTITY' | translate }}</span>
    <span class="capitalize-first-letter" fxFlex="25%">{{ 'IOT_DICTIONARY.VIEW' | translate }}</span>
  </section>
  @if (topics) {
    <section fxLayout="column">
      @for (topic of topics; track topic.id) {
        <div class="event-topic-list-topic">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="35%">{{ topic.name }}</span>
            <span fxFlex="35%">{{ topic.entity.name }}</span>
            @if (topic.view) {
              <span fxFlex="25%" class="capitalize-first-letter">{{ 'IOT_DICTIONARY.' + topic.view | translate }}</span>
            }
          </div>
        </div>
      }
    </section>
  }
</div>
