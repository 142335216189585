import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumber } from '@iot-platform/models/common';
import { PhoneNumberUtils } from '../utils';

export class PhoneNumberValidators {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control?.value || control.value.length === 0) {
        return null;
      }
      const result: PhoneNumber = PhoneNumberUtils.parsePhoneNumber(control.value);
      return result.isValid ? null : { invalidPhoneNumber: { ...result } };
    };
  }
}
