import { PlatformRequest } from '@iot-platform/models/common';
import { AssetEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssetEventsByAsset = createAction('[AssetEventsByAsset] Load Asset Events By Asset', props<{ request: PlatformRequest }>());
//
export const loadMvAssetEventsByAssetSettings = createAction('[AssetEventsByAsset] Load MV AssetEventsByAsset Settings', props<{ settingName: string }>());
//
export const loadTotalActiveAssetEventsByAsset = createAction(
  '[AssetEventsByAsset] Load Total Active Asset Events By Asset',
  props<{ request: PlatformRequest }>()
);
//
export const saveTableStateByAsset = createAction(
  '[AssetEventsByAsset] Save Table State',
  props<{ tableState: { checked: AssetEvent[]; selected: AssetEvent } }>()
);
//
export const updateStatusByAssetEventIdByAsset = createAction(
  '[AssetEventsByAsset] Update Status By Asset Event Id',
  props<{ status: { assetEventId: string; value: string } }>()
);
//
export const bulkUpdateStatusByAssetEventIdByAsset = createAction(
  '[AssetEventsByAsset] Bulk Update Status By Asset Event Id',
  props<{ assetEventIds: string[]; status: string }>()
);
//
export const clearData = createAction('[AssetEventsByAsset] Clear Data');
