import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridNameCellService {
  grids$: BehaviorSubject<I4BGrid<I4BGridOptions, I4BGridData>[]> = new BehaviorSubject<I4BGrid<I4BGridOptions, I4BGridData>[]>([]);

  constructor(@Inject('environment') private readonly environment, private readonly http: HttpClient) {}

  public getGridNameById(gridId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.grids$.pipe(
      switchMap((grids: I4BGrid<I4BGridOptions, I4BGridData>[]) => {
        const found = grids.find((grid) => grid.id === gridId);
        if (found) {
          return of(found);
        } else {
          return this.http.get<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grid}/${gridId}`).pipe(
            tap((grid) => {
              grids.push(grid);
              this.grids$.next(grids);
            })
          );
        }
      })
    );
  }
}
