<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ADMIN.TRANSLATION_MANAGEMENT.EDIT_FORM.TITLE' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content">
    <form [formGroup]="editFrom" class="full-width p-20" fxLayout="column">
      @for (item of translations; track item.key) {
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
          <mat-form-field color="accent" fxFlex>
            <mat-label>{{ item.key | translate | uppercase }}</mat-label>
            <input [formControlName]="item.key" [required]="item.required" matInput />
            @if (editFrom?.get(item.key)?.invalid) {
              <mat-error>{{ 'ADMIN.TRANSLATION_MANAGEMENT.ERROR_MESSAGE.REQUIRED_KEY' | translate }}</mat-error>
            }
          </mat-form-field>
        </div>
      }
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
    <button (click)="save()" [disabled]="!editFrom.valid || !editFrom.touched" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.UPDATE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
