import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { SortUtil } from '@iot-platform/iot-platform-utils';

import { Role } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-ui-backoffice-roles-crud',
  templateUrl: './roles-crud.component.html',
  styleUrls: ['./roles-crud.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesCrudComponent implements OnChanges {
  @Input() role: Role;
  @Input() isAdmin: boolean;
  @Input() adminConceptsFromEntitySession: [string];
  @Input() readonly: boolean;

  @Output() authorizationsChanged: EventEmitter<any> = new EventEmitter();

  enabledConcepts: any[] = [];
  disabledConcepts: any[] = [];
  availableConcepts: any[] = [];
  rights: any[] = [];

  hasAdminConcepts;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('role') && changes.role.currentValue && changes.role.currentValue.rights) {
      this.role = { ...changes.role.currentValue, rights: { ...changes.role.currentValue.rights } };

      if (!changes.role.previousValue || this.readonly) {
        this.setMatrixRights(this.role, true);
      } else {
        this.setMatrixRights(this.role, false);
      }
    }
  }

  setMatrixRights(role: Role, sortConcepts: boolean): void {
    this.enabledConcepts = [];
    this.disabledConcepts = [];
    this.availableConcepts = [];

    Object.entries(role.rights).forEach(([key, value]) => {
      if (this.adminConceptsFromEntitySession && this.adminConceptsFromEntitySession.indexOf(key) > -1) {
        this.enabledConcepts.push({ name: key, authorizations: value, enabled: true });
      }
      if ((this.adminConceptsFromEntitySession && this.adminConceptsFromEntitySession.indexOf(key) === -1) || !this.adminConceptsFromEntitySession) {
        this.disabledConcepts.push({ name: key, authorizations: value, enabled: false });
      }
    });

    if (this.adminConceptsFromEntitySession) {
      this.hasAdminConcepts = true;
      this.adminConceptsFromEntitySession.forEach((concept) => {
        if (this.enabledConcepts.find((cpt) => cpt.name === concept) === undefined) {
          this.availableConcepts.push({ name: concept });
        }
      });
    } else {
      this.hasAdminConcepts = false;
    }

    if (sortConcepts) {
      this.enabledConcepts = [...this.enabledConcepts.sort(SortUtil.sortByName)];
      this.disabledConcepts = [...this.disabledConcepts.sort(SortUtil.sortByName)];
      this.availableConcepts = [...this.availableConcepts.sort(SortUtil.sortByName)];
    }
  }

  createNewRightsMatrix() {
    return this.enabledConcepts.reduce((acc, value) => {
      acc[value.name] = value.authorizations;
      return acc;
    }, {});
  }

  onToggleAuthorizations(newAuthorizations: string[], oldConcept: any): void {
    const conceptToUpdate = this.enabledConcepts.find((concept) => concept.name === oldConcept.name);
    conceptToUpdate.authorizations = newAuthorizations;
    this.authorizationsChanged.emit(this.createNewRightsMatrix());
  }

  moveConceptToAvailable(concept): void {
    this.enabledConcepts.splice(this.enabledConcepts.indexOf(concept), 1);
    this.availableConcepts = [...this.availableConcepts, { ...concept, authorizations: [], enabled: false }];
    this.authorizationsChanged.emit(this.createNewRightsMatrix());
  }

  moveConceptToEnabled(concept): void {
    this.availableConcepts.splice(this.availableConcepts.indexOf(concept), 1);
    this.enabledConcepts = [...this.enabledConcepts, { ...concept, authorizations: [], enabled: true }];
    this.authorizationsChanged.emit(this.createNewRightsMatrix());
  }
}
