<mat-chip-set fxFlex>
  @for (label of tagCategory()?.labels; track label.id) {
    <mat-chip-option [selectable]="false" [style.background-color]="tagCategory()?.color" disableRipple>
      @if (labelOnly()) {
        <span>{{ label.name }}</span>
      } @else {
        <span>{{ tagCategory()?.name }}: {{ label.name }}</span>
      }
    </mat-chip-option>
  }
</mat-chip-set>
