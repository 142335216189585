import { Role } from '@iot-platform/models/common';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const listRolesByOrganizationSuccess = createAction('[Organizations/Admin Page] List Roles By Organization Success', props<{ roles: Role[] }>());
export const listRolesByOrganizationFailure = createAction('[Organizations/Admin Page] List Roles By Organization Failure', props<{ error: any }>());

export const selectRoleInOrganizationSuccess = createAction(
  '[Organizations/Roles/Admin Page] Select Role In Organization Success',
  props<{ selectedRole: Role }>()
);
export const selectRoleInOrganizationFailure = createAction('[Organizations/Roles/Admin Page] Select Role In Organization Failure', props<{ error: any }>());

export const updateRoleSuccess = createAction('[Organizations/Role/Admin Page] Update Role Success', props<{ updatedRole: Update<Role> }>());
export const updateRoleFailure = createAction('[Organizations/Role/Admin Page] Update Role Failure', props<{ error: any }>());

export const addRoleToOrganizationSuccess = createAction('[Organizations/Admin Page] Add Role to Organization Success', props<{ addedRole: Role }>());
export const addRoleToOrganizationFailure = createAction('[Organizations/Admin Page] Add Role to Organization Failure', props<{ error: any }>());

export const removeRoleFromOrganizationSuccess = createAction(
  '[Organizations/Admin Page] Remove Role From Organization Success',
  props<{ removedRole: Role }>()
);
export const removeRoleFromOrganizationFailure = createAction('[Organizations/Admin Page] Remove Role From Organization Failure', props<{ error: any }>());
