import { PlatformResponse } from '@iot-platform/models/common';

import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadDeviceEventsByDeviceSuccess = createAction(
  '[DeviceEventsByDevice] Load Device Events By Device Success',
  props<{ response: PlatformResponse; deviceId: string }>()
);
export const loadDeviceEventsByDeviceFailure = createAction('[DeviceEventsByDevice] Load Device Events By Device Failure', props<{ error: any }>());
//
export const loadTotalActiveDeviceEventsByDeviceSuccess = createAction(
  '[DeviceEventsByDevice] Load Total Active Device Events By Device Success',
  props<{ totalActiveEvents: number }>()
);
export const loadTotalActiveDeviceEventsByDeviceFailure = createAction(
  '[DeviceEventsByDevice] Load Total Active Device Events By Device Failure',
  props<{ error: any }>()
);
//
export const saveTableByDeviceStateSuccess = createAction(
  '[DeviceEventsByDevice] Save Table State Success',
  props<{ selectedId: string; checkedIds: string[] }>()
);
export const saveTableByDeviceStateFailure = createAction('[DeviceEventsByDevice] Save Table State Failure', props<{ error: any }>());

export const updateStatusByDeviceEventIdByDeviceSuccess = createAction(
  '[DeviceEventsByDevice] Update Status By Device Event Id Success',
  props<{ deviceEvent: DeviceEvent }>()
);
export const updateStatusByDeviceEventIdByDeviceFailure = createAction(
  '[DeviceEventsByDevice] Update Status By Device Event Id Failure',
  props<{ error: any }>()
);
//
export const bulkUpdateStatusByDeviceEventIdByDeviceSuccess = createAction(
  '[DeviceEventsByDevice] Bulk Update Status By Device Event Id Success',
  props<{ deviceEvents: DeviceEvent[] }>()
);
export const bulkUpdateStatusByDeviceEventIdFailure = createAction(
  '[DeviceEventsByDevice] Bulk Update Status By Device Event Id Failure',
  props<{ error: any }>()
);
//
export const loadMvDeviceEventsByDeviceSettingsSuccess = createAction(
  '[DeviceEventsByDevice] Load MV DeviceEventsByDevice Settings Success',
  props<{ settings: any }>()
);
export const loadMvDeviceEventsByDeviceSettingsFailure = createAction(
  '[DeviceEventsByDevice] Load MV DeviceEventsByDevice Settings Failure',
  props<{ error: any }>()
);
