import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CustomOptionsCellParams } from '../../../models/custom-options-cell.params';

@Component({
  standalone: true,
  selector: 'grid-engine-translated-cell',
  templateUrl: './translated-cell.component.html',
  imports: [NgClass, NgStyle, TranslateModule],
  styleUrls: ['./translated-cell.component.scss']
})
export class TranslatedCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomOptionsCellParams> = signal(null);

  translateKey: Signal<string> = computed(() => {
    const params = this.params();
    return params?.cellOptions?.translateKey ?? 'IOT_DICTIONARY.';
  });

  translateStyle: Signal<any> = computed(() => {
    const params = this.params();
    return params?.cellOptions?.class ? params.cellOptions.class[this.processValue(params?.value)] : '';
  });

  agInit(params: CustomOptionsCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  processValue(value: string): string {
    return value
      ?.toLowerCase()
      ?.split('_')
      ?.reduce((acc, v) => {
        if (acc && v) {
          return acc + v[0].toUpperCase() + v.slice(1);
        }
        return v;
      }, '');
  }
}
