import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

export abstract class AbstractFavoriteFilterEngineService {
  abstract getAccount$(): Observable<UserAccount>;

  abstract getUserPreferences$(): Observable<UserPreferences>;

  abstract saveUserPreferences(user: UserAccount, preferences: UserPreferences): void;
}
