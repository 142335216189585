import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';
import { InfoDisplayPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { RichVariableValueCellHelpers } from '../../../helpers/rich-variable-value-cell.helpers';
import { RichValueCellParams } from '../../../models/rich-value-cell.params';
// eslint-disable-next-line max-len
import { AssetVariableConfigureReadPopupComponent } from '../../grid-manager-popups/asset-variable-configure-read-popup/asset-variable-configure-read-popup.component';

@Component({
  standalone: true,
  selector: 'grid-engine-rich-variable-value-cell',
  templateUrl: './rich-variable-value-cell.component.html',
  imports: [FlexLayoutModule, TranslateModule, ValueUnitFormatPipe, InfoDisplayPipe, MatIcon, MatTooltip],
  providers: [ValueUnitFormatPipe, InfoDisplayPipe],
  styleUrls: ['./rich-variable-value-cell.component.scss']
})
export class RichVariableValueCellComponent implements ICellRendererAngularComp {
  dialog: MatDialog = inject(MatDialog);

  params: WritableSignal<RichValueCellParams> = signal(null);
  displayInfoIcon: WritableSignal<boolean> = signal(false);
  variable: Signal<Partial<AssetVariable> | Partial<DeviceVariable> | Partial<FollowedVariable>> = computed(() => {
    const params = this.params();
    return RichVariableValueCellHelpers.getVariable(params);
  });
  displayUnit: Signal<boolean> = computed(() => {
    const params = this.params();
    return get(params, ['cellOptions', 'displayUnit'], true);
  });
  canReadGraph: Signal<boolean> = computed(() => {
    const params = this.params();
    return params?.userPermissions?.find(({ key }) => key === 'canReadGraph')?.value ?? false;
  });
  canReadEvent: Signal<boolean> = computed(() => {
    const params = this.params();
    return params?.userPermissions?.find(({ key }) => key === 'canReadEvent')?.value ?? false;
  });
  minimalDisplay: Signal<boolean> = computed(() => {
    const params = this.params();
    return get(params, ['cellOptions', 'minimalDisplay'], false);
  });
  eventIconProperties: Signal<{ isDisplayed: boolean; tooltip: string; iconName: string }> = computed(() => {
    const canReadEvent = this.canReadEvent();
    const variable = this.variable();
    if (canReadEvent && !!get(variable, ['totalActiveEvents']) && !get(variable, ['totalAcknowledgedEvents'])) {
      return {
        iconName: 'active',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACTIVE_EVENTS'
      };
    } else if (canReadEvent && !get(variable, ['totalActiveEvents']) && !!get(variable, ['totalAcknowledgedEvents'])) {
      return {
        iconName: 'acknowledged',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACKNOWLEDGED_EVENTS'
      };
    } else if (canReadEvent && !!get(variable, ['totalActiveEvents']) && !!get(variable, ['totalAcknowledgedEvents'])) {
      return {
        iconName: 'ongoing',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACTIVE_AND_ACKNOWLEDGED_EVENTS'
      };
    }
    return { iconName: '', isDisplayed: false, tooltip: '' };
  });
  displayGraphIcon: Signal<boolean> = computed(() => {
    const canReadGraph = this.canReadGraph();
    const variable = this.variable();
    return canReadGraph && !!(variable as Partial<AssetVariable> | Partial<FollowedVariable>)?.totalGraphs;
  });

  agInit(params: RichValueCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    const minimalDisplay = this.minimalDisplay();
    const params = this.params();
    if (!minimalDisplay) {
      params.dispatchEvent({
        type: params.eventConfiguration.type,
        options: params.eventConfiguration.options,
        rawData: params.data
      });
    }
  }

  openFollowedVariableEvents(event: MouseEvent): void {
    event.stopPropagation();
    const params = this.params();
    const followedVariable = this.variable();
    params.dispatchEvent({
      type: 'openFollowedVariableEvents',
      options: params.eventConfiguration.options,
      rawData: { asset: params.data, followedVariable }
    });
  }

  openGraphsByVariable(event: MouseEvent): void {
    event.stopPropagation();
    const params = this.params();
    const followedVariable = this.variable();
    let siteId: string = get(params, 'data.site.id', null);
    if (params?.cellOptions?.dataType === 'event' || !siteId) {
      siteId = get(params, 'data.context.site.id');
    }
    params.dispatchEvent({
      type: 'openGraphsByVariable',
      options: params.eventConfiguration.options,
      rawData: { asset: params.data, followedVariable, siteId }
    });
  }

  toggleIconInfo(displayIcon: boolean, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const params = this.params();
    const variable = this.variable();
    this.displayInfoIcon.set(displayIcon && !!variable?.linked && !params.data.hasOwnProperty('device'));
  }

  openVariableConfiguration(event: MouseEvent): void {
    event.stopPropagation();
    const params = this.params();
    const assetVariable = this.variable();
    this.dialog.open(AssetVariableConfigureReadPopupComponent, {
      data: {
        assetVariable,
        asset: !!params.colDef.field ? params.data : get(params.data, 'asset')
      }
    });
  }
}
