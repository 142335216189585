import { EntityAdapter } from '@ngrx/entity';
import { Dictionary, EntitySelectors } from '@ngrx/entity/src/models';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { BaseSelector } from './base-selector.model';
import { BaseState } from './base-state.model';

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-types */
export abstract class ApiSelector<S, T, P, F> extends BaseSelector<S, BaseState<T, P, F>> {
  selectAdapter: EntitySelectors<T, object>;

  selectIds: (state: object) => string[] | number[];
  selectEntities: (state: object) => Dictionary<T>;
  selectAll: (state: object) => T[];
  selectTotal: (state: object) => number;

  selectSelectedEntity: MemoizedSelector<BaseState<T, P, F>, '' | T | undefined | null>;
  selectCurrentEntity: MemoizedSelector<object, T | null>;
  selectId: MemoizedSelector<BaseState<T, P, F>, string | null>;
  selectPagination: MemoizedSelector<object, P | null>;
  selectPlatformResponse: MemoizedSelector<
    object,
    {
      data: T[];
      currentPage: number;
      hasMore: boolean;
      limit: number;
      maxPage: number;
      total: number;
    }
  >;
  selectLoading: MemoizedSelector<object, boolean>;
  selectLoaded: MemoizedSelector<object, boolean>;
  selectFilters: MemoizedSelector<object, F[]>;
  selectError: MemoizedSelector<object, unknown>;

  protected constructor(public parentFeatureKey: string, public featureKey: string, public adapter: EntityAdapter<T>) {
    super(parentFeatureKey, featureKey);
    this.selectAdapter = adapter?.getSelectors(this.selectState);

    this.selectIds = this.selectAdapter.selectIds;
    this.selectEntities = this.selectAdapter.selectEntities;
    this.selectAll = this.selectAdapter.selectAll;
    this.selectTotal = this.selectAdapter.selectTotal;

    this.selectCurrentEntity = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.entity);
    this.selectId = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.selectedId);
    this.selectSelectedEntity = createSelector(this.selectEntities, this.selectId, (entities, selectedId) => selectedId && entities[selectedId]);
    this.selectPagination = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.pagination);
    this.selectPlatformResponse = createSelector(this.selectAll, this.selectPagination as MemoizedSelector<object, P>, (data: T[], pagination: P) => {
      const p: any = { ...pagination };
      return {
        data,
        currentPage: p.currentPage,
        hasMore: p.hasMore,
        limit: p.limit,
        maxPage: p.maxPage,
        total: p.total
      };
    });

    this.selectLoading = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.loading);
    this.selectLoaded = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.loaded);
    this.selectFilters = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.filters);
    this.selectError = createSelector(this.selectState, (state: BaseState<T, P, F>) => state.error);
  }
}
