import { PlatformRequest } from '@iot-platform/models/common';

import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadDeviceEventsByDevice = createAction('[DeviceEventsByDevice] Load Device Events By Device', props<{ request: PlatformRequest }>());
//
export const loadMvDeviceEventsByDeviceSettings = createAction(
  '[DeviceEventsByDevice] Load MV DeviceEventsByDevice Settings',
  props<{ settingName: string }>()
);
//
export const loadTotalActiveDeviceEventsByDevice = createAction(
  '[DeviceEventsByDevice] Load Total Active Device Events By Device',
  props<{ request: PlatformRequest }>()
);
//
export const saveTableStateByDevice = createAction(
  '[DeviceEventsByDevice] Save Table State',
  props<{ tableState: { checked: DeviceEvent[]; selected: DeviceEvent } }>()
);
//
export const updateStatusByDeviceEventIdByDevice = createAction(
  '[DeviceEventsByDevice] Update Status By Device Event Id',
  props<{ status: { deviceEventId: string; value: string } }>()
);
//
export const bulkUpdateStatusByDeviceEventIdByDevice = createAction(
  '[DeviceEventsByDevice] Bulk Update Status By Device Event Id',
  props<{ deviceEventIds: string[]; status: string }>()
);
