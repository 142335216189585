import { inject } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { DevicesService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, of, map, tap, concatMap } from 'rxjs';
import { DeviceCommentsActions } from '../actions';
import { NotificationService } from '@iot-platform/notification';

const loadDeviceComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCommentsActions.loadComments),
      concatMap(({ device }) =>
        devicesService.loadComments(device).pipe(
          map((comments: Log[]) => DeviceCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(DeviceCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCommentsActions.addComment),
      concatMap(({ deviceId, comment }) =>
        devicesService.addComment(deviceId, comment).pipe(
          map((addedComment: Log) => DeviceCommentsActions.addCommentSuccess({ comment: addedComment })),
          catchError((error) => of(DeviceCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCommentsActions.editComment),
      concatMap(({ deviceId, comment }) =>
        devicesService.editComment(deviceId, comment).pipe(
          map((editedComment: Log) => DeviceCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(DeviceCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCommentsActions.deleteComment),
      concatMap(({ deviceId, commentId }) =>
        devicesService.deleteComment(deviceId, commentId).pipe(
          map((deletedCommentId: string) => DeviceCommentsActions.deleteCommentSuccess({ commentId: deletedCommentId })),
          catchError((error) => of(DeviceCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCommentsActions.loadComments, DeviceCommentsActions.addComment, DeviceCommentsActions.editComment, DeviceCommentsActions.deleteComment),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceCommentsActions.loadCommentsSuccess,
        DeviceCommentsActions.loadCommentsFailure,
        DeviceCommentsActions.addCommentSuccess,
        DeviceCommentsActions.addCommentFailure,
        DeviceCommentsActions.editCommentSuccess,
        DeviceCommentsActions.editCommentFailure,
        DeviceCommentsActions.deleteCommentSuccess,
        DeviceCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCommentsActions.addCommentSuccess, DeviceCommentsActions.editCommentSuccess, DeviceCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceCommentsActions.loadCommentsFailure,
        DeviceCommentsActions.addCommentFailure,
        DeviceCommentsActions.editCommentFailure,
        DeviceCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const DeviceCommentsEffects = {
  loadDeviceComments$,
  addComment$,
  editComment$,
  deleteComment$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
