import { HeaderType } from '@iot-platform/models/grid-engine';

export const productCatalogsMetadataSettings = {
  metadata: {
    url: '/product-catalogs',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'product-catalogs',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      pageSize: 10,
      refreshDelay: 120,
      multipleSelection: false,
      renderer: 'table',
      enableConditions: [],
      visibleConditions: [],
      columns: [],
      buttonColumn: {
        id: 'button',
        name: '',
        order: 90,
        width: '3%',
        headerType: HeaderType.BASIC,
        cellType: 'button',
        type: 'center center',
        enableConditions: [[{ key: 'isEditable', value: true }]],
        visibleConditions: [],
        singleActions: [
          {
            key: 'edit',
            label: 'IOT_DICTIONARY.EDIT',
            enableConditions: [[{ key: 'isEditable', value: true }]],
            visibleConditions: []
          },
          {
            key: 'delete',
            label: 'IOT_DICTIONARY.DELETE',
            enableConditions: [[{ key: 'isEditable', value: true }]],
            visibleConditions: []
          }
        ]
      },
      expandedPanel: {}
    }
  },
  masterViewComments: {}
};
