<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">
        {{ 'USERS.PROFILE_PREFERENCES.EDIT_PREFERENCES' | translate: { firstName: data.user?.firstname, lastName: data.user?.lastname } }}
      </h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20">
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate }}</mat-label>
          <mat-select formControlName="appLanguage" required>
            @for (language of availableLanguages; track language) {
              <mat-option [value]="language"> {{ 'USERS.PROFILE_PREFERENCES.LANGUAGES.' + language | translate }} </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'USERS.PROFILE_PREFERENCES.DATE_FORMAT' | translate }}</mat-label>
          <mat-select formControlName="appDateFormats" required>
            @for (dateFormat of dateFormatList; track dateFormat) {
              <mat-option [value]="dateFormat">{{ currentDate | dateFormat: dateFormat.momentFullFormat }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'USERS.PROFILE_PREFERENCES.NUMBER_FORMAT' | translate }}</mat-label>
          <mat-select formControlName="appNumberFormat" required>
            @for (numberFormat of numberFormatList; track numberFormat) {
              <mat-option [value]="numberFormat"> {{ 'USERS.PROFILE_PREFERENCES.NUMBER_FORMATS.' + numberFormat | translate }} </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'USERS.PROFILE_PREFERENCES.NUMBER_OF_DECIMALS' | translate }}</mat-label>
          <mat-select formControlName="appNumberOfDecimals" required>
            @for (numberOfDecimals of numberOfDecimalsList; track numberOfDecimals) {
              <mat-option [value]="numberOfDecimals">{{ numberOfDecimals }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!form.valid || !form.touched" class="button-regular" color="accent" mat-raised-button>
      {{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
