<div class="default-theme app-shell" data-cy="feature-app-shell">
  <mat-sidenav-container (backdropClick)="closePreferences()" class="main-container">
    <mat-sidenav #sidenav [opened]="sidenavState().isOpen" class="navigation-bar" data-cy="feature-app-shell-sidenav"
                 mode="side">
      <div class="navigation-bar-content" fxFlex fxLayout="column" fxLayoutAlign="space-between">
        @if (navigationTree()) {
          <mat-nav-list class="sidenav">
            @for (navBlock of navigationTree(); track $index) {
              <iot-platform-ui-sidenav-block data-cy="feature-app-shell-sidenav-block"
                                             [navBlock]="navBlock"></iot-platform-ui-sidenav-block>
            } @empty {
              ...
            }
          </mat-nav-list>
        }
        <iot-platform-ui-build-info (openReleaseNotes)="openReleaseNotes()" class="sticky"></iot-platform-ui-build-info>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="main-sidenav-content">
      <div class="sticky">
        <iot-platform-feature-app-shell-header
          (changeBusinessProfile)="onChangeBusinessProfile($event)"
          (logoClicked)="returnHome()"
          (logout)="logout()"
          (menuClicked)="toggleSidenav()"
          (myProfile)="openMyProfile()"
          [allowRedirectToMyProfile]="settings.allowRedirectToMyProfile"
          [allowSwitchBusinessProfile]="settings.allowSwitchBusinessProfile"
          [businessProfiles]="businessProfiles()"
          [currentUserLoading]="currentUserLoading()"
          [currentUser]="currentUser()"
          [logo]="settings.logo"
          [selectedBusinessProfile]="businessProfile()"
          data-cy="feature-app-shell-header"
        >
        </iot-platform-feature-app-shell-header>
      </div>
      <div class="main-sidenav-body">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
