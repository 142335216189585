<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content" fxLayout="row">
    <iot-platform-ui-image-selector
      (selectImage)="onSelectImage($event)"
      [galleryPath]="'devices-gallery.json'"
      [lastImageSaved]="imageUrl"
      class=""
    ></iot-platform-ui-image-selector>
    <form [formGroup]="deviceForm" class="full-width p-20" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.DEVICE_NAME' | translate }}</mat-label>
          <input #nameInput formControlName="name" matInput maxlength="30" pattern="\S.*" required />
          @if (name.invalid && (name.errors.maxlength || name.errors.pattern)) {
            <mat-error>{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.NAME' | translate }}</mat-error>
          }
          @if (name.invalid && name.errors.duplicate) {
            <mat-error>{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ nameInput.value?.length || 0 }}/30</mat-hint>
        </mat-form-field>
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.IDENTIFIER' | translate }}</mat-label>
          <input formControlName="identifier" matInput />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.FAMILY' | translate }}</mat-label>
          <input formControlName="family" matInput maxlength="20" required />
          @if (family.invalid) {
            <mat-error>{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.FAMILY' | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.MODEL' | translate }}</mat-label>
          <input formControlName="model" matInput maxlength="20" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.INCOMING_CONNECTOR' | translate }}</mat-label>
          <mat-select formControlName="incomingConnector">
            @for (connector of allConnectors; track connector.id) {
              <mat-option [value]="connector">{{ connector.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.OUTGOING_CONNECTOR' | translate }}</mat-label>
          <mat-select (selectionChange)="onOutgoingSelectorChange($event.value)" formControlName="outgoingConnector">
            @for (connector of allConnectors; track connector.id) {
              <mat-option [value]="connector">{{ connector.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.STATUS' | translate }}</mat-label>
          <mat-select formControlName="status" required>
            @for (status of allStatusAllowed; track status) {
              <mat-option [value]="status">{{ 'DEVICES.CARD.STATUS_LIST.' + status | translate }}</mat-option>
            }
          </mat-select>
          @if (status.invalid) {
            <mat-error>{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.STATUS' | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.ENDPOINT' | translate }}</mat-label>
          <input #endpointInput formControlName="endpoint" matInput maxlength="50" />
          @if (endpoint.invalid) {
            <mat-error>{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.ENDPOINT' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ endpointInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.LOGIN' | translate }}</mat-label>
          <input formControlName="login" matInput />
        </mat-form-field>
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.PASSWORD' | translate }}</mat-label>
          <input [type]="hide ? 'password' : 'text'" formControlName="password" matInput />
          <mat-icon (click)="togglePassword()" class="password-toggle" matIconSuffix>{{ hide ? 'visibility_off' : 'visibility' }} </mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.SUPPORT' | translate }}</mat-label>
          <input formControlName="support" matInput maxlength="30" />
        </mat-form-field>
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'DEVICES.INFO_FORM.DESCRIPTION' | translate }}</mat-label>
          <textarea #descriptionInput formControlName="description" matInput maxlength="300"></textarea>
          <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/300</mat-hint>
        </mat-form-field>
      </div>
      <p class="dialog-form-tips">{{ 'DEVICES.INFO_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'DEVICES.INFO_FORM.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!deviceForm.valid" class="button-regular" color="accent" mat-raised-button>{{ action$ | async }}</button>
  </mat-card-actions>
</mat-card>
