<div fxLayoutAlign="start center" class="followed-variable-header">
  <span fxFlex class="fw-600">{{ 'ASSETS.VARIABLE.FOLLOWED.CARD_TITLE' | translate: { followedRank: followedVariableCard.followedNumber } }}</span>
  @if(canUpdate && followedVariableCard.type === FollowedVariableCardType.PREVIEW) {
  <div class="followed-variable-actions">
    <span (click)="removeFollowedVariable.emit()">{{ 'ASSETS.VARIABLE.FOLLOWED.REMOVE' | translate }}</span>
  </div>
  }
</div>

@if(followedVariableCard.type === FollowedVariableCardType.PREVIEW) {
<div class="followed-variable-card">
  <div fxLayout="row" style="height: 100%">
    <div class="followed-variable-icons" fxFlex="40px" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-icon>{{ followedVariableCard.followedNumberIcon }}</mat-icon>
      <mat-icon>{{ followedVariableCard.linkedIcon }}</mat-icon>
    </div>
    <div class="followed-variable-info" fxLayout="column" fxLayoutAlign="start ">
      <div class="followed-variable-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <span>{{ followedVariableCard.name }}</span>
      </div>
    </div>
  </div>
</div>
} @else {
<div
  fxLayoutAlign="center center"
  class="capitalize-first-letter followed-variable-placeholder"
  [ngClass]="{ 'followed-variable-placeholder--disabled': !canUpdate }"
  (click)="canUpdate && addFollowedVariable.emit()"
>
  <span>{{ 'IOT_DICTIONARY.ADD' | translate }}</span>
</div>
}
