import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [NgClass, TranslateModule],
  selector: 'iot-platform-ui-event-severity-display',
  templateUrl: './event-severity-display.component.html',
  styleUrls: ['./event-severity-display.component.scss']
})
export class EventSeverityDisplayComponent {
  severity = input<string>();
}
