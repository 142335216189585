import { CommonApiRequest, Filter } from '@iot-platform/models/common';
import { Scheduler } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const listSchedulers = createAction('[Schedulers] List Schedulers', props<{ entityId: string; request: CommonApiRequest }>());
export const getScheduler = createAction('[Schedulers] Get Scheduler', props<{ entityId: string; schedulerId: string }>());
export const loadAvailableDevicesForScheduler = createAction(
  '[Schedulers] Load Available Devices For Scheduler',
  props<{ scheduler: Scheduler; filters: Filter[]; page: number }>()
);
export const loadSelectedDevicesForScheduler = createAction(
  '[Schedulers] Load Selected Devices For Scheduler',
  props<{ scheduler: Scheduler; filters: Filter[]; page: number }>()
);
export const loadTotalAvailableDevicesForScheduler = createAction('[Schedulers] Load Total Available Devices For Scheduler', props<{ scheduler: Scheduler }>());
export const loadTotalSelectedDevicesForScheduler = createAction('[Schedulers] Load Total Selected Devices For Scheduler', props<{ scheduler: Scheduler }>());
export const clearDevicesManagement = createAction('[Schedulers] Clear Devices Management', props<{ scheduler: Scheduler }>());
//
export const addScheduler = createAction('[Schedulers] Add Scheduler', props<{ schedulerToAdd: Scheduler }>());
export const updateScheduler = createAction('[Schedulers] Update Scheduler', props<{ schedulerToUpdate: Scheduler }>());
export const manageDevices = createAction('[Schedulers] Manage Devices', props<{ schedulerToUpdate: Scheduler; idsToAdd: string[]; idsToRemove: string[] }>());
export const deleteScheduler = createAction('[Schedulers] Delete Scheduler', props<{ schedulerToDelete: Scheduler }>());
export const navigateToSchedulerDetails = createAction(
  '[Schedulers] Navigate To Scheduler Details',
  props<{ selectedScheduler: Scheduler; tabIndex: number }>()
);
export const navigateToSchedulerDetailsSuccess = createAction('[Schedulers] Navigate To Scheduler Details Success', props<{ tabIndex: number }>());
export const launchNow = createAction('[Schedulers] Launch Now', props<{ toLaunch: Scheduler }>());
//
export const loadMVSettings = createAction('[Schedulers] Load Schedulers Settings', props<{ settingName: string }>());
export const loadSchedulerSelectedDevicesMVSettings = createAction(
  '[Schedulers] Load Scheduler Selected Devices MV Settings',
  props<{ settingName: string }>()
);
export const loadSchedulerAvailableDevicesMVSettings = createAction(
  '[Schedulers] Load Scheduler Available Devices MV Settings',
  props<{ settingName: string }>()
);

export const saveMVSettings = createAction('[Schedulers] Save Schedulers Settings', props<{ name: string; values: any }>());

export const saveSchedulerFilter = createAction('[Schedulers] Save Schedulers Filters', props<{ filters: Filter[] }>());
