import { NgClass } from '@angular/common';
import { Component, DestroyRef, effect, inject, Input, input, OnInit, output } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { DateIntervalUtils, GetUtils } from '@iot-platform/iot-platform-utils';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DateRange, DateRangeElement } from './date-range.model';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatDatepickerModule, MatSelectModule, MatIcon, MatInputModule, NgClass],
  selector: 'iot-platform-ui-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-mdc-select-date-range-overlay-pane' }
    }
  ]
})
export class DateRangeComponent implements OnInit {
  placeholderFrom = input<string>('DATE_RANGE.PLACEHOLDER_FROM');
  placeholderTo = input<string>('DATE_RANGE.PLACEHOLDER_TO');
  placeholderHours = input<string>('DATE_RANGE.PLACEHOLDER_HOURS');
  placeholderMinutes = input<string>('DATE_RANGE.PLACEHOLDER_MINUTES');
  placeholderClear = input<string>('DATE_RANGE.CLEAR');
  required = input<boolean>(false);
  dateFormatterPattern = input<string | null>(null);
  diffInMonths = input<number>(0);

  @Input() clearDateRangeFilters$ = new Subject<any>();
  @Input() validateForm$ = new Subject<any>();

  dateRangeChange = output<DateRange>();
  dateRangeReady = output<UntypedFormGroup>();
  dateRangeCleared = output<DateRange>();

  form: UntypedFormGroup;
  hours: Array<number> = [];
  minutes: Array<number> = [];
  defaultEndHours = 23;
  defaultEndMinutes = 59;
  defaultEndSeconds = 0;
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly storage: LocalStorageService = inject(LocalStorageService);

  constructor() {
    this.form = new UntypedFormGroup(
      {
        startDate: new UntypedFormControl(null, this.required() ? [Validators.required] : []),
        startHours: new UntypedFormControl(0, []),
        startMinutes: new UntypedFormControl(0, []),
        startSeconds: new UntypedFormControl(0, []),
        endDate: new UntypedFormControl(null, this.required() ? [Validators.required] : []),
        endHours: new UntypedFormControl(this.defaultEndHours, []),
        endMinutes: new UntypedFormControl(this.defaultEndMinutes, []),
        endSeconds: new UntypedFormControl(this.defaultEndSeconds, [])
      },
      this.diffInMonths() > 0 ? [this.dateRangeDiffInMonthsValidator] : []
    );

    effect(() => {
      const required = this.required();
      if (required) {
        this.startDate?.setValidators([Validators.required]);
        this.endDate?.setValidators([Validators.required]);
      } else {
        this.startDate?.setValidators([]);
        this.endDate?.setValidators([]);
      }
      this.startDate?.updateValueAndValidity();
      this.endDate?.updateValueAndValidity();
    });

    effect(() => {
      const diffInMonths = this.diffInMonths();
      if (diffInMonths !== null && diffInMonths !== undefined && diffInMonths > 0) {
        this.form?.setValidators([this.dateRangeDiffInMonthsValidator]);
      } else {
        this.form?.setValidators([]);
      }
      this.form?.updateValueAndValidity({ emitEvent: false });
    });

    const formChanges = toSignal(this.form.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)));
    effect(
      () => {
        formChanges();
        this.onValueChanges();
      },
      { allowSignalWrites: true }
    );
  }

  get startDate(): AbstractControl | null {
    return this.form.get('startDate');
  }

  get startHours(): AbstractControl | null {
    return this.form.get('startHours');
  }

  get startMinutes(): AbstractControl | null {
    return this.form.get('startMinutes');
  }

  get startSeconds(): AbstractControl | null {
    return this.form.get('startSeconds');
  }

  get endDate(): AbstractControl | null {
    return this.form.get('endDate');
  }

  get endHours(): AbstractControl | null {
    return this.form.get('endHours');
  }

  get endMinutes(): AbstractControl | null {
    return this.form.get('endMinutes');
  }

  get endSeconds(): AbstractControl | null {
    return this.form.get('endSeconds');
  }

  get timeZone(): string {
    if (JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.timezoneDetails?.offset) {
      return GetUtils.get(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)), 'timezoneDetails.offset', '');
    } else {
      return GetUtils.get(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)), 'timezone', '');
    }
  }

  ngOnInit(): void {
    this.hours = DateIntervalUtils.getArrayOfConsecutiveNumbers(0, this.defaultEndHours);
    this.minutes = DateIntervalUtils.getArrayOfConsecutiveNumbers(0, this.defaultEndMinutes);
    this.dateRangeReady.emit(this.form);
    this.clearDateRangeFilters$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.resetForm();
    });
    this.validateForm$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.validateAllFields();
    });
  }

  validateAllFields(): void {
    Object.keys(this.form.controls).forEach((field: any) => {
      const c = this.form.get(field);
      c.markAsTouched({ onlySelf: true });
      c.updateValueAndValidity();
    });
  }

  resetForm(): void {
    this.form.reset(
      {
        startDate: null,
        startHours: 0,
        startMinutes: 0,
        startSeconds: 0,
        endDate: null,
        endHours: this.defaultEndHours,
        endMinutes: this.defaultEndMinutes,
        endSeconds: this.defaultEndSeconds
      },
      { emitEvent: false }
    );
  }

  resetStartDate(): void {
    this.form.reset({
      ...this.form.value,
      startDate: null,
      startHours: 0,
      startMinutes: 0,
      startSeconds: 0
    });

    this.dateRangeCleared.emit({ startDate: this.getStartDate(), endDate: this.getEndDate() });
  }

  resetEndDate(): void {
    this.form.reset({
      ...this.form.value,
      endDate: null,
      endHours: this.defaultEndHours,
      endMinutes: this.defaultEndMinutes,
      endSeconds: this.defaultEndSeconds
    });
    this.dateRangeCleared.emit({ startDate: this.getStartDate(), endDate: this.getEndDate() });
  }

  getStartDate(): DateRangeElement | null {
    return this.startDate.value ? this.getDate(this.startDate.value, this.startHours.value, this.startMinutes.value, this.startSeconds.value) : null;
  }

  getEndDate(): DateRangeElement | null {
    return this.endDate.value ? this.getDate(this.endDate.value, this.endHours.value, this.endMinutes.value, this.endSeconds.value) : null;
  }

  getDate(date: Date, hours: number, minutes: number, seconds: number): DateRangeElement {
    const d = new Date(date);
    d.setHours(hours);
    d.setMinutes(minutes);
    d.setSeconds(seconds);
    const { value, dateLabel: label } = DateIntervalUtils.getDateFilter(d, hours, minutes, seconds, this.timeZone);
    return {
      date: d,
      hours,
      minutes,
      seconds,
      label,
      value: this.dateFormatterPattern() ? moment(value).format(this.dateFormatterPattern()) : value,
      timestamp: d.getTime()
    };
  }

  onValueChanges(): void {
    this.dateRangeChange.emit({
      startDate: this.getStartDate(),
      endDate: this.getEndDate()
    });
  }

  readonly dateRangeDiffInMonthsValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from: DateRangeElement | null = this.form ? this.getStartDate() : null;
    const to: DateRangeElement | null = this.form ? this.getEndDate() : null;
    if (from && to) {
      const diffInMonths = moment.duration(moment(to.date).diff(moment(from.date))).asMonths();
      invalid = diffInMonths > this.diffInMonths();
    }
    return invalid ? { invalidRange: { from: from?.date, to: to?.date } } : null;
  };
}
