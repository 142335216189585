import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { PlatformRequest, PlatformResponse, Product } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminProductsService {
  constructor(@Inject('environment') private environment, private httpClient: HttpClient) {}

  getAllProducts(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString());
    params = params.set('page', request.page.toString());

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }
    return this.httpClient.get<{ page: any; content: Product[] }>(`${this.environment.api.url}${this.environment.api.endpoints.products}`, { params }).pipe(
      map((response) => ({
          data: response.content,
          currentPage: response.page.curPage,
          hasMore: false,
          limit: 500,
          maxPage: 0,
          total: response.page.total,
          next: response.page.next,
          prev: response.page.prev
        }))
    );
  }

  addProduct(productToAdd: Product): Observable<Product> {
    return this.httpClient.post<Product>(`${this.environment.api.url}${this.environment.api.endpoints.products}`, productToAdd);
  }

  updateProduct(productToUpdate: Product): Observable<Product> {
    return this.httpClient.patch<Product>(`${this.environment.api.url}${this.environment.api.endpoints.products}/${productToUpdate.id}`, {
      name: productToUpdate.name,
      identifier: productToUpdate.identifier,
      catalog: productToUpdate.catalog
    });
  }

  deleteProduct(productToDelete: Product): Observable<Product> {
    return this.httpClient
      .delete(`${this.environment.api.url}${this.environment.api.endpoints.products}/${productToDelete.id}`)
      .pipe(map((_) => productToDelete));
  }
}
