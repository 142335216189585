import { FavoriteView } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
// Fixme import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { AuthBusinessProfilesPageActions } from 'libs/auth/src/lib/state/actions/auth-business-profiles-page.actions';
import { FavoriteViewsActions } from '../actions';

export const favoriteViewsApiFeatureKey = 'favoriteViewsApi';

export interface State extends EntityState<FavoriteView> {
  selectedFavoriteViewId: string | null;
}

export const adapter: EntityAdapter<FavoriteView> = createEntityAdapter<FavoriteView>({
  selectId: (favoriteView: FavoriteView) => favoriteView.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedFavoriteViewId: null
});

export const reducer = createReducer(
  initialState,
  /* on(FavoriteViewsActions.loadFavoriteViews, (state: State) => adapter.removeAll(state)),*/
  on(FavoriteViewsActions.loadFavoriteViewsSuccess, (state: State, { favoriteViews }) => adapter.setAll(favoriteViews, state)),
  on(FavoriteViewsActions.addFavoriteViewSuccess, FavoriteViewsActions.addFavoriteViewFromMVSuccess, (state, { favoriteView }) =>
    adapter.addOne(favoriteView, state)
  ),
  on(FavoriteViewsActions.getCountForFavoriteView, (state, { favoriteView }) =>
    adapter.updateOne(
      {
        id: favoriteView.id,
        changes: { loading: true }
      },
      state
    )
  ),
  on(FavoriteViewsActions.getCountForFavoriteViewSuccess, (state, { favoriteView }) =>
    adapter.updateOne({ id: favoriteView.id, changes: { count: favoriteView.count, loading: false } }, state)
  ),
  on(FavoriteViewsActions.updateFavoriteViewSuccess, FavoriteViewsActions.updateFavoriteViewFromMVSuccess, (state, { favoriteView }) =>
    adapter.updateOne({ id: favoriteView.id, changes: favoriteView }, state)
  ),
  on(FavoriteViewsActions.deleteFavoriteViewSuccess, (state, { deletedFavoriteView }) => adapter.removeOne(deletedFavoriteView.id, state)),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export const getSelectedId = (state: State) => state.selectedFavoriteViewId;
