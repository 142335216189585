import { ContactNotification } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadNotificationsSuccess = createAction('[NotificationsDb] Load Notifications Success', props<{ notifications: ContactNotification[] }>());
export const loadNotificationsFailure = createAction('[NotificationsDb] Load Notifications Failure', props<{ error: any }>());
//
export const addNotificationSuccess = createAction('[NotificationsDb] Add Notification Success', props<{ addedNotification: ContactNotification }>());
export const addNotificationFailure = createAction('[NotificationsDb] Add Notification Failure', props<{ error: any }>());
//
export const updateNotificationSuccess = createAction('[NotificationsDb] Update Notification Success', props<{ updatedNotification: ContactNotification }>());
export const updateNotificationFailure = createAction('[NotificationsDb] Update Notification Failure', props<{ error: any }>());
//
export const deleteNotificationSuccess = createAction('[NotificationsDb] Delete Notification Success', props<{ deletedNotification: ContactNotification }>());
export const deleteNotificationFailure = createAction('[NotificationsDb] Delete Notification Failure', props<{ error: any }>());
//
export const configureNotificationsSuccess = createAction(
  '[NotificationsDb] Configure Contact Notifications Success',
  props<{ updatedNotifications: ContactNotification[] }>()
);
export const configureNotificationsFailure = createAction('[NotificationsDb] Configure Contact Notifications Failure', props<{ error: any }>());
