import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_CONFIG, AuthConfig } from './auth.config';
import { AuthInterceptor } from './auth.interceptor';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { LoginChangeTemporaryPasswordFormComponent } from './components/login-change-temporary-password-form/login-change-temporary-password-form.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPasswordRulesComponent } from './components/login-password-rules/login-password-rules.component';
import { LoginResetPasswordFormComponent } from './components/login-reset-password-form/login-reset-password-form.component';
import { LoginSelectAuthenticationComponent } from './components/login-select-authentication/login-select-authentication.component';
import { LoginSelectProfileComponent } from './components/login-select-profile/login-select-profile.component';
import { LoginSendCodeFormComponent } from './components/login-send-code-form/login-send-code-form.component';
import { LoginShellComponent } from './containers/login-shell/login-shell.component';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { AuthApiActions } from './state/actions';

const COMPONENTS = [
  LoginSelectProfileComponent,
  LoginCallbackComponent,
  LoginFormComponent,
  LoginPasswordRulesComponent,
  LoginResetPasswordFormComponent,
  LoginSelectAuthenticationComponent,
  LoginSendCodeFormComponent,
  LoginChangeTemporaryPasswordFormComponent,
  LoginShellComponent
];

@NgModule({
  imports: COMPONENTS,
  exports: COMPONENTS,
  providers: [AuthGuard, TranslateService]
})
export class AuthModule {
  private store: Store = inject(Store);
  private storage: LocalStorageService = inject(LocalStorageService);

  constructor() {
    const session = this.storage.get(LocalStorageKeys.STORAGE_SESSION_KEY);
    const initialState = {
      loggedIn: session ? JSON.parse(session).loggedIn : false,
      tokenExpired: session ? JSON.parse(session).tokenExpired : true,
      idToken: session ? JSON.parse(session).idToken : null,
      accessToken: session ? JSON.parse(session).accessToken : null,
      refreshToken: session ? JSON.parse(session).refreshToken : null,
      currentUser: session ? JSON.parse(session).currentUser : null,
      cognitoUser: session ? JSON.parse(session).cognitoUser : null,
      userId: session ? JSON.parse(session).userId : null,
      firstLogIn: session ? JSON.parse(session).firstLogIn : false,
      businessProfiles: session ? JSON.parse(session).businessProfiles : null,
      selectedBusinessProfile: session ? JSON.parse(session).selectedBusinessProfile : null,
      currentEntity: session ? JSON.parse(session).currentEntity : null,
      privileges: session ? JSON.parse(session).privileges : null,
      preferences: session ? JSON.parse(session).preferences : null,
      isUserAdmin: false,
      loggedOut: false
    };
    this.store.dispatch(AuthApiActions.setCachedInitialState({ initialState }));
  }

  static forRoot(config?: Partial<AuthConfig>): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        { provide: AUTH_CONFIG, useValue: config }
      ]
    };
  }
}
