import { Organization } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';

import { AdminOrganizationsApiActions, AdminOrganizationsPageActions } from '../actions';

export const adminOrganizationsApiFeatureKey = 'adminOrganizationsApi';

export interface State extends EntityState<Organization> {
  selectedOrganizationId: string | null;
  selectedOrganization: Organization;
}

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
  selectId: (organization: Organization) => organization.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedOrganizationId: null,
  selectedOrganization: null
});

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsApiActions.listOrganizationsSuccess, (state: State, { organizations }) => adapter.setAll(organizations, state)),
  on(AdminOrganizationsApiActions.listOrganizationsFailure, (state: State, { error }) => ({ ...state, error })),
  on(AdminOrganizationsPageActions.selectOrganization, (state: State, { selectedOrganizationId }) => ({ ...state, selectedOrganizationId })),
  on(AdminOrganizationsApiActions.selectOrganizationSuccess, (state: State, { selectedOrganization }) =>
    adapter.updateOne({ id: selectedOrganization.id, changes: selectedOrganization }, { ...state, selectedOrganization })
  ),
  on(AdminOrganizationsApiActions.selectOrganizationFailure, (state: State, { error }) => ({ ...state, error })),
  on(AdminOrganizationsApiActions.updateOrganizationSuccess, (state: State, { updatedOrganization }) =>
    adapter.updateOne({ id: updatedOrganization.id, changes: updatedOrganization }, { ...state, selectedOrganization: updatedOrganization })
  ),
  on(AdminOrganizationsApiActions.updateOrganizationFailure, (state: State, { error }) => ({ ...state, error })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),
  on(AdminOrganizationsApiActions.addOrganizationSuccess, (state, { organization }) => adapter.addOne(organization, state)),
  on(AdminOrganizationsApiActions.addOrganizationFailure, (state, { error }) => ({ ...state, error })),
  on(AdminOrganizationsApiActions.deleteOrganizationSuccess, (state, { removedOrganization }) =>
    adapter.removeOne(removedOrganization.id, { ...state, selectedOrganizationId: null, selectedOrganization: null })
  ),
  on(AdminOrganizationsApiActions.deleteOrganizationFailure, (state, { error }) => ({ ...state, error }))
);

export const getSelectedId = (state: State) => state.selectedOrganizationId;
export const getSelectedOrganization = (state: State) => state.selectedOrganization;
