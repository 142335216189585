import { BaseState, Contact, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { ContactsActions } from '../actions';

export type State = BaseState<Contact, Pagination, Filter>;

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (entity: Contact) => entity?.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const contactsFeature = createFeature({
  name: 'contacts',
  reducer: createReducer(
    initialState,
    on(ContactsActions.loadContactsSuccess, (state: State, { contacts }): State => adapter.setAll(contacts, state)),
    on(
      ContactsActions.addContactSuccess,
      (state: State, { addedContact }): State =>
        adapter.addOne(addedContact, {
          ...state,
          entity: addedContact
        })
    ),
    on(
      ContactsActions.updateContactSuccess,
      (state: State, { updatedContact }): State =>
        adapter.updateOne(
          {
            id: updatedContact.id,
            changes: updatedContact
          },
          { ...state, entity: updatedContact }
        )
    ),
    on(ContactsActions.deleteContactSuccess, (state: State, { deletedContact }): State => adapter.removeOne(deletedContact.id, state)),
    on(
      ContactsActions.loadContacts,
      ContactsActions.addContact,
      ContactsActions.updateContact,
      ContactsActions.deleteContact,
      (state: State): State => ({
        ...state,
        loaded: false,
        loading: true
      })
    ),
    on(
      ContactsActions.loadContactsSuccess,
      ContactsActions.addContactSuccess,
      ContactsActions.updateContactSuccess,
      ContactsActions.deleteContactSuccess,
      (state: State): State => ({ ...state, loaded: true, loading: false })
    ),

    on(
      ContactsActions.loadContactsFailure,
      ContactsActions.addContactFailure,
      ContactsActions.updateContactFailure,
      ContactsActions.deleteContactFailure,
      (state: State, { error }): State => ({ ...state, error, loaded: false, loading: false })
    )
  )
});
