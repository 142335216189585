import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { TopicsSubscribedProtocolsDb, TopicsSubscribedProtocolsUi } from '../actions';
//
export const topicsSubscribedProtocolsUiFeatureKey = 'topicsSubscribedProtocolsUi';

export interface State {
  error?: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

const topicsSubscribedProtocolsUiReducer = createReducer(
  initialState,
  on(TopicsSubscribedProtocolsUi.listProtocolSubscribedToTopicByTopicId, (state: State) => ({ ...state, pending: true })),
  on(TopicsSubscribedProtocolsDb.listProtocolSubscribedToTopicByTopicIdSuccess, (state: State) => ({ ...state, pending: false })),
  on(TopicsSubscribedProtocolsDb.listProtocolSubscribedToTopicByTopicIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),
  //
  on(TopicsSubscribedProtocolsUi.subscribeProtocolToTopic, (state: State) => ({ ...state, pending: true })),
  on(TopicsSubscribedProtocolsDb.subscribeToTopicByProtocolIdSuccess, (state: State) => ({ ...state, pending: false })),
  on(TopicsSubscribedProtocolsDb.subscribeToTopicByProtocolIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),
  //
  on(TopicsSubscribedProtocolsUi.unsubscribeProtocolToTopic, (state: State) => ({ ...state, pending: true })),
  on(TopicsSubscribedProtocolsDb.unsubscribeToTopicByProtocolIdSuccess, (state: State) => ({ ...state, pending: false })),
  on(TopicsSubscribedProtocolsDb.unsubscribeToTopicByProtocolIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return topicsSubscribedProtocolsUiReducer(state, action);
}

export const getPending = (state: State) => state.pending;
export const getTopicsSubscribedProtocolsError = (state: State) => state.error;
