import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Calendar } from '@iot-platform/models/ocm';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CalendarsDbActions, CalendarsUiActions } from '../actions';

export const calendarsDbFeatureKey = 'calendarsDb';

export interface State extends EntityState<Calendar> {
  selectedCalendarId: string;
  pagination: CommonIndexedPagination;
  mvSettings: any;
  currentFilters: Filter[];
  error: any;
}

export const adapter: EntityAdapter<Calendar> = createEntityAdapter<Calendar>({
  selectId: (calendar: Calendar) => calendar.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedCalendarId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 25 },
  mvSettings: null,
  currentFilters: [],
  error: null
});

export const calendarsDbReducer = createReducer(
  initialState,
  on(CalendarsUiActions.listCalendars, (state) => ({ ...state, selectedCalendarId: null })),
  on(CalendarsDbActions.listCalendarsSuccess, (state, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: response.pagination
    })
  ),
  on(CalendarsDbActions.listCalendarsFailure, (state, { error }) => ({ ...state, error })),

  on(CalendarsDbActions.getCalendarSuccess, (state, { selectedCalendar }) =>
    adapter.addOne(selectedCalendar, { ...state, selectedCalendarId: selectedCalendar.id })
  ),
  on(CalendarsDbActions.getCalendarFailure, (state, { error }) => ({ ...state, error })),

  on(CalendarsDbActions.addCalendarSuccess, (state, { addedCalendar }) =>
    adapter.addOne(addedCalendar, { ...state, pagination: { ...state.pagination, total: state.pagination.total + 1 }, selectedCalendarId: addedCalendar.id })
  ),
  on(CalendarsDbActions.addCalendarFailure, (state, { error }) => ({ ...state, error })),

  on(CalendarsDbActions.updateCalendarSuccess, (state, { updatedCalendar }) => adapter.upsertOne(updatedCalendar, state)),
  on(CalendarsDbActions.updateCalendarFailure, (state, { error }) => ({ ...state, error })),

  on(CalendarsDbActions.deleteCalendarSuccess, (state, { deletedCalendar }) => adapter.removeOne(deletedCalendar.id, { ...state, selectedCalendarId: null })),
  on(CalendarsDbActions.deleteCalendarFailure, (state, { error }) => ({ ...state, error })),

  on(CalendarsUiActions.navigateToCalendarDetails, (state: State, { calendarToNavigate }) => ({ ...state, selectedCalendarId: calendarToNavigate.id })),

  on(CalendarsDbActions.loadMVSettingsSuccess, (state, { mvSettings }) => ({ ...state, mvSettings })),
  on(CalendarsDbActions.loadMVSettingsFailure, (state, { error }) => ({ ...state, error })),
  //
  on(CalendarsUiActions.saveMVSettings, (state: State) => adapter.removeAll({ ...state })),
  on(CalendarsDbActions.saveMVSettingsSuccess, (state: State, { mvSettings }) => ({ ...state, mvSettings })),
  on(CalendarsDbActions.saveMVSettingsFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(CalendarsUiActions.saveCalendarsFilters, (state: State, { filters }) => ({ ...state, currentFilters: filters })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return calendarsDbReducer(state, action);
}

export const getSelectedCalendarId = (state: State) => state.selectedCalendarId;
export const getPagination = (state: State) => state.pagination;
export const getMVSettings = (state: State) => state.mvSettings;
export const getCalendarsFilters = (state: State) => state.currentFilters;
