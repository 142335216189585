import { Injectable } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { DeviceEventsBySiteLogsDbActions, DeviceEventsBySiteLogsUiActions } from '../actions';
import { DeviceEventsService } from '@iot-platform/shared/services';

@Injectable()
export class DeviceEventsBySiteLogsEffects {
  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteLogsUiActions.loadLogsByDeviceEvent),
      switchMap((action) =>
        this.deviceEventsService.loadComments(action.deviceEvent).pipe(
          map((logs: Log[]) => DeviceEventsBySiteLogsDbActions.loadLogsByDeviceEventSuccess({ logs })),
          catchError((error) => of(DeviceEventsBySiteLogsDbActions.loadLogsByDeviceEventFailure({ error })))
        )
      )
    )
  );

  createLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteLogsUiActions.createLogByDeviceEvent),
      concatMap((action) =>
        this.deviceEventsService.addComment(action.comment.deviceEvent.id, action.comment.value).pipe(
          map((log: Log) => DeviceEventsBySiteLogsDbActions.createLogByDeviceEventSuccess({ log, deviceEvent: action.comment.deviceEvent })),
          catchError((error) => of(DeviceEventsBySiteLogsDbActions.createLogByDeviceEventFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsBySiteLogsDbActions.createLogByDeviceEventSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsBySiteLogsDbActions.loadLogsByDeviceEventFailure, DeviceEventsBySiteLogsDbActions.createLogByDeviceEventFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private deviceEventsService: DeviceEventsService,
    private notificationService: NotificationService
  ) {}
}
