import { FavoriteView, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createAction, props } from '@ngrx/store';

export const addFavoriteView = createAction('[Admin Business Profile/Admin Page] Add Favorite View', props<{ favoriteViewToAdd: FavoriteView }>());

export const deleteFavoriteView = createAction('[Admin Business Profile/Admin Page] Delete Favorite View', props<{ favoriteViewToDelete: FavoriteView }>());

export const updateFavoriteView = createAction('[Admin Business Profile/Admin Page] Update Favorite View', props<{ favoriteViewToUpdate: FavoriteView }>());

export const duplicateFavoriteView = createAction(
  '[Admin Business Profile/Admin Page] Duplicate Favorite View',
  props<{ favoriteViewToDuplicate: FavoriteView }>()
);

export const duplicateGridAndFavoriteView = createAction(
  '[Admin Business Profile/Admin Page] Duplicate Grid and Favorite View',
  props<{ gridToDuplicate: I4BGrid<I4BGridOptions, I4BGridData>; favoriteViewToDuplicate: FavoriteView }>()
);

export const changePage = createAction('[Admin Business Profile/Admin Page] Change Favorite View Page', props<{ pagination: Pagination }>());

export const duplicateGridThenAddFavoriteView = createAction(
  '[Admin Business Profile/Admin Page] Duplicate Grid then Add Favorite View',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteViewToAdd: FavoriteView }>()
);

export const duplicateGridThenUpdateFavoriteView = createAction(
  '[Admin Business Profile/Admin Page] Duplicate Grid then Update Favorite View',
  props<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteViewToUpdate: FavoriteView }>()
);
