import { Injectable } from '@angular/core';
import { fromAuth } from '@iot-platform/auth';
import { AbstractFavoriteFilterEngineService } from '@iot-platform/iot-platform-ui';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { fromUserPreferences, PreferencesActions } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoriteFilterEngineService extends AbstractFavoriteFilterEngineService {
  constructor(private readonly store: Store) {
    super();
  }

  getAccount$(): Observable<UserAccount> {
    return this.store.select(fromAuth.selectAccount);
  }

  getUserPreferences$(): Observable<UserPreferences> {
    return this.store.select(fromUserPreferences.getPreferences);
  }

  saveUserPreferences(user: UserAccount, preferences: UserPreferences): void {
    this.store.dispatch(PreferencesActions.saveUserPreferences({ user, preferences }));
  }
}
