import { Pipe, PipeTransform } from '@angular/core';
import cronstrue from 'cronstrue';
import { Options } from 'cronstrue/dist/options';

@Pipe({
  name: 'cronDisplay',
  standalone: true
})
export class CronDisplayPipe implements PipeTransform {
  transform(value: string, args?: Options): string {
    try {
      return cronstrue.toString(value, { use24HourTimeFormat: true, ...args });
    } catch (e) {
      return e as string;
    }
  }
}
