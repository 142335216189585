import { BusinessProfile } from '@iot-platform/models/common';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const listBusinessProfilesSuccess = createAction('[Admin Business Profiles/API] List Success', props<{ businessProfiles: BusinessProfile[] }>());
export const listBusinessProfilesFailure = createAction('[Admin Business Profiles/API] List Error', props<{ error: any }>());

export const selectBusinessProfile = createAction('[Admin Business Profiles/API] Select Business Profile', props<{ selectedBusinessProfileId: string }>());

export const addBusinessProfileSuccess = createAction(
  '[Admin Business Profiles/API] Add Business Profile Success',
  props<{ businessProfile: BusinessProfile }>()
);
export const addBusinessProfileFailure = createAction('[Admin Business Profiles/API] Add Business Profile Failure', props<{ error: any }>());

export const deleteBusinessProfileSuccess = createAction(
  '[Admin Business Profiles/API] Delete Business Profile Success',
  props<{ deletedBusinessProfile: BusinessProfile }>()
);
export const deleteBusinessProfileFailure = createAction('[Admin Business Profiles/API] Delete Business Profile Failure', props<{ error: any }>());

export const updateBusinessProfileSuccess = createAction(
  '[Admin Business Profiles/API] Update Business Profile Success',
  props<{ businessProfile: Update<BusinessProfile> }>()
);
export const updateBusinessProfileFailure = createAction('[Admin Business Profiles/API] Update Business Profile Failure', props<{ error: any }>());
