import { createReducer, on } from '@ngrx/store';

import { BusinessProfilesFavoriteViewsApiActions, BusinessProfilesFavoriteViewsPageActions, BusinessProfilesPageActions } from '../actions';

export const adminBusinessProfilesFavoriteViewsPageFeatureKey = 'adminBusinessProfilesFavoriteViewsPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesFavoriteViewsPageActions.updateFavoriteView, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesFavoriteViewsApiActions.updateFavoriteViewFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(BusinessProfilesFavoriteViewsPageActions.deleteFavoriteView, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewSuccess, (state: State) => ({ ...state, pending: false })),
  on(BusinessProfilesFavoriteViewsApiActions.deleteFavoriteViewFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
