<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ 'USERS.PROFILE_PREFERENCES.YOUR_CONSUMER_KEY' | translate}}</h1>
            <button (click)="exit()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
        <p data-hj-suppress>{{data.apiKeyConsumerKey}}</p>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="exit()" class="button-regular" id="apiKeyCloseButton"
                mat-button>{{ 'USERS.PROFILE_PREFERENCES.UI.BUTTONS.CANCEL' | translate }}</button>
        <button (click)="copy()" class="button-regular" color="accent" id="apiKeyCopyButton" mat-raised-button>
            <mat-icon class="copy-button">content_copy</mat-icon>
            {{ 'USERS.PROFILE_PREFERENCES.UI.BUTTONS.COPY' | translate }}
        </button>
    </mat-card-actions>
</mat-card>




