import { Directive, effect, inject, Injector, model, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class AbstractDebounceDirective implements OnInit, OnDestroy {
  debounceTime = model<number>(300);

  protected emitEvent$: Subject<any> = new Subject<any>();
  protected subscription$: Subject<void> = new Subject<void>();
  protected injector: Injector = inject(Injector);

  ngOnInit() {
    effect(
      () => {
        const delay = this.debounceTime();
        this.ngOnDestroy();
        this.initEvent(delay);
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  initEvent(delay: number): void {
    this.emitEvent$.pipe(takeUntil(this.subscription$), debounceTime(delay), distinctUntilChanged()).subscribe((value) => this.onEvent(value));
  }

  abstract onEvent(value: any): void;

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }
}
