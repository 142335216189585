import { Component, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CardLoaderModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { BaseUser, BusinessProfile, UserAccount } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { UsersUtil } from '../../utils/users.util';
import { UserInfoPopupService } from './services/user-info-popup.service';

@Component({
  standalone: true,
  imports: [DetailPopupModule, FlexLayoutModule, TranslateModule, MatIconModule, MatCardModule, CardLoaderModule],
  selector: 'iot-platform-users-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.scss']
})
export class UserDetailDialogComponent {
  private readonly userInfoPopupService: UserInfoPopupService = inject(UserInfoPopupService);
  public readonly dialogRef: MatDialogRef<UserDetailDialogComponent> = inject(MatDialogRef<UserDetailDialogComponent>);
  public data: { user: BaseUser } = inject(MAT_DIALOG_DATA);

  userAccount: Signal<UserAccount> = toSignal(this.userInfoPopupService.getUserAccountById(this.data.user.id));
  businessProfiles: Signal<BusinessProfile[]> = toSignal(this.userInfoPopupService.getBusinessProfilesByUserId(this.data.user.id));

  protected readonly UsersUtil = UsersUtil;
}
