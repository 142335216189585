import { HttpClient } from '@angular/common/http';
import { inject, Injectable, ProviderToken, signal, WritableSignal } from '@angular/core';
import { Environment } from '@iot-platform/models/common';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetTemplateCellService {
  private readonly environment: Environment = inject('environment' as unknown as ProviderToken<Environment>);
  private readonly httpClient: HttpClient = inject(HttpClient);
  cachedTemplates: WritableSignal<AssetTemplate[]> = signal([]);
  templateIds = new Set<string>();

  getAssetTemplateById(templateId: string): Observable<AssetTemplate> {
    if (this.templateIds.has(templateId)) {
      return of(this.cachedTemplates().find((t) => t.id === templateId));
    } else {
      return this.httpClient.get<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${templateId}`).pipe(
        tap((t) => {
          this.templateIds.add(t.id);
          this.cachedTemplates.update((cachedTemplates) => [...cachedTemplates, t]);
        })
      );
    }
  }
}
