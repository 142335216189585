import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { AssetVariable, I4BApiError } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AssetVariablesActions } from '../actions';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface State extends BaseState<AssetVariable, Pagination, Filter> {}

export const adapter: EntityAdapter<AssetVariable> = createEntityAdapter<AssetVariable>({
  selectId: (assetVariable: AssetVariable) => assetVariable.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE
});

export const assetVariablesFeature = createFeature({
  name: 'assetVariables',
  reducer: createReducer(
    initialState,
    // DATA
    on(AssetVariablesActions.getAll, (state: State) => adapter.removeAll(state)),
    on(AssetVariablesActions.getAllSuccess, (state: State, { allVariables }) => adapter.setAll(allVariables, state)),
    on(AssetVariablesActions.getOneSuccess, (state: State, { element }) => adapter.setOne(element, { ...state, selectedId: element.id as string })),
    on(AssetVariablesActions.addOneSuccess, (state: State, { added }) => adapter.addOne(added, { ...state, selectedId: added.id as string })),
    on(
      AssetVariablesActions.updateOneSuccess,
      AssetVariablesActions.updateFollowedAssetVariableSuccess,
      AssetVariablesActions.updateThresholdsSuccess,
      (state: State, { updated }) => adapter.upsertOne(updated, { ...state, selectedId: updated.id as string })
    ),
    on(AssetVariablesActions['deleteMany-'], (state: State, { response }) => adapter.removeMany(getSuccessVariablesIds(response.variables ?? {}), state)),

    // UI
    on(
      AssetVariablesActions.getAll,
      AssetVariablesActions.getOne,
      AssetVariablesActions.addOne,
      AssetVariablesActions.updateOne,
      AssetVariablesActions.deleteMany,
      AssetVariablesActions.updateFollowedAssetVariable,
      AssetVariablesActions.resetLastValues,
      AssetVariablesActions.breakFormula,
      (state: State) => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      AssetVariablesActions.getAllSuccess,
      AssetVariablesActions.getOneSuccess,
      AssetVariablesActions.addOneSuccess,
      AssetVariablesActions.updateOneSuccess,
      AssetVariablesActions['deleteMany-'],
      AssetVariablesActions.updateFollowedAssetVariableSuccess,
      AssetVariablesActions['resetLastValues-'],
      AssetVariablesActions.breakFormulaSuccess,
      (state: State) => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      AssetVariablesActions.getAllFailure,
      AssetVariablesActions.getOneFailure,
      AssetVariablesActions.addOneFailure,
      AssetVariablesActions.updateOneFailure,
      AssetVariablesActions.deleteManyFailure,
      AssetVariablesActions.resetLastValuesFailure,
      AssetVariablesActions.breakFormulaFailure,
      (state: State, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    ),

    on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
  )
});

function getSuccessVariablesIds(variables: { [key: string]: { success: boolean; error?: I4BApiError } }): string[] {
  return Object.keys(variables ?? {}).reduce((acc: string[], value: string) => {
    if (variables[value].success) {
      acc.push(value);
    }
    return acc;
  }, []);
}
