import { ThemePalette } from '@angular/material/core';
import { IotToolbarButtonConfig } from '../../models';
import { IotToolbarButtonType } from './button-type.enum';
import { IotToolbarDefaultButtonConfig } from './button.config';

export class IotToolbarDefaultButton implements Partial<IotToolbarButtonConfig> {
  key: string;
  dispatchAction = { type: '', options: undefined };
  icon = '';
  svgIcon = '';
  tooltip = '';
  displayButton = true;
  color = 'accent' as ThemePalette;
  type = IotToolbarButtonType.REGULAR_BUTTON;
  disabled = false;
  order = 1;
  isToggleOn = false;
  toggleOnClass = '';
  toggleOffClass = '';
  removable = false;

  constructor(buttonConfig: IotToolbarDefaultButtonConfig, order: number) {
    Object.keys(buttonConfig).forEach((property: string) => (this[property] = buttonConfig[property] ?? this[property]));
    this.order = order ?? this.order;
  }
}
