import { BaseUser } from '@iot-platform/models/common';
import { Subscriber } from '@iot-platform/models/i4b';
import { Topic } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listUsersSubscribedToTopicByTopicId = createAction('[Topics] List Users Subscribed to Topic By Topic Id', props<{ topicId: string }>());
//
export const subscribeUserToTopic = createAction('[Topics] Subscribe User to Topic', props<{ userToSubscribe: BaseUser; topic: Topic }>());
//
export const unsubscribeUserToTopic = createAction('[Topics] Unsubscribe User to Topic', props<{ userToUnsubscribe: Subscriber; topic: Topic }>());
