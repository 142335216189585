import { GridsDbActions } from '@iot-platform/grid-engine';
import { Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { BusinessProfilesApiActions, BusinessProfilesGridsApiActions, BusinessProfilesGridsPageActions, BusinessProfilesPageActions } from '../actions/';

export const adminBusinessProfilesGridsApiFeatureKey = 'adminBusinessProfilesGridsApi';

export interface State extends EntityState<I4BGrid<I4BGridOptions, I4BGridData>> {
  selectedGridId: string | null;
  pagination: Pagination;
  error: any;
}

export const adapter: EntityAdapter<I4BGrid<I4BGridOptions, I4BGridData>> = createEntityAdapter<I4BGrid<I4BGridOptions, I4BGridData>>({
  selectId: (grid: I4BGrid<I4BGridOptions, I4BGridData>) => grid.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedGridId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 10 },
  error: null
});

export const adminBusinessProfilesGridsReducer = createReducer(
  initialState,
  on(BusinessProfilesApiActions.selectBusinessProfile, (state) => adapter.removeAll(state)),

  on(BusinessProfilesPageActions.listGridsByBusinessProfile, (state) => adapter.removeAll(state)),
  on(BusinessProfilesGridsApiActions.listGridsByBusinessProfileSuccess, (state, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        total: response.total,
        hasMore: response.hasMore,
        limit: 10,
        currentPage: response.currentPage,
        maxPage: response.maxPage
      }
    })
  ),
  on(BusinessProfilesGridsApiActions.listGridsByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesGridsApiActions.addGridSuccess, GridsDbActions.addGridSuccess, (state, { grid }) =>
    adapter.addOne(grid as I4BGrid<I4BGridOptions, I4BGridData>, state)
  ),
  on(BusinessProfilesGridsApiActions.addGridFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesGridsApiActions.updateGridSuccess, GridsDbActions.updateGridSuccess, (state, { grid }) =>
    adapter.updateOne({ id: grid.id, changes: grid as I4BGrid<I4BGridOptions, I4BGridData> }, state)
  ),
  on(BusinessProfilesGridsApiActions.updateGridFailure, (state, { error }) => ({ ...state, error })),
  //
  on(BusinessProfilesGridsApiActions.deleteGridSuccess, GridsDbActions.removeGridSuccess, (state, { removed }) =>
    adapter.removeOne(removed.id, { ...state, pagination: { ...state.pagination, total: state.pagination.total - 1 } })
  ),
  on(BusinessProfilesGridsApiActions.deleteGridFailure, (state, { error }) => ({ ...state, error })),
  //
  on(BusinessProfilesGridsPageActions.changePage, (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, currentPage: pagination.currentPage }
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return adminBusinessProfilesGridsReducer(state, action);
}

export const getSelectedGridId = (state: State) => state.selectedGridId;
export const getPagination = (state: State) => state.pagination;
