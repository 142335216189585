import { HttpErrorResponse } from '@angular/common/http';
import { BusinessProfile } from '@iot-platform/models/common';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const AuthApiActions = createActionGroup({
  source: '[Auth] API Actions',
  events: {
    signInSuccess: props<{ cognitoUser: CognitoUser }>(),
    signInFailure: props<{ error: any }>(),
    signInWithSSOSuccess: props<{ idToken: string; accessToken: string; refreshToken: string; expiresIn: number }>(),
    signInWithSSOFailure: props<{ error: HttpErrorResponse }>(),
    loadAccountSuccess: props<{ account: any; withRedirect?: boolean }>(),
    loadAccountFailure: props<{ error: any }>(),
    loadPrivilegesSuccess: props<{ privileges: any }>(),
    loadPrivilegesFailure: props<{ error: any }>(),
    signOutSuccess: emptyProps(),
    signOutFailure: props<{ error: any }>(),
    changePasswordSuccess: props<{ result: any }>(),
    changePasswordFailure: props<{ error: any }>(),
    forgotPasswordSuccess: props<{ result: any }>(),
    forgotPasswordFailure: props<{ error: any }>(),
    updatePasswordWithCodeSuccess: props<{ result: any }>(),
    updatePasswordWithCodeFailure: props<{ error: any }>(),
    retrieveSsoSessionSuccess: props<{ idToken: string; accessToken: string; refreshToken: string }>(),
    retrieveSsoSessionFailure: props<{ error: any }>(),
    refreshSsoTokensSuccess: props<{ idToken: string; accessToken: string; tokenType: string; expiresIn: number }>(),
    refreshSsoTokensFailure: props<{ error: any }>(),
    retrieveSession: emptyProps(),
    retrieveSessionSuccess: props<{ cognitoUser: CognitoUser }>(),
    retrieveSessionFailure: props<{ error: any }>(),
    storeSession: props<{ session: any }>(),
    storeSessionSuccess: emptyProps(),
    storeSessionFailure: emptyProps(),
    refreshToken: emptyProps(),
    refreshTokenSuccess: props<{ refreshed: CognitoUser }>(),
    refreshTokenFailure: props<{ error: any }>(),
    checkIfUserIsAdmin: props<{ entityId: string; userId: string }>(),
    checkIfUserIsAdminSuccess: props<{ isAdminUser: boolean }>(),
    checkIfUserIsAdminFailure: props<{ error: any }>(),
    setCachedInitialState: props<{ initialState: any }>(),
    setBusinessProfiles: props<{ businessProfiles: BusinessProfile[] }>()
  }
});
