import { get } from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ArrayUtils {
  static pluck = (key: string) => (array: any[]) => Array.from(new Set(array.map((obj) => obj[key])));

  /**
   * Combine duplicates elements
   *
   * @usageNotes
   *
   * ```
   * const array = [
   *    { key:"manage", value:"0"},
   *    { key:"manage", value:"1"},
   *    { key:"manage", value:"2"},
   *    { key:"fab", value:"0"},
   *    { key:"fab", value:"1"},
   *    { key:"limit", value:"10"}
   *  ];
   *
   * let result = combineDuplicates('key', array)
   * console.log(result);   // [{ key: 'manage', values: '0,1,2' }, { key: 'fab', values: '0,1' }, { key: 'limit', values: '10' } ]
   *
   * result = combineDuplicates('key', array, ['fab'])
   * console.log(result);   // [{ key: 'manage', values: '0,1,2' }, { key: 'fab', values: '1' }, { key: 'limit', values: '10' } ]
   * ```
   *
   * @param criteriaKey the criteria key.
   * @param array selected filters.
   * @param exclusions Optional parameter. It represent excluded properties
   *
   * Excluded properties will get the last selected value in the filter
   */
  static combineDuplicates = (criteriaKey: string, array: { value: string; key: string }[], exclusions?: string[]) =>
    ArrayUtils.pluck(criteriaKey)(array).map((key: string) => {
      const values = array.filter((e) => e[criteriaKey] === key).map((e) => e.value);
      return {
        key,
        values: exclusions && exclusions.includes(key) ? values.pop() : values.join()
      };
    });

  static isEqual = (a: any[] = [], b: any[] = []): boolean => {
    const sortedA = a.sort((v1: string, v2: string) => v1.toString().localeCompare(v2.toString()));
    const sortedB = b.sort((v1: string, v2: string) => v1.toString().localeCompare(v2.toString()));
    return sortedA.length === sortedB.length && sortedA.every((element: any, index: number): boolean => element === sortedB[index]);
  };

  static diffByKeys(list1: any[], list2: any[], key1: string, key2?: string): any[] {
    return list1.filter((v1) => !list2.find((v2) => get(v1, key1) === get(v2, key2 ?? key1)));
  }

  static padEnd = (array: any[], minLength: number, fillValue: any) => Object.assign(new Array(minLength).fill(fillValue), array);
}
