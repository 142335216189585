import { NgModule } from '@angular/core';
import { DeviceDetailsBasicInfoComponent } from './device-details-basic-info/device-details-basic-info.component';
import { DeviceDetailsConnectorsComponent } from './device-details-connectors/device-details-connectors.component';
import { DeviceInfoStatusModule } from './device-info-status/device-info-status.module';

@NgModule({
  imports: [DeviceDetailsBasicInfoComponent, DeviceDetailsConnectorsComponent, DeviceInfoStatusModule],
  exports: [DeviceDetailsBasicInfoComponent, DeviceDetailsConnectorsComponent]
})
export class DeviceDetailsModule {}
