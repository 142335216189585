import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, signal, WritableSignal } from '@angular/core';
import { CronDisplayPipe } from '@iot-platform/pipes';

@Component({
  standalone: true,
  selector: 'grid-engine-cron-readable-cell',
  templateUrl: './cron-readable-cell.component.html',
  imports: [CronDisplayPipe],
  providers: [CronDisplayPipe],
  styleUrls: ['./cron-readable-cell.component.scss']
})
export class CronReadableCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<ICellRendererParams> = signal(null);

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
