import { I4BArrayOfObjectColumnConfiguration, I4BCellType } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class ArrayOfObjectColumn implements I4BColumn<I4BBasicColumnHeader, I4BArrayOfObjectColumnConfiguration, I4BColumnOptions> {
  columnId = 'e5e6da5a-cb50-4fd2-bd8c-c40f3df1e611-array-of-object';
  className = ArrayOfObjectColumn;
  dataLibs: DataLibrary[] = [DataLibrary.SITES, DataLibrary.ASSETS, DataLibrary.DEVICES, DataLibrary.CONNECTORS];
  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC, displayName: '' });
  configuration: I4BArrayOfObjectColumnConfiguration = {
    isLink: false,
    position: '',
    sortProperty: '',
    id: '',
    translationKey: '',
    concept: undefined,
    isDefault: true,
    isConfigurable: true,
    width: 150,
    cell: {
      type: I4BCellType.ARRAY_OF_OBJECTS,
      options: {
        separator: ', ',
        attributes: undefined,
        expressions: []
      }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BArrayOfObjectColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }

    if (customConfig) {
      this.configuration = {
        ...this.configuration,
        ...customConfig,
        id: this.options.customId ? this.options.customId : customConfig.id,
        sortProperty: customConfig.sortProperty ? customConfig.sortProperty : this.configuration.sortProperty,
        concept: customConfig.concept ? customConfig.concept : this.configuration.concept,
        width: customOptions?.width ? customOptions.width : customConfig.width ? customConfig.width : 150,
        cell: {
          type: customConfig?.cell?.type ?? this.configuration.cell.type,
          options: { ...this.configuration.cell.options, ...customConfig?.cell?.options }
        }
      };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions, customId: customConfig.id };
    }
  }
}
