import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { dummyFeatureReducer } from './dummy-feature.selector';

export { BaseState, INITIAL_BASE_STATE } from './base-state.model';
export { BaseFacade } from './base-facade.model';
export { BaseSelector } from './base-selector.model';
export { ApiSelector } from './api-selector.model';
export { ApiFeatureSelector } from './api-feature-selector.model';
export { DummyFacade } from './dummy.facade';
export { DummyFeatureSelector } from './dummy-feature.selector';

export function provideDummyState(): EnvironmentProviders[] {
  return [
    // provideState(dummyFeatureReducer.name, dummyFeatureReducer.reducer)
    importProvidersFrom(StoreModule.forFeature(dummyFeatureReducer.name, dummyFeatureReducer.reducer))
  ];
}
