<div class="detail-card" fxLayout="column" fxLayoutGap="4px">
  <ul class="detail-card-list">
    <li attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.NAME' | translate }}"
        class="detail-card-item">{{ device()?.name | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.TYPE' | translate }}" class="detail-card-item">
      {{ device()?.type | infoDisplay: 'hyphen' : 'family' : 'model' }}
    </li>
    <li attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.VARIABLE' | translate }}" class="detail-card-item">
      {{ deviceVariable()?.name | infoDisplay }}
    </li>
    <li attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.EVENT_VALUE' | translate }}"
        class="detail-card-item">
      @if (eventVariableProcessedUnit()) {
        <span> {{ eventVariableProcessedUnit() }} </span>
      } @else {
        <span> {{ event()?.context?.deviceVariable?.value | numberFormat | infoDisplay }} {{ event()?.context?.deviceVariable?.unit }} </span>
      }
      @if (event()?.context?.deviceVariable?.value) {
        <span>({{ event()?.occurrenceTime | dateFormat }})</span>
      }
    </li>
    <li attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.COMMENT' | translate }}" class="detail-card-item">
      {{ deviceVariable()?.comment | infoDisplay }}
    </li>
  </ul>
  <ul class="detail-card-list">
    <li attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.CURRENT_VALUE' | translate }}"
        class="detail-card-item">
      <span
        class="detail-asset-variable-card-value"> {{ deviceVariable() | valueUnitFormat }} </span>
      <span>({{ deviceVariable()?.lastValue?.datetime | dateFormat | infoDisplay }})</span>
    </li>
  </ul>
</div>
