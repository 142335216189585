export type PhoneNumberType =
  | 'FIXED_LINE'
  | 'MOBILE'
  | 'FIXED_LINE_OR_MOBILE'
  | 'TOLL_FREE'
  | 'PREMIUM_RATE'
  | 'UNKNOWN'
  | 'UAN'
  | 'PAGER'
  | 'PERSONAL_NUMBER'
  | 'VOIP'
  | 'SHARED_COST';

export type PhoneNumberPossibleReason = 'INVALID_COUNTRY_CODE' | 'TOO_SHORT' | 'TOO_LONG' | 'INVALID_LENGTH' | 'UNKNOWN';

export const DEFAULT_PHONE_NUMBER_REGIN_CODE = 'FR';

export interface PhoneNumber {
  regionCode: string;
  reason: PhoneNumberPossibleReason;
  isValid: boolean;
  numberType: PhoneNumberType;
  originalFormat: string;
  nationalFormat: string;
  e164Format: string;
  internationalFormat: string;
}
